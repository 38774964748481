import React from 'react';
import { connect } from 'react-redux';
import withAll from 'components/base/withAll';
import { withStyles } from '@material-ui/styles';
import { Field, reduxForm } from 'redux-form';
import { Icon, CardActions, CardContent } from '@material-ui/core';
import Button from 'components/base/Button';
import Input from 'components/base/forms/Input';
import PropTypes from 'prop-types';
import Error from 'components/base/forms/Error/index';
// import CheckBox from 'components/base/forms/CheckBox';
import { combine, required, validateForm, length, format } from 'redux-form-validators';
import { forms } from 'constants/config';
import PlatformSelectField from './PlatformSelectField';
import KeywordSelectField from './KeywordSelectField';
import styles from './styles';

const customValidate = {
  monitorName: combine(
    required({ msg: 'Name is required.' }),
    format({ with: /^[0-9a-zA-Z_ -]+$/i, msg: 'Only letters, numbers and spaces are allowed. Exception "_", and "-".' }),
    length({ max: 50, msg: 'Value exceeds 50 characters' }),
  ),
  query: combine(
    required({ msg: 'Keyword is required.' }),
    length({ max: 250, msg: 'Value exceeds 250 characters' }),
  ),
  platforms: required({ msg: 'Platform is required.' }),
};

const KeyPhraseMonitorForm = ({ handleSubmit, error, classes }) => (
  <form className={classes.form} onSubmit={handleSubmit}>
    <CardContent>
      <div className={classes.note}>
        When you create a monitor, we will show you the matching post activity over the past 365 days.
        Monitors are refreshed every day.
      </div>
      <Field
        name="monitorName"
        component={Input}
        type="text"
        placeholder="Name"
        label="Name"
        fullWidth
        required
      />
      <Field
        className={classes.platformSelect}
        name="platforms"
        component={PlatformSelectField}
        fullWidth
        include={['instagram', 'tiktok']}
      />
      <Field
        name="query"
        component={KeywordSelectField}
        fullWidth
      />
      <div className={classes.note}>
        Adding multiple keywords will result in an AND operation, i.e. #fitness AND #health
      </div>
      {/* <Field
        name="status"
        component={({ input, label }) => <CheckBox {...input} label={label} />}
        type="checkbox"
        label="Active"
        fullWidth
      /> */}
      <Error error={error} />
    </CardContent>
    <CardActions>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        id="changeMonitor"
      >
        <Icon>send</Icon> Submit
      </Button>
    </CardActions>
  </form>
);

KeyPhraseMonitorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ keyPhraseMonitor: { data } }) => ({
  initialValues: {
    ...data,
  },
});

export default withAll(
  reduxForm({
    form: forms.KEY_PHRASE_MONITOR_FORM,
    validate: validateForm(customValidate),
  }),
  withStyles(styles),
  connect(mapStateToProps, null),
)(KeyPhraseMonitorForm);
