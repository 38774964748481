import React from 'react';
import { getInfluencers, updateErrorState } from 'redux/influencers/actions';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import { connect } from 'react-redux';
import withAll from 'components/base/withAll';
import { withRouter } from 'react-router';
import withModal from 'components/base/withModal';
import { home } from 'constants/routing';

function withInfluencerSearch(WrappedComponent) {
  class Wrapper extends React.Component {
    getInfluencers = async (params) => {
      const { openModal } = this.props;
      try {
        await this.props.getInfluencers(params);
      } catch (e) {
        if (e.errors.status === 400) {
          // 400 status indicates the default search params don't
          // match the default search params in the backend which has
          // triggered a cycle of components mounting/dismounting which
          // prevents the sign-up modal from opening
          // the below is a workaround to use a state variable in redux
          // to trigger the sign up modal if we encounter a scenario
          // where the search params don't match. Dev Team should
          // take a look and update the code as necessary
          this.props.updateErrorState(true);
        } else {
          openModal();
        }
      }
    };

    closeModal = () => {
      this.props.updateErrorState(false);
      this.props.history.push(home());
    }

    render() {
      const { open, closeModal, ...props } = this.props;
      return (
        <React.Fragment>
          <BetaSignupModal
            open={open || this.props.influencers.error}
            closeModal={this.props.influencers.error ? this.closeModal : closeModal}
            onSignupComplete={this.props.influencers.error ? this.closeModal : closeModal}
          />
          <WrappedComponent
            {...props}
            getInfluencers={this.getInfluencers}
          />
        </React.Fragment>
      );
    }
  }
  return withAll(
    withModal,
    withRouter,
    connect(
      state => ({
        influencers: state.influencers,
      }),
      { getInfluencers, updateErrorState },
    ),
  )(Wrapper);
}

export default withInfluencerSearch;
