import { newReducer } from 'redux/utils';

const initialState = {
  onBoarding: {
    stepIndex: 0,
    showCategoryMenu: false,
    locationSearchQuery: [],
    run: false,
    isCompleted: false,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const onBoardingReducer = newReducer(initialState.onBoarding);

onBoardingReducer.method.UPDATE_ON_BOARDING = (state, action) => ({
  ...state,
  ...action.data,
});
