export default theme => ({
  content: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 5rem)',
    },
    [theme.breakpoints.up('sm')]: {
      height: '40vh',
    },
  },
  card: {
    borderRadius: '0.4rem',
    boxShadow: theme.customShadows[3],
  },
});

