export default theme => ({
  root: {},
  topLocation: {
    marginTop: '0.5rem',
    color: theme.palette.black.dark,
  },
  topLocationBlurred: {
    marginTop: '0.5rem',
    color: theme.palette.black.dark,
    filter: 'blur(5px)',
  },
  countries: {
    color: theme.palette.black.light,
  },
});
