import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faSnapchatGhost,
  faPinterest,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import styles from './styles';

const PLATFORM_CONFIG = {
  instagram: {
    icon: <FontAwesomeIcon icon={faInstagram} />,
  },
  facebook: {
    icon: <FontAwesomeIcon icon={faFacebookF} />,
  },
  twitter: {
    icon: <FontAwesomeIcon icon={faTwitter} />,
  },
  youtube: {
    icon: <FontAwesomeIcon icon={faYoutube} />,
  },
  snapchat: {
    icon: <FontAwesomeIcon icon={faSnapchatGhost} />,
  },
  blog: {
    icon: <FontAwesomeIcon icon={faGlobe} />,
  },
  tiktok: {
    icon: <FontAwesomeIcon icon={faTiktok} />,
  },
  pinterest: {
    icon: <FontAwesomeIcon icon={faPinterest} />,
  },
};

class PlatformIcon extends React.Component {
  constructor(props) {
    super(props);

    const {
      classes,
      className,
      platform,
      selected,
    } = this.props;

    this.state = {
      checked: selected,
      classNames: [className, classes.icon, classes[platform]],
    };
  }

  render() {
    const { classes, platform, onToggle } = this.props;
    const config = PLATFORM_CONFIG[platform];
    if (config === undefined) {
      return <React.Fragment />;
    }

    const classNames = [...this.state.classNames];
    const iconProps = {};

    if (onToggle) {
      classNames.push(classes.clickable);

      iconProps.role = 'button';
      iconProps.onClick = () => {
        const checked = !this.state.checked;

        if (!checked) {
          classNames.push(classes.inactive);
        } else {
          const index = classNames.indexOf(classes.inactive);
          if (index > -1) {
            classNames.splice(index, 1);
          }
        }

        this.setState({ checked, classNames }, () => onToggle(platform, checked));
      };
    }

    return <div className={cx(classNames)} {...iconProps}>{config.icon}</div>;
  }
}

PlatformIcon.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  platform: PropTypes.string.isRequired,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  selected: PropTypes.bool,
};

PlatformIcon.defaultProps = {
  className: '',
  onToggle: null,
  selected: true,
};

export default withStyles(styles)(PlatformIcon);
