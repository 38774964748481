import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import H6 from 'components/base/titles/CardHeading/H6';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import HorizontalBarHtml from 'components/base/charts/Bar/BarHtml/HorizontalBarHtml';
import withAll from 'components/base/withAll';
import { formatNumber } from 'utils/helpers';
import { config } from 'theme';

const styles = () => ({
  root: {},
  avatar: {
    marginRight: '0.8rem',
  },
});

const NULL_VALUE_DISPLAY = '-';

const LEGEND_COLORS = [
  config.palette.green.main,
  config.palette.red.main,
  config.palette.barChart.gradient.blue,
  config.palette.blue.dark,
];

const createInnerLabel = brandData => (
  `${brandData.name}: ${formatNumber(brandData.currentPeriod.total, '$0.00a', NULL_VALUE_DISPLAY)}`
);

const OverallChart = ({ classes, data }) => (
  <div className={classes.root}>
    <H6>
      <LabelWithInfoIcon
        labelText="Estimated Creator Spend - Overall"
        tooltip="The overall spend for each brand within the selected time period."
      />
    </H6>
    <HorizontalBarHtml
      data={{
        barLabels: data.map(b => createInnerLabel(b)),
        colors: LEGEND_COLORS,
        labels: data.map(b => <Avatar className={classes.avatar} alt={b.name} src={b.logo} />),
        values: data.map(b => b.currentPeriod.total),
      }}
    />
  </div>
);

OverallChart.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    currentPeriod: PropTypes.shape({
      total: PropTypes.number,
    }),
  })),
};

OverallChart.defaultProps = {
  data: [],
};

const mapStateToProps = ({ competitiveReportEstimatedSpend: { data: { data } } }) => ({
  data,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(OverallChart);
