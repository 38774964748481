import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import RelatedKeywordsModal from 'components/common/modals/RelatedKeywords';
import { getRelatedKeywords as getRelatedKeywordsAction,
  resetRelatedKeywords as resetRelatedKeywordsAction } from 'redux/relatedKeywords/actions';
import styles from './styles';

const RelatedKeywords = ({
  classes,
  keywords,
  isLoading,
  open,
  openModal,
  closeModal,
  getRelatedKeywords,
  resetRelatedKeywords,
  isAuthenticated,
}) => {
  const handleClick = async () => {
    await resetRelatedKeywords();
    getRelatedKeywords(keywords);
    openModal();
  };
  return (
    <div className={classes.root}>
      {isAuthenticated && keywords && keywords.length>0 && keywords.length<4 ?
        <Button
          variant="text"
          color="primary"
          className={classes.button_related_keywords}
          onClick={handleClick}
          disabled={isLoading}
        >
          view related keywords
        </Button> :
        <React.Fragment />
      }
      <RelatedKeywordsModal open={open} onClose={closeModal} />
    </div>
  );
};

RelatedKeywords.propTypes = {
  keywords: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getRelatedKeywords: PropTypes.func.isRequired,
  resetRelatedKeywords: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ relatedKeywords, searchParams, auth: { isAuthenticated } }) => ({
  isLoading: relatedKeywords.loading,
  keywords: searchParams.keywords,
  isAuthenticated,
});

const mapDispatchToProps = {
  getRelatedKeywords: getRelatedKeywordsAction,
  resetRelatedKeywords: resetRelatedKeywordsAction,
};

export default withAll(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withModal,
)(RelatedKeywords);
