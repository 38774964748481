import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AsyncMultiSelect from 'components/base/AsyncMultiSelect';
import { getLabelAutoComplete } from 'redux/autocompletes/actions';

const MININUM_INPUT_CHARS = 3;

class LabelAutoComplete extends Component {
  loadOptions = async (inputValue, callback) => {
    if (inputValue.length < MININUM_INPUT_CHARS) {
      callback([]);
      return;
    }
    await this.props.getLabelAutoComplete(inputValue);
    callback(this.props.labelAutoComplete.results);
  }

  render() {
    const { onChange, handleRemoveLabel, labels } = this.props;
    return (
      <div>
        <AsyncMultiSelect
          name="labels"
          value={labels}
          placeholder="Find Labels"
          onChange={onChange}
          loadOptions={this.loadOptions}
          handleRemoveSelection={handleRemoveLabel}
          minChars={MININUM_INPUT_CHARS}
          isMulti
        />
      </div>
    );
  }
}

LabelAutoComplete.propTypes = {
  getLabelAutoComplete: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  })),
  labelAutoComplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  handleRemoveLabel: PropTypes.func.isRequired,
};

LabelAutoComplete.defaultProps = {
  onChange: null,
  labels: null,
};

export default connect(
  state => ({
    labelAutoComplete: state.labelAutoComplete,
  }),
  { getLabelAutoComplete },
)(LabelAutoComplete);
