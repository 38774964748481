import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import LineBasic from 'components/base/charts/Line/LineBasic';
import { CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import styles from './styles';

const getPostOptions = firstDate => ({
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: false,
        min: 0,
      },
    }],
    xAxes: [{
      type: 'time',
      distribution: 'linear',
      display: true,
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
      time: {
        tooltipFormat: 'DD MMM YYYY',
        min: firstDate,
        minUnit: 'day',
        displayFormats: {
          day: 'MMM DD, YYYY',
          week: 'MMM DD, YYYY',
          month: 'MMM DD, YYYY',
          quarter: 'MMM DD, YYYY',
          year: 'MMM DD, YYYY',
        },
      },
    }],
  },
  tooltips: {
    intersect: false,
  },
  maintainAspectRatio: false,
});


const MonitorGraph = ({
  classes, postsByTime, theme,
}) => {
  const transformPostData = () => ({
    datasets: [{
      data: postsByTime.map(value => ({ x: value.date, y: value.dailyPostCount })),
      borderColor: theme.palette.green.main,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      backgroundColor: theme.palette.green.main,
      fill: true,
    }],
  });
  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.graphContainer}>
          <LineBasic
            data={transformPostData()}
            options={getPostOptions(postsByTime[0].date)}
          />
        </div>
      </CardContent>
    </Card>
  );
};

MonitorGraph.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  postsByTime: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ keyPhraseMonitorPosts: { data } }) => ({
  postsByTime: data.postsByTime,
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles, { withTheme: true })(MonitorGraph));
