import { get } from 'lodash';
import { newReducer } from '../../utils';

const initialState = {
  categories: {
    data: [],
    loading: false,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const categoriesFeaturedReducer = newReducer(initialState);

categoriesFeaturedReducer.method.FETCH_FEATURED_CATEGORIES_PENDING = (state = initialState) => ({
  ...state,
  categories: {
    data: [],
    loading: true,
  },
});

categoriesFeaturedReducer.method.FETCH_FEATURED_CATEGORIES_FULFILLED = (state = initialState, action) => ({
  ...state,
  categories: {
    data: get(action.payload, 'categories'),
    loading: false,
  },
});

categoriesFeaturedReducer.method.FETCH_FEATURED_CATEGORIES_REJECTED = (state = initialState) => ({
  ...state,
  categories: {
    data: [],
    loading: false,
  },
});
