import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Field, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import { lengthValidator, requiredValidator } from 'utils/validators';
import { ImageUploadStyled as ImageUpload } from 'components/base/ImageUpload';
import Input from 'components/base/forms/Input';
import styles from './styles';
import CategorySelectField from './CategorySelectField';

class ListForm extends React.Component {
  getCategory = (category) => {
    const { categories } = this.props;
    return find(categories, c => c.name === category);
  };

  handleCategoryChange = (categoryName) => {
    const category = this.getCategory(categoryName);
    const { formValues = {} } = this.props;
    const { image } = formValues;
    this.props.change('listForm', 'image', {
      ...image,
      defaultImage: category.image,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.list__form}>
        <div className={classes.image__section}>
          <Field
            name="image"
            component={ImageUpload}
          />
        </div>
        <div className={classes.inputs}>
          <Field
            component={Input}
            label="Name"
            className={classes.list__name}
            margin="normal"
            name="name"
            validate={[requiredValidator, lengthValidator(0, 257)]}
          />
          <Field
            component={CategorySelectField}
            name="category"
            onChange={this.handleCategoryChange}
          />
        </div>
      </div>
    );
  }
}

ListForm.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  initial: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.string,
  }),
  change: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    category_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
  })).isRequired,
};

ListForm.defaultProps = {
  initial: null,
};

const mapStateToProps = state => ({
  formValues: getFormValues('listForm')(state),
  categories: get(state, ['lists', 'categories', 'data']),
});

const mapDispatchToProps = {
  change,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListForm));
