export default theme => ({
  buttonBox: {
    position: 'fixed',
    bottom: 100,
    right: 20,
    zIndex: 99,
  },
  buttonFab: {
    backgroundColor: theme.palette.white.dark,
    height: 60,
    width: 60,
  },
});
