import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { formatNumber } from 'utils/helpers';
import Chip from 'components/base/Chip';


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.white.main,
    alignItems: 'center',
    marginBottom: '0.8rem',
    display: 'flex',
  },
  brandName: {
    textAlign: 'left',
    paddingLeft: '1rem',
  },
  logo: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    position: 'absolute',
    height: '100%',
  },
  logoContainer: {
    width: '4rem',
    height: '4rem',
    position: 'relative',
    backgroundColor: theme.palette.white.dark,
    '&:after': {
      content: '',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  chip: {
    marginLeft: '0.8rem',
  },
});

const BrandsInReport = ({ classes, data, verticalPostCount }) => (
  <Card
    className={classes.root}
  >
    <div className={classes.logoContainer}>
      <div className={classes.logo} style={{ backgroundImage: `url(${data.logo})` }} />
    </div>
    <div className={classes.contentContainer}>
      <div className={classes.brandName}>{data.name}</div>
      <Chip
        size="small"
        label={`Brand Avg. ${formatNumber(data.postCount / verticalPostCount, '0%', '-')}`}
        className={classes.chip}
      />
    </div>
  </Card>
);

BrandsInReport.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  verticalPostCount: PropTypes.number.isRequired,
};

BrandsInReport.defaultProps = {
};

const mapStateToProps = ({ competitiveReportVerticalBreakdown }) => ({
  verticalPostCount: get(competitiveReportVerticalBreakdown, 'data.vertical.postCount'),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(BrandsInReport);

