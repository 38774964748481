import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PageTitle from 'components/base/titles/PageTitle';
import { Typography, Grid } from '@material-ui/core';
import PromoBannerWrapper from 'components/base/sections/PromoBannerWrapper';
import Button from 'components/base/Button';
import BulleitItem from 'components/base/sections/BulleitItem';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import withModal from 'components/base/withModal';
import withAll from 'components/base/withAll';
import reportImage from 'images/home/reports.gif';

const styles = theme => ({
  innerContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  title: {
    textAlign: 'left',
    display: 'block',
    fontSize: '2rem',
    margin: '0 0 0.8rem 0',
    '&:before': {
      width: '40px',
      marginBottom: '0.8rem',
      display: 'block',
      position: 'relative',
      content: '""',
      height: '2px',
      backgroundColor: theme.palette.pink.main,
    },
    [theme.breakpoints.up('md')]: {
      width: '25rem',
    },
  },
  subTitle: {
    marginBottom: '1.2rem',
    color: theme.palette.black.light,
    display: 'block',
  },
  imageContainer: {
    flexGrow: 1,
    textAlign: 'right',
  },
  image: {
    width: '100%',
    borderRadius: '0.4rem',
    boxShadow: theme.customShadows[3],
    overflow: 'hidden',
    margin: '1rem 0 -2rem auto',
    '& > img': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '75%',
      minWidth: '40rem',
      margin: '0 0 -13rem auto',
    },
  },
  bullietContainer: {
    marginBottom: '1rem',
  },
  textContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40rem',
    },
  },
});

const IntroBanner = ({
  classes,
  open,
  closeModal,
  openModal,
}) => (
  <PromoBannerWrapper>
    <BetaSignupModal
      open={open}
      closeModal={closeModal}
      onSignupComplete={closeModal}
    />
    <div className={classes.innerContainer}>
      <div className={classes.textContainer}>
        <PageTitle
          title={<React.Fragment>The Creator <b>Insights & Trends</b> Platform</React.Fragment>}
          className={classes.title}
          titleId="homeHeader"
        />
        <Typography variant="body" className={classes.subTitle} id="introBannerBody">
          Go Prism, a proprietary social intelligence platform, leverages innovative technology
          and analysis, as well as cross-channel data sets to give brands access to social insights
          on demand. Go Prism cuts through the noise of social media to provide actionable insights,
          allowing brands to determine competitive benchmarks, understand campaign efficacy and make
          more informed decisions around targeting and spend.
        </Typography>
        <Grid container className={classes.bullietContainer} id="introBannerBulletContainer">
          <BulleitItem>Influencer Marketing Trends</BulleitItem>
          <BulleitItem>Competitive Insights</BulleitItem>
          <BulleitItem>Creator Discovery</BulleitItem>
          <BulleitItem>& More…</BulleitItem>
        </Grid>
        <Button id="introBannerBtnGetStarted" color="primary" variant="contained" size="large" onClick={openModal}>
          Get Started
        </Button>
      </div>
      <div className={classes.imageContainer}>
        <div className={classes.image}>
          <img alt="Reports" src={reportImage} />
        </div>
      </div>
    </div>
  </PromoBannerWrapper>
);

IntroBanner.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default withAll(
  withStyles(styles),
  withModal,
)(IntroBanner);
