import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import StatCard from 'components/common/StatTicker';
import { slice } from 'lodash';
import { ma } from 'moving-averages';
import { getTrend, partitionDataByWeek } from 'lib/utils';

const styles = theme => ({
  statGrid: {
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
    },
  },
});

const getRollingAverage = (statList, maDays) => {
  const weeklyPosts = partitionDataByWeek(slice(ma(statList, maDays), maDays));
  return {
    trend3WRa: getTrend(weeklyPosts, 1, 3),
    trend6WRa: getTrend(weeklyPosts, 1, 6),
  };
};

const AvgStatCtrl = ({
  statList, isWideScreen, maDays, classes,
}) => {
  const rollingAvg = getRollingAverage(statList, maDays);
  let boxSize = 3;
  if (isWideScreen) {
    boxSize = 2;
  }
  return (
    <Grid container spacing={2} className={classes.statGrid} justify="flex-end">
      <Grid item md={boxSize}>
        <StatCard
          value={rollingAvg.trend3WRa}
          label="3 WEEK AVERAGE"
        />
      </Grid>
      <Grid item md={boxSize}>
        <StatCard
          value={rollingAvg.trend6WRa}
          label="6 WEEK AVERAGE"
        />
      </Grid>
    </Grid>);
};

AvgStatCtrl.propTypes = {
  statList: PropTypes.shape({}).isRequired,
  maDays: PropTypes.number,
  isWideScreen: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
};

AvgStatCtrl.defaultProps = {
  isWideScreen: false,
  maDays: 14,
};

export default withStyles(styles)(AvgStatCtrl);
