import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { getPercentageChange } from 'lib/utils';
import { formatIndicator } from 'utils/helpers';
import { round } from 'lodash';

export const dataRateTooltipLabel = (tooltip, dataset, rawData, customOptions) => {
  const { index: i, datasetIndex } = tooltip;
  const thisDataset = dataset.datasets[datasetIndex].data;
  if (!i) {
    return '';
  }
  if (customOptions.rawData) {
    return tooltip.yLabel;
  }
  if (!rawData) {
    return round(tooltip.yLabel);
  }
  const postCount = rawData[tooltip.index];
  const changeVal = formatIndicator(getPercentageChange(tooltip.yLabel, thisDataset[i - 1]));
  return [`Post count: ${round(postCount)}`, changeVal];
};

export const getOptions = (options, rawData, customOptions = {}) => ({
  responsive: true,
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  elements: {
    point: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
  tooltips: {
    displayColors: false,
    mode: 'index',
    enabled: true,
    intersect: false,
    callbacks: {
      label: (tooltip, dataset) => dataRateTooltipLabel(tooltip, dataset, rawData, customOptions),
      labelColor: (tooltipItem, chart) => ({
        borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
        backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
      }),
    },
  },
  title: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: false,
      },
    }],
    xAxes: [{
      display: true,
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
    }],
  },
  ...options,
  legend: {
    display: false,
    labels: {
      usePointStyle: true,
    },
    ...options.legend ? options.legend : {},
  },
});

const LineBasic = ({
  options, data, rawData, customOptions, ...other
}) => (
  <Line
    data={data}
    options={getOptions(options, rawData, customOptions)}
    {...other}
  />
);

LineBasic.propTypes = {
  options: PropTypes.shape({}),
  rawData: PropTypes.shape({}),
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
};

LineBasic.defaultProps = {
  options: {},
  rawData: null,
};

export default LineBasic;
