import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { login } from '../../constants/routing';

export default ({
  component: C,
  props: cProps,
  redirect,
  ...rest
}) =>
  (<Route
    {...rest}
    render={props => (cProps.isAuthenticated ?
      <C key={Date.now()} {...props} {...cProps} /> :
      <Redirect
        to={!isEmpty(redirect) ? redirect : `${login()}?redirect=${props.location.pathname}${props.location.search}`}
      />)
    }
  />);

