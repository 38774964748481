import { omit, set } from 'lodash';
import { newReducer } from 'redux/utils';
import { INFLUENCER_SEARCH_TABS } from 'constants/options';

const initialState = {
  search: {
    isVerticalPanelOpen: true,
    selectedTab: INFLUENCER_SEARCH_TABS.INFLUENCER,
    selectedInfluencers: {},
  },
};

// eslint-disable-next-line import/prefer-default-export
export const searchReducer = newReducer(initialState.search);

searchReducer.method.TOGGLE_SEARCH_PANEL = (state, action) => ({
  ...state,
  isVerticalPanelOpen: action.isVerticalPanelOpen,
});

searchReducer.method.SELECT_SEARCH_TAB = (state, action) => ({
  ...state,
  selectedTab: action.selectedTab,
  isVerticalPanelOpen: true,
});

searchReducer.method.ADD_SELECTED_INFLUENCER = (state, { influencerId, influencerName }) => ({
  ...state,
  selectedInfluencers: set(state.selectedInfluencers, influencerId, { name: influencerName }),
});

searchReducer.method.ADD_SELECTED_INFLUENCERS = (state, { selectedInfluencers }) => ({
  ...state,
  selectedInfluencers: {
    ...state.selectedInfluencers,
    ...selectedInfluencers,
  },
});

searchReducer.method.REMOVE_SELECTED_INFLUENCER = (state, { influencerId }) => ({
  ...state,
  selectedInfluencers: omit(state.selectedInfluencers, influencerId),
});

searchReducer.method.DESELECT_ALL_INFLUENCERS = state => ({
  ...state,
  selectedInfluencers: {},
});
