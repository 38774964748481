import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from 'components/base/Layout/PageLayout';
import Influencers from './Influencers';
import Categories from './Categories';

const Featured = ({ configs }) => (
  <PageLayout>
    {configs.featuredCategoriesEnabled && <Categories />}
    {configs.featuredInfluencersEnabled && <Influencers />}
  </PageLayout>
);

Featured.propTypes = {
  configs: PropTypes.shape({
    featuredCategoriesEnabled: PropTypes.bool.isRequired,
    featuredInfluencersEnabled: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ configs: { data, loading } }) => ({
  configs: data || [],
  loading,
});

export default connect(mapStateToProps, null)(Featured);
