import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { CardActions, CardContent } from '@material-ui/core';
import Button from 'components/base/Button';
import Input from 'components/base/forms/Input';
import CheckBox from 'components/base/forms/CheckBox';
import PropTypes from 'prop-types';
import Error from 'components/base/forms/Error/index';
import { combine, required, validateForm, length } from 'redux-form-validators';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { forms } from 'constants/config';
import { brandIds } from 'utils/validators';
import BrandAutoComplete from './BrandAutoComplete';

const customValidate = {
  title: combine(
    required({ msg: 'First name is required.' }),
    length({ max: 250, msg: 'Value exceeds 250 characters' }),
  ),
  brandIds: brandIds('You must select at least 1 brand.'),
};

const styles = theme => ({
  title: {
    '& input': {
      fontSize: '1.4rem',
    },
  },
  addCompanyControl: {
    marginTop: '1rem',
    border: `1px solid ${theme.palette.border.main}`,
    height: '10rem',
  },
});

class CompetitiveReportForm extends React.Component {
  componentDidMount() {
    const { initial, initialize } = this.props;
    if (initial) {
      initialize(initial);
    }
  }
  render() {
    const {
      handleSubmit,
      error,
      actions,
      classes,
      hideActive,
    } = this.props;
    return (
      <form className="form" onSubmit={handleSubmit}>
        <CardContent>
          <div>
            <Field
              name="title"
              component={Input}
              type="text"
              placeholder="Title"
              label="Title"
              className={classes.title}
              fullWidth
            />
          </div>
          <div>
            <Field
              name="brandIds"
              component={p => (
                <BrandAutoComplete
                  {...p}
                />
              )}
              label="Brands"
            />
          </div>
          {!hideActive && (
            <div>
              <Field
                name="active"
                component={({ input, label }) => <CheckBox {...input} label={label} />}
                type="checkbox"
                label="Active"
              />
            </div>
          )}
          <div>
            <Error error={error} />
          </div>
        </CardContent>
        <CardActions>
          {actions}
        </CardActions>
      </form>
    );
  }
}

CompetitiveReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  initial: PropTypes.shape({}),
  error: PropTypes.string,
  actions: PropTypes.element,
  classes: PropTypes.shape({}).isRequired,
  hideActive: PropTypes.bool,
};

CompetitiveReportForm.defaultProps = {
  error: null,
  hideActive: false,
  initial: { brandIds: [] },
  actions: <Button fullWidth variant="contained" color="primary" type="submit">Save</Button>,
};

export default withAll(
  reduxForm({
    form: forms.BETA_SIGNUP_FORM,
    validate: validateForm(customValidate),
  }),
  withStyles(styles),
)(CompetitiveReportForm);
