import {
  ALL_GENDER,
  DEFAULT_MIN_FOLLOWER_COUNT,
  DEFAULT_MAX_FOLLOWER_COUNT,
  DEFAULT_INFLUENCER_SEARCH_SORT,
  DEFAULT_INFLUENCER_SEARCH_PAGE_SIZE,
  PLATFORMS,
} from 'constants/options';
import { convertFilters } from 'utils/influencers';
import { newReducer } from '../utils';

export const initialState = {
  igFollowerRange: {
    min: DEFAULT_MIN_FOLLOWER_COUNT,
    max: DEFAULT_MAX_FOLLOWER_COUNT,
  },
  piFollowerRange: {
    min: DEFAULT_MIN_FOLLOWER_COUNT,
    max: DEFAULT_MAX_FOLLOWER_COUNT,
  },
  ttFollowerRange: {
    min: DEFAULT_MIN_FOLLOWER_COUNT,
    max: DEFAULT_MAX_FOLLOWER_COUNT,
  },
  ytFollowerRange: {
    min: DEFAULT_MIN_FOLLOWER_COUNT,
    max: DEFAULT_MAX_FOLLOWER_COUNT,
  },
  gender: ALL_GENDER,
  genders: null,
  categories: [],
  keywords: [],
  labels: [],
  captions: [],
  platforms: [PLATFORMS.INSTAGRAM],
  locations: [],
  locationRadius: 10,
  audienceGender: ALL_GENDER,
  countries: [],
  username: null,
  usernameExact: false,
  sort: DEFAULT_INFLUENCER_SEARCH_SORT,
  limit: DEFAULT_INFLUENCER_SEARCH_PAGE_SIZE,
  page: 0,
};

export const defaultInitialState = JSON.parse(JSON.stringify(initialState));

export const searchParamsReducer = newReducer(initialState);

searchParamsReducer.method.MERGE_SEARCH_PARAMS = (state, { searchParams }) => ({
  ...state,
  ...searchParams,
});

searchParamsReducer.method.SET_SEARCH_PARAM = (state, { key, value }) => ({
  ...state,
  [key]: value,
});

searchParamsReducer.method.RESET_SEARCH_PARAMS = () => ({
  ...JSON.parse(JSON.stringify(defaultInitialState)),
});

searchParamsReducer.method.DEFAULT_SEARCH_PARAMS_FULFILLED = (state, action) => convertFilters(action.payload);
