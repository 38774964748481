import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from 'components/base/Card';
import { CardContent, CardMedia, Typography, Avatar, Icon } from '@material-ui/core';

import Button from 'components/base/Button';
import withAll from 'components/base/withAll';

const styles = theme => ({
  root: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
  },
  indexAvatar: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.white.main,
    margin: '0 auto',
  },
  logo: {
    height: '3rem',
    backgroundSize: 'contain',
  },
  logoContainer: {
    padding: '1rem',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const Brand = ({
  classes,
  brand,
  index,
  handleRemove,
}) => (
  <Card className={classes.root} key={`brand-card-${brand.id}`}>
    <CardContent className={classes.cardContent}>
      <Avatar className={classes.indexAvatar}>{index + 1}</Avatar>
      <div className={classes.logoContainer}>
        <CardMedia className={classes.logo} image={brand.logo} title={brand.label} />
      </div>
      <Typography variant="h6" className={classes.title}>{brand.label}</Typography>
      <Button
        fullWidth
        variant="contained"
        color="error"
        endIcon={<Icon>delete</Icon>}
        onClick={handleRemove}
      >
        Remove
      </Button>
    </CardContent>
  </Card>
);

Brand.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brand: PropTypes.shape({
    logo: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default withAll(withStyles(styles))(Brand);
