import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography, Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.2rem',
  },
  text: {
    color: theme.palette.black.light,
    paddingLeft: '0.4rem',
    fontSize: '0.8rem',
  },
  icon: {
    color: theme.palette.pink.main,
    fontSize: '1rem',
  },
});

const BulleitItem = ({ children, classes }) => (
  <Grid item xs={6} className={classes.root}>
    <CheckCircleIcon className={classes.icon} />
    <Typography variant="body" className={classes.text}>{children}</Typography>
  </Grid>
);

BulleitItem.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(BulleitItem);
