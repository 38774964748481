export default theme => ({
  instagram: {
    background: theme.palette.socialPlatform.instagramGradient,
    color: theme.palette.white.main,
  },
  facebook: {
    background: theme.palette.socialPlatform.facebook,
    color: theme.palette.white.main,
  },
  twitter: {
    background: theme.palette.socialPlatform.twitter,
    color: theme.palette.white.main,
  },
  youtube: {
    background: theme.palette.socialPlatform.youtube,
    color: theme.palette.white.main,
  },
  snapchat: {
    background: theme.palette.socialPlatform.snapchat,
    color: theme.palette.white.main,
  },
  blog: {
    background: theme.palette.socialPlatform.blog,
    color: theme.palette.white.main,
  },
  tiktok: {
    background: theme.palette.socialPlatform.tiktok,
    color: theme.palette.white.main,
  },
  pinterest: {
    background: theme.palette.socialPlatform.pinterest,
    color: theme.palette.white.main,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1rem',
    marginRight: '0.3rem',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.blue.main,
    },
  },
  inactive: {
    background: theme.palette.black.dark,
  },
});
