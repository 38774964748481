export default theme => ({
  root: {
    display: 'flex',
  },
  fullName: {
    color: theme.palette.black.dark,
  },
  menu: {
    '& a': {
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
});
