export default theme => ({
  note: {
    margin: '0.3rem 0',
    fontSize: '12px',
    textAlign: 'left',
    color: theme.palette.black.dark,
  },
  platformSelect: {
    marginTop: '1rem',
  },
});
