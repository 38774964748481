import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { withStyles } from '@material-ui/styles';
import { startCase } from 'lodash';
import numeral from 'numeral';
import styles from './styles';


class EthnicChart extends Component {
  transformValues = value => [value / 100];

  generateBarBackground = value => [1 - (value / 100)];

  generateBarChartColors = value => `rgba(31, 164, 231, ${(value / 100) + 0.2})`;
  renderBarItem = (value) => {
    const { theme } = this.props;
    const data = {
      labels: false,
      datasets: [{
        data: this.transformValues(value),
        backgroundColor: this.generateBarChartColors(value),
        borderWidth: 1,
        borderColor: theme.palette.border.main,
      }, {
        label: false,
        data: this.generateBarBackground(value),
        backgroundColor: theme.palette.white.main,
        borderWidth: 1,
        borderColor: theme.palette.border.main,
      }],
    };
    const options = {
      height: 10,
      tooltips: {
        enabled: false,
      },
      legend: false,
      title: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [{
          display: false,
          stacked: true,
          barPercentage: 1,
          ticks: {
            min: 0,
            max: 1,
            beginAtZero: true,
          },
        }],
        xAxes: [{
          display: false,
          drawTicks: false,
          stacked: true,
          gridLines: {
            lineWidth: 0,
          },
        }],
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    };
    return <HorizontalBar height={25} data={data} options={options} redraw />;
  }
  render() {
    const { ethnicData, classes } = this.props;
    return (
      <div className={classes.root}>
        {ethnicData.map(d => (
          <div key={d.ethnicType} className={classes.bar__item}>
            {this.renderBarItem(d.value)}
            <div className={classes.bar__label}>
              <span className={classes.label__text}>{startCase(d.ethnicType)}</span>
              <span className={classes.label__value}>{numeral(d.value / 100).format('0%')}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

EthnicChart.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  ethnicData: PropTypes.arrayOf(PropTypes.shape({
    ethnicType: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
};

export default withStyles(styles, { withTheme: true })(EthnicChart);
