import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBrandDetail as getBrandDetailAction } from 'redux/brand/actions';
import BrandDetail from 'components/common/BrandDetail';
import UnauthorizedBrand from 'components/common/modals/UnauthorizedBrand';
import withAll from 'components/base/withAll';
import withQueryParams from 'components/base/withQueryParams';
import BetaSignupFlowWrapper from 'components/common/BetaSignupFlowWrapper';
import PageLayout from 'components/base/Layout/PageLayout';
import { mpPageView } from 'utils/copenhagen';

const Brand = ({
  getBrandDetail,
  match: { params: { brandId } },
  isLoading,
  loaded,
}) => {
  const onComponentDidMountFetch = async () => {
    await getBrandDetail(brandId);
    mpPageView({ name: 'brand', brandId });
  };
  return (
    <div key={`brand-profile-${brandId}`}>
      <BetaSignupFlowWrapper
        UnauthorizedModal={UnauthorizedBrand}
        onComponentDidMountFetch={onComponentDidMountFetch}
        isLoading={isLoading}
        loaded={loaded}
      >
        <PageLayout>
          <BrandDetail />
        </PageLayout>
      </BetaSignupFlowWrapper>
    </div>
  );
};

Brand.propTypes = {
  getBrandDetail: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brandId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ brand }) => ({
  isLoading: brand.loading,
  loaded: brand.loaded,
});

const queryParamsToProps = ({ showSignup }) => ({
  showSignup,
});

export default withAll(
  connect(mapStateToProps, { getBrandDetail: getBrandDetailAction }),
  withQueryParams(queryParamsToProps),
)(Brand);
