import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import BaseModal from 'components/base/modals/BaseModal';
import withAll from 'components/base/withAll';
import {
  deleteBrandSafetyReport as deleteBrandSafetyReportAction,
  getBrandSafetyReports as getBrandSafetyReportsAction,
} from 'redux/brandSafetyReports/actions';

const styles = () => ({
  body: {
    padding: '1rem',
  },
  buttons: {
    display: 'flex',
    marginTop: '2rem',
  },
  btn: {
    marginRight: '0.5rem',
  },
});

const SuccessMessage = () => <React.Fragment>Report has been deleted.</React.Fragment>;

const DeleteModal = ({
  classes,
  open,
  onClose,
  report,
  isLoading,
  deleteBrandSafetyReport,
  getBrandSafetyReports,
}) => {
  const onConfirm = async () => {
    await deleteBrandSafetyReport(report.id, report);
    onClose();
    toast.success(<SuccessMessage />);
    getBrandSafetyReports();
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Delete report"
      width="25rem"
      height="auto"
    >
      <div className={classes.body}>
        <Typography variant="body1">
          Are you sure you want to delete a report for <b>{report.creator}</b>?
        </Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={onConfirm}
          >
            Yes
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={onClose}
          >
            No
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

DeleteModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  report: PropTypes.shape({
    creator: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  deleteBrandSafetyReport: PropTypes.func.isRequired,
  getBrandSafetyReports: PropTypes.func.isRequired,
};

const mapStateToProps = ({ brandSafetyReport }) => ({
  isLoading: brandSafetyReport.loading,
});

const mapDispatchToProps = {
  deleteBrandSafetyReport: deleteBrandSafetyReportAction,
  getBrandSafetyReports: getBrandSafetyReportsAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DeleteModal);
