import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import withModal from '../../../../base/withModal';
import UpdateListModal from '../../../../common/modals/UpdateListModal';
import styles from './styles';

const EditListLink = (props) => {
  const {
    classes,
    closeModal,
    list,
    open,
    openModal,
  } = props;
  if (list) {
    return (
      <React.Fragment>
        <Button
          className={classes.edit__list__button}
          onClick={openModal}
        >
          <SettingsIcon />
          Edit this list
        </Button>
        <UpdateListModal
          open={open}
          closeModal={closeModal}
          list={list}
        />
      </React.Fragment>
    );
  }
  return null;
};

EditListLink.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  list: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.string,
  }),
};

EditListLink.defaultProps = {
  list: null,
};

export default withModal(withStyles(styles)(EditListLink));
