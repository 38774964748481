import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Tab from 'components/base/Tab';

const UpgradeTab = ({ requireUpgrade, ...other }) => {
  if (!requireUpgrade) {
    return <Tab {...other} />;
  }
  return (
    <React.Fragment>
      <Tooltip title="Only available for subscribed users." aria-label="trial-tab">
        <div>
          <Tab disabled={requireUpgrade} {...other} />
        </div>
      </Tooltip>
    </React.Fragment>
  );
};

UpgradeTab.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  requireUpgrade: PropTypes.bool,
};

UpgradeTab.defaultProps = {
  requireUpgrade: false,
};

export default UpgradeTab;
