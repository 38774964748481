export default theme => ({
  navigationItem: {
    padding: '0.6rem 0',
    margin: '0 1rem',
    color: theme.palette.blue.main,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    '&.active': {
      borderBottom: `2px solid ${theme.palette.blue.main}`,
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '&:hover': {
      color: theme.palette.blue.dark,
    },
  },
});
