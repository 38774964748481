export default theme => ({
  root: {
    textAlign: 'center',
    padding: '0.8em',
    boxShadow: theme.customShadows[1],
  },
  title: {
    color: theme.palette.black.light,
    fontSize: '1.4rem',
    marginBottom: 0,
    fontWeight: 400,
  },
});
