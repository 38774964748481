import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = theme => ({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.white.dark,
  },
  loader: {
    padding: '0.4rem',
  },
});

const CircularLoader = ({ isLoading, classes }) => (
  <div className={classes.loaderContainer}>
    { isLoading && <CircularProgress className={classes.loader} /> }
  </div>
);

CircularLoader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CircularLoader);
