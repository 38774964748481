import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const defaultRenderInputComponent = (inputProps) => {
  const {
    inputRef = () => {},
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        fullWidth: true,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      type="text"
      {...other}
    />
  );
};


const getSuggestionValue = suggestion => suggestion.label;


const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
};


class AutoComplete extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
    };
  }

  onChange = (event, { newValue }) => {
    const { onChange } = this.props;
    this.setState({
      value: newValue,
    });
    if (onChange) {
      onChange(newValue);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      value: '',
    });
  };

  onSuggestionSelected = (event, suggestion) => {
    const { onSuggestionSelected } = this.props;
    this.setState({
      value: '',
    });
    onSuggestionSelected(event, suggestion);
  };

  render() {
    const {
      classes,
      suggestions,
      showAfter,
      onSuggestionsFetchRequested,
      onSuggestionSelected,
      inputProps,
      renderInputComponent,
    } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Autosuggest
          renderInputComponent={renderInputComponent}
          inputProps={{
            onChange: this.onChange,
            value,
            ...inputProps,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          suggestions={showAfter && size(value) < showAfter ? [] : suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

AutoComplete.propTypes = {
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  defaultRenderInputComponent: PropTypes.func,
  inputProps: PropTypes.shape({}),
};

AutoComplete.defaultProps = {
  onChange: null,
  locations: null,
  suggestions: [],
  defaultRenderInputComponent,
  inputProps: {},
};

export default withStyles(styles)(AutoComplete);
