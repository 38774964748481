import { get, isEmpty, nth } from 'lodash';
import { PLATFORMS } from 'constants/options';
import { initialState } from 'redux/searchParams/reducers';

// eslint-disable-next-line import/prefer-default-export
export const getInfluencerName = (influencer) => {
  if (!isEmpty(influencer.name)) {
    return influencer.name;
  }
  const instagramName = get(influencer, ['platforms', 'instagram', 'username']);
  if (!isEmpty(instagramName)) {
    return `@${instagramName}`;
  }
  return '';
};

export const getInstagramHandleFromProfilePlatforms = (platforms) => {
  const instagram = platforms.find(platform => platform.platform === PLATFORMS.INSTAGRAM);

  if (isEmpty(instagram)) {
    return null;
  }
  return instagram.handle;
};

export const createProfileLinkFromUsername = (username, platform) => {
  switch (platform) {
    case 'instagram':
      return `https://www.instagram.com/${username}`;
    case 'twitter':
      return `https://www.twitter.com/${username}`;
    case 'facebook':
      return `https://www.facebook.com/${username}`;
    case 'pinterest':
      return `https://www.pinterest.com/${username}/`;
    case 'tiktok':
      return `https://www.tiktok.com/@${username}/`;
    case 'snapchat':
      return `https://www.snapchat.com/add/${username}/`;
    case 'blog':
      return username;
    case 'youtube':
      return username;
    default:
      return null;
  }
};

export const createUsernameDisplay = (username, platform) => {
  switch (platform) {
    case 'instagram':
      return `@${username}`;
    case 'twitter':
      return `@${username}`;
    case 'facebook':
      return `@${username}`;
    case 'pinterest':
      return `@${username}`;
    case 'blog':
      return username;
    case 'youtube':
      return username;
    case 'tiktok':
      return `@${username}`;
    case 'snapchat':
      return username;
    default:
      return null;
  }
};

export const convertFilters = ({ filters: { igFollowerRange, ...rest } }) => ({
  ...initialState,
  igFollowerRange: {
    min: nth(igFollowerRange, 0),
    max: nth(igFollowerRange, 1),
  },
  ...rest,
});
