import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PageTitle from 'components/base/titles/PageTitle';
import PageLayout from 'components/base/Layout/PageLayout';
import Loader from 'components/base/Loader';
import BrandCard from 'components/common/Brands/BrandCard';
import { connect } from 'react-redux';
import { getStatBrands } from 'redux/brand/actions';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { brandProfileUrl } from 'constants/routing';
import { mpPageView } from 'utils/copenhagen';
import styles from './styles';

class Brands extends PureComponent {
  componentDidMount() {
    this.props.getStatBrands();
    mpPageView({ name: 'brands' });
  }

  render() {
    const { classes, isLoading, brands } = this.props;
    return (
      <Loader loading={isLoading}>
        <PageLayout>
          <PageTitle title="Top Brands" />
          <Grid container spacing={3} className={classes.root} alignContent="center">
            {brands.map(b => (
              <Grid item xs={12} md={6}>
                <Link
                  className={classes.verticalLink}
                  to={brandProfileUrl(b.id)}
                >
                  <BrandCard data={b} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </PageLayout>
      </Loader>
    );
  }
}

Brands.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getStatBrands: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ statBrands }) => ({
  isLoading: statBrands.loading,
  brands: statBrands.data,
});

export default connect(mapStateToProps, { getStatBrands })(withStyles(styles)(Brands));
