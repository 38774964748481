import { newReducer } from '../utils';

const initialState = {
  serverDown: false,
  addBrandTooltip: {
    showTooltip: false,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const appReducer = newReducer(initialState);
export const tooltip = newReducer(initialState.addBrandTooltip);

appReducer.method.SET_SERVER_DOWN = (state, { serverDown }) => ({
  serverDown,
});

tooltip.method.SHOW_ADD_BRAND_TOOLTIP = (state, { showTooltip }) => ({
  ...state,
  showTooltip,
});

tooltip.method.UPDATE_TOOLTIP = (state, { tooltipName, showTooltip }) => ({
  ...state,
  [tooltipName]: !!showTooltip,
});
