export default theme => ({
  root: {
    display: 'flex',
    width: '100%',
    marginBottom: '0.8rem',
  },
  stat__stat_column: {
    flexGrow: '1',
  },
  stat__icon_column: {
    marginRight: '0.8rem',
  },
  stat__icon: {
    background: theme.palette.black.light,
    padding: '0.4rem',
    borderRadius: '50%',
    color: theme.palette.black.main,
    overflow: 'visible',
  },
  stat__value: {
    color: theme.palette.white.main,
  },
  stat__label: {
    color: theme.palette.black.light,
  },
});
