import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import styles from './styles';
import { fetchCategories } from '../../../../../redux/lists/actions';

class CategorySelectField extends React.Component {
  componentDidMount() {
    this.props.fetchCategories();
  }

  getOptions = () => {
    const { categories, classes } = this.props;
    return sortBy(categories, 'name').map(({ name }) => (
      <option
        key={name}
        value={name}
        className={classes.category__option}
      >
        {name}
      </option>
    ));
  };

  render() {
    const { classes, input: { value, onChange } } = this.props;
    return (
      <FormControl className={classes.category__select}>
        <InputLabel htmlFor="categories">Category</InputLabel>
        <Select
          value={value}
          name="Category"
          inputProps={{
            name: 'categories',
            id: 'categories',
          }}
          onChange={e => onChange(e.target.value)}
        >
          {this.getOptions()}
        </Select>
      </FormControl>
    );
  }
}

CategorySelectField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({}).isRequired,
  fetchCategories: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    category_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
  })),
};

CategorySelectField.defaultProps = {
  categories: [],
};


const mapStateToProps = ({ lists: { categories: { data, loading } } }) => ({
  categories: data || [],
  loading,
});

const mapDispatchToProps = {
  fetchCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CategorySelectField));
