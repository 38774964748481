import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBrandDetail } from 'redux/brand/actions';
import { withRouter } from 'react-router';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import withQueryParams from 'components/base/withQueryParams';
import withMultipleModals from 'components/base/withMultipleModals';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import ACCOUNT_TAB from 'components/app/AccountSettings/constants';
import { BETA_SIGNUP_TIMEOUT, LOCAL_STORAGE_VALUE } from 'constants/config';
import { home, buildAccountSettingsUrl } from 'constants/routing';

const BETA_SIGNUP_MODAL = 'BETA_SIGNUP_MODAL';
const BETA_SIGNUP_MODAL_UNAUTHORIZED = 'BETA_SIGNUP_MODAL_UNAUTHORIZED';
const UNVERIFIED_MODAL = 'UNVERIFIED_MODAL';

class BetaSignupFlowWrapper extends Component {
  async componentDidMount() {
    const {
      history,
      openModal,
      isAuthenticated,
      showSignup,
      onComponentDidMountFetch,
    } = this.props;

    try {
      await onComponentDidMountFetch();
    } catch (e) {
      if (e.errors.status === 403) {
        if (isAuthenticated) {
          history.push(buildAccountSettingsUrl(ACCOUNT_TAB.SUBSCRIPTION));
          return;
        }
        if (showSignup) {
          openModal(BETA_SIGNUP_MODAL_UNAUTHORIZED);
          return;
        }
        openModal(UNVERIFIED_MODAL);
        return;
      }
    }

    // Open signup modal if param passed
    if (showSignup && !isAuthenticated) {
      openModal(BETA_SIGNUP_MODAL);
      return;
    }

    // If user not authenticated show signup modal
    if (!isAuthenticated
      && !localStorage.getItem(LOCAL_STORAGE_VALUE.BETA_SIGN_UP_COMPLETE)) {
      setTimeout(() => openModal(BETA_SIGNUP_MODAL), BETA_SIGNUP_TIMEOUT);
    }
  }

  onSignupComplete = () => {
    const { closeModal } = this.props;
    closeModal(BETA_SIGNUP_MODAL);
  }

  onSignupCompleteOnUnauthorizedView = () => {
    const { history, closeModal } = this.props;
    // Redirect after .5 second, give some time to display confirmation message.
    closeModal(BETA_SIGNUP_MODAL_UNAUTHORIZED);
    setTimeout(() => history.push(home()), 500);
  }

  unAuthorizedCloseModal = () => {
    const { history, closeModal } = this.props;
    closeModal(BETA_SIGNUP_MODAL_UNAUTHORIZED);
    history.push(home());
  }

  render() {
    const {
      children,
      isLoading,
      loaded,
      closeModal,
      openModal,
      open,
      UnauthorizedModal,
    } = this.props;
    return (
      <React.Fragment>
        <UnauthorizedModal
          open={open[UNVERIFIED_MODAL]}
          closeModal={() => closeModal(UNVERIFIED_MODAL)}
          showSignupModal={() => openModal(BETA_SIGNUP_MODAL_UNAUTHORIZED)}
        />
        <BetaSignupModal
          open={open[BETA_SIGNUP_MODAL]}
          closeModal={() => closeModal(BETA_SIGNUP_MODAL)}
          onSignupComplete={this.onSignupComplete}
        />
        <BetaSignupModal
          hideClose
          open={open[BETA_SIGNUP_MODAL_UNAUTHORIZED]}
          closeModal={this.unAuthorizedCloseModal}
          onSignupComplete={this.onSignupCompleteOnUnauthorizedView}
        />
        <Loader loading={isLoading} loaded={loaded}>
          {children}
        </Loader>
      </React.Fragment>
    );
  }
}

BetaSignupFlowWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  UnauthorizedModal: PropTypes.element.isRequired,
  history: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onComponentDidMountFetch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brandId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const queryParamsToProps = ({ showSignup }) => ({
  showSignup,
});

export default withAll(
  connect(mapStateToProps, { getBrandDetail }),
  withMultipleModals([BETA_SIGNUP_MODAL, UNVERIFIED_MODAL, BETA_SIGNUP_MODAL_UNAUTHORIZED]),
  withRouter,
  withQueryParams(queryParamsToProps),
)(BetaSignupFlowWrapper);
