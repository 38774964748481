import { request } from 'lib/http';
import API from 'constants/api';
import { includes, pick, identity, isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { LOCAL_STORAGE_VALUE } from 'constants/config';
import { v4 as uuidV4 } from 'uuid';


export const MP_LIB_VERSION = '2.4.2';
export const MP_EVENTS = {
  USER_LOGIN: 'user_login',
  USER_ACCOUNT_CREATED: 'user_account_created',
  CLICK_MAIN_NAVIGATION: 'click__main_navigation',
  CLOSE_SIGNUP_MODAL: 'close__signup_modal',
  OPEN_SIGNUP_MODAL: 'open__signup_modal',
  CLICK_SIGNUP_HEADER_BTN: 'click__signup_header_btn',
  CLICK_TAB: 'click__tab',
  CLICK_REPORT_SIGNUP: 'click__report_signup',
  VIEW_PAGE: 'view__page',
  DELETE_COMPETITIVE_REPORT: 'delete__competitive_report',
  CREATE_COMPETITIVE_REPORT: 'create__competitive_report',
  UPDATE_COMPETITIVE_REPORT: 'update__competitive_report',
  EXPORT_COMPETITIVE_REPORT: 'export__competitive_report',
  REFRESH_COMPETITIVE_REPORT: 'refresh__competitive_report',
  SEARCH_BRAND_AUTOCOMPLETE: 'search__brand_auto_complete',
  CREATE_BRAND_SAFETY_REPORT: 'create__brand_safety_report',
  REFRESH_BRAND_SAFETY_REPORT: 'refresh__brand_safety_report',
  DELETE_BRAND_SAFETY_REPORT: 'delete__brand_safety_report',
  SEM_LANDING_PAGE_SIGNUP_TOP: 'sem_landing_page__signup_top',
  SEM_LANDING_PAGE_TRY_FOR_FREE_MIDDLE: 'sem_landing_page__try_for_free_middle',
  SEM_LANDING_PAGE_TRY_FOR_FREE_BOTTOM: 'sem_landing_page__try_for_free_bottom',
};

const cleanDict = obj => pick(obj, identity);

export const browser = () => {
  try {
    const userAgent = window.navigator.userAgent || '';
    const vendor = window.navigator.vendor || '';
    const opera = window.opera || '';
    if (opera) {
      if (includes(userAgent, 'Mini')) {
        return 'Opera Mini';
      }
      return 'Opera';
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
      return 'BlackBerry';
    } else if (includes(userAgent, 'FBIOS')) {
      return 'Facebook Mobile';
    } else if (includes(userAgent, 'Chrome')) {
      return 'Chrome';
    } else if (includes(userAgent, 'CriOS')) {
      return 'Chrome iOS';
    } else if (includes(vendor, 'Apple')) {
      if (includes(userAgent, 'Mobile')) {
        return 'Mobile Safari';
      }
      return 'Safari';
    } else if (includes(userAgent, 'Android')) {
      return 'Android Mobile';
    } else if (includes(userAgent, 'Konqueror')) {
      return 'Konqueror';
    } else if (includes(userAgent, 'Firefox')) {
      return 'Firefox';
    } else if (includes(userAgent, 'MSIE') || includes(userAgent, 'Trident/')) {
      return 'Internet Explorer';
    } else if (includes(userAgent, 'Gecko')) {
      return 'Mozilla';
    }
  } catch (e) {
    window.console.error(e);
  }
  return '';
};

export const os = () => {
  try {
    const a = window.navigator.userAgent;
    if (/Windows/i.test(a)) {
      if (/Phone/.test(a)) {
        return 'Windows Mobile';
      }
      return 'Windows';
    } else if (/(iPhone|iPad|iPod)/.test(a)) {
      return 'iOS';
    } else if (/Android/.test(a)) {
      return 'Android';
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
      return 'BlackBerry';
    } else if (/Mac/i.test(a)) {
      return 'Mac OS X';
    } else if (/Linux/.test(a)) {
      return 'Linux';
    }
  } catch (e) {
    window.console.error(e);
  }
  return '';
};

export const device = () => {
  const userAgent = window.navigator.userAgent || '';
  try {
    if (/iPad/.test(userAgent)) {
      return 'iPad';
    } else if (/iPod/.test(userAgent)) {
      return 'iPod Touch';
    } else if (/iPhone/.test(userAgent)) {
      return 'iPhone';
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
      return 'BlackBerry';
    } else if (/Windows Phone/i.test(userAgent)) {
      return 'Windows Phone';
    } else if (/Android/.test(userAgent)) {
      return 'Android';
    }
  } catch (e) {
    window.console.error(e);
  }
  return '';
};

export const referringDomain = () => {
  const referrer = document.referrer || '';
  try {
    const split = referrer.split('/');
    if (split.length >= 3) {
      return split[2];
    }
  } catch (e) {
    window.console.error(e);
  }
  return '';
};

export const pushSignUpEvent = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'signUp',
  });
};

export const getMixpanelAnonId = () => {
  const mpCookie = Cookies.get(LOCAL_STORAGE_VALUE.MIXPANEL);
  if (!isEmpty(mpCookie)) {
    return mpCookie;
  }
  Cookies.set(LOCAL_STORAGE_VALUE.MIXPANEL, uuidV4());
  return Cookies.get(LOCAL_STORAGE_VALUE.MIXPANEL);
};

export const mpTrack = (eventName, properties = {}) => {
  try {
    if (!eventName) {
      window.console.error('mpTrack: missing eventName');
      return;
    }

    /* eslint no-param-reassign: 'error' */
    properties.$browser = browser();
    properties.$os = os();
    properties.$device = device();
    properties.$referrer = document.referrer;
    properties.$referring_domain = referringDomain();
    properties.$screen_height = window.screen.height;
    properties.$screen_width = window.screen.width;
    properties.mp_lib = 'web';
    properties.$lib_version = MP_LIB_VERSION;
    properties.$location = window.location.href;
    properties.$anon_id = getMixpanelAnonId();

    cleanDict(properties);
    request('post', API.CEVICHE, {
      data: {
        event_name: eventName,
        properties,
      },
    }).then(() => {

    }).catch((response = {}) => {
      window.console.error(response);
    });
  } catch (e) {
    window.console.error(e);
  }
};

export const mpPageView = props => mpTrack(MP_EVENTS.VIEW_PAGE, props);
