import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const titleCase = str => str.toLowerCase().split(' ').map(word =>
  word.replace(word[0], word[0].toUpperCase())).join(' ');

const getTopLocationsString = (topLocations) => {
  const titleCased = topLocations.map(tl => titleCase(tl));
  return titleCased.join(', ');
};

class TopCountriesChart extends Component {
  generateBarBackground = () => {
    const { geoData } = this.props;
    return geoData.map(g => 1 - (g.value / 100));
  }

  generateBarChartColors = () => {
    const { geoData } = this.props;
    return geoData.map(g => `rgba(31, 164, 231, ${(g.value / 100) + 0.2})`);
  }

  generateLabels = () => {
    const { geoData } = this.props;
    return geoData.map(g => titleCase(g.name.replace('_', ' ')));
  }

  transformValues = () => {
    const { geoData } = this.props;
    return geoData.map(g => g.value / 100);
  }

  render() {
    const {
      theme, classes, geoData, locationTeaser,
    } = this.props;
    const data = {
      labels: this.generateLabels(),
      datasets: [{
        backgroundColor: this.generateBarChartColors(),
        barPercentage: 1,
        borderColor: theme.palette.border.main,
        borderWidth: 1,
        data: this.transformValues(),
        datalabels: {
          display: true,
          align: 'end',
          anchor: 'end',
          font: { size: 14 },
          formatter: value => `${(value * 100).toFixed(2)}%`,
        },
      }],
    };
    const options = {
      layout: {
        padding: {
          top: 30,
        },
      },
      legend: false,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      responsive: true,
      scales: {
        xAxes: [{
          display: true,
          drawTicks: false,
          gridLines: { lineWidth: 0 },
          stacked: true,
        }],
        yAxes: [{
          display: false,
          stacked: true,
        }],
      },
      title: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };
    return (
      <div className={classes.root}>
        <Bar height={50} data={data} options={options} redraw />
        {geoData.map(g => (
          g.topLocations && g.topLocations.length ?
            <div
              key={g.name}
              className={locationTeaser ? classes.topLocationBlurred : classes.topLocation}
            >
              <Typography variant="body2">Top locations for {titleCase(g.name.replace('_', ' '))}:</Typography>
              <Typography variant="body1" className={classes.countries}>
                {getTopLocationsString(g.topLocations)}
              </Typography>
            </div> :
            null
        ))}
      </div>
    );
  }
}

TopCountriesChart.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  locationTeaser: PropTypes.bool.isRequired,
  geoData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    topLocations: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
};

export default withStyles(styles, { withTheme: true })(TopCountriesChart);
