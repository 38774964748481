export default theme => ({
  input: {
    fontSize: '0.8rem',
    marginTop: '1em',
  },
  submitButton: {
    marginTop: '1em',
    textTransform: 'none',
    color: 'white',
    backgroundColor: theme.palette.green.main,
  },
});
