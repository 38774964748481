export default theme => ({
  root: {
    width: '290px',
    backgroundColor: theme.palette.black.main,
    color: theme.palette.white.main,
  },
  profile__name: {
    color: theme.palette.white.main,
    fontSize: '1rem',
    lineHeight: 'normal',
    marginBottom: '0.4rem',
  },
  profile__header: {
    padding: '1.4rem',
    borderBottom: `1px solid ${theme.palette.black.dark}`,
  },
  profile__avatar: {
    width: '4rem',
    height: '4rem',
    marginBottom: '0.6rem',
  },
  profile__location: {
    marginBottom: '0.4rem',
    color: theme.palette.black.light,
    fontWeight: 600,
  },
  location__icon: {
    verticalAlign: 'middle',
    marginLeft: '-0.3rem',
    fontSize: '1.2rem',
  },
  btn_add_to_list_menu: {
    width: '100%',
  },
});
