export default theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.white.dark,
    paddingBottom: '2rem',
  },
  profile__title: {
    fontSize: '1.3rem',
    fontWeight: '600',
    color: theme.palette.black.main,
    marginBottom: '0.4rem',
  },
  profile__bio: {
    fontSize: '1rem',
    color: theme.palette.black.dark,
    marginBottom: '0.6rem',
  },
  profile__container: {
    padding: '1.8rem',
    backgroundColor: theme.palette.white.main,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  profile__items: {
    display: 'flex',
    marginBottom: '0.6rem',
    margin: 0,
    padding: 0,
  },
  profile__item: {
    marginLeft: '1.8rem',
    color: theme.palette.black.light,
    '&:first-child': {
      marginLeft: 0,
      listStyle: 'none',
    },
  },
  profile__item__label: {
    fontSize: '1rem',
    color: theme.palette.black.light,
    display: 'inline-block',
    paddingRight: '0.2rem',
  },
  profile__item__value: {
    fontSize: '1rem',
    color: theme.palette.black.dark,
    fontWeight: 600,
    display: 'inline-block',
  },
  profile__additional_data: {
    display: 'flex',
    marginBottom: '0.4rem',
  },
  profile__h5: {
    fontSize: '1rem',
    color: theme.palette.black.dark,
  },
  profile__social_links: {
    width: '70%',
  },
  profile__email_button: {
    paddingTop: '2vh',
  },
  tab__container: {
    boxShadow: theme.customShadows[1],
    borderBottom: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.white.main,
    padding: '0 1.8rem',
  },
  tab__item: {
    textTransform: 'none',
    fontSize: '1rem',
  },
  tabs__content__container: {
    padding: '3rem 1.8rem',
  },
  tabs__content__container_empty: {
    padding: '1rem 1.8rem',
    height: '30vh',
  },
  tabs: {
    root: {
      boxShadow: theme.customShadows[1],
      borderBottom: `1px solid ${theme.palette.border.main}`,
      backgroundColor: theme.palette.white.main,
    },
  },
  push: {
    height: theme.sizes.layout.footer.height.desktop,
    marginTop: '1rem',
  },
});
