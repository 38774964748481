import moment from 'moment';
import { SHOW_STATES_FOR_COUNTRIES } from 'constants/config';
import { concat, includes, isEmpty, slice, mean, sum, chunk, get, keys, values } from 'lodash';
import { PLATFORMS } from 'constants/options';

export const formatDate = ms => moment(ms).format('DD MMM YYYY');

export const formatLocationFromLocationObject = (location) => {
  if (includes(SHOW_STATES_FOR_COUNTRIES, location.countryAbbreviation)) {
    return `${location.city}, ${location.stateAbbreviation}, ${location.countryAbbreviation}`;
  }
  return `${location.city}, ${location.countryAbbreviation}`;
};

export const combineHashtagAndMentions = (hashtags, mentions) => {
  const formatedMentions = mentions.map(mention => ({
    ...mention,
    text: `@${mention.text}`,
  }));
  const formatedHashtags = hashtags.map(tag => ({
    ...tag,
    text: `#${tag.text}`,
  }));
  return concat(formatedHashtags, formatedMentions);
};

export const socialLinkFromUsernameAndPlatform = (username, platform) => {
  if (platform === PLATFORMS.INSTAGRAM) {
    return `https://instagram.com/${username}`;
  }
  return null;
};

export const getPercentageChange = (newNumber, originalNumber) => {
  const changeValue = newNumber - originalNumber;
  return (changeValue / originalNumber) * 100;
};

export const formatPercentageChange = number => number.toFixed(2);

export const getTrend = (data, offset = 7, rollingWindowMultiplier = 1) => {
  const rollingWindow = (offset * rollingWindowMultiplier) + offset;
  if (isEmpty(data) || data.length < rollingWindow) {
    return 0;
  }

  const newDelta = slice(data, -offset);
  const oldDelta = slice(data, -rollingWindow, -offset);
  const change = mean(newDelta) - mean(oldDelta);
  return change !== 0.0 && mean(oldDelta) !== 0.0 ? change / mean(oldDelta) : 0;
};

export const groupTimelineDataByMonthForBarChart = (dateList, valueList) => {
  const dateValues = dateList.reduce((acc, cur, i) => {
    const dateKey = moment(cur).format('MMM YY');
    return {
      ...acc,
      [dateKey]: get(acc, dateKey, 0) + valueList[i],
    };
  }, {});

  const yValues = values(dateValues);
  const maxY = Math.max(...yValues.map(o => o));
  let maxList = Array.apply(null, Array(yValues.length)); // eslint-disable-line prefer-spread
  maxList = maxList.map((x, i) => maxY - yValues[i]);
  return { dates: keys(dateValues), values: yValues, maxList };
};


export const partitionDataByWeek = data => chunk(data.reverse(), 7).map(d => sum(d)).reverse();

