export default theme => ({
  brandSafetyIconSafe: {
    color: theme.palette.green.main,
    fontSize: '1.8rem',
  },
  brandSafetyIconIssues: {
    color: theme.palette.pink.main,
    fontSize: '1.8rem',
  },
  brandSafetyIconAdd: {
    color: theme.palette.blue.main,
    fontSize: '1.8rem',
  },
  brandSafetyIconDefault: {
    color: theme.palette.black.light,
    fontSize: '1.8rem',
  },
  brandSafetyIconWrapper: {
    position: 'absolute',
    bottom: '-7%',
    left: '55%',
  },
  brandSafetyIconBackground: {
    backgroundColor: theme.palette.white.iconBacground,
    padding: '6px',
  },
});
