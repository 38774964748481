import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import ShareIcon from '@material-ui/icons/Share';
import { identity } from 'lodash';
import { connect } from 'react-redux';
import ButtonMenu from 'components/base/ButtonMenu';
import withModal from 'components/base/withModal';
import withAll from 'components/base/withAll';
import withOnBoarding from 'components/common/OnBoarding/withOnBoarding';
import withPermission from 'components/common/withPermission';
import { updateList as updateListAction } from 'redux/lists/actions';
import { toast } from 'react-toastify';
import styles from './styles';
import ShareListCard from './ShareListCard';

const ShareList = ({
  classes,
  open,
  isAuthenticated,
  list,
  updateList,
}) => {
  const onSubmit = async (data) => {
    if (Object.keys(data).length === 0) return;

    await updateList(
      list.uid,
      { is_public: true, shared_to: data.emails.split(','), shared_message: data.message },
      {},
    );
    toast.success(`"${list.name}" has been shared.`);
  };

  const getMenuButton = () => (
    <React.Fragment>
      <ShareIcon className={classes.shareListIcon} /> Share List
    </React.Fragment>
  );

  const getMenuChildren = () => (
    <ShareListCard closeOnClick onClick={onSubmit} />
  );

  return (
    <ButtonMenu
      openable={!open && isAuthenticated}
      buttonChildren={getMenuButton()}
      menuChildren={[getMenuChildren()]}
      buttonProps={{
        variant: 'contained',
        id: 'btn_share_list',
        className: classes.shareListButton,
      }}
      menuProps={{
        disableAutoFocusItem: true,
      }}
    />
  );
};


ShareList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  list: PropTypes.shape({}).isRequired,
  updateList: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = {
  updateList: updateListAction,
};

export default withAll(
  withOnBoarding,
  withPermission('lists', null, identity, true),
  withStyles(styles),
  withModal,
  connect(mapStateToProps, mapDispatchToProps),
)(ShareList);
