import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { withStyles } from '@material-ui/styles';
import Card from 'components/base/Card';
import { CardContent } from '@material-ui/core';
import LineBasic from 'components/base/charts/Line/LineBasic';
import AvgStatCtrl from 'components/common/FeaturedVerticals/AvgStat';
import BrandTitle from './BrandTitle';

const styles = () => ({
  root: {
    width: '100%',
  },
});

class BrandCard extends React.Component {
  getOptions = () => ({
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: false,
        },
      }],
      xAxes: [{
        display: true,
        ticks: {
          autoSkipPadding: 14,
        },
        gridLines: {
          display: true,
          drawOnChartArea: false,
        },
      }],
    },
  });

  transformData = (data) => {
    const { theme } = this.props;
    return {
      labels: data.dates.filter((element, index) => (index % 7 === 0)).map(date => moment(date).format('MM/DD/YY')),
      datasets: [{
        data: data.weeklyPostCounts,
        backgroundColor: 'transparent',
        borderWidth: 3,
        borderColor: theme.palette.green.main,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      }],
    };
  };

  render() {
    const { data, classes } = this.props;
    return (
      <Card className={classes.root}>
        <BrandTitle data={data} />
        <CardContent>
          <LineBasic
            data={this.transformData(data)}
            options={this.getOptions()}
            rawData={data.weeklyPostCounts}
          />
        </CardContent>
        <AvgStatCtrl statList={data.weeklyPostCounts} maDays={2} />
      </Card>
    );
  }
}

BrandCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    weeklyPostCounts: PropTypes.arrayOf(PropTypes.number),
    dates: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(BrandCard);
