import { matchPath } from 'react-router';

export const home = () => '/';

export const landingPageFreeInfluencerSearchTool = () => '/free-influencer-search-tool';

export const privacyPolicy = () => '/privacy-policy';
export const termsOfUse = () => '/terms-of-use';

export const login = () => '/login';

export const register = () => '/signup';

export const pricing = () => '/pricing';

export const accountSettingsUrl = () => '/settings/:tab';
export const buildAccountSettingsUrl = (tab = 0) => `/settings/${tab}`;
export const buildBrandRequestUrl = () => '/brand-request';

export const forgotPassword = () => '/forgot-password';

export const setupPassword = () => '/setup-password';

export const creatorRegistration = () => '/creator-registration';

export const verifyEmailUrl = () => '/verify-email';

export const changePassword = () => '/change-password';

export const searchUrl = () => '/search';

export const featuredUrl = () => '/featured';

export const listsUrl = () => '/lists';

export const verticalsUrl = () => '/industries';
export const brandsUrl = () => '/brands';
export const brandUrl = () => '/brands/:brandId';
export const brandProfileUrl = brandId => `/brands/${brandId}/`;

export const competitiveReportUrl = () => '/reports/:reportId';
export const buildCompetitiveReportUrl = reportId => `/reports/${reportId}`;
export const competitiveReportsUrl = () => '/reports';
export const competitiveReportCreateUrl = () => '/reports/create';
export const competitiveReportUpdateUrl = () => '/reports/update/:reportId';
export const buildCompetitiveReportUpdateUrl = reportId => `/reports/update/${reportId}`;

export const verticalDetailUrl = () => '/industries/:verticalId';
export const createVerticalDetailUrl = slug => `/industries/${slug}`;

export const listUrl = listId => `/list/${listId}`;

export const influencerProfileUrl = influencerId => `/influencer/${influencerId}`;

export const onPage = (location, url) => !!matchPath(location.pathname, url);

export const nonExisting = () => '/404';

export const brandSafetyReportUrl = () => '/brand-safety-reports/:reportId';
export const brandSafetyReportsUrl = () => '/brand-safety-reports';
export const buildBrandSafetyReportsUrl = reportId => `/brand-safety-reports/${reportId}`;

export const creatorListeningUrl = () => '/creator-listening';
export const keyPhraseMonitorUrl = () => '/creator-listening/:monitorId';
export const buildKeyPhraseMonitorUrl = monitorId => `/creator-listening/${monitorId}`;
