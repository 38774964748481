import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const FooterItem = ({ classes, children }) => (
  <span className={classes.root}>
    {children}
  </span>
);

FooterItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default withStyles(styles)(FooterItem);
