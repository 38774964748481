import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { STEPS_INDXES } from 'constants/onboarding';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import withInfluencerSearch from 'components/common/withInfluencerSearch';
import Tooltip from './Tooltip';

class LocationTooltip extends React.Component {
  handleSearch = () => {
    const { searchParams } = this.props;
    searchParams.page = 0;
    this.props.getInfluencers(searchParams);
    this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SEARCH_TAGS });
  }

  render() {
    return <Tooltip submitText="Search" handleSubmit={this.handleSearch} {...this.props} />;
  }
}


LocationTooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  getInfluencers: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    searchParams: state.searchParams,
  }),
  { updateOnBoarding },
)(withInfluencerSearch(LocationTooltip));
