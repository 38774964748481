import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import InputRange from 'components/base/InputRange';
import SearchSection from '../SearchSection';
import CheckboxCircle from '../../../../common/CheckboxCircle';
import { setSearchParam } from '../../../../../redux/searchParams/actions';
import styles from './styles';

const AVAILABLE_FOLLOWER_MIN = 10000;
const AVAILABLE_FOLLOWER_MAX = 1000000;
const FOLLOWER_SEARCH_STEP = 10000;

class FollowerFilter extends React.PureComponent {
  generateFollowerCountDisplayLabel = () => {
    const { value } = this.props;
    const maxNumber = value.max === AVAILABLE_FOLLOWER_MAX ? '+1MM' : numeral(value.max).format('0,0');
    return (
      <Typography variant="body1">
        Between {numeral(value.min).format('0,0')}&nbsp; to {maxNumber} followers.
      </Typography>
    );
  };

  selectRange = (event, checked) => {
    const { searchParams: { platforms }, platform } = this.props;
    if (checked) {
      if (!platforms.includes(platform)) {
        platforms.push(platform);
        this.props.setSearchParam('platforms', platforms);
      }
    } else {
      const i = platforms.indexOf(platform);
      if (i > -1 && platforms.length > 1) {
        platforms.splice(i, 1);
        this.props.setSearchParam('platforms', platforms);
      }
    }
  };

  render() {
    const {
      classes,
      platform,
      name,
      title,
      icon,
      value,
      onChange,
      searchParams: { platforms },
      inputMin,
      inputMax,
      inputStep,
      tooltip,
    } = this.props;
    const platformSelected = platforms.includes(platform);

    return (
      <SearchSection id={name} title={title} icon={icon} tooltip={tooltip}>
        <div className={classes.checkboxRow}>
          <div>
            <CheckboxCircle
              size="1.6rem"
              className={classes.checkbox}
              onChange={this.selectRange}
              checked={platformSelected}
            />
          </div>
          <div className={classes.inputWrapper}>
            <InputRange
              minValue={inputMin}
              maxValue={inputMax}
              step={inputStep}
              disabled={!platformSelected}
              name={name}
              value={value}
              onChange={onChange}
            />
            {this.generateFollowerCountDisplayLabel()}
          </div>
        </div>
      </SearchSection>
    );
  }
}

FollowerFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  platform: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  setSearchParam: PropTypes.func.isRequired,
  inputMin: PropTypes.number,
  inputMax: PropTypes.number,
  inputStep: PropTypes.number,
  tooltip: PropTypes.string,
};

FollowerFilter.defaultProps = {
  inputMin: AVAILABLE_FOLLOWER_MIN,
  inputMax: AVAILABLE_FOLLOWER_MAX,
  inputStep: FOLLOWER_SEARCH_STEP,
  tooltip: 'Find Creators that fall within your goals. For example, do you want to increase engagement,' +
    ' improve awareness, or build a reputation?',
};

export default connect(
  state => ({
    searchParams: state.searchParams,
  }),
  { setSearchParam },
)(withStyles(styles)(FollowerFilter));
