import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import HomeTitle from 'components/base/titles/HomeTitle';
import HomeSubTitle from 'components/base/titles/HomeSubTitle';
import keywordSearch from 'images/home/keywordsearch.gif';
import Button from 'components/base/Button';
import BulleitItem from 'components/base/sections/BulleitItem';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import { searchUrl } from 'constants/routing';

const styles = theme => ({
  root: {
    marginBottom: '4rem',
    position: 'relative',
    marginTop: '4rem',
  },
  container: {},
  title: {
    justifyContent: 'left',
  },
  image: {
    width: '100%',
    borderRadius: '0.4rem',
    boxShadow: theme.customShadows[3],
    overflow: 'hidden',
    '& > img': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '75%',
      minWidth: '40rem',
    },
  },
  bullietContainer: {
    marginBottom: '1rem',
  },
});

const InfluencerDiscovery = ({ classes, history }) => (
  <div className={classes.root}>
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={8}>
        <Grid item md={6}>
          <HomeTitle
            title={<React.Fragment>Discover The Perfect Creators<br /> For Your Campaign</React.Fragment>}
            className={classes.title}
            titleId="influencerDiscoveryH1"
          />
          <HomeSubTitle titleId="influencerDiscoveryH2">
            Surface relevant creators across social platforms, sort and filter based on custom criteria
             and create your own custom creator lists. Search and sort influencers with the following:
          </HomeSubTitle>
          <Grid container className={classes.bullietContainer} id="influencerDiscoveryBullietContainer">
            <BulleitItem>Brand mentions and hashtags</BulleitItem>
            <BulleitItem>AI image recognition labels</BulleitItem>
            <BulleitItem>Audience demographics</BulleitItem>
            <BulleitItem>Geography</BulleitItem>
            <BulleitItem>Following size</BulleitItem>
            <BulleitItem>AI based vertical categorization</BulleitItem>
            <BulleitItem>Gender</BulleitItem>
            <BulleitItem>Social platforms</BulleitItem>
            <BulleitItem>& More..</BulleitItem>
          </Grid>
          <Button
            onClick={() => history.push(searchUrl())}
            color="primary"
            variant="contained"
            size="large"
            id="influencerDiscoveryBtnSearchNow"
          >
            Search Now
          </Button>
        </Grid>
        <Grid item md={6}>
          <div className={classes.image}>
            <img
              src={keywordSearch}
              alt="search"
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  </div>
);

InfluencerDiscovery.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withAll(
  withStyles(styles),
  withRouter,
)(InfluencerDiscovery);
