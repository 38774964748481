import React from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
} from '@material-ui/core';
import Card from 'components/base/Card';
import H6 from 'components/base/titles/CardHeading/H6';
import { withStyles } from '@material-ui/styles';
import RelatedBrandTable from './RelatedBrandTable';

const styles = () => ({
  root: {},
  title: {
    paddingBottom: 0,
  },
});

const RelatedBrands = ({ classes }) => (
  <Card className={classes.root}>
    <CardContent className={classes.title}>
      <H6 align="left">Related Brands</H6>
      <RelatedBrandTable />
    </CardContent>
  </Card>
);

RelatedBrands.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(RelatedBrands);
