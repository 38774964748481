import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { CardContent, Grid } from '@material-ui/core';
import Card from 'components/base/Card';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';
import H6 from 'components/base/titles/CardHeading/H6';
import Post from 'components/common/CompetitiveReport/TopContent/Post';
import styles from './styles';


const MonitorTopPosts = ({ classes, posts }) => (
  <Card>
    <CardContent>
      <H6>
        <LabelWithInfoIcon
          labelText="Top Content"
          tooltip="Top performing content mentioning the #hashtag(s) or @mention(s)."
        />
      </H6>
      {isEmpty(posts) && (
        <Alert severity="warning">
              We don&apos;t have sufficient data for this monitor. Please try again later.
        </Alert>
      )}
      <Grid container spacing={2}>
        {posts.map(p => (
          <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
            <Post data={p} />
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

MonitorTopPosts.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withAll(withStyles(styles))(MonitorTopPosts);
