export default () => ({
  body: {
    padding: '1rem',
  },
  buttons: {
    display: 'flex',
    marginTop: '2rem',
  },
  btn: {
    marginRight: '0.5rem',
  },
});
