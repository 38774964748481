import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import cx from 'classnames';
import styles from './styles';
import BaseModal from '../BaseModal';

class FormModal extends React.PureComponent {
  componentDidMount() {
    const { initial, initialize } = this.props;
    if (initial) {
      initialize(initial);
    }
  }

  render() {
    const {
      children,
      classes,
      handleSubmit,
      submitting,
      onClose,
      open,
      submitText,
      hasCloseButton,
      closeText,
      title,
      width,
      height,
    } = this.props;
    return (
      <BaseModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.formModal}
        open={open}
        onClose={onClose}
        title={title}
        width={width}
        height={height}
      >
        <form className={cx(classes.form, 'form')} onSubmit={handleSubmit}>
          <div className={classes.form__content}>
            {children}
          </div>
          <div className={classes.footer}>
            {submitting ?
              <CircularProgress /> :
              <Button
                className={classes.submit__button}
                variant="contained"
                color="primary"
                type="submit"
              >
                {submitText}
              </Button>
            }
            {hasCloseButton ?
              <Button
                className={classes.close__button}
                variant="contained"
                color="primary"
                type="close"
              >
                {closeText}
              </Button> : ''
            }
          </div>
        </form>
      </BaseModal>
    );
  }
}

FormModal.propTypes = {
  children: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initial: PropTypes.shape({}),
  initialize: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitText: PropTypes.string,
  closeText: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  submitting: PropTypes.bool,
};

FormModal.defaultProps = {
  initial: null,
  width: '90%',
  height: '60%',
  submitting: false,
};

FormModal.defaultProps = {
  submitText: 'Submit',
  closeText: 'Close',
  hasCloseButton: false,
};

export default withStyles(styles)(reduxForm({
})(FormModal));
