import API from 'constants/api';
import { request } from 'lib/http';
import { loadStripe } from '@stripe/stripe-js/pure';
import { toast } from 'react-toastify';


const stripePromise = loadStripe(process.env.STRIPE_KEY);

const redirectToCheckout = async (email, subscriptionId, redirectUrl) => {
  let error = '';
  const stripe = await stripePromise;
  const response = await request('post', API.SECURITY_CHECKOUT_SESSION, {
    data: {
      email,
      subscription_id: subscriptionId,
      redirect_url: redirectUrl,
    },
  }).catch((errorResponse) => {
    // eslint-disable-next-line prefer-destructuring
    error = errorResponse.response.data.error;
  });

  if (error) {
    toast.error(error);
    return;
  }

  // When the customer clicks on the button, redirect them to Checkout.
  const result = await stripe.redirectToCheckout({
    sessionId: response.id,
  });
  if (result.error) {
    toast.error(result.error.message);
  }
};

export default redirectToCheckout;
