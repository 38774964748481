import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PageLayout from 'components/base/Layout/PageLayout';
import CompetitiveReportCreateUpdate from 'components/common/CompetitiveReportCreateUpdate/CreateReportPublic';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PageTitle from 'components/base/titles/PageTitle';
import { Typography } from '@material-ui/core';
import { mpPageView } from 'utils/copenhagen';
import PromoBanner from './PromoBanner';

const styles = theme => ({
  title: {
    justifyContent: 'center',
    margin: '1rem 0 0.4rem 0',
  },
  textFree: {
    display: 'inline-block',
    padding: '0.4rem',
    fontWeight: 600,
    margin: '0 0.2rem',
    borderBottom: `2px solid ${theme.palette.pink.main}`,
  },
  subTitle: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.black.light,
    marginBottom: '3rem',
  },
});

const Bulleit = ({ children }) => (
  <div><CheckCircleIcon /> {children}</div>
);

Bulleit.propTypes = {
  children: PropTypes.string.isRequired,
};

class CompetitiveReportsCreatePublic extends React.Component {
  componentDidMount() {
    mpPageView({ name: 'create_report' });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <PromoBanner />
        <PageLayout>
          <PageTitle
            title={<React.Fragment>Create a <span className={classes.textFree}>FREE</span> Report</React.Fragment>}
            className={classes.title}
          />
          <Typography className={classes.subTitle} variant="body">
            Select brands below to compare your brand with.
          </Typography>
          <CompetitiveReportCreateUpdate />
        </PageLayout>
      </div>
    );
  }
}

CompetitiveReportsCreatePublic.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CompetitiveReportsCreatePublic);
