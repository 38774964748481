import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  CardContent,
} from '@material-ui/core';
import Card from 'components/base/Card';
import { ma } from 'moving-averages';
import { slice } from 'lodash';
import * as moment from 'moment';
import LineBasic from 'components/base/charts/Line/LineBasic';
import AvgStat from 'components/common/FeaturedVerticals/AvgStat';
import H6 from 'components/base/titles/CardHeading/H6';

const styles = theme => ({
  root: {
    cursor: 'pointer',
    width: '100%',
    position: 'relative',
    '&:hover': {
      boxShadow: theme.customShadows[2],
    },
  },
  title: {
    fontColor: theme.palette.black.main,
    textAlign: 'left',
    marginBottom: 0,
    paddingBottom: 0,
  },
  statGrid: {
    marginTop: 0,
    marginLeft: '1rem',
    marginBottom: '1rem',
  },
  chart: {},
});

const MA_DAYS = 14;

class VerticalCard extends React.Component {
  getOptions = () => ({
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: false,
        },
      }],
      xAxes: [{
        display: true,
        ticks: {
          autoSkipPadding: 14,
        },
        gridLines: {
          display: true,
          drawOnChartArea: false,
        },
      }],
    },
  });

  transformData = (data) => {
    const { theme } = this.props;
    return {
      labels: slice(data.dates.map(value => moment(value).format('MMM DD')), MA_DAYS),
      datasets: [{
        data: slice(ma(data.dailyPostCounts, MA_DAYS), MA_DAYS),
        backgroundColor: 'transparent',
        borderColor: theme.palette.green.main,
        borderWidth: 3,
        pointRadius: 0,
      }],
    };
  };

  render() {
    const { data, classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardContent>
          <H6 align="left">{data.vertical}</H6>
          <div className={classes.chart}>
            <LineBasic
              className={classes.chart}
              data={this.transformData(data)}
              options={this.getOptions()}
              rawData={data.dailyPostCounts}
            />
          </div>
        </CardContent>
        <AvgStat statList={data.dailyPostCounts} />
      </Card>
    );
  }
}

VerticalCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(VerticalCard);
