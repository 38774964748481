import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Tooltip from 'components/base/Tooltip';
import PointIcon from '@material-ui/icons/FiberManualRecord';
import { isEmpty } from 'lodash';

const styles = theme => ({
  root: {
    display: 'inline',
    color: p => p.data.color,
    paddingRight: '0.2rem',
  },
  point: {
    verticalAlign: 'middle',
    marginRight: '-0.3rem',
  },
  label: {
    fontSize: '0.8rem',
    verticalAlign: 'middle',
    color: theme.palette.black.dark,
    fontWeight: 400,
  },
});

const LegendItem = ({ classes, data }) => (
  <div className={classes.root}>
    {!isEmpty(data.tooltip) && (
      <Tooltip title={data.tooltip.title} arrow>
        <div>
          <PointIcon className={classes.point} /> <span className={classes.label}>{data.title}</span>
        </div>
      </Tooltip>
    )}
    {isEmpty(data.tooltip) && (
      <React.Fragment>
        <PointIcon className={classes.point} /> <span className={classes.label}>{data.title}</span>
      </React.Fragment>
    )}
  </div>
);

LegendItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
    tooltip: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};

export default withStyles(styles)(LegendItem);
