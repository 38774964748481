import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { setSearchParam } from '../../../redux/searchParams/actions';

const mapStateToProps = field => ({ searchParams }) => ({
  value: get(searchParams, field),
  searchParams,
});

const mapDispatchToProps = field => ({
  onChange: value => setSearchParam(field, value),
});

const mergeProps = ({ searchParams, ...stateProps }, dispatchProps) => ({
  searchParams,
  input: {
    ...stateProps,
    ...dispatchProps,
  },
});

const withSearchParam = field => WrappedComponent => connect(
  mapStateToProps(field),
  mapDispatchToProps(field),
  mergeProps,
)(props => <WrappedComponent {...props} />);

export default withSearchParam;
