import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import PageTitle from 'components/base/titles/PageTitle';
import { Grid } from '@material-ui/core';
import InfluencerCard from 'components/common/InfluencerCard';
import withPermission from 'components/common/withPermission';
import { isEmpty, identity } from 'lodash';
import { Alert } from '@material-ui/lab';

const styles = () => ({
  root: {},
  gridItem: {
    width: '100%',
  },
});

const BrandCreators = ({
  classes, data, permittedProfileViews, permitted,
}) => (
  <div className={classes.root} sel="brand_creators" brand={data.brand}>
    <PageTitle title={data.brand} />
    <Grid container spacing={2}>
      {isEmpty(data.persons) && (
        <Alert severity="warning">
          We don&apos;t have sufficient data for this filter. Please try a different selection.
        </Alert>
      )}
      {data.persons.map(p => (
        <Grid className={classes.gridItem} item>
          <InfluencerCard
            permittedProfileViews={permittedProfileViews}
            influencer={p}
            brandName={data.brand}
            canViewProfile
            canViewTopBrands
            canViewSelectedBrandMentions
            canSaveToList={false}
            canViewBrandSafety={permitted}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

BrandCreators.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  permittedProfileViews: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    brand: PropTypes.string,
    persons: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  permitted: PropTypes.bool.isRequired,
};

export default withAll(
  withPermission('create_brand_safety_reports', null, identity, true),
  withStyles(styles),
)(BrandCreators);
