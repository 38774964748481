import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export default withStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '-0.4rem',
    '& > div:before': {
      display: 'none',
    },
    '& label': {
      fontSize: '1.2rem',
      fontWeight: '600',
      transform: 'translate(0, 1.5px) scale(0.75)',
    },
    '& input': {
      marginTop: '0.5rem',
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.white.main,
      border: `1px solid ${theme.palette.border.main}`,
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);
