import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { PLATFORM_ICONS } from 'constants/options';
import {
  Typography,
  Avatar,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  title: {
    fontColor: theme.palette.black.dark,
    textAlign: 'left',
    margin: '0 0 0 0.4rem',
    fontSize: '0.9rem',
  },
  avatar: {
    width: '20px',
    height: '20px',
    backgroundColor: 'transparent',
  },
});

const CreatorTitle = ({ classes, data }) => (
  <div className={classes.root}>
    <Avatar
      className={classes.avatar}
      alt={data.username}
    >
      {PLATFORM_ICONS[data.platform]}
    </Avatar>
    <Typography variant="h5" className={classes.title}>@{data.username}</Typography>
  </div>
);

CreatorTitle.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    username: PropTypes.string,
    platform: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(CreatorTitle);
