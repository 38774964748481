import React from 'react';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import ListDetailsInfo from '../ListDetailsInfo';
import Stat from './Stat';
import { displayNumber } from '../../../../../utils/helpers';

const ListDetailsHeader = ({
  classes,
  list,
  loading,
  membersDict,
  exportListFunc,
}) => {
  const { stats = {}, members = 0 } = list;
  return (
    <Card className={classes.header} id="list_detail_stats">
      <ListDetailsInfo list={list} membersDict={membersDict} exportListFunc={exportListFunc} />
      <Stat title="Creators" value={members} loading={loading} />
      <Stat title="Followers" value={displayNumber(stats.totalReach)} loading={loading} />
      <Stat title="Avg. Eng." value={displayNumber(stats.avgEngagement)} loading={loading} />
      <Stat title="Avg. Spon. Eng." value={displayNumber(stats.avgSponsoredPostEngagement)} loading={loading} />
      <Stat title="Avg. Comments" value={displayNumber(stats.avgComments)} loading={loading} />
      <Stat title="Avg. Likes" value={displayNumber(stats.avgLikes)} loading={loading} />
    </Card>
  );
};

ListDetailsHeader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  list: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  membersDict: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  exportListFunc: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListDetailsHeader);
