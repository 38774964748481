import React from 'react';
import { Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import numeral from 'numeral';
import StatItem from './StatItem';
import styles from './styles';

const Stats = ({
  classes,
  followers,
  avgViews,
  avgLikes,
  avgComments,
}) => (
  <div className={classes.root}>
    <Typography variant="h6" className={classes.stats__title}>Influencer Overview</Typography>
    <StatItem value={numeral(followers).format('0,0')} label="Followers" Icon={FavoriteIcon} />
    {!!avgViews &&
      <StatItem value={numeral(avgViews).format('0,0')} label="Average Views" Icon={RemoveRedEyeIcon} />
    }
    {!!avgLikes &&
      <StatItem value={numeral(avgLikes).format('0,0')} label="Likes per post" Icon={ThumbUpIcon} />
    }
    {!!avgComments &&
      <StatItem value={numeral(avgComments).format('0,0')} label="Comments per post" Icon={ChatBubbleIcon} />
    }
  </div>
);

Stats.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  followers: PropTypes.number,
  avgViews: PropTypes.number,
  avgLikes: PropTypes.number,
  avgComments: PropTypes.number,
};

Stats.defaultProps = {
  followers: null,
  avgViews: null,
  avgLikes: null,
  avgComments: null,
};

export default withStyles(styles)(Stats);
