import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import { withRouter } from 'react-router';
import { listUrl } from 'constants/routing';
import { first, values } from 'lodash';
import Tooltip from './Tooltip';

class SavedListViewTooltip extends React.Component {
  handleSearch = async () => {
    const { lists } = this.props;
    this.props.history.push(listUrl(first(values(lists.data)).uid));
  }

  render() {
    return <Tooltip submitText="Next" handleSubmit={this.handleSearch} {...this.props} />;
  }
}


SavedListViewTooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  updateOnBoarding: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    lists: state.lists,
  }),
  { updateOnBoarding },
)(SavedListViewTooltip));
