import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { size, values, isEmpty } from 'lodash';
import { mpTrack } from 'utils/copenhagen';
import Loader from 'components/base/Loader';
import PageLayout from 'components/base/Layout/PageLayout';
import PageTitle from 'components/base/titles/PageTitle';
import withOnBoarding from 'components/common/OnBoarding/withOnBoarding';
import InfluencerSelectionManager from 'components/common/InfluencerSelectionManager';
import { fetchCategories, fetchLists } from 'redux/lists/actions';
import { deselectAllInfluencers } from 'redux/searchDisplay/actions';
import CreateListButton from './CreateListButton';
import ListGrid from './ListGrid';
import styles from './styles';

class Lists extends React.PureComponent {
  componentDidMount() {
    mpTrack('load__lists', {});
    this.props.fetchLists();
    this.props.fetchCategories();
    this.props.deselectAllInfluencers();
  }

  componentDidUpdate() {
    const { handleSavedListsOnBoarding, loading, lists } = this.props;
    if (!loading && !isEmpty(lists)) {
      handleSavedListsOnBoarding();
    }
  }

  render() {
    const {
      classes,
      lists,
      loading,
    } = this.props;
    const listCount = size(lists);
    return (
      <InfluencerSelectionManager>
        <Loader loading={loading}>
          <PageLayout>
            <div className={classes.actionsRow}>
              <PageTitle title={`Showing ${listCount} lists`} />
              <div className={classes.actionButtons}>
                <CreateListButton />
              </div>
            </div>
            <ListGrid lists={lists} />
          </PageLayout>
        </Loader>
      </InfluencerSelectionManager>
    );
  }
}

Lists.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  deselectAllInfluencers: PropTypes.func.isRequired,
  fetchLists: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  handleSavedListsOnBoarding: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lists: { loading, data }, onBoarding }) => ({
  lists: values(data) || [],
  loading: loading || false,
  onBoarding,
});

const mapDispatchToProps = {
  deselectAllInfluencers,
  fetchLists,
  fetchCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withOnBoarding(Lists)));
