import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { getCompetitiveReportVerticalBreakdown } from 'redux/reports/actions';
import Loader from 'components/base/Loader';
import { withRouter } from 'react-router';
import { get, first } from 'lodash';
import Card from 'components/base/Card';
import H6 from 'components/base/titles/CardHeading/H6';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { CardContent, Grid } from '@material-ui/core';
import { DEFAULT_POSTED_WITHIN, DEFAULT_POSTED_BEFORE } from 'constants/options';
import { HeaderVerticalFilter, HeaderDateFilter } from './Header';
import TopBrandChart from './TopBrandChart';
import SelectedBrandChart from './SelectedBrandChart';
import BrandsInReport from './BrandsInReport';
import TopPosts from './TopPosts';
import TabHeader from '../TabHeader';

const styles = () => ({
  root: {},
  card: {
    marginBottom: '1.8rem',
  },
  filterSelect: {
    marginBottom: '2rem',
  },
});

class VerticalBreakdown extends React.Component {
  componentDidMount() {
    const { selectedVertical, match: { params: { reportId } } } = this.props;
    this.props.getCompetitiveReportVerticalBreakdown(reportId, {
      vertical: selectedVertical,
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    });
  }

  render() {
    const {
      classes,
      isLoading,
      loaded,
      selectedBrands,
    } = this.props;
    return (
      <div className={classes.root}>
        <TabHeader
          title="Vertical Breakdown"
          description="Zooms out to examine the vertical of the brands by showing the share
                      of voice and top posts within the vertical."
        >
          <HeaderDateFilter />
        </TabHeader>

        <div className={classes.filterSelect}>
          <HeaderVerticalFilter />
        </div>

        <Loader loading={isLoading} loaded={loaded}>
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={9}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <H6>
                              <LabelWithInfoIcon
                                labelText="Brands in report"
                                tooltip="These are the brands that are in the competitive analysis report."
                              />
                            </H6>
                            <SelectedBrandChart />
                          </Grid>
                          <Grid item xs={12}>
                            <H6>
                              <LabelWithInfoIcon
                                labelText="Top brands in vertical"
                                tooltip="These are the top brands by creators activity in the vertical."
                              />
                            </H6>
                            <TopBrandChart />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={3}>
                        {selectedBrands.map(d => <BrandsInReport data={d} />)}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12}>
                <H6>
                  <LabelWithInfoIcon
                    labelText="Top posts in vertical"
                    tooltip="These are the top posts made by creators in the vertical."
                  />
                </H6>
                <TopPosts />
              </Grid>
            </Grid>
          </React.Fragment>
        </Loader>
      </div>
    );
  }
}

VerticalBreakdown.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  selectedVertical: PropTypes.string.isRequired,
  selectedBrands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  getCompetitiveReportVerticalBreakdown: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = ({ competitiveReportVerticalBreakdown, competitiveReportStat }) => ({
  isLoading: competitiveReportVerticalBreakdown.loading,
  loaded: competitiveReportVerticalBreakdown.loaded,
  verticals: get(competitiveReportStat, 'data.verticals', []),
  selectedVertical: get(
    competitiveReportVerticalBreakdown,
    'data.params.vertical',
    first(get(competitiveReportStat, 'data.verticals', [])),
  ),
  selectedBrands: get(competitiveReportVerticalBreakdown, 'data.selectedBrands', []),
});

export default withAll(
  connect(mapStateToProps, { getCompetitiveReportVerticalBreakdown }),
  withStyles(styles),
  withRouter,
)(VerticalBreakdown);
