export default theme => ({
  stats: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `1px solid ${theme.palette.border.main}`,
    '& div:not(:first-child)': {
      borderLeft: `1px solid ${theme.palette.border.main}`,
    },
  },
  stat__wrapper: {
    padding: '20px 0px',
  },
});
