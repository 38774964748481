import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/base/Button';
import {
  TableCell,
  TableRow,
  Typography,
  Icon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import { buildCompetitiveReportUrl, buildCompetitiveReportUpdateUrl } from 'constants/routing';
import { REPORT_STATUS_LABELS, REPORT_STATUS } from 'constants/reports';
import RefreshAction from 'components/common/CompetitiveReports/RefreshAction';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {},
  edit: {},
  activeIcon: {
    color: theme.palette.green.main,
  },
  reportLink: {
    color: theme.palette.blue.main,
  },
});


const ReportRow = ({
  report,
  userId,
  classes,
  history,
}) => (
  <React.Fragment>
    <TableRow key={`report-${report.id}`} className={classes.root}>
      <TableCell component="th" scope="row">
        <Button
          disabled={!report.active || report.status !== REPORT_STATUS.completed}
          variant="link"
          className={classes.reportLink}
          onClick={() => history.push(buildCompetitiveReportUrl(report.id))}
        >
          <Typography>{report.title}</Typography>
        </Button>
      </TableCell>
      <TableCell align="left">
        {report.brands.map(b => (
          <Typography key={b}>{b}</Typography>
        ))}
      </TableCell>
      <TableCell align="left">
        <Typography>{REPORT_STATUS_LABELS[report.status]}</Typography>
      </TableCell>
      <TableCell align="left">
        {report.active && <Icon className={classes.activeIcon}>checkcircleoutline</Icon>}
      </TableCell>
      <TableCell align="right">
        {userId === report.createdById && <RefreshAction report={report} />}
      </TableCell>
      <TableCell align="right">
        {!report.isSample && (
          <Button
            onClick={() => history.push(buildCompetitiveReportUpdateUrl(report.id))}
            className={classes.edit}
            variant="contained"
            color="secondary"
          ><Icon>edit</Icon>
          </Button>)}
      </TableCell>
    </TableRow>
  </React.Fragment>
);

ReportRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    active: PropTypes.bool,
    isSample: PropTypes.bool,
    createdById: PropTypes.number,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ userProfile: { data: { id } } }) => ({
  userId: id,
});

export default withAll(
  connect(mapStateToProps, {}),
  withStyles(styles),
  withRouter,
)(ReportRow);
