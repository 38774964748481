import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatNumber } from 'utils/helpers';
import {
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Paper,
  Typography,
} from '@material-ui/core';
import { keys, get, sum } from 'lodash';
import { withStyles } from '@material-ui/styles';
import { PLATFORM_ICONS } from 'constants/options';

const styles = () => ({
  root: {},
});

const NULL_DISPLAY = 'N/A';

const Platforms = ({ classes, brand }) => (
  <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Platform</TableCell>
          <TableCell align="right">Total Posts</TableCell>
          <TableCell align="right">Total Creators</TableCell>
          <TableCell align="right">Likes</TableCell>
          <TableCell align="right">Comments</TableCell>
          <TableCell align="right">Views</TableCell>
          <TableCell align="right">Total Interactions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keys(brand.platforms).map(p => (
          <TableRow key={p}>
            <TableCell component="th" scope="row">
              {PLATFORM_ICONS[p]}
            </TableCell>
            <TableCell align="right">
              <Typography>
                {formatNumber(
                  sum(get(brand, ['platforms', p, 'dailyPostCounts'])),
                  '0a',
                  NULL_DISPLAY,
                )}
              </Typography>
            </TableCell>
            <TableCell align="right">
              {formatNumber(
                get(brand, ['platforms', p, 'creatorCount']),
                '0a',
                NULL_DISPLAY,
              )}
            </TableCell>
            <TableCell align="right">
              {formatNumber(
                get(brand, ['platforms', p, 'totalLikeCounts']),
                '0a',
                NULL_DISPLAY,
              )}
            </TableCell>
            <TableCell align="right">
              {formatNumber(
                get(brand, ['platforms', p, 'totalCommentCounts']),
                '0a',
                NULL_DISPLAY,
              )}
            </TableCell>
            <TableCell align="right">
              {formatNumber(
                get(brand, ['platforms', p, 'totalViewCounts']),
                '0a',
                NULL_DISPLAY,
              )}
            </TableCell>
            <TableCell align="right">
              {formatNumber(
                get(brand, ['platforms', p, 'interactions']),
                '0a',
                NULL_DISPLAY,
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

Platforms.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brand: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ brand }) => ({
  brand: brand.data,
});

export default connect(mapStateToProps, null)(withStyles(styles)(Platforms));
