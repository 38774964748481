import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { formatNumber } from 'utils/helpers';

const style = theme => ({
  root: {
    textAlign: 'right',
    width: '6rem',
    height: '6rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.blue.main,
    position: 'absolute',
    top: '-2.5rem',
    right: '-2.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.white.main,
  },
  label: {
    fontSize: '0.5rem',
    paddingRight: '3rem',
  },
  value: {
    fontWeight: 600,
    display: 'block',
    fontSize: '1rem',
    paddingRight: '3rem',
    paddingTop: '1.8rem',
    lineHeight: '0.8rem',
  },
});

const BrandMentions = ({ classes, selectedBrandMentions }) => (
  <div className={classes.root}>
    <span className={classes.value}>{formatNumber(selectedBrandMentions, '0a', '-')}</span>
    <span className={classes.label}>mentions</span>
  </div>
);

BrandMentions.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  selectedBrandMentions: PropTypes.number.isRequired,
};

export default withStyles(style)(BrandMentions);
