import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Chip from 'components/base/Chip';
import cx from 'classnames';

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.white.main,
    minWidth: 'auto',
    padding: '0.2rem',
  },
  buttonUnselected: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.border.main}`,
  },
});

const ChipFilter = ({
  classes,
  buttonSelected,
  className,
  ...other
}) => (
  <Chip
    className={cx([
      classes.button,
      ...buttonSelected ? [] : [classes.buttonUnselected],
      className,
    ])}
    {...other}
  />
);

ChipFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  buttonSelected: PropTypes.bool,
  className: PropTypes.string,
};

ChipFilter.defaultProps = {
  buttonSelected: false,
  className: null,
};

export default withStyles(styles)(ChipFilter);
