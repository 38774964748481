import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { register } from 'constants/routing';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import redirectToStripeCheckout from 'utils/stripeUtils';
import styles from './styles';


const Subscription = ({
  classes,
  subscription,
  isAuthenticated,
  userProfile,
  history,
}) => {
  const price = `${numeral(subscription.price).format('$0')} / ${subscription.interval}`;

  const redirectToCheckout = async () => {
    if (isAuthenticated && userProfile.data.email) {
      await redirectToStripeCheckout(userProfile.data.email, subscription.subscriptionId, window.location.href);
    } else {
      toast.info('Must log in or create an account before purchasing a subscription');
      history.push({
        pathname: register(),
        state: {
          subscriptionId: subscription.subscriptionId,
          redirectUrl: window.location.href,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" className={classes.button} onClick={redirectToCheckout}>
        {price}
      </Button>
      <div className={classes.note}>
        <Typography variant="body2">per user/month for early adopters</Typography>
      </div>
    </React.Fragment>
  );
};

Subscription.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  subscription: PropTypes.shape({}).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userProfile: PropTypes.shape({
    email: PropTypes.string,
  }),
};

Subscription.defaultProps = {
  userProfile: {
    email: null,
  },
};

export default withAll(
  connect(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userProfile: state.userProfile,
  }), null),
  withModal,
  withRouter,
  withStyles(styles),
)(Subscription);
