export default theme => ({
  chip: {
    margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
  },
  multiSelectResultContainer: {
    margin: '0.4rem -0.2rem 0 -0.2rem',
  },
  placeholder: {
    position: 'absolute',
  },
  indicatorSeparator: {
    display: 'none',
  },
  valueContainer: {
    order: 1,
    flex: 1,
  },
  indicatorsContainer: {
    flex: 0,
  },
  notice: {
    fontSize: '0.6rem',
    color: theme.palette.black.dark,
    marginTop: '0.4rem',
  },
});
