import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import Chip from 'components/base/Chip';
import Select, { components, Creatable } from 'react-select';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import styles from './styles';

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    onDelete={() => props.removeProps.onClick(props.value)}
    deleteIcon={<ClearIcon {...props.removeProps} />}
  />
);

const MultiValueContainer = () => (
  <span style={{ display: 'none' }} />
);

const ValueContainer = ({ children, ...props }) => (
  <div className={props.selectProps.classes.valueContainer} >
    <components.ValueContainer {...props}>
      {children}
    </components.ValueContainer>
  </div>
);

const IndicatorSeparator = props => (
  // eslint-disable-next-line react/prop-types
  <span className={props.selectProps.classes.indicatorSeparator} />
);

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <SearchIcon />
  </components.DropdownIndicator>
);

const IndicatorsContainer = props => (
  <div className={props.selectProps.classes.indicatorsContainer} >
    <components.IndicatorsContainer {...props} />
  </div>
);

const Input = props => (
  <components.Input {...props} className={props.selectProps.classes.input} />
);

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const Control = props => (
  <components.Control {...props} className={props.selectProps.classes.control} />
);

const selectComponents = {
  MultiValueContainer,
  DropdownIndicator,
  IndicatorsContainer,
  ValueContainer,
  IndicatorSeparator,
  Input,
  Placeholder,
  Control,
};

class MultiSelect extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    selectAdditionalProps: PropTypes.shape({}),
    creatable: PropTypes.bool,
    handleRemoveSelection: PropTypes.func,
    classes: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })),
    ]).isRequired,
  };

  static defaultProps = {
    placeholder: '',
    value: [],
    options: [],
    handleRemoveSelection: null,
    selectAdditionalProps: {},
    creatable: false,
  };

  handleChange = (value) => {
    this.props.onChange(value);
  };

  handleRemoveSelection = (selectedValue) => {
    this.props.handleRemoveSelection(selectedValue);
  };

  render() {
    const {
      value, name, placeholder, options, classes, theme, selectAdditionalProps, creatable,
    } = this.props;

    const selectStyles = {
      control: base => ({
        ...base,
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: theme.shape.borderRadius,
      }),
      menu: base => ({
        ...base,
      }),
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        {
          creatable ?
            <Creatable
              classes={classes}
              name={name}
              value={value}
              styles={selectStyles}
              onChange={this.handleChange}
              options={options}
              components={selectComponents}
              placeholder={placeholder}
              isClearable={false}
              textFieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
              }}
              isMulti
              {...selectAdditionalProps}
            /> :
            <Select
              classes={classes}
              name={name}
              value={value}
              styles={selectStyles}
              onChange={this.handleChange}
              options={options}
              components={selectComponents}
              placeholder={placeholder}
              isClearable={false}
              textFieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
              }}
              isMulti
              {...selectAdditionalProps}
            />
        }
        <div className={classes.multiSelectResultContainer}>
          {value.map(result => (
            <MultiValue
              key={`multi-val-${result.value}`}
              value={result.value}
              removeProps={{ onClick: this.handleRemoveSelection }}
            >
              {result.label}
            </MultiValue>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MultiSelect);

