import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  push: {
    height: theme.sizes.layout.footer.height.desktop,
    marginTop: '1rem',
  },
});


const FooterSpace = (props) => {
  const { classes } = props;
  return <div className={classes.push} />;
};

FooterSpace.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};
export default withStyles(styles, { withTheme: true })(FooterSpace);
