import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import withAll from 'components/base/withAll';

const styles = () => ({
  root: {},
  value: {},
  label: {
    fontSize: '0.8rem',
  },
});

const Stat = ({
  classes,
  value,
  label,
  className,
  ...other
}) => (
  <div className={className} {...other}>
    <Typography className={className} variant="h6">{value}</Typography>
    <Typography className={classes.label}>{label}</Typography>
  </div>
);

Stat.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
};

Stat.defaultProps = {
  value: '-',
};

export default withAll(withStyles(styles))(Stat);
