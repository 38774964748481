import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUITab from '@material-ui/core/Tab';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    marginRight: '1rem',
    minWidth: 'auto',
    padding: '0.6rem 0',
  },
});

const Tab = ({ track, ...other }) => {
  if (track) {
    return <MUITab onClick={() => mpTrack(MP_EVENTS.CLICK_TAB, { label: other.label })} {...other} />;
  }
  return <MUITab {...other} />;
};

Tab.propTypes = {
  track: PropTypes.bool,
};

Tab.defaultProps = {
  track: true,
};

export default withStyles(styles)(Tab);
