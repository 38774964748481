import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import BrandItem from './BrandItem';

const styles = () => ({
  root: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '6rem',
    fontSize: '1rem',
    fontWeight: 800,
  },
  values: {
    flex: 1,
  },
});

const Platforms = ({ classes, data }) => (
  <div className={classes.root}>
    <Typography className={classes.title}>Brands:</Typography>
    <div className={classes.values}>
      {data.brandLabelLegend.map(b => <BrandItem brand={b} />)}
    </div>
  </div>
);

Platforms.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    brandLabelLegend: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default withStyles(styles)(Platforms);
