import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Loader from 'components/base/Loader';
import Logo from 'components/base/Logo/LoginLogo';
import { withRouter } from 'react-router';
import LoggedOutLayout from 'components/base/LoggedOutLayout';
import withQueryParams from 'components/base/withQueryParams';
import withAll from 'components/base/withAll';
import API from 'constants/api';
import { request } from 'lib/http';
import { searchUrl } from 'constants/routing';
import Button from 'components/base/Button';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import withModal from 'components/base/withModal';
import { mpPageView } from 'utils/copenhagen';
import EnterprisePlan from './EnterprisePlan';
import Plan from './Plan';
import styles from './styles';

class Pricing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      finished: false,
      loading: false,
    };
  }

  async componentDidMount() {
    mpPageView({ name: 'pricing' });
    await this.loadAndSetPlans();
    this.checkCompletion();
  }

  loadAndSetPlans = async () => {
    this.setState({ loading: true });

    const { plans } = await this.loadPlans();

    this.setState({
      plans,
      loading: false,
    });
  };

  loadPlans = async () => request('get', API.SECURITY_PLANS, {});

  checkCompletion = () => {
    if (this.props.sessionId) {
      this.setState({
        finished: true,
      });
    }
  };

  renderPlans() {
    const { plans } = this.state;
    return (
      <React.Fragment>
        {plans.map(p => (
          <Grid item md={3} key={p.planId}>
            <Plan plan={p} />
          </Grid>
        ))}
        <Grid item md={3}>
          <EnterprisePlan />
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const {
      classes,
      isAuthenticated,
      open,
      openModal,
      closeModal,
      history,
    } = this.props;
    const { loading } = this.state;
    return (
      <LoggedOutLayout>
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <Grid container spacing={2} className={classes.planGrid}>
            <Loader loading={loading}>
              {this.state.finished ? history.push(searchUrl()) : this.renderPlans()}
            </Loader>
            {!isAuthenticated && (
              <Grid item xs={12} className={classes.trialSignup}>
                <Typography variant="h5">- Or -</Typography>
                <Button variant="contained" color="primary" onClick={openModal}>Start a FREE Trial</Button>
              </Grid>
            )}
          </Grid>
          <div className={classes.push} />
        </div>
        <BetaSignupModal
          open={open}
          closeModal={closeModal}
          onSignupComplete={closeModal}
        />
      </LoggedOutLayout>
    );
  }
}

Pricing.propTypes = {
  open: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  isAuthenticated: PropTypes.string.isRequired,
  sessionId: PropTypes.string,
};

Pricing.defaultProps = {
  sessionId: null,
};

const paramsToProps = ({ sessionId }) => ({
  sessionId,
});

export default withAll(
  connect(state => ({ isAuthenticated: state.auth.isAuthenticated })),
  withQueryParams(paramsToProps),
  withStyles(styles),
  withModal,
  withRouter,
)(Pricing);
