import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CircleBorderIcon from '@material-ui/icons/Brightness1Outlined';
import styles from './styles';

const CheckboxCircle = (props) => {
  const { classes, size, ...rest } = props;
  return (
    <Checkbox
      icon={<CircleBorderIcon className={classes.root} style={{ fontSize: size }} />}
      checkedIcon={<CheckCircle className={classes.checked} style={{ fontSize: size }} />}
      {...rest}
    />
  );
};

CheckboxCircle.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  size: PropTypes.string,
};

CheckboxCircle.defaultProps = {
  className: 'circle-checkbox',
  value: 'checked',
  checked: false,
  size: '2rem',
};

export default withStyles(styles)(CheckboxCircle);
