import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    bottom: 0,
    width: '100%',
    padding: '0 2px',
    boxSizing: 'border-box',
  },
  label: {
    flex: 1,
    color: theme.palette.black.light,
    fontSize: '0.8rem',
    textAlign: 'center',
  },
});

const XAxis = ({
  labels,
  classes,
}) => (
  <div
    className={classes.root}
  >
    {/* eslint-disable-next-line */}
    {labels.map((l, k) => <div key={`xAxis-${k}`} className={classes.label}>{l}</div>)}
  </div>
);

XAxis.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};


export default withStyles(styles, { withTheme: true })(XAxis);
