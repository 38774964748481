import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/base/Button';

const Tooltip = ({
  backProps,
  continuous,
  index,
  isLastStep,
  size,
  skipProps,
  step,
  tooltipProps,
  handleSubmit,
  submitText,
  hidePrimary,
}) => {
  const {
    content,
    hideBackButton,
    hideFooter,
    showProgress,
    showSkipButton,
    title,
    styles,
  } = step;
  const {
    back,
    close,
    last,
    next,
    skip,
  } = step.locale;
  const output = {
    primary: close,
  };

  if (continuous) {
    output.primary = isLastStep ? last : next;

    if (showProgress) {
      output.primary = (
        <span>
          {output.primary} ({index + 1}/{size})
        </span>
      );
    }
  }

  if (showSkipButton && !isLastStep) {
    output.skip = (
      <button
        style={styles.buttonSkip}
        type="button"
        data-test-id="button-skip"
        aria-live="off"
        {...skipProps}
      >
        {skip}
      </button>
    );
  }

  if (!hideBackButton && index > 0) {
    output.back = (
      <button style={styles.buttonBack} type="button" data-test-id="button-back" {...backProps}>
        {back}
      </button>
    );
  }

  return (
    <div
      key="JoyrideTooltip"
      className="react-joyride__tooltip"
      style={styles.tooltip}
      {...tooltipProps}
    >
      <div style={styles.tooltipContainer}>
        {title && (
          <h4 style={styles.tooltipTitle} aria-label={title}>
            {title}
          </h4>
        )}
        <div style={styles.tooltipContent}>{content}</div>
      </div>
      {!hideFooter && (
        <div style={styles.tooltipFooter}>
          <div style={styles.tooltipFooterSpacer}>{output.skip}</div>
          {!hidePrimary &&
            <Button color="primary" variant="contained" type="button" onClick={handleSubmit}>{submitText}</Button>
          }
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func,
  hidePrimary: PropTypes.bool,
  submitText: PropTypes.string,
};

Tooltip.defaultProps = {
  submitText: 'Ok',
  hidePrimary: false,
  handleSubmit: () => {},
};

export default Tooltip;
