export default () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  carousel_container: {
    margin: '0 -24px',
  },
});
