export default theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.blue.main,
    height: '100%',
    display: 'flex',
    '& input': {
      backgroundColor: theme.palette.white.dark,
      border: 'none',
    },
  },
  toggleButton: {
    padding: 0,
    height: '100%',
    minWidth: 'auto',
    marginRight: '1rem',
    color: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toggleIcon: {
    verticalAlign: 'middle',
  },
  searchButtonContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'normal',
    },
  },
  desktopMainNav: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  mobileMainNav: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileNav: {
    flex: 1,
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileNavOpen: {
    display: 'none',
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});
