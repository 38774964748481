export default theme => ({
  container: ({ isOverflow }) => ({
    position: 'absolute',
    background: 'white',
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    overflow: (isOverflow ? 'scroll' : null),
  }),
  header: {
    display: 'flex',
    flexDirection: 'row',
    margin: '6px 0 6px 12px',
    height: '50px',
    alignItems: 'center',
  },
  header__text: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.palette.text.bold,
  },
  content: {
    background: theme.palette.white.dark,
    width: '100%',
    height: '100%',
  },
  close__button: {
    marginLeft: 'auto',
  },
});
