import React from 'react';
import PageLayout from 'components/base/Layout/PageLayout';
import CompetitiveReportCreateUpdate from 'components/common/CompetitiveReportCreateUpdate/CreateReport';
import { mpPageView } from 'utils/copenhagen';

class CompetitiveReportsCreate extends React.Component {
  componentDidMount() {
    mpPageView({ name: 'create_report' });
  }

  render() {
    return (
      <PageLayout>
        <CompetitiveReportCreateUpdate />
      </PageLayout>
    );
  }
}

export default CompetitiveReportsCreate;
