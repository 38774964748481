import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { withStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import { formatNumber } from 'utils/helpers';

export const getOptions = options => ({
  responsive: true,
  title: {
    display: false,
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltip, data) => (
        ` ${data.labels[tooltip.index]}: ` +
        `${formatNumber(data.datasets[tooltip.datasetIndex].data[tooltip.index], '0a', '-')}`
      ),
    },
  },
  ...options,
  legend: {
    display: false,
    labels: {
      usePointStyle: true,
    },
    ...options.legend ? options.legend : {},
  },
});

const styles = {
  root: {
    position: 'relative',
  },
  chartBackdrop: {
    position: 'absolute',
    flex: 1,
    alignItems: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
  },
  chart: {
    position: 'relative',
  },
};

const DoughnutBasic = ({
  options,
  data,
  backdropElement,
  classes,
  ...other
}) => (
  <div className={classes.root}>
    {!isEmpty(backdropElement) && (
      <div className={classes.chartBackdrop}>
        {backdropElement}
      </div>
    )}
    <div className={classes.chart}>
      <Doughnut
        data={data}
        options={getOptions(options)}
        {...other}
      />
    </div>
  </div>
);

DoughnutBasic.propTypes = {
  options: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
  backdropElement: PropTypes.element,
};

DoughnutBasic.defaultProps = {
  options: {},
  backdropElement: null,
};

export default withStyles(styles)(DoughnutBasic);
