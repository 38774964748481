import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import ReactDOM from 'react-dom';
import { normalizeNumber } from 'utils/helpers';
import { max, min } from 'lodash';
import * as d3 from 'd3';

const calculateRadius = (bubbleMaxSize, bubbleMinSize, maxVal, minVal, value) =>
  ((bubbleMaxSize - bubbleMinSize) * normalizeNumber(value, maxVal, minVal)) + bubbleMinSize;

class Force extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.svg = ReactDOM.findDOMNode(this);
    this.renderChart();
  }

  dragstart(d) {
    // eslint-disable-next-line
    d3.select(this).classed('fixed', d.fixed = true);
  }

  dblclick(d) {
    // eslint-disable-next-line
    d3.select(this).classed('fixed', d.fixed = false);
  }

  // Resolves collisions between d and all other circles.
  collide(alpha) {
    const {
      clusterPadding,
      padding,
      bubbleMaxSize,
      data,
    } = this.props;
    const quadtree = d3.geom.quadtree(data.nodes);
    return (d) => {
      const r = d.radius + bubbleMaxSize + Math.max(padding, clusterPadding);
      const nx1 = d.x - r;
      const nx2 = d.x + r;
      const ny1 = d.y - r;
      const ny2 = d.y + r;
      quadtree.visit((quad, x1, y1, x2, y2) => {
        if (quad.point && quad.point !== d) {
          let x = d.x - quad.point.x;
          let y = d.y - quad.point.y;
          let l = Math.sqrt((x * x) + (y * y));
          const rad = d.radius + quad.point.radius + (d.cluster === quad.point.cluster ? padding : clusterPadding);
          if (l < rad) {
            l = ((l - rad) / l) * alpha;
            // eslint-disable-next-line
            d.x -= x *= l;
            // eslint-disable-next-line
            d.y -= y *= l;
            // eslint-disable-next-line
            quad.point.x += x;
            // eslint-disable-next-line
            quad.point.y += y;
          }
        }
        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    };
  }

  renderChart = () => {
    const {
      data,
      width,
      height,
      charge,
      gravity,
      linkDistance,
      bubbleMaxSize,
      bubbleMinSize,
      fontMaxSize,
      fontMinSize,
      nodeColor,
      selectedOpacity,
      defaultOpacity,
      onSelectNode,
      theme,
    } = this.props;
    const dataValues = data.nodes.map(item => item.value);
    const maxVal = max(dataValues);
    const minVal = dataValues.length > 1 ? min(dataValues) : 1;
    this.svg.innerHTML = '';
    // Allow bubbles overflowing its SVG container in visual aspect if props(overflow) is true.
    this.svg.style.overflow = 'visible';

    d3.select(this.svg);

    const force = d3.layout.force()
      .charge(charge)
      .linkDistance(linkDistance)
      .gravity(gravity)
      .size([width, height]);

    const drag = force.drag()
      .on('dragstart', this.dragstart);

    const fade = (d) => {
      // eslint-disable-next-line
      node.transition().style('opacity', selectedOpacity);
      // eslint-disable-next-line
      label.transition().style('opacity', selectedOpacity);
      // eslint-disable-next-line
      labelCounts.transition().style('opacity', selectedOpacity);

      // eslint-disable-next-line
      node.filter((selectedNode, i) => i === d.index)
        .transition().style('opacity', defaultOpacity);

      // eslint-disable-next-line
      label.filter((selectedNode, i) => i === d.index)
        .transition().style('opacity', defaultOpacity);

      // eslint-disable-next-line
      labelCounts.filter((selectedNode, i) => i === d.index)
        .transition().style('opacity', defaultOpacity);

      onSelectNode(d);
    };

    const linkedByIndex = {};
    data.links.forEach((d) => {
      linkedByIndex[`${d.source},${d.target}`] = d.usage;
    });

    const svg = d3.select(this.svg)
      .attr('width', width)
      .attr('height', height);
    force
      .nodes(data.nodes)
      .links(data.links)
      .start();

    const link = svg.selectAll('.link')
      .data(data.links)
      .enter().append('line')
      .attr('class', 'link')
      .style('stroke', 'black')
      .style('stroke-width', d => Math.sqrt(d.value));

    const node = svg.selectAll('.node')
      .data(data.nodes)
      .enter().append('circle')
      .attr('class', 'node')
      .attr('r', (d) => {
        const circleSize = calculateRadius(bubbleMaxSize, bubbleMinSize, maxVal, minVal, d.value);
        data.nodes[d.index].radius = circleSize;
        return circleSize;
      })
      .style('fill', nodeColor)
      .style('opacity', 1)
      .style('cursor', 'pointer')
      .call(force.drag)
      .on('click', fade)
      .on('dblclick', this.dblclick)
      .call(drag);

    const label = svg.selectAll('text.label')
      .data(data.nodes)
      .enter().append('text')
      .attr('class', 'label')
      .style('cursor', 'pointer')
      .style({
        fill: 'black',
        'fill-opacity': 1,
        'font-weight': 'normal',
        'text-shadow': `-1px -1px 0 ${theme.palette.white.main},
          1px -1px 0 ${theme.palette.white.main},
          -1px  1px 0 ${theme.palette.white.main},
          1px  1px 0 ${theme.palette.white.main}`,
      })
      .style('font-size', d => calculateRadius(fontMaxSize, fontMinSize, maxVal, minVal, d.value))
      .attr('text-anchor', 'middle')
      .on('click', fade)
      .text(d => d.label);

    const labelCounts = svg.selectAll('text.label-counts')
      .data(data.nodes)
      .enter().append('text')
      .attr('class', 'label-counts')
      .style({
        fill: theme.palette.white.main,
        'fill-opacity': 1,
        'font-weight': '800',
      })
      .style('cursor', 'pointer')
      .style('font-size', d => calculateRadius(fontMaxSize, fontMinSize, maxVal, minVal, d.value) - 3)
      .attr('text-anchor', 'middle')
      .on('click', fade)
      .text(d => d.value);

    force.on('tick', () => {
      link.attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      node
        .each(this.collide(0.5))
        .attr('cx', (d) => {
          // eslint-disable-next-line
          d.x = Math.max(d.radius, Math.min(width - d.radius, d.x));
          return d.x;
        })
        .attr('cy', (d) => {
          // eslint-disable-next-line
          d.y = Math.max(d.radius, Math.min(height - d.radius, d.y));
          return d.y;
        });

      label.attr('transform', d => `translate(${d.x}, ${d.y})`);

      labelCounts.attr('transform', (d) => {
        const textSize = calculateRadius(fontMaxSize, fontMinSize, maxVal, minVal, d.value);
        return `translate(${d.x}, ${(d.y + textSize)})`;
      });
    });
  }


  render() {
    const {
      width,
      height,
    } = this.props;
    return (
      <svg width={width} height={height} />
    );
  }
}

Force.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  padding: PropTypes.number,
  clusterPadding: PropTypes.number,
  charge: PropTypes.number,
  gravity: PropTypes.number,
  linkDistance: PropTypes.number,
  bubbleMaxSize: PropTypes.number,
  bubbleMinSize: PropTypes.number,
  fontMaxSize: PropTypes.number,
  fontMinSize: PropTypes.number,
  nodeColor: PropTypes.string,
  defaultOpacity: PropTypes.number,
  selectedOpacity: PropTypes.number,
  onSelectNode: PropTypes.func,
};

Force.defaultProps = {
  padding: 20,
  clusterPadding: 15,
  charge: -150,
  gravity: 0.06,
  linkDistance: 100,
  bubbleMaxSize: 40,
  bubbleMinSize: 20,
  fontMaxSize: 14,
  fontMinSize: 10,
  nodeColor: '#5a96f9',
  defaultOpacity: 1,
  selectedOpacity: 0.2,
  onSelectNode: () => {},
};

export default withStyles(() => ({}), { withTheme: true })(Force);
