import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AsyncMultiSelect from 'components/base/AsyncMultiSelect';
import { getKeywordAutoComplete } from 'redux/autocompletes/actions';

const MINIMUM_INPUT_CHARS = 3;

class KeywordAutoComplete extends Component {
  // TODO: add debounce
  loadOptions = async (inputValue, callback) => {
    if (inputValue.length < MINIMUM_INPUT_CHARS) {
      callback([]);
      return;
    }
    await this.props.getKeywordAutoComplete(inputValue);
    callback(this.props.keywordAutoComplete.results);
  }

  render() {
    const {
      onChange, handleRemoveKeyword, keywords, placeholder,
    } = this.props;
    return (
      <div>
        <AsyncMultiSelect
          name="categories"
          value={keywords}
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={this.loadOptions}
          handleRemoveSelection={handleRemoveKeyword}
          defaultOptions
          minChars={MINIMUM_INPUT_CHARS}
          isMulti
        />
      </div>
    );
  }
}

KeywordAutoComplete.propTypes = {
  getKeywordAutoComplete: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  })),
  keywordAutoComplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  handleRemoveKeyword: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

KeywordAutoComplete.defaultProps = {
  onChange: null,
  keywords: null,
  placeholder: 'Find Keywords',
};

export default connect(
  state => ({
    keywordAutoComplete: state.keywordAutoComplete,
  }),
  { getKeywordAutoComplete },
)(KeywordAutoComplete);
