import React from 'react';
import Button from 'components/base/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import styles from './styles';

const NoResults = ({
  classes,
  openModal,
  username,
}) => (
  <React.Fragment>
    <div className={classes.root}>
      <Typography
        className={classes.no_result__message}
        variant="body1"
      >
        <b>{username}</b> doesn&apos;t exist in system.
      </Typography>
      <Button
        type="button"
        className={classes.btn__add}
        variant="contained"
        onClick={openModal}
      >
        Add now
      </Button>
    </div>
  </React.Fragment>
);

NoResults.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  openModal: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

NoResults.defaultProps = {};

export default withStyles(styles)(NoResults);

