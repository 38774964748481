const ICON_CONFIG = {
  LOW_RISK: {
    className: 'brandSafetyIconSafe',
    icon: 'verified_user',
  },
  MEDIUM_RISK: {
    className: 'brandSafetyIconIssues',
    icon: 'gpp_maybe',
  },
  HIGH_RISK: {
    className: 'brandSafetyIconIssues',
    icon: 'gpp_maybe',
  },
  ADD: {
    className: 'brandSafetyIconAdd',
    icon: 'add_moderator',
  },
  null: {
    className: 'brandSafetyIconDefault',
    icon: 'safety_check',
  },
};

export default ICON_CONFIG;
