export default theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    padding: '0.8rem',
    backgroundColor: theme.palette.white.dark,
    boxShadow: theme.customShadows[1],
    marginTop: '0.1rem',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  btn__add: {
    float: 'right',
    flex: 1,
    backgroundColor: theme.palette.green.main,
  },
  no_result__message: {
    flexGrow: 1,
    color: theme.palette.black.light,
  },
});
