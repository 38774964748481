export default theme => ({
  root: {
    paddingRight: '8rem',
  },
  platform__item: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  username: {
    paddingRight: '1rem',
    flexGrow: '1',
    '& input': {
      height: '2.25rem',
    },
  },
  btn__remove: {
    backgroundColor: theme.palette.red.main,
    padding: '4px 16px',
  },
  remove_btn__container: {
    width: '84px',
  },
  btn__add: {
    backgroundColor: theme.palette.green.main,
  },
  form: {
    marginBottom: '4rem',
  },
});
