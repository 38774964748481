import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';

export const GET_COUNTRIES_AUTOCOMPLETE = 'GET_COUNTRIES_AUTOCOMPLETE';
export const GET_LOCATIONS_AUTOCOMPLETE = 'GET_LOCATIONS_AUTOCOMPLETE';
export const GET_KEYWORD_AUTOCOMPLETE = 'GET_KEYWORD_AUTOCOMPLETE';
export const GET_LABEL_AUTOCOMPLETE = 'GET_LABEL_AUTOCOMPLETE';
export const GET_USERNAME_AUTOCOMPLETE = 'GET_USERNAME_AUTOCOMPLETE';
export const GET_BRAND_AUTOCOMPLETE = 'GET_BRAND_AUTOCOMPLETE';

export const getLocationAutoComplete = value => async (dispatch) => {
  await dispatch({
    type: GET_LOCATIONS_AUTOCOMPLETE,
    payload: request('get', API.LOCATION_AUTO_COMPLETE, {
      params: {
        query: value,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getCountriesAutoComplete = value => async (dispatch) => {
  await dispatch({
    type: GET_COUNTRIES_AUTOCOMPLETE,
    payload: request('get', API.COUNTRIES_AUTO_COMPLETE, {
      params: {
        query: value,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getKeywordAutoComplete = value => async (dispatch) => {
  await dispatch({
    type: GET_KEYWORD_AUTOCOMPLETE,
    payload: request('get', API.KEYWORD_AUTO_COMPLETE, {
      params: {
        query: value,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getLabelAutoComplete = value => async (dispatch) => {
  await dispatch({
    type: GET_LABEL_AUTOCOMPLETE,
    payload: request('get', API.LABELS_AUTO_COMPLETE, {
      params: {
        query: value,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getUsernameAutoComplete = value => async (dispatch) => {
  await dispatch({
    type: GET_USERNAME_AUTOCOMPLETE,
    payload: request('get', API.USERNAMES_AUTO_COMPLETE, {
      params: {
        query: value,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getBrandAutoComplete = value => async (dispatch) => {
  await dispatch({
    type: GET_BRAND_AUTOCOMPLETE,
    payload: request('get', API.BRANDS_AUTO_COMPLETE, {
      params: {
        query: value,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.SEARCH_BRAND_AUTOCOMPLETE, { value });
};

