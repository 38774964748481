import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { formatNumber } from 'utils/helpers';
import styles from './styles';

const NULL_VALUE_DISPLAY = 'N/A';

const Stats = ({
  classes,
  totalReach,
  avgEngagement,
  avgComments,
  avgSponsoredEngagement,
}) => (
  <div className={classes.statSection}>
    <Grid container className={classes.statContainer}>
      <Grid item xs>
        <div className={classes.statValue}>
          <Typography variant="h6">{formatNumber(totalReach, '0a', NULL_VALUE_DISPLAY)}</Typography>
        </div>
        <div className={classes.statLabel}>
          <Typography variant="body1">Total reach</Typography>
        </div>
      </Grid>
      <Grid item xs>
        <div className={classes.statValue}>
          <Typography variant="h6">{formatNumber(avgEngagement / 100, '0.00%', NULL_VALUE_DISPLAY)}</Typography>
        </div>
        <div className={classes.statLabel}>
          <Typography variant="body1">Eng. rate</Typography>
        </div>
      </Grid>
      {
        avgSponsoredEngagement ?
          <Grid item xs>
            <div className={classes.statValue}>
              <Typography variant="h6">
                {formatNumber(avgSponsoredEngagement / 100, '0.00%', NULL_VALUE_DISPLAY)}
              </Typography>
            </div>
            <div className={classes.statLabel}>
              <Typography variant="body1">Spon Eng. rate</Typography>
            </div>
          </Grid> :
          null


      }
      <Grid item xs>
        <div className={classes.statValue}>
          <Typography variant="h6">{formatNumber(avgComments, '0a', NULL_VALUE_DISPLAY)}</Typography>
        </div>
        <div className={classes.statLabel}>
          <Typography variant="body1">Comments</Typography>
        </div>
      </Grid>
    </Grid>
  </div>
);

Stats.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  totalReach: PropTypes.number,
  avgComments: PropTypes.number,
  avgEngagement: PropTypes.number,
  avgSponsoredEngagement: PropTypes.number,
};

Stats.defaultProps = {
  totalReach: 0,
  avgComments: 0,
  avgEngagement: 0,
  avgSponsoredEngagement: 0,
};

export default withStyles(styles)(Stats);
