import React, { PureComponent } from 'react';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import { Container, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';
import withAll from 'components/base/withAll';
import { withRouter } from 'react-router';
import logo from 'images/logo.png';
import animatedLanding from 'images/animated-landing.gif';
import landingMetaImage from 'images/landing-meta-image.png';
import backgroundImg from 'images/prism-landing-background.png';
import { faChartPie, faDatabase, faUserShield, faChartArea } from '@fortawesome/free-solid-svg-icons';
import styles from './styles';
import { home, register } from '../../../constants/routing';
import DetailContainer from './DetailContainer';

class SEMLandingPage extends PureComponent {
  trackRegisterClick = (event) => {
    mpTrack(event, {});
    this.props.history.push(register());
  };

  render() {
    const { classes, titleHtml, titleText } = this.props;
    const meta = {
      title: titleText,
      meta: {
        charset: 'utf-8',
        name: {
          image: landingMetaImage,
        },
      },
    };

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <Container style={{ backgroundImage: `url(${backgroundImg})` }} className={classes.container}>
          <div className={classes.mainHeader}>
            <Link to={home()}>
              <div className={classes.logo}><img src={logo} alt="Prism" /></div>
            </Link>

            <Link to={register()} onClick={() => this.trackRegisterClick(MP_EVENTS.SEM_LANDING_PAGE_SIGNUP_TOP)}>
              <Button className={classes.topSignUp} color="primary" variant="contained">Signup</Button>
            </Link>
          </div>


          <div className={classes.mainContent}>
            <div className={classes.topLeftPanel}>
              <div id="topLeftPanelHeaderTitle" className={classes.topLeftPanel_header}>
                {titleHtml}
              </div>
              <div id="topLeftPanelBodyTitle" className={classes.topLeftPanel_body}>
                Cut through the noise of social media and get <br />
                actionable insights on demand utilizing our free <br />
                influencer marketing database during your 7-day <br />
                trial.
              </div>
              <Link
                to={register()}
                className={classes.link}
                onClick={() => this.trackRegisterClick(MP_EVENTS.SEM_LANDING_PAGE_TRY_FOR_FREE_MIDDLE)}
              >
                <Button color="primary" variant="contained" className={classes.topLeftPanel_tryItForFreeBtn}>
                  Try for free
                </Button>
              </Link>
            </div>
            <div className={classes.topRightPanel}>
              <img src={animatedLanding} className={classes.topRightPanel_img} alt="animation" />
            </div>
          </div>

          <div className={classes.pageMiddle}>
            <div className={classes.pageMiddle_header}>
              <div id="pageMiddleHeader">The Power of Data at your Fingertips</div>
            </div>
            <div id="pageMiddleBody" className={classes.pageMiddle_body}>
              Go Prism cuts through the noise of social media to provide actionable insights, allowing brands to
              determine competitive <br /> benchmarks, understand campaign efficacy and make more informed decisions
              around targeting and spend.
            </div>
            <div className={classes.pageMiddle_detailsList}>
              <div className={classes.pageMiddle_detail_rowTop}>
                <DetailContainer
                  icon={faDatabase}
                  title="INFLUENCER DATABASE"
                  description="We provide you with the most comprehensive and searchable influencer database"
                />
                <DetailContainer
                  classes={classes}
                  icon={faChartPie}
                  title="COMPETITIVE REPORTS"
                  description="Keep an eye on your competitors with our competitor benchmarking report"
                />
                <DetailContainer
                  classes={classes}
                  icon={faUserShield}
                  title="BRAND SAFETY"
                  description="With a press of a button you can quickly identify potentially objectionable
                  content from influencers before putting them on your campaigns."
                />
              </div>
              <div className={classes.pageMiddle_detail_rowBottom}>
                <DetailContainer
                  classes={classes}
                  icon={faChartArea}
                  title="INDUSTRY BENCHMARKING"
                  description="Pull in-depth performance benchmarking across
                    both, organic and paid social content."
                />
              </div>

              <div className={classes.pageMiddle}>
                <div id="pageMiddleHeader" className={classes.pageMiddle_header}>
                  <div>Here is what people are saying</div>
                </div>
                <div id="pageMiddleBOdy" className={classes.pageMiddle_body}>
                  “The Competitive Insights, Performance Benchmarks, and Brand Safety features <br /> have been a
                  game-changer for us as we look to earn RFPs from major retail brands,” <br />
                  said Alden Reiman, CEO and Founder of The Reiman Agency.
                </div>
              </div>

              <div className={classes.action}>
                <div id="actionTitle" className={classes.action_title}>Start your 7-day Free Trial</div>
                <div id="actionDesc" className={classes.action_desc}>No credit card required</div>
                <Link
                  to={register()}
                  onClick={() => this.trackRegisterClick(MP_EVENTS.SEM_LANDING_PAGE_TRY_FOR_FREE_BOTTOM)}
                >
                  <Button className={classes.action_btn} color="primary" variant="contained">Try for free</Button>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

SEMLandingPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  titleHtml: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAll(
  withStyles(styles),
  withRouter,
)(SEMLandingPage);
