import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import withPermission from 'components/common/withPermission';
import InfluencerCard from 'components/common/InfluencerCard';
import { CardContent, Grid } from '@material-ui/core';
import Card from 'components/base/Card';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import H6 from 'components/base/titles/CardHeading/H6';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';
import styles from './styles';

const MonitorTopCreators = ({
  classes, creators, permitted, permittedProfileViews,
}) => (
  <Card>
    <CardContent>
      <H6>
        <LabelWithInfoIcon
          labelText="Top Creators"
          tooltip="Top creators that have mentioned the #hashtag(s) or @mention(s)."
        />
      </H6>
      {isEmpty(creators) && (
        <Alert severity="warning">
              We don&apos;t have sufficient data for this monitor. Please try again later.
        </Alert>
      )}
      <Grid container spacing={2}>
        {creators.map(p => (
          <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
            <InfluencerCard
              influencer={p}
              canViewProfile={permitted}
              permittedProfileViews={permittedProfileViews}
              canViewTopBrands
              canSaveToList={false}
            />
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

MonitorTopCreators.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  creators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  permitted: PropTypes.bool,
  permittedProfileViews: PropTypes.shape({}),
};

MonitorTopCreators.defaultProps = {
  permitted: false,
  permittedProfileViews: {},
};

const mapMetaToProps = ({ views }) => ({
  permittedProfileViews: views,
});

export default withAll(
  withPermission('influencer_profile', null, mapMetaToProps, true),
  withStyles(styles),
)(MonitorTopCreators);
