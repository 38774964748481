import { newReducer } from '../utils';

const initialState = {
  data: {},
  loading: true,
  // using this property to check if configs have previously been fetched.
  loaded: false,
};

// eslint-disable-next-line import/prefer-default-export
export const configReducer = newReducer(initialState);

configReducer.method.FETCH_CONFIG_FULFILLED = (state = initialState, action) => ({
  ...state,
  data: {
    ...state.data,
    ...action.payload,
  },
  loading: false,
  loaded: true,
});

configReducer.method.FETCH_CONFIG_PENDING = (state = initialState) => ({
  ...state,
  data: {},
  loading: true,
});

configReducer.method.FETCH_CONFIG_REJECTED = (state = initialState) => ({
  ...state,
  data: {},
  loading: false,
  loaded: true,
});
