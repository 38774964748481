export default theme => ({
  wrapper: {
    paddingTop: '2rem',
  },
  logo: {
    position: 'relative',
    height: '4em',
    width: '18em',
    margin: '0 auto',
  },
  planGrid: {
    marginBottom: '2rem',
    justifyContent: 'center',
  },
  finishMessage: {
    fontSize: '26px',
    textAlign: 'center',
    margin: '10rem auto',
  },
  link: {
    color: theme.palette.blue.main,
    textDecoration: 'none',
  },
  trialSignup: {
    textAlign: 'center',
  },
});
