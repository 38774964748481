import { APIError } from 'lib/errors';
import { serverUrl } from 'utils/env';
import { recursiveFunc } from 'utils/mixins';
import { camelCase, get, mapKeys } from 'lodash';
import { API_CONFIG } from 'constants/config';
import Cookies from 'js-cookie';
import axios from 'axios';
import qs from 'query-string';
import { clearAuth } from '../redux/authentication/actions';
import { setServerDown, setServerUp } from '../redux/app/actions';
import store from '../redux/store';

export const REQUEST_TIMEOUT_MS = 600000;

const camelCaseIfNotId = v => (v.includes('-') ? v : camelCase(v));

const extractResult = (response) => {
  store.dispatch(setServerUp);

  const code = response.status;
  const body = response.data;

  if (code === 200) {
    // convert keys to camelCase and return
    return recursiveFunc(mapKeys)(body, (v, k) => camelCaseIfNotId(k));
  }
  if (code === 401) {
    Cookies.remove(API_CONFIG.TOKEN_NAME);
  }
  throw new APIError(code, body.error);
};

export const request = (method, url, options) => new Promise((resolve, reject) => {
  if (!url) {
    return reject(new APIError(400, 'Request url is a required field'));
  }
  if (!options) {
    return reject(new APIError(400, 'Request options is a required field'));
  }
  if (!method) {
    return reject(new APIError(400, 'Request method is a required field'));
  }
  const headers = {};
  const token = Cookies.get(API_CONFIG.TOKEN_NAME);
  if (token) {
    headers.Authorization = `Token ${token}`;
  }
  const reqOptions = {
    timeout: REQUEST_TIMEOUT_MS,
    url: serverUrl(url),
    paramsSerializer: params => qs.stringify(params),
    headers,
    method,
    ...options,
  };
  // because of Safari https://stackoverflow.com/a/44479078/5751147
  axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
  axios.defaults.xsrfCookieName = 'csrftoken';
  return axios(reqOptions)
    .then(extractResult)
    .then(resolve)
    .catch((response) => {
      if (!response.response) {
        store.dispatch(setServerDown);
      } else {
        store.dispatch(setServerUp);
      }
      if (get(response, ['response', 'status']) === 401) {
        store.dispatch(clearAuth);
      }
      return reject(response);
    });
});

export const tokenThunk = f => (dispatch, getState) => {
  f(dispatch, getState().auth.sessionToken);
};
