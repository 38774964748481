import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import PlatformIcon from 'components/common/PlatformIcon';
import { getUnusualEngagementPosts as getUnusualEngagementPostsAction } from 'redux/brandSafetyReports/actions';
import RankBy from 'components/app/BrandSafetyReport/RankBy';
import Post from 'components/app/BrandSafetyReport/Post';
import { RankByComparators, RankByField, filterIfRankBySponsored } from 'components/app/BrandSafetyReport/utils';
import { RANK_ERROR_TEXT } from 'components/app/BrandSafetyReport/constants';
import styles from './styles';

class UnusualPostEngagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlatforms: [...new Set(this.props.data.posts.map(p => p.platform))],
      rankByField: RankByField.Date,
    };
  }

  async componentDidMount() {
    const { report, getUnusualEngagementPosts } = this.props;
    await getUnusualEngagementPosts(report.id);
    this.updateStateFromProps();
  }

  onRankChange = (rankBy) => {
    this.setState({ rankByField: rankBy });
  }

  onPlatformToggle = (platform, checked) => {
    const { selectedPlatforms } = this.state;
    if (checked) {
      selectedPlatforms.push(platform);
    } else {
      const index = selectedPlatforms.indexOf(platform);
      if (index > -1) {
        selectedPlatforms.splice(index, 1);
      }
    }
    this.setState({ selectedPlatforms });
  };

  updateStateFromProps() {
    const { data } = this.props;
    this.setState({
      selectedPlatforms: [...new Set(data.posts.map(p => p.platform))],
    });
  }

  render() {
    const {
      classes,
      data,
      isLoading,
      loaded,
    } = this.props;
    const { selectedPlatforms, rankByField } = this.state;
    const platforms = [...new Set(data.posts.map(p => p.platform))];
    let posts = data.posts.filter(p => selectedPlatforms.includes(p.platform));

    posts = filterIfRankBySponsored(rankByField, posts, () => {
      toast.error(RANK_ERROR_TEXT);
      this.onRankChange(RankByField.Interactions);
    });
    posts.sort(RankByComparators[rankByField]);

    return (
      <div className={classes.root}>
        <Loader loading={isLoading} loaded={loaded}>
          {platforms.length ? (
            <div className={classes.header}>
              <div className={classes.postedOn}>
                <div className={classes.postedOnLabel}>Posted on:</div>
                {platforms.map(p => <PlatformIcon key={p} platform={p} onToggle={this.onPlatformToggle} />)}
              </div>
              <RankBy onRankChange={this.onRankChange} activeRank={rankByField} />
            </div>
          ) : null}
          <Grid container spacing={2}>
            {posts.length ? posts.map(p => (
              <Grid item md={3} key={p.postId}>
                <Post post={p} showAnomalyEngagement />
              </Grid>
            )) : (
              <Typography variant="h5" className={classes.noResults}>No results</Typography>
            )}
          </Grid>
        </Loader>
      </div>
    );
  }
}

UnusualPostEngagement.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  getUnusualEngagementPosts: PropTypes.func.isRequired,
  data: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({
      platform: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ brandSafetyReportUnusualEngagementPosts }) => ({
  data: brandSafetyReportUnusualEngagementPosts.data,
  isLoading: brandSafetyReportUnusualEngagementPosts.loading,
  loaded: brandSafetyReportUnusualEngagementPosts.loaded,
});

export default withAll(
  withStyles(styles),
  connect(mapStateToProps, { getUnusualEngagementPosts: getUnusualEngagementPostsAction }),
)(UnusualPostEngagement);
