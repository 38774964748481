import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import withAll from 'components/base/withAll';
import {
  getCompetitiveReports as getCompetitiveReportsAction,
  refreshCompetitiveReport as refreshCompetitiveReportAction,
} from 'redux/reports/actions';
import styles from './styles';

const SuccessMessage = () => <React.Fragment>Report is being refreshed, check back shortly.</React.Fragment>;

const RefreshAction = ({
  classes,
  report,
  isLoading,
  getCompetitiveReports,
  refreshCompetitiveReport,
}) => {
  const onClick = async () => {
    await refreshCompetitiveReport(report.id);
    toast.success(<SuccessMessage />);
    getCompetitiveReports();
  };

  return (
    <React.Fragment>
      <Button className={classes.root} onClick={onClick} disabled={isLoading}>
        <FontAwesomeIcon icon={faRedo} />
      </Button>
    </React.Fragment>
  );
};

RefreshAction.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getCompetitiveReports: PropTypes.func.isRequired,
  refreshCompetitiveReport: PropTypes.func.isRequired,
};

const mapStateToProps = ({ brandSafetyReport }) => ({
  isLoading: brandSafetyReport.loading,
});

const mapDispatchToProps = {
  getCompetitiveReports: getCompetitiveReportsAction,
  refreshCompetitiveReport: refreshCompetitiveReportAction,
};

export default withAll(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(RefreshAction);
