import React from 'react';
import Avatar from './Avatar';

// eslint-disable-next-line react/prefer-stateless-function
class RightOptions extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Avatar />
      </React.Fragment>
    );
  }
}


export default RightOptions;
