import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Icon, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Tooltip from 'components/base/Tooltip';
import AddReportButton from './AddReportButton';
import styles from './styles';
import { buildBrandSafetyReportsUrl } from '../../../../constants/routing';
import ICON_CONFIG from '../../../../constants/brandSafetyReports';

const BrandSafetyIcon = ({ classes, influencer, canViewBrandSafety }) => {
  const doesReportExist = brandSafetyReport => !isEmpty(brandSafetyReport.id);
  const brandSafetyIndex =
    doesReportExist(influencer.brandSafetyReport) ? influencer.brandSafetyReport.index : 'ADD';
  const config = get(ICON_CONFIG, brandSafetyIndex);

  const [disabled, setDisabled] = React.useState(false);
  const titleText = doesReportExist(influencer.brandSafetyReport) ? 'View Safety Report' : 'Run Brand Safety Report';

  return (
    <Tooltip
      title={disabled ? '' : titleText}
    >
      <div className={classes.brandSafetyIconWrapper}>
        {doesReportExist(influencer.brandSafetyReport) ?
          (
            <IconButton
              className={classes.brandSafetyIconBackground}
              href={buildBrandSafetyReportsUrl(influencer.brandSafetyReport.id)}
            >
              <Icon className={classes[config.className]}>{config.icon}</Icon>
            </IconButton>
          ) : (
            <AddReportButton
              permitted={canViewBrandSafety}
              onModalChangeState={setDisabled}
              preselectedInfluencer={influencer}
              modalTitle={
                `Run Brand Safety Report for ${influencer.name}`
              }
            >
              <Icon className={classes[config.className]}>{config.icon}</Icon>
            </AddReportButton>
          )
        }
      </div>
    </Tooltip>
  );
};

BrandSafetyIcon.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  canViewBrandSafety: PropTypes.bool.isRequired,
  influencer: PropTypes.shape({
    brandSafetyReport: PropTypes.shape({
      id: PropTypes.string.isRequired,
      index: PropTypes.oneOf(['LOW_RISK', 'MEDIUM_RISK', 'HIGH_RISK', null]),
    }).isRequired,
  }).isRequired,
};

export default withStyles(styles)(BrandSafetyIcon);
