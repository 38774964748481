import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

export default withStyles(theme => ({
  root: {
    width: '100%',
    textTransform: 'none',
    maxWidth: 'none',
    textAlign: 'left',
    paddingLeft: '1.8rem',
    '& svg': {
      verticalAlign: 'middle',
      marginRight: '0.8rem',
      color: theme.palette.border.dark,
    },
  },
  wrapper: {
    display: 'inline-block',
  },
  selected: {
    borderRight: `2px solid ${theme.palette.blue.main}`,
    boxShadow: theme.customShadows[1],
    backgroundColor: theme.palette.white.main,
    color: theme.palette.blue.main,
    '& svg': {
      color: theme.palette.blue.main,
    },
  },
}))(Tab);
