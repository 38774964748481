export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.8rem',
    background: theme.palette.white.main,
    color: theme.palette.black.dark,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  platformIcon: {
    display: 'inline-flex',
  },
  usernameWrapper: {
    fontSize: '12px',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  username: {
    color: theme.palette.black.main,
  },
  date: {},
  body: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.8rem',
    minHeight: '13rem',
  },
  image: {
    position: 'relative',
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxHeight: '13rem',
      maxWidth: '100%',
    },
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1rem',
  },
  stat: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.8rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  statValue: {
    fontWeight: 'bold',
    color: theme.palette.black.main,
    lineHeight: '1rem',
  },
  statTitle: {
    fontSize: '0.8rem',
    lineHeight: '0.8rem',
  },
  caption: {
    fontSize: '12px',
    height: '3.1rem',
    overflow: 'hidden',
    marginBottom: '0.4rem',
    marginTop: '0.8rem',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  objectionableList: {
    fontSize: '12px',
    display: 'flex',
    marginTop: '0.2rem',
  },
  objectionableListTitle: {
    fontWeight: 'bold',
  },
  objectionableListValue: {
    display: 'flex',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  anomalyEngagementContent: {
    fontSize: '12px',
    color: theme.palette.black.light,
    marginTop: '0.2rem',
  },
  imgNotAvailTextClass: {
    marginTop: '1rem',
    height: 'auto',
  },
});
