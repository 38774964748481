import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import {
  getCompetitiveReportVerticalBreakdown as getCompetitiveReportVerticalBreakdownAction,
} from 'redux/reports/actions';
import { withRouter } from 'react-router';
import { getDaysFromDate, getDateFromDays } from 'utils/helpers';
import ChipFilter from 'components/base/ChipFilter';
import DateFilter from 'components/common/DateFilter';
import { get } from 'lodash';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

const VerticalSelect = ({
  params,
  classes,
  getCompetitiveReportVerticalBreakdown,
  verticals,
  match: { params: { reportId } },
}) => {
  const handleVerticalSelect = vertical => getCompetitiveReportVerticalBreakdown(
    reportId,
    {
      ...params,
      vertical,
    },
  );

  return (
    <div className={classes.root}>
      {verticals.map(v => (
        <ChipFilter
          buttonSelected={v === params.vertical}
          label={v}
          onClick={() => handleVerticalSelect(v)}
        />
      ))}
    </div>
  );
};

const DateSelect = ({
  params,
  getCompetitiveReportVerticalBreakdown,
  match: { params: { reportId } },
}) => {
  const handleDateSelect = (fromDate, toDate) => {
    getCompetitiveReportVerticalBreakdown(
      reportId,
      {
        ...params,
        postedWithin: getDaysFromDate(fromDate),
        postedBefore: getDaysFromDate(toDate),
      },
    );
  };

  return (<DateFilter
    handleSelect={handleDateSelect}
    selectedFromDate={getDateFromDays(params.postedWithin)}
    selectedToDate={getDateFromDays(params.postedBefore)}
    customDate
  />);
};


const propTypes = {
  getCompetitiveReportVerticalBreakdown: PropTypes.func.isRequired,
  params: PropTypes.shape({
    vertical: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

VerticalSelect.propTypes = {
  ...propTypes,
  verticals: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.shape({}).isRequired,
};
DateSelect.propTypes = propTypes;

const mapStateToProps = ({ competitiveReportVerticalBreakdown, competitiveReportStat }) => ({
  params: competitiveReportVerticalBreakdown.params,
  verticals: get(competitiveReportStat, 'data.verticals'),
});

export const HeaderVerticalFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportVerticalBreakdown: getCompetitiveReportVerticalBreakdownAction }),
  withStyles(styles),
  withRouter,
)(VerticalSelect);

export const HeaderDateFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportVerticalBreakdown: getCompetitiveReportVerticalBreakdownAction }),
  withRouter,
)(DateSelect);

