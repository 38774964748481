import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { get } from 'lodash';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const AGE_GROUPS_LABELS_MAP = {
  1218: '12-18',
  1925: '19-25',
  2632: '26-32',
  3339: '33-39',
  4046: '40-46',
  47: '47>',
};
const AGE_GROUPS_VALUES_MAP = {
  1218: '12-18',
  1925: '19-25',
  2632: '26-32',
  3339: '33-39',
  4046: '40-46',
  47: '47',
};
const AGE_GROUPS_ORDER = [
  1218,
  1925,
  2632,
  3339,
  4046,
  47,
];

const JULIUS_AGE_GROUPS_LABELS_MAP = {
  16: '<16',
  1719: '17 - 19',
  2024: '20 - 24',
  2529: '25 - 29',
  3034: '30 - 34',
  3539: '35 - 39',
  4049: '40 - 49',
  5059: '50 - 59',
  60: '60+',
};
const JULIUS_AGE_GROUPS_VALUES_MAP = {
  16: '16',
  1719: '17 - 19',
  2024: '20 - 24',
  2529: '25 - 29',
  3034: '30 - 34',
  3539: '35 - 39',
  4049: '40 - 49',
  5059: '50 - 59',
  60: '60',
};
const JULIUS_AGE_GROUPS_ORDER = [
  16,
  1719,
  2024,
  2529,
  3034,
  3539,
  4049,
  5059,
  60,
];

const BAR_HEIGHT = 220;

class AgeChart extends Component {
  transformValues = () => {
    const { ageData, isJuliusData } = this.props;
    if (isJuliusData || ageData['60']) {
      return JULIUS_AGE_GROUPS_ORDER.map(ageGroup => get(ageData, JULIUS_AGE_GROUPS_VALUES_MAP[ageGroup], 0.0) / 100);
    }
    return AGE_GROUPS_ORDER.map(ageGroup => get(ageData, AGE_GROUPS_VALUES_MAP[ageGroup], 0.0) / 100);
  }
  generateBarBackground = () => {
    const { ageData, isJuliusData } = this.props;
    if (isJuliusData || ageData['60']) {
      return JULIUS_AGE_GROUPS_ORDER.map(ageGroup => 1 - (ageData[JULIUS_AGE_GROUPS_VALUES_MAP[ageGroup]] / 100));
    }
    return AGE_GROUPS_ORDER.map(ageGroup => 1 - (ageData[AGE_GROUPS_VALUES_MAP[ageGroup]] / 100));
  }
  generateBarChartColors = () => {
    const { ageData, isJuliusData } = this.props;
    if (isJuliusData || ageData['60']) {
      return JULIUS_AGE_GROUPS_ORDER.map(ageGroup =>
        `rgba(31, 164, 231, ${(ageData[JULIUS_AGE_GROUPS_VALUES_MAP[ageGroup]] / 100) + 0.2})`);
    }
    return AGE_GROUPS_ORDER.map(ageGroup =>
      `rgba(31, 164, 231, ${(ageData[AGE_GROUPS_VALUES_MAP[ageGroup]] / 100) + 0.2})`);
  }

  generateLabels = () => {
    const { ageData, isJuliusData } = this.props;
    if (isJuliusData || ageData['60']) {
      return JULIUS_AGE_GROUPS_ORDER.map(ageGroup => JULIUS_AGE_GROUPS_LABELS_MAP[ageGroup]);
    }
    return AGE_GROUPS_ORDER.map(ageGroup => AGE_GROUPS_LABELS_MAP[ageGroup]);
  }

  dataLabelFormatter = (value) => {
    const { ageData, isJuliusData } = this.props;
    if (isJuliusData || ageData['60']) {
      return `${(value * 100).toFixed(0)}%`;
    }
    return `${(value * 100).toFixed(2)}%`;
  }


  render() {
    const { theme, classes } = this.props;
    const data = {
      labels: this.generateLabels(),
      datasets: [{
        data: this.transformValues(),
        backgroundColor: this.generateBarChartColors(),
        borderWidth: 1,
        borderColor: theme.palette.border.main,
        datalabels: {
          display: true,
          align: 'end',
          anchor: 'end',
          font: { size: 14 },
          formatter: value => this.dataLabelFormatter(value),
        },
      }, {
        label: false,
        data: this.generateBarBackground(),
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: theme.palette.border.main,
      }],
    };
    const options = {
      responsive: true,
      tooltips: {
        enabled: false,
      },
      legend: false,
      title: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [{
          display: false,
          stacked: true,
          barPercentage: 1,
          ticks: {
            min: 0,
            max: 1,
            beginAtZero: true,
          },
        }],
        xAxes: [{
          ticks: {
            fontSize: 14,
            fontColor: theme.palette.black.light,
          },
          drawTicks: false,
          stacked: true,
          gridLines: {
            lineWidth: 0,
          },
          display: true,
        }],
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    };
    return (
      <div className={classes.root} >
        <Bar height={BAR_HEIGHT} data={data} options={options} redraw />
      </div>
    );
  }
}

AgeChart.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  ageData: PropTypes.shape({}).isRequired,
  isJuliusData: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(AgeChart);
