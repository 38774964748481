import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import { withStyles } from '@material-ui/styles';
import { CardContent, Grid, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { formatNumber, isPositiveNumber } from 'utils/helpers';

const NULL_VALUE_DISPLAY = 'N/A';

const styles = theme => ({
  root: {
    border: '1px solid',
    background: 'none',
    margin: '0.1rem',
    borderRadius: '0.5rem',
    borderColor: ({ value }) => (isPositiveNumber(value) ? theme.palette.green.main : theme.palette.red.main),
  },
  statValue: {
    fontSize: '1rem',
    display: 'block',
    color: ({ value }) => (isPositiveNumber(value) ? theme.palette.green.main : theme.palette.red.main),
  },
  iconContainer: {
    fontSize: '0.6rem',
    color: ({ value }) => (isPositiveNumber(value) ? theme.palette.green.main : theme.palette.red.main),
  },
  arrow: {
    fontSize: '1rem',
  },
  statCardContent: {
    padding: '10px',
    '&:last-child': {
      padding: '10px',
    },
  },
});

const StatCard = ({ value, classes }) => (
  <Card className={classes.root}>
    <CardContent className={classes.statCardContent}>
      <Grid container alignItems="center" spacing={0}>
        <Grid item xs="10">
          <Typography className={classes.statValue}>
            {formatNumber(value, '0.00%', NULL_VALUE_DISPLAY)}
          </Typography>
        </Grid>
        <Grid item xs="2" className={classes.iconContainer}>
          {isPositiveNumber(value) ?
            <ArrowUpwardIcon className={classes.arrow} /> :
            <ArrowDownwardIcon className={classes.arrow} />}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

StatCard.propTypes = {
  value: PropTypes.number.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(StatCard);
