import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'components/base/Button';
import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import { cloneDeep, isEmpty, get } from 'lodash';
import { requestNewInfluencers } from 'redux/influencers/actions';
import { toast } from 'react-toastify';
import styles from './styles';
import InfluencerPlatform from './InfluencerPlatform';
import PlatformToggleButton from './InfluencerPlatform/PlatformToggleButton';

const getPlatformKey = index => `platform-${index}`;

class RequestInfluencerForm extends Component {
  state = {
    platforms: [],
  }

  handleSubmit = (data) => {
    const { platforms } = this.state;
    this.setState({
      platforms: [
        ...platforms,
        data,
      ],
    });
  }

  saveInfluencers = async () => {
    const { platforms } = this.state;
    const { onSubmit } = this.props;
    try {
      await this.props.requestNewInfluencers(platforms);
      toast.success(`Thanks for your submission!. We’ll 
      notify you when those  influencers are available in the 
      system.`);
      onSubmit();
    } catch (e) {
      if (get(e, ['errors', 'error'])) {
        toast.error(e.errors.error);
      } else {
        toast.error('Server error, something went wrong.');
      }
    }
  }

  handleRemove = (index) => {
    const { platforms } = this.state;
    const newPlatforms = cloneDeep(platforms);
    newPlatforms.splice(index, 1);
    this.setState({
      platforms: newPlatforms,
    });
  }

  render() {
    const {
      classes,
    } = this.props;
    const { platforms } = this.state;
    return (
      <div className={classes.root}>
        {platforms.map((platform, index) => (
          <div key={getPlatformKey(index)} className={classes.platform__item}>
            <TextField
              type="text"
              value={platform.username}
              className={classes.username}
              disabled
            />
            <PlatformToggleButton
              input={{ value: platform.platform, onChange: () => {} }}
            />
            <div className={classes.remove_btn__container}>
              <Button
                variant="contained"
                className={classes.btn__remove}
                onClick={() => this.handleRemove(index)}
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
        <InfluencerPlatform
          platforms={platforms.map(platform => platform.username)}
          onSubmit={this.handleSubmit}
        />
        <div className={classes.btn__container}>
          <Button
            type="button"
            onClick={this.saveInfluencers}
            disabled={isEmpty(platforms)}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

RequestInfluencerForm.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  requestNewInfluencers: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default connect(
  null,
  { requestNewInfluencers },
)(withStyles(styles)(RequestInfluencerForm));
