import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { privacyPolicy, termsOfUse } from 'constants/routing';

const styles = theme => ({
  root: {
    color: theme.palette.black.dark,
    marginTop: '1rem',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.blue.main,
    textDecoration: 'none',
  },
});

const TermsAgreement = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="body1">
      <span>By signing up you agree to the following terms in our </span>
      <Link className={classes.link} to={termsOfUse()} target="_blank" rel="noopener noreferrer">
        Terms Of Use
      </Link>
      <span> and </span>
      <Link className={classes.link} to={privacyPolicy()} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </Link>
    </Typography>
  </div>
);

TermsAgreement.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(TermsAgreement);
