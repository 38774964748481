import React, { useState } from 'react';

// eslint-disable-next-line
function withMultipleModals(modalNames = []) {
  // eslint-disable-next-line
  return (WrappedComponent) => {
    // eslint-disable-next-line
    return function (props) {
      const [open, setOpen] = useState(modalNames.reduce((acc, elem) => {
        acc[elem] = false;
        return acc;
      }, {}));

      const openModal = (modalName) => {
        setOpen({
          ...open,
          [modalName]: true,
        });
      };

      const closeModal = (modalName) => {
        setOpen({
          ...open,
          [modalName]: false,
        });
      };

      return (
        <WrappedComponent
          open={open}
          openModal={openModal}
          closeModal={closeModal}
          {...props}
        />
      );
    };
  };
}

export default withMultipleModals;
