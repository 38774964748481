import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Logo from './index';

const styles = () => ({
  root: {
    textAlign: 'center',
    marginBottom: '10px',
    '& div': {
      display: 'inline-block',
      width: '50%',
    },
  },
});

const LoginLogo = props => <div className={props.classes.root}><Logo /></div>;

LoginLogo.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(LoginLogo);
