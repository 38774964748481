import React from 'react';
import { withStyles } from '@material-ui/styles';
import InputRange from 'react-input-range';
import styles from './styles';

const CustomInputRange = props => (
  <div className={props.classes.root}>
    <InputRange {...props} />
  </div>
);

export default withStyles(styles)(CustomInputRange);
