import React from 'react';
import InstagramFollowerFilter from './InstagramFollowerFilter';
import PinterestFollowerFilter from './PinterestFollowerFilter';
import TikTokFollowerFilter from './TikTokFollowerFilter';
import YouTubeFollowerFilter from './YouTubeFollowerFilter';

const FollowerFilters = () => (
  <React.Fragment>
    <InstagramFollowerFilter />
    <PinterestFollowerFilter />
    <TikTokFollowerFilter />
    <YouTubeFollowerFilter />
  </React.Fragment>
);

export default FollowerFilters;
