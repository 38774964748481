import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { influencerProfileUrl } from 'constants/routing';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { truncate } from 'lodash';
import { withStyles } from '@material-ui/styles';
import { getInfluencerName } from 'utils/influencers';
import cx from 'classnames';
import styles from './styles';

const INFLUENCER_NAME_MAX_LENGTH = 35;
const InfluencerName = (props) => {
  const {
    classes,
    influencer,
    canViewProfile,
  } = props;
  const [open, doSetOpen] = useState(false);
  return (
    <div className={classes.root}>
      {canViewProfile &&
        <Link to={influencerProfileUrl(influencer.id)} className={classes.link} >
          <Typography variant="h6" className={cx([classes.name, 'influencer-name'])}>
            {truncate(getInfluencerName(influencer), { length: INFLUENCER_NAME_MAX_LENGTH })}
          </Typography>
        </Link>
      }
      {!canViewProfile && !open &&
        <Typography
          variant="h6"
          onClick={() => { doSetOpen(true); }}
          className={cx([classes.name, 'influencer-name'])}
        >
          {truncate(getInfluencerName(influencer), { length: INFLUENCER_NAME_MAX_LENGTH })}
        </Typography>
      }
      {!canViewProfile && open && <BetaSignupModal
        open={open}
        closeModal={() => { doSetOpen(false); }}
        onSignupComplete={() => { doSetOpen(false); }}
      />}

    </div>
  );
};

InfluencerName.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  influencer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    platforms: PropTypes.shape({
      instagram: PropTypes.shape({
        username: PropTypes.string,
      }),
    }),
  }).isRequired,
  canViewProfile: PropTypes.bool,
};

InfluencerName.defaultProps = {
  canViewProfile: false,
};

export default withStyles(styles)(InfluencerName);
