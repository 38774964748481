import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Card from 'components/base/Card';
import Chip from 'components/base/Chip';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubble from '@material-ui/icons/ChatBubble';
import { PLAIN_PLATFORM_ICONS, PLATFORM_COLOR } from 'constants/options';
import { openInNewTab, formatNumber } from 'utils/helpers';
import { first, isEmpty } from 'lodash';
import ImageBoxWithDefault from 'components/common/ImageBoxWithDefault';

const NULL_VALUE_DISPLAY = 'N/A';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.customShadows[2],
    },
  },
  media: {
    height: '17rem',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  content: {
    flex: 1,
  },
  contentCopy: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
  },
  icon: {
    color: theme.palette.black.light,
    padding: '0 0.4rem',
  },
  statContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  engagement: {
    position: 'absolute',
    right: '0.4rem',
    top: '0.4rem',
  },
  brandUsername: {
    maxWidth: '9.2rem',
  },
  avatar: {
    backgroundColor: '#f0f000',
  },
  imgNotAvailTextClass: {
    top: '6rem',
  },
});

const calculateEngagment = post => (post.likes + post.comments) / post.followerCount;

const Post = ({
  classes,
  data,
  hideMention,
}) => (
  // eslint-disable-next-line
  <div className={classes.root} onClick={() => openInNewTab(data.permalink)}>
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" style={{ backgroundColor: PLATFORM_COLOR[data.platform] }}>
            {PLAIN_PLATFORM_ICONS[data.platform]}
          </Avatar>
        }
        title={data.username}
      />
      {!isEmpty(data.followerCount) &&
        <Tooltip title="Engagement">
          <Chip
            className={classes.engagement}
            color="primary"
            label={formatNumber(calculateEngagment(data), '0.00%', NULL_VALUE_DISPLAY)}
          />
        </Tooltip>
      }
      <ImageBoxWithDefault
        src={data.image}
        className={classes.media}
        imgNotAvailTextClass={classes.imgNotAvailTextClass}
      />
      <CardContent className={classes.content}>
        <Typography className={classes.contentCopy} variant="body2" color="textSecondary" component="p">
          {data.caption}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {!isEmpty(data.brandMention) && !isEmpty(hideMention) &&
          <Tooltip title="Brand mentioned">
            <Chip label={`@${first(data.brandMention)}`} className={classes.brandUsername} />
          </Tooltip>
        }
        <Tooltip title="Likes">
          <div className={classes.statContainer}>
            <Chip label={formatNumber(data.likes, '0a', NULL_VALUE_DISPLAY)} />
            <FavoriteIcon className={classes.icon} />
          </div>
        </Tooltip>
        <Tooltip title="Comments">
          <div className={classes.statContainer}>
            <Chip label={formatNumber(data.comments, '0a', NULL_VALUE_DISPLAY)} />
            <ChatBubble className={classes.icon} />
          </div>
        </Tooltip>
      </CardActions>
    </Card>
  </div>
);

Post.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  hideMention: PropTypes.bool,
  data: PropTypes.shape({
    username: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    likes: PropTypes.number.isRequired,
    comments: PropTypes.number.isRequired,
  }).isRequired,
};

Post.defaultProps = {
  hideMention: false,
};

export default withStyles(styles, { withTheme: true })(Post);
