import { get } from 'lodash';
import { config } from 'theme';
import { ALL_LABEL } from 'components/common/CompetitiveReport/constants';
import { DEFAULT_POSTED_WITHIN, DEFAULT_POSTED_BEFORE } from 'constants/options';
import moment from 'moment';
import { newReducer } from '../utils';
import { updateInfluencerBrandSafetyReport } from '../brandSafetyReports/utils';

const initialState = {
  competitiveReport: {
    loading: false,
    loaded: false,
    data: {
      brands: [],
    },
  },
  competitiveReportRefresh: {
    loading: false,
    loaded: false,
  },
  competitiveReportStats: {
    loading: false,
    loaded: false,
    data: {
      brands: [],
    },
  },
  competitiveReportExport: {
    loading: false,
    loaded: false,
    excelData: null,
  },
  competitiveReports: {
    loading: false,
    loaded: false,
    data: {
      results: [],
    },
  },
  competitiveReportPosts: {
    loading: false,
    params: {
      platforms: [],
      brands: [],
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    },
    loaded: false,
    data: {
      posts: [],
    },
  },
  competitiveReportVerticalBreakdown: {
    loading: false,
    params: {
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    },
    loaded: false,
    data: {},
  },
  competitiveReportCreators: {
    loading: false,
    params: {
      platforms: [],
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    },
    loaded: false,
    data: [],
  },
  competitiveReportDistribution: {
    loading: false,
    params: {
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    },
    loaded: false,
    data: {},
  },
  competitiveReportPostActivity: {
    loading: false,
    params: {
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    },
    loaded: false,
    data: {},
  },
  competitiveReportEstimatedSpend: {
    loading: false,
    params: {
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    },
    loaded: false,
    data: {},
  },
};
const LEGEND_COLORS = [
  config.palette.green.main,
  config.palette.red.main,
  config.palette.barChart.gradient.blue,
  config.palette.blue.dark,
  config.palette.black.light,
];

const getLegendColor = i => (LEGEND_COLORS.length >= i + 1 ? LEGEND_COLORS[i] : config.palette.black.light);

const filterLegendLabels = data => data.filter(d => d.label !== ALL_LABEL);

export const transformLegendLabels = data => filterLegendLabels(data).map((d, i) => ({
  name: d.label,
  legendColor: getLegendColor(i),
}));

export const transformPostsByMonth = data => ({
  ...data,
  dataSets: filterLegendLabels(data.dataSets),
});

const createBrandLegendFromStats = data => transformLegendLabels(get(data, 'postsByDay.dataSets', []));

const transformCompetitiveReport = data => ({
  ...data,
  brandLabelLegend: createBrandLegendFromStats(data),
  verticals: [...new Set(get(data, 'brands', []).map(b => b.vertical))],
  platforms: get(data, 'postsByPlatform.labels', []).map(p => p.toLowerCase()),
});

const transformPost = post => ({
  ...post,
  postDate: moment(post.postDate),
});


const transformPosts = data => data.posts.map(p => transformPost(p));

export const competitiveReportReducer = newReducer(initialState.competitiveReport);
export const competitiveReportRefreshReducer = newReducer(initialState.competitiveReportRefresh);
export const competitiveReportStatReducer = newReducer(initialState.competitiveReportStats);
export const competitiveReportExportReducer = newReducer(initialState.competitiveReportExport);
export const competitiveReportsReducer = newReducer(initialState.competitiveReports);
export const customizedCompetitiveReportsReducer = newReducer(initialState.competitiveReports);
export const competitiveReportPostsReducer = newReducer(initialState.competitiveReportPosts);
export const competitiveReportVerticalBreakdownReducer = newReducer(initialState.competitiveReportVerticalBreakdown);
export const competitiveReportCreatorsReducer = newReducer(initialState.competitiveReportCreators);
export const competitiveReportDistributionReducer = newReducer(initialState.competitiveReportDistribution);
export const competitiveReportPostActivityReducer = newReducer(initialState.competitiveReportPostActivity);
export const competitiveReportEstimatedSpendReducer = newReducer(initialState.competitiveReportEstimatedSpend);

/*
Competitive report reducers
----------------------------
 */
competitiveReportReducer.method.GET_COMPETITIVE_REPORT_PENDING = state => ({
  ...state,
  loading: true,
});

competitiveReportReducer.method.GET_COMPETITIVE_REPORT_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  params: {},
  loading: false,
  loaded: true,
});

competitiveReportReducer.method.GET_COMPETITIVE_REPORT_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

competitiveReportReducer.method.CREATE_COMPETITIVE_REPORTS_PENDING = state => ({
  ...state,
  loading: true,
});


competitiveReportReducer.method.CREATE_COMPETITIVE_REPORTS_FULFILLED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

competitiveReportReducer.method.CREATE_COMPETITIVE_REPORTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

competitiveReportReducer.method.UPDATE_COMPETITIVE_REPORTS_PENDING = state => ({
  ...state,
  loading: true,
});


competitiveReportReducer.method.UPDATE_COMPETITIVE_REPORTS_FULFILLED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

competitiveReportReducer.method.UPDATE_COMPETITIVE_REPORTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

competitiveReportRefreshReducer.method.REFRESH_COMPETITIVE_REPORT_PENDING = state => ({
  ...state,
  loading: true,
});

competitiveReportRefreshReducer.method.REFRESH_COMPETITIVE_REPORT_FULFILLED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

competitiveReportRefreshReducer.method.REFRESH_COMPETITIVE_REPORT_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

/*
Competitive reports stats
----------------------------
 */
competitiveReportStatReducer.method.GET_COMPETITIVE_REPORT_STATS_PENDING = state => ({
  ...state,
  loading: true,
});

competitiveReportStatReducer.method.GET_COMPETITIVE_REPORT_STATS_FULFILLED = (state, action) => ({
  ...state,
  data: transformCompetitiveReport(action.payload),
  params: {},
  loading: false,
  loaded: true,
});

competitiveReportStatReducer.method.GET_COMPETITIVE_REPORT_STATS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Competitive reports reducers
----------------------------
 */
competitiveReportsReducer.method.GET_COMPETITIVE_REPORTS_PENDING = state => ({
  ...state,
  loading: true,
});


competitiveReportsReducer.method.GET_COMPETITIVE_REPORTS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  params: {},
  loading: false,
  loaded: true,
});

competitiveReportsReducer.method.GET_COMPETITIVE_REPORTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Customized competitive reports reducers
----------------------------
 */
customizedCompetitiveReportsReducer.method.GET_CUSTOMIZED_COMPETITIVE_REPORTS_PENDING = state => ({
  ...state,
  loading: true,
});


customizedCompetitiveReportsReducer.method.GET_CUSTOMIZED_COMPETITIVE_REPORTS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  params: {},
  loading: false,
  loaded: true,
});

customizedCompetitiveReportsReducer.method.GET_CUSTOMIZED_COMPETITIVE_REPORTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Competitive report post reducers
----------------------------
 */
competitiveReportPostsReducer.method.GET_COMPETITIVE_REPORT_POSTS_PENDING = (state, action) => ({
  ...state,
  params: action.meta.params,
  loading: true,
});

competitiveReportPostsReducer.method.GET_COMPETITIVE_REPORT_POSTS_FULFILLED = (state, action) => ({
  ...state,
  data: {
    ...action.payload,
    posts: transformPosts(action.payload),
  },
  params: action.meta.params,
  loading: false,
  loaded: true,
});

competitiveReportPostsReducer.method.GET_COMPETITIVE_REPORT_POSTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Competitive report vertical breakdown reducers
----------------------------
 */
competitiveReportVerticalBreakdownReducer.method.GET_COMPETITIVE_REPORT_VERTICAL_BREAKDOWN_PENDING = (
  state,
  action,
) => ({
  ...state,
  params: action.meta.params,
  loading: true,
});

competitiveReportVerticalBreakdownReducer.method.GET_COMPETITIVE_REPORT_VERTICAL_BREAKDOWN_FULFILLED = (
  state,
  action,
) => ({
  ...state,
  data: {
    ...action.payload,
  },
  params: action.meta.params,
  loading: false,
  loaded: true,
});

competitiveReportVerticalBreakdownReducer.method.GET_COMPETITIVE_REPORT_VERTICAL_BREAKDOWN_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Competitive report creators reducers
----------------------------
 */
competitiveReportCreatorsReducer.method.GET_COMPETITIVE_REPORT_CREATORS_PENDING = (state, action) => ({
  ...state,
  params: action.meta.params,
  loading: true,
});

competitiveReportCreatorsReducer.method.GET_COMPETITIVE_REPORT_CREATORS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload.data,
  params: action.meta.params,
  loading: false,
  loaded: true,
});

competitiveReportCreatorsReducer.method.GET_COMPETITIVE_REPORT_CREATORS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

competitiveReportCreatorsReducer.method.CREATE_BRAND_SAFETY_REPORTS_FULFILLED = (state, action) => ({
  ...state,
  data: state.data.map(b => ({
    ...b,
    persons: updateInfluencerBrandSafetyReport(b.persons, get(action, 'payload.results', [])),
  })),
});


/*
Competitive report platform distribution reducers
----------------------------
 */
competitiveReportDistributionReducer.method.GET_COMPETITIVE_REPORT_DISTRIBUTION_PENDING = (state, action) => ({
  ...state,
  params: action.meta.params,
  loading: true,
});

competitiveReportDistributionReducer.method.GET_COMPETITIVE_REPORT_DISTRIBUTION_FULFILLED = (state, action) => ({
  ...state,
  data: {
    ...action.payload,
  },
  params: action.meta.params,
  loading: false,
  loaded: true,
});

competitiveReportDistributionReducer.method.GET_COMPETITIVE_REPORT_DISTRIBUTION_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Competitive report post activity reducers
----------------------------
 */
competitiveReportPostActivityReducer.method.GET_COMPETITIVE_REPORT_ACTIVITY_PENDING = (state, action) => ({
  ...state,
  params: action.meta.params,
  loading: true,
});

competitiveReportPostActivityReducer.method.GET_COMPETITIVE_REPORT_ACTIVITY_FULFILLED = (state, action) => ({
  ...state,
  data: {
    ...action.payload,
  },
  params: action.meta.params,
  loading: false,
  loaded: true,
});

competitiveReportPostActivityReducer.method.GET_COMPETITIVE_REPORT_ACTIVITY_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Competitive report estimated influencer spend
----------------------------
 */
competitiveReportEstimatedSpendReducer.method.GET_COMPETITIVE_REPORT_ESTIMATED_SPEND_PENDING = (state, action) => ({
  ...state,
  params: action.meta.params,
  loading: true,
});

competitiveReportEstimatedSpendReducer.method.GET_COMPETITIVE_REPORT_ESTIMATED_SPEND_FULFILLED = (state, action) => ({
  ...state,
  data: {
    ...action.payload,
  },
  params: action.meta.params,
  loading: false,
  loaded: true,
});

competitiveReportEstimatedSpendReducer.method.GET_COMPETITIVE_REPORT_ESTIMATED_SPEND_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


/*
Competitive report export
----------------------------
 */
competitiveReportExportReducer.method.GET_COMPETITIVE_REPORT_EXPORT_PENDING = state => ({
  ...state,
  loading: true,
});

competitiveReportExportReducer.method.GET_COMPETITIVE_REPORT_EXPORT_FULFILLED = (state, action) => ({
  ...state,
  ...action.payload,
  loading: false,
  loaded: true,
});

competitiveReportExportReducer.method.GET_COMPETITIVE_REPORT_EXPORT_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

