export default theme => ({
  root: {},
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
  },
  label: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.black.main,
  },
});
