// https://material-ui.com/customization/themes/
import { createTheme } from '@material-ui/core/styles';

export const config = {
  get typography() {
    return {
      useNextVariants: true,
      h6: {
        fontSize: '0.85rem',
        fontWeight: 700,
        color: this.palette.text.bold,
      },
      h5: {
        fontSize: '1rem',
        color: this.palette.black.dark,
        marginBottom: '1rem',
        fontWeight: 700,
      },
      body1: {
        fontSize: '0.8rem',
      },
      fontFamily: '"Nunito", sans-serif',
    };
  },
  shape: {
    borderRadius: '0.14rem',
  },
  palette: {
    socialPlatform: {
      blog: '#eca026',
      instagram: '#e4405f',
      instagramGradient: 'linear-gradient(90deg, rgba(250,189,109,1) 0%, ' +
        'rgba(212,54,107,1) 50%, rgba(157,64,184,1) 100%)',
      facebook: '#4267B2',
      twitter: '#00acee',
      snapchat: '#fffc00',
      youtube: '#ff0000',
      google: '#db4437',
      pinterest: '#e60023',
      tiktok: '#69c9d0',
    },
    blue: {
      main: '#0aa3ea',
      light: '#d3f1ff',
      contrastText: '#ffffff',
      dark: '#0772a3',
    },
    green: {
      main: '#37c389',
      ugly: '#C9E9A9',
      light: '#81E5CE',
      dark: '#2c9167',
    },
    orange: {
      label: '#ffa500',
    },
    yellow: {
      main: '#efd962',
    },
    gender: {
      m: '#37c389',
      f: '#0aa3ea',
    },
    lightGreen: {
      main: '#d4edd8',
    },
    red: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    pink: {
      main: '#F864A2',
    },
    black: {
      main: '#203844',
      dark: '#465960',
      light: '#9499a1',
    },
    white: {
      main: '#ffffff',
      light: '#fafbfd',
      dark: '#ecf0f6',
      iconBacground: '#ffffffCC',
    },
    border: {
      main: '#dce1e9',
      dark: '#888888',
    },
    text: {
      bold: '#203843',
      meta: '#B2B7BA',
    },
    barChart: {
      gradient: {
        blue: '#4ac1dc',
        green: '#57b36e',
        backBar: '#eee',
      },
    },
    get error() {
      return this.red;
    },
    get primary() {
      return this.blue;
    },
    // eslint-disable-next-line
    get plain() {
      return {
        main: this.black.light,
        light: this.white.dark,
      };
    },
    get background() {
      return {
        main: this.white.dark,
      };
    },
  },
  get customShadows() {
    return {
      1: '0 2px 4px 0 rgba(32,56,68, 0.1)',
      2: '0 8px 16px 0 rgba(32,56,68, 0.2)',
      3: '0 40px 60px 0 rgb(0 11 40 / 16%)',
    };
  },
  sizes: {
    sideBar: {
      desktop: '21.2rem',
    },
    searchMenuHideLeft: {
      desktop: '-40rem',
    },
    layout: {
      appHeader: {
        height: {
          desktop: '7rem',
        },
      },
      footer: {
        height: {
          desktop: '3rem',
          mobile: '8rem',
        },
      },
    },
  },
};

const theme = createTheme(config);

export default createTheme(theme, {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [theme.breakpoints.up('sm')]: {
          paddingLeft: '3rem',
          paddingRight: '3rem',
        },
      },
    },
  },
});
