import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import withAll from 'components/base/withAll';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ReportRow from 'components/common/CompetitiveReports/ReportList/ReportRow';
import NoResultCard from 'components/base/NoResultCard';

const styles = () => ({
  root: {},
  refreshCol: {
    width: '1rem',
  },
  editCol: {
    width: '1rem',
  },
});

const ReportList = ({ classes, competitiveReports }) => {
  if (isEmpty(competitiveReports)) {
    return <NoResultCard noResultMessage="No reports created yet." />;
  }
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Brands</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Active</TableCell>
            <TableCell align="right" className={classes.refreshCol} />
            <TableCell align="right" className={classes.editCol} />
          </TableRow>
        </TableHead>
        <TableBody>
          {competitiveReports.map(r => <ReportRow key={r.id} report={r} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  competitiveReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ competitiveReports }) => ({
  competitiveReports: get(competitiveReports, 'data.results', []),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(ReportList);
