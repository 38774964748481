import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
  root: {},
});

const SafetyLegend = ({ classes, className }) => (
  <div className={cx(className, classes.root)}>
    <Typography variant="body1"><b>Safety Legend</b></Typography>
    <Typography variant="body1">
      <b>Review Recommended:</b> We’ve noticed a high amount of potentially objectionable content.
    </Typography>
    <Typography variant="body1">
      <b>Moderate:</b> We’ve noticed a moderate amount of potentially objectionable content.
    </Typography>
    <Typography variant="body1">
      <b>Low:</b> We’ve noticed a small amount of potentially objectionable content.
    </Typography>
  </div>
);

SafetyLegend.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
};

SafetyLegend.defaultProps = {
  className: '',
};

export default withStyles(styles)(SafetyLegend);
