import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import ScatterBasic from 'components/base/charts/Scatter/ScatterBasic';
import { get, orderBy, truncate } from 'lodash';
import { findBrandObjFromName } from 'components/common/CompetitiveReport/utils';
import H6 from 'components/base/titles/CardHeading/H6';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { formatNumber } from 'utils/helpers';
import Legend from 'components/base/charts/Legend';
import { LEGEND_MAX_CHAR } from 'components/common/CompetitiveReport/constants';
import { transformLegendLabels } from 'redux/reports/reducers';

const styles = () => ({
  root: {},
  titleText: {
    paddingRight: '0.8rem',
  },
});


class AverageAudienceSizeByMonth extends React.PureComponent {
  getOptions = () => ({
    legend: {
      display: false,
    },
    tooltips: {
      displayColors: false,
      // mode: 'index',
      enabled: true,
      intersect: false,
      callbacks: {
        label: (tooltip, dataset) => {
          const { index: i, datasetIndex } = tooltip;
          const thisDataset = dataset.datasets[datasetIndex];
          if (!i) {
            return '';
          }
          return `${thisDataset.label} Total Reach: ${formatNumber(tooltip.yLabel, '0a', '-')}`;
        },
      },
    },
  });

  transformDatasets = () => {
    const { data: { dataSets, labels }, brands } = this.props;
    return {
      labels,
      datasets: orderBy(dataSets.map(d => ({
        label: d.label,
        data: d.values.map((v, i) => ({ x: labels[i], y: v })),
        backgroundColor: findBrandObjFromName(d.label, brands).legendColor,
        pointRadius: 5,
      })), 'label'),
    };
  }

  render() {
    const { classes } = this.props;
    const transformedData = this.transformDatasets();
    return (
      <div className={classes.root}>
        <H6>
          <LabelWithInfoIcon
            labelText="Creator Reach by Month"
            tooltip="The total creator reach by brand broken down by month."
          />
          <Legend
            items={transformedData.datasets.map(d => ({
              title: truncate(d.label, { length: LEGEND_MAX_CHAR }),
              color: d.backgroundColor,
            }))}
          />
        </H6>
        <ScatterBasic
          options={this.getOptions()}
          height={100}
          data={transformedData}
        />
      </div>
    );
  }
}

AverageAudienceSizeByMonth.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    dataSets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ competitiveReportDistribution }) => ({
  data: get(competitiveReportDistribution, 'data.avgAudienceSizeMonth', {}),
  brands: transformLegendLabels(get(competitiveReportDistribution, 'data.avgAudienceSizeMonth.dataSets', [])),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(AverageAudienceSizeByMonth);
