export default theme => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    backgroundColor: theme.palette.white.light,
    width: '98%',
    maxWidth: '30rem',
    height: '100%',
    margin: '0 auto',
    cursor: 'default',
    borderRadius: '0.2rem',
    overflow: 'hidden',
    boxShadow: theme.customShadows[1],
  },
  checkMark: {
    color: theme.palette.green.main,
  },
  name: {
    margin: '0',
    padding: '1rem',
    color: theme.palette.white.light,
    backgroundColor: theme.palette.blue.main,
  },
  mostPopularPlaceholder: {
    height: '1.2rem',
    maxHeight: '1.2rem',
    padding: '0.2rem',
  },
  subTitle: {
    fontSize: '21px',
    fontWeight: 'bold',
    margin: '1rem 1rem',
    textAlign: 'left',
    color: theme.palette.black.dark,
    '&.first': {
      margin: '0.6rem 1rem 1.4rem',
    },
  },
  planSetting: {
    margin: '0.5rem 1rem',
    textAlign: 'left',
    position: 'relative',
    padding: '0 1.4rem',
    '& div': {
      color: theme.palette.black.dark,
    },
    '& svg': {
      marginRight: '0.4rem',
      position: 'absolute',
      left: 0,
    },
  },
  subscriptions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: '9rem',

    '& a': {
      textDecoration: 'none',
    },
  },
  pushUp: {
    minHeight: '9rem',
  },
  buttonWrapper: {
    textAlign: 'center',
    width: '70%',
    margin: '0.5rem auto',
    backgroundColor: theme.palette.white.light,
    border: `1px solid ${theme.palette.black.main}`,
  },
  button: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.white.main,
    width: '80%',
    marginTop: '2vh',
    padding: '1rem',
    cursor: 'pointer',

    '&:last-child': {
      padding: '1rem',
    },
    '&:hover': {
      boxShadow: theme.customShadows[2],
      backgroundColor: theme.palette.green.dark,
    },
  },
});
