import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import { withRouter } from 'react-router';
import { searchUrl } from 'constants/routing';
import Tooltip from './Tooltip';

class HelpTooltip extends React.Component {
  handleSearch = async () => {
    this.props.history.push(searchUrl());
    this.props.updateOnBoarding({ run: false, isCompleted: true });
  }

  render() {
    return <Tooltip submitText="Finish" handleSubmit={this.handleSearch} {...this.props} />;
  }
}


HelpTooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  updateOnBoarding: PropTypes.func.isRequired,
};

export default withRouter(connect(
  null,
  { updateOnBoarding },
)(HelpTooltip));
