export default theme => ({
  bar__label: {
    display: 'flex',
    color: theme.palette.black.light,
    fontSize: '1rem',
    marginBottom: '0.2rem',
  },
  label__text: {
    textAlign: 'left',
    display: 'inline-block',
    flex: 1,
  },
  label__value: {
    textAlign: 'right',
    display: 'inline-block',
  },
});
