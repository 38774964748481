import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import { ALL_LABEL } from 'components/common/CompetitiveReport/constants';
import DayOfWeekChart from './DayOfWeekChart';

const styles = () => ({
  root: {},
});

const filterBrands = data => orderBy(data.filter(d => d.brand !== ALL_LABEL), 'brand');

const createColGrid = totalCols => (
  totalCols === 3 ? 4 : 6
);

const PlatformsByDayOfWeek = ({ platformsByDayOfWeek, classes }) => (
  <div className={classes.root}>
    <Grid container spacing={2}>
      {platformsByDayOfWeek.map(d => (
        <Grid item lg={createColGrid(platformsByDayOfWeek.length)}>
          <DayOfWeekChart data={d} />
        </Grid>
      ))}
    </Grid>
  </div>
);

PlatformsByDayOfWeek.propTypes = {
  platformsByDayOfWeek: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportPostActivity }) => ({
  platformsByDayOfWeek: filterBrands(get(competitiveReportPostActivity, 'data.platformsByDayOfWeek', [])),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(PlatformsByDayOfWeek);
