import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PageLayout from 'components/base/Layout/PageLayout';

const styles = theme => ({
  headerContainer: {
    backgroundColor: theme.palette.white.light,
    padding: '4rem 0 0 0',
    width: '100%',
    position: 'relative',
    marginBottom: '6rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '8rem',
    },
  },
  svgContainer: {
    width: '100%',
    position: 'absolute',
    zIndex: 0,
    [theme.breakpoints.up('md')]: {
      bottom: '-14.5rem',
    },
  },
  layout: {
    zIndex: 1,
    position: 'relative',
  },
});

const PromoBannerWrapper = ({ classes, theme, children }) => (
  <div className={classes.headerContainer}>
    <PageLayout className={classes.layout}>
      {children}
    </PageLayout>
    <div className={classes.svgContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill={theme.palette.white.light}
          // eslint-disable-next-line
          d="M0,256L60,240C120,224,240,192,360,192C480,192,600,224,720,240C840,256,960,256,1080,229.3C1200,203,1320,149,1380,122.7L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        />
      </svg>
    </div>
  </div>
);

PromoBannerWrapper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
};

export default withStyles(styles, { withTheme: true })(PromoBannerWrapper);
