import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import {
  Container,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import HomeTitle from 'components/base/titles/HomeTitle';
import HomeSubTitle from 'components/base/titles/HomeSubTitle';
import verticalImg from 'images/home/verticals2.png';
import { createVerticalDetailUrl, verticalsUrl } from 'constants/routing';
import { getVerticalCategories as getVerticalCategoriesAction } from 'redux/categories/actions';
import { connect } from 'react-redux';

const styles = () => ({
  root: {
    marginBottom: '4rem',
    position: 'relative',
    marginTop: '4rem',
  },
  container: {},
  title: {
    justifyContent: 'left',
  },
  image: {
    width: '100%',
    overflow: 'hidden',
    '& > img': {
      width: '100%',
    },
  },
  formControl: {
    width: '100%',
  },
});

const Verticals = ({
  classes, history, getVerticalCategories, verticals,
}) => {
  const [vertical, setVertical] = React.useState('');
  const handleChange = (event) => {
    history.push(event.target.value);
    setVertical(event.target.value);
  };

  useEffect(() => {
    getVerticalCategories();
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={8}>
          <Grid item md={6}>
            <HomeTitle
              title={<React.Fragment>Track Sponsored Post Activity <br />By Industry</React.Fragment>}
              className={classes.title}
              titleId="verticalH1"
            />
            <HomeSubTitle titleId="verticalH2">
              Show me what is happening in:
            </HomeSubTitle>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select a Vertical</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={vertical}
                onChange={handleChange}
                label="Select a vertical"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={verticalsUrl()}>All Verticals</MenuItem>
                {Object.keys(verticals).map(key => (
                  <MenuItem value={createVerticalDetailUrl(key)}>
                    {verticals[key]}
                  </MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <div className={classes.image}>
              <img
                src={verticalImg}
                alt="Verticals"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Verticals.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  getVerticalCategories: PropTypes.func.isRequired,
  verticals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ verticalsCategories }) => ({
  isLoading: verticalsCategories.loading,
  verticals: verticalsCategories.verticals.data,
});

export default connect(
  mapStateToProps,
  { getVerticalCategories: getVerticalCategoriesAction },
)(withRouter(withStyles(styles)(Verticals)));
