// eslint-disable-next-line import/prefer-default-export
export const updateInfluencerBrandSafetyReport = (influencers, reportData) => {
  const reportDataByInfluencerId = {};

  reportData.forEach((report) => {
    reportDataByInfluencerId[report.influencerId] = { id: report.id, index: report.brandSafetyIndex };
  });

  return influencers.map(i => (
    reportDataByInfluencerId[i.id] ? { ...i, brandSafetyReport: reportDataByInfluencerId[i.id] } : i
  ));
};
