export default theme => ({
  root: {
    boxShadow: theme.customShadows[1],
    textAlign: 'center',
    height: '14rem',
    position: 'relative',
    padding: '0',
  },
  category_image: {
    height: '11.7rem',
    cursor: 'pointer',
  },
  title: {
    marginTop: '0.3rem',
    textAlign: 'center',
    fontWeight: '700',
  },
});
