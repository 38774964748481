import { mapKeys, snakeCase } from 'lodash';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { recursiveFunc } from 'utils/mixins';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';
import { request } from 'lib/http';

export const CREATE_BRAND_SAFETY_REPORTS = 'CREATE_BRAND_SAFETY_REPORTS';
export const DELETE_BRAND_SAFETY_REPORT = 'DELETE_BRAND_SAFETY_REPORT';
export const GET_BRAND_SAFETY_REPORT = 'GET_BRAND_SAFETY_REPORT';
export const GET_BRAND_SAFETY_REPORT_POSTS = 'GET_BRAND_SAFETY_REPORT_POSTS';
export const GET_BRAND_SAFETY_REPORTS = 'GET_BRAND_SAFETY_REPORTS';
export const GET_BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS = 'GET_BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS';
export const FETCH_OBJECTIONABLE_CONTENT_LISTS = 'FETCH_OBJECTIONABLE_CONTENT_LISTS';
export const REFRESH_BRAND_SAFETY_REPORT = 'REFRESH_BRAND_SAFETY_REPORT';

export const createBrandSafetyReports = data => async (dispatch) => {
  await dispatch({
    type: CREATE_BRAND_SAFETY_REPORTS,
    payload: request('post', API.BRAND_SAFETY_REPORTS(), {
      data: recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
    }),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.CREATE_BRAND_SAFETY_REPORT, data);
};

export const getBrandSafetyReport = reportId => async (dispatch) => {
  await dispatch({
    type: GET_BRAND_SAFETY_REPORT,
    payload: request('get', API.BRAND_SAFETY_REPORT(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getBrandSafetyReportPosts = reportId => async (dispatch) => {
  await dispatch({
    type: GET_BRAND_SAFETY_REPORT_POSTS,
    payload: request('get', API.BRAND_SAFETY_REPORT_POSTS(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getUnusualEngagementPosts = reportId => async (dispatch) => {
  await dispatch({
    type: GET_BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS,
    payload: request('get', API.BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const deleteBrandSafetyReport = (reportId, report) => async (dispatch) => {
  await dispatch({
    type: DELETE_BRAND_SAFETY_REPORT,
    payload: request('delete', API.BRAND_SAFETY_REPORT(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.DELETE_BRAND_SAFETY_REPORT, report);
};

export const refreshBrandSafetyReport = reportId => async (dispatch) => {
  await dispatch({
    type: REFRESH_BRAND_SAFETY_REPORT,
    payload: request('put', API.BRAND_SAFETY_REPORT(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.REFRESH_BRAND_SAFETY_REPORT, {});
};

export const getBrandSafetyReports = () => async (dispatch) => {
  await dispatch({
    type: GET_BRAND_SAFETY_REPORTS,
    payload: request('get', API.BRAND_SAFETY_REPORTS(), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const fetchObjectionableContentLists = () => async (dispatch) => {
  await dispatch({
    type: FETCH_OBJECTIONABLE_CONTENT_LISTS,
    payload: request('get', API.OBJECTIONABLE_CONTENT_LISTS(), {}),
  });
};

