import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/base/Loader';
import { searchUrl } from 'constants/routing';
import ProfileLeftColumn from 'components/common/InfluencerProfile/ProfileLeftColumn';
import ProfileRightColumn from 'components/common/InfluencerProfile/ProfileRightColumn';
import { getInfluencerProfile, getInfluencerTopContent } from 'redux/influencers/actions';
import styles from './styles';
import withPermission from '../../common/withPermission';

class InfluencerProfile extends Component {
  componentDidMount() {
    this.getInfluencerProfile();
  }

  componentDidUpdate(prevProps) {
    if (this.props.influencerId !== prevProps.influencerId) {
      this.getInfluencerProfile();
    }
  }

  getInfluencerProfile = () => {
    const { influencerId } = this.props;
    this.props.getInfluencerProfile(influencerId);
    this.props.getInfluencerTopContent(influencerId);
  };

  changeRoute = () => {
    if (this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      this.props.history.push(searchUrl());
    }
  }

  render() {
    const {
      classes,
      influencerProfile,
      views = {},
    } = this.props;
    return (
      <div className={classes.root}>
        <Loader loading={influencerProfile.loading || !influencerProfile.loaded}>
          <React.Fragment>
            <Button className={classes.btn__close} onClick={this.changeRoute} >
              <CloseIcon />
            </Button>
            <ProfileLeftColumn influencer={influencerProfile.data} />
            <ProfileRightColumn influencer={influencerProfile.data} permittedViews={views} />
          </React.Fragment>
        </Loader>
      </div>
    );
  }
}

InfluencerProfile.propTypes = {
  influencerId: PropTypes.string.isRequired,
  influencerProfile: PropTypes.shape({}).isRequired,
  getInfluencerProfile: PropTypes.func.isRequired,
  getInfluencerTopContent: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  views: PropTypes.shape({}),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    length: PropTypes.func.isRequired,
  }),
};

InfluencerProfile.defaultProps = {
  views: {},
  history: null,
};

const mapMetaToProps = ({ views }) => ({
  views,
});

export default withPermission('influencer_profile', null, mapMetaToProps, true)(connect(
  state => ({
    influencerProfile: state.influencerProfile,
  }),
  { getInfluencerProfile, getInfluencerTopContent },
)(withStyles(styles)(withRouter(InfluencerProfile))));
