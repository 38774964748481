import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity } from 'lodash';
import { withStyles } from '@material-ui/styles';
import { getBrandSafetyReports } from 'redux/brandSafetyReports/actions';
import PageLayout from 'components/base/Layout/PageLayout';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import withPermission from 'components/common/withPermission';
import { mpPageView } from 'utils/copenhagen';
import ReportList from './ReportList';
import styles from './styles';

class BrandSafetyReports extends React.Component {
  async componentDidMount() {
    await this.props.getBrandSafetyReports();
    mpPageView({ name: 'BrandSafetyReports' });
  }

  render() {
    const {
      permitted,
      canViewUnusualPostIndex,
      isLoading,
      loaded,
    } = this.props;

    if (!permitted) {
      return null;
    }

    return (
      <Loader loading={isLoading} loaded={loaded}>
        <PageLayout>
          <ReportList canViewUnusualPostIndex={canViewUnusualPostIndex} />
        </PageLayout>
      </Loader>
    );
  }
}

BrandSafetyReports.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  permitted: PropTypes.bool.isRequired,
  canViewUnusualPostIndex: PropTypes.bool.isRequired,
  getBrandSafetyReports: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ brandSafetyReports }) => ({
  isLoading: brandSafetyReports.loading,
  loaded: brandSafetyReports.loaded,
});

export default withAll(
  withPermission('view_brand_safety_reports', null, identity, true),
  connect(mapStateToProps, { getBrandSafetyReports }),
  withStyles(styles),
)(BrandSafetyReports);
