export default () => ({
  button: {
    textTransform: 'none',
    marginTop: '0.4rem',
    width: '100%',
  },
  recommend_influencer_icon: {
    marginRight: '0.8rem',
  },
});
