import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import withAll from 'components/base/withAll';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import NoResultCard from 'components/base/NoResultCard';
import MonitorRow from './MonitorRow';

const styles = () => ({
  root: {},
});

const KeyPhraseMonitorList = ({
  classes, keyPhraseMonitors,
}) => {
  if (isEmpty(keyPhraseMonitors)) {
    return <NoResultCard noResultMessage="You have no monitors, set one up now!" />;
  }
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Platforms</TableCell>
              <TableCell align="left">Keyword/Phrase</TableCell>
              {/* <TableCell align="left">Active</TableCell> */}
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {keyPhraseMonitors.map(m => <MonitorRow monitor={m} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

KeyPhraseMonitorList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  keyPhraseMonitors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ keyPhraseMonitors }) => ({
  keyPhraseMonitors: keyPhraseMonitors.data,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(KeyPhraseMonitorList);
