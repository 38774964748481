import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid, Avatar } from '@material-ui/core';
import { connect } from 'react-redux';
import { formatNumber } from 'utils/helpers';
import { sum, identity } from 'lodash';
import moment from 'moment';
import PageTitle from 'components/base/titles/PageTitle';
import withAll from 'components/base/withAll';
import StatCard from 'components/common/StatCard';
import withPermission from 'components/common/withPermission';
import NoResultCard from 'components/base/NoResultCard';
import DateFilter from 'components/common/DateFilter';
import { DEFAULT_FROM_DATE, DEFAULT_TO_DATE } from 'constants/options';
import { filterBrandDetail as filterBrandDetailAction } from 'redux/brand/actions';
import HistoricGraph from './HistoricGraph';
import RelatedBrands from './RelatedBrands';
import StatTable from './StatTable';
import PlatformFilter from './PlatformFilter';
import styles from './styles';

const NULL_VALUE_DISPLAY = 'N/A';
const GRID_SPACING = 2;

const BrandDetail = ({
  brand,
  classes,
  filterBrandDetail,
  params,
}) => {
  if (!brand.totalPostCount) {
    return (
      <div className={classes.noResults}>
        <NoResultCard noResultMessage="Sorry we were unable to find data for this brand." />
      </div>
    );
  }

  const [selectedFromDate, setSelectedFromDate] = React.useState(DEFAULT_FROM_DATE);
  const [selectedToDate, setSelectedToDate] = React.useState(DEFAULT_TO_DATE);

  const handleFilterSelect = (fromDate, toDate) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
    filterBrandDetail({
      ...params,
      fromDate: moment.parseZone(fromDate),
      toDate: moment.parseZone(toDate),
    });
  };

  React.useEffect(() => {
    handleFilterSelect(selectedFromDate, selectedToDate);
  }, [brand.name]);

  return (
    <div>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12}>
          <Grid container justify="flex-end" spacing={1}>
            <Grid item xs>
              <PageTitle
                title={brand.name}
                icon={<Avatar className={classes.brandLogo} component="span" src={brand.logo} />}
              />
            </Grid>
            {/* Temporarily hidden according to the task IBP-6209 */
            false && (
            <Grid item xs="auto" className={classes.headerItem}>
              <PlatformFilter />
            </Grid>
            )}
            <Grid item xs="auto" className={classes.headerItem}>
              <DateFilter
                handleSelect={handleFilterSelect}
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                customDate
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <HistoricGraph />
            </Grid>
          </Grid>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs>
              <StatCard
                title="Creators"
                value={formatNumber(
                  brand.creatorCount, '0a',
                  NULL_VALUE_DISPLAY,
                )}
              />
            </Grid>
            <Grid item xs>
              <StatCard
                title="Posts"
                value={formatNumber(
                  sum(brand.dailyPostCounts), '0a',
                  NULL_VALUE_DISPLAY,
                )}
              />
            </Grid>
            <Grid item xs>
              <StatCard
                title="Interactions"
                value={formatNumber(
                  sum(brand.dailyEngagements), '0a',
                  NULL_VALUE_DISPLAY,
                )}
              />
            </Grid>
            <Grid item xs>
              <StatCard
                title="Views"
                value={formatNumber(
                  sum(brand.dailyViewCounts), '0a',
                  NULL_VALUE_DISPLAY,
                )}
              />
            </Grid>
          </Grid>
          <PageTitle title="Brand insights from the past 2 years" className={classes.title} />
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <StatTable />
            </Grid>
          </Grid>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <RelatedBrands />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

BrandDetail.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brand: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterBrandDetail: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
};

BrandDetail.defaultProps = {};

const mapStateToProps = ({ brand }) => ({
  brand: brand.data,
  params: brand.params,
});

export default withAll(
  connect(mapStateToProps, { filterBrandDetail: filterBrandDetailAction }),
  withPermission('brand', null, identity, true),
  withStyles(styles),
)(BrandDetail);
