import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageTitle from 'components/base/titles/PageTitle';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Field } from 'redux-form';
import Input from 'components/base/forms/bootstrap/Input';
import { passwordValidator, validate, passwordsMatch } from 'utils/validators';
import { updateUserPassword } from 'redux/user/actions';
import { logout } from 'redux/authentication/actions';
import { toast } from 'react-toastify';
import {
  required,
  combine,
} from 'redux-form-validators';
import AccountForm from '../AccountForm';
import styles from './styles';


const customValidate = validate({
  password: combine(
    required({ msg: 'Password is required.' }),
    passwordValidator,
  ),
  confirmation: combine(
    passwordsMatch('Password does not match.'),
    required({ msg: 'Password confirmation is required.' }),
  ),
});


class ChangePassword extends Component {
  handleSubmit = ({ password }) => {
    const response = this.props.updateUserPassword({ password });
    toast.success('Password saved successfully');
    this.props.logout();
    return response;
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle
          title="Change password"
          subtitle="You can update your password by entering a new password below.
            Upon saving your new password you will be
             prompted to login using the new login information."
        />
        <AccountForm
          form="changePassword"
          submitText="Update password"
          onSubmit={this.handleSubmit}
          validate={customValidate}
          hideHeaderError
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="New password"
                name="password"
                type="password"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="Confirm password"
                name="confirmation"
                type="password"
              />
            </Grid>
          </Grid>
        </AccountForm>
      </React.Fragment>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(
  null,
  { updateUserPassword, logout },
)(withStyles(styles)(ChangePassword));
