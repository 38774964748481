import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Card, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { reset, SubmissionError } from 'redux-form';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import PageTitle from 'components/base/titles/PageTitle';
import PageLayout from 'components/base/Layout/PageLayout';
import BrandRequestForm from 'components/common/forms/BrandRequestForm';
import UpgradeModal from 'components/common/UpgradeModal';
import { brandRequest as brandRequestAction } from 'redux/brand/actions';
import { forms } from 'constants/config';
import styles from './styles';

const SuccessMessage = () => (
  <Typography variant="body2">
    Your brand request has been received and will be processed within 2 business days.
  </Typography>
);

class BrandRequest extends React.PureComponent {
  onSubmit = async (data) => {
    const { brandRequest, resetForm } = this.props;

    try {
      await brandRequest(data);
      resetForm();
      toast.success(<SuccessMessage />);
    } catch (error) {
      if (error.errors) {
        if (error.errors.status === 403) {
          toast.error(<Typography variant="body2">{error.errors.detail}</Typography>);
          return;
        }
        throw new SubmissionError(error.errors);
      }
    }
  };

  render() {
    const {
      classes,
      isTrial,
      closeModal,
      openModal,
      open,
    } = this.props;
    return (
      <PageLayout>
        <div className={classes.card}>
          <PageTitle
            title="Request a brand"
            subtitle={
              <React.Fragment>
                You can request a brand by entering a new brand data below.
                Your brand request will be processed within 2 business days.
                {isTrial && <h4>You need a subscription to complete this action.</h4>}
              </React.Fragment>}
          />
          <Card>
            <BrandRequestForm onSubmit={data => (isTrial ? openModal() : this.onSubmit(data))} />
            {isTrial && <UpgradeModal
              open={open}
              closeModal={closeModal}
              messageBody="You need a subscription to request a brand."
            />}
          </Card>
        </div>
      </PageLayout>
    );
  }
}

BrandRequest.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brandRequest: PropTypes.func.isRequired,
  isTrial: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ userProfile: { data: { isTrial } } }) => ({
  isTrial,
});

const mapDispatchToProps = {
  brandRequest: brandRequestAction,
  resetForm: () => dispatch => dispatch(reset(forms.BRAND_REQUEST_FORM)),
};

export default withAll(
  withModal,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(BrandRequest);
