import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Chip from 'components/base/Chip';
import { CATEGORY_DICT } from 'constants/options';
import {
  isEmpty,
  pick,
  keys,
  slice,
} from 'lodash';
import styles from './styles';

const MAX_CATEGORIES = 2;

class Categories extends Component {
  createCategoryList = (categories) => {
    if (isEmpty(categories)) {
      return [];
    }

    // Only display primary categories, do not show secondary categories
    const mainCategories = keys(pick(categories, keys(CATEGORY_DICT)));

    // Count of remaining categories
    const remainingCategories = mainCategories.length - MAX_CATEGORIES;

    // Truncate list to max categories that can be displayed
    const categoriesToDisplay = slice(mainCategories, 0, MAX_CATEGORIES);

    // Get labels to go with property values
    const transformedCateogires = categoriesToDisplay.map(category => ({
      value: category,
      label: CATEGORY_DICT[category],
    }));

    if (mainCategories.length > MAX_CATEGORIES) {
      transformedCateogires.push({
        value: 'cat-more',
        label: `${remainingCategories}+`,
      });
    }
    return transformedCateogires;
  }

  renderCategories = (categories) => {
    const transformedCategories = this.createCategoryList(categories);
    return transformedCategories.map(category => (
      <Chip key={category.value} label={category.label} />
    ));
  }

  render() {
    const { classes, categories } = this.props;
    return (
      <div className={classes.categories}>
        {this.renderCategories(categories)}
      </div>
    );
  }
}

Categories.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  categories: PropTypes.shape({}),
};

Categories.defaultProps = {
  categories: {},
};

export default withStyles(styles)(Categories);
