import { handleErrors } from 'utils/api';
import { request } from '../../lib/http';
import API from '../../constants/api';

export const FETCH_CONFIG = 'FETCH_CONFIG';

export const fetchConfig = () => async (dispatch) => {
  dispatch({
    type: FETCH_CONFIG,
    payload: request('get', API.FETCH_CONFIG, {}),
  }).catch((response) => {
    try {
      handleErrors(response);
    } catch (error) {
      // DO nothing since if the user is not logged in it will reutrn 401
    }
  });
};
