import React from 'react';
import Modal from 'components/base/modals/PrismModal';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { login as loginAction } from 'redux/authentication/actions';
import QuickSignupForm from 'components/common/forms/QuickSignupForm';
import { reset, SubmissionError } from 'redux-form';
import { forms } from 'constants/config';
import { connect } from 'react-redux';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import { searchUrl } from 'constants/routing';
import styles from './styles';

const QuickSignupModal = ({
  classes,
  open,
  closeModal,
  hideClose,
  onSignupComplete,
  resetForm,
  history,
  login,
}) => {
  async function onSubmit(formData) {
    try {
      await onSignupComplete(formData);
      resetForm();
      setTimeout(async () => {
        await login(formData.email, formData.password);
        history.push(searchUrl());
      }, 1000);
    } catch (error) {
      if (error.errors) {
        throw new SubmissionError(error.errors);
      }
    }
  }
  function onCloseModal() {
    closeModal();
    mpTrack(MP_EVENTS.CLOSE_SIGNUP_MODAL);
  }
  return (
    <Modal
      title={`Free ${process.env.BETA_DEFAULT_TRIAL_DAYS}-day access to Influencer Search and Competitive Reports`}
      open={open}
      onClose={onCloseModal}
      className={classes.root}
      hideClose={hideClose}
    >
      <Typography variant="body">
        Your report is now being generated! To be able to view your report signup for a free
        {process.env.BETA_DEFAULT_TRIAL_DAYS}-day trial to explore competitive reports and
        influencer discovery. <b>No credit card required.</b>
      </Typography>
      <QuickSignupForm onSubmit={onSubmit} />
    </Modal>
  );
};

QuickSignupModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  hideClose: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  onSignupComplete: PropTypes.func,
};

QuickSignupModal.defaultProps = {
  onSignupComplete: () => {},
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = {
  resetForm: () => dispatch => dispatch(reset(forms.QUICK_SIGNUP_FORM)),
  login: loginAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(QuickSignupModal);
