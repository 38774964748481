import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Menu } from '@material-ui/core';
import Button from 'components/base/Button';

class ButtonMenu extends Component {
  state = {
    anchorEl: null,
    isOpen: false,
  };

  close = () => {
    this.setState({ anchorEl: null, isOpen: false }, () => {
      this.props.onClose();
    });
  };

  handleClick = (event) => {
    const { isOpen } = this.state;
    const { onOpen } = this.props;
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: !isOpen,
    }, () => {
      if (!isOpen) {
        onOpen();
      }
    });
  };

  handleClose = () => {
    this.close();
  };

  renderMenuChildren = () => {
    const { menuChildren } = this.props;
    return menuChildren.map((menuChild) => {
      if (menuChild) {
        const { closeOnClick, onClick, ...rest } = menuChild.props;
        if (closeOnClick) {
          return React.cloneElement(menuChild, {
            ...rest,
            onClick: (event) => {
              this.close();
              setTimeout(() => onClick(event), 50);
            },
          });
        }
        return menuChild;
      }
      return null;
    });
  };

  render() {
    const {
      buttonProps,
      buttonChildren,
      menuProps,
      openable,
    } = this.props;
    const {
      isOpen,
      anchorEl,
    } = this.state;
    return (
      <React.Fragment>
        <Button
          aria-owns="menuButton"
          aria-haspopup="true"
          onClick={this.handleClick}
          {...buttonProps}
        >
          {buttonChildren}
        </Button>
        <Menu
          id="menuButton"
          open={isOpen && openable}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          disableAutoFocusItem
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...menuProps}
        >
          {this.renderMenuChildren()}
        </Menu>
      </React.Fragment>
    );
  }
}

ButtonMenu.propTypes = {
  buttonProps: PropTypes.shape({}),
  buttonChildren: PropTypes.element.isRequired,
  menuProps: PropTypes.shape({}),
  menuChildren: PropTypes.arrayOf(PropTypes.element).isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  openable: PropTypes.bool,
};

ButtonMenu.defaultProps = {
  buttonProps: {},
  menuProps: {},
  onOpen: noop,
  onClose: noop,
  openable: true,
};

export default ButtonMenu;
