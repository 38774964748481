import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const SideBar = ({
  classes,
  children,
  footer,
  style,
}) => (
  <div className={classes.root} style={style}>
    <div className={classes.search_bar__container}>
      {children}
    </div>
    {!isEmpty(footer) &&
    <div className={classes.side_bar__footer}>
      <div className={classes.btn__container}>
        {footer}
      </div>
    </div>
    }
  </div>
);

SideBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  style: PropTypes.shape({}),
};

SideBar.defaultProps = {
  footer: null,
  style: {},
};

export default withStyles(styles)(SideBar);
