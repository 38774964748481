import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { STEPS_INDXES } from 'constants/onboarding';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import Tooltip from './Tooltip';

class SavedList2Tooltip extends React.Component {
  handleSearch = async () => {
    this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST_3 });
  }

  render() {
    return <Tooltip submitText="Next" handleSubmit={this.handleSearch} {...this.props} />;
  }
}


SavedList2Tooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  updateOnBoarding: PropTypes.func.isRequired,
};

export default connect(
  null,
  { updateOnBoarding },
)(SavedList2Tooltip);
