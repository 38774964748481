export default theme => ({
  form: {
    height: '100%',
    width: '100%',
  },
  form__content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 120px)',
    width: 'calc(100% - 60px)',
    padding: '30px',
  },
  footer: {
    width: 'calc(100% - 20px)',
    height: '60px',
    paddingLeft: '20px',
    background: theme.palette.white.main,
  },
  submit__button: {
    marginTop: '0.5rem',
  },
  close__button: {
    marginTop: '0.5rem',
    marginLeft: '0.4rem',
    background: theme.palette.white.main,
    color: theme.palette.black.main,
  },
});
