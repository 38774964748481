import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { getCompetitiveReportCreators } from 'redux/reports/actions';
import Loader from 'components/base/Loader';
import { withRouter } from 'react-router';
import { isEmpty, get } from 'lodash';
import { Alert } from '@material-ui/lab';
import withPermission from 'components/common/withPermission';
import { DEFAULT_CREATOR_PARAMS } from '../constants';
import { HeaderSort, HeaderDateFilter, HeaderPlatformFilter } from './Header';
import BrandCreators from './BrandCreators';
import TabHeader from '../TabHeader';

const styles = () => ({
  root: {},
  card: {
    marginBottom: '1.8rem',
  },
  filterSelect: {
    marginBottom: '2rem',
  },
});

class TopCreators extends React.Component {
  componentDidMount() {
    const { platforms, match } = this.props;
    const { reportId } = match.params;
    this.props.getCompetitiveReportCreators(
      reportId,
      {
        ...DEFAULT_CREATOR_PARAMS,
        platforms,
      },
    );
  }

  render() {
    const {
      classes,
      isLoading,
      loaded,
      data,
      permittedProfileViews,
    } = this.props;
    return (
      <div className={classes.root}>
        <TabHeader
          title="Top Creators"
          description="Provides a creator spotlight on the creators showing top performers
                      of each brand, providing insights into how frequently they have worked
                      with a brand and which platform was used."
        >
          <HeaderSort />
          <HeaderDateFilter />
        </TabHeader>

        <div className={classes.filterSelect}>
          <HeaderPlatformFilter />
        </div>

        <Loader loading={isLoading} loaded={loaded}>
          {isEmpty(data) && (
            <Alert severity="warning">
              We don&apos;t have sufficient data for this filter. Please try a different selection.
            </Alert>
          )}
          <Grid container spacing={2} justifyContent="center">
            {data.map(d => (
              <Grid item md={3}>
                <BrandCreators permittedProfileViews={permittedProfileViews} data={d} />
              </Grid>
            ))}
          </Grid>
        </Loader>
      </div>
    );
  }
}

TopCreators.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  permittedProfileViews: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  getCompetitiveReportCreators: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = ({ competitiveReportCreators, competitiveReportStat }) => ({
  isLoading: competitiveReportCreators.loading,
  loaded: competitiveReportCreators.loaded,
  data: competitiveReportCreators.data,
  platforms: get(competitiveReportStat, 'data.platforms', []),
});

const mapMetaToProps = ({ views }) => ({
  permittedProfileViews: views,
});

export default withAll(
  connect(mapStateToProps, { getCompetitiveReportCreators }),
  withStyles(styles),
  withRouter,
  withPermission(
    'influencer_profile',
    null,
    mapMetaToProps,
    true,
  ),
)(TopCreators);
