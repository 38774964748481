import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Container } from '@material-ui/core';
import AppBar from 'components/base/AppBar';
import Toolbar from 'components/base/Toolbar';
import Logo from 'components/base/Logo';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import { login } from 'constants/routing';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';
import RightOptions from './RightOptions';
import SearchNav from './SearchNav';
import styles from './styles';

const NavBar = ({
  classes,
  isAuthenticated,
  closeModal,
  open,
  openModal,
}) => {
  const signupClicked = () => {
    openModal();
    mpTrack(MP_EVENTS.CLICK_SIGNUP_HEADER_BTN);
  };
  return (
    <AppBar position="static" className={classes.header}>
      <BetaSignupModal
        open={open}
        closeModal={closeModal}
        onSignupComplete={closeModal}
      />
      <Container maxWidth={false}>
        <Toolbar>
          <div className={classes.logoContainer}>
            <Logo />
          </div>
          <div className={classes.topNav}>
            {isAuthenticated && <RightOptions />}
            {!isAuthenticated && <Button color="primary" href={login()}>Login</Button>}
            {!isAuthenticated && (
              <Button color="primary" variant="contained" onClick={signupClicked}>Signup</Button>
            )}
          </div>
        </Toolbar>
      </Container>
      <SearchNav />
    </AppBar>
  );
};

NavBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
  withModal,
)(NavBar);
