import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { formatLocationFromLocationObject } from 'lib/utils';
import { isEmpty } from 'lodash';
import AddToList from 'components/common/lists/AddToList';
import { getInfluencerName, getInstagramHandleFromProfilePlatforms } from 'utils/influencers';
import Stats from './Stats';
import styles from './styles';
import RecommendInfluencer from './RecommendInfluencer';

const ProfileLeftColumn = ({
  classes,
  influencer,
}) => (
  <div className={classes.root}>
    <div className={classes.profile__header}>
      <Avatar className={classes.profile__avatar} src={influencer.profilePhoto} />
      <Typography variant="h6" className={classes.profile__name}>
        {getInfluencerName({
          name: influencer.fullName,
          platforms: {
            instagram: {
              username: getInstagramHandleFromProfilePlatforms(influencer.platforms),
            },
          },
        })}
      </Typography>
      {!isEmpty(influencer.location) &&
        <Typography className={classes.profile__location}>
          <LocationOnIcon className={classes.location__icon} /> {formatLocationFromLocationObject(influencer.location)}
        </Typography>
      }
      <div className={classes.profile__header__btn_container}>
        <AddToList
          selectedInfluencers={[{ id: influencer.id, name: influencer.fullName }]}
          hideSelectedListPopoverTitle
          fullWidth
        />
        <RecommendInfluencer
          selectedInfluencerId={influencer.id}
        />
      </div>
    </div>
    <Stats {...influencer.stats} />
  </div>
);

ProfileLeftColumn.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  influencer: PropTypes.shape({
    platforms: PropTypes.arrayOf(PropTypes.shape({
      platforms: PropTypes.string,
      handle: PropTypes.string,
    })).isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.shape({
      countryAbbreviation: PropTypes.string,
      city: PropTypes.string,
      stateAbbreviation: PropTypes.string,
    }),
    profilePhoto: PropTypes.string,
    stats: PropTypes.shape({}),
  }).isRequired,
};

ProfileLeftColumn.defaultProps = {};

export default withStyles(styles)(ProfileLeftColumn);
