import { get } from 'lodash';
import { DEFAULT_POSTED_WITHIN, DEFAULT_POSTED_BEFORE } from 'constants/options';
import { newReducer } from '../utils';

const initialState = {
  keyPhraseMonitors: {
    loading: false,
    loaded: false,
    data: {
      results: [],
    },
  },
  keyPhraseMonitor: {
    loading: false,
    loaded: false,
    data: {
      status: true,
    },
  },
  keyPhraseMonitorPosts: {
    loading: false,
    loaded: false,
    data: {
      name: '',
      query: '',
      postsByTime: [],
      topPosts: [],
      topCreators: [],
      platforms: [],
    },
    params: {
      postedWithin: DEFAULT_POSTED_WITHIN,
      postedBefore: DEFAULT_POSTED_BEFORE,
    },
  },
};

const transformMonitor = data => ({
  ...data,
  status: get(data, 'status', true) === 'active',
});

export const keyPhraseMonitorsReducer = newReducer(initialState.keyPhraseMonitors);
export const keyPhraseMonitorReducer = newReducer(initialState.keyPhraseMonitor);
export const keyPhraseMonitorPostsReducer = newReducer(initialState.keyPhraseMonitorPosts);

/*
Key phrase monitors reducers
----------------------------
*/

keyPhraseMonitorsReducer.method.GET_KEY_PHRASE_MONITORS_PENDING = state => ({
  ...state,
  loading: true,
});


keyPhraseMonitorsReducer.method.GET_KEY_PHRASE_MONITORS_FULFILLED = (state, action) => ({
  data: get(action.payload, 'results', []).map(transformMonitor),
  loading: false,
  loaded: true,
});

keyPhraseMonitorsReducer.method.GET_KEY_PHRASE_MONITORS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

/*
Key phrase monitor reducers
----------------------------
*/

keyPhraseMonitorReducer.method.RESET_KEY_PHRASE_MONITOR = () => ({
  ...initialState.keyPhraseMonitor,
});

keyPhraseMonitorReducer.method.GET_KEY_PHRASE_MONITOR_PENDING = state => ({
  ...state,
  loading: true,
});

keyPhraseMonitorReducer.method.GET_KEY_PHRASE_MONITOR_FULFILLED = (state, action) => ({
  ...state,
  data: transformMonitor(action.payload),
  params: {},
  loading: false,
  loaded: true,
});

keyPhraseMonitorReducer.method.GET_KEY_PHRASE_MONITOR_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

keyPhraseMonitorReducer.method.CREATE_KEY_PHRASE_MONITOR_PENDING = state => ({
  ...state,
  loading: true,
});


keyPhraseMonitorReducer.method.CREATE_KEY_PHRASE_MONITOR_FULFILLED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

keyPhraseMonitorReducer.method.CREATE_KEY_PHRASE_MONITOR_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

keyPhraseMonitorReducer.method.UPDATE_KEY_PHRASE_MONITOR_PENDING = state => ({
  ...state,
  loading: true,
});


keyPhraseMonitorReducer.method.UPDATE_KEY_PHRASE_MONITOR_FULFILLED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

keyPhraseMonitorReducer.method.UPDATE_KEY_PHRASE_MONITOR_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

/*
Key phrase monitor reducers
----------------------------
*/

keyPhraseMonitorPostsReducer.method.GET_KEY_PHRASE_MONITOR_POSTS_PENDING = (state, action) => ({
  ...state,
  params: action.meta.params,
  loading: true,
});

keyPhraseMonitorPostsReducer.method.GET_KEY_PHRASE_MONITOR_POSTS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  params: action.meta.params,
  loading: false,
  loaded: true,
});

keyPhraseMonitorPostsReducer.method.GET_KEY_PHRASE_MONITOR_POSTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

keyPhraseMonitorPostsReducer.method.RESET_KEY_PHRASE_MONITOR_POSTS = () => ({
  ...initialState.keyPhraseMonitorPosts,
});
