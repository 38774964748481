import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { CardContent, Grid } from '@material-ui/core';
import Card from 'components/base/Card';
import withAll from 'components/base/withAll';
import H6 from 'components/base/titles/CardHeading/H6';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDaysFromDate, getDateFromDays } from 'utils/helpers';
import Loader from 'components/base/Loader';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import DateFilter from 'components/common/DateFilter';
import {
  getCompetitiveReportPlatformDistribution as getCompetitiveReportPlatformDistributionAction,
} from 'redux/reports/actions';
import PostsByPlatform from './PostsByPlatform';
import PlatformDistributionByBrand from './PlatformDistributionByBrand';
import AverageAudienceSizeByMonth from './AverageAudienceSizeByMonth';
import AverageAudienceSizeByPlatform from './AverageAudienceSizeByPlatform';
import SponsoredSelect from './SponsoredSelect';
import TabHeader from '../TabHeader';

const styles = () => ({
  root: {},
  filterSelect: {
    marginBottom: '2rem',
  },
  card: {
    marginBottom: '1.8rem',
  },
});

const PlatformDistribution = ({
  classes,
  params,
  isLoading,
  loaded,
  getCompetitiveReportPlatformDistribution,
  match: { params: { reportId } },
}) => {
  React.useEffect(() => {
    getCompetitiveReportPlatformDistribution(
      reportId,
      { ...params },
    );
  }, []);

  const handleDateSelect = (fromDate, toDate) => {
    getCompetitiveReportPlatformDistribution(
      reportId,
      {
        ...params,
        postedWithin: getDaysFromDate(fromDate),
        postedBefore: getDaysFromDate(toDate),
      },
    );
  };

  return (
    <div className={classes.root}>
      <TabHeader
        title="Platform Distribution"
        description="Provides insight into a brand's content distribution across each social
                    media platform and its overall reach across each platform."
      >
        <DateFilter
          handleSelect={handleDateSelect}
          selectedFromDate={getDateFromDays(params.postedWithin)}
          selectedToDate={getDateFromDays(params.postedBefore)}
          customDate
        />
      </TabHeader>

      <div className={classes.filterSelect}>
        <SponsoredSelect />
      </div>

      <Loader loading={isLoading} loaded={loaded}>
        <Card className={classes.card}>
          <CardContent sel="posts_by_brand">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <PostsByPlatform />
              </Grid>
              <Grid item md={12}>
                <PlatformDistributionByBrand />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent sel="creator_audience_size_by_month">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <AverageAudienceSizeByMonth />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent sel="creator_reach_by_platform">
            <H6 className={classes.creatorByPlatformTitle} align="left">
              <LabelWithInfoIcon
                labelText="Creator Reach by Platform"
                tooltip="The total creator reach broken down by platform."
              />
            </H6>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <AverageAudienceSizeByPlatform />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Loader>
    </div>
  );
};

PlatformDistribution.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getCompetitiveReportPlatformDistribution: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  params: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ competitiveReportDistribution }) => ({
  isLoading: competitiveReportDistribution.loading,
  loaded: competitiveReportDistribution.loaded,
  params: competitiveReportDistribution.params,
});

const mapDispatchToProps = {
  getCompetitiveReportPlatformDistribution: getCompetitiveReportPlatformDistributionAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(PlatformDistribution);
