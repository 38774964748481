export default theme => ({
  content: {
    paddingTop: '7em',
    margin: '0px 40px',
  },
  empty__list: {
    textAlign: 'center',
    marginTop: '40px',
    color: theme.palette.text.bold,
    fontSize: '18px',
  },
  actions: {
    padding: '1rem 0',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    '& button': {
      marginRight: '10px',
    },
  },
  members: {
  },
});
