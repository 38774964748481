import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { size } from 'lodash';
import { components, Async, AsyncCreatable } from 'react-select';
import { Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import Chip from 'components/base/Chip';
import styles from './styles';

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    onDelete={() => props.removeProps.onClick(props.value)}
    deleteIcon={<ClearIcon {...props.removeProps} />}
  />
);

const MultiValueContainer = () => (
  <span style={{ display: 'none' }} />
);

const ValueContainer = ({ children, ...props }) => (
  <div className={props.selectProps.classes.valueContainer} >
    <components.ValueContainer {...props}>
      {children}
    </components.ValueContainer>
  </div>
);

const Menu = props => (
  <components.Menu {...props} className={cx(props.className, 'autocomplete-dropdown')}>
    {props.children}
  </components.Menu>
);

const IndicatorSeparator = props => (
  // eslint-disable-next-line react/prop-types
  <span className={props.selectProps.classes.indicatorSeparator} />
);

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <SearchIcon />
  </components.DropdownIndicator>
);

const IndicatorsContainer = props => (
  <div className={props.selectProps.classes.indicatorsContainer} >
    <components.IndicatorsContainer {...props} />
  </div>
);

const Input = props => (
  <components.Input {...props} className={cx([props.selectProps.classes.input, 'async-input'])} />
);

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const Control = props => (
  <components.Control {...props} className={props.selectProps.classes.control} />
);

const selectComponents = {
  MultiValueContainer,
  DropdownIndicator,
  IndicatorsContainer,
  ValueContainer,
  IndicatorSeparator,
  Input,
  Placeholder,
  Control,
  Menu,
};

class MultiSelect extends PureComponent {
  handleChange = (value) => {
    this.props.onChange(value);
  };

  handleRemoveSelection = (selectedValue) => {
    this.props.handleRemoveSelection(selectedValue);
  };

  loadOptions = async (inputValue, callback) => {
    const { minChars, loadOptions } = this.props;
    if (minChars && size(inputValue) < minChars) {
      callback([]);
      return;
    }
    await loadOptions(inputValue, callback);
  }

  render() {
    const {
      value,
      name,
      placeholder,
      note,
      classes,
      theme,
      isMulti,
      isClearable,
      creatable,
    } = this.props;

    const selectStyles = {
      control: base => ({
        ...base,
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: theme.shape.borderRadius,
      }),
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div>
        {
          creatable ?
            <AsyncCreatable
              classes={classes}
              name={name}
              value={value}
              styles={selectStyles}
              onChange={this.handleChange}
              components={selectComponents}
              placeholder={placeholder}
              loadOptions={this.loadOptions}
              defaultOptions
              isClearable={isClearable}
              textFieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
              }}
              isMulti={isMulti}
            /> :
            <Async
              classes={classes}
              name={name}
              value={value}
              styles={selectStyles}
              onChange={this.handleChange}
              components={selectComponents}
              placeholder={placeholder}
              loadOptions={this.loadOptions}
              defaultOptions
              isClearable={isClearable}
              textFieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
              }}
              isMulti={isMulti}
            />
        }
        {note ? <Typography className={classes.notice}>{note}</Typography> : null}
        <div className={classes.multiSelectResultContainer}>
          {isMulti ? value.map((result, key) => (
            <MultiValue
              // eslint-disable-next-line
              key={`key-${key}`}
              value={result.value}
              removeProps={{ onClick: this.handleRemoveSelection }}
            >
              {result.label}
            </MultiValue>
          )) : null}
        </div>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleRemoveSelection: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
      ]),
      label: PropTypes.string,
    })),
  ]).isRequired,
  note: PropTypes.string,
  minChars: PropTypes.number,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  creatable: PropTypes.bool,
};

MultiSelect.defaultProps = {
  placeholder: '',
  value: [],
  options: [],
  note: '',
  handleRemoveSelection: null,
  minChars: null,
  isMulti: true,
  isClearable: false,
  creatable: true,
};

export default withStyles(styles, { withTheme: true })(MultiSelect);

