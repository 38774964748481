import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import styles from './styles';

const ErrorCard = ({
  errors,
  classes,
}) => (
  <React.Fragment>
    {!isEmpty(errors) &&
      <Card className={classes.error__container}>
        <CardContent>
          <Typography variant="body1" className={classes.error__title}><b>Please fix errors for:</b></Typography>
          <ul className={classes.error__list}>
            {/* eslint-disable-next-line */}
            {errors.map((error, key) => <li key={`error-${key}`}>{error}</li>)}
          </ul>
        </CardContent>
      </Card>
    }
  </React.Fragment>
);

ErrorCard.propTypes = {
  classes: PropTypes.shape({}),
  errors: PropTypes.arrayOf(PropTypes.string),
};

ErrorCard.defaultProps = {
  classes: {},
  errors: null,
};

export default withStyles(styles)(ErrorCard);

