import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import { remove } from 'lodash';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UsernameAutoComplete from 'components/app/NavBar/SearchNav/UsernameAutoComplete';
import Button from 'components/base/Button';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import { fetchObjectionableContentLists } from 'redux/brandSafetyReports/actions';
import { forms } from 'constants/config';
import styles from './styles';

const ObjectionPriority = {
  HIGH: 'HIGH',
  MED: 'MEDIUM',
  LOW: 'LOW',
  IGNORE: 'IGNORE',
};

const defaultObjectionPriority = ObjectionPriority.HIGH;

class BrandSafetyReportsForm extends React.Component {
  async componentDidMount() {
    await this.props.fetchObjectionableContentLists();

    const objectionableListPriorities = this.getDefaultObjectionableListPriorities();
    this.props.change(forms.BRAND_SAFETY_REPORT, 'objectionableListPriorities', objectionableListPriorities);

    const { preselectedInfluencer, formValues = {} } = this.props;
    const influencers = formValues.influencers || [];

    if (preselectedInfluencer) {
      const { id } = preselectedInfluencer;
      influencers.push({ id });
      this.props.change(forms.BRAND_SAFETY_REPORT, 'influencers', influencers);
    }
  }

  getDefaultObjectionableListPriorities() {
    const { objectionableContentLists: { data: { lists } } } = this.props;
    const result = {};
    lists.forEach((list) => {
      result[list.id] = defaultObjectionPriority;
    });
    return result;
  }

  handleUsernameSelected = (suggestion) => {
    const { formValues = {} } = this.props;
    const influencers = formValues.influencers || [];
    const influencer = suggestion.suggestion.value;
    const existingInfluencer = influencers.filter(i => i.id === influencer.id);
    if (!existingInfluencer.length) {
      influencers.push(influencer);
    }
    this.props.change(forms.BRAND_SAFETY_REPORT, 'influencers', influencers);
  };

  handleUsernameRemove = (influencerId) => {
    const { formValues = {} } = this.props;
    const influencers = formValues.influencers || [];
    remove(influencers, i => i.id === influencerId);
    this.props.change(forms.BRAND_SAFETY_REPORT, 'influencers', influencers);
  };

  handlePrioritySelected = (e) => {
    const { formValues = {} } = this.props;
    const defaultObjectionableListPriorities = this.getDefaultObjectionableListPriorities();
    const objectionableListPriorities = formValues.objectionableListPriorities || defaultObjectionableListPriorities;
    const objectionableListId = parseInt(e.target.name.split('__')[1], 10);
    objectionableListPriorities[objectionableListId] = e.target.value;
    this.props.change(forms.BRAND_SAFETY_REPORT, 'objectionableListPriorities', objectionableListPriorities);
  };

  renderInfluencerRow = (influencer) => {
    const { classes } = this.props;
    return (
      <div key={influencer.id} className={classes.influencerRow}>
        <span>{influencer.label}</span>
        <Button
          variant="text"
          className={classes.removeButton}
          onClick={() => this.handleUsernameRemove(influencer.id)}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </Button>
      </div>
    );
  };

  render() {
    const {
      classes, formValues = {}, objectionableContentLists: { data: { lists }, loading }, preselectedInfluencer,
    } = this.props;
    const influencers = formValues.influencers || [];

    return (
      <div className={classes.form}>
        { !preselectedInfluencer &&
          <div className={classes.inputs}>
            <div className={classes.title}>Add platform handle(s):</div>
            <div className={classes.influencerList}>
              {influencers.map(this.renderInfluencerRow)}
            </div>
            <UsernameAutoComplete
              width="100%"
              className={classes.usernameAutocomplete}
              onSuggestionSelected={this.handleUsernameSelected}
            />
          </div>
        }
        <div className={classes.priorities}>
          <div className={classes.title}>Brand Safety Reporting Priorities:</div>
          <Loader loading={loading}>
            <Table className={classes.prioritiesTable}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} width="40%" />
                  <TableCell className={classes.tableCell} width="15%" align="center">High</TableCell>
                  <TableCell className={classes.tableCell} width="15%" align="center">Med</TableCell>
                  <TableCell className={classes.tableCell} width="15%" align="center">Low</TableCell>
                  <TableCell className={classes.tableCell} width="15%" align="center">Exclude</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lists.map(i => (
                  <TableRow key={`objectionable_list_row_${i.id}`}>
                    <TableCell className={classes.tableCell} width="40%">{i.label}</TableCell>
                    <TableCell className={classes.tableCell} colSpan={4}>
                      <RadioGroup
                        row
                        className={classes.priorityRadios}
                        aria-label={i.title}
                        name={`objectionable_list__${i.id}`}
                        defaultValue={defaultObjectionPriority}
                        onChange={this.handlePrioritySelected}
                      >
                        <FormControlLabel control={<Radio />} label="" value={ObjectionPriority.HIGH} />
                        <FormControlLabel control={<Radio />} label="" value={ObjectionPriority.MED} />
                        <FormControlLabel control={<Radio />} label="" value={ObjectionPriority.LOW} />
                        <FormControlLabel control={<Radio />} label="" value={ObjectionPriority.IGNORE} />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Loader>
        </div>
      </div>
    );
  }
}

BrandSafetyReportsForm.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  initial: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.string,
  }),
  change: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  fetchObjectionableContentLists: PropTypes.func.isRequired,
  objectionableContentLists: PropTypes.shape({}).isRequired,
  preselectedInfluencer: PropTypes.shape({}),
};

BrandSafetyReportsForm.defaultProps = {
  initial: null,
  preselectedInfluencer: null,
};

const mapStateToProps = state => ({
  formValues: getFormValues(forms.BRAND_SAFETY_REPORT)(state),
  objectionableContentLists: state.objectionableContentLists,
});

const mapDispatchToProps = {
  change,
  fetchObjectionableContentLists,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  reduxForm({ form: forms.BRAND_SAFETY_REPORT }),
)(BrandSafetyReportsForm);
