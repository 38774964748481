import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Container, Tabs } from '@material-ui/core';
import PageTitle from 'components/base/titles/PageTitle';
import Tab from 'components/base/Tab';
import { a11yProps } from 'components/base/tabs/utils';
import withAll from 'components/base/withAll';
import { TABS } from '../constants';
import Platforms from './Platforms';
import Brands from './Brands';
import Download from './Download';
import UpgradeTab from './UpgradeTab';
import DateGenerated from './DateGenerated';

const styles = theme => ({
  root: {
    textAlign: 'left',
    paddingTop: theme.sizes.layout.appHeader.height.desktop,
    backgroundColor: theme.palette.white.light,
    marginBottom: '2rem',
    boxShadow: theme.customShadows[1],
  },
  pageTitle: {
    marginBottom: '0.6rem',
  },
  tabContainer: {
    backgroundColor: theme.palette.white.main,
    marginTop: '2rem',
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  leftCol: {
    flex: 1,
  },
  logo: {
    width: '8rem',
    filter: 'grayscale(100%)',
    opacity: 0.5,
  },
});

const Header = ({
  classes,
  tabValue,
  handleChange,
  data,
  containerRef,
  viewFullReport,
  isInternal,
}) => {
  const getTabs = () => (
    <div className={classes.tabContainer}>
      <Container maxWidth={false}>
        <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary">
          <Tab
            sel="post_activity_tab"
            className={classes.tab}
            label="Post Activity"
            {...a11yProps(TABS.POST_ACTIVITY)}
          />
          <UpgradeTab
            className={classes.tab}
            sel="platform_distribution_tab"
            label="Platform Distribution"
            requireUpgrade={!viewFullReport}
            {...a11yProps(TABS.PLATFORM_DISTRIBUTION)}
          />
          <UpgradeTab
            className={classes.tab}
            sel="top_content_tab"
            label="Top Content"
            requireUpgrade={!viewFullReport}
            {...a11yProps(TABS.TOP_CONTENT)}
          />
          <UpgradeTab
            className={classes.tab}
            sel="vertical_breakdown_tab"
            label="Vertical Breakdown"
            requireUpgrade={!viewFullReport}
            {...a11yProps(TABS.VERTICAL_DISTRIBUTION)}
          />
          <UpgradeTab
            className={classes.tab}
            sel="top_creators_tab"
            label="Top Creators"
            requireUpgrade={!viewFullReport}
            {...a11yProps(TABS.TOP_CREATORS)}
          />
          <UpgradeTab
            className={classes.tab}
            sel="audience_demographics_tab"
            label="Audience Demographics"
            requireUpgrade={!viewFullReport}
            {...a11yProps(TABS.AUDIENCE_DEMOGRAPHICS)}
          />
          {isInternal && (
            <Tab
              className={classes.tab}
              sel="estimated_influencer_spend"
              label="Estimated Creator Spend"
              {...a11yProps(TABS.ESTIMATED_INFLUENCER_SPEND)}
            />
          )}
        </Tabs>
      </Container>
    </div>
  );

  const title = () => (
    <div>{data.title}
      <Download
        reportId={data.id}
        containerRef={containerRef}
        dataToExport={data}
        isInternal={isInternal}
      />
    </div>
  );
  return (
    <div className={classes.root}>
      <Container maxWidth={false} className={classes.container}>
        <div className={classes.leftCol}>
          <PageTitle className={classes.pageTitle} title={title()} />
          <DateGenerated createdAt={data.createdAt} />
          <Platforms data={data} />
          <Brands data={data} />
        </div>
      </Container>
      {getTabs()}
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  tabValue: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  containerRef: PropTypes.func.isRequired,
  viewFullReport: PropTypes.bool,
  isInternal: PropTypes.bool,
};

Header.defaultProps = {
  viewFullReport: false,
  isInternal: false,
};

const mapStateToProps = ({ competitiveReportStat: { data: { viewFullReport } } }) => ({
  viewFullReport,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(Header);
