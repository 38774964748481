import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Button from 'components/base/Button';
import { withStyles } from '@material-ui/core/styles';
import withAll from 'components/base/withAll';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import withModal from 'components/base/withModal';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.green.main,
    padding: '1rem',
    textAlign: 'center',
    color: theme.palette.white.main,
    zIndex: 3,
  },
  signupBtn: {
    margin: '0 1rem',
  },
});

const SignupBanner = ({
  classes,
  open,
  closeModal,
  openModal,
}) => {
  const signupClick = () => {
    openModal();
    mpTrack(MP_EVENTS.CLICK_REPORT_SIGNUP);
  };
  return (
    <div className={classes.root}>
      <BetaSignupModal
        open={open}
        closeModal={closeModal}
        onSignupComplete={closeModal}
      />
      <Typography variant="body"><b>Compare with other brands now</b></Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={signupClick}
        className={classes.signupBtn}
      >
        Create a free report
      </Button>
    </div>
  );
};

SignupBanner.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default withAll(
  withStyles(styles),
  withModal,
)(SignupBanner);
