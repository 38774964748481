import {
  SORT_DIRECTIONS,
  PLATFORMS,
  DEFAULT_POSTED_WITHIN,
  DEFAULT_POSTED_BEFORE,
} from 'constants/options';
import { config } from 'theme';

export const TABS = {
  POST_ACTIVITY: 0,
  PLATFORM_DISTRIBUTION: 1,
  TOP_CONTENT: 2,
  VERTICAL_DISTRIBUTION: 3,
  TOP_CREATORS: 4,
  AUDIENCE_DEMOGRAPHICS: 5,
  ESTIMATED_INFLUENCER_SPEND: 6,
};

export const EXPORT_TAB_NAMES = {
  [TABS.POST_ACTIVITY]: 'post_activity',
  [TABS.PLATFORM_DISTRIBUTION]: 'platform_distribution',
  [TABS.TOP_CONTENT]: 'top_content',
  [TABS.TOP_CREATORS]: 'top_creators',
  [TABS.AUDIENCE_DEMOGRAPHICS]: 'audience_demographics',
};

export const ALL_LABEL = 'All';

export const LEGEND_MAX_CHAR = 20;

export const AVAILABLE_REPORT_PLATFORMS = [
  PLATFORMS.INSTAGRAM,
  PLATFORMS.TWITTER,
  PLATFORMS.TIKTOK,
];

export const POST_SORT_OPTIONS = {
  FOLLOWER_COUNT: 'user.follower_count',
  ENGAGEMENT: 'engagement',
  INTERACTIONS: 'interactions',
};
export const CREATOR_SORT_OPTIONS = {
  FOLLOWER_COUNT: 'follower_count',
  ENGAGEMENT: 'engagement',
  SPONSORED_ENGAGEMENT: 'sponsored_engagement',
};
export const POST_SORT_OPTIONS_LABELS = {
  [POST_SORT_OPTIONS.FOLLOWER_COUNT]: 'Follower Count',
  [POST_SORT_OPTIONS.ENGAGEMENT]: 'Sponsored Engagement Rate',
  [POST_SORT_OPTIONS.INTERACTIONS]: 'Interaction',
};

export const CREATOR_SORT_OPTIONS_LABELS = {
  [CREATOR_SORT_OPTIONS.FOLLOWER_COUNT]: 'Follower Count',
  [CREATOR_SORT_OPTIONS.ENGAGEMENT]: 'Engagement Rate',
  [CREATOR_SORT_OPTIONS.SPONSORED_ENGAGEMENT]: 'Sponsored Engagement Rate',
};

export const DEFAULT_POST_PARAMS = {
  sort: POST_SORT_OPTIONS.FOLLOWER_COUNT,
  sortDirection: SORT_DIRECTIONS.desc,
  platforms: AVAILABLE_REPORT_PLATFORMS,
  combinedMentions: false,
  postedWithin: DEFAULT_POSTED_WITHIN,
  postedBefore: DEFAULT_POSTED_BEFORE,
};

export const DEFAULT_CREATOR_PARAMS = {
  sort: CREATOR_SORT_OPTIONS.ENGAGEMENT,
  sortDirection: CREATOR_SORT_OPTIONS.desc,
  platforms: AVAILABLE_REPORT_PLATFORMS,
  postedWithin: DEFAULT_POSTED_WITHIN,
  postedBefore: DEFAULT_POSTED_BEFORE,
};

export const FOLLOWER_RANGE_LABELS = {
  '10K': '<10k',
  '10K50K': '10k-50k',
  '50K100K': '50k-100k',
  '100K500K': '100k-500k',
  '500K750K': '500k-750k',
  '750K1M': '750k-1k',
  '1M': '1m+',
};

export const FOLLOWER_RANGE_COLORS = {
  '10K': config.palette.red.main,
  '10K50K': config.palette.yellow.main,
  '50K100K': config.palette.blue.dark,
  '100K500K': config.palette.orange.label,
  '500K750K': config.palette.green.main,
  '750K1M': config.palette.pink.main,
  '1M': config.palette.barChart.gradient.blue,
};
