import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import Stat from '../Stat';
import styles from './styles';

const statWidth = (stats) => {
  const statsCount = size(stats);
  const percentage = Math.floor(100 / statsCount);
  return `${percentage}%`;
};

const Stats = (props) => {
  const { classes, stats } = props;
  const displayedStats = stats.map(stat => (
    <div key={stat.title} className={classes.stat__wrapper} style={{ width: statWidth(stats) }}>
      <Stat
        value={stat.value}
        title={stat.title}
        tooltip={stat.tooltip}
      />
    </div>
  ));
  return (
    <div className={classes.stats}>
      {displayedStats}
    </div>
  );
};

Stats.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  stats: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    tooltip: PropTypes.string.isRequired,
  })).isRequired,
};

export default withStyles(styles)(Stats);
