import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
// eslint-disable-next-line
import Dimensions from 'react-dimensions';
import XAxis from './XAxis';
import Grid from './Grid';


const X_LABELS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];
const isLastIndex = (data, index) => data.length - 1 === index;

const styles = () => ({
  root: {
    flex: 1,
  },
});

const rowHeight = (dataset, index, containerWidth) => containerWidth / dataset[index].data.length;

class MatrixGraph extends React.PureComponent {
  render() {
    const {
      dataset,
      classes,
      index,
      containerWidth,
    } = this.props;
    const height = rowHeight(dataset, index, containerWidth);
    return (
      <div
        className={classes.root}
        style={{ height }}
      >
        <Grid dataset={dataset} index={index} labels={X_LABELS} />
        {isLastIndex(dataset, index) && <XAxis labels={X_LABELS} />}
      </div>
    );
  }
}

MatrixGraph.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  dataset: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
};

export default Dimensions()(withStyles(styles, { withTheme: true })(MatrixGraph));
