import { camelCase, invert } from 'lodash';
import { faInstagram, faTiktok, faTwitter, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import moment from 'moment';
import { strartOfDayISO } from 'utils/helpers';

export const CATEGORIES = [
  { value: 'animals-and-pets', label: 'Animals & Pets' },
  { value: 'artist', label: 'Artist' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'beauty', label: 'Beauty' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'food', label: 'Food' },
  { value: 'gaming', label: 'Gaming' },
  { value: 'interior-design', label: 'Interior Design' },
  { value: 'moms', label: 'Moms' },
  { value: 'music', label: 'Music' },
  { value: 'travel', label: 'Travel' },
];

export const CATEGORY_DICT = CATEGORIES.reduce((result, item) => ({
  ...result,
  [camelCase(item.value)]: item.label,
}), {});

export const SEARCH_SORT_OPTIONS = [
  { label: 'Follower count', value: 'follower_count' },
  { label: 'Avg Likes', value: 'average_likes' },
  { label: 'Avg Comments', value: 'average_comments' },
  { label: 'Engagement Rate', value: 'engagement' },
  { label: 'Sponsored Engagement Rate', value: 'sponsored_engagement' },
  { label: 'Relevant Post Engagement', value: 'relevant_post_engagement' },
];

export const MEMBER_SORT_OPTIONS = [
  { label: 'Follower count', value: 'follower_count' },
  { label: 'Avg Likes', value: 'average_likes' },
  { label: 'Avg Comments', value: 'average_comments' },
  { label: 'Engagement Rate', value: 'engagement' },
  { label: 'Sponsored Engagement Rate', value: 'sponsored_engagement' },
];

export const GENDER_LABEL_MAP = {
  m: 'Male',
  f: 'Female',
};

export const GENDER_LABEL_REVERSE_MAP = invert(GENDER_LABEL_MAP);

export const SEARCH_PAGE_SIZE_OPTIONS = [
  20, 50, 100,
];

export const INFLUENCER_SEARCH_TABS = {
  INFLUENCER: 'INFLUENCER',
  AUDIENCE: 'AUDIENCE',
  CONTENT: 'CONTENT',
};

export const DEFAULT_INFLUENCER_SEARCH_SORT = 'follower_count';

export const DEFAULT_INFLUENCER_SEARCH_PAGE_SIZE = 20;

export const ALL_GENDER = 'ALL';

export const DEFAULT_MIN_FOLLOWER_COUNT = 100000;
export const DEFAULT_MAX_FOLLOWER_COUNT = 500000;

export const AVAILABLE_FOLLOWER_MIN = 10000;
export const AVAILABLE_FOLLOWER_MAX = 1000000;
export const FOLLOWER_SEARCH_STEP = 10000;
export const PLATFORMS = {
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  SNAPCHAT: 'snapchat',
  TIKTOK: 'tiktok',
  PINTEREST: 'pinterest',
  BLOG: 'blog',
};

export const PLATFORM_COLOR = {
  instagram: '#8a3ab9',
  snapchat: '#f5ca55',
  twitter: '#70aaec',
  facebook: '#4b68a9',
  youtube: '#d83f3a',
  pinterest: '#b73734',
  tiktok: '#010101',
};

export const PLATFORM_ICON_STYLES = {
  instagram: () => ({
    backgroundColor: PLATFORM_COLOR.instagram,
  }),
  tiktok: () => ({
    backgroundColor: PLATFORM_COLOR.tiktok,
  }),
  twitter: () => ({
    backgroundColor: PLATFORM_COLOR.twitter,
  }),
  pinterest: () => ({
    backgroundColor: PLATFORM_COLOR.pinterest,
  }),
  youtube: () => ({
    backgroundColor: PLATFORM_COLOR.youtube,
  }),
};

export const PLATFORM_ICONS = {
  instagram: <FontAwesomeIcon icon={faInstagram} color={PLATFORM_COLOR.instagram} />,
  tiktok: <FontAwesomeIcon icon={faTiktok} color={PLATFORM_COLOR.tiktok} />,
  twitter: <FontAwesomeIcon icon={faTwitter} color={PLATFORM_COLOR.twitter} />,
  pinterest: <FontAwesomeIcon icon={faPinterest} color={PLATFORM_COLOR.pinterest} />,
  youtube: <FontAwesomeIcon icon={faYoutube} color={PLATFORM_COLOR.youtube} />,
};

export const PLAIN_PLATFORM_ICONS = {
  instagram: <FontAwesomeIcon icon={faInstagram} />,
  tiktok: <FontAwesomeIcon icon={faTiktok} />,
  twitter: <FontAwesomeIcon icon={faTwitter} />,
  pinterest: <FontAwesomeIcon icon={faPinterest} />,
  youtube: <FontAwesomeIcon icon={faYoutube} />,
};

export const PLATFORM_LABELS = {
  instagram: 'Instagram',
  tiktok: 'Tiktok',
  twitter: 'Twitter',
};

export const SORT_DIRECTIONS = {
  asc: 'asc',
  desc: 'desc',
};

export const DATE_FILTER_MIN_DATE = moment().subtract(24, 'month');
export const DATE_FILTER_MAX_DATE = moment();

export const DEFAULT_FROM_DATE = strartOfDayISO(moment().subtract(12, 'month'));
export const DEFAULT_TO_DATE = strartOfDayISO(DATE_FILTER_MAX_DATE);

export const DEFAULT_POSTED_WITHIN = moment().diff(moment().subtract(12, 'month'), 'days');
export const DEFAULT_POSTED_BEFORE = moment().diff(DATE_FILTER_MAX_DATE, 'days');

export const DATE_FILTER_OPTIONS = [
  { label: '6 Months', value: 6 },
  { label: '12 Months', value: 12 },
  { label: '24 Months', value: 24 },
];
