import Cookies from 'js-cookie';
import { API_CONFIG } from 'constants/config';
import { login } from 'constants/routing';
import { newReducer } from '../utils';

const initialState = {
  auth: {
    username: null,
    loading: true,
    loaded: false,
    isAuthenticated: false,
  },
  signup: {
    loading: true,
    isAuthenticated: false,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const authReducer = newReducer(initialState.auth);
export const signupReducer = newReducer(initialState.signup);

authReducer.method.LOGIN_FULFILLED = (state, action) => {
  Cookies.set(API_CONFIG.TOKEN_NAME, action.payload.key);
  return {
    ...state,
    isAuthenticated: true,
    loading: false,
    loaded: true,
  };
};

authReducer.method.LOGIN_PENDING = state => ({
  ...state,
  isAuthenticated: false,
  loading: false,
});

authReducer.method.LOGIN_REJECTED = (state) => {
  Cookies.remove(API_CONFIG.TOKEN_NAME);
  return {
    ...state,
    isAuthenticated: false,
    loading: false,
    loaded: true,
  };
};


signupReducer.method.REGISTER_PRISM_USER_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
});

signupReducer.method.REGISTER_PRISM_USER_PENDING = state => ({
  ...state,
  loading: true,
});

signupReducer.method.REGISTER_PRISM_USER_REJECTED = state => ({
  ...state,
  loading: false,
});

authReducer.method.GET_USER_PENDING = state => ({
  ...state,
  isAuthenticated: false,
  loading: true,
});

authReducer.method.GET_USER_FULFILLED = () => ({
  isAuthenticated: true,
  loading: false,
  loaded: true,
});

authReducer.method.GET_USER_REJECTED = () => ({
  isAuthenticated: false,
  loading: false,
  loaded: true,
});

authReducer.method.LOGOUT_FULFILLED = () => {
  Cookies.remove(API_CONFIG.TOKEN_NAME);
  window.location.href = login();
};

authReducer.method.CLEAR_AUTH = () => ({
  loading: false,
  isAuthenticated: false,
});
