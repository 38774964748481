import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import { PLAIN_PLATFORM_ICONS, PLATFORM_ICON_STYLES } from 'constants/options';

const styles = theme => ({
  root: {
    display: 'inline-flex',
    margin: '0 0.2rem',
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '1rem',
  },
});

const PlatformItem = ({ platform, theme, classes }) => {
  if (!PLATFORM_ICON_STYLES[platform]) {
    return null;
  }
  return (
    <Avatar className={classes.root} style={PLATFORM_ICON_STYLES[platform](theme)}>
      {PLAIN_PLATFORM_ICONS[platform]}
    </Avatar>
  );
};

PlatformItem.propTypes = {
  platform: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(PlatformItem);
