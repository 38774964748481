import React from 'react';
import Modal from 'components/base/modals/PrismModal';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import { withRouter } from 'react-router-dom';
import { createVerticalDetailUrl } from 'constants/routing';
import { VERTICAL_PATHS_NAME_MAP } from 'constants/config';
import VerticalCard from 'components/common/FeaturedVerticals/VerticalCard';
import { connect } from 'react-redux';
import { getVerticalSummary } from 'redux/stats/actions';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import styles from './styles';


class UnauthorizedVertical extends React.Component {
  componentDidMount() {
    this.props.getVerticalSummary();
  }

  link = (vertical) => {
    // eslint-disable-next-line
    document.location = document.location = createVerticalDetailUrl(VERTICAL_PATHS_NAME_MAP[vertical]);
  }


  render() {
    const {
      classes,
      open,
      verticals,
      loaded,
      isLoading,
      showSignupModal,
    } = this.props;
    return (
      <Modal
        title="Subscribers only"
        open={open}
        onClose={() => {}}
        width="80%"
        hideClose
      >
        <Button
          variant="contained"
          color="primary"
          onClick={showSignupModal}
        >
          Sign up for your {process.env.BETA_DEFAULT_TRIAL_DAYS}-day free trial
        </Button>
        <Typography className={classes.noCreditCard} variant="body">No credit card required</Typography>
        <Typography className={classes.orTitle} variant="body">OR</Typography>
        <Typography variant="h5">View the below industries for FREE.</Typography>
        <Loader loading={isLoading} loaded={loaded}>
          <Grid container spacing={3} className={classes.root} alignContent="center">
            {verticals.map(verticalData => (
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.verticalLink}
                  variant="link"
                  onClick={() => this.link(verticalData.vertical)}
                >
                  <VerticalCard data={verticalData} />
                </Button>
              </Grid>
            ))}
          </Grid>
        </Loader>
      </Modal>
    );
  }
}

UnauthorizedVertical.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  verticals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  getVerticalSummary: PropTypes.func.isRequired,
  showSignupModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ verticals }) => ({
  isLoading: verticals.loading,
  loaded: verticals.loaded,
  verticals: verticals.data.verticals.filter(v => v.isPublic),
});

export default withAll(
  connect(mapStateToProps, { getVerticalSummary }),
  withStyles(styles),
)(withRouter(UnauthorizedVertical));
