import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ChipFilter from 'components/base/ChipFilter';
import withAll from 'components/base/withAll';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    marginRight: '0.3rem',
  },
});

class PlatformFilter extends React.PureComponent {
  optionSelected = platformSelected => this.props.handleSelect(platformSelected);

  render() {
    const {
      classes,
      params: { platforms: platformsSelected },
      platforms: availablePlatforms,
    } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.label}>Platform:</Typography>
        {availablePlatforms.map(p => (
          <ChipFilter
            key={p}
            buttonSelected={platformsSelected.includes(p)}
            onDelete={() => this.optionSelected(p)}
            onClick={() => this.optionSelected(p)}
            disabled={platformsSelected.includes(p) && platformsSelected.length === 1}
            avatar={<PlatformItem platform={p} />}
          />
        ))}
      </div>
    );
  }
}

PlatformFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSelect: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string),
};

PlatformFilter.defaultProps = {
  platforms: [],
};

const mapStateToProps = ({ competitiveReportStat }) => ({
  platforms: get(competitiveReportStat, 'data.platforms', []),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
  withRouter,
)(PlatformFilter);
