import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    marginBottom: '0.8rem',
  },
});

const H6 = ({ children, classes, ...other }) => (
  <Typography className={classes.root} variant="h6" {...other}>{children}</Typography>
);

H6.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(H6);
