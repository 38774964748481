import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import HomeTitle from 'components/base/titles/HomeTitle';
import HomeSubTitle from 'components/base/titles/HomeSubTitle';
import Button from 'components/base/Button';
import BulleitItem from 'components/base/sections/BulleitItem';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import { competitiveReportCreateUrl } from 'constants/routing';
import reportImage from 'images/home/competitiveinfluencerreports.png';

const styles = () => ({
  root: {
    marginBottom: '4rem',
    position: 'relative',
    marginTop: '4rem',
  },
  container: {},
  title: {
    justifyContent: 'left',
  },
  bullietContainer: {
    marginBottom: '1rem',
  },
  image: {
    width: '100%',
    overflow: 'hidden',
    '& > img': {
      width: '100%',
    },
  },
});

const CompetitiveReports = ({ classes, history }) => (
  <div className={classes.root}>
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={8}>
        <Grid item md={6}>
          <div className={classes.image}>
            <img
              src={reportImage}
              alt="search"
            />
          </div>
        </Grid>
        <Grid item md={6}>
          <HomeTitle
            title={<React.Fragment>Create Custom Competitor <br /> Benchmark Reports</React.Fragment>}
            className={classes.title}
            titleId="competitiveReportsH1"
          />
          <HomeSubTitle titleId="competitiveReportsH2">
            Create a report with the specific competitors that you want to track
          </HomeSubTitle>
          <Grid container className={classes.bullietContainer} id="competitiveReportsBullietContainer">
            <BulleitItem>Share Of Voice</BulleitItem>
            <BulleitItem>Top Creators</BulleitItem>
            <BulleitItem>Top Performing Content</BulleitItem>
            <BulleitItem>Top Performing Days</BulleitItem>
            <BulleitItem>Influencer Tier Breakdown</BulleitItem>
            <BulleitItem>Top Brands</BulleitItem>
            <BulleitItem>Category Trends & Activity</BulleitItem>
            <BulleitItem>Influencer Demographics</BulleitItem>
            <BulleitItem>& More…</BulleitItem>
          </Grid>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => history.push(competitiveReportCreateUrl())}
            id="competitiveReportsBtnCreateReport"
          >
            Create a report
          </Button>
        </Grid>
      </Grid>
    </Container>
  </div>
);

CompetitiveReports.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withAll(
  withStyles(styles),
  withRouter,
)(CompetitiveReports);
