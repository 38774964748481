export default theme => ({
  saved__list__icon: {
    marginRight: '0.8rem',
  },
  influencer__text: {
    fontSize: '18px',
    margin: '10px',
    color: theme.palette.text.bold,
  },
  menu__container: {
  },
  menu__content: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    height: '300px',
  },
  list__options: {
    height: '250px',
    overflow: 'scroll',
  },
  list__option: {
    display: 'flex',
    flexDirection: 'row',
  },
  list__name: {
    fontSize: '18px',
    color: theme.palette.text.meta,
    marginTop: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 70px)',
  },
  add__to__list__button: {
    margin: '0px 10px 10px 10px',
    textTransform: 'none',
  },
  selected__list: {
    color: theme.palette.blue.main,
  },
});
