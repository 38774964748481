// eslint-disable-next-line import/prefer-default-export
export const TABS = {
  SCORECARD: 0,
  BRAND_SAFETY: 1,
  UNUSUAL_POST_ENGAGEMENT: 2,
  // SPONSORED_ACTIVITY: 1,
  // COMPETITIVE_CONTENT: 2,
};

export const RANK_ERROR_TEXT = 'This report has insufficient sponsored posts to display.';
