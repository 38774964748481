export default theme => ({
  root: {
    backgroundColor: theme.palette.white.light,
    position: 'relative',
    height: '100%',
    boxShadow: theme.customShadows[1],
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.5s ease-out',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      width: theme.sizes.sideBar.desktop,
    },
  },
  search_bar__container: {
    flexGrow: 1,
    overflow: 'scroll',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '7rem',
    },
  },
  side_bar__footer: {
    width: '100%',
    height: '4rem',
  },
  btn__container: {
    padding: '1rem 0',
    marginLeft: '3rem',
    marginRight: '1rem',
    display: 'flex',
    flexDirection: 'row',
    '& button': {
      flex: 1,
      margin: '0 0.3rem',
      '&:first-child': {
        marginLeft: '-0.2rem',
      },
      '&:last-child': {
        marginRight: '-0.2rem',
      },
    },
    '& .button-wrapper': {
      display: 'inline-flex',
      flex: 1,
      margin: '0 0.3rem',
      '&:first-child': {
        marginRight: '0.5rem',
      },
    },
  },
});
