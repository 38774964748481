export default theme => ({
  root: {},
  card: {
    marginBottom: '1.8rem',
    position: 'relative',
  },
  note: {
    fontSize: '12px',
    textAlign: 'center',
    color: theme.palette.black.dark,
  },
  brandSafetyIndex: {
    fontSize: '21px',
    textAlign: 'center',
  },
  detailsBtn: {
    color: theme.palette.black.light,
    textDecoration: 'underline',
  },
  gaugeItem: {
    textAlign: 'center',
  },
  safetyLegend: {
    marginTop: '0.4rem',
    textAlign: 'center',
  },
});
