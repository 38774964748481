import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

export default withStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    margin: '0.2rem',
    // background color may cover text of chips when exporting to pdf due to this issue:
    // https://github.com/niklasvh/html2canvas/issues/2739
    backgroundColor: props => (props.isExportedAsPDF ? 'none' : theme.palette.blue.light),
    color: theme.palette.blue.dark,
  },
  colorPrimary: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
  },
}))(Chip);
