import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import HomeTitle from 'components/base/titles/HomeTitle';
import { Container, Grid } from '@material-ui/core';
import StatCard from 'components/common/StatCard';

const styles = () => ({
  root: {
    marginBottom: '4rem',
    paddingBottom: '2rem',
    position: 'relative',
    marginTop: '16rem',
  },
  container: {
    position: 'relative',
    zIndex: 1,
  },
  title: {
    justifyContent: 'center',
    marginBottom: '3rem',
    textAlign: 'center',
    '&:before': {
      width: '0',
      marginBottom: '0',
      height: 0,
      backgroundColor: 'transparent',
    },
  },
});

const ContentCounterSection = ({ classes }) => (
  <div className={classes.root}>
    <Container maxWidth={false} className={classes.container}>
      <HomeTitle
        titleId="counterSectionTitle"
        title="Power your influencer search with data"
        className={classes.title}
      />
      <Grid container spacing={8}>
        <Grid item xs={12} md={3}>
          <StatCard
            title="DATA POINTS ANALYZED"
            value="100B+"
            cardId="counterStat1"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="HASHTAGS & MENTIONS"
            value="87M+"
            cardId="counterStat2"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="PIECES OF CONTENT"
            value="421M+"
            cardId="counterStat3"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="UNIQUE CREATORS"
            value="1M+"
            cardId="counterStat4"
          />
        </Grid>
      </Grid>
    </Container>
  </div>
);

ContentCounterSection.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ContentCounterSection);
