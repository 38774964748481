
export const LOCATION_SEARCH_QUERY = {
  followerRange: { min: 10000, max: 1000000 },
  categories: [{ value: 'food', label: 'Food' }],
  locations: [{
    label: 'Los Angeles, CA',
    value: {
      type: 'CITY',
      lat: 34.0522,
      lon: -118.244,
    },
  }, {
    label: 'New York City, NY',
    value: {
      type: 'CITY',
      lat: 40.7143,
      lon: -74.006,
    },
  }],
};

export const TAGS_SEARCH_QUERY = [{
  label: 'pizza',
  value: {
    label: 'pizza',
    text: 'pizza',
  },
}];

export const TAGS_SEARCH_QUERY_2 = [{
  label: 'dessert',
  value: {
    label: 'dessert',
    text: 'dessert',
  },
}];

export const STEPS_INDXES = {
  SEARCH_CATEGORIES: 0,
  SEARCH_LOCATIONS: 1,
  SEARCH_TAGS: 2,
  SEARCH_TAGS_2: 3,
  SAVED_LIST: 4,
  SAVED_LIST_2: 5,
  SAVED_LIST_3: 6,
  SAVED_LIST_4: 7,
  SAVED_LIST_VIEW: 8,
  SAVED_LIST_DETAIL: 9,
  SAVED_LIST_DETAIL_2: 10,
  HELP_BUTTON: 11,
  FINISH: 12,
};
