import React, { PureComponent } from 'react';
import Input from 'components/base/forms/Input';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validInstagram } from 'utils/validators';
import { required, exclusion } from 'redux-form-validators';
import PlatformToggleButton from './PlatformToggleButton';
import styles from '../styles';


const DEFAULT_PLATFORM_TYPE = 'instagram';

class InfluencerPlatform extends PureComponent {
  componentDidMount() {
    const { initialize } = this.props;
    initialize({ username: '', platform: DEFAULT_PLATFORM_TYPE });
  }

  onSubmit = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
    this.props.reset('requestInfluencerForm');
  }

  render() {
    const {
      classes,
      handleSubmit,
      platforms,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className={classes.form}>
        <div className={classes.platform__item}>
          <Field
            component={Input}
            className={classes.username}
            margin="normal"
            name="username"
            validate={[
              required({ msg: 'Username is required.' }),
              validInstagram,
              exclusion({ in: platforms, msg: 'Platform already added.' }),
            ]}
          />
          <Field
            component={PlatformToggleButton}
            className={classes.username}
            margin="normal"
            name="platform"
            validate={[
              required({ msg: 'Platform type is required.' }),
            ]}
          />
          <div className={classes.remove_btn__container} />
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.btn__add}
        >
          Add
        </Button>
      </form>
    );
  }
}

InfluencerPlatform.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  reset: PropTypes.func.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ reset }, dispatch);

export default connect(null, mapDispatchToProps)(withStyles(styles)(reduxForm({
  form: 'requestInfluencerForm',
  touchOnBlur: false,
})(InfluencerPlatform)));
