import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import { slice } from 'lodash';
import { withStyles } from '@material-ui/styles';
import RelatedBrandRow from './RelatedBrandRow';

const styles = () => ({
  root: {},
  tickerColumn: {
    width: '7rem',
  },
});

const MAX_BRANDS = 5;

const RelatedBrandTable = ({ classes, relatedBrands }) => (
  <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Brand</TableCell>
          <TableCell align="left">Platforms</TableCell>
          <TableCell align="right">Total Sponsored Posts</TableCell>
          <TableCell align="right">Total Creators</TableCell>
          <TableCell align="right" className={classes.tickerColumn}>6 Week Average </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {relatedBrands.map(b => <RelatedBrandRow brand={b} />)}
      </TableBody>
    </Table>
  </TableContainer>
);

RelatedBrandTable.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  relatedBrands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ brand }) => ({
  brand: brand.data,
  relatedBrands: slice(brand.data.relatedBrands, 0, MAX_BRANDS),
});

export default connect(mapStateToProps, null)(withStyles(styles)(RelatedBrandTable));
