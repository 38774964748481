import { toast } from 'react-toastify';
import { join, size } from 'lodash';
import { mpTrack } from 'utils/copenhagen';
import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';

export const FETCH_LISTS = 'FETCH_LISTS';
export const EXPORT_LIST = 'EXPORT_LIST';
export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const FETCH_LIST = 'FETCH_LIST';
export const UPDATE_LIST = 'UPDATE_LIST';
export const DELETE_LIST = 'DELETE_LIST';
export const ADD_TO_LISTS = 'ADD_TO_LISTS';
export const REMOVE_FROM_LISTS = 'REMOVE_FROM_LISTS';
export const FETCH_LIST_CATEGORIES = 'FETCH_LIST_CATEGORIES';

export const fetchLists = () => async (dispatch) => {
  dispatch({
    type: FETCH_LISTS,
    payload: request('post', API.FETCH_LISTS, {}),
  });
};

export const exportListData = (listId, sort, sortDirection) => async (dispatch) => {
  await dispatch({
    type: EXPORT_LIST,
    payload: request('post', API.EXPORT_LIST, {
      data: {
        list_id: listId,
        sort,
        sort_direction: sortDirection,
      },
    }),
  });
};

export const fetchMembers = (listId, sort, sortDirection) => async (dispatch) => {
  mpTrack('load__list_members', { listId, sort, sortDirection });
  dispatch({
    type: FETCH_MEMBERS,
    payload: request('post', API.FETCH_MEMBERS, {
      data: {
        list_id: listId,
        sort,
        sort_direction: sortDirection,
      },
    }),
  });
};

export const fetchList = listId => async (dispatch) => {
  await dispatch({
    type: FETCH_LIST,
    payload: request('get', API.FETCH_LIST(listId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const updateList = (listId, listUpdate, other) => async (dispatch) => {
  await dispatch({
    type: UPDATE_LIST,
    payload: request('post', API.UPDATE_LIST, {
      data: {
        list_id: listId,
        update: listUpdate,
      },
    }),
    meta: {
      listId,
      listUpdate,
      other,
    },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const deleteList = listId => async (dispatch) => {
  mpTrack('delete__list', { listId });
  dispatch({
    type: DELETE_LIST,
    payload: request('post', API.DELETE_LIST, {
      data: {
        list_id: listId,
      },
    }).then((response) => {
      toast.success('List Deleted Successfully');
      return response;
    }),
    meta: {
      listId,
    },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const addToLists = (listIds, influencerIds) => async (dispatch) => {
  mpTrack('save__influencer_to_list', { listIds, influencerIds });
  return dispatch({
    type: ADD_TO_LISTS,
    payload: request('post', API.ADD_TO_LISTS, {
      data: {
        list_ids: listIds,
        influencer_ids: influencerIds,
      },
    }).then((response) => {
      const { success, failed } = response;
      if (size(success) > 0) {
        const succeededNames = join(success.map(list => list.name), ', ');
        toast.success(`Successfully added to ${succeededNames}`);
      }
      if (size(failed) > 0) {
        const failedNames = join(failed.map(list => list.name), ', ');
        toast.error(`Failed to add creators to ${failedNames}`);
      }
      return response;
    }),
  });
};

export const removeFromLists = (listIds, influencerIds) => async (dispatch) => {
  mpTrack('delete__influencer_from_list', { listIds, influencerIds });
  dispatch({
    type: REMOVE_FROM_LISTS,
    payload: request('post', API.REMOVE_FROM_LISTS, {
      data: {
        list_ids: listIds,
        influencer_ids: influencerIds,
      },
    }).then((response) => {
      const { success, failed } = response;
      if (size(success) > 0) {
        const succeededNames = join(success.map(list => list.name), ', ');
        toast.success(`Successfully removed from ${succeededNames}`);
      }
      if (size(failed) > 0) {
        const failedNames = join(failed.map(list => list.name), ', ');
        toast.error(`Failed to add creators from ${failedNames}`);
      }
      return response;
    }),
    meta: {
      listIds,
      influencerIds,
    },
  });
};

export const fetchCategories = () => async (dispatch) => {
  dispatch({
    type: FETCH_LIST_CATEGORIES,
    payload: request('post', API.FETCH_LIST_CATEGORIES, {}),
  });
};
