import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Icon, Typography } from '@material-ui/core';
import Tooltip from 'components/base/Tooltip';
import styles from './styles';

const SearchSection = ({
  classes,
  title,
  children,
  icon,
  className,
  id,
  tooltip,
}) => (

  <div id={id} className={cx(classes.root, className)}>
    {icon && <div className={classes.sectionIcon}>{icon}</div>}

    <Typography variant="h6" className={classes.sectionTitle}>{title}</Typography>
    <Tooltip title={tooltip} placement="right">
      <div className={classes.sectionInfoIconDiv}>
        <Icon className={classes.sectionInfoIcon}>info_outlined</Icon>
      </div>
    </Tooltip>
    <div>
      {children}
    </div>
  </div>

);

SearchSection.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
  id: PropTypes.string,
  tooltip: PropTypes.string,
};

SearchSection.defaultProps = {
  icon: null,
  className: null,
  id: null,
  tooltip: '',
};

export default withStyles(styles)(SearchSection);
