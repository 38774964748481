export default theme => ({
  error: {
    margin: '0.5rem',
    fontSize: '0.75rem',
    textAlign: 'center',
    minHeight: '1em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: theme.palette.red.main,
    width: '100%',
    display: 'block',
  },
});
