import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './styles';
import {
  creatorDiscoveryTitle,
  industryTrendsTitle,
  monitorTitle,
  brandSafetyTitle,
  competitorReportTitle,
  researchServiceTitle,
} from '../constants';

const EnterprisePlan = ({
  classes,
}) => (
  <div className={classes.root}>
    <Typography className={classes.name} variant="h5">Enterprise</Typography>

    <div className={classes.mostPopularPlaceholder} />
    <Tooltip title={creatorDiscoveryTitle()}>
      <div className={classes.subTitle}>Creator Discovery</div>
    </Tooltip>
    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Search Millions of Unique Creators
      </div>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Unlimited Searches
      </div>
    </div>
    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Unlimited Saved Lists
      </div>
    </div>
    <div className={classes.planSetting}>
      <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
      Full Audience Demographics
    </div>

    <Tooltip title={industryTrendsTitle()}>
      <div className={classes.subTitle}>Industry Trends Access</div>
    </Tooltip>

    <Tooltip title={monitorTitle()}>
      <div className={classes.subTitle}>5 Creator Listening Monitors</div>
    </Tooltip>

    <Tooltip title={brandSafetyTitle()}>
      <div className={classes.subTitle}>Unlimited Brand Safety Checks</div>
    </Tooltip>

    <Tooltip title={competitorReportTitle()}>
      <div className={cx(classes.subTitle, 'first')}>Unlimited Competitive Reports</div>
    </Tooltip>

    <Tooltip title={researchServiceTitle()}>
      <div className={classes.subTitle}>Research as a Service</div>
    </Tooltip>
    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Exclusive access to our data science and research team
      </div>
    </div>
    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Run customizable, in-depth reporting and analysis specific to your brand
      </div>
    </div>
    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Ad-hoc data requests
      </div>
    </div>
    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Insights & analysis services
      </div>
    </div>

    <div className={classes.pushUp} />

    <div className={classes.subscriptions}>
      <a href="mailto:support@goprism.it?subject=Quote Request on Prism plan">
        <Button variant="contained" className={classes.button}>
          Request Quote
        </Button>
      </a>
    </div>
  </div>
);

EnterprisePlan.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(EnterprisePlan);
