import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { reset, SubmissionError } from 'redux-form';
import { Card, Link } from '@material-ui/core';
import { register, login } from 'redux/authentication/actions';
import Logo from 'components/base/Logo/LoginLogo';
import LoggedOutLayout from 'components/base/LoggedOutLayout';
import BetaSignupForm from 'components/common/forms/BetaSignupForm';
import { toast } from 'react-toastify';
import { forms } from 'constants/config';
import redirectToCheckout from 'utils/stripeUtils';
import { login as loginRoute } from '../../../constants/routing';
import styles from './styles';

const SuccessMessage = () => (
  <React.Fragment>
    Account created!
  </React.Fragment>
);

class Register extends PureComponent {
  onSubmit = async (formData) => {
    const { location } = this.props;
    try {
      await this.props.register(formData);
      this.props.resetForm();
      toast.success(<SuccessMessage />);
      await this.props.login(formData.email, formData.password);
      await redirectToCheckout(formData.email, location.state.subscriptionId, location.state.redirectUrl);
    } catch (error) {
      if (error.errors) {
        throw new SubmissionError(error.errors);
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <LoggedOutLayout>
        <div className={classes.card}>
          <Logo />
          <Card>
            <BetaSignupForm onSubmit={data => this.onSubmit(data)} />
          </Card>
          <div className={classes.forgot__password__wrapper}>
            <Link href={loginRoute()} >
              Login
            </Link>
          </div>
        </div>
      </LoggedOutLayout>
    );
  }
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
  }).isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    authentication: state.authentication,
  }),
  {
    register,
    login,
    resetForm: () => dispatch => dispatch(reset(forms.BETA_SIGNUP_FORM)),
  },
)(withStyles(styles)(Register));

