import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import H6 from 'components/base/titles/CardHeading/H6';
import HorizontalBarHtml from 'components/base/charts/Bar/BarHtml/HorizontalBarHtml';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import { PLATFORM_COLOR } from 'constants/options';
import { formatNumber } from 'utils/helpers';

const styles = () => ({
  root: {},
});

const NULL_VALUE_DISPLAY = 'NA';

const PlatformDistribution = ({ classes, title, data: { values, labels } }) => (
  <div className={classes.root}>
    <H6 align="center">{title}</H6>
    <HorizontalBarHtml
      data={{
        values,
        labels: labels.map(l => <PlatformItem platform={l.toLowerCase()} />),
        colors: labels.map(l => PLATFORM_COLOR[l.toLowerCase()]),
        barLabels: values.map(v => formatNumber(v, '0a', NULL_VALUE_DISPLAY)),
      }}
    />
  </div>
);

PlatformDistribution.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};
export default withStyles(styles)(PlatformDistribution);
