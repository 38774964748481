export const creatorDiscoveryTitle = () => 'Search Millions of creators across seven social platforms, ' +
                                           'including TikTok, Instagram, and Youtube.';

export const industryTrendsTitle = () => 'Always be up-to-date with creators ' +
                                         'and content trends across 20 different industries.';

export const monitorTitle = () => 'Monitor keywords, hashtags, or brands and see how they have trended ' +
                                   'historically so you are always aware of new posts or activities.';

export const brandSafetyTitle = () => 'Brands take time to build but moments to crumble. ' +
                                      'Before starting your partnership, ' +
                                      'run a social media background check and ' +
                                      'ensure the creator aligns with your brand.';

export const competitorReportTitle = () => 'Deeply analyze your closest competitors or even brands you admire. ' +
                                           'Understand their social media strategy from share of voice and ' +
                                           'platform presence to best-performing content and creators.';

export const researchServiceTitle = () => 'If you have big questions about strategy or your competition ' +
                                          'that needs data, our data team can help!';

export const exportTitle = () => '.csv and .pdf exports';
