import React from 'react';
import PropTypes from 'prop-types';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { getCompetitiveReportCreators as getCompetitiveReportCreatorsAction } from 'redux/reports/actions';
import { withRouter } from 'react-router';
import {
  CREATOR_SORT_OPTIONS_LABELS,
} from 'components/common/CompetitiveReport/constants';
import { getDaysFromDate, getDateFromDays } from 'utils/helpers';
import SortOptions from 'components/common/CompetitiveReport/SortOptions';
import PlatformsFilter from 'components/common/CompetitiveReport/PlatformFilter';
import DateFilter from 'components/common/DateFilter';
import { xor } from 'lodash';

const Sort = ({
  params,
  getCompetitiveReportCreators,
  match: { params: { reportId } },
}) => {
  const handleSortSelect = (sort) => {
    getCompetitiveReportCreators(
      reportId,
      { ...params, sort },
    );
  };

  return (<SortOptions
    handleSelect={handleSortSelect}
    selectedValue={params.sort}
    options={CREATOR_SORT_OPTIONS_LABELS}
  />);
};

const PlatformSelect = ({
  params,
  getCompetitiveReportCreators,
  match: { params: { reportId } },
}) => {
  const handlePlatformSelect = (platformSelected) => {
    const { platforms } = params;
    getCompetitiveReportCreators(
      reportId,
      {
        ...params,
        platforms: xor(platforms, [platformSelected]),
      },
    );
  };

  return (<PlatformsFilter handleSelect={handlePlatformSelect} params={params} />);
};

const DateSelect = ({
  params,
  getCompetitiveReportCreators,
  match: { params: { reportId } },
}) => {
  const handleDateSelect = (fromDate, toDate) => {
    getCompetitiveReportCreators(
      reportId,
      {
        ...params,
        postedWithin: getDaysFromDate(fromDate),
        postedBefore: getDaysFromDate(toDate),
      },
    );
  };

  return (<DateFilter
    handleSelect={handleDateSelect}
    selectedFromDate={getDateFromDays(params.postedWithin)}
    selectedToDate={getDateFromDays(params.postedBefore)}
    customDate
  />);
};

const propTypes = {
  getCompetitiveReportCreators: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

Sort.propTypes = propTypes;
PlatformSelect.propTypes = propTypes;
DateSelect.propTypes = propTypes;

const mapStateToProps = ({ competitiveReportCreators }) => ({
  params: competitiveReportCreators.params,
});

export const HeaderSort = withAll(
  connect(mapStateToProps, { getCompetitiveReportCreators: getCompetitiveReportCreatorsAction }),
  withRouter,
)(Sort);

export const HeaderPlatformFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportCreators: getCompetitiveReportCreatorsAction }),
  withRouter,
)(PlatformSelect);

export const HeaderDateFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportCreators: getCompetitiveReportCreatorsAction }),
  withRouter,
)(DateSelect);
