import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import withQueryParams from 'components/base/withQueryParams';
import { isEmpty } from 'lodash';
import { searchUrl } from '../../constants/routing';

const UnauthenticatedRoute = ({
  redirect,
  component: C,
  props: cProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      !cProps.isAuthenticated ? <C {...props} {...cProps} />
        : <Redirect to={isEmpty(redirect) ? searchUrl() : redirect} />
    )}
  />
);

const mapQueryParams = ({ redirect }) => ({
  redirect,
});

export default withQueryParams(mapQueryParams)(UnauthenticatedRoute);
