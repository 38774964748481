import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
// eslint-disable-next-line
import MatrixGraph from './MatrixGraph';

const styles = () => ({
  root: {
    marginBottom: '0.8rem',
  },
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    paddingRight: '0.2rem',
  },
});

const Matrix = ({
  options,
  data,
  classes,
  theme,
  ...other
}) => (
  <div className={classes.root}>
    {data.map((d, index) => (
      <div className={classes.chartContainer}>
        <div className={classes.label}>{d.label}</div>
        <MatrixGraph index={index} dataset={data} options={options} {...other} />
      </div>
    ))}
  </div>
);

Matrix.propTypes = {
  options: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Matrix.defaultProps = {
  options: {},
};

export default withStyles(styles, { withTheme: true })(Matrix);
