
export const REPORT_STATUS = {
  created: 'created',
  queued: 'queued',
  processing: 'processing',
  failed: 'failed',
  completed: 'completed',
};


export const REPORT_STATUS_LABELS = {
  [REPORT_STATUS.created]: 'Creating',
  [REPORT_STATUS.queued]: 'Queued, refresh page for updates',
  [REPORT_STATUS.processing]: 'Processing',
  [REPORT_STATUS.failed]: 'Failed',
  [REPORT_STATUS.completed]: 'Completed',
};
