export default theme => ({
  root: {
    color: theme.palette.blue.light,
  },
  checked: {
    color: theme.palette.blue.main,
  },
  colorSecondary: {
    color: theme.palette.blue.light,
  },
});
