import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ loading, children, loaded }) => (
  <React.Fragment>
    {loading &&
      <div className={`load${loading ? '' : ' loaded'}`}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>
    }
    {!loading && loaded && children}
  </React.Fragment>
);

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  loaded: PropTypes.bool,
};

Loader.defaultProps = {
  children: <div />,
  loaded: true,
};

export default Loader;
