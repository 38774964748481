import { slice, get, uniqBy } from 'lodash';
import { newReducer } from '../utils';

const allowedHomeVerticals = [
  'Automotive',
  'Apparel / Fashion / Accessories',
  'Beauty',
  'CPG',
  'Entertainment',
  'Gaming / Apps',
];
const transformVerticalStats = stats => stats.filter(s => allowedHomeVerticals.includes(s.vertical));
const transformBrandStats = (stats) => {
  let allBrands = [];
  stats.forEach((vertical) => {
    if (!allowedHomeVerticals.includes(vertical.vertical)) {
      return;
    }
    const dates = vertical.dates.filter((element, index) => (index % 7 === 0));
    // eslint-disable-next-line no-param-reassign
    vertical.brands.forEach(brand => brand.dates = dates); // eslint-disable-line no-return-assign
    allBrands = [
      ...allBrands,
      ...vertical.brands,
    ];
  });
  return allBrands;
};
const transformPost = ({
  postText,
  likeCount,
  commentCount,
  mentions,
  username,
  permalink,
  followerCount,
  platform,
  image,
}) => ({
  caption: postText,
  brandMention: mentions,
  likes: likeCount,
  comments: commentCount,
  engagements: likeCount + commentCount,
  username,
  permalink,
  followerCount,
  platform,
  image,
});
export const transformPosts = posts => uniqBy(posts, d => d.username).map(d => transformPost(d));
const initialState = {
  homeStats: {
    loading: false,
    loaded: false,
    data: {
      verticalStats: [],
      brandStats: [],
      featuredPosts: [],
    },
  },
  verticals: {
    loading: false,
    loaded: false,
    data: {
      verticals: [],
    },
  },
  vertical: {
    loading: false,
    loaded: false,
    data: {
      brands: [],
      verticalEmotions: [],
    },
  },
};

export const homeStatReducer = newReducer(initialState.homeStats);
export const verticalsReducer = newReducer(initialState.verticals);
export const verticalReducer = newReducer(initialState.vertical);

homeStatReducer.method.GET_HOME_STATS_PENDING = state => ({
  ...state,
  loading: true,
});

homeStatReducer.method.GET_HOME_STATS_FULFILLED = (state, action) => ({
  ...state,
  data: {
    verticalStats: transformVerticalStats(get(action.payload, 'verticals', [])),
    brandStats: transformBrandStats(get(action.payload, 'verticals', []))
      .sort((a, b) => b.totalPostCount - a.totalPostCount),
    featuredPosts: slice(
      transformPosts(get(action.payload, 'posts', []))
        .sort((a, b) => b.engagements - a.engagements),
      0,
      6,
    ),
  },
  loading: false,
  loaded: true,
});

homeStatReducer.method.GET_HOME_STATS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: true,
});


verticalsReducer.method.GET_VERTICALS_PENDING = state => ({
  ...state,
  loading: true,
});

verticalsReducer.method.GET_VERTICALS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

verticalsReducer.method.GET_VERTICALS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

verticalReducer.method.GET_VERTICAL_PENDING = state => ({
  ...state,
  loading: true,
});

verticalReducer.method.GET_VERTICAL_FULFILLED = (state, action) => ({
  ...state,
  data: {
    ...action.payload,
  },
  loading: false,
  loaded: true,
});

verticalReducer.method.GET_VERTICAL_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});
