import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import FormModal from '../../../base/modals/FormModal';
import ListForm from '../../forms/ListForm';
import { fetchCategories } from '../../../../redux/lists/actions';

class ListFormModal extends React.Component {
  componentDidMount() {
    this.props.fetchCategories();
  }

  getDefaultImage = () => {
    const { categories, list } = this.props;
    const category = find(categories, c => c.name === list.category);
    return category ? category.image : null;
  };

  initialList = () => {
    const { list } = this.props;
    if (!list) {
      return null;
    }
    const { image, ...other } = list;
    return {
      ...other,
      image: {
        image,
        defaultImage: this.getDefaultImage(),
      },
    };
  };

  render() {
    const {
      open,
      onClose,
      onSubmit,
      title,
    } = this.props;
    return (
      <FormModal
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        title={title}
        height="50%"
        form="listForm"
        initial={this.initialList()}
      >
        <ListForm />
      </FormModal>
    );
  }
}

ListFormModal.propTypes = {
  list: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
  })).isRequired,
};

ListFormModal.defaultProps = {
  list: null,
};

const mapStateToProps = ({ lists: { categories: { data = [] } } }) => ({
  categories: data,
});

const mapDispatchToProps = {
  fetchCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListFormModal);
