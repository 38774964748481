import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Grid, Tooltip } from '@material-ui/core';
import { getBrandAutoComplete } from 'redux/autocompletes/actions';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import AutoComplete from 'components/base/AutoComplete';
import { debounce, isEmpty, get } from 'lodash';
import { toast } from 'react-toastify';
import { setTooltip } from 'redux/app/actions';
import Brand from './Brand';
import AddBrand from './AddBrand';
import styles from './styles';
import NoResults from './NoResults';

const MININUM_INPUT_CHARS = 3;
const TOOLTIP_NAME = 'reportAddBrandTooltip';
const TOOLTIP_NAME_FULL_PATH = `setTooltip.${TOOLTIP_NAME}`;

const renderInputComponent = (inputProps) => {
  const {
    classes,
    inputRef = () => {},
    meta: { touched, error },
    ref,
    ...other
  } = inputProps;
  const hasError = !!(touched && error);
  const errorElem = <span>{hasError ? error : ''}</span>;
  return (
    <TextField
      error={hasError}
      helperText={errorElem}
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        className: classes.input,
        fullWidth: true,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" className={classes.searchIcon}>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      type="text"
      {...other}
    />
  );
};

const BrandAlreadyExistMessage = () => (
  <React.Fragment>
    This brand has already been selected.
  </React.Fragment>
);

class BrandAutoComplete extends React.PureComponent {
  constructor() {
    super();
    this.brandInputRef = React.createRef();
  }

  state = {
    typedValue: '',
  };

  componentDidMount() {
    this.props.setTooltip(TOOLTIP_NAME, false);
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionSelected = (event, suggestion) => {
    const { input: { onChange, value } } = this.props;
    const selection = suggestion.suggestion.value;
    // Confirm selection is not already in values
    if (value.find(v => v.id === selection.id)) {
      toast.error(<BrandAlreadyExistMessage />);
      return;
    }
    onChange([
      ...value,
      selection,
    ]);
  };

  onTypeChange = (typedValue) => {
    this.setState({
      typedValue,
    });
  };

  loadSuggestions = debounce(async (value) => {
    if (value.length < MININUM_INPUT_CHARS) {
      return;
    }

    await this.props.getBrandAutoComplete(value);
  }, 300);

  removeBrand = (brandId) => {
    const { input: { onChange, value } } = this.props;
    onChange(value.filter(v => v.id !== brandId));
  }

  render() {
    const {
      classes,
      brandAutocomplete,
      showTooltip,
      input: { value },
      meta,
      isInternal,
      maxBrandsAllowedInternal,
    } = this.props;
    const { typedValue } = this.state;
    const suggestions = brandAutocomplete.results;
    const openNoResults = isEmpty(brandAutocomplete.results)
      && !brandAutocomplete.loading
      && brandAutocomplete.loaded
      && !isEmpty(typedValue);
    const maxAllowedBrands = (isInternal) ? maxBrandsAllowedInternal : 4;

    return (
      <div
        className={classes.root}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div className={classes.autoCompleteWrapper}>
          <Tooltip title="Start Typing to Add a Company" open={showTooltip} placement="top-start" arrow>
            <AutoComplete
              showAfter={MININUM_INPUT_CHARS}
              renderInputComponent={renderInputComponent}
              inputProps={{
                classes,
                placeholder: `Select up to ${maxAllowedBrands} brands.`,
                disabled: (!isEmpty(value) && value.length >= maxAllowedBrands),
                meta,
                inputRef: (el) => { this.brandInputRef.current = el; },
              }}
              onChange={this.onTypeChange}
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionSelected={(event, suggestion) => this.onSuggestionSelected(event, suggestion)}
              className={classes.autoComplete}
            />
          </Tooltip>
          {openNoResults &&
          <NoResults username={typedValue} />
          }
        </div>
        <Grid container className={classes.selectedResultContainer} spacing={8}>
          {!isEmpty(value) && value.map((b, i) => (
            <Grid item sm={3} key={`brandGridCard-${b.id}`}>
              <Brand brand={b} index={i} handleRemove={() => this.removeBrand(b.id)} />
            </Grid>
          ))}
          {[...Array(value ? maxAllowedBrands - value.length : maxAllowedBrands)].map((x, i) => (
            <Grid item xs={3} company-index={i}>
              <AddBrand index={i} inputRef={this.brandInputRef} />
            </Grid>))}
        </Grid>
      </div>
    );
  }
}

BrandAutoComplete.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.shape({})),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({}).isRequired,
  getBrandAutoComplete: PropTypes.func.isRequired,
  brandAutocomplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({}),
    })).isRequired,
  }).isRequired,
  showTooltip: PropTypes.bool.isRequired,
  setTooltip: PropTypes.func.isRequired,
  isInternal: PropTypes.bool.isRequired,
  maxBrandsAllowedInternal: PropTypes.number.isRequired,
};

BrandAutoComplete.defaultProps = {
  value: [],
};

const mapStateToProps = state => ({
  showTooltip: !!get(state, TOOLTIP_NAME_FULL_PATH),
  brandAutocomplete: state.brandAutocomplete,
  isInternal: state.userProfile.data.isInternal,
  maxBrandsAllowedInternal: state.configs.data.maxBrandsAllowedInternal,
});

const mapDispatchToProps = {
  getBrandAutoComplete,
  setTooltip,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(BrandAutoComplete));
