import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { mpPageView } from 'utils/copenhagen';
import { getKeyPhraseMonitors, resetKeyPhraseMonitor } from 'redux/monitors/actions';
import KeyPhraseMonitorList from 'components/common/KeyPhraseMonitorList';
import PageLayout from 'components/base/Layout/PageLayout';
import PageTitle from 'components/base/titles/PageTitle';
import Loader from 'components/base/Loader';
import Button from 'components/base/Button';
import withModal from 'components/base/withModal';
import withAll from 'components/base/withAll';
import ViewListIcon from '@material-ui/icons/ViewList';
import KeyPhraseMonitorModal from 'components/common/modals/KeyPhraseMonitorModal';
import styles from './styles';


class CreatorListeningMonitors extends React.Component {
  async componentDidMount() {
    await this.props.getKeyPhraseMonitors();
    this.props.resetKeyPhraseMonitor();
    mpPageView({ name: 'monitors' });
  }

  render() {
    const {
      isLoading,
      loaded,
      classes,
      closeModal,
      openModal,
      open,
    } = this.props;
    return (
      <div className={classes.root}>
        <Loader loading={isLoading} loaded={loaded}>
          <PageLayout>
            <div className={classes.actionsRow}>
              <PageTitle title="Monitors" />
              <div className={classes.actionButtons}>
                <Button
                  className={classes.createMonitorButton}
                  variant="contained"
                  color="primary"
                  onClick={openModal}
                >
                  <ViewListIcon className={classes.createMonitorIcon} />
                Create monitor
                </Button>
              </div>
            </div>
            <KeyPhraseMonitorList />
          </PageLayout>
        </Loader>
        {open && <KeyPhraseMonitorModal
          open={open}
          closeModal={closeModal}
        />}
      </div>
    );
  }
}

CreatorListeningMonitors.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getKeyPhraseMonitors: PropTypes.func.isRequired,
  resetKeyPhraseMonitor: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ keyPhraseMonitors }) => ({
  isLoading: keyPhraseMonitors.loading,
  loaded: keyPhraseMonitors.loaded,
});

export default withAll(
  connect(
    mapStateToProps,
    { getKeyPhraseMonitors, resetKeyPhraseMonitor },
  ),
  withStyles(styles),
  withModal,
)(CreatorListeningMonitors);
