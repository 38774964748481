import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required, length, validateForm, combine } from 'redux-form-validators';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { itemsCountValidator, emailsValidator } from 'utils/validators';
import Input from 'components/base/forms/Input';
import TextArea from 'components/base/forms/TextArea';
import withAll from 'components/base/withAll';
import { forms } from 'constants/config';
import styles from './styles';

const customValidate = {
  emails: combine(
    required({ msg: 'Required.' }),
    itemsCountValidator(0, 4),
    emailsValidator,
  ),
  message: length({ max: 300, msg: 'Message exceeds 300 characters' }),
};

const ShareListForm = ({ classes, handleSubmit }) => (
  <form className={classes.root} onSubmit={handleSubmit}>
    <Field
      className={classes.input}
      name="emails"
      component={Input}
      type="text"
      placeholder="Emails (ex. first@abc.com,second@abc.com)"
      fullWidth
    />
    <Typography className={classes.helperText}>
      {"Recipients will see the list's name and your message and be granted view-only access."}
    </Typography>

    <Field
      className={classes.input}
      name="message"
      component={TextArea}
      type="text"
      placeholder="Add a message (Optional)"
      fullWidth
    />

    <Button
      className={classes.submitButton}
      variant="contained"
      type="submit"
      id="btn_share_list_menu"
    >
      Share
    </Button>
  </form>
);

ShareListForm.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withAll(
  reduxForm({
    form: forms.SHARE_LIST_FORM,
    validate: validateForm(customValidate),
  }),
  withStyles(styles),
)(ShareListForm);
