import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const Error = ({
  error,
  classes,
}) => (
  <React.Fragment>
    {error && <span className={classes.error}>{error}</span>}
  </React.Fragment>
);

Error.propTypes = {
  classes: PropTypes.shape({}),
  error: PropTypes.string,
};

Error.defaultProps = {
  classes: {},
  error: null,
};

export default withStyles(styles)(Error);

