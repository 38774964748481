import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

export default withStyles(() => ({
  flexContainer: {
    flexDirection: 'column',
  },
  indicator: {
    display: 'none',
  },
}))(Tabs);
