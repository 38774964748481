import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { faPinterest } from '@fortawesome/free-brands-svg-icons';
import FollowerFilter from './FollowerFilter';
import withSearchParam from '../../withSearchParam';
import Icon from './Icon';
import styles from './styles';
import { PLATFORMS } from '../../../../../constants/options';

const AVAILABLE_FOLLOWER_MIN = 500;
const FOLLOWER_SEARCH_STEP = 500;

const PinterestFollowerFilter = ({ classes, input: { onChange, value } }) => (
  <FollowerFilter
    platform={PLATFORMS.PINTEREST}
    name="pinterest_follower_count"
    title="Followers"
    inputMin={AVAILABLE_FOLLOWER_MIN}
    inputStep={FOLLOWER_SEARCH_STEP}
    icon={<Icon icon={faPinterest} className={classes.piIcon} />}
    value={value}
    onChange={onChange}
  />
);

PinterestFollowerFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default withSearchParam('piFollowerRange')(withStyles(styles)(PinterestFollowerFilter));
