import React from 'react';
import PropType from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import List from '../List';


class ListGrid extends React.PureComponent {
  render() {
    const { lists, classes } = this.props;
    const paperLists = lists.map(list => (
      <Grid item xs={12} md={3} key={list.name} className="list_tile">
        <List list={list} />
      </Grid>
    ));
    return (
      <div className={classes.grid_container}>
        <Grid
          container
          id="saved_lists_view"
          cellHeight="auto"
          emptyMessage="No Lists"
        >
          {paperLists}
        </Grid>
      </div>
    );
  }
}

ListGrid.propTypes = {
  lists: PropType.arrayOf(PropType.shape({})).isRequired,
  classes: PropType.shape({}).isRequired,
};

export default withStyles(styles)(ListGrid);
