import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from 'components/base/MultiSelect';
import SearchSection from './SearchSection';
import withSearchParam from '../withSearchParam';

const MININUM_INPUT_CHARS = 3;

class CaptionFilter extends React.PureComponent {
  handleRemoveCaption = (caption) => {
    const { input: { onChange, value } } = this.props;
    onChange(value.filter(v => v.value !== caption));
  };

  render() {
    const { input: { onChange, value } } = this.props;
    return (
      <SearchSection
        id="caption_search_filter"
        title="Caption Search"
        className="caption_search"
        tooltip="Find creators and their content that captions certain text or phrases"
      >
        <MultiSelect
          name="captions"
          value={value}
          placeholder="Find Captions"
          onChange={onChange}
          handleRemoveSelection={this.handleRemoveCaption}
          minChars={MININUM_INPUT_CHARS}
          creatable
          isMulti
        />
      </SearchSection>
    );
  }
}

CaptionFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])).isRequired,
  }).isRequired,
};

export default withSearchParam('captions')(CaptionFilter);
