import React from 'react';
import { connect } from 'react-redux';
import { reduce } from 'lodash';

export const getSelectedInfluencerIds = selectedInfluencers => reduce(selectedInfluencers, (acc, v, k) => {
  acc.push({
    id: k,
    ...v,
  });
  return acc;
}, []);

const mapStateToProps = ({ searchDisplay: { selectedInfluencers } }) => ({
  selectedInfluencers: getSelectedInfluencerIds(selectedInfluencers),
});

export default (WrappedComponent) => {
  const WithSelectedInfluencers = (props) => {
    const { selectedInfluencers } = props;

    return (
      <WrappedComponent
        selectedInfluencers={selectedInfluencers}
        {...props}
      />
    );
  };
  return connect(mapStateToProps, null)(WithSelectedInfluencers);
};
