import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faSnapchatGhost,
  faTwitter,
  faYoutube,
  faInstagram,
  faPinterest,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { isEmpty } from 'lodash';
import ToggleButton from 'components/base/ToggleButton';
import ToggleButtonGroup from 'components/base/ToggleButtonGroup';
import SearchSection from './SearchSection';
import withSearchParam from '../withSearchParam';
import { PLATFORMS } from '../../../../constants/options';

class PlatformFilter extends React.PureComponent {
  handlePlatformChange = (event, platforms) => {
    const { input: { onChange } } = this.props;

    // Enforce at least one platform always selected.
    if (isEmpty(platforms)) {
      return;
    }
    onChange(platforms);
  };

  render() {
    const { input: { value } } = this.props;
    return (
      <SearchSection
        id="platform_search_filter"
        title="Other Platforms"
        tooltip="Find creators that also have these social platforms."
      >
        <div>
          <ToggleButtonGroup value={value} onChange={this.handlePlatformChange}>
            <ToggleButton value={PLATFORMS.INSTAGRAM} className="btn__platform-instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </ToggleButton>
            <ToggleButton value={PLATFORMS.PINTEREST} className="btn__platform-pinterest">
              <FontAwesomeIcon icon={faPinterest} />
            </ToggleButton>
            <ToggleButton value={PLATFORMS.TIKTOK} className="btn__platform-tiktok">
              <FontAwesomeIcon icon={faTiktok} />
            </ToggleButton>
            <ToggleButton value={PLATFORMS.YOUTUBE} className="btn__platform-youtube">
              <FontAwesomeIcon icon={faYoutube} />
            </ToggleButton>
            <ToggleButton value={PLATFORMS.FACEBOOK} className="btn__platform-facebook">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </ToggleButton>
            <ToggleButton value={PLATFORMS.TWITTER} className="btn__platform-twitter">
              <FontAwesomeIcon icon={faTwitter} />
            </ToggleButton>
            <ToggleButton value={PLATFORMS.SNAPCHAT} className="btn__platform-snapchat">
              <FontAwesomeIcon icon={faSnapchatGhost} />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </SearchSection>
    );
  }
}

PlatformFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default withSearchParam('platforms')(PlatformFilter);
