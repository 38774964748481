import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { Container, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PageTitle from 'components/base/titles/PageTitle';
import Tab from 'components/base/Tab';
import { a11yProps } from 'components/base/tabs/utils';
import { TABS } from 'components/app/BrandSafetyReport/constants';
import PlatformIcon from 'components/common/PlatformIcon';
import styles from './styles';

const Header = ({
  classes,
  report,
  tab,
  updateTab,
  canViewUnusualPostIndex,
}) => (
  <div className={classes.root}>
    <Container maxWidth={false}>
      <PageTitle
        title={`Brand Sponsorship Scorecard${report.creator ? ` - ${report.creator}` : ''}`}
      />
      <div className={classes.labelRow}>
        <Typography variant="h5" className={classes.label}>
          Generated:
        </Typography>
        {moment(report.modifiedDate).format('MM/DD/YY')}
      </div>
      <div className={classes.labelRow}>
        <Typography variant="h5" className={classes.label}>
          Platforms:
        </Typography>
        {report.platforms.map(p => <PlatformIcon key={p} platform={p} />)}
      </div>
      <div className={classes.labelRow}>
        <Typography variant="h5" className={classes.label}>
          Brand Safety:
        </Typography>
        {report.objectionableListTitles.join(' | ')}
      </div>
    </Container>
    <Container maxWidth={false} className={classes.tabContainer}>
      <Tabs value={tab} onChange={updateTab} indicatorColor="primary">
        <Tab
          className={classes.tab}
          label="Scorecard"
          {...a11yProps(TABS.SCORECARD)}
        />
        <Tab
          className={classes.tab}
          label="Brand Safety"
          {...a11yProps(TABS.BRAND_SAFETY)}
        />
        {
          canViewUnusualPostIndex && (
            <Tab
              className={classes.tab}
              label="Unusual Post Engagement"
              {...a11yProps(TABS.UNUSUAL_POST_ENGAGEMENT)}
            />
          )
        }
      </Tabs>
    </Container>
  </div>
);

Header.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    influencerId: PropTypes.string,
    creator: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.string),
    analyzedPosts: PropTypes.number,
    brandSafetyIndex: PropTypes.string,
    objectionableListTitles: PropTypes.arrayOf(PropTypes.string),
    modifiedDate: PropTypes.string,
  }).isRequired,
  tab: PropTypes.number.isRequired,
  updateTab: PropTypes.func.isRequired,
  canViewUnusualPostIndex: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Header);
