import React from 'react';
import { Avatar, Tooltip, Typography, Button } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { openInNewTab } from 'utils/helpers';
import { connect } from 'react-redux';
import withAll from 'components/base/withAll';
import withPermission from 'components/common/withPermission';
import { isEmpty, identity } from 'lodash';
import store from 'redux/store';
import { formatDate } from 'lib/utils';
import styles from './styles';
import EditListLink from '../EditListLink';

class ListDetailsInfo extends React.PureComponent {
  getExportListUrl = async () => {
    await this.props.exportListFunc();
    const { lists: { listData } } = store.getState();
    if (listData && listData.url) {
      openInNewTab(listData.url);
    }
  }


  render() {
    const {
      classes, list, isAuthenticated, permitted,
    } = this.props;
    if (list) {
      return (
        <div className={classes.info}>
          <Avatar
            src={list.image || list.defaultImage}
            className={classes.avatar}
          />
          <div className={classes.info__text}>
            <Tooltip title={list.name} aria-label="add" placement="bottom-start">
              <Typography className={classes.list__name}>{list.name}</Typography>
            </Tooltip>
            <Typography className={classes.list__meta}>{`Created on ${formatDate(list.createdTime)}`}</Typography>
            {!isEmpty(list) && permitted &&
              <Button onClick={this.getExportListUrl}>
                <DownloadIcon />
              </Button>
            }
            {list.createdBy &&
              <Typography className={classes.list__meta}>{`Created by ${list.createdBy}`}</Typography>}
            {!isEmpty(list) && isAuthenticated && list.isOwner && <EditListLink list={list} />}
          </div>
        </div>
      );
    }
    return null;
  }
}

ListDetailsInfo.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  list: PropTypes.shape({}).isRequired,
  listData: PropTypes.shape({
    url: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  exportListFunc: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  permitted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  listData: state.lists.listData,
  isAuthenticated: state.auth.isAuthenticated,
});

export default withAll(
  withPermission('can_export_list', null, identity, true),
  connect(mapStateToProps),
  withStyles(styles),
)(ListDetailsInfo);

