import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import BaseModal from 'components/base/modals/BaseModal';
// eslint-disable-next-line
import { openInNewTab } from 'utils/helpers';
import { getCompetitiveReportExport as getCompetitiveReportExportAction } from 'redux/reports/actions';
import SalesPDFExport from 'components/common/CompetitiveReport/SalesPDFExport';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import withAll from 'components/base/withAll';
import { get } from 'lodash';
import { connect } from 'react-redux';
import store from 'redux/store';


const styles = theme => ({
  root: {
    display: 'inline-block',
    color: theme.palette.border.dark,
  },
});

const buttonStyles = theme => ({
  root: {
    position: 'relative',
    marginLeft: '70rem',
    backgroundColor: theme.palette.green.main,
  },
});

const DownloadButton = withStyles(buttonStyles)(({ handleDownloadPdf, classes }) => (
  <Button
    className={classes.root}
    variant="contained"
    size="large"
    onClick={handleDownloadPdf}
  >
    DOWNLOAD
  </Button>
));

const Download = ({
  classes,
  containerRef,
  reportId,
  dataToExport,
  isInternal,
  getCompetitiveReportExport,
  canExportReport,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, doOpen] = React.useState(false);

  const openPreview = () => doOpen(true);
  const closePreview = () => doOpen(false);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const reportName = () => (
    dataToExport.title
  );

  const exportExcel = async () => {
    handleClose();
    await getCompetitiveReportExport(reportId);
    openInNewTab(store.getState().competitiveReportExport.excelData);
  };

  const handleDownloadPdf = async () => {
    // adding scale to fix gray/blurry image export per:
    // https://github.com/niklasvh/html2canvas/issues/2183
    const canvas = await html2canvas(containerRef.current, { scale: 2 });
    const data = canvas.toDataURL('image/jpeg');
    const pdf = new JsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    let heightLeft = pdfHeight;
    let position = 0;
    pdf.addImage(data, 'JPEG', 0, position, pdfWidth, pdfHeight);
    heightLeft -= pdf.internal.pageSize.getHeight();

    const iterTimes = Math.floor(pdfHeight / pdf.internal.pageSize.getHeight());
    for (let i = 0; i < iterTimes; i += 1) {
      if (heightLeft >= 0) {
        position = heightLeft - pdfHeight;
        pdf.addPage();
        pdf.addImage(data, 'JPEG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();
      }
    }
    pdf.save(`${reportName()}.pdf`);
  };

  return (
    <div className={classes.root}>
      {
        canExportReport &&
        <Button
          className={classes.root}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <DownloadIcon />
        </Button>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={exportExcel}>CSV</MenuItem>
        {isInternal && <MenuItem onClick={openPreview}>PDF</MenuItem>}
      </Menu>
      <BaseModal
        open={open}
        onClose={closePreview}
        width="80rem"
        height="40rem"
        title="PDF Export Preview"
        isOverflow
      >
        <DownloadButton handleDownloadPdf={handleDownloadPdf} />
        <SalesPDFExport
          title={dataToExport.title}
          ref={containerRef}
        />
        <DownloadButton handleDownloadPdf={handleDownloadPdf} />
      </BaseModal>
    </div>
  );
};

Download.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  containerRef: PropTypes.func.isRequired,
  reportId: PropTypes.number.isRequired,
  dataToExport: PropTypes.shape({}).isRequired,
  isInternal: PropTypes.bool.isRequired,
  getCompetitiveReportExport: PropTypes.func.isRequired,
  canExportReport: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ competitiveReportExport, competitiveReportStat }) => ({
  competitiveReportExport,
  canExportReport: get(competitiveReportStat, 'data.canExportReport', false),
});
export default withAll(
  connect(mapStateToProps, { getCompetitiveReportExport: getCompetitiveReportExportAction }),
  withStyles(styles),
)(Download);

