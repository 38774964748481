import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';

export const GET_VERTICALS_CATEGORIES = 'GET_VERTICALS_CATEGORIES';


export const getVerticalCategories = () => (dispatch) => {
  dispatch({
    type: GET_VERTICALS_CATEGORIES,
    payload: request('get', API.VERTICALS_CATEGORIES, {}),
  }).catch((response) => {
    handleErrors(response);
  });
};
