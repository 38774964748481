export default theme => ({
  list: {
    margin: 10,
  },
  image: {
    height: 200,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  list__text: {
    width: 'calc(100% - 48px)',
  },
  text__info: {
    display: 'flex',
    flexDirection: 'column',
  },
  list__name: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.text.bold,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  list__date: {
    fontSize: 15,
    color: theme.palette.text.meta,
  },
  more__button: {
    marginLeft: 'auto',
  },
});
