import React, { Component } from 'react';
import { Menu, MenuItem, Button, Link } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from 'redux/authentication/actions';
import UpgradeButton from 'components/common/UpgradeButton';
import { buildAccountSettingsUrl, buildBrandRequestUrl } from 'constants/routing';
import withAll from 'components/base/withAll';
import styles from './styles';

class UserAvatar extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleSignout = () => {
    try {
      this.props.logout();
    } catch (e) {
      // eslint-disable-next-line
      return;
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, userProfile: { data: userProfile } } = this.props;
    const { anchorEl } = this.state;
    return (
      <div className={classes.root}>
        <Button
          aria-owns={anchorEl ? 'user-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.fullName}
        >
          {userProfile.firstName} {userProfile.lastName} <KeyboardArrowDownIcon />
        </Button>
        {userProfile.isTrial ? <UpgradeButton /> : null}
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={classes.menu}
          disableAutoFocusItem
        >
          <MenuItem>
            <Link href={buildAccountSettingsUrl()}>Account Settings</Link>
          </MenuItem>
          <MenuItem>
            <Link href={buildBrandRequestUrl()}>Request a Brand</Link>
          </MenuItem>
          <MenuItem onClick={this.handleSignout}>Sign Out</MenuItem>
        </Menu>
      </div>
    );
  }
}

UserAvatar.propTypes = {
  logout: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  userProfile: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = {
  logout,
};


export default withAll(
  withStyles(styles),
  connect(
    state => ({
      userProfile: state.userProfile,
    }),
    mapDispatchToProps,
  ),
)(UserAvatar);
