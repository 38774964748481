import React from 'react';
import PropTypes from 'prop-types';
import SearchSection from './SearchSection';
import AudienceTopCountriesAutoComplete from './AudienceTopCountriesAutoComplete';
import withSearchParam from '../withSearchParam';

class AudienceTopCountriesFilter extends React.PureComponent {
  handleRemoveCountry = (keyword) => {
    const { input: { onChange, value } } = this.props;
    onChange(value.filter(v => v.value !== keyword));
  };

  render() {
    const { input: { onChange, value } } = this.props;
    return (
      <SearchSection
        id="audience_top_countries_filter"
        title="Audience Top Countries"
        tooltip="Find Creators with engaged users in specific countries."
      >
        <AudienceTopCountriesAutoComplete
          countries={value}
          onChange={onChange}
          handleRemoveCountry={this.handleRemoveCountry}
        />
      </SearchSection>
    );
  }
}

AudienceTopCountriesFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])).isRequired,
  }).isRequired,
};

export default withSearchParam('countries')(AudienceTopCountriesFilter);
