export default theme => ({
  btnContainer: {
    marginTop: '1.8rem',
  },
  btn: {
    backgroundColor: theme.palette.green.main,
  },
  subscribeAlert: {
    marginBottom: '1rem',
  },
});
