import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Button from 'components/base/Button';
import SideBar from 'components/base/SideBar';
import { deselectAllInfluencers, togglePanel } from 'redux/searchDisplay/actions';
import { resetSearchParams } from 'redux/searchParams/actions';
import { INFLUENCER_SEARCH_TABS } from 'constants/options';
import { scrollToTop } from 'utils/helpers';
import withInfluencerSearch from 'components/common/withInfluencerSearch';
import 'react-input-range/lib/css/index.css';
import withAll from 'components/base/withAll';
import SearchFilters from './SearchFilters';
import SearchButton from './SearchButton';
import styles from './styles';

class SearchBar extends PureComponent {
  componentDidMount() {
    const { influencers, searchParams } = this.props;
    if (!influencers.influencers.length && !influencers.loading && !searchParams.isRecommendation) {
      this.handleSearch();
    }
  }

  toggleSearch = openStatus => this.props.togglePanel(openStatus);

  handleSearch = () => {
    scrollToTop();
    const { searchParams } = this.props;
    searchParams.page = 0;
    this.props.getInfluencers(searchParams);
    this.props.deselectAllInfluencers();
  };

  resetSearch = () => {
    this.props.resetSearchParams();
  };

  render() {
    const {
      classes,
      searchDisplay,
      theme,
    } = this.props;
    const searchBarStyle = {
      left: searchDisplay.isVerticalPanelOpen ? 0 : theme.sizes.searchMenuHideLeft.desktop,
    };
    const toggleStyle = {
      transform: !searchDisplay.isVerticalPanelOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    };

    return (
      <React.Fragment>
        <Button
          className={classes.toggleButton}
          onClick={() => this.toggleSearch(!searchDisplay.isVerticalPanelOpen)}
          component="button"
        >
          <KeyboardArrowLeftIcon className={classes.toggleIcon} style={toggleStyle} />
        </Button>
        <SideBar
          style={searchBarStyle}
          footer={(
            <React.Fragment>
              <div className="button-wrapper">
                <Button
                  key="reset-button"
                  variant="contained"
                  color="default"
                  onClick={this.resetSearch}
                >
                  Reset all
                </Button>
              </div>
              <div className="button-wrapper">
                <SearchButton key="search-button" handleSearch={this.handleSearch} />
              </div>
            </React.Fragment>
          )}
        >
          {searchDisplay.selectedTab === INFLUENCER_SEARCH_TABS.INFLUENCER &&
          <SearchFilters />}
        </SideBar>
      </React.Fragment>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  togglePanel: PropTypes.func.isRequired,
  searchDisplay: PropTypes.shape({
    isVerticalPanelOpen: PropTypes.bool.isRequired,
  }).isRequired,
  getInfluencers: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  deselectAllInfluencers: PropTypes.func.isRequired,
  resetSearchParams: PropTypes.func.isRequired,
  influencers: PropTypes.shape({
    total: PropTypes.number,
    influencers: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }).isRequired,
};

export default withAll(
  connect(
    state => ({
      searchDisplay: state.searchDisplay,
      searchParams: state.searchParams,
      influencers: state.influencers,
    }),
    {
      deselectAllInfluencers,
      togglePanel,
      resetSearchParams,
    },
  ),
  withStyles(styles, { withTheme: true }),
  withInfluencerSearch,
)(SearchBar);
