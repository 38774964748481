import {
  isEmpty,
  sum,
  mean,
  slice,
  keys,
  findIndex,
  findLastIndex,
  cloneDeep,
  clone,
  get,
  uniqBy,
} from 'lodash';
import { transformPosts } from 'redux/stats/reducers';
import * as moment from 'moment';
import { ma } from 'moving-averages';
import { getTrend, partitionDataByWeek } from 'lib/utils';
import { newReducer } from '../utils';


const initialState = {
  brand: {
    loading: false,
    loaded: false,
    data: {},
    params: {},
    initialData: {},
  },
  statBrands: {
    loading: false,
    loaded: false,
    data: [],
  },
  brands: {
    loading: false,
    loaded: false,
    data: [],
  },
};

const MA_DAYS = 28;

const mergePlatforms = (platformsToMerge = [], data) => {
  const valueArraysFieldsToMerge = ['dailyEngagements', 'dailyPostCounts', 'dailyViewCounts', 'dailyCreatorCounts'];
  const sumValuesNumberFieldsToMerge = [
    'creatorCount',
    'interactions',
    'totalPostCount',
    'totalViewCounts',
  ];
  const avgValuesNumberFieldsToMerge = ['trend3WRa', 'trend6WRa'];
  const mergedData = {};
  valueArraysFieldsToMerge.forEach(f => Object.assign(mergedData, {
    [f]: platformsToMerge.map(p => data.platforms[p][f]).reduce((a, b) => a.map((v, i) => v + b[i])),
  }));

  sumValuesNumberFieldsToMerge.forEach(f => Object.assign(mergedData, {
    [f]: sum(platformsToMerge.map(p => data.platforms[p][f])),
  }));

  avgValuesNumberFieldsToMerge.forEach(f => Object.assign(mergedData, {
    [f]: mean(platformsToMerge.map(p => data.platforms[p][f])),
  }));

  return mergedData;
};

const filterByPlatform = (data = {}, params = {}) => {
  const { platform } = params;
  if (!isEmpty(platform)) {
    return data.platforms[platform];
  }
  const platforms = keys(data.platforms);
  if (isEmpty(platforms)) {
    return data;
  }
  return mergePlatforms(platforms, data);
};

const findIndexesByDate = (dateArray, fromDate, toDate) => {
  // find fromDate index
  const fromIndex = findIndex(dateArray, d => moment(d) >= fromDate);
  let toIndex = findIndex(dateArray, d => moment(d) >= toDate);
  if (toIndex === -1) {
    toIndex = findLastIndex(dateArray);
  }
  return {
    fromIndex,
    toIndex,
  };
};

const filterByDate = (data = {}, params = {}) => {
  const valueArraysFieldsToMerge = [
    'dailyEngagements',
    'dailyPostCounts',
    'dates',
    'dailyViewCounts',
    'dailyCreatorCounts',
  ];
  const { fromDate, toDate } = params;
  if (isEmpty(fromDate) || isEmpty(toDate)) {
    return data;
  }
  const { fromIndex, toIndex } = findIndexesByDate(data.dates, fromDate, toDate);

  valueArraysFieldsToMerge.forEach(f => (
    Object.assign(data, { [f]: slice(data[f], fromIndex, toIndex) })
  ));
  return data;
};

const filterBrand = (data = {}, params = {}) => {
  const filters = [filterByPlatform, filterByDate];
  const transformedData = data;
  filters.forEach(fn => Object.assign(transformedData, fn(transformedData, params)));
  return transformedData;
};

const transformBrandPlatform = (platforms) => {
  let platformsFiltered = {};
  const platformsTypes = keys(platforms);
  if (isEmpty(platformsTypes)) {
    return {};
  }
  platformsTypes.forEach((p) => {
    if (get(platforms, [p, 'totalPostCount'], 0) > 0) {
      platformsFiltered = Object.assign(platformsFiltered, { [p]: platforms[p] });
    }
  });
  return platformsFiltered;
};

const transformRelatedBrand = (brand) => {
  const weeklyPosts = partitionDataByWeek(slice(ma(brand.dailyPostCounts, MA_DAYS), MA_DAYS));
  return {
    ...brand,
    trend3WRa: getTrend(weeklyPosts, 1, 3),
    trend6WRa: getTrend(weeklyPosts, 1, 6),
  };
};

const transformBrand = (brand) => {
  const platforms = transformBrandPlatform(brand.platforms);
  const dailyPostCountsMa = slice(ma(brand.dailyPostCounts, MA_DAYS), MA_DAYS);
  const dailyEngagementsMa = slice(ma(get(brand, 'dailyEngagements', []), MA_DAYS), MA_DAYS);
  const dailyPostCountsMaWeekly = partitionDataByWeek(clone(dailyPostCountsMa));
  const dailyEngagementsMaWeekly = partitionDataByWeek(clone(dailyEngagementsMa));
  return {
    ...brand,
    platforms,
    dailyPostCountsMa,
    dailyEngagementsMa,
    trend3WRaEngagements: getTrend(dailyEngagementsMaWeekly, 1, 3),
    trend6WRaEngagements: getTrend(dailyEngagementsMaWeekly, 1, 6),
    trend3WRa: getTrend(dailyPostCountsMaWeekly, 1, 3),
    trend6WRa: getTrend(dailyPostCountsMaWeekly, 1, 6),
    relatedBrands: uniqBy(brand.relatedBrands.map(b => transformRelatedBrand(b)), b => b.name)
      .filter(b => b.id !== brand.id),
    posts: transformPosts(brand.posts),
    vertical: brand.verticalData,
  };
};

// eslint-disable-next-line
export const brandReducer = newReducer(initialState.brand);
export const statBrandsReducer = newReducer(initialState.statBrands);
export const brandsReducer = newReducer(initialState.brands);


brandReducer.method.FILTER_BRAND = (state, action) => ({
  ...state,
  params: action.meta.params,
  data: transformBrand(filterBrand(cloneDeep(state.initialData), action.meta.params)),
  loading: false,
  loaded: true,
});

brandReducer.method.GET_BRAND_PENDING = state => ({
  ...state,
  loading: true,
});

brandReducer.method.GET_BRAND_FULFILLED = (state, action) => ({
  ...state,
  data: transformBrand(filterBrand(action.payload, action.meta.params)),
  params: {},
  initialData: action.payload,
  loading: false,
  loaded: true,
});

brandReducer.method.GET_BRAND_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

statBrandsReducer.method.GET_STAT_BRANDS_PENDING = state => ({
  ...state,
  loading: true,
});

statBrandsReducer.method.GET_STAT_BRANDS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

statBrandsReducer.method.GET_BRANDS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});


brandsReducer.method.GET_BRANDS_PENDING = state => ({
  ...state,
  loading: true,
});

brandsReducer.method.GET_BRANDS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

brandsReducer.method.GET_BRANDS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});
