import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import ChipFilter from 'components/base/ChipFilter';
import withAll from 'components/base/withAll';
import { withRouter } from 'react-router';
import { Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: '0.3rem',
  },
});

const SponsoredFilter = ({
  classes,
  params: { isSponsored },
  handleSelect,
}) => (
  <div className={classes.root}>
    <Typography variant="h6" className={classes.label}>Content-Type:</Typography>
    <ChipFilter
      buttonSelected={isSponsored === false}
      onDelete={() => handleSelect({ organic: false })}
      onClick={() => handleSelect({ organic: isSponsored === false ? null : true })}
      label="Organic"
    />
    <ChipFilter
      buttonSelected={isSponsored === true}
      onDelete={() => handleSelect({ sponsored: false })}
      onClick={() => handleSelect({ sponsored: isSponsored === true ? null : true })}
      label="Sponsored"
    />
  </div>
);

SponsoredFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSelect: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
};

export default withAll(
  withStyles(styles),
  withRouter,
)(SponsoredFilter);
