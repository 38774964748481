import React from 'react';
import PropTypes from 'prop-types';
import ChartComponent, { Chart } from 'react-chartjs-2';
import { isEmpty, max, slice } from 'lodash';
import { withStyles } from '@material-ui/core';
import { formatNumber } from 'utils/helpers';

const styles = () => ({
  root: {
    position: 'relative',
  },
  backgroundChart: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '100%',
  },
  chart: {
    position: 'relative',
  },
});
const PERCENTAGE_OF_PI_TO_CUT = 0.5;

const getBackgroundOptions = () => ({
  responsive: true,
  title: {
    display: false,
  },
  rotation: Math.PI - ((Math.PI * PERCENTAGE_OF_PI_TO_CUT) * 0.5),
  circumference: Math.PI + (Math.PI * PERCENTAGE_OF_PI_TO_CUT),
  cutoutPercentage: 60,
  legend: {
    display: false,
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  tooltips: {
    display: false,
    enabled: false,
  },
});

export const getOptions = (options, rawData) => ({
  responsive: true,
  title: {
    display: false,
  },
  rotation: Math.PI - ((Math.PI * PERCENTAGE_OF_PI_TO_CUT) * 0.5),
  circumference: Math.PI + (Math.PI * PERCENTAGE_OF_PI_TO_CUT),
  cutoutPercentage: 60,
  legend: {
    display: false,
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  tooltips: {
    displayColors: false,
    mode: 'dataset',
    enabled: true,
    intersect: false,
    custom: (model) => {
      if (model.body) {
        // eslint-disable-next-line
        model.body = slice(model.body.filter(b => !isEmpty(b.lines)), 0, 1);
      }
    },
    callbacks: {
      label: (tooltip) => {
        const { index: i, datasetIndex } = tooltip;
        const thisDataset = rawData.datasets[datasetIndex];
        if (!i) {
          return '';
        }
        return `${thisDataset.label}: ${formatNumber(thisDataset.value, '0a', '-')}`;
      },
      title: () => '',
    },
  },
  ...options,
});

Chart.controllers.radial = Chart.controllers.doughnut.extend({
  calculateTotal: () => 1,
});


const createDataValues = (index, values) => {
  const sumValue = max(values);
  return values.map((v, i) => (i !== index ? 0 : v / sumValue));
};

const transformData = (data) => {
  const colorList = data.datasets.map(d => d.backgroundColor);
  const valueList = data.datasets.map(d => d.value);

  return {
    datasets: data.datasets.map((d, i) => ({
      data: createDataValues(i, valueList),
      label: d.label,
      backgroundColor: colorList,
    })),
    labels: data.datasets.map(d => d.label),
  };
};

const transformBackgroundData = (data, theme) => {
  const colorList = data.datasets.map(() => theme.palette.border.main);
  const valueList = data.datasets.map(() => 1);

  return {
    datasets: data.datasets.map((d, i) => ({
      data: createDataValues(i, valueList),
      label: d.label,
      backgroundColor: colorList,
    })),
    labels: data.datasets.map(d => d.label),
  };
};

class RadialChart extends React.Component {
  render() {
    const {
      options,
      data,
      classes,
      theme,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.backgroundChart}>
          <ChartComponent
            {...this.props}
            data={transformBackgroundData(data, theme)}
            options={getBackgroundOptions(options, data)}
            // eslint-disable-next-line
            ref={ref => this.chartInstance = ref && ref.chartInstance}
            type="radial"
          />
        </div>
        <div className={classes.chart}>
          <ChartComponent
            {...this.props}
            data={transformData(data)}
            options={getOptions(options, data)}
            // eslint-disable-next-line
            ref={ref => this.chartInstance = ref && ref.chartInstance}
            type="radial"
          />
        </div>
      </div>
    );
  }
}

RadialChart.propTypes = {
  options: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      backgroundColor: PropTypes.string,
    })),
  }).isRequired,
};

RadialChart.defaultProps = {
  options: {},
};

export default withStyles(styles, { withTheme: true })(RadialChart);
