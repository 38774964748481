import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import DayOfTheWeekMatrix from 'components/base/charts/Matrix/DayOfTheWeekMatrix';
import { PLATFORM_COLOR } from 'constants/options';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import H6 from 'components/base/titles/CardHeading/H6';

const styles = () => ({
  root: {},
});

const transformData = data => data.dataSets
  .map(platform => ({
    label: <PlatformItem platform={platform.label.toLowerCase()} />,
    data: platform.values.map((v, vi) => ({
      x: vi + 1,
      y: 1,
      d: {
        gridColor: PLATFORM_COLOR[platform.label.toLowerCase()],
        xLabel: data.labels[vi],
        value: v,
      },
      v,
    })),
  }));

const DayOfWeekChart = ({ data, classes }) => (
  <div className={classes.root}>
    <H6 align="center">{data.brand} Posts</H6>
    <DayOfTheWeekMatrix
      data={transformData(data)}
    />
  </div>
);

DayOfWeekChart.propTypes = {
  data: PropTypes.shape({
    brand: PropTypes.string,
    dataSets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.number),
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withAll(withStyles(styles))(DayOfWeekChart);
