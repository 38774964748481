import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.sizes.layout.appHeader.height.desktop,
    },
  },
});

const PageLayout = ({
  classes, className, children, ...other
}) => (
  <div className={cx(classes.wrapper, className)} {...other}>
    <Container maxWidth={false}>
      {children}
    </Container>
  </div>
);

PageLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

PageLayout.defaultProps = {
  className: '',
  children: null,
};

export default withStyles(styles)(PageLayout);
