import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageTitle from 'components/base/titles/PageTitle';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Loader from 'components/base/Loader';
import Input from 'components/base/forms/bootstrap/Input';
import { validate, phoneValidator } from 'utils/validators';
import { Field } from 'redux-form';
import { updateUserProfile, fetchUserProfile } from 'redux/user/actions';
import {
  url,
  email,
  length,
  required,
  combine,
} from 'redux-form-validators';
import { toast } from 'react-toastify';
import AccountForm from '../AccountForm';
import styles from './styles';


const validationConfig = {
  firstName: combine(
    required({ msg: 'First name is required.' }),
    length({ max: 255, msg: 'First name must be less than 255 characters' }),
  ),
  lastName: combine(
    required({ msg: 'Last name is required.' }),
    length({ max: 255, msg: 'Last name must be less than 255 characters' }),
  ),
  email: combine(
    required({ msg: 'Email is required.' }),
    email({ msg: 'Email is not valid.' }),
    length({ max: 255, msg: 'Email must be less than 255 characters' }),
  ),
  phoneNumber: combine(
    required({ msg: 'Phone number is required.' }),
    phoneValidator,
  ),
  company: combine(
    required({ msg: 'Company is required.' }),
    length({ max: 255, msg: 'Company must be less than 255 characters' }),
  ),
  companyUrl: combine(
    url({ msg: 'URL invalid, URL must contain https or http.' }),
    length({ max: 255, msg: 'URL must be less than 255 characters' }),
  ),
};

class UserProfile extends Component {
  handleSubmit = async (formData) => {
    const response = await this.props.updateUserProfile(formData);
    this.props.fetchUserProfile();
    toast.success('Profile information saved successfully');
    return response;
  }

  render() {
    const { userProfile } = this.props;
    return (
      <Loader loading={userProfile.loading}>
        <PageTitle title="User profile" />
        <AccountForm
          form="userProfile"
          onSubmit={this.handleSubmit}
          initial={userProfile.data}
          submitText="Update profile"
          validate={validate(validationConfig)}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="First Name"
                name="firstName"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="Last Name"
                name="lastName"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="Email"
                name="email"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="Phone number"
                name="phoneNumber"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="Brand/Company"
                name="company"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Input}
                label="Brand/Company URL"
                placeholder="Ex: https://www.example.com"
                name="companyUrl"
              />
            </Grid>
          </Grid>
        </AccountForm>
      </Loader>
    );
  }
}

UserProfile.propTypes = {
  updateUserProfile: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    company: PropTypes.string,
    companyUrl: PropTypes.string,
    brandCategory: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default connect(
  state => ({
    userProfile: state.userProfile,
  }),
  { updateUserProfile, fetchUserProfile },
)(withStyles(styles)(UserProfile));
