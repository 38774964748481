import React from 'react';

const semPageConfigs = [
  {
    url: '/free-influencer-search-tool',
    title: 'Go Prism - Influencer Marketing Intelligence Platform Landing Page',
    titleHtml: [
      <div>Want to find influencers</div>,
      <div>on Instagram and TikTok?</div>,
      <div>We got you.</div>,
    ],
  },
  {
    url: '/free-influencer-search-tool/Best-Influencer-Database-2022',
    titleHtml: <div>Best Influencer Database 2022</div>,
    title: 'Best Influencer Database 2022',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Database',
    titleHtml: <div>Influencer Marketing Database</div>,
    title: 'Influencer Marketing Database',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Campaign-Platform',
    titleHtml: <div>Influencer Campaign Platform</div>,
    title: 'Influencer Campaign Platform',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing:-Brands',
    titleHtml: <div>Influencer Marketing: Brands</div>,
    title: 'Influencer Marketing: Brands',
  },
  {
    url: '/free-influencer-search-tool/Viral-Influencer-Marketing',
    titleHtml: <div>Viral Influencer Marketing</div>,
    title: 'Viral Influencer Marketing',
  },
  {
    url: '/free-influencer-search-tool/Micro-Influencer-Marketing',
    titleHtml: <div>Micro Influencer Marketing</div>,
    title: 'Micro Influencer Marketing',
  },
  {
    url: '/free-influencer-search-tool/Best-Influencer-Search-Tool',
    titleHtml: <div>Best Influencer Search Tool</div>,
    title: 'Best Influencer Search Tool',
  },
  {
    url: '/free-influencer-search-tool/Top-Influencer-Platforms',
    titleHtml: <div>Top Influencer Platforms</div>,
    title: 'Top Influencer Platforms',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Strategy',
    titleHtml: <div>Influencer Marketing Strategy</div>,
    title: 'Influencer Marketing Strategy',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Campaign',
    titleHtml: <div>Influencer Marketing Campaign</div>,
    title: 'Influencer Marketing Campaign',
  },
  {
    url: '/free-influencer-search-tool/Best-Micro-Influencer-Platform',
    titleHtml: <div>Best Micro Influencer Platform</div>,
    title: 'Best Micro Influencer Platform',
  },
  {
    url: '/free-influencer-search-tool/2022-Influencer-Search-Engine',
    titleHtml: <div>2022 Influencer Search Engine</div>,
    title: '2022 Influencer Search Engine',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Search-Tool-2022',
    titleHtml: <div>Influencer Search Tool 2022</div>,
    title: 'Influencer Search Tool 2022',
  },
  {
    url: '/free-influencer-search-tool/Platform-For-Influencers',
    titleHtml: <div>Platform For Influencers</div>,
    title: 'Platform For Influencers',
  },
  {
    url: '/free-influencer-search-tool/1-Influencer-Search-Platform',
    titleHtml: <div>#1 Influencer Search Platform</div>,
    title: '#1 Influencer Search Platform',
  },
  {
    url: '/free-influencer-search-tool/Micro-Influencer-Database-2022',
    titleHtml: <div>Micro Influencer Database 2022</div>,
    title: 'Micro Influencer Database 2022',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Reporting',
    titleHtml: <div>Influencer Marketing Reporting</div>,
    title: 'Influencer Marketing Reporting',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Platform',
    titleHtml: <div>Influencer Marketing Platform</div>,
    title: 'Influencer Marketing Platform',
  },
  {
    url: '/free-influencer-search-tool/Best-Influencer-Marketplace',
    titleHtml: <div>Best Influencer Marketplace</div>,
    title: 'Best Influencer Marketplace',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Network-Platform',
    titleHtml: <div>Influencer Network Platform</div>,
    title: 'Influencer Network Platform',
  },
  {
    url: '/free-influencer-search-tool/2022-1-Influencer-Database',
    titleHtml: <div>2022 #1 Influencer Database</div>,
    title: '2022 #1 Influencer Database',
  },
  {
    url: '/free-influencer-search-tool/Best-Influencer-Platforms',
    titleHtml: <div>Best Influencer Platforms</div>,
    title: 'Best Influencer Platforms',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Planning',
    titleHtml: <div>Influencer Marketing Planning</div>,
    title: 'Influencer Marketing Planning',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Websites',
    titleHtml: <div>Influencer Marketing Websites</div>,
    title: 'Influencer Marketing Websites',
  },
  {
    url: '/free-influencer-search-tool/Best-Platforms-For-Influencers',
    titleHtml: <div>Best Platforms For Influencers</div>,
    title: 'Best Platforms For Influencers',
  },
  {
    url: '/free-influencer-search-tool/Platform-To-Find-Influencers',
    titleHtml: <div>Platform To Find Influencers</div>,
    title: 'Platform To Find Influencers',
  },
  {
    url: '/free-influencer-search-tool/Influencer-Marketing-Platform---Conquesting',
    titleHtml: <div>Influencer Marketing Platform - Conquesting</div>,
    title: 'Influencer Marketing Platform - Conquesting',
  },
];

export default semPageConfigs;
