export default () => ({
  topTitle: {
    marginTop: '1rem',
  },
  title: {
    justifyContent: 'center',
    marginTop: '3rem',
  },
  bannerSection: {},
});
