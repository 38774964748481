import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import PageLayout from 'components/base/Layout/PageLayout';
import PageTitle from 'components/base/titles/PageTitle';
import PlatformIcon from 'components/common/PlatformIcon';
import DateFilter from 'components/common/DateFilter';
import { getKeyPhraseMonitorPosts as getKeyPhraseMonitorPostsAction } from 'redux/monitors/actions';
import { getDaysFromDate, getDateFromDays } from 'utils/helpers';
import Loader from 'components/base/Loader';
import styles from './styles';
import MonitorGraph from './MonitorGraph';
import MonitorTopPosts from './MonitorTopPosts';
import MonitorTopCreators from './MonitorTopCreators';

const KeyPhraseMonitor = ({
  monitorId, classes, name, query, posts, creators, platforms, lastUpdate,
  getKeyPhraseMonitorPosts, params, isLoading, loaded,
}) => {
  const handleFilterSelect = (fromDate, toDate) => {
    getKeyPhraseMonitorPosts(
      monitorId,
      {
        ...params,
        postedWithin: getDaysFromDate(fromDate),
        postedBefore: getDaysFromDate(toDate),
      },
    );
  };

  return (
    <PageLayout>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <PageTitle
            title={
              <div className={classes.monitorTitle}>
                <div>Monitor - {name}</div>
                <div className={classes.monitorPlatforms} >
                  {platforms.map(platform => (<PlatformIcon platform={platform} key={platform} />))}
                </div>
              </div>
            }
            subtitle={
              <div className={classes.monitorSubTitle}>
              Last update: { moment(lastUpdate).format('MMMM Do YYYY, h:mm:ss a') }, data may be delayed up to 60 days
                <br />
              Showing matching posts for <b>{query}</b> over the past {params.postedWithin} days
              </div>
            }
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.filterContainer}>
          <div className={classes.filterItem}>
            <DateFilter
              handleSelect={handleFilterSelect}
              selectedFromDate={getDateFromDays(params.postedWithin)}
              selectedToDate={getDateFromDays(params.postedBefore)}
              customDate
            />
          </div>
        </Grid>
      </Grid>
      <Loader loading={isLoading} loaded={loaded}>
        <div className={classes.monitorDataContainer}>
          <MonitorGraph monitorId={monitorId} />
          <MonitorTopPosts posts={posts} />
          <MonitorTopCreators creators={creators} />
        </div>
      </Loader>
    </PageLayout>
  );
};

KeyPhraseMonitor.propTypes = {
  monitorId: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  creators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  lastUpdate: PropTypes.string.isRequired,
  params: PropTypes.shape({}).isRequired,
  getKeyPhraseMonitorPosts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  keyPhraseMonitorPosts: {
    data, params, loading, loaded,
  },
}) => ({
  name: data.name,
  query: data.query,
  posts: data.topPosts,
  creators: data.topCreators,
  platforms: data.platforms,
  lastUpdate: data.lastUpdate,
  isLoading: loading,
  loaded,
  params,
});

const mapDispatchToProps = { getKeyPhraseMonitorPosts: getKeyPhraseMonitorPostsAction };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(KeyPhraseMonitor));
