import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Icon, CardActions, CardContent } from '@material-ui/core';
import Button from 'components/base/Button';
import Input from 'components/base/forms/Input';
import PropTypes from 'prop-types';
import {
  passwordValidator,
  passwordsMatch,
  companyEmailValidator,
  phoneValidator,
} from 'utils/validators';
import Error from 'components/base/forms/Error/index';
import TermsAgreement from 'components/common/TermsAgreement';
import { combine, required, validateForm, length } from 'redux-form-validators';
import { forms } from 'constants/config';

const customValidate = {
  firstName: combine(
    required({ msg: 'First name is required.' }),
    length({ max: 100, msg: 'Value exceeds 100 characters' }),
  ),
  lastName: combine(
    required({ msg: 'Last name is required.' }),
    length({ max: 100, msg: 'Value exceeds 100 characters' }),
  ),
  company: combine(
    required({ msg: 'Company is required.' }),
    length({ max: 100, msg: 'Value exceeds 100 characters' }),
  ),
  email: combine(
    required({ msg: 'Corporate Email is required.' }),
    companyEmailValidator,
  ),
  phoneNumber: combine(
    required({ msg: 'Phone is required.' }),
    phoneValidator,
  ),
  password: combine(
    required({ msg: 'Password is required.' }),
    passwordValidator,
  ),
  confirmPassword: combine(
    passwordsMatch('Password does not match.'),
    required({ msg: 'Password confirmation is required.' }),
  ),
};

class QuickSignupForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <CardContent>
          <div>
            <Field
              name="firstName"
              component={Input}
              type="text"
              placeholder="First Name"
              label="First Name"
              fullWidth
            />
          </div>
          <div>
            <Field
              name="lastName"
              component={Input}
              type="text"
              placeholder="Last Name"
              label="Last Name"
              fullWidth
            />
          </div>
          <div>
            <Field
              name="email"
              component={Input}
              type="text"
              placeholder="Corporate Email"
              label="Corporate Email"
              fullWidth
            />
          </div>
          <div>
            <Field
              name="phoneNumber"
              component={Input}
              type="text"
              placeholder="Phone"
              label="Phone"
              fullWidth
            />
          </div>
          <div>
            <Field
              name="company"
              component={Input}
              type="text"
              placeholder="Company"
              label="Company"
              fullWidth
            />
          </div>
          <div>
            <Field
              name="password"
              component={Input}
              type="password"
              placeholder="Password"
              label="Password"
              fullWidth
            />
          </div>
          <div>
            <Field
              name="confirmPassword"
              component={Input}
              type="password"
              placeholder="Confirm password"
              label="Confirm password"
              fullWidth
            />
          </div>
          <div>
            <TermsAgreement />
          </div>
          <div>
            <Error error={error} />
          </div>
        </CardContent>
        <CardActions>
          <Button fullWidth variant="contained" color="primary" type="submit"><Icon>send</Icon> Sign up</Button>
        </CardActions>
      </form>
    );
  }
}

export default reduxForm({
  form: forms.QUICK_SIGNUP_FORM,
  validate: validateForm(customValidate),
})(QuickSignupForm);
