export default theme => ({
  navigation__container: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row!important',
      height: 'auto!important',
      opacity: '1!important',
      display: 'flex!important',
    },
    display: p => (p.open ? 'flex' : 'none'),
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    margin: 0,
    height: p => (p.open ? 'auto' : 0),
    opacity: p => (p.open ? 1 : 0),
    transition: 'opacity 0.5s ease-in, height 0.5s ease-in',
  },
});
