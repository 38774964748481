import { get } from 'lodash';
import { newReducer } from '../utils';

const initialState = {
  verticals: {
    data: {},
    loading: false,
  },
};

const verticalCategories = newReducer(initialState);

export default verticalCategories;

verticalCategories.method.GET_VERTICALS_CATEGORIES_PENDING = state => ({
  ...state,
  loading: true,
});


verticalCategories.method.GET_VERTICALS_CATEGORIES_FULFILLED = (state = initialState, action) => ({
  ...state,
  verticals: {
    data: get(action.payload, 'verticals'),
    loading: false,
    loaded: true,
  },
});


verticalCategories.method.GET_VERTICALS_CATEGORIES_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: true,
});
