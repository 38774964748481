import React from 'react';
import PropTypes from 'prop-types';
import { MP_EVENTS } from 'utils/copenhagen';
import {
  brandSafetyReportsUrl,
  competitiveReportCreateUrl,
  competitiveReportsUrl,
  listsUrl,
  pricing,
  searchUrl,
  verticalsUrl,
  creatorListeningUrl,
} from 'constants/routing';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import NavigationItem from './NavigationItem';

const NavItems = ({
  isAuthenticated, brandSafety,
}) => (
  <React.Fragment>
    <NavigationItem
      id="saved_list_nav_link"
      label="Find Creators"
      to={searchUrl()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'Find Creators' } }}
    />
    {isAuthenticated && <NavigationItem
      id="search_nav_link"
      label="My Saved Lists"
      to={listsUrl()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'My Saved Lists' } }}
    />}
    <NavigationItem
      label="Industries"
      to={verticalsUrl()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'Industries' } }}
    />
    {/* <NavigationItem */}
    {/*   label="Brands" */}
    {/*   to={brandsUrl()} */}
    {/*   trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'Brands' } }} */}
    {/* /> */}
    {isAuthenticated && <NavigationItem
      label="Competitive Analysis"
      to={competitiveReportsUrl()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'My Reports' } }}
    />}
    {isAuthenticated && brandSafety && <NavigationItem
      label="Brand Safety"
      to={brandSafetyReportsUrl()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'Brand Safety' } }}
    />}
    {isAuthenticated && <NavigationItem
      label="Creator Listening"
      to={creatorListeningUrl()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'Creator Listening' } }}
    />}
    {!isAuthenticated && <NavigationItem
      label="Reports"
      to={competitiveReportCreateUrl()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'Reports' } }}
    />}
    {!isAuthenticated && <NavigationItem
      label="Pricing"
      to={pricing()}
      trackEvent={{ name: MP_EVENTS.CLICK_MAIN_NAVIGATION, eventProps: { label: 'Pricing' } }}
    />}
  </React.Fragment>
);

NavItems.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  brandSafety: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default withAll(connect(mapStateToProps, null))(NavItems);
