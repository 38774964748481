export default theme => ({
  edit__list__button: {
    width: '104px',
    padding: '0px',
    textTransform: 'none',
    marginTop: '15px',
    color: theme.palette.text.meta,
    '& svg': {
      marginRight: '5px',
    },
  },
});
