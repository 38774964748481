import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import NavBar from 'components/app/NavBar';
import ErrorBoundary from './ErrorBoundary';
// import ServerDown from '../app/InterstitialPages/ServerDown';
import FooterNav from '../common/FooterNav';

const styles = theme => ({
  bodyWrapper: {
    minHeight: '100%',
    marginBottom: `-${theme.sizes.layout.footer.height.desktop}`,
  },
});

class BaseLayout extends PureComponent {
  render() {
    const {
      classes, children, serverDown,
    } = this.props;
    if (serverDown) {
      toast.error('Unexpected server error');
    }
    // const body = serverDown ? <ServerDown /> : children;
    return (
      <React.Fragment>
        <NavBar />
        <ErrorBoundary>
          <div className={classes.bodyWrapper}>
            {children}
          </div>
        </ErrorBoundary>
        <FooterNav />
      </React.Fragment>
    );
  }
}

BaseLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
  serverDown: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { serverDown } }) => ({
  serverDown,
});

export default connect(mapStateToProps)(withStyles(styles)(BaseLayout));
