import React from 'react';
import { Avatar, Typography, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const style = theme => ({
  title: {
    margin: '0.8rem 0 0 0',
    fontWeight: 600,
    display: 'block',
    fontSize: '0.8rem',
  },
  logoAvatar: {
    marginRight: '-1rem',
    display: 'inline-block',
    border: `2px solid ${theme.palette.white.main}`,
    boxShadow: theme.customShadows[1],
  },
});

const TopBrands = ({ classes, brands }) => (
  <div className={classes.root}>
    <Typography variant="body" align="center" className={classes.title}>Top Brands Mentioned</Typography>
    <div className={classes.logoContainer}>
      {brands.map(b => (
        <React.Fragment>
          <Tooltip title={b.name}>
            <Avatar className={classes.logoAvatar} src={b.logo} alt={b.name} />
          </Tooltip>
        </React.Fragment>
      ))}
    </div>
  </div>
);

TopBrands.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default withStyles(style)(TopBrands);
