import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import KeyPhraseMonitorForm from 'components/common/forms/KeyPhraseMonitorForm';
import BaseModal from 'components/base/modals/BaseModal';
import {
  createKeyPhraseMonitor as createKeyPhraseMonitorAction,
  updateKeyPhraseMonitor as updateKeyPhraseMonitorAction,
  getKeyPhraseMonitors as getKeyPhraseMonitorsAction,
} from 'redux/monitors/actions';

const SuccessMessage = action => <React.Fragment>Monitor has been {action}.</React.Fragment>;


const KeyPhraseMonitorModal = ({
  createKeyPhraseMonitor,
  updateKeyPhraseMonitor,
  getKeyPhraseMonitors,
  closeModal,
  monitor,
  open,
}) => {
  const isUpdate = !!monitor.id;
  const handleSubmit = async (data) => {
    try {
      if (isUpdate) {
        await updateKeyPhraseMonitor(data.id, data);
        toast.success(SuccessMessage('updated'));
      } else {
        await createKeyPhraseMonitor(data);
        toast.success(SuccessMessage('created'));
      }
      getKeyPhraseMonitors();
      closeModal();
    } catch (error) {
      if (error.errors) {
        if (error.errors.status === 403) {
          toast.error(<Typography variant="body2">{error.errors.detail}</Typography>);
          return;
        }
        throw new SubmissionError(error.errors);
      }
    }
  };

  return (
    <BaseModal
      onClose={closeModal}
      open={open}
      title={isUpdate ? 'Update Monitor' : 'Create Monitor'}
      height="auto"
    >
      <KeyPhraseMonitorForm
        onSubmit={handleSubmit}
      />
    </BaseModal>
  );
};

KeyPhraseMonitorModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  monitor: PropTypes.shape({
    id: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  getKeyPhraseMonitors: PropTypes.func.isRequired,
  createKeyPhraseMonitor: PropTypes.func.isRequired,
  updateKeyPhraseMonitor: PropTypes.func.isRequired,
};

KeyPhraseMonitorModal.defaultProps = {
  monitor: {},
};

const mapDispatchToProps = {
  createKeyPhraseMonitor: createKeyPhraseMonitorAction,
  updateKeyPhraseMonitor: updateKeyPhraseMonitorAction,
  getKeyPhraseMonitors: getKeyPhraseMonitorsAction,
};

export default connect(null, mapDispatchToProps)(KeyPhraseMonitorModal);
