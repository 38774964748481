import { reduce, set } from 'lodash';
import { getInfluencerName } from '../../utils/influencers';

const TOGGLE_SEARCH_PANEL = 'TOGGLE_SEARCH_PANEL';
const SELECT_SEARCH_TAB = 'SELECT_SEARCH_TAB';
const ADD_SELECTED_INFLUENCER = 'ADD_SELECTED_INFLUENCER';
const ADD_SELECTED_INFLUENCERS = 'ADD_SELECTED_INFLUENCERS';
const REMOVE_SELECTED_INFLUENCER = 'REMOVE_SELECTED_INFLUENCER';
const DESELECT_ALL_INFLUENCERS = 'DESELECT_ALL_INFLUENCERS';

export const togglePanel = value => dispatch => dispatch({
  type: TOGGLE_SEARCH_PANEL,
  isVerticalPanelOpen: value,
});

export const selectTab = value => dispatch => dispatch({
  type: SELECT_SEARCH_TAB,
  selectedTab: value,
});

export const addSelectedInfluencer = (influencerId, influencerName) => dispatch => dispatch({
  type: ADD_SELECTED_INFLUENCER,
  influencerId,
  influencerName,
});

export const addSelectedInfluencers = influencers => (dispatch) => {
  const selectedInfluencers = reduce(influencers, (acc, influencer) => set(acc, influencer.id, {
    name: getInfluencerName(influencer),
  }), {});
  dispatch({
    type: ADD_SELECTED_INFLUENCERS,
    selectedInfluencers,
  });
};

export const removeSelectedInfluencer = influencerId => dispatch => dispatch({
  type: REMOVE_SELECTED_INFLUENCER,
  influencerId,
});


export const deselectAllInfluencers = () => dispatch => dispatch({
  type: DESELECT_ALL_INFLUENCERS,
});
