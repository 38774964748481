import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { CardContent, Tooltip, Grid } from '@material-ui/core';
import Card from 'components/base/Card';
import Chip from 'components/base/Chip';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubble from '@material-ui/icons/ChatBubble';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { formatNumber } from 'utils/helpers';
import { slice } from 'lodash';
import ImageBoxWithDefault from 'components/common/ImageBoxWithDefault';
import CreatorTitle from './CreatorTitle';

const styles = theme => ({
  root: {},
  icon: {
    color: theme.palette.black.light,
    padding: '0 0.3rem',
    fontSize: '0.7rem',
  },
  span: {
    fontSize: '0.7rem',
  },
  statContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mentionsContainer: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  postContainer: {
    height: 200,
    '& > .MuiGrid-grid-xs-auto': {
      flexGrow: 100,
    },
  },
  postRowContainer: {
    position: 'relative',
  },
  postImage: {
    objectFit: 'cover',
  },
  imgNotAvailTextClass: {
    top: '1rem',
  },
});

const NULL_VALUE_DISPLAY = 'N/A';

const CreatorCard = ({ data, classes }) => (
  <Card className={classes.root}>
    <Grid container spacing={0}>
      <Grid item xs={3}>
        <CreatorTitle data={data} />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Tooltip title="Likes">
                <div className={classes.statContainer}>
                  <FavoriteIcon className={classes.icon} />
                  <span className={classes.span}>{formatNumber(data.likeCount, '0a', NULL_VALUE_DISPLAY)}</span>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Comments">
                <div className={classes.statContainer}>
                  <ChatBubble className={classes.icon} />
                  <span className={classes.span}>{formatNumber(data.commentCount, '0a', NULL_VALUE_DISPLAY)}</span>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Views">
                <div className={classes.statContainer}>
                  <VisibilityIcon className={classes.icon} />
                  <span className={classes.span}>{formatNumber(data.videoViewCount, '0a', NULL_VALUE_DISPLAY)}</span>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.mentionsContainer}>
            <Chip label={`${formatNumber(data.totalPosts, '0a', NULL_VALUE_DISPLAY)} Mentions of brand`} />
          </Grid>
        </CardContent>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={0} className={classes.postContainer}>
          {slice(data.posts, 0, 3).map(p => (
            <Grid item xs={4} className={classes.postRowContainer}>
              <ImageBoxWithDefault
                src={p.image}
                className={classes.postImage}
                imgNotAvailTextClass={classes.imgNotAvailTextClass}
                height="200"
              />
            </Grid>
           ))}
        </Grid>
      </Grid>
    </Grid>
  </Card>
);

CreatorCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(CreatorCard);
