import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import cx from 'classnames';

const styles = theme => ({
  root: {
    marginBottom: '1.2rem',
    color: theme.palette.black.light,
    display: 'block',
  },
});

const HomeSubTitle = ({
  classes,
  className,
  children,
  titleId,
}) => (
  <Typography id={titleId} variant="body" className={cx(classes.root, className)}>{children}</Typography>
);

HomeSubTitle.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  titleId: PropTypes.string,
};

HomeSubTitle.defaultProps = {
  className: '',
  titleId: null,
};

export default withStyles(styles)(HomeSubTitle);
