import React from 'react';
import Modal from 'components/base/modals/PrismModal';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import BetaSignupForm from 'components/common/forms/BetaSignupForm';
import { register as registerAction, login as loginAction } from 'redux/authentication/actions';
import { reset, SubmissionError } from 'redux-form';
import { forms, LOCAL_STORAGE_VALUE } from 'constants/config';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import { searchUrl } from 'constants/routing';
import styles from './styles';

const SuccessMessage = () => (
  <React.Fragment>
    Account created!
  </React.Fragment>
);

const BetaSignupModal = ({
  classes,
  open,
  closeModal,
  register,
  resetForm,
  hideClose,
  onSignupComplete,
  history,
  login,
}) => {
  async function onSubmit(formData) {
    try {
      await register(formData);
      resetForm();
      localStorage.setItem(LOCAL_STORAGE_VALUE.BETA_SIGN_UP_COMPLETE, true);
      toast.success(<SuccessMessage />);
      onSignupComplete();
      setTimeout(async () => {
        await login(formData.email, formData.password);
        history.push(searchUrl());
      }, 1000);
    } catch (error) {
      if (error.errors) {
        throw new SubmissionError(error.errors);
      }
    }
  }
  function onCloseModal() {
    closeModal();
    mpTrack(MP_EVENTS.CLOSE_SIGNUP_MODAL);
  }
  const copyString = `Create a free ${process.env.BETA_DEFAULT_TRIAL_DAYS}-day trial to
   search millions of vetted creators and view audience demographics to maximize your reach.`;
  return (
    <Modal
      title="Get Beta Access to Prism"
      open={open}
      onClose={onCloseModal}
      className={classes.root}
      hideClose={hideClose}
    >
      <Typography variant="h6">{copyString}</Typography>
      <Typography className={classes.noCreditCard} variant="body">- No credit card required- </Typography>
      <BetaSignupForm onSubmit={data => onSubmit(data)} />
    </Modal>
  );
};

BetaSignupModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  hideClose: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  onSignupComplete: PropTypes.func,
};

BetaSignupModal.defaultProps = {
  onSignupComplete: () => {},
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = {
  register: registerAction,
  login: loginAction,
  resetForm: () => dispatch => dispatch(reset(forms.BETA_SIGNUP_FORM)),
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(BetaSignupModal);
