export default theme => ({
  root: {
    padding: '1rem 1rem 1rem 3rem',
    borderBottom: `1px solid ${theme.palette.border.main}`,
    position: 'relative',
  },
  sectionTitle: {
    display: 'inline-flex',
    color: theme.palette.black.main,
    marginBottom: '0.6rem',
    verticalAlign: 'top',
  },
  sectionIcon: {
    display: 'inline-flex',
    marginRight: '0.4rem',
  },
  sectionInfoIconDiv: {
    display: 'inline-flex',
  },
  sectionInfoIcon: {
    marginLeft: '0.3rem',
    color: theme.palette.black.dark,
    fontSize: '1.1rem',
    '&:hover': {
      color: theme.palette.black.main,
    },
  },
});
