import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { debounce, isEmpty } from 'lodash';
import cx from 'classnames';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import { getUsernameAutoComplete } from 'redux/autocompletes/actions';
import { getInfluencers } from 'redux/influencers/actions';
import AutoComplete from 'components/base/AutoComplete';
import { influencerProfileUrl } from 'constants/routing';
import RequestInfluencer from 'components/common/modals/RequestInfluencers';
import withModal from 'components/base/withModal';
import { scrollToTop } from 'utils/helpers';
import NoResults from './NoResults';
import styles from './styles';

const MINIMUM_INPUT_CHARS = 3;

const renderInputComponent = (inputProps) => {
  const {
    classes,
    inputRef = () => {},
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        className: classes.input,
        fullWidth: true,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" className={classes.searchIcon}>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      type="text"
      {...other}
    />
  );
};

class UsernameAutoComplete extends Component {
  state = {
    value: '',
    openNoResults: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionSelected = (event, suggestion) => {
    if (this.props.onSuggestionSelected) {
      this.props.onSuggestionSelected(suggestion);
      return;
    }

    scrollToTop();
    this.props.history.push(influencerProfileUrl(suggestion.suggestion.value.id));
  };

  onChange = (value) => {
    this.setState({
      value,
    });
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({
      openNoResults: false,
    });
  };

  loadSuggestions = debounce(async (value) => {
    if (value.length < MINIMUM_INPUT_CHARS) {
      this.setState({
        openNoResults: false,
      });
      return;
    }

    await this.props.getUsernameAutoComplete(value);
    if (isEmpty(this.props.usernameAutoComplete.results)) {
      this.setState({
        openNoResults: true,
      });
    }
  }, 300);


  render() {
    const {
      classes,
      className,
      usernameAutoComplete,
      open,
      closeModal,
      openModal,
      placeholder,
      width,
    } = this.props;
    const { value, openNoResults } = this.state;
    const suggestions = usernameAutoComplete.results;

    return (
      <div
        className={cx(className, classes.root)}
        style={{ width }}
        ref={(node) => {
          this.node = node;
        }}
      >
        <AutoComplete
          showAfter={MINIMUM_INPUT_CHARS}
          renderInputComponent={renderInputComponent}
          inputProps={{ classes, placeholder }}
          onChange={this.onChange}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionSelected={this.onSuggestionSelected}
        />
        {openNoResults &&
          <NoResults username={value} openModal={openModal} />
        }
        <RequestInfluencer open={open} closeModal={closeModal} />
      </div>
    );
  }
}

UsernameAutoComplete.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  getUsernameAutoComplete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  usernameAutoComplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({}),
    })).isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  onSuggestionSelected: PropTypes.func,
  width: PropTypes.string,
};

UsernameAutoComplete.defaultProps = {
  className: '',
  placeholder: 'Search username, handle or name',
  onSuggestionSelected: null,
  width: '50%',
};

const mapStateToProps = ({ usernameAutoComplete }) => ({
  usernameAutoComplete,
});

const mapDispatchToProps = {
  getUsernameAutoComplete,
  getInfluencers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withModal(withRouter(withStyles(styles)(UsernameAutoComplete))));
