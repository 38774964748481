export default theme => ({
  social__icon__container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.white.main,
  },
  platform_link: {
    textDecoration: 'none',
  },
  platform_chip: {
    cursor: 'pointer',
  },
});
