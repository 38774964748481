import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PageTitle from 'components/base/titles/PageTitle';
import { Typography, Grid } from '@material-ui/core';
import PromoBannerWrapper from 'components/base/sections/PromoBannerWrapper';
import BulleitItem from 'components/base/sections/BulleitItem';
import reportImage from 'images/home/reports.gif';

const styles = theme => ({
  innerContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  title: {
    textAlign: 'left',
    display: 'block',
    fontSize: '2rem',
    margin: '0 0 0.8rem 0',
    '&:before': {
      width: '40px',
      marginBottom: '0.8rem',
      display: 'block',
      position: 'relative',
      content: '""',
      height: '2px',
      backgroundColor: theme.palette.pink.main,
    },
    [theme.breakpoints.up('md')]: {
      width: '25rem',
    },
  },
  subTitle: {
    marginBottom: '1.2rem',
    color: theme.palette.black.light,
    display: 'block',
  },
  imageContainer: {
    flexGrow: 1,
    textAlign: 'right',
  },
  image: {
    width: '100%',
    borderRadius: '0.4rem',
    boxShadow: theme.customShadows[3],
    overflow: 'hidden',
    margin: '1rem 0 -2rem auto',
    '& > img': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '75%',
      minWidth: '40rem',
      margin: '0 0 -13rem auto',
    },
  },
  textContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40rem',
    },
  },
});

const PromoBanner = ({ classes }) => (
  <PromoBannerWrapper>
    <div className={classes.innerContainer}>
      <div className={classes.textContainer}>
        <PageTitle
          title={<React.Fragment>Create <b>Custom Competitor</b> Benchmark Reports</React.Fragment>}
          className={classes.title}
        />
        <Typography variant="body" className={classes.subTitle}>
          Create a report with the specific competitors that you want to track
        </Typography>
        <Grid container className={classes.bullietContainer}>
          <BulleitItem>Share Of Voice</BulleitItem>
          <BulleitItem>Top Creators</BulleitItem>
          <BulleitItem>Top Performing Content</BulleitItem>
          <BulleitItem>Top Performing Days</BulleitItem>
          <BulleitItem>Influencer Tier Breakdown</BulleitItem>
          <BulleitItem>Top Brands</BulleitItem>
          <BulleitItem>Category Trends & Activity</BulleitItem>
          <BulleitItem>Influencer Demographics</BulleitItem>
          <BulleitItem>& More…</BulleitItem>
        </Grid>
      </div>
      <div className={classes.imageContainer}>
        <div className={classes.image}>
          <img alt="Reports" src={reportImage} />
        </div>
      </div>
    </div>
  </PromoBannerWrapper>
);

PromoBanner.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(PromoBanner);
