import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import H6 from 'components/base/titles/CardHeading/H6';
import VerticalBarHtml from 'components/base/charts/Bar/BarHtml/VerticalBarHtml';
import { formatNumber } from 'utils/helpers';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';

const styles = () => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
  },
  barChart: {
    flex: 1,
    height: 'auto',
  },
});

const AgeGroups = ({ classes, data: { values, labels }, theme }) => (
  <div className={classes.root}>
    <H6 align="center">
      <LabelWithInfoIcon
        labelText="Age Groups"
        tooltip="The most probable age group of the audience."
      />
    </H6>
    <VerticalBarHtml
      className={classes.barChart}
      data={{
        values,
        labels,
        barLabels: values.map(v => formatNumber(v / 100, '0%', 'NA')),
        colors: labels.map(() => theme.palette.green.light),
      }}
    />
  </div>
);

AgeGroups.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};
export default withStyles(styles, { withTheme: true })(AgeGroups);
