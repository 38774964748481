import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMars,
  faVenus,
} from '@fortawesome/free-solid-svg-icons';
import styles from './styles';

const GenderChart = ({ theme, genderStats, classes }) => {
  const data = {
    labels: ['Male', 'Female'],
    datasets: [{
      data: [genderStats.male, genderStats.female],
      backgroundColor: [
        theme.palette.green.main,
        theme.palette.blue.main,
      ],
      borderColor: theme.palette.white.dark,
      borderWidth: 4,
    }],
  };
  const options = {
    responsive: true,
    legend: false,
    title: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };
  return (
    <div className={classes.root}>
      <Doughnut height={200} data={data} options={options} redraw />
      <Typography variant="body1" className={classes.gender__stat}>
        <b className={classes.gender__stat__value}>
          <FontAwesomeIcon className={classes.gender__icon_male} icon={faMars} /> {genderStats.male}%
        </b> Male
      </Typography>
      <Typography variant="body1" className={classes.gender__stat}>
        <b className={classes.gender__stat__value}>
          <FontAwesomeIcon className={classes.gender__icon_female} icon={faVenus} /> {genderStats.female}%
        </b> Female
      </Typography>
    </div>
  );
};

GenderChart.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  genderStats: PropTypes.shape({
    male: PropTypes.number.isRequired,
    female: PropTypes.number.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(GenderChart);
