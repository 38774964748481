import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { reset, SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import FormModal from 'components/base/modals/FormModal';
import {
  createBrandSafetyReports as createBrandSafetyReportsAction,
} from 'redux/brandSafetyReports/actions';
import { forms } from 'constants/config';
import BrandSafetyReportsForm from '../../../app/BrandSafetyReports/AddReportsModal/BrandSafetyReportsForm';
import styles from './styles';

const SuccessMessage = () => <Typography variant="body2">Reports has been queued.</Typography>;

class AddReportModal extends React.Component {
  onModalClose = () => {
    const { onClose } = this.props;
    reset(forms.BRAND_SAFETY_REPORT);
    onClose();
  };

  onSubmit = async ({ influencers, objectionableListPriorities }) => {
    const { createBrandSafetyReports, handleSubmit } = this.props;
    const influencersData = influencers.map(i => i.id);

    if (!influencersData.length) {
      return;
    }

    handleSubmit(true);
    toast.success(<SuccessMessage />);
    this.onModalClose();

    try {
      await createBrandSafetyReports({
        influencer_ids: influencersData,
        objectionableListPriorities,
      });
    } catch (error) {
      if (error.errors) {
        if (error.errors.status === 403) {
          toast.error(<Typography variant="body2">{error.errors.detail}</Typography>);
          return;
        }
        throw new SubmissionError(error.errors);
      }
    } finally {
      handleSubmit(false);
    }
  };

  render() {
    const { isOpen, preselectedInfluencer, title } = this.props;
    return (
      <React.Fragment>
        <FormModal
          open={isOpen}
          onClose={this.onModalClose}
          onSubmit={this.onSubmit}
          title={title}
          form="brandSafetyReportsForm"
          width="400px"

          height="auto"
          initial={{}}
        >
          <BrandSafetyReportsForm
            preselectedInfluencer={preselectedInfluencer}
            formValues={[]}
          />
        </FormModal>
      </React.Fragment>
    );
  }
}

AddReportModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createBrandSafetyReports: PropTypes.func.isRequired,
  preselectedInfluencer: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
};

AddReportModal.defaultProps = {
  handleSubmit: noop,
};

const mapStateToProps = ({ brandSafetyReport }) => ({
  loading: brandSafetyReport.loading,
});

const mapDispatchToProps = {
  createBrandSafetyReports: createBrandSafetyReportsAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AddReportModal);
