import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';

export default withStyles(() => ({
  title: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  subheader: {
    fontSize: '0.8rem',
  },
}))(CardHeader);
