import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import LegendItem from './LegendItem';

const styles = () => ({
  root: {
    margin: 0,
    padding: 0,
    display: 'inline-block',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const Legend = ({ classes, items, ...other }) => (
  <div className={classes.root} {...other}>
    <div className={classes.container}>
      {items.map(i => <LegendItem data={i} />)}
    </div>
  </div>
);

Legend.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
    tooltip: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
    }),
  })).isRequired,
};

export default withStyles(styles)(Legend);
