export default theme => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '1rem',
  },
  igIcon: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.socialPlatform.instagram,
  },
  piIcon: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.socialPlatform.pinterest,
  },
  ttIcon: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.socialPlatform.tiktok,
  },
  ytIcon: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.socialPlatform.youtube,
  },
  checkboxRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    width: '1.5rem',
    padding: 0,
  },
  inputWrapper: {
    width: '15rem',
  },
});
