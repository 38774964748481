import React from 'react';
import PropTypes from 'prop-types';
import { CATEGORIES } from 'constants/options';
import MultiSelect from 'components/base/MultiSelect';
import withOnBoarding from 'components/common/OnBoarding/withOnBoarding';
import SearchSection from './SearchSection';
import withSearchParam from '../withSearchParam';

const spotLightStyles = {
  height: '400px',
  width: '81%',
  position: 'absolute',
  top: 0,
  zIndex: -1,
};

class CategoryFilter extends React.PureComponent {
  handleRemoveCategory = (category) => {
    const { input: { onChange, value } } = this.props;
    onChange(value.filter(v => v.value !== category));
  };

  render() {
    const {
      input: { onChange, value },
      onBoarding: { showCategoryMenu },
      handleOnBoardingCategorySearch,
    } = this.props;

    return (
      <SearchSection
        id="category_search_filter"
        title="Creator Category"
        tooltip="Find Creators that post about a specific topic."
      >
        <React.Fragment>
          {showCategoryMenu &&
            <MultiSelect
              name="categories"
              options={CATEGORIES}
              value={value}
              placeholder="Find Category"
              onChange={handleOnBoardingCategorySearch}
              handleRemoveSelection={this.handleRemoveCategory}
              selectAdditionalProps={{ menuIsOpen: showCategoryMenu }}
              isMulti
            />
          }
          {!showCategoryMenu &&
            <MultiSelect
              name="categories"
              options={CATEGORIES}
              value={value}
              placeholder="Find Category"
              onChange={onChange}
              handleRemoveSelection={this.handleRemoveCategory}
              isMulti
            />
          }
          <div id="category_search" className="spotlight__category_search" style={spotLightStyles} />
        </React.Fragment>
      </SearchSection>

    );
  }
}

CategoryFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onBoarding: PropTypes.shape({
    showCategoryMenu: PropTypes.bool,
  }).isRequired,
  handleOnBoardingCategorySearch: PropTypes.func.isRequired,
};

export default withSearchParam('categories')(withOnBoarding(CategoryFilter));
