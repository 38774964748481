import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import ShareListForm from 'components/common/forms/ShareListForm';
import Tooltip from 'components/base/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Typography, Icon } from '@material-ui/core';
import styles from './styles';

const ShareListCard = ({ classes, onClick }) => (
  <div className={classes.content}>
    <div className={classes.header}>
      <Typography id="share_list_title" variant="h3" className={classes.title}>
        Share List
      </Typography>

      <Tooltip
        title="Sharing the creator list will give others view-only access to your curated Creator List"
        placement="right"
      >
        <div className={classes.tooltipIconDiv}>
          <Icon className={classes.tooltipIcon}>info_outlined</Icon>
        </div>
      </Tooltip>

      <Button className={classes.closeButton} onClick={() => onClick({})}>
        <CloseIcon />
      </Button>
    </div>

    <ShareListForm onSubmit={data => onClick(data)} />
  </div>
);

ShareListCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ShareListCard);
