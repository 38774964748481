import { withStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup } from '@material-ui/lab';

export default withStyles(() => ({
  root: {
    boxShadow: 'none',
    display: 'flex',
    borderRadius: '0px !important',
  },
}))(ToggleButtonGroup);
