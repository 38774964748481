import React from 'react';
import { isArray, isString, get } from 'lodash';
import * as PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import KeywordAutoComplete from 'components/app/Search/SearchBar/KeywordAutoComplete';
import ToggleButton from 'components/base/ToggleButton';
import ToggleButtonGroup from 'components/base/ToggleButtonGroup';
import styles from './styles';

const LOGIC_RULES = {
  AND: 'AND',
  OR: 'OR',
};
const DEFAULT_RULE = 'AND';

class KeywordSelectField extends React.Component {
  constructor(props) {
    super(props);

    const { input: { value } } = props;

    this.state = {
      selectedLogic: this.getLogicWord(value),
    };
  }
    getKeywordType = (value) => {
      const type = get(value, 'type', 'hashtag');
      if (type === 'hashtag') return '#';
      else if (type === 'mention') return '@';
      return '';
    }

    getLogicWord = (value) => {
      const result = value.split(' ').filter(word => Object.keys(LOGIC_RULES).includes(word));
      if (result.length > 0) return result[0];

      return DEFAULT_RULE;
    }

    handleRemoveKeyword = (keyword) => {
      const { input: { value } } = this.props;
      const values = this.transformToList(value);
      this.handleChange(values.filter(v => v.value !== keyword));
    }

    transformToList = (value) => {
      if (isArray(value)) return value;
      const exclude = [...Object.keys(LOGIC_RULES), ''];
      const result = value.split(' ').filter(word => !exclude.includes(word)).map(word => ({
        label: word,
        value: word,
      }));
      return result;
    }

    normalizeString = (value) => {
      let normalValue = value.replace(/[^a-zA-Z0-9-_@#.]/g, '');
      if (!['@', '#'].some(v => value.includes(v))) normalValue = `#${normalValue}`;
      return normalValue;
    }

    transformToString = (value) => {
      if (isString(value)) return this.normalizeString(value);
      return this.getKeywordType(value) + value.text;
    }

    handleChange = (value) => {
      const { input: { onChange } } = this.props;
      const newValue = value.map(word => this.transformToString(word.value)).join(` ${this.state.selectedLogic} `);
      return onChange(newValue);
    }

    handleChangeLogic = (_, logic) => {
      if (!logic) return null;
      const { input: { onChange, value } } = this.props;
      this.setState({ selectedLogic: logic });
      const newValue = this.transformToList(value).map(word => this.transformToString(word.value)).join(` ${logic} `);
      return onChange(newValue);
    }

    render() {
      const {
        classes, input: { value }, meta: { touched, error },
      } = this.props;
      const hasError = !!(touched && error);
      const errorElem = <span className="MuiFormHelperText-root Mui-error">{hasError ? error : ''}</span>;
      return (
        <FormControl className={classes.keywordFormControl}>
          <div className={classes.keywordContainer}>
            <div className={classes.keywordSelect}>
              <KeywordAutoComplete
                keywords={this.transformToList(value)}
                onChange={this.handleChange}
                handleRemoveKeyword={this.handleRemoveKeyword}
                placeholder="Enter #hashtag or @mention"
              />
              {errorElem}
            </div>
            <div className={classes.keywordLogic}>
              <ToggleButtonGroup
                exclusive
                value={this.state.selectedLogic}
                onChange={this.handleChangeLogic}
              >
                {Object.entries(LOGIC_RULES).map(([v, t]) => (
                  <ToggleButton value={v} className={classes.keywordLogicItem}>
                    {t}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
          </div>
        </FormControl>
      );
    }
}

KeywordSelectField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

KeywordSelectField.defaultProps = {
  meta: null,
};

export default withStyles(styles)(KeywordSelectField);
