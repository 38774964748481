import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import cx from 'classnames';
import imageNotAvailablePath from 'images/topContent/image-not-available.jpg';

const styles = theme => ({
  imageNotAvailableMsgBackground: {
    backgroundColor: theme.palette.black.main,
    position: 'absolute',
    width: '60%',
    right: '20%',
    height: '1.5rem',
    textAlign: 'center',
  },
  imageNotAvailableMsg: {
    color: 'white',
  },
});

const CardMediaWithDefault = ({
  classes, imageNotAvailable, imgNotAvailTextClass, caption, ...props
}) => {
  const [hasImage, setHasImage] = useState(true);
  return (
    <React.Fragment>
      {!hasImage && (
        <div className={cx(classes.imageNotAvailableMsgBackground, imgNotAvailTextClass)}>
          <span className={classes.imageNotAvailableMsg}>{caption}</span>
        </div>
      )}
      <CardMedia
        component="img"
        onError={(e) => {
          e.target.src = imageNotAvailable;
          setHasImage(false);
        }}
        {...props}
      />
    </React.Fragment>
  );
};

CardMediaWithDefault.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  imageNotAvailable: PropTypes.string,
  imgNotAvailTextClass: PropTypes.string,
  caption: PropTypes.string,
};

CardMediaWithDefault.defaultProps = {
  imageNotAvailable: imageNotAvailablePath,
  imgNotAvailTextClass: null,
  caption: 'Image not available',
};

export default withAll(withStyles(styles, { withTheme: true }))(CardMediaWithDefault);
