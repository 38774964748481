import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import Tooltip from 'components/base/Tooltip';
import styles from './styles';

const LabelWithInfoIcon = ({
  classes,
  labelText,
  tooltip,
  placement,
}) => (
  <div className={classes.root}>
    <span>{labelText}</span>
    <Tooltip title={tooltip} placement={placement}>
      <div className={classes.infoIconDiv}>
        <Icon className={classes.infoIcon}>info_outlined</Icon>
      </div>
    </Tooltip>
  </div>
);

LabelWithInfoIcon.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  labelText: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

LabelWithInfoIcon.defaultProps = {
  placement: 'bottom',
};

export default withStyles(styles)(LabelWithInfoIcon);
