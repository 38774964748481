import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import withAll from 'components/base/withAll';
import withInfluencerSearch from 'components/common/withInfluencerSearch';
import { isEmpty } from 'lodash';
import withModal from 'components/base/withModal';
import AddToList from 'components/common/lists/AddToList/MultipleAddToList';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import styles from './styles';
import SelectedInfluencers from '../../../common/SelectedInfluencers';
import Sort from './Sort';
import PageLimit from './PageLimit';

// eslint-disable-next-line react/no-multi-comp
class Search extends PureComponent {
  handleSort = (sort) => {
    const { searchParams } = this.props;
    this.props.getInfluencers({
      ...searchParams,
      sort,
      page: 0,
    });
  };

  render() {
    const {
      classes,
      searchParams,
      influencers,
      open,
      closeModal,
      isAuthenticated,
      openModal,
    } = this.props;

    return (
      <div className={classes.root}>
        {!isAuthenticated && <BetaSignupModal
          open={open}
          closeModal={closeModal}
          onSignupComplete={closeModal}
        />}
        <div className={classes.leftButtons}>
          <SelectedInfluencers
            buttonProps={{
              variant: 'contained',
              color: 'secondary',
            }}
            influencers={influencers.influencers}
          />
          { !searchParams.isRecommendation &&
            <React.Fragment>
              <Sort
                handleSort={isAuthenticated ? this.handleSort : openModal}
                selectedSort={searchParams.sort}
                showLabels={!isEmpty(searchParams.labels)}
              />
              <PageLimit />
            </React.Fragment>
          }
        </div>
        <div id="saved_lists" className={classes.rightButtons}>
          <AddToList />
        </div>
      </div>
    );
  }
}


Search.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
  getInfluencers: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  influencers: PropTypes.shape({
    pagination: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
};

export default withAll(
  connect(
    state => ({
      searchDisplay: state.searchDisplay,
      searchParams: state.searchParams,
      influencers: state.influencers,
      isAuthenticated: state.auth.isAuthenticated,
    }),
    null,
  ),
  withStyles(styles),
  withModal,
  withInfluencerSearch,
)(Search);
