export default theme => ({
  root: {
    color: theme.palette.black.dark,
    marginBottom: '2rem',
  },
  tabContainer: {
    backgroundColor: theme.palette.white.main,
    marginTop: '2rem',
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  label: {
    margin: '0 0.4rem 0 0',
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.6rem',
  },
});
