import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { sum, isEmpty } from 'lodash';
import Bar from '../Bar';

const styles = () => ({
  root: {},
});

const transformValues = ({
  values,
  labels,
  colors,
  barLabels,
}, customMaxValue) => {
  const maxVal = customMaxValue || sum(values);
  return values.map((v, i) => ({
    key: `horizontalBar_${i}`,
    label: labels[i],
    fill: `${(v / maxVal) * 100}%`,
    value: v,
    barColor: colors[i],
    barLabel: !isEmpty(barLabels) ? barLabels[i] : null,
  }));
};

const HorizontalBarHtml = ({
  classes,
  data,
  displayTooltip,
  maxValue,
}) => (
  <div className={classes.root}>
    {transformValues(data, maxValue).map(d => (
      <Bar key={d.key} data={d} displayTooltip={displayTooltip} />
    ))}
  </div>
);

HorizontalBarHtml.propTypes = {
  data: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    colors: PropTypes.arrayOf(PropTypes.string),
    barLabels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  displayTooltip: PropTypes.bool,
  maxValue: PropTypes.number,
};

HorizontalBarHtml.defaultProps = {
  displayTooltip: false,
  maxValue: null,
};

export default withStyles(styles)(HorizontalBarHtml);
