import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { recursiveFunc } from 'utils/mixins';
import { mapKeys, snakeCase, camelCase } from 'lodash';

export const GET_BRAND = 'GET_BRAND';
export const GET_STAT_BRANDS = 'GET_STAT_BRANDS';
export const GET_BRANDS = 'GET_BRANDS';
export const FILTER_BRAND = 'FILTER_BRAND';
export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';

export const getBrandDetail = brandId => async (dispatch) => {
  await dispatch({
    type: GET_BRAND,
    payload: request('get', API.BRAND_DETAIL(brandId), {}),
    meta: { params: {} },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getStatBrands = (params = {}) => (dispatch) => {
  dispatch({
    type: GET_STAT_BRANDS,
    payload: request('get', API.STAT_BRANDS, { params }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getBrands = (params = {}) => (dispatch) => {
  dispatch({
    type: GET_BRANDS,
    payload: request('get', API.BRANDS, { params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)) }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const filterBrandDetail = (params = {}) => (dispatch) => {
  dispatch({
    type: FILTER_BRAND,
    payload: {},
    meta: { params },
  });
};

export const brandRequest = data => async (dispatch) => {
  await dispatch({
    type: CREATE_BRAND_REQUEST,
    payload: request('post', API.BRAND_REQUEST, {
      data: {
        ...recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
        ...(data.logoFile && { logo_file: data.logoFile.image }),
      },
    }),
  }).catch((response) => {
    handleErrors({
      response: {
        status: response.response.status,
        data: recursiveFunc(mapKeys)(response.response.data, (v, k) => camelCase(k)),
      },
    });
  });
};
