import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { mpTrack } from 'utils/copenhagen';
import store from 'redux/store';
import { isString, isObjectLike, isEmpty } from 'lodash';
import { ALL_GENDER, AVAILABLE_FOLLOWER_MAX, PLATFORMS } from '../../constants/options';
import { mergeSearchParams, setSearchParam } from '../searchParams/actions';
import { convertKeyword } from '../utils';

export const GET_INFLUENCERS = 'GET_INFLUENCERS';
export const GET_INFLUENCER_PROFILE = 'GET_INFLUENCER_PROFILE';
export const GET_INFLUENCER_TOP_POSTS = 'GET_INFLUENCER_TOP_POSTS';
export const GET_INFLUENCER_TOP_CONTENT = 'GET_INFLUENCER_TOP_CONTENT';
export const GET_INFLUENCER_SPONSORED_POSTS = 'GET_INFLUENCER_SPONSORED_POSTS';
export const REQUEST_NEW_INFLUENCERS = 'REQUEST_NEW_INFLUENCERS';
export const UPDATE_ERROR_STATE = 'UPDATE_ERROR_STATE';

const followerRangeValue = range => [range.min, range.max === AVAILABLE_FOLLOWER_MAX ? null : range.max];

export const convertSearchParams = ({
  categories,
  igFollowerRange,
  piFollowerRange,
  ttFollowerRange,
  ytFollowerRange,
  gender,
  keywords,
  labels,
  captions,
  username,
  usernameExact,
  platforms,
  locations,
  locationRadius,
  audienceGender,
  countries,
  page,
  limit,
  sort,
}) => {
  const data = {
    page,
    limit,
    sort,
  };

  if (username && username.value) {
    data.username = isString(username.value) ? username.value : username.value.value;
    data.username_exact = usernameExact;
    return data;
  }

  data.keywords = keywords.map(convertKeyword);
  data.labels = labels.map(value => ({ text: isString(value.value) ? value.value : value.value.text }));
  data.captions = !isEmpty(captions) ? captions.map(value =>
    ({ text: isString(value.value) ? value.value : value.value.text })) : null;
  data.genders = gender === ALL_GENDER ? null : [gender];
  data.categories = categories.map(value => value.value);
  data.platforms = platforms;
  data.locations = locations.map(value => ({ ...(isObjectLike(value.value) ? value.value : {}) }));
  data.location_radius = locationRadius;
  data.audience_gender = audienceGender === ALL_GENDER ? null : audienceGender;
  data.countries = countries.map(value => (isString(value.value) ? value.value : value.value.text));

  if (platforms.includes(PLATFORMS.INSTAGRAM)) {
    data.ig_follower_range = followerRangeValue(igFollowerRange);
  }

  if (platforms.includes(PLATFORMS.PINTEREST)) {
    data.pi_follower_range = followerRangeValue(piFollowerRange);
  }

  if (platforms.includes(PLATFORMS.TIKTOK)) {
    data.tt_follower_range = followerRangeValue(ttFollowerRange);
  }

  if (platforms.includes(PLATFORMS.YOUTUBE)) {
    data.yt_follower_range = followerRangeValue(ytFollowerRange);
  }

  data.is_brand = false;
  return data;
};

export const getRecommendedInfluencers = influencerId => async (dispatch) => {
  await dispatch(setSearchParam('isRecommendation', true));
  mpTrack('recommend__influencers', influencerId);
  await dispatch({
    type: GET_INFLUENCERS,
    payload: request('post', API.INFLUENCERS_RECOMMEND, {
      data: { influencer_id: influencerId },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getInfluencers = data => async (dispatch) => {
  await dispatch(mergeSearchParams(data));
  await dispatch(setSearchParam('isRecommendation', false));
  const { isAuthenticated } = store.getState().auth;
  mpTrack('search__influencers', data);
  await dispatch({
    type: GET_INFLUENCERS,
    payload: request('post', isAuthenticated ? API.INFLUENCERS_SEARCH : API.INFLUENCERS_DEFAULT_SEARCH, {
      data: convertSearchParams(data),
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const updateErrorState = errorFlag => (dispatch) => {
  dispatch({
    type: UPDATE_ERROR_STATE,
    key: 'error',
    value: errorFlag,
  });
};

export const getInfluencerProfile = influencerId => (dispatch) => {
  // clear out top post redux store
  mpTrack('load__profile', { influencerId });
  dispatch({
    type: GET_INFLUENCER_TOP_POSTS,
    posts: [],
  });
  dispatch({
    type: GET_INFLUENCER_SPONSORED_POSTS,
    posts: [],
  });
  dispatch({
    type: GET_INFLUENCER_PROFILE,
    payload: request('post', API.INFLUENCERS_PROFILE, { data: { influencer_id: influencerId } }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const destroyInfluencerPosts = () => (dispatch) => {
  // clear out top post redux store
  dispatch({
    type: GET_INFLUENCER_TOP_POSTS,
    posts: [],
  });
  dispatch({
    type: GET_INFLUENCER_SPONSORED_POSTS,
    posts: [],
  });
};

export const getInfluencerTopContent = (influencerId, params) => (dispatch) => {
  dispatch({
    type: GET_INFLUENCER_TOP_CONTENT,
    payload: request('post', API.INFLUENCERS_PROFILE_TOP_CONTENT, {
      data: {
        ...params,
        influencer_id: influencerId,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getInfluencerTopPost = (influencerId, params) => (dispatch) => {
  mpTrack('load__profile--influencers_top_post', { influencerId, ...params });
  dispatch({
    type: GET_INFLUENCER_TOP_POSTS,
    payload: request('post', API.INFLUENCERS_PROFILE_TOP_POST, {
      data: {
        ...params,
        influencer_id: influencerId,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getInfluencerSponsoredPost = (influencerId, tag) => (dispatch) => {
  mpTrack('load__profile--influencers_sponsored_post', { influencerId, tag });
  dispatch({
    type: GET_INFLUENCER_SPONSORED_POSTS,
    payload: request('post', API.INFLUENCERS_PROFILE_SPONSORED_POST, {
      data: {
        tag,
        influencer_id: influencerId,
      },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const requestNewInfluencers = influencers => async () => {
  mpTrack('request_new_influencers', { influencers });
  await request('post', API.REQUEST_NEW_INFLUENCERS, {
    data: {
      influencers,
    },
  }).catch((response) => {
    handleErrors(response);
  });
};
