import { toast } from 'react-toastify';
import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { fetchConfig } from 'redux/config/actions';
import { fetchUserProfile } from 'redux/user/actions';
import { recursiveFunc } from 'utils/mixins';
import { snakeCase, mapKeys } from 'lodash';
import { getMixpanelAnonId, pushSignUpEvent } from 'utils/copenhagen';

export const LOGIN = 'LOGIN';
export const GET_USER = 'GET_USER';
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const REGISTER_INFLUENCER = 'REGISTER_INFLUENCER';
export const REGISTER_PRISM_USER = 'REGISTER_PRISM_USER';

export const getUser = () => async (dispatch) => {
  await dispatch({
    type: GET_USER,
    payload: request('get', API.GET_USER, {}),
  }).catch((response) => {
    try {
      handleErrors(response);
    } catch (error) {
      // DO nothing since if the user is not logged in it will reutrn 401
    }
  });
};

export const login = (email, password) => async (dispatch) => {
  await dispatch({
    type: LOGIN,
    payload: request('post', API.LOGIN, {
      data: {
        email,
        password,
        $anon_id: getMixpanelAnonId(),
      },
      headers: {},
    }),
  }).catch((response) => {
    handleErrors(response);
  });
  await fetchUserProfile()(dispatch);
  await fetchConfig()(dispatch);
};


export const register = data => async (dispatch) => {
  await dispatch({
    type: REGISTER_PRISM_USER,
    data,
    payload: request('post', API.CREATE_USER, {
      data: {
        ...recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
        $anon_id: getMixpanelAnonId(),
      },
      headers: {},
    }),
  }).then(() => pushSignUpEvent())
    .catch((response) => {
      handleErrors(response);
    });
};

export const registerInfluencer = (email, password) => async (dispatch) => {
  await dispatch({
    type: REGISTER_INFLUENCER,
    payload: request('post', API.CREATE_INFLUENCER_USER, {
      data: {
        email,
        password,
        $anon_id: getMixpanelAnonId(),
      },
      headers: {},
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const logout = () => async (dispatch) => {
  await dispatch({
    type: LOGOUT,
    payload: request('get', API.LOGOUT, {}),
  }).catch(() => {
    toast.error('Logout Failed');
  });
};

export const clearAuth = (dispatch) => {
  dispatch({
    type: CLEAR_AUTH,
  });
};

