export default () => ({
  root: {
    position: 'relative',
  },
  blurContainer: {
    filter: props => (props.requireUpgrade ? 'blur(10px)' : 'none'),
  },
  buttonContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonCard: {
    width: '80%',
    padding: '3rem',
  },
});
