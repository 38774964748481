import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import { withStyles } from '@material-ui/styles';
import {
  Tabs,
  CardContent,
} from '@material-ui/core';
import Tab from 'components/base/Tab';
import LineBasic from 'components/base/charts/Line/LineBasic';
import BarGradient from 'components/base/charts/Bar/BarGradient';
import { slice } from 'lodash';
import * as moment from 'moment';
import { connect } from 'react-redux';
import TabPanel from 'components/base/tabs/TabPanel';
import { a11yProps } from 'components/base/tabs/utils';
import { groupTimelineDataByMonthForBarChart } from 'lib/utils';
import AvgStatCtrl from 'components/common/AvgStatCtrl';

const styles = () => ({
  root: {
    position: 'relative',
  },
  tabContainer: {
    padding: '0 1rem',
    position: 'relative',
    zIndex: 1,
  },
});

const TABS = {
  POST_ACTIVATIONS: 0,
  POST_CREATORS: 1,
  POST_ENGAGEMENT: 2,
};

const MA_DAYS = 28;

const getLineChartOptions = () => ({
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: false,
      },
    }],
    xAxes: [{
      display: true,
      ticks: {
        autoSkipPadding: 7,
      },
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
    }],
  },
});

class HistoricGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: TABS.POST_ACTIVATIONS,
    };
  }
  getTabs() {
    const { classes } = this.props;
    const { tabValue } = this.state;
    return (
      <Tabs value={tabValue} onChange={this.handleChange} indicatorColor="primary">
        <Tab className={classes.tab} label="Post Activations" {...a11yProps(TABS.POST_ACTIVATIONS)} />
        <Tab className={classes.tab} label="Post Creators" {...a11yProps(TABS.POST_CREATORS)} />
        <Tab className={classes.tab} label="Post Engagements" {...a11yProps(TABS.POST_ENGAGEMENT)} />
      </Tabs>
    );
  }
  transformPostActivationData = (data) => {
    const { theme } = this.props;
    return {
      labels: slice(data.dates.map(value => moment(value).format('MMM DD, YYYY')), MA_DAYS),
      datasets: [{
        data: data.dailyPostCountsMa,
        borderWidth: 3,
        backgroundColor: 'transparent',
        borderColor: theme.palette.green.main,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      }],
    };
  };
  transformPostCreators = (data) => {
    const { theme } = this.props;
    const chartData = groupTimelineDataByMonthForBarChart(data.dates, data.dailyCreatorCounts);
    return {
      labels: chartData.dates,
      datasets: [{
        data: chartData.values,
        borderWidth: 0,
        borderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      }, {
        data: chartData.maxList,
        borderWidth: 0,
        borderColor: 'transparent',
        backgroundColor: theme.palette.barChart.gradient.gray,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      }],
    };
  };

  transformPostEngagementData = (data) => {
    const { theme } = this.props;
    return {
      labels: slice(data.dates.map(value => moment(value).format('MMM DD, YYYY')), MA_DAYS),
      datasets: [{
        data: data.dailyEngagementsMa,
        borderWidth: 3,
        backgroundColor: theme.palette.lightGreen.main,
        borderColor: theme.palette.green.main,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      }],
    };
  };

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  render() {
    const { classes, data } = this.props;
    const { tabValue } = this.state;
    return (
      <Card className={classes.root}>
        <div className={classes.tabContainer}>
          {this.getTabs()}
        </div>
        <TabPanel value={tabValue} index={TABS.POST_ACTIVATIONS}>
          <CardContent>
            <AvgStatCtrl statList={data.dailyPostCountsMa} isWideScreen="true" />
            <LineBasic
              data={this.transformPostActivationData(data)}
              rawData={data.dailyPostCountsMa}
              height="100px"
              options={getLineChartOptions()}
            />
          </CardContent>
        </TabPanel>
        <TabPanel value={tabValue} index={TABS.POST_CREATORS}>
          <CardContent>
            <BarGradient
              data={this.transformPostCreators(data)}
            />
          </CardContent>
        </TabPanel>
        <TabPanel value={tabValue} index={TABS.POST_ENGAGEMENT}>
          <CardContent>
            <AvgStatCtrl statList={data.dailyEngagementsMa} isWideScreen="true" />
            <LineBasic
              data={this.transformPostEngagementData(data)}
              height="100px"
              options={getLineChartOptions()}
            />
          </CardContent>
        </TabPanel>
      </Card>
    );
  }
}

HistoricGraph.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ brand }) => ({
  data: brand.data,
});

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(HistoricGraph));
