import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { normalizeNumber } from 'utils/helpers';
import { max, min } from 'lodash';
import * as d3 from 'd3';
import ReactDOMServer from 'react-dom/server';

const calculateRadius = (bubbleMaxSize, bubbleMinSize, maxVal, minVal, value) =>
  ((bubbleMaxSize - bubbleMinSize) * normalizeNumber(value, maxVal, minVal)) + bubbleMinSize;

const setCircleMinMax = (nodeCount, canvasWidth, canvasHeight, nodePadding, bubbleMaxSize) => {
  // get canvas area, add total padding from all nodes, then devide by node count then find the square root
  // eslint-disable-next-line
  const circleMaxSize = Math.floor(Math.sqrt((canvasWidth * canvasHeight + (nodePadding * 4 * nodeCount)) / 2 / nodeCount)) / 2;

  return {
    // min node size should be 50% of max size
    min: Math.floor(circleMaxSize * 0.5),
    max: min([circleMaxSize, bubbleMaxSize]),
  };
};

// Show label if value larger than this
const LABEL_VAL_SIZE = 35;

class Force extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.svg = ReactDOM.findDOMNode(this);
    this.renderChart();
  }

  componentDidUpdate() {
    const {
      width,
      height,
    } = this.props;
    if (width !== 0 && height !== 0) {
      this.renderChart();
    }
  }

  componentWillUnmount() {
    d3.select('body').selectAll('.tooltip').remove();
  }

  showLabel = val => val > LABEL_VAL_SIZE;

  dragstart(d) {
    // eslint-disable-next-line
    d3.select(this).classed('fixed', d.fixed = true);
  }

  dblclick(d) {
    // eslint-disable-next-line
    d3.select(this).classed('fixed', d.fixed = false);
  }

  // Resolves collisions between d and all other circles.
  collide(alpha) {
    const {
      clusterPadding,
      padding,
      bubbleMaxSize,
      data,
    } = this.props;
    const quadtree = d3.geom.quadtree(data.nodes);
    return (d) => {
      const r = d.radius + bubbleMaxSize + Math.max(padding, clusterPadding);
      const nx1 = d.x - r;
      const nx2 = d.x + r;
      const ny1 = d.y - r;
      const ny2 = d.y + r;
      quadtree.visit((quad, x1, y1, x2, y2) => {
        if (quad.point && quad.point !== d) {
          let x = d.x - quad.point.x;
          let y = d.y - quad.point.y;
          let l = Math.sqrt((x * x) + (y * y));
          const rad = d.radius + quad.point.radius + (d.cluster === quad.point.cluster ? padding : clusterPadding);
          if (l < rad) {
            l = ((l - rad) / l) * alpha;
            // eslint-disable-next-line
            d.x -= x *= l;
            // eslint-disable-next-line
            d.y -= y *= l;
            // eslint-disable-next-line
            quad.point.x += x;
            // eslint-disable-next-line
            quad.point.y += y;
          }
        }
        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    };
  }

  renderChart = () => {
    const {
      data,
      width,
      height,
      charge,
      gravity,
      padding,
      linkDistance,
      fontMaxSize,
      fontMinSize,
      bubbleMaxSize,
      gravityCenter,
    } = this.props;
    const dataValues = data.nodes.map(item => item.value);
    const maxVal = max(dataValues);
    const minVal = dataValues.length > 1 ? min(dataValues) : 1;
    this.svg.innerHTML = '';
    // Allow bubbles overflowing its SVG container in visual aspect if props(overflow) is true.
    this.svg.style.overflow = 'visible';

    const nodeSizeScale = setCircleMinMax(data.nodes.length, width, height, padding, bubbleMaxSize);

    d3.select(this.svg);

    const force = d3.layout.force()
      .charge(charge)
      .chargeDistance(10)
      .linkDistance(linkDistance)
      .gravity(gravity)
      .size([width, height]);

    const drag = force.drag()
      .on('dragstart', this.dragstart);

    const linkedByIndex = {};
    data.links.forEach((d) => {
      linkedByIndex[`${d.source},${d.target}`] = d.usage;
    });

    const svg = d3.select(this.svg)
      .attr('width', width)
      .attr('height', height);
    force
      .nodes(data.nodes)
      .links(data.links)
      .start();

    const tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    // Create the svg:defs element and the main gradient definition.
    const svgDefs = svg.append('defs');

    const mainGradient = svgDefs.append('linearGradient')
      .attr('id', 'igGradient');

    // Create the stops of the main gradient. Each stop will be assigned
    // a class to style the stop using CSS.
    mainGradient.append('stop')
      .style('stop-color', '#fabd6d')
      .attr('offset', '0');

    mainGradient.append('stop')
      .style('stop-color', '#d4366b')
      .attr('offset', '0.5');

    mainGradient.append('stop')
      .style('stop-color', '#9d40b8')
      .attr('offset', '1');

    const node = svg.selectAll('.node')
      .data(data.nodes)
      .enter().append('circle')
      .attr('class', 'node')
      .attr('r', (d) => {
        const circleSize = calculateRadius(nodeSizeScale.max, nodeSizeScale.min, maxVal, minVal, d.value);
        data.nodes[d.index].radius = circleSize;
        return circleSize;
      })
      .style('fill', d => d.color)
      .style('cursor', 'pointer');

    const icons = svg.selectAll('.label-icon')
      .data(data.nodes).enter()
      .append('foreignObject')
      // eslint-disable-next-line
      .attr('class', d => this.showLabel(d.radius) ? 'label-icon has-label' : 'label-icon')
      .attr('width', d => d.radius * 2)
      .attr('height', d => d.radius * 2)
      .style('font-weight', 600)
      .style('color', d => d.textColor)
      .style('font-size', d => `${d.radius}px`)
      .attr('text-anchor', 'middle')
      .html(d => ReactDOMServer.renderToString(d.icon))
      .on('mouseover', (d) => {
        try {
          tooltip.transition()
            .duration(200)
            .style('opacity', 0.9);
          tooltip.html(d.popoverLabel)
            .style('left', `${d3.event.pageX}px`)
            .style('top', `${(d3.event.pageY - 28)}px`);
          // eslint-disable-next-line no-empty
        } catch (err) {

        }
      })
      .on('mouseout', () => {
        try {
          tooltip.transition()
            .duration(500)
            .style('opacity', 0);
          // eslint-disable-next-line no-empty
        } catch (err) {

        }
      })
      .call(force.drag)
      .call(drag);

    const labelCounts = svg.selectAll('text.label-counts')
      .data(data.nodes)
      .enter().append('text')
      .attr('class', 'label-counts')
      .style({
        'fill-opacity': 1,
        'font-weight': 600,
      })
      .style('fill', d => d.textColor)
      .style('font-size', d => calculateRadius(fontMaxSize, fontMinSize, maxVal, minVal, d.value) - 8)
      // eslint-disable-next-line
      .style('opacity', d => this.showLabel(d.radius) ? 1 : 0)
      .attr('text-anchor', 'middle')
      .text(d => d.label);

    force.on('tick', (e) => {
      const k = 0.1 * e.alpha;

      node
        .each(this.collide(0.5))
        .attr('cx', (d) => {
          // eslint-disable-next-line
          d.x = Math.max(d.radius, Math.min(width - d.radius, d.x));
          if (gravityCenter) {
            // eslint-disable-next-line
            d.x += (gravityCenter.x - d.x) * k;
          }
          return d.x;
        })
        .attr('cy', (d) => {
          // eslint-disable-next-line
          d.y = Math.max(d.radius, Math.min(height - d.radius, d.y));
          if (gravityCenter) {
            // eslint-disable-next-line
            d.y += (gravityCenter.y - d.y) * k;
          }
          return d.y;
        });

      icons.attr('transform', d => `translate(${d.x - d.radius}, ${d.y - d.radius})`);

      labelCounts.attr('transform', d => `translate(${d.x}, ${d.y + (d.radius / 1.6)})`);
    });
  }


  render() {
    const {
      width,
      height,
    } = this.props;
    return (
      <svg width={width} height={height} />
    );
  }
}

Force.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  padding: PropTypes.number,
  clusterPadding: PropTypes.number,
  charge: PropTypes.number,
  gravity: PropTypes.number,
  linkDistance: PropTypes.number,
  bubbleMaxSize: PropTypes.number,
  fontMaxSize: PropTypes.number,
  fontMinSize: PropTypes.number,
  gravityCenter: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

Force.defaultProps = {
  padding: 10,
  clusterPadding: 15,
  charge: -100,
  gravity: 0.1,
  linkDistance: 100,
  bubbleMaxSize: 40,
  fontMaxSize: 23,
  fontMinSize: 13,
  gravityCenter: null,
};

export default Force;
