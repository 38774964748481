
// eslint-disable-next-line import/prefer-default-export
export const serverUrl = (path) => {
  const base = process.env.API_BASE;
  let url = null;
  if (base) {
    // the url is relative unless a base is configured
    url = `${base}${path}/`;
  }
  return url;
};
