import React from 'react';
import PropTypes from 'prop-types';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { getCompetitiveReportPosts as getCompetitiveReportPostsAction } from 'redux/reports/actions';
import { withRouter } from 'react-router';
import {
  POST_SORT_OPTIONS_LABELS,
} from 'components/common/CompetitiveReport/constants';
import { getDaysFromDate, getDateFromDays } from 'utils/helpers';
import SortOptions from 'components/common/CompetitiveReport/SortOptions';
import PlatformsFilter from 'components/common/CompetitiveReport/PlatformFilter';
import BrandFilter from 'components/common/CompetitiveReport/BrandFilter';
import SponsoredFilter from 'components/common/CompetitiveReport/SponsoredFilter';
import DateFilter from 'components/common/DateFilter';
import { xor } from 'lodash';

const Sort = ({
  params,
  getCompetitiveReportPosts,
  match: { params: { reportId } },
}) => {
  const handleSortSelect = (sort) => {
    getCompetitiveReportPosts(
      reportId,
      { ...params, sort },
    );
  };

  return (<SortOptions
    handleSelect={handleSortSelect}
    selectedValue={params.sort}
    options={POST_SORT_OPTIONS_LABELS}
  />);
};

const PlatformSelect = ({
  params,
  getCompetitiveReportPosts,
  match: { params: { reportId } },
}) => {
  const handlePlatformSelect = (platformSelected) => {
    const { platforms } = params;
    getCompetitiveReportPosts(
      reportId,
      {
        ...params,
        platforms: xor(platforms, [platformSelected]),
      },
    );
  };

  return (<PlatformsFilter handleSelect={handlePlatformSelect} params={params} />);
};

const DateSelect = ({
  params,
  getCompetitiveReportPosts,
  match: { params: { reportId } },
}) => {
  const handleDateSelect = (fromDate, toDate) => {
    getCompetitiveReportPosts(
      reportId,
      {
        ...params,
        postedWithin: getDaysFromDate(fromDate),
        postedBefore: getDaysFromDate(toDate),
      },
    );
  };

  return (<DateFilter
    handleSelect={handleDateSelect}
    selectedFromDate={getDateFromDays(params.postedWithin)}
    selectedToDate={getDateFromDays(params.postedBefore)}
    customDate
  />);
};

const BrandSelect = ({
  params,
  getCompetitiveReportPosts,
  match: { params: { reportId } },
}) => {
  const handleBrandSelect = (brandsSelected) => {
    const { brands } = params;
    getCompetitiveReportPosts(
      reportId,
      {
        ...params,
        brands: xor(brands, [brandsSelected]),
      },
    );
  };

  const combinedMentionsSelected = () => {
    const { combinedMentions } = params;
    getCompetitiveReportPosts(
      reportId,
      {
        ...params,
        combinedMentions: !combinedMentions,
      },
    );
  };

  return (<BrandFilter
    handleSelect={handleBrandSelect}
    combinedMentionsSelected={combinedMentionsSelected}
    params={params}
    showCombinedMentions
  />);
};

const SponsoredSelect = ({
  params,
  getCompetitiveReportPosts,
  match: { params: { reportId } },
}) => {
  const handleSponsoredSelect = (options) => {
    const { isSponsored, ...otherParams } = params;
    getCompetitiveReportPosts(
      reportId,
      {
        ...otherParams,
        ...options.sponsored ? { isSponsored: true } : {},
        ...options.organic ? { isSponsored: false } : {},
      },
    );
  };

  return (<SponsoredFilter handleSelect={handleSponsoredSelect} params={params} />);
};

const propTypes = {
  getCompetitiveReportPosts: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

Sort.propTypes = propTypes;
PlatformSelect.propTypes = propTypes;
DateSelect.propTypes = propTypes;
BrandSelect.propTypes = propTypes;
SponsoredSelect.propTypes = propTypes;

const mapStateToProps = ({ competitiveReportPosts }) => ({
  params: competitiveReportPosts.params,
});

export const HeaderSort = withAll(
  connect(mapStateToProps, { getCompetitiveReportPosts: getCompetitiveReportPostsAction }),
  withRouter,
)(Sort);

export const HeaderPlatformFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportPosts: getCompetitiveReportPostsAction }),
  withRouter,
)(PlatformSelect);

export const HeaderBrandFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportPosts: getCompetitiveReportPostsAction }),
  withRouter,
)(BrandSelect);

export const HeaderSponsoredFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportPosts: getCompetitiveReportPostsAction }),
  withRouter,
)(SponsoredSelect);

export const HeaderDateFilter = withAll(
  connect(mapStateToProps, { getCompetitiveReportPosts: getCompetitiveReportPostsAction }),
  withRouter,
)(DateSelect);
