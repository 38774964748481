import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Subscription from '../Subscription';
import styles from './styles';
import {
  creatorDiscoveryTitle,
  industryTrendsTitle,
  monitorTitle,
  brandSafetyTitle,
  competitorReportTitle,
  exportTitle,
} from '../constants';

const Plan = ({ classes, plan }) => (
  <div className={classes.root}>
    <Typography className={classes.name} variant="h5">{plan.name}</Typography>
    {plan.settings.isMostPopular ? (
      <div className={classes.mostPopularBanner}>
        <Typography className={classes.mostPopularText} variant="body1">Most Popular</Typography>
      </div>
    ) : (
      <div className={classes.mostPopularPlaceholder} />
    )}
    <div className={classes.subTitle}>Creator Discovery
      <Tooltip title={creatorDiscoveryTitle()}>
        <InfoIcon fontsize="small" color="primary" />
      </Tooltip>
    </div>

    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Search Millions of Unique Creators
      </div>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        {plan.settings.searchLimit.toLocaleString()} Monthly Searches
      </div>
    </div>
    <div className={classes.planSetting}>
      <div>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        {/* eslint-disable-next-line max-len */}
        {plan.settings.savedListLimit.toLocaleString()} Saved Lists, {plan.settings.influencersPerListLimit.toLocaleString()} Creators Max
      </div>
    </div>
    <div className={classes.planSetting}>
      <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
      {plan.settings.canAccessFullDemographics ? 'Full' : null} Audience Demographics
    </div>
    {plan.settings.canAccessCreatorTopContent ?
      <div className={classes.planSetting}>
        <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
        Creators Top Content
      </div> :
      null
    }


    <div className={classes.subTitle}>Industry Trends Access
      <Tooltip title={industryTrendsTitle()}>
        <InfoIcon fontsize="small" color="primary" />
      </Tooltip>
    </div>

    {plan.settings.canAccessTopCreatorsAndContent ?
      <div className={classes.planSetting}>
        <div><FontAwesomeIcon className={classes.checkMark} icon={faCheck} />Top Creators And Content</div>
      </div>
      : null
    }
    {plan.settings.canAccessBrandInsights ?
      <div className={classes.planSetting}>
        <div><FontAwesomeIcon className={classes.checkMark} icon={faCheck} />Brand Insights</div>
      </div>
      : null
    }

    {plan.settings.creatorListeningMonitorsLimit > 0 ?
      <div className={classes.subTitle}>
        {plan.settings.creatorListeningMonitorsLimit} Creator Listening
        {plan.settings.creatorListeningMonitorsLimit === 1 ? ' Monitor' : ' Monitors'}
        <Tooltip title={monitorTitle()}>
          <InfoIcon fontsize="small" color="primary" />
        </Tooltip>
      </div>
      :
      null
    }
    {plan.settings.brandSafetyReportsLimit > 0 ?
      <div className={classes.subTitle}>
        {plan.settings.brandSafetyReportsLimit} Brand Safety
        {plan.settings.brandSafetyReportsLimit === 1 ? ' Check' : ' Checks'}
        <Tooltip title={brandSafetyTitle()}>
          <InfoIcon fontsize="small" color="primary" />
        </Tooltip>
      </div>
      : null
    }

    {plan.settings.reportsLimit > 0 ?
      <div className={cx(classes.subTitle, 'first')}>
        {plan.settings.reportsLimit === 1 ?
          `${plan.settings.reportsLimit} Competitive Report` :
          `${plan.settings.reportsLimit} Competitive Reports`}
        <Tooltip title={competitorReportTitle()}>
          <InfoIcon fontsize="small" color="primary" />
        </Tooltip>
      </div>
      : null
    }

    {
      plan.settings.canAccessDataExports ?
        <div className={classes.subTitle}>Data Exports
          <Tooltip title={exportTitle()}>
            <InfoIcon fontsize="small" color="primary" />
          </Tooltip>
        </div>
        :
        null
    }
    <div className={classes.pushUp} />

    <div className={classes.subscriptions}>
      {
        plan.subscriptions.map(s => (
          <Subscription
            key={s.subscriptionId}
            subscription={s}
          />
        ))
      }
    </div>
  </div >
);

Plan.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  plan: PropTypes.shape({
    planId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    highlights: PropTypes.arrayOf(PropTypes.string),
    highlightsReports: PropTypes.arrayOf(PropTypes.string),
    settings: PropTypes.shape({
      brandSafetyReportsLimit: PropTypes.number,
      creatorListeningMonitorsLimit: PropTypes.number,
      influencersPerListLimit: PropTypes.number,
      isMostPopular: PropTypes.bool,
      reportsLimit: PropTypes.number,
      resultsLimit: PropTypes.number,
      savedListLimit: PropTypes.number,
      searchLimit: PropTypes.number,
    }).isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      interval: PropTypes.string,
      price: PropTypes.number,
      subscriptionId: PropTypes.number,
    })).isRequired,
  }).isRequired,
};

export default withStyles(styles)(Plan);
