import React from 'react';
import PropTypes from 'prop-types';
import UpdateReport from 'components/common/CompetitiveReportCreateUpdate/UpdateReport';
import { nonExisting } from 'constants/routing';
import PageLayout from 'components/base/Layout/PageLayout';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { getCompetitiveReportDetail } from 'redux/reports/actions';

class CompetitiveReportsUpdate extends React.Component {
  async componentDidMount() {
    const { reportId } = this.props.match.params;
    try {
      await this.props.getCompetitiveReportDetail(reportId);
    } catch (e) {
      window.location.replace(nonExisting());
    }
  }

  render() {
    const {
      isLoading,
      loaded,
    } = this.props;
    return (
      <PageLayout>
        <Loader loading={isLoading} loaded={loaded}>
          <UpdateReport />
        </Loader>
      </PageLayout>
    );
  }
}
CompetitiveReportsUpdate.propTypes = {
  getCompetitiveReportDetail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = ({ competitiveReport }) => ({
  isLoading: competitiveReport.loading,
  loaded: competitiveReport.loaded,
});

export default withAll(connect(mapStateToProps, { getCompetitiveReportDetail }))(CompetitiveReportsUpdate);
