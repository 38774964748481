import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import Card from 'components/base/Card';
import GaugeSimple from 'components/base/charts/GaugeSimple';
import AnomalyPostIndex from 'components/app/BrandSafetyReports/ReportList/AnomalyPostIndex';
import BrandSafetyIndex from 'components/app/BrandSafetyReports/ReportList/BrandSafetyIndex';
import SafetyLegend from 'components/app/BrandSafetyReports/SafetyLegend';
import { TABS } from 'components/app/BrandSafetyReport/constants';
import styles from './styles';

const brandSafetyIndexPercentage = {
  UNDEFINED: 0,
  HIGH_RISK: 0.15,
  MEDIUM_RISK: 0.5,
  LOW_RISK: 0.85,
};

const Scorecard = ({
  classes,
  report,
  setTab,
  canViewUnusualPostIndex,
}) => (
  <div className={classes.root}>
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4} className={classes.gaugeItem}>
            <GaugeSimple
              id="potentiallyObjectionableContent"
              label="Potentially Objectionable Content"
              percent={report.brandSafetyIndex ? brandSafetyIndexPercentage[report.brandSafetyIndex] : 0}
            />
            <BrandSafetyIndex className={classes.brandSafetyIndex} report={report} />
            <Button
              variant="text"
              className={classes.detailsBtn}
              onClick={() => setTab(TABS.BRAND_SAFETY)}
            >
              Details
            </Button>
          </Grid>
          {
            canViewUnusualPostIndex && (
              <Grid item md={4} className={classes.gaugeItem}>
                <GaugeSimple
                  id="unusualPostEngagement"
                  label="Unusual Post Engagement"
                  percent={report.anomalyPostIndex ? brandSafetyIndexPercentage[report.anomalyPostIndex] : 0}
                />
                <AnomalyPostIndex className={classes.brandSafetyIndex} report={report} />
                <Button
                  variant="text"
                  className={classes.detailsBtn}
                  onClick={() => setTab(TABS.UNUSUAL_POST_ENGAGEMENT)}
                >
                  Details
                </Button>
              </Grid>
            )
          }
          <Grid item md={12} className={classes.note}>
            Scoring based on an analysis of historic post activity across the specified platforms
            <SafetyLegend className={classes.safetyLegend} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </div>
);

Scorecard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    influencerId: PropTypes.string,
    anomalyPostIndex: PropTypes.string,
    brandSafetyIndex: PropTypes.string,
  }).isRequired,
  setTab: PropTypes.func.isRequired,
  canViewUnusualPostIndex: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Scorecard);
