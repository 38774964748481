export default () => ({
  keywordFormControl: {
    width: '100%',
    marginTop: '1rem',
  },
  keywordContainer: {
    display: 'flex',
  },
  keywordSelect: {
    width: '80%',
  },
  keywordLogic: {
    width: '20%',
  },
  keywordLogicItem: {
    height: '2.6rem',
    width: '50%',
  },
});
