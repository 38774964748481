import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as React from 'react';

const MenuOption = (props) => {
  const {
    icon,
    label,
    onClick,
    className,
  } = props;
  return (
    <MenuItem key={label} onClick={onClick} selected={false} className={className}>
      { icon ?
        <ListItemIcon>
          {icon}
        </ListItemIcon> : null
      }
      <Typography>
        {label}
      </Typography>
    </MenuItem>
  );
};

MenuOption.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MenuOption.defaultProps = {
  icon: null,
  className: null,
};

export default MenuOption;
