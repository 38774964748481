import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AsyncMultiSelect from 'components/base/AsyncMultiSelect';
import { getUsernameAutoComplete } from 'redux/autocompletes/actions';

const MINIMUM_INPUT_CHARS = 3;

class UsernameAutoComplete extends Component {
  loadOptions = async (inputValue, callback) => {
    if (inputValue.length < MINIMUM_INPUT_CHARS) {
      callback([]);
      return;
    }
    await this.props.getUsernameAutoComplete(inputValue);
    callback(this.props.usernameAutoComplete.results);
  }

  render() {
    const { onChange, username } = this.props;
    const value = username ? [username] : [];
    return (
      <div>
        <AsyncMultiSelect
          name="username"
          value={value}
          placeholder="Find Usernames"
          note="Entering a username will ignore any other search filters"
          onChange={onChange}
          loadOptions={this.loadOptions}
          minChars={MINIMUM_INPUT_CHARS}
          isMulti={false}
          isClearable
        />
      </div>
    );
  }
}

UsernameAutoComplete.propTypes = {
  getUsernameAutoComplete: PropTypes.func.isRequired,
  username: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({}),
  }),
  usernameAutoComplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
};

UsernameAutoComplete.defaultProps = {
  onChange: null,
  username: null,
};

export default connect(
  state => ({
    usernameAutoComplete: state.usernameAutoComplete,
  }),
  { getUsernameAutoComplete },
)(UsernameAutoComplete);
