import React from 'react';
import PropTypes from 'prop-types';
import SearchSection from './SearchSection';
import RelatedKeywords from './RelatedKeywords';
import KeywordAutoComplete from './KeywordAutoComplete';
import withSearchParam from '../withSearchParam';

class KeywordFilter extends React.PureComponent {
  handleRemoveKeyword = (keyword) => {
    const { input: { onChange, value } } = this.props;
    onChange(value.filter(v => v.value !== keyword));
  };

  render() {
    const { input: { onChange, value } } = this.props;
    return (
      <SearchSection
        id="keyword_search_filter"
        title="Keyword Search"
        tooltip="Find creators that have mentioned specific hashtags."
      >
        <KeywordAutoComplete
          keywords={value}
          onChange={onChange}
          handleRemoveKeyword={this.handleRemoveKeyword}
        />
        <RelatedKeywords />
      </SearchSection>
    );
  }
}

KeywordFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])).isRequired,
  }).isRequired,
};

export default withSearchParam('keywords')(KeywordFilter);
