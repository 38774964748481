import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { RankByField, RankByComparators, filterIfRankBySponsored } from 'components/app/BrandSafetyReport/utils';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import PlatformIcon from 'components/common/PlatformIcon';
import { RANK_ERROR_TEXT } from 'components/app/BrandSafetyReport/constants';
import { getBrandSafetyReportPosts as getBrandSafetyReportPostsAction } from 'redux/brandSafetyReports/actions';
import Post from '../Post';
import RankBy from '../RankBy';
import styles from './styles';

class BrandSafety extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedListIds: this.props.data.lists.map(l => l.id),
      selectedPlatforms: [...new Set(this.props.data.posts.map(p => p.platform))],
      rankByField: RankByField.Date,
    };
  }

  async componentDidMount() {
    const { report, getBrandSafetyReportPosts } = this.props;
    await getBrandSafetyReportPosts(report.id);
    this.updateStateFromProps();
  }

  onListChange = (event, checked) => {
    const listId = parseInt(event.target.value, 10);
    const { selectedListIds } = this.state;
    const index = selectedListIds.indexOf(listId);

    if (checked) {
      selectedListIds.push(listId);
    } else if (index > -1) {
      selectedListIds.splice(index, 1);
    }

    this.setState({
      selectedListIds,
    });
  };

  onRankChange = (rankBy) => {
    this.setState({ rankByField: rankBy });
  }

  onPlatformToggle = (platform, checked) => {
    const { selectedPlatforms } = this.state;
    if (checked) {
      selectedPlatforms.push(platform);
    } else {
      const index = selectedPlatforms.indexOf(platform);
      if (index > -1) {
        selectedPlatforms.splice(index, 1);
      }
    }
    this.setState({ selectedPlatforms });
  };

  updateStateFromProps() {
    const { data } = this.props;
    this.setState({
      selectedListIds: data.lists.map(l => l.id),
      selectedPlatforms: [...new Set(data.posts.map(p => p.platform))],
    });
  }

  render() {
    const {
      classes,
      data,
      isLoading,
      loaded,
    } = this.props;
    const { selectedListIds, selectedPlatforms, rankByField } = this.state;
    const platforms = [...new Set(data.posts.map(p => p.platform))];
    let posts = data.posts.filter(p =>
      selectedListIds.some(listId => p.objectionableListIds.includes(listId)) &&
      selectedPlatforms.includes(p.platform));

    posts = filterIfRankBySponsored(rankByField, posts, () => {
      toast.error(RANK_ERROR_TEXT);
      this.onRankChange(RankByField.Interactions);
    });
    posts.sort(RankByComparators[rankByField]);

    return (
      <div className={classes.root}>
        <Loader loading={isLoading} loaded={loaded}>
          {platforms.length ? (
            <div className={classes.header}>
              <div className={classes.postedOn}>
                <div className={classes.postedOnLabel}>Posted on:</div>
                {platforms.map(p => <PlatformIcon key={p} platform={p} onToggle={this.onPlatformToggle} />)}
              </div>
              <RankBy onRankChange={this.onRankChange} activeRank={rankByField} />
            </div>
          ) : null}
          {data.lists.length ? (
            <div className={classes.flaggedFor}>
              <div className={classes.flaggedForLabel}>Flagged for:</div>
              <FormGroup className={classes.flaggedForFormGroup}>
                {data.lists.map(l => (
                  <FormControlLabel
                    key={l.id}
                    className={classes.flaggedForCheckboxLabel}
                    control={<Checkbox
                      defaultChecked
                      color="primary"
                      value={l.id}
                      onChange={this.onListChange}
                    />}
                    label={l.label}
                  />
                ))}
              </FormGroup>
            </div>
          ) : null}
          <Grid container spacing={2}>
            {posts.length ? posts.map(p => (
              <Grid item md={3} key={p.postId}>
                <Post post={p} lists={data.lists} />
              </Grid>
            )) : (
              <Typography variant="h5" className={classes.noResults}>No results</Typography>
            )}
          </Grid>
        </Loader>
      </div>
    );
  }
}

BrandSafety.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  getBrandSafetyReportPosts: PropTypes.func.isRequired,
  data: PropTypes.shape({
    lists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ brandSafetyReportPosts }) => ({
  data: brandSafetyReportPosts.data,
  isLoading: brandSafetyReportPosts.loading,
  loaded: brandSafetyReportPosts.loaded,
});

export default withAll(
  withStyles(styles),
  connect(mapStateToProps, { getBrandSafetyReportPosts: getBrandSafetyReportPostsAction }),
)(BrandSafety);
