import { get } from 'lodash';
import { newReducer } from '../../utils';

const initialState = {
  influencers: {
    data: [],
    loading: false,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const influencerFeaturedReducer = newReducer(initialState);

influencerFeaturedReducer.method.FETCH_FEATURED_INFLUENCERS_PENDING = (state = initialState) => ({
  ...state,
  influencers: {
    data: [],
    loading: true,
  },
});

influencerFeaturedReducer.method.FETCH_FEATURED_INFLUENCERS_FULFILLED = (state = initialState, action) => ({
  ...state,
  influencers: {
    data: get(action.payload, 'influencers'),
    loading: false,
  },
});

influencerFeaturedReducer.method.FETCH_FEATURED_INFLUENCERS_REJECTED = (state = initialState) => ({
  ...state,
  influencers: {
    data: [],
    loading: false,
  },
});
