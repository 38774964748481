/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-chartjs-2';
import { withStyles } from '@material-ui/styles';
import { max, round } from 'lodash';
import Tooltip from 'components/base/Tooltip';
import { formatNumber } from 'utils/helpers';

const MIN_ALPHA = 0.05;
const MARGIN = 2;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tile: {
    position: 'relative',
    margin: `${MARGIN}px`,
    border: `1px solid ${theme.palette.white.main}`,
    boxSizing: 'border-box',
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },
  tileContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
});

const getBackgroundColor = (data, index) => {
  const value = data[index];
  const maxValue = max(data.filter(v => v.y === value.y).map(v => v.v));
  const alpha = max([MIN_ALPHA, value.v / maxValue]);
  return Chart.helpers.color(value.d.gridColor).alpha(alpha).rgbString();
};

const Grid = ({
  dataset,
  labels,
  classes,
  index,
}) => (
  <div
    className={classes.root}
  >
    {dataset[index].data.map((d, k) => (
      <div
        // eslint-disable-next-line
        key={`m-square-${k}`}
        className={classes.tile}
        style={{
          flexBasis: `calc(${round(100 / dataset[index].data.length)}% - ${MARGIN * 2}px)`,
        }}
      >
        <Tooltip
          title={`${labels[k]}: ${formatNumber(dataset[index].data[k].v, '0a', '-')}`}
          arrow
        >
          <div
            className={classes.tileContent}
            style={{
              backgroundColor: getBackgroundColor(dataset[index].data, k),
            }}
          />
        </Tooltip>
      </div>
    ))}
  </div>
);

Grid.propTypes = {
  options: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
  dataset: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Grid.defaultProps = {
  options: {},
};

export default withStyles(styles, { withTheme: true })(Grid);
