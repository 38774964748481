import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import DeleteModal from './DeleteModal';
import styles from './styles';

const DeleteAction = ({
  classes,
  report,
  isLoading,
  open,
  openModal,
  closeModal,
}) => (
  <React.Fragment>
    <Button className={classes.root} onClick={openModal} disabled={isLoading}>
      <FontAwesomeIcon icon={faTrash} />
    </Button>
    <DeleteModal open={open} onClose={closeModal} report={report} />
  </React.Fragment>
);

DeleteAction.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ brandSafetyReport }) => ({
  isLoading: brandSafetyReport.loading,
});

export default withAll(
  withStyles(styles),
  connect(mapStateToProps, null),
  withModal,
)(DeleteAction);
