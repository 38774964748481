import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Icon, CardActions, CardContent, Typography } from '@material-ui/core';
import Input from 'components/base/forms/Input';
import PropTypes from 'prop-types';
import { requiredValidator, emailValidator } from 'utils/validators';
import Error from 'components/base/forms/Error/index';

const FORM_FULL_WIDTH = true;

class ForgotPasswordForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    classes: PropTypes.shape({
    }).isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { handleSubmit, error, classes } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <CardContent>
          <div className={classes.forgot__password__title}>
            <Typography variant="h5" color="inherit">
              Please enter the email address associated with you Prism account:
            </Typography>
          </div>
          <div>
            <Field
              name="email"
              component={Input}
              type="text"
              placeholder="Email"
              label="Email"
              fullWidth={FORM_FULL_WIDTH}
              validate={[requiredValidator, emailValidator]}
            />
          </div>
          <div>
            <Error error={error} />
          </div>
        </CardContent>
        <CardActions>
          <Button color="primary" size="small" type="submit"><Icon>send</Icon> Submit</Button>
        </CardActions>

      </form>
    );
  }
}

export default reduxForm({
  form: 'forgotPasswordForm', // a unique identifier for this form
})(ForgotPasswordForm);
