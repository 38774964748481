export default theme => ({
  root: {
    display: 'inline-flex',
  },
  infoIconDiv: {
    display: 'inline-flex',
  },
  infoIcon: {
    marginLeft: '0.3rem',
    marginRight: '1rem',
    color: theme.palette.black.dark,
    fontSize: '1.1rem',
    '&:hover': {
      color: theme.palette.black.main,
    },
  },
});
