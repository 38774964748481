export default theme => ({
  root: {
    textAlign: 'center',
    width: '70%',
    margin: '0.5rem auto',
    backgroundColor: theme.palette.white.light,
    border: `1px solid ${theme.palette.black.main}`,
  },
  button: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.white.main,
    width: '80%',
    padding: '1rem',
    cursor: 'pointer',

    '&:last-child': {
      padding: '1rem',
    },
    '&:hover': {
      boxShadow: theme.customShadows[2],
      backgroundColor: theme.palette.green.dark,
    },
  },
  anchorPrice: {
    fontWeight: 'bold',
    color: theme.palette.black.light,
    padding: '0.1rem 0.2rem',
    textDecoration: `line-through solid ${theme.palette.black.light}`,
    textDecorationThickness: '2px',
    '-webkit-text-decoration-color': theme.palette.black.light,
    '-webkit-text-decoration-line': 'line-through',
    '-webkit-text-decoration-style': 'solid',
  },
  note: {
    color: theme.palette.black.light,
  },
});
