export default theme => ({
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
  },
  container: {
    padding: '0',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '-6200%',
  },
  logo: {
    display: 'inline-block',
    width: '12rem',
    padding: '0.4rem',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  topSignUp: {
    display: 'inline-block',
    color: 'white',
    padding: '0.2rem',
    width: '9rem',
    textAlign: 'center',
    float: 'right',
    marginTop: '2em',
    marginRight: '1em',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mainContent: {
    marginTop: '4rem',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: '4em auto 0 auto',
    },
  },
  topLeftPanel: {
    marginLeft: '7.9%',
    width: '34%',
    display: 'inline-block',
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
      textAlign: 'center',
    },
  },
  topLeftPanel_header: {
    fontSize: '1.5rem',
    marginBottom: '1em',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
    },
  },
  topLeftPanel_body: {
    fontSize: '1.1rem',
    marginBottom: '1em',
    color: '#767676',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  link: {
    textDecoration: 'none',
  },
  topLeftPanel_tryItForFreeBtn: {
    padding: '0.2rem',
    width: '9rem',
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0 auto 2rem auto',
      fontSize: '1.5rem',
    },
  },
  topRightPanel: {
    width: '45%',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  pageMiddle: {
    margin: '0 auto',
    marginTop: '3rem',
    textAlign: 'center',
    paddingBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '5rem',
    },
  },
  pageMiddle_header: {
    marginTop: '1rem',
    fontSize: '1.5rem',
    fontWeight: '400',
  },
  pageMiddle_body: {
    marginTop: '1rem',
    fontSize: '1rem',
    color: '#9c9c9c',
  },
  pageMiddle_detailsList: {
    width: '90%',
    margin: '0 auto',
    marginTop: '2rem',
  },
  topRightPanel_img: {
    width: '48rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
    },
  },
  action: {
    margin: '1rem auto',
    background: 'white',
    borderRadius: '0.3rem',
    width: '91%',
    padding: '1rem',
    height: '5.5rem',
    [theme.breakpoints.down('sm')]: {
      height: '6rem',
      width: '95%',
    },
  },
  action_title: {
    margin: '0.5rem',
    marginLeft: '2rem',
    fontSize: '2rem',
    fontWeight: '600',
    width: '50%',
    textAlign: 'left',
    display: 'inline-block',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      margin: '0.2rem',
      fontSize: '1.4rem',
      fontWeight: '600',
      width: '100%',
      textAlign: 'center',
      display: 'block',
    },
  },
  action_desc: {
    margin: '0.5rem',
    marginLeft: '2rem',
    textAlign: 'left',
    width: '50%',
    display: 'inline-block',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      margin: '0.2rem',
      width: '100%',
      textAlign: 'center',
      display: 'block',
    },
  },
  action_btn: {
    padding: '0.2rem',
    width: '11rem',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-block',
    marginTop: '-2.2rem',
    float: 'right',
    marginRight: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0.2rem',
      width: '100%',
      textAlign: 'center',
      display: 'block',
    },
  },
});
