import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/styles';
import withModal from 'components/base/withModal';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  GridList,
  Link,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GridListTile from 'components/app/Search/GridListTile';
import { getInfluencerProfile } from 'redux/influencers/actions';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import { influencerProfileUrl } from 'constants/routing';
import { withRouter } from 'react-router-dom';
import { keys, isEmpty } from 'lodash';
import styles from './styles';
import Platforms from './Platforms';
import Categories from './Categories';
import SavedListCheckbox from './SavedListCheckbox';
import Stats from './Stats';
import { getInfluencerName } from '../../../utils/influencers';
import InfluencerName from './InfluencerName';
import TopBrands from './TopBrands';
import BrandMentions from './BrandMentions';
import BrandSafetyIcon from './BrandSafetyIcon';


class InfluencerCard extends PureComponent {
  getPlatforms = platformDict => keys(platformDict).map(platform => ({
    label: platform,
    value: platformDict[platform].followers ? platformDict[platform].followers : 0,
    username: platformDict[platform].username,
  }));

  openProfile = () => {
    const { influencer } = this.props;
    this.props.history.push(influencerProfileUrl(influencer.id));
  }

  openSignup = () => {
    const { openModal } = this.props;
    openModal();
  }

  renderLocation = (location) => {
    const { classes } = this.props;
    return (
      <div className={classes.location}>
        { !!location && !!location.city && location.country &&
          <Typography variant="body1" className={classes.locationText}>{location.city}, {location.country}</Typography>
        }
      </div>
    );
  };

  render() {
    const {
      classes,
      brandName,
      influencer,
      open,
      closeModal,
      canViewProfile,
      isAuthenticated,
      canViewTopBrands,
      canSaveToList,
      canViewSelectedBrandMentions,
      canViewBrandSafety,
    } = this.props;
    const influencerDisplayName = getInfluencerName(influencer);

    return (
      <Card className={classes.root} sel="influencer_card" brand_name={brandName} >
        <CardContent>
          {canViewSelectedBrandMentions && <BrandMentions selectedBrandMentions={influencer.selectedBrandMentions} />}
          <div className={classes.headerSection}>
            {canSaveToList && (
              <SavedListCheckbox
                influencerId={influencer.id}
                influencerDisplayName={influencerDisplayName}
              />
            )}
            <div className={classes.bubbleContainer}>
              <div className={classes.avatarWrapper}>
                <Avatar
                  onClick={canViewProfile ? this.openProfile : this.openSignup}
                  src={influencer.profilePhoto}
                  className={classes.avatar}
                />
                { influencer.brandSafetyReport != null &&
                  <BrandSafetyIcon
                    influencer={influencer}
                    canViewBrandSafety={canViewBrandSafety}
                  />
                }
              </div>
              <div className={classes.platforms}>
                <Platforms platforms={this.getPlatforms(influencer.platforms)} />
              </div>
            </div>
            <InfluencerName
              influencer={influencer}
              canViewProfile={canViewProfile}
              openSignUp={open}
              closeModal={closeModal}
            />
            {this.renderLocation(influencer.location)}
            <Categories categories={influencer.categories} />
          </div>
          <Stats
            avgComments={influencer.stats.avgComments}
            avgEngagement={influencer.stats.avgEngagement}
            totalReach={influencer.stats.totalReach}
            avgSponsoredEngagement={influencer.stats.avgSponsoredPostEngagement}
          />
          <div className={classes.previewImgSection}>
            <GridList className={classes.gridList} cellHeight={70} cols={4}>
              {influencer.images.map(image => (
                <GridListTile className={classes.photoItem} key={image.postId}>
                  <Link href={image.postLink} target="_blank">
                    <img src={image.imageUrl} alt="" className={classes.gridImage} />
                  </Link>
                </GridListTile>
              ))}
            </GridList>
          </div>
          {canViewTopBrands && !isEmpty(influencer.topBrandMentions) && (
            <TopBrands brands={influencer.topBrandMentions} />
          )}
        </CardContent>
        {!isAuthenticated && <BetaSignupModal
          open={open}
          closeModal={closeModal}
          onSignupComplete={closeModal}
        />}
      </Card>
    );
  }
}


InfluencerCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  brandName: PropTypes.string.isRequired,
  influencer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    profilePhoto: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    categories: PropTypes.shape({}),
    stats: PropTypes.shape({
      totalReach: PropTypes.number,
      avgComments: PropTypes.number,
      avgEngagement: PropTypes.number,
    }),
    platforms: PropTypes.shape({}),
    images: PropTypes.arrayOf(PropTypes.shape({
      postId: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      postLink: PropTypes.string,
    })),
    topBrandMentions: PropTypes.arrayOf(PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
    })),
    brandSafetyReport: PropTypes.shape({}),
  }).isRequired,
  canViewProfile: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  permittedProfileViews: PropTypes.shape({}).isRequired,
  canViewTopBrands: PropTypes.bool,
  canSaveToList: PropTypes.bool,
  canViewSelectedBrandMentions: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
  canViewBrandSafety: PropTypes.bool,

};

InfluencerCard.defaultProps = {
  canViewTopBrands: false,
  canSaveToList: true,
  canViewSelectedBrandMentions: false,
  history: null,
  canViewBrandSafety: false,
};

export default connect(
  ({ auth: { isAuthenticated } }) => ({
    isAuthenticated,
  }),
  { getInfluencerProfile },
)(withModal(withStyles(styles)(withRouter((InfluencerCard)))));
