import React from 'react';
import Modal from 'components/base/modals/PrismModal';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import { withRouter } from 'react-router-dom';
import { brandProfileUrl } from 'constants/routing';
import BrandCard from 'components/common/Brands/BrandCard';
import { connect } from 'react-redux';
import { getStatBrands } from 'redux/brand/actions';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import styles from './styles';

class UnauthorizedVertical extends React.Component {
  componentDidMount() {
    this.props.getStatBrands({ public: true });
  }

  link = (brandId) => {
    // eslint-disable-next-line
    document.location = document.location = brandProfileUrl(brandId);
  }

  render() {
    const {
      classes,
      open,
      brands,
      loaded,
      isLoading,
      showSignupModal,
    } = this.props;
    return (
      <Modal
        title="Subscribers only"
        open={open}
        onClose={() => {}}
        width="80%"
        hideClose
      >
        <Button
          variant="contained"
          color="primary"
          onClick={showSignupModal}
        >
          Sign up for your {process.env.BETA_DEFAULT_TRIAL_DAYS}-day free trial
        </Button>
        <Typography className={classes.noCreditCard} variant="body">No credit card required</Typography>
        <Typography className={classes.orTitle} variant="body">OR</Typography>
        <Typography variant="h5">View the below brands for FREE.</Typography>
        <Loader loading={isLoading} loaded={loaded}>
          <Grid container spacing={3} className={classes.root} alignContent="center">
            {brands.map(brand => (
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.brandLink}
                  variant="link"
                  onClick={() => this.link(brand.id)}
                >
                  <BrandCard data={brand} />
                </Button>
              </Grid>
            ))}
          </Grid>
        </Loader>
      </Modal>
    );
  }
}

UnauthorizedVertical.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  getStatBrands: PropTypes.func.isRequired,
  showSignupModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ statBrands }) => ({
  isLoading: statBrands.loading,
  loaded: statBrands.loaded,
  brands: statBrands.data.filter(v => v.isPublic),
});

export default withAll(
  connect(mapStateToProps, { getStatBrands }),
  withStyles(styles),
)(withRouter(UnauthorizedVertical));
