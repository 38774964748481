export default () => ({
  card: {
    width: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  logo: {
    textAlign: 'center',
    opacity: 0.3,
    marginBottom: '10px',
  },
  forgot__password__wrapper: {
    marginTop: '1rem',
    textAlign: 'center',
  },
});
