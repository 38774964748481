import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { formatNumber } from 'utils/helpers';
import HorizontalBarHtml from 'components/base/charts/Bar/BarHtml/HorizontalBarHtml';
import { config } from 'theme';

const styles = () => ({
  root: {},
  avatar: {
    marginRight: '0.8rem',
  },
});

const NULL_VALUE_DISPLAY = '-';

const LEGEND_COLORS = [
  config.palette.green.main,
  config.palette.red.main,
  config.palette.barChart.gradient.blue,
  config.palette.blue.dark,
  config.palette.black.light,
  config.palette.green.main,
  config.palette.red.main,
  config.palette.barChart.gradient.blue,
  config.palette.blue.dark,
  config.palette.black.light,
  config.palette.green.main,
  config.palette.red.main,
  config.palette.barChart.gradient.blue,
  config.palette.blue.dark,
  config.palette.black.light,
];

const createInnerLabel = (brandData, verticalPostCount) => (
  `${brandData.name}: ${formatNumber(brandData.postCount / verticalPostCount, '0%', NULL_VALUE_DISPLAY)}`
);

const TopBrandChart = ({ classes, topBrands, verticalPostCount }) => (
  <div className={classes.root}>
    <HorizontalBarHtml
      data={{
        values: topBrands.map(v => v.postCount),
        labels: topBrands.map(l => <Avatar className={classes.avatar} alt={l.name} src={l.logo} />),
        colors: LEGEND_COLORS,
        barLabels: topBrands.map(v => createInnerLabel(v, verticalPostCount)),
      }}
      maxValue={verticalPostCount}
    />
  </div>
);

TopBrandChart.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  topBrands: PropTypes.arrayOf(PropTypes.shape({})),
  verticalPostCount: PropTypes.number.isRequired,
};

TopBrandChart.defaultProps = {
  topBrands: [],
};

const mapStateToProps = ({ competitiveReportVerticalBreakdown }) => ({
  topBrands: get(competitiveReportVerticalBreakdown, 'data.topBrands', []),
  verticalPostCount: get(competitiveReportVerticalBreakdown, 'data.vertical.postCount'),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(TopBrandChart);
