import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { withRouter } from 'react-router';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DeleteAction from 'components/app/BrandSafetyReports/DeleteAction';
import RefreshAction from 'components/app/BrandSafetyReports/RefreshAction';
import Button from 'components/base/Button';
import withAll from 'components/base/withAll';
import PlatformIcon from 'components/common/PlatformIcon';
import { REPORT_STATUS_LABELS } from 'constants/reports';
import { buildBrandSafetyReportsUrl } from 'constants/routing';
import AnomalyPostIndex from './AnomalyPostIndex';
import BrandSafetyIndex from './BrandSafetyIndex';

const styles = theme => ({
  platformsCell: {
    display: 'flex',
  },
  viewReportBtn: {
    color: theme.palette.blue.main,
  },
});

const ReportRow = ({
  classes,
  history,
  report,
  canViewUnusualPostIndex,
}) => (
  <TableRow id={report.id}>
    <TableCell align="left">
      <Button
        variant="link"
        className={classes.viewReportBtn}
        onClick={() => history.push(buildBrandSafetyReportsUrl(report.id))}
      >
        {report.creator}
      </Button>
    </TableCell>
    <TableCell align="left">
      <div className={classes.platformsCell}>
        {report.platforms.map(p => <PlatformIcon platform={p} />)}
      </div>
    </TableCell>
    <TableCell align="center">
      {report.analyzedPosts}
    </TableCell>
    {/* <TableCell align="center"> TODO next step */}
    {/*  {report.varietyIndex} */}
    {/* </TableCell> */}
    {/* <TableCell align="center"> TODO next step */}
    {/*  {report.sponsoredRatio} */}
    {/* </TableCell> */}
    <TableCell align="center">
      <BrandSafetyIndex report={report} />
    </TableCell>
    {
      canViewUnusualPostIndex && (
        <TableCell align="center">
          <AnomalyPostIndex report={report} />
        </TableCell>
      )
    }
    <TableCell align="left">
      <Typography>{REPORT_STATUS_LABELS[report.status]}</Typography>
    </TableCell>
    <TableCell align="left">
      <Typography>{moment(report.modifiedDate).format('MM/DD/YY')}</Typography>
    </TableCell>
    <TableCell align="left">
      <RefreshAction report={report} />
      {!report.isSample && <DeleteAction report={report} />}
    </TableCell>
  </TableRow>
);

ReportRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    influencerId: PropTypes.string,
    creator: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.string),
    analyzedPosts: PropTypes.number,
    brandSafetyIndex: PropTypes.string,
    modifiedDate: PropTypes.string,
    isSample: PropTypes.bool,
  }).isRequired,
  canViewUnusualPostIndex: PropTypes.bool.isRequired,
};

export default withAll(
  withStyles(styles),
  withRouter,
)(ReportRow);
