import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, first, get } from 'lodash';
import { SubmissionError } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { Card, Link } from '@material-ui/core';
import { login } from 'redux/authentication/actions';
import Logo from 'components/base/Logo/LoginLogo';
import LoggedOutLayout from 'components/base/LoggedOutLayout';
import { forgotPassword } from 'constants/routing';
import LoginForm from './LoginForm';
import styles from './styles';

class Login extends PureComponent {
  async onSubmit(formData) {
    try {
      await this.props.login(formData.email, formData.password);
    } catch (error) {
      if (error.errors) {
        if (!isEmpty(get(error, 'errors.non_field_errors'))) {
          throw new SubmissionError({ _error: first(error.errors.non_field_errors) });
        }
        throw new SubmissionError({ _error: 'Email or password credentials are incorrect.' });
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <LoggedOutLayout>
        <div className={classes.card}>
          <Logo />
          <Card>
            <LoginForm onSubmit={data => this.onSubmit(data)} />
          </Card>
          <div className={classes.forgot__password__wrapper}>
            <Link href={forgotPassword()} >
              Forgot my password
            </Link>
          </div>
        </div>
      </LoggedOutLayout>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    authentication: state.authentication,
  }),
  { login },
)(withStyles(styles)(Login));

