import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { slice } from 'lodash';
import { ma } from 'moving-averages';
import { getTrend, partitionDataByWeek } from 'lib/utils';
import StatCard from 'components/base/Card/StatCard';
import StatContainer from 'components/base/Card/StatContainer';
import { formatNumber } from 'utils/helpers';


const NULL_VALUE_DISPLAY = 'N/A';

const styles = () => ({
  statGrid: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    marginTop: 0,
  },
});

const getRollingAverage = (statList, maDays) => {
  const weeklyPosts = partitionDataByWeek(slice(ma(statList, maDays), maDays));
  return {
    trend3WRa: getTrend(weeklyPosts, 1, 3),
    trend6WRa: getTrend(weeklyPosts, 1, 6),
  };
};

const AvgStatCtrl = ({
  statList, maDays,
}) => {
  const rollingAvg = getRollingAverage(statList, maDays);
  return (
    <StatContainer>
      <StatCard
        coloredValues
        showDirectionalArrow
        value={rollingAvg.trend3WRa}
        formatter={v => formatNumber(v, '0.00%', NULL_VALUE_DISPLAY)}
        label="3 WEEK AVERAGE"
        xs={6}
      />
      <StatCard
        coloredValues
        showDirectionalArrow
        value={rollingAvg.trend6WRa}
        formatter={v => formatNumber(v, '0.00%', NULL_VALUE_DISPLAY)}
        label="6 WEEK AVERAGE"
        xs={6}
      />
    </StatContainer>
  );
};

AvgStatCtrl.propTypes = {
  statList: PropTypes.shape({}).isRequired,
  maDays: PropTypes.number,
  classes: PropTypes.shape({}).isRequired,
};

AvgStatCtrl.defaultProps = {
  maDays: 14,
};

export default withStyles(styles)(AvgStatCtrl);
