import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import PageTitle from 'components/base/titles/PageTitle';
import withAll from 'components/base/withAll';
import withQueryParams from 'components/base/withQueryParams';
import PageLayout from 'components/base/Layout/PageLayout';
import BetaSignupFlowWrapper from 'components/common/BetaSignupFlowWrapper';
import VerticalDetail from 'components/common/VerticalDetail';
import UnauthorizedVertical from 'components/common/modals/UnauthorizedVertical';
import { getVerticalDetail as getVerticalDetailAction } from 'redux/stats/actions';
import { mpPageView } from 'utils/copenhagen';
import styles from './styles';

const Vertical = ({
  getVerticalDetail,
  match: { params: { verticalId } },
  verticalName,
  isLoading,
  loaded,
}) => {
  const onComponentDidMountFetch = async () => {
    await getVerticalDetail(verticalId);
    mpPageView({ name: 'vertical', verticalId });
  };
  return (
    <div key={`brand-profile-${verticalId}`}>
      <BetaSignupFlowWrapper
        UnauthorizedModal={UnauthorizedVertical}
        onComponentDidMountFetch={onComponentDidMountFetch}
        isLoading={isLoading}
        loaded={loaded}
      >
        <PageLayout>
          <PageTitle title={verticalName} />
          <VerticalDetail />
        </PageLayout>
      </BetaSignupFlowWrapper>
    </div>
  );
};

Vertical.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getVerticalDetail: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  verticalName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      verticalId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

Vertical.defaultProps = {
  verticalName: null,
};

const mapStateToProps = ({ vertical }) => ({
  isLoading: vertical.loading,
  loaded: vertical.loaded,
  verticalName: vertical.data.vertical,
});

const queryParamsToProps = ({ showSignup }) => ({
  showSignup,
});

export default withAll(
  connect(mapStateToProps, { getVerticalDetail: getVerticalDetailAction }),
  withStyles(styles),
  withQueryParams(queryParamsToProps),
)(Vertical);

