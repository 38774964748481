import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/base/Button';
import { connect } from 'react-redux';
import {
  TableCell,
  TableRow,
  Typography,
  Icon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import PlatformIcon from 'components/common/PlatformIcon';
import { buildKeyPhraseMonitorUrl } from 'constants/routing';
import withMultipleModals from 'components/base/withMultipleModals';
import KeyPhraseMonitorModal from 'components/common/modals/KeyPhraseMonitorModal';
import DeleteMonitorModal from 'components/common/modals/DeleteMonitorModal';
import { getKeyPhraseMonitor as getKeyPhraseMonitorAction,
  resetKeyPhraseMonitor as resetKeyPhraseMonitorAction } from 'redux/monitors/actions';


const ModalName = {
  KeyPhraseMonitorModal: 'KeyPhraseMonitorModal',
  DeleteMonitorModal: 'DeleteMonitorModal',
};

const styles = theme => ({
  root: {},
  edit: {},
  monitorLink: {
    color: theme.palette.blue.main,
  },
  activeIcon: {
    color: theme.palette.green.main,
  },
  platformsCell: {
    display: 'flex',
  },
});

const MonitorRow = ({
  monitor,
  classes,
  history,
  closeModal,
  openModal,
  open,
  getKeyPhraseMonitor,
  resetKeyPhraseMonitor,
}) => (
  <React.Fragment>
    <TableRow key={`report-${monitor.id}`} className={classes.root}>
      <TableCell component="th" scope="row">
        <Button
          onClick={() => history.push(buildKeyPhraseMonitorUrl(monitor.id))}
          className={classes.monitorLink}
          variant="contained"
          color="secondary"
        ><Typography>{monitor.monitorName}</Typography>
        </Button>
      </TableCell>
      <TableCell align="left">
        <div className={classes.platformsCell}>
          {monitor.platforms.map(p => <PlatformIcon platform={p} />)}
        </div>
      </TableCell>
      <TableCell align="left">
        <Typography>{monitor.query}</Typography>
      </TableCell>
      {/* <TableCell align="left">
        <Typography>
          { monitor.status && <Icon className={classes.activeIcon}>checkcircleoutline</Icon> }
        </Typography>
      </TableCell> */}
      <TableCell align="right">
        <Button
          onClick={async () => {
            await getKeyPhraseMonitor(monitor.id);
            openModal(ModalName.KeyPhraseMonitorModal);
          }}
          className={classes.edit}
          variant="contained"
          color="secondary"
        ><Icon>edit</Icon>
        </Button>

        <Button
          onClick={() => openModal(ModalName.DeleteMonitorModal)}
          className={classes.delete}
          variant="contained"
          color="secondary"
        ><Icon>delete</Icon>
        </Button>
      </TableCell>
    </TableRow>

    <KeyPhraseMonitorModal
      open={open[ModalName.KeyPhraseMonitorModal]}
      closeModal={() => {
        resetKeyPhraseMonitor();
        closeModal(ModalName.KeyPhraseMonitorModal);
}}
      monitor={monitor}
    />

    <DeleteMonitorModal
      open={open[ModalName.DeleteMonitorModal]}
      closeModal={() => closeModal(ModalName.DeleteMonitorModal)}
      monitor={monitor}
    />
  </React.Fragment>
);

MonitorRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  monitor: PropTypes.shape({
    id: PropTypes.string,
    query: PropTypes.string,
    monitorName: PropTypes.string,
    status: PropTypes.bool,
    platforms: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.arrayOf(PropTypes.bool).isRequired,
  getKeyPhraseMonitor: PropTypes.func.isRequired,
  resetKeyPhraseMonitor: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getKeyPhraseMonitor: getKeyPhraseMonitorAction,
  resetKeyPhraseMonitor: resetKeyPhraseMonitorAction,
};

export default withAll(
  withRouter,
  withMultipleModals([ModalName.KeyPhraseMonitorModal, ModalName.DeleteMonitorModal]),
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
)(MonitorRow);
