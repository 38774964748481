import { request } from 'lib/http';
import API from 'constants/api';
import { recursiveFunc } from 'utils/mixins';
import { snakeCase, mapKeys } from 'lodash';
import { handleErrors } from 'utils/api';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SETUP_PASSWORD = 'SETUP_PASSWORD';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';

export const fetchUserProfile = () => async (dispatch) => {
  dispatch({
    type: FETCH_USER_PROFILE,
    payload: request('get', API.FETCH_USER_PROFILE, {}),
  });
};

export const updateUserProfile = data => async (dispatch) => {
  await dispatch({
    type: UPDATE_USER_PROFILE,
    payload: request('put', API.UPDATE_USER_PROFILE, {
      data: recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
    }),
  });
};

export const patchUserProfile = data => async (dispatch) => {
  await dispatch({
    type: UPDATE_USER_PROFILE,
    payload: request('patch', API.PATCH_USER_PROFILE, {
      data: recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
    }),
  });
};

export const updateUserPassword = data => async (dispatch) => {
  await dispatch({
    type: UPDATE_USER_PASSWORD,
    payload: request('put', API.UPDATE_USER_PASSWORD, {
      data,
    }),
  });
};

export const forgotPassword = email => async (dispatch) => {
  await dispatch({
    type: FORGOT_PASSWORD,
    payload: request('post', API.FORGOT_PASSWORD, {
      data: {
        email,
      },
      headers: {},
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const resetPasswordWithToken = (password, confirmPassword, resetCode) => async (dispatch) => {
  await dispatch({
    type: CHANGE_PASSWORD,
    payload: request('post', API.CHANGE_PASSWORD, {
      data: {
        password,
        reset_code: resetCode,
      },
      headers: {},
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const setupPasswordWithToken = (password, confirmPassword, resetCode) => async (dispatch) => {
  await dispatch({
    type: SETUP_PASSWORD,
    payload: request('post', API.SET_PASSWORD, {
      data: {
        password,
        reset_code: resetCode,
      },
      headers: {},
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const verifyEmail = (email, code) => async (dispatch) => {
  await dispatch({
    type: VERIFY_EMAIL,
    payload: request('post', API.VERIFY_EMAIL, {
      data: {
        email,
        code,
      },
      headers: {},
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const helpRequest = data => request('post', API.USER_HELP_REQUEST, {
  data,
});
