import React from 'react';
import { withStyles } from '@material-ui/styles';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MenuItem } from '@material-ui/core';
import { clone, get, isEmpty, size, identity } from 'lodash';
import ButtonMenu from 'components/base/ButtonMenu';
import PageLayout from 'components/base/Layout/PageLayout';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import AddToList from 'components/common/lists/AddToList/MultipleAddToList';
import ShareList from 'components/common/lists/ShareList';
import withOnBoarding from 'components/common/OnBoarding/withOnBoarding';
import InfluencerGrid from 'components/common/InfluencerGrid';
import RemoveFromList from 'components/common/lists/RemoveFromList';
import SelectedInfluencers from 'components/common/SelectedInfluencers';
import { MEMBER_SORT_OPTIONS } from 'constants/options';
import { SubmissionError } from 'redux-form';
import withPermission from 'components/common/withPermission';
import { fetchList, fetchMembers, exportListData } from 'redux/lists/actions';
import { login } from 'constants/routing';
import ListDetailsHeader from './ListDetailsHeader';
import styles from './styles';

class ListDetails extends React.Component {
  state = {
    sort: { label: 'Follower count', value: 'follower_count' },
  };

  async componentDidMount() {
    try {
      await this.props.fetchList(this.props.listId);
      this.fetchListMembers();
    } catch (e) {
      if (e.errors) {
        if (e.errors.status === 401) {
          window.location.href = login();
          return;
        }
        throw new SubmissionError(e.errors);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { handleSavedListDetailOnBoarding, loading, list } = this.props;
    if (this.props.listId !== prevProps.listId) {
      this.fetchListMembers();
    }

    if (!loading && !isEmpty(list)) {
      handleSavedListDetailOnBoarding();
    }
  }

  fetchListMembers = () => {
    this.props.fetchMembers(this.props.listId, this.state.sort.value, 'desc');
  };

  exportList = async () => {
    await this.props.exportListData(this.props.listId, this.state.sort.value, 'desc');
  };

  createSortOptions = () => clone(MEMBER_SORT_OPTIONS);

  handleSort = (sort) => {
    this.setState({
      sort,
    }, this.fetchListMembers);
  };

  renderSortOptions = sortOptions => sortOptions.map(sort => (
    <MenuItem
      key={`sort-${sort.value}`}
      onClick={() => this.handleSort(sort)}
      closeOnClick
    >
      {sort.label}
    </MenuItem>
  ));

  renderActions = () => {
    const {
      classes, listId, members, list, isAuthenticated,
    } = this.props;
    const sortOptions = this.createSortOptions();
    return (
      <div className={classes.actions}>
        {isAuthenticated && list.isOwner &&
        <SelectedInfluencers
          buttonProps={{
            variant: 'contained',
            color: 'primary',
          }}
          influencers={members}
        />
        }

        <ButtonMenu
          buttonProps={{
            variant: 'contained',
            color: 'primary',
          }}
          buttonChildren={(
            <React.Fragment>{this.state.sort.label}<KeyboardArrowDownIcon /></React.Fragment>
          )}
          menuChildren={this.renderSortOptions(sortOptions)}
        />

        {isAuthenticated && list.isOwner &&
        <React.Fragment>
          <RemoveFromList listId={listId} />
          <AddToList />
          <ShareList list={list} />
        </React.Fragment>
        }

      </div>
    );
  };

  render() {
    const {
      classes,
      list,
      loading,
      members,
      membersLoading,
      permitted,
    } = this.props;
    const memberCount = size(members);
    return (
      <PageLayout>
        <ListDetailsHeader list={list} loading={loading} membersDict={members} exportListFunc={this.exportList} />
        {this.renderActions()}
        <div className={classes.members}>
          <Loader loading={membersLoading}>
            {memberCount > 0 ?
              <InfluencerGrid
                columns={4}
                influencers={members}
                canViewBrandSafety={permitted}
              />
              :
              <div className={classes.empty__list}>
                No Creators found
              </div>
            }
          </Loader>
        </div>
      </PageLayout>
    );
  }
}

ListDetails.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  list: PropTypes.shape({}),
  listId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchList: PropTypes.func.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  exportListData: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  membersLoading: PropTypes.bool,
  handleSavedListDetailOnBoarding: PropTypes.func.isRequired,
  permitted: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

ListDetails.defaultProps = {
  list: {},
  membersLoading: false,
};

const mapStateToProps = (
  {
    lists: {
      loading,
      data,
      members: {
        data: membersData,
        loading: membersLoading,
      },
    },
    auth: { isAuthenticated },
  },
  { listId },
) => {
  const list = get(data, listId, {});
  return {
    members: membersData || [],
    membersLoading,
    loading,
    list,
    isAuthenticated,
  };
};

const mapDispatchToProps = {
  fetchList,
  fetchMembers,
  exportListData,
};

export default withAll(
  withPermission('create_brand_safety_reports', null, identity, true),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withOnBoarding,
)(ListDetails);
