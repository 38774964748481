import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { keys } from 'lodash';
import { connect } from 'react-redux';
import { searchUrl } from '../../constants/routing';

const PermissionedRoute = ({
  component: C, shouldHaveRule, config, props: cProps, ...rest
}) => {
  let isPermitted = false;
  keys(shouldHaveRule).forEach((rule) => {
    if (config.data[rule] === shouldHaveRule[rule]) {
      isPermitted = true;
    }
  });
  return (<Route
    {...rest}
    render={props => (isPermitted ?
      <C {...props} {...cProps} /> :
      <Redirect to={searchUrl()} />)
    }
  />);
};

PermissionedRoute.propTypes = {
  component: PropTypes.shape({}).isRequired,
  shouldHaveRule: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({}).isRequired,
};

export default connect(
  state => ({ config: state.configs }),
  null,
)(PermissionedRoute);
