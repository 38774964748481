export default theme => ({
  statSection: {
    margin: '1rem -0.5rem',
  },
  statContainer: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    '& > div': {
      borderRight: `1px solid ${theme.palette.border.main}`,
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  statLabel: {
    '& p': {
      color: theme.palette.black.light,
    },
  },
});
