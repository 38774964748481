export default theme => ({
  root: {
    margin: '0 0.2rem',
    minWidth: 'auto',
    boxShadow: theme.customShadows[1],
    '&:first-child': {
      marginLeft: '0',
    },
  },
});
