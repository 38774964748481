import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import {
  Container,
  Grid,
  Avatar,
} from '@material-ui/core';
import HomeTitle from 'components/base/titles/HomeTitle';
import HomeSubTitle from 'components/base/titles/HomeSubTitle';
import brandImg from 'images/home/brands.png';
import { brandProfileUrl, brandsUrl } from 'constants/routing';
import cx from 'classnames';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  root: {
    marginBottom: '4rem',
    position: 'relative',
    marginTop: '4rem',
  },
  container: {},
  title: {
    justifyContent: 'left',
  },
  image: {
    width: '100%',
    '& > img': {
      width: '100%',
    },
  },
  logoContainer: {
    display: 'flex',
  },
  avatar: {
    display: 'flex',
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: '-1rem',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.customShadows[2],
    },
  },
  iconAvatar: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});

const BRANDS_TO_DISPLAY = [
  {
    id: 1159,
    name: 'Amazon',
    logo: 'https://logo.clearbit.com/amazon.com',
  }, {
    id: 449,
    name: 'Nike',
    logo: 'https://logo.clearbit.com/nike.com',
  }, {
    id: 602102,
    name: 'Disney+',
    logo: 'https://logo.clearbit.com/disneyplus.com',
  }, {
    id: 4969,
    name: 'Target',
    logo: 'https://logo.clearbit.com/target.com',
  },
];

const Brands = ({ classes, history }) => (
  <div className={classes.root}>
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={8}>
        <Grid item md={6}>
          <div className={classes.image}>
            <img
              src={brandImg}
              alt="search"
            />
          </div>
        </Grid>
        <Grid item md={6}>
          <HomeTitle
            title={<React.Fragment>See Brands Crushing It With <br />Their Influencer Marketing</React.Fragment>}
            className={classes.title}
            titleId="brandsH1"
          />
          <HomeSubTitle titleId="brandsH2">
            See what other brands in your industry are doing with
            influencer and how their sponsored posts are performing.
          </HomeSubTitle>
          <div className={classes.logoContainer}>
            {BRANDS_TO_DISPLAY.map(b => (
              <Avatar
                className={classes.avatar}
                onClick={() => history.push(brandProfileUrl(b.id))}
                alt={b.name}
                src={b.logo}
              />
            ))}
            <Avatar
              className={cx(classes.avatar, classes.iconAvatar)}
              onClick={() => history.push(brandsUrl())}
              alt="More.."
            >
              <AddIcon />
            </Avatar>
          </div>
        </Grid>
      </Grid>
    </Container>
  </div>
);

Brands.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withAll(
  withStyles(styles),
  withRouter,
)(Brands);
