import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import numeral from 'numeral';
import Tooltip from 'components/base/Tooltip';
import H6 from 'components/base/titles/CardHeading/H6';

const allStyles = theme => ({
  vertical: {
    root: {
      display: 'flex',
      margin: '0 0.3rem',
      alignItems: 'center',
      flexDirection: 'column-reverse',
    },
    barContainer: {
      flex: 1,
      width: '2rem',
      backgroundColor: theme.palette.white.dark,
      position: 'relative',
      borderRadius: '0.2rem',
      overflow: 'hidden',
    },
    barValue: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      fontWeight: 600,
    },
    label: {
      marginTop: '0.2rem',
      fontSize: '0.6rem',
    },
    barLabel: {
      position: 'absolute',
      top: '0.2rem',
      width: '100%',
      fontSize: '0.6rem',
      textAlign: 'center',
      color: theme.palette.black.dark,
      fontWeight: 600,
      textShadow: `-1px -1px 0 ${theme.palette.white.dark},
         1px -1px 0 ${theme.palette.white.dark},
         -1px 1px 0 ${theme.palette.white.dark},
          1px 1px 0 ${theme.palette.white.dark}`,
    },
  },
  horizontal: {
    root: {
      display: 'flex',
      marginBottom: '0.6rem',
      alignItems: 'center',
    },
    barContainer: {
      flex: 1,
      height: '2rem',
      backgroundColor: theme.palette.white.dark,
      position: 'relative',
      borderRadius: '0.2rem',
      overflow: 'hidden',
    },
    barValue: {
      position: 'absolute',
      left: 0,
      height: '100%',
      fontWeight: 600,
    },
    barLabel: {
      position: 'absolute',
      right: '0.2rem',
      height: '100%',
      fontSize: '0.6rem',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.black.dark,
      fontWeight: 600,
      textShadow: `-1px -1px 0 ${theme.palette.white.dark},
         1px -1px 0 ${theme.palette.white.dark},
         -1px 1px 0 ${theme.palette.white.dark},
          1px 1px 0 ${theme.palette.white.dark}`,
    },
    label: {
      fontSize: '0.6rem',
    },
  },
});

const styles = theme => ({
  root: props => allStyles(theme)[props.rotation].root,
  barContainer: props => allStyles(theme)[props.rotation].barContainer,
  barValue: props => allStyles(theme)[props.rotation].barValue,
  barLabel: props => allStyles(theme)[props.rotation].barLabel,
  label: props => allStyles(theme)[props.rotation].label,
});

const barStyles = (data, rotation) => ({
  backgroundColor: data.barColor,
  ...(rotation === 'horizontal') ? { width: data.fill } : { height: data.fill },
});


const formatNumber = (value, formatter, defaultValue = null) => {
  if (!value) {
    return defaultValue;
  }
  return numeral(value).format(formatter);
};
const tooltipTransform = d => `${formatNumber(d.value, '0a', '-')}`;

const Bar = ({
  classes,
  data,
  rotation,
  displayTooltip,
  tooltipLabel,
}) => (

  <Tooltip disableHoverListener={!displayTooltip} title={tooltipLabel(data)} arrow>
    <div className={classes.root}>
      {
        !displayTooltip && (<H6 className={classes.title} align="center"> {tooltipLabel(data)}</H6>)
      }
      <div className={classes.label}>{data.label}</div>
      <div className={classes.barContainer}>
        <div
          className={classes.barValue}
          style={barStyles(data, rotation)}
        />
        {!isEmpty(data.barLabel) && (
          <span className={classes.barLabel}>{data.barLabel}</span>
        )}
      </div>
    </div>
  </Tooltip>
);

Bar.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    fill: PropTypes.string,
    barColor: PropTypes.string,
    barLabel: PropTypes.string,
  }).isRequired,
  displayTooltip: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
  rotation: PropTypes.string,
  tooltipLabel: PropTypes.func,
};

Bar.defaultProps = {
  rotation: 'horizontal',
  displayTooltip: false,
  tooltipLabel: tooltipTransform,
};

export default withStyles(styles)(Bar);
