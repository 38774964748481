import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { formatNumber } from 'utils/helpers';
import withAll from 'components/base/withAll';
import StatCard from 'components/common/StatCard';
import PostGraph from './PostGraph';
import Brands from './Brands';

const styles = theme => ({
  buttonContainer: {
    textAlign: 'center',
    backgroundColor: theme.palette.border.main,
    boxShadow: 'none',
    paddingTop: '0.4rem',
    borderRadius: '0.4rem',
  },
  button: {},
  statContainer: {
    display: 'flex',
  },
});
const NULL_VALUE_DISPLAY = 'N/A';

const VerticalDetail = ({
  vertical,
  classes,
}) => (
  <div>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} className={classes.statContainer}>
        <StatCard title="Creators" value={formatNumber(vertical.creatorCount, '0a', NULL_VALUE_DISPLAY)} />
      </Grid>
      <Grid item md={8}>
        <Brands />
      </Grid>
    </Grid>
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <PostGraph />
      </Grid>
    </Grid>
  </div>
);

VerticalDetail.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  vertical: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ vertical }) => ({
  vertical: vertical.data,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(VerticalDetail);
