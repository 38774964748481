import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';

export const GET_HOME_STATS = 'GET_HOME_STATS';
export const GET_VERTICALS = 'GET_VERTICALS';
export const GET_VERTICAL = 'GET_VERTICAL';

export const getHomeStats = () => (dispatch) => {
  dispatch({
    type: GET_HOME_STATS,
    payload: request('get', API.HOME_STATS, {
      headers: {},
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getVerticalSummary = () => (dispatch) => {
  dispatch({
    type: GET_VERTICALS,
    payload: request('get', API.VERTICAL_SUMMARY, {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getVerticalDetail = vertical => async (dispatch) => {
  await dispatch({
    type: GET_VERTICAL,
    payload: request('get', API.VERTICAL_DETAIL(vertical), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};
