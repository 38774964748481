
export const SET_SERVER_DOWN = 'SET_SERVER_DOWN';
export const UPDATE_TOOLTIP = 'UPDATE_TOOLTIP';

export const setServerDown = (dispatch, getState) => {
  const { app: { serverDown } } = getState();
  if (!serverDown) {
    dispatch({
      type: SET_SERVER_DOWN,
      serverDown: true,
    });
  }
};

export const setServerUp = (dispatch, getState) => {
  const { app: { serverDown } } = getState();
  if (serverDown) {
    dispatch({
      type: SET_SERVER_DOWN,
      serverDown: false,
    });
  }
};

export const setTooltip = (tooltipName, showTooltip: false) => (dispatch) => {
  dispatch({
    type: UPDATE_TOOLTIP,
    tooltipName,
    showTooltip,
  });
};
