export default theme => ({
  root: {
    padding: '0.4rem 0',
    position: 'relative',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  input: {
    backgroundColor: theme.palette.white.dark,
    fontSize: '0.8rem',
    height: '2rem',
  },
  searchIcon: {
    color: theme.palette.black.light,
    paddingLeft: '0.4rem',
  },
});
