import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mpTrack } from 'utils/copenhagen';
import { first, isEmpty } from 'lodash';
import Joyride, { STATUS, EVENTS } from 'react-joyride';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import { setSearchParam, resetSearchParams } from 'redux/searchParams/actions';
import {
  STEPS_INDXES,
  LOCATION_SEARCH_QUERY,
  TAGS_SEARCH_QUERY,
  TAGS_SEARCH_QUERY_2,
} from 'constants/onboarding';
import { addSelectedInfluencer } from 'redux/searchDisplay/actions';
import { scrollIntoView } from 'utils/helpers';
import { getInfluencerName } from 'utils/influencers';
import { patchUserProfile } from 'redux/user/actions';
import { withRouter } from 'react-router';
import { searchUrl, listsUrl } from 'constants/routing';
import LocationTooltip from './LocationTooltip';
import TagsTooltip from './TagsTooltip';
import Tags2Tooltip from './Tags2Tooltip';
import SavedListTooltip from './SavedListTooltip';
import SavedList2Tooltip from './SavedList2Tooltip';
import SavedList3Tooltip from './SavedList3Tooltip';
import SavedList4Tooltip from './SavedList4Tooltip';
import SavedListViewTooltip from './SavedListViewTooltip';
import SavedListDetailViewTooltip from './SavedListDetailViewTooltip';
import SavedListDetailView2Tooltip from './SavedListDetailView2Tooltip';
import HelpTooltip from './HelpTooltip';
import CategoryTooltip from './CategoryTooltip';


class OnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.joyride = React.createRef();
  }

  state = {
    steps: [
      {
        target: '.spotlight__category_search',
        content: 'Pick a category to start browsing influencers',
        tooltipComponent: CategoryTooltip,
        placement: 'top',
        pageLocation: searchUrl(),
      },
      {
        target: '#location_search',
        content: 'Let\'s find foodies in LA and NYC',
        placement: 'right',
        tooltipComponent: LocationTooltip,
        pageLocation: searchUrl(),
      },
      {
        target: '.label_search',
        content: 'Now we\'re hungry for pizza',
        placement: 'right',
        tooltipComponent: TagsTooltip,
        pageLocation: searchUrl(),
      },
      {
        target: '.label_search',
        content: 'Hope you left room for dessert',
        placement: 'right',
        tooltipComponent: Tags2Tooltip,
        pageLocation: searchUrl(),
      },
      {
        target: '#saved_lists',
        content: 'Let\'s create a saved list of our favorite dessert influencers, select an influencer here',
        placement: 'bottom',
        tooltipComponent: SavedListTooltip,
        pageLocation: searchUrl(),
      },
      {
        target: '#search_results > div:first-child .influnecer_select',
        content: 'Select the checkbox to select an influencer',
        placement: 'top',
        tooltipComponent: SavedList2Tooltip,
        pageLocation: searchUrl(),
      },
      {
        target: '#saved_lists',
        content: 'You can then add your influencer to a saved list here. ',
        placement: 'bottom',
        tooltipComponent: SavedList3Tooltip,
        pageLocation: searchUrl(),
      },
      {
        target: '#saved_list_nav_link',
        content: 'You can get to your saved lists at anytime by clicking here.',
        placement: 'bottom',
        tooltipComponent: SavedList4Tooltip,
        pageLocation: searchUrl(),
      },
      {
        target: '#saved_lists_view > li.list_tile:first-child',
        content: 'View details on a list by clicking on the list tile.',
        placement: 'right',
        tooltipComponent: SavedListViewTooltip,
        pageLocation: listsUrl(),
      },
      {
        target: '#list_detail_stats',
        content: 'View list stats above.',
        placement: 'top',
        tooltipComponent: SavedListDetailViewTooltip,
      },
      {
        target: '#search_nav_link',
        content: 'At any time you can get back to search here.',
        placement: 'bottom',
        tooltipComponent: SavedListDetailView2Tooltip,
      },
      {
        target: '#help-button',
        content: 'Need help? At anytime ask for more help here.',
        placement: 'bottom',
        tooltipComponent: HelpTooltip,
        isLastStep: true,
      },
    ],
  };

  handleStepStartSearchCategories = () => {
    setTimeout(() => {
      this.props.updateOnBoarding({
        showCategoryMenu: true,
      });
    }, 500);

    setTimeout(() => {
      scrollIntoView('#category_search', 'end');
    }, 1000);
  }

  handleStepStartLocationSearch = () => {
    setTimeout(async () => {
      await this.props.resetSearchParams();
      await this.props.setSearchParam('locations', LOCATION_SEARCH_QUERY.locations);
      await this.props.setSearchParam('categories', LOCATION_SEARCH_QUERY.categories);
      await this.props.setSearchParam('followerRange', LOCATION_SEARCH_QUERY.followerRange);
      scrollIntoView('#location_search', 'end');
    }, 500);
  }

  handleStepStartTagSearch = () => {
    setTimeout(async () => {
      await this.props.resetSearchParams();
      await this.props.setSearchParam('locations', LOCATION_SEARCH_QUERY.locations);
      await this.props.setSearchParam('categories', LOCATION_SEARCH_QUERY.categories);
      await this.props.setSearchParam('followerRange', LOCATION_SEARCH_QUERY.followerRange);
      await this.props.setSearchParam('labels', TAGS_SEARCH_QUERY);
      scrollIntoView('.label_search', 'end');
    }, 500);
  }

  handleStepStartTagSearch2 = () => {
    setTimeout(async () => {
      await this.props.resetSearchParams();
      await this.props.setSearchParam('locations', LOCATION_SEARCH_QUERY.locations);
      await this.props.setSearchParam('categories', LOCATION_SEARCH_QUERY.categories);
      await this.props.setSearchParam('followerRange', LOCATION_SEARCH_QUERY.followerRange);
      await this.props.setSearchParam('labels', TAGS_SEARCH_QUERY_2);
      scrollIntoView('.label_search', 'end');
    }, 500);
  }

  handleStepSavedList2 = () => {
    const { influencers: { influencers } } = this.props;
    const firstInfluencer = first(influencers);
    if (!firstInfluencer) {
      return;
    }
    setTimeout(async () => {
      this.props.addSelectedInfluencer(firstInfluencer.id, getInfluencerName(firstInfluencer));
    }, 1000);
  }

  updateUserTutorial = () => {
    this.props.patchUserProfile({
      tutorialCompleted: true,
    });
  }

  stepCallback = (data) => {
    const {
      index,
      status,
      type,
    } = data;
    if (index === STEPS_INDXES.SEARCH_CATEGORIES && status === STATUS.RUNNING && type === EVENTS.TOOLTIP) {
      this.handleStepStartSearchCategories();
      this.updateUserTutorial();
      mpTrack('started__onboarding', {});
      return;
    } else if (index === STEPS_INDXES.SEARCH_LOCATIONS && status === STATUS.RUNNING && type === EVENTS.BEACON) {
      this.handleStepStartLocationSearch();
    } else if (index === STEPS_INDXES.SEARCH_TAGS && status === STATUS.RUNNING && type === EVENTS.BEACON) {
      this.handleStepStartTagSearch();
    } else if (index === STEPS_INDXES.SEARCH_TAGS_2 && status === STATUS.RUNNING && type === EVENTS.BEACON) {
      this.handleStepStartTagSearch2();
    } else if (index === STEPS_INDXES.SAVED_LIST_2 && status === STATUS.RUNNING && type === EVENTS.BEACON) {
      this.handleStepSavedList2();
    }
    this.props.updateOnBoarding({
      showCategoryMenu: false,
    });
  }

  render() {
    const { steps } = this.state;
    const {
      stepIndex,
      run,
      userProfile,
      isCompleted,
      isAuthenticated,
    } = this.props;
    const currentStep = steps[stepIndex];
    if (!run
      || !isAuthenticated
      || (userProfile.loaded && userProfile.data.tutorialCompleted)
      || !userProfile.loaded
      || isCompleted
      || (!isEmpty(currentStep.pageLocation) && currentStep.pageLocation !== this.props.location.pathname)) {
      return null;
    }
    return (
      <Joyride
        ref={this.joyride}
        stepIndex={stepIndex}
        steps={steps}
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
        spotlightClicks
        callback={this.stepCallback}
        run={run}
        disableScrolling
      />
    );
  }
}

OnBoarding.propTypes = {
  stepIndex: PropTypes.number.isRequired,
  run: PropTypes.bool.isRequired,
  updateOnBoarding: PropTypes.func.isRequired,
  setSearchParam: PropTypes.func.isRequired,
  resetSearchParams: PropTypes.func.isRequired,
  addSelectedInfluencer: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  userProfile: PropTypes.shape({
    data: PropTypes.shape({
      tutorialCompleted: PropTypes.bool,
    }),
    loaded: PropTypes.bool,
  }).isRequired,
  influencers: PropTypes.shape({
    total: PropTypes.number,
    influencers: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }).isRequired,
  patchUserProfile: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    stepIndex: state.onBoarding.stepIndex,
    run: state.onBoarding.run,
    isCompleted: state.onBoarding.isCompleted,
    influencers: state.influencers,
    userProfile: state.userProfile,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    updateOnBoarding,
    setSearchParam,
    resetSearchParams,
    addSelectedInfluencer,
    patchUserProfile,
  },
)(withRouter(OnBoarding));
