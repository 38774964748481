import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { getCompetitiveReportPosts } from 'redux/reports/actions';
import Loader from 'components/base/Loader';
import { withRouter } from 'react-router';
import { get, isEmpty } from 'lodash';
import { DEFAULT_POST_PARAMS } from '../constants';
import { HeaderSort,
  HeaderPlatformFilter,
  HeaderDateFilter,
  HeaderBrandFilter,
  HeaderSponsoredFilter } from './Header';
import Post from './Post';
import TabHeader from '../TabHeader';

const styles = () => ({
  root: {},
  filterSelect: {
    marginBottom: '2rem',
  },
  card: {
    marginBottom: '1.8rem',
  },
});

class TopContent extends React.Component {
  componentDidMount() {
    const { brands, match: { params: { reportId } } } = this.props;
    this.props.getCompetitiveReportPosts(reportId, {
      ...DEFAULT_POST_PARAMS,
      brands: brands.map(b => b.id),
    });
  }

  render() {
    const {
      classes,
      isLoading,
      loaded,
      data: { posts },
    } = this.props;
    return (
      <div className={classes.root}>
        <TabHeader
          title="Top Content"
          description="Shows the top-performing posts by brand and platform. Our filters and
                      sorting capability allow you to determine what has worked for each brand
                      and platform."
        >
          <HeaderSort />
          <HeaderDateFilter />
        </TabHeader>

        <div className={classes.filterSelect}>
          <HeaderPlatformFilter />
          <HeaderBrandFilter />
          <HeaderSponsoredFilter />
        </div>

        <Loader loading={isLoading} loaded={loaded}>
          <div>
            {isEmpty(posts) && <Alert severity="warning">No data found for this report and params.</Alert>}
            <Grid container spacing={2} justify="center">
              {posts.map(p => (
                <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                  <Post data={p} />
                </Grid>
              ))}
            </Grid>
          </div>
        </Loader>
      </div>
    );
  }
}

TopContent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  getCompetitiveReportPosts: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number,
    }),
  }).isRequired,
};

TopContent.defaultProps = {
  brands: [],
};

const mapStateToProps = ({ competitiveReportPosts, competitiveReportStat }) => ({
  isLoading: competitiveReportPosts.loading,
  loaded: competitiveReportPosts.loaded,
  data: competitiveReportPosts.data,
  brands: get(competitiveReportStat, 'data.brands', []),
});

export default withAll(
  connect(mapStateToProps, { getCompetitiveReportPosts }),
  withStyles(styles),
  withRouter,
)(TopContent);
