import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Dimensions from 'react-dimensions';
import { withStyles } from '@material-ui/styles';
import NoResultCard from 'components/base/NoResultCard';
import { connect } from 'react-redux';
import {
  Typography,
} from '@material-ui/core';
import { getInfluencerSponsoredPost } from 'redux/influencers/actions';
import Force from 'components/base/charts/Force';
import styles from './styles';
import Brands from './Brands';
import Posts from '../Posts';


class SponsoredPosts extends Component {
  handleClick = (tag) => {
    const { influencerProfile } = this.props;
    this.props.getInfluencerSponsoredPost(influencerProfile.data.id, tag);
  }

  render() {
    const {
      sponsoredTags,
      containerWidth,
      classes,
      brands,
      theme,
      influencerSponsoredPosts: { posts, loading },
    } = this.props;
    return (
      <div>
        {isEmpty(sponsoredTags) &&
          <div className={classes.tabs__content__container_empty}>
            <NoResultCard noResultMessage="No sponsored data." />
          </div>}
        {!isEmpty(sponsoredTags) &&
          <React.Fragment>
            <div className={classes.bubble__container}>
              <Typography variant="h5">Sponsored tags</Typography>
              <Force
                data={{
                  nodes: sponsoredTags.map(d => ({ label: d.text, value: d.count })),
                  links: [],
                }}
                width={containerWidth}
                height={300}
                onSelectNode={node => this.handleClick(node.label)}
                nodeColor={theme.palette.blue.main}
              />
            </div>
            <div className={classes.brands}>
              <Typography variant="h5">Brands</Typography>
              <Brands brands={brands} />
            </div>
            <div>
              <Typography variant="h5">Posts</Typography>
              <Posts posts={posts} loading={loading} />
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

SponsoredPosts.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    postCount: PropTypes.number,
    instagramUsername: PropTypes.string,
  })).isRequired,
  sponsoredTags: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    count: PropTypes.number,
  })).isRequired,
  influencerProfile: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  containerWidth: PropTypes.number.isRequired,
  influencerSponsoredPosts: PropTypes.shape({}),
  getInfluencerSponsoredPost: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

SponsoredPosts.defaultProps = {
  influencerSponsoredPosts: {},
};

export default connect(
  state => ({
    influencerProfile: state.influencerProfile,
    influencerSponsoredPosts: state.influencerSponsoredPosts,
  }),
  { getInfluencerSponsoredPost },
)(Dimensions()(withStyles(styles, { withTheme: true })(SponsoredPosts)));
