export default theme => ({
  no_result__icon: {
    fontSize: '2rem',
  },
  brand__link: {
    color: theme.palette.white.light,
    cursor: 'pointer',
  },
  chip: {
    cursor: 'pointer',
  },
});
