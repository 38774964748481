import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/styles';
import H6 from 'components/base/titles/CardHeading/H6';
import Chip from 'components/base/Chip';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';

const styles = () => ({
  root: {
    textAlign: 'center',
  },
});

const AudienceInterest = ({ classes, data: { values }, isExportedAsPDF }) => {
  const presentValues = values.filter(v => !isEmpty(v));
  if (!presentValues.length) {
    return null;
  }
  return (
    <div className={classes.root}>
      <H6 align="center">
        <LabelWithInfoIcon
          labelText="Audience Interests"
          tooltip="The most probable interests of the audience."
        />
      </H6>
      {presentValues.map(v =>
      (<Chip
        label={v}
        variant="outlined"
        isExportedAsPDF={isExportedAsPDF}
      />))}
    </div>
  );
};

AudienceInterest.propTypes = {
  data: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  isExportedAsPDF: PropTypes.bool,
};

AudienceInterest.defaultProps = {
  isExportedAsPDF: false,
};

export default withStyles(styles)(AudienceInterest);
