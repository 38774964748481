import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Icon, CardActions, Grid, CardContent } from '@material-ui/core';
import Button from 'components/base/Button';
import Input from 'components/base/forms/Input';
import { ImageUpload as ImageUploadBase } from 'components/base/ImageUpload';
import PropTypes from 'prop-types';
import Error from 'components/base/forms/Error/index';
import { combine, required, validateForm, length, url, format } from 'redux-form-validators';
import { forms } from 'constants/config';
import styles from './styles';
import VerticalSelectField from './VerticalSelectField';

const lowerWithoutAt = value => value && value.toLowerCase().replace('@', '');

const customValidate = {
  name: combine(
    required({ msg: 'Name is required.' }),
    length({ max: 100, msg: 'Value exceeds 100 characters' }),
  ),
  vertical: combine(
    required({ msg: 'Vertical is required.' }),
    length({ max: 50, msg: 'Value exceeds 50 characters' }),
  ),
  parentBrand: combine(length({ max: 100, msg: 'Value exceeds 100 characters' })),
  instagramUsername: combine(
    format({ with: /^[a-z0-9._]+$/i, allowBlank: true, msg: 'Only letters and numbers are allowed. Exception "." and "_".' }),
    required({ msg: 'Instagram username is required.' }),
    length({ max: 30, msg: 'Value exceeds 30 characters' }),
  ),
  twitterUsername: combine(
    format({ with: /^[a-z0-9_]+$/i, allowBlank: true, msg: 'Only letters and numbers are allowed. Exception "_".' }),
    length({ max: 15, msg: 'Value exceeds 15 characters' }),
  ),
  tiktokUsername: combine(
    format({ with: /^[a-z0-9._]+$/i, allowBlank: true, msg: 'Only letters and numbers are allowed. Exception "." and "_".' }),
    length({ max: 24, msg: 'Value exceeds 24 characters' }),
  ),
  youtubeUsername: combine(
    url({ allowBlank: true }),
    length({ max: 255, msg: 'Value exceeds 255 characters' }),
  ),
  pinterestUsername: combine(
    format({ with: /^[a-z0-9_]+$/i, allowBlank: true, msg: 'Only letters and numbers are allowed. Exception "_".' }),
    length({ max: 30, msg: 'Value exceeds 30 characters' }),
  ),
  domain: combine(
    url({ protocolIdentifier: false, allowBlank: true }),
    length({ max: 255, msg: 'Value exceeds 255 characters' }),
  ),
};

const ImageUpload = withStyles(styles)(ImageUploadBase);

class BrandRequestForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    classes: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                name="logoFile"
                component={ImageUpload}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <Field
                  name="name"
                  component={Input}
                  type="text"
                  placeholder="Name"
                  label="Name"
                  fullWidth
                  required
                />
              </div>
              <div>
                <Field
                  name="vertical"
                  component={VerticalSelectField}
                  required
                />
              </div>
              <div>
                <Field
                  name="parentBrand"
                  component={Input}
                  type="text"
                  placeholder="Parent Brand Name"
                  label="Parent Brand"
                  fullWidth
                />
              </div>
              <div>
                <Field
                  name="instagramUsername"
                  component={Input}
                  type="text"
                  placeholder="Instagram Username (ex: instagramcreator)"
                  label="Instagram Username"
                  fullWidth
                  required
                  normalize={lowerWithoutAt}
                />
              </div>
              <div>
                <Field
                  name="twitterUsername"
                  component={Input}
                  type="text"
                  placeholder="Twitter Username (ex: twittercreator)"
                  label="Twitter Username"
                  fullWidth
                  normalize={lowerWithoutAt}
                />
              </div>
              <div>
                <Field
                  name="tiktokUsername"
                  component={Input}
                  type="text"
                  placeholder="Tiktok Username (ex: tiktokcreator)"
                  label="Tiktok Username"
                  fullWidth
                  normalize={lowerWithoutAt}
                />
              </div>
              <div>
                <Field
                  name="youtubeUsername"
                  component={Input}
                  type="text"
                  placeholder="YouTube Username (ex: https://www.youtube.com/user/youtubecreator)"
                  label="YouTube Username"
                  fullWidth
                  normalize={lowerWithoutAt}
                />
              </div>
              <div>
                <Field
                  name="pinterestUsername"
                  component={Input}
                  type="text"
                  placeholder="Pinterest Username (ex: pinterestcreator)"
                  label="Pinterest Username"
                  fullWidth
                  normalize={lowerWithoutAt}
                />
              </div>
              <div>
                <Field
                  name="domain"
                  component={Input}
                  type="text"
                  placeholder="Domain (ex: domain.com)"
                  label="Domain"
                  fullWidth
                  normalize={lowerWithoutAt}
                />
              </div>
              <div>
                <Error error={error} />
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            id="requestBrand"
          >
            <Icon>send</Icon> Request a Brand
          </Button>
        </CardActions>
      </form>
    );
  }
}

export default reduxForm({
  form: forms.BRAND_REQUEST_FORM,
  validate: validateForm(customValidate),
})(withStyles(styles)(BrandRequestForm));
