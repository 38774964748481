import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'components/base/ToggleButton';
import ToggleButtonGroup from 'components/base/ToggleButtonGroup';
import { ALL_GENDER } from 'constants/options';
import SearchSection from './SearchSection';
import withSearchParam from '../withSearchParam';

class AudienceGenderFilter extends React.PureComponent {
  handleGenderChange = (event, gender) => {
    const { input: { onChange } } = this.props;
    onChange(gender);
  };

  render() {
    const { input: { value } } = this.props;
    return (
      <SearchSection
        id="gender_search_filter"
        title="Audience Gender"
        tooltip="The Gender of the audience as most probable gender by influencer name and country."
      >
        <div>
          <ToggleButtonGroup value={value} exclusive onChange={this.handleGenderChange}>
            <ToggleButton value={ALL_GENDER}>
              All
            </ToggleButton>
            <ToggleButton value="male">
              Skews Male
            </ToggleButton>
            <ToggleButton value="female">
              Skews Female
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </SearchSection>
    );
  }
}

AudienceGenderFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default withSearchParam('audienceGender')(AudienceGenderFilter);
