import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { ALL_LABEL } from 'components/common/CompetitiveReport/constants';
import { PLATFORM_COLOR } from 'constants/options';
import RadialChart from 'components/base/charts/Doughnut/Radial';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import H6 from 'components/base/titles/CardHeading/H6';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import Tooltip from 'components/base/Tooltip';
import { formatNumber } from 'utils/helpers';

const styles = () => ({
  root: {},
  legend: {
    textAlign: 'center',
  },
});


const transformData = (competitiveReportData) => {
  const { postsByPlatform } = competitiveReportData;
  const filteredDataSet = postsByPlatform.dataSets.find(d => d.label === ALL_LABEL);
  return {
    datasets: filteredDataSet.values.map((d, i) => ({
      label: postsByPlatform.labels[i],
      value: d,
      backgroundColor: PLATFORM_COLOR[postsByPlatform.labels[i].toLowerCase()],
    })),
    labels: postsByPlatform.labels,
  };
};


const PostsByPlatform = ({ data, classes }) => (
  <div className={classes.root}>
    <H6 align="center">
      <LabelWithInfoIcon
        labelText="Posts By Platform"
        tooltip="The number of posts across each platform."
      />
    </H6>
    <RadialChart
      data={data}
      height={125}
    />
    <div className={classes.legend}>
      {data.labels.map((p, k) => (
        <Tooltip title={`Posts: ${formatNumber(data.datasets[k].value, '0a', '-')}`} arrow>
          <span><PlatformItem platform={p.toLowerCase()} /></span>
        </Tooltip>
      ))}
    </div>
  </div>
);

PostsByPlatform.propTypes = {
  data: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportPostActivity }) => ({
  data: transformData(competitiveReportPostActivity.data),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(PostsByPlatform);
