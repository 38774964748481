import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ChipFilter from 'components/base/ChipFilter';
import PlatformItem from 'components/common/PlatformFilter/PlatformItem';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '&.single': {
      '.MuiChip-label': {
        paddingLeft: -12,
        paddingRight: -12,
      },
    },
  },
  label: {
    marginRight: '0.3rem',
  },
  single: {
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

const PlatformFilter = ({
  classes,
  singleSelection,
  platforms,
  selected,
  onSelect,
  onRemove,
}) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState(selected);

  const onSelectHandler = (platform) => {
    if (singleSelection) {
      setSelectedPlatforms([platform]);
    } else {
      const newPlatforms = [...selectedPlatforms];
      newPlatforms.push(platform);
      setSelectedPlatforms(newPlatforms);
    }

    onSelect(platform);
  };

  const onRemoveHandler = (platform) => {
    const newPlatforms = selectedPlatforms.filter(p => p !== platform);
    setSelectedPlatforms(newPlatforms);

    onRemove(platform);
  };

  const getRestProps = (platform) => {
    const restProps = {};

    if (singleSelection) {
      restProps.className = classes.single;
    } else {
      restProps.onDelete = () => onRemoveHandler(platform);
    }

    return restProps;
  };

  return (
    platforms.length > 0 && (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.label}>Platform:</Typography>
        {platforms.map(p => (
          <ChipFilter
            key={p}
            buttonSelected={selectedPlatforms.includes(p)}
            onClick={() => onSelectHandler(p)}
            avatar={<PlatformItem platform={p} />}
            {...getRestProps(p)}
          />
        ))}
      </div>
    )
  );
};

PlatformFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  singleSelection: PropTypes.bool,
  platforms: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};

PlatformFilter.defaultProps = {
  singleSelection: false,
  platforms: [],
  selected: [],
  onRemove: noop,
};

export default withStyles(styles)(PlatformFilter);
