import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { CardContent, Grid } from '@material-ui/core';
import Card from 'components/base/Card';
import withAll from 'components/base/withAll';
import PageTitle from 'components/base/titles/PageTitle';
import AgeGroups from './AgeGroups';
import EthicOrigins from './EthicOrigins';
import TopCountries from './TopCountries';
import AudienceInterest from './AudienceInterest';
import Gender from './Gender';

const styles = () => ({
  root: {
    marginLeft: '1rem',
  },
  card: {
    marginBottom: '1.8rem',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const BrandAudience = ({ classes, data, isExportedAsPDF }) => (
  <div className={classes.root} sel="brand_audience">
    <PageTitle title={data.brand.name} />
    <Card className={classes.card} sel="brand_audience_card" brand_name={data.brand.name}>
      <CardContent>
        <Grid container spacing={8}>
          <Grid item md={3} className={classes.gridItem}>
            <Gender data={data.audienceGender} />
          </Grid>
          <Grid item md={3} className={classes.gridItem}>
            <AgeGroups data={data.audienceAge} />
          </Grid>
          <Grid item md={3} className={classes.gridItem}>
            <EthicOrigins data={data.audienceEthnicity} />
          </Grid>
          <Grid item md={3} className={classes.gridItem}>
            <TopCountries data={data.audienceCountries} />
          </Grid>
          <Grid item md={12}>
            <AudienceInterest data={data.audienceInterest} isExportedAsPDF={isExportedAsPDF} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </div>
);

BrandAudience.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    brand: PropTypes.shape({}),
    audienceAge: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      values: PropTypes.arrayOf(PropTypes.number),
    }),
    audienceGender: {
      labels: PropTypes.arrayOf(PropTypes.string),
      values: PropTypes.arrayOf(PropTypes.number),
    },
    audienceEthnicity: {
      labels: PropTypes.arrayOf(PropTypes.string),
      values: PropTypes.arrayOf(PropTypes.number),
    },
    audienceCountries: {
      labels: PropTypes.arrayOf(PropTypes.string),
      values: PropTypes.arrayOf(PropTypes.number),
    },
    audienceInterest: {
      values: PropTypes.arrayOf(PropTypes.string),
    },
  }).isRequired,
  isExportedAsPDF: PropTypes.bool,
};

BrandAudience.defaultProps = {
  isExportedAsPDF: false,
};


export default withAll(withStyles(styles))(BrandAudience);
