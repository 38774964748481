import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { take } from 'lodash';
import { withStyles } from '@material-ui/styles';
import AddToList from 'components/common/lists/AddToList/MultipleAddToList';
import PageTitle from 'components/base/titles/PageTitle';
import { Link } from 'react-router-dom';
import { searchUrl } from 'constants/routing';
import { fetchFeaturedInfluencers } from '../../../../redux/featured/influencers/actions';
import styles from './styles';
import SelectedInfluencers from '../../../common/SelectedInfluencers';
import InfluencerSelectionManager from '../../../common/InfluencerSelectionManager';
import InfluencerGrid from '../../../common/InfluencerGrid';

const influencerSubtitle = () => (
  <span>
    <Link to={searchUrl()} >Use the filter options</Link> to find relevant creators.
  </span>
);

class Influencers extends React.Component {
  componentDidMount() {
    this.props.fetchFeaturedInfluencers();
  }

  render() {
    const { classes, featuredInfluencers } = this.props;
    return (
      <InfluencerSelectionManager>
        <div className={classes.container}>
          <div className={classes.header} >
            <PageTitle
              title="Suggested Creators"
              subtitle={influencerSubtitle()}
            />
            <div className={classes.action__buttons}>
              <div className={classes.add__to__list}>
                <SelectedInfluencers
                  buttonProps={{
                    variant: 'contained',
                    color: 'primary',
                    style: {
                      marginRight: 10,
                    },
                  }}
                />
                <AddToList />
              </div>
            </div>
          </div>
          <InfluencerGrid
            columns={4}
            influencers={take(featuredInfluencers, 8)}
          />
        </div>
      </InfluencerSelectionManager>
    );
  }
}

Influencers.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  fetchFeaturedInfluencers: PropTypes.func.isRequired,
  featuredInfluencers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ featured: { influencers: { influencers: { data, loading } } } }) => ({
  featuredInfluencers: data || [],
  loading,
});

const mapDispatchToProps = {
  fetchFeaturedInfluencers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Influencers));
