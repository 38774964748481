import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import PostActivity from './PostActivity';
import PlatformDistribution from './PlatformDistribution';
import AudienceDemographics from './AudienceDemographics';


const SalesPDFExport = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <Grid>
      <Typography variant="h2" align="center">
        Report {props.title}
      </Typography>
      <Typography variant="h3" align="center">
        <b> GO PRISM INSIGHTS</b>
      </Typography>
      <Typography variant="h4" align="center">
        Using Go Prism <sup>TM</sup>, a proprietary competitive intelligence platform
        built by Open Influence, we extracted the following actionable insights
      </Typography>
      <PostActivity viewFullReport />
      <PlatformDistribution />
      <AudienceDemographics isExportedAsPDF />
    </Grid>
  </div>
));


SalesPDFExport.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default SalesPDFExport;
