import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'components/base/ToggleButton';
import ToggleButtonGroup from 'components/base/ToggleButtonGroup';
import SearchSection from './SearchSection';
import withSearchParam from '../withSearchParam';

const ALL_GENDER = 'ALL';

class GenderFilter extends React.PureComponent {
  handleGenderChange = (event, gender) => {
    const { input: { onChange } } = this.props;
    onChange(gender);
  };

  render() {
    const { input: { value } } = this.props;
    return (
      <SearchSection
        id="gender_search_filter"
        title="Creator Gender"
        tooltip="The Gender of the creator as most probable gender by influencer name and country."
      >
        <div>
          <ToggleButtonGroup value={value} exclusive onChange={this.handleGenderChange}>
            <ToggleButton value={ALL_GENDER} className="gender-btn__any">
              Any gender
            </ToggleButton>
            <ToggleButton value="m" className="gender-btn__male">
              Male
            </ToggleButton>
            <ToggleButton value="f" className="gender-btn__female">
              Female
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </SearchSection>
    );
  }
}

GenderFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default withSearchParam('gender')(GenderFilter);
