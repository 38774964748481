import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'components/base/Pagination';
import withAll from 'components/base/withAll';
import UpgradeModal from 'components/common/UpgradeModal';
import { scrollToTop } from 'utils/helpers';
import withModal from 'components/base/withModal';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import withInfluencerSearch from 'components/common/withInfluencerSearch';
import styles from './styles';

class SearchPagination extends Component {
  onChangePage = (page) => {
    const {
      searchParams,
      openModal,
      isAuthenticated,
    } = this.props;
    if (!isAuthenticated) {
      openModal();
      return;
    }

    scrollToTop();

    this.props.getInfluencers({
      ...searchParams,
      page,
    });
  };

  render() {
    const {
      classes,
      influencers,
      searchParams,
      open,
      closeModal,
      isAuthenticated,
    } = this.props;
    return (
      <div className={classes.root}>
        <Pagination
          total={influencers.pagination.total}
          current={influencers.pagination.page}
          pageSize={searchParams.limit}
          display={5}
          onChange={this.onChangePage}
        />
        {isAuthenticated && <UpgradeModal open={open} closeModal={closeModal} />}
        {!isAuthenticated && <BetaSignupModal
          open={open}
          closeModal={closeModal}
          onSignupComplete={closeModal}
        />}
      </div>
    );
  }
}

SearchPagination.propTypes = {
  searchParams: PropTypes.shape({}).isRequired,
  influencers: PropTypes.shape({
    pagination: PropTypes.shape({
      total: PropTypes.number,
      page: PropTypes.number,
    }).isRequired,
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  getInfluencers: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default withAll(
  withStyles(styles, { withTheme: true }),
  withModal,
  connect(
    state => ({
      influencers: state.influencers,
      searchParams: state.searchParams,
      isAuthenticated: state.auth.isAuthenticated,
    }),
    null,
  ),
  withInfluencerSearch,
)(SearchPagination);
