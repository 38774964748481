import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from 'components/base/Card';
import { CardContent } from '@material-ui/core';
import withAll from 'components/base/withAll';
import Button from 'components/base/Button';
import { connect } from 'react-redux';
import { setTooltip } from 'redux/app/actions';

const TOOLTIP_VISIBLE_TIME = 2000;
const TOOLTIP_NAME = 'reportAddBrandTooltip';

const styles = theme => ({
  root: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
  },
  indexAvatar: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.white.main,
    margin: '0 auto',
  },
  logo: {
    height: '3rem',
    backgroundSize: 'contain',
  },
  logoContainer: {
    padding: '1rem',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '11em',
  },
  addCompanyButton: {
    background: `${theme.palette.green.main}`,
    color: `${theme.palette.white.main}`,
    width: '15rem',
    margin: '0 auto',
    textAlign: 'center',
    marginTop: '4rem',
    padding: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      background: `${theme.palette.green.main}`,
      color: `${theme.palette.white.main}`,
    },
  },
});

class Brand extends React.Component {
  handleClick() {
    const { inputRef } = this.props;
    this.props.setTooltip(TOOLTIP_NAME, true);
    inputRef.current.focus();
    setTimeout(() => {
      this.props.setTooltip(TOOLTIP_NAME, false);
    }, TOOLTIP_VISIBLE_TIME);
  }

  render() {
    const { classes, index } = this.props;
    return (
      <Card className={classes.root} index={index}>
        <CardContent className={classes.cardContent}>
          {index === 0 &&
          <Button
            className={classes.addCompanyButton}
            onClick={() => this.handleClick()}
          >
            <span>+ Add Company to Analysis</span>
          </Button>}
        </CardContent>
      </Card>);
  }
}

Brand.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  setTooltip: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = {
  setTooltip,
};

export default withAll(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(Brand);
