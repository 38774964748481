import { combineHashtagAndMentions } from 'lib/utils';
import { get } from 'lodash';
import { newReducer } from '../utils';
import { updateInfluencerBrandSafetyReport } from '../brandSafetyReports/utils';

const initialState = {
  influencers: {
    influencers: [],
    pagination: {
      total: null,
      page: null,
    },
    loading: false,
    error: false,
  },
  influencerNavigation: {
    data: {},
  },
  influencerProfile: {
    data: {},
    loading: false,
    loaded: false,
  },
  influencerTopPosts: {
    posts: [],
    loading: false,
  },
  influencerTopContent: {
    posts: [],
    loading: false,
  },
  requestNewInfluencers: {
    loading: false,
    loaded: false,
  },
};

export const influencerReducer = newReducer(initialState.influencers);

export const influencerProfileReducer = newReducer(initialState.influencerProfile);

export const influencerProfileNavigationReducer = newReducer(initialState.influencerNavigation);

export const influencerTopContentReducer = newReducer(initialState.influencerTopContent);

export const influencerTopPostsReducer = newReducer(initialState.influencerTopPosts);

export const influencerSponsoredPostsReducer = newReducer(initialState.influencerTopPosts);

export const requestNewInfluencerReducer = newReducer(initialState.requestNewInfluencers);

requestNewInfluencerReducer.method.REQUEST_NEW_INFLUENCERS_FULFILLED = (state, action) => ({
  ...state,
  ...action.payload,
  loading: false,
});

requestNewInfluencerReducer.method.REQUEST_NEW_INFLUENCERS_REJECTED = state => ({
  ...state,
  loading: false,
});

requestNewInfluencerReducer.method.REQUEST_NEW_INFLUENCERS_PENDING = state => ({
  ...state,
  loading: true,
});

influencerProfileNavigationReducer.method.GET_INFLUENCERS_FULFILLED = (state, action) => ({
  data: get(action.payload, 'influencers'),
});

influencerProfileNavigationReducer.method.FETCH_FEATURED_INFLUENCERS_FULFILLED = (state, action) => ({
  data: get(action.payload, 'influencers'),
});

influencerReducer.method.GET_INFLUENCERS_FULFILLED = (state, action) => ({
  ...state,
  ...action.payload,
  loading: false,
});

influencerReducer.method.GET_INFLUENCERS_PENDING = state => ({
  ...state,
  loading: true,
});

influencerReducer.method.GET_INFLUENCERS_REJECTED = state => ({
  ...state,
  loading: false,
});

influencerReducer.method.UPDATE_ERROR_STATE = (state, action) => ({
  ...state,
  error: action.value,
});

influencerReducer.method.CREATE_BRAND_SAFETY_REPORTS_FULFILLED = (state, action) => ({
  ...state,
  influencers: updateInfluencerBrandSafetyReport(state.influencers, get(action, 'payload.results', [])),
});

influencerProfileReducer.method.GET_INFLUENCER_PROFILE_FULFILLED = (state, action) => ({
  data: {
    ...action.payload,
    topHashtagsMentions: combineHashtagAndMentions(action.payload.topHashtags, action.payload.topMentions),
  },
  loading: false,
  loaded: true,
});

influencerProfileReducer.method.GET_INFLUENCER_PROFILE_PENDING = state => ({
  ...state,
  loading: true,
});

influencerProfileReducer.method.GET_INFLUENCER_PROFILE_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

influencerTopContentReducer.method.GET_INFLUENCER_TOP_CONTENT_FULFILLED = (state, action) => ({
  ...state,
  ...action.payload,
  loading: false,
});

influencerTopContentReducer.method.GET_INFLUENCER_TOP_CONTENT = (state, action) => ({
  ...state,
  ...action,
  loading: false,
});

influencerTopContentReducer.method.GET_INFLUENCER_TOP_CONTENT_PENDING = state => ({
  ...state,
  loading: true,
});

influencerTopContentReducer.method.GET_INFLUENCER_TOP_CONTENT_REJECTED = state => ({
  ...state,
  loading: false,
});

influencerTopPostsReducer.method.GET_INFLUENCER_TOP_POSTS_FULFILLED = (state, action) => ({
  ...state,
  ...action.payload,
  loading: false,
});

influencerTopPostsReducer.method.GET_INFLUENCER_TOP_POSTS = (state, action) => ({
  ...state,
  ...action,
  loading: false,
});

influencerTopPostsReducer.method.GET_INFLUENCER_TOP_POSTS_PENDING = state => ({
  ...state,
  loading: true,
});

influencerTopPostsReducer.method.GET_INFLUENCER_TOP_POSTS_REJECTED = state => ({
  ...state,
  loading: false,
});

influencerSponsoredPostsReducer.method.GET_INFLUENCER_SPONSORED_POSTS_FULFILLED = (state, action) => ({
  ...state,
  ...action.payload,
  loading: false,
});

influencerSponsoredPostsReducer.method.GET_INFLUENCER_SPONSORED_POSTS = (state, action) => ({
  ...state,
  ...action,
  loading: false,
});

influencerSponsoredPostsReducer.method.GET_INFLUENCER_SPONSORED_POSTS_PENDING = state => ({
  ...state,
  loading: true,
});

influencerSponsoredPostsReducer.method.GET_INFLUENCER_SPONSORED_POSTS_REJECTED = state => ({
  ...state,
  loading: false,
});

