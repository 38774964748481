import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import BaseModal from 'components/base/modals/BaseModal';
import UpgradeButton from 'components/common/UpgradeButton';
import H6 from 'components/base/titles/CardHeading/H6';
import styles from './styles';

const UpgradeModal = ({
  classes,
  closeModal,
  open,
  messageBody,
  messageTitle,
}) => (
  <BaseModal
    open={open}
    onClose={closeModal}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    title={messageTitle}
    height="auto"
    width="700"
  >
    <div className={classes.root}>
      <H6 align="center">{messageBody}</H6>
      <UpgradeButton fullWidth />
    </div>
  </BaseModal>
);

UpgradeModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  messageBody: PropTypes.string,
  messageTitle: PropTypes.string,
};

UpgradeModal.defaultProps = {
  messageBody: 'Get full access by subscribing below.',
  messageTitle: 'Upgrade subscription',
};

export default withStyles(styles)(UpgradeModal);
