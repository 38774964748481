import React from 'react';
import {
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Button from 'components/base/Button';
import withAll from 'components/base/withAll';
import { withStyles } from '@material-ui/styles';
import { keys } from 'lodash';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  label: {
    paddingRight: '0.4rem',
    width: '3rem',
  },
});


function SortOptions({
  classes,
  handleSelect,
  selectedValue,
  options,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(selectedValue);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (p) => {
    setAnchorEl(null);
    setSelectedOption(p);
  };

  const onSelect = (sort) => {
    handleClose(sort);
    handleSelect(sort);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.label}>Sort by</Typography>
      <Button
        variant="contained"
        color="secondary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {options[selectedValue]}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(selectedOption)}
      >
        {keys(options).map(k => (
          <MenuItem onClick={() => onSelect(k)}>{options[k]}</MenuItem>
        ))}
      </Menu>
    </div>
  );
}

SortOptions.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
};

SortOptions.defaultProps = {
  selectedValue: null,
};

export default withAll(withStyles(styles))(SortOptions);
