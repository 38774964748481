export default theme => ({
  root: {
    boxShadow: theme.customShadows[1],
    textAlign: 'center',
    height: ({ canViewTopBrands }) => (
      canViewTopBrands ? 'auto' : '391px'
    ),
    position: 'relative',
  },
  previewImgSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginTop: '1.6rem',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  avatar: {
    width: '6rem',
    height: '6rem',
    cursor: 'pointer',
  },
  bubbleContainer: {
    marginBottom: '0.8rem',
    display: 'flex',
  },
  avatarWrapper: {
    position: 'relative',
  },
  location: {
    marginBottom: '0.4rem',
    height: '20px',
  },
  locationText: {
    color: theme.palette.black.light,
  },
  gridImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  platforms: {
    width: '100%',
    paddingLeft: '0.4rem',
    '& svg': {
      overflow: 'visible',
    },
    '& .svg-inline--fa': {
      position: 'absolute',
      top: '50%',
      bottom: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& .has-label .svg-inline--fa': {
      transform: 'translate(-50%, -75%)',
    },
  },
});
