import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Card, Typography } from '@material-ui/core';
import styles from './styles';

const NoResultCard = ({
  classes,
  noResultMessage,
}) => (
  <Card className={classes.root}>
    <Typography variant="h5" component="h3" className={classes.title}>
      {noResultMessage}
    </Typography>
  </Card>
);

NoResultCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  noResultMessage: PropTypes.string,
};

NoResultCard.defaultProps = {
  noResultMessage: 'No results found.',
};

export default withStyles(styles)(NoResultCard);
