export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  searchContent: {
    flexGrow: 1,
    transition: 'all 0.5s ease-out',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0!important',
    },
  },
});
