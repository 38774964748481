import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import FollowerFilter from './FollowerFilter';
import withSearchParam from '../../withSearchParam';
import Icon from './Icon';
import styles from './styles';
import { PLATFORMS } from '../../../../../constants/options';

const InstagramFollowerFilter = ({ classes, input: { onChange, value } }) => (
  <FollowerFilter
    platform={PLATFORMS.INSTAGRAM}
    name="instagram_follower_count"
    title="Followers"
    icon={<Icon icon={faInstagram} className={classes.igIcon} />}
    value={value}
    onChange={onChange}
  />
);

InstagramFollowerFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default withSearchParam('igFollowerRange')(withStyles(styles)(InstagramFollowerFilter));
