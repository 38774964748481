import { newReducer } from '../utils';

const initialState = {
  countries: {
    loading: false,
    results: [],
  },
  locations: {
    loading: false,
    results: [],
  },
  keywords: {
    loading: false,
    results: [],
  },
  labels: {
    loading: false,
    results: [],
  },
  usernames: {
    loading: false,
    results: [],
  },
  brands: {
    loading: false,
    loaded: false,
    results: [],
  },
};

export const countriesAutoCompleteReducer = newReducer(initialState.countries);

export const locationAutoCompleteReducer = newReducer(initialState.locations);

export const keywordAutoCompleteReducer = newReducer(initialState.keywords);

export const labelAutoCompleteReducer = newReducer(initialState.labels);

export const usernameAutoCompleteReducer = newReducer(initialState.usernames);

export const brandAutocompleteReducer = newReducer(initialState.brands);

locationAutoCompleteReducer.method.GET_LOCATIONS_AUTOCOMPLETE_FULFILLED = (state, action) => ({
  ...state,
  results: action.payload.locations.map(location => ({
    label: location.label,
    value: location,
  })),
  loading: false,
});

locationAutoCompleteReducer.method.GET_LOCATIONS_AUTOCOMPLETE_PENDING = state => ({
  ...state,
  loading: true,
});

locationAutoCompleteReducer.method.GET_LOCATIONS_AUTOCOMPLETE_REJECTED = state => ({
  ...state,
  loading: false,
});

countriesAutoCompleteReducer.method.GET_COUNTRIES_AUTOCOMPLETE_FULFILLED = (state, action) => ({
  ...state,
  results: action.payload.countries.map(c => ({
    label: c.label,
    value: c,
  })),
  loading: false,
});

countriesAutoCompleteReducer.method.GET_COUNTRIES_AUTOCOMPLETE_PENDING = state => ({
  ...state,
  loading: true,
});

countriesAutoCompleteReducer.method.GET_COUNTRIES_AUTOCOMPLETE_REJECTED = state => ({
  ...state,
  loading: false,
});

keywordAutoCompleteReducer.method.GET_KEYWORD_AUTOCOMPLETE_FULFILLED = (state, action) => ({
  ...state,
  results: action.payload.keywords.map(keyword => ({
    label: keyword.label,
    value: keyword,
  })),
  loading: false,
});

keywordAutoCompleteReducer.method.GET_KEYWORD_AUTOCOMPLETE_PENDING = state => ({
  ...state,
  loading: true,
});

keywordAutoCompleteReducer.method.GET_KEYWORD_AUTOCOMPLETE_REJECTED = state => ({
  ...state,
  loading: false,
});

labelAutoCompleteReducer.method.GET_LABEL_AUTOCOMPLETE_FULFILLED = (state, action) => ({
  ...state,
  results: action.payload.labels.map(label => ({
    label: label.label,
    value: label,
  })),
  loading: false,
});

labelAutoCompleteReducer.method.GET_LABEL_AUTOCOMPLETE_PENDING = state => ({
  ...state,
  loading: true,
});

labelAutoCompleteReducer.method.GET_LABEL_AUTOCOMPLETE_REJECTED = state => ({
  ...state,
  loading: false,
});

usernameAutoCompleteReducer.method.GET_USERNAME_AUTOCOMPLETE_FULFILLED = (state, action) => ({
  ...state,
  results: action.payload.hits.map(label => ({
    label: label.label,
    value: label,
  })),
  loading: false,
});

usernameAutoCompleteReducer.method.GET_USERNAME_AUTOCOMPLETE_PENDING = state => ({
  ...state,
  loading: true,
});

usernameAutoCompleteReducer.method.GET_USERNAME_AUTOCOMPLETE_REJECTED = state => ({
  ...state,
  loading: false,
});

brandAutocompleteReducer.method.GET_BRAND_AUTOCOMPLETE_PENDING = state => ({
  ...state,
  loading: true,
});

brandAutocompleteReducer.method.GET_BRAND_AUTOCOMPLETE_FULFILLED = (state, action) => ({
  ...state,
  results: action.payload.hits.map(label => ({
    label: label.label,
    value: label,
  })),
  loading: false,
  loaded: true,
});

brandAutocompleteReducer.method.GET_BRAND_AUTOCOMPLETE_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

