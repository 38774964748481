import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { convertKeyword } from '../utils';

export const GET_RELATED_KEYWORDS = 'GET_RELATED_KEYWORDS';
export const RESET_RELATED_KEYWORDS = 'RESET_RELATED_KEYWORDS';

export const resetRelatedKeywords = () => (dispatch) => {
  dispatch({
    type: RESET_RELATED_KEYWORDS,
  });
};

export const getRelatedKeywords = value => async (dispatch) => {
  await dispatch({
    type: GET_RELATED_KEYWORDS,
    payload: request('post', API.RELATED_KEYWORDS, {
      data: { keywords: value.map(convertKeyword) },
    }),
  }).catch((response) => {
    handleErrors(response);
  });
};
