import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const CheckBoxField = ({
  disabled,
  className,
  name,
  value,
  onChange,
  label,
  color,
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        className={className}
        type="checkbox"
        id={name}
        name={name}
        onChange={onChange}
        checked={value}
        disabled={disabled}
        color={color}
        value={value}
      />
    )}
    label={label}
  />
);

CheckBoxField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

CheckBoxField.defaultProps = {
  label: '',
  disabled: false,
  className: '',
  color: '',
};

export default CheckBoxField;
