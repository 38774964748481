import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { map, size } from 'lodash';
import { connect } from 'react-redux';
import { fetchLists, removeFromLists } from '../../../../redux/lists/actions';
import styles from './styles';
import withSelectedInfluencers from '../../withSelectedInfluencers';
import API from '../../../../constants/api';
import { deselectAllInfluencers } from '../../../../redux/searchDisplay/actions';

class RemoveFromList extends React.Component {
  onClick = async () => {
    const { listId, selectedInfluencers } = this.props;
    const selectedInfluencerIds = map(selectedInfluencers, 'id');
    await this.props.removeFromLists([listId], selectedInfluencerIds);
    this.props.deselectAllInfluencers();
    setTimeout(this.props.fetchLists, API.REFRESH_TIMEOUT);
  };

  render() {
    const { classes, selectedInfluencers } = this.props;
    const selectedInfluencersCount = size(selectedInfluencers) || 0;
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.remove__button}
        disabled={selectedInfluencersCount === 0}
        onClick={this.onClick}
      >
        <DeleteIcon className={classes.delete__icon} />
        Remove from List
      </Button>
    );
  }
}

RemoveFromList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  deselectAllInfluencers: PropTypes.func.isRequired,
  listId: PropTypes.string.isRequired,
  fetchLists: PropTypes.func.isRequired,
  removeFromLists: PropTypes.func.isRequired,
  selectedInfluencers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

const mapDispatchToProps = {
  deselectAllInfluencers,
  fetchLists,
  removeFromLists,
};

export default withSelectedInfluencers(connect(null, mapDispatchToProps)(withStyles(styles)(RemoveFromList)));
