import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Icon, CardActions, CardContent } from '@material-ui/core';
import Input from 'components/base/forms/Input';
import PropTypes from 'prop-types';
import { requiredValidator, emailValidator } from 'utils/validators';
import Error from 'components/base/forms/Error/index';

const FORM_FULL_WIDTH = true;

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <CardContent>
          <div>
            <Field
              name="email"
              component={Input}
              type="text"
              placeholder="Email"
              label="Email"
              fullWidth={FORM_FULL_WIDTH}
              validate={[requiredValidator, emailValidator]}
            />
          </div>
          <div>
            <Field
              name="password"
              component={Input}
              type="password"
              placeholder="Password"
              label="Password"
              fullWidth={FORM_FULL_WIDTH}
              validate={[requiredValidator]}
            />
          </div>
          <div>
            <Error error={error} />
          </div>
        </CardContent>
        <CardActions>
          <Button sel="login-btn" color="primary" size="small" type="submit"><Icon>send</Icon> Login</Button>
        </CardActions>

      </form>
    );
  }
}

export default reduxForm({
  form: 'loginForm', // a unique identifier for this form
})(LogInForm);
