import { Card, IconButton, Menu } from '@material-ui/core';
import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

class MenuButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchor: null,
    };
  }

  handleOpen = (e) => {
    this.setState({
      anchor: e.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchor: null,
    });
  };

  childrenWithOnClick = () => {
    const { children } = this.props;
    return React.Children.map(children, (child) => {
      const { onClick, ...other } = child.props;
      return (
        <child.type
          onClick={(e) => {
            this.handleClose();
            onClick(e);
          }}
          {...other}
        />
      );
    });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { className, icon, id } = this.props;
    const { anchor } = this.state;
    return (
      <div className={className}>
        <IconButton
          aria-label="More"
          aria-controls="menu-list-grow"
          aria-haspopup="true"
          onClick={this.handleOpen}
        >
          {icon}
        </IconButton>
        <Card>
          <Menu
            elevation={0}
            id={id}
            anchorEl={anchor}
            getContentAnchorEl={null}
            open={Boolean(anchor)}
            onClose={this.handleClose}
            disableAutoFocusItem
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {this.childrenWithOnClick()}
          </Menu>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(MenuButton);
