import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  Avatar,
} from '@material-ui/core';
import Chip from 'components/base/Chip';
import { formatNumber } from 'utils/helpers';
import H6 from 'components/base/titles/CardHeading/H6';

const NULL_VALUE_DISPLAY = 'N/A';

const styles = theme => ({
  root: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  titleContainer: {
    textAlign: 'left',
  },
  title: {
    margin: '0 0 0 0.4rem',
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  chipContainer: {
    textAlign: 'right',
    flexGrow: 100,
  },
});

const BrandTitle = ({ classes, data }) => (
  <div className={classes.root}>
    <Avatar
      alt={data.name}
      src={data.logo}
      className={classes.avatar}
      imgProps={{ style: { objectFit: 'contain' } }}
    />
    <div className={classes.titleContainer}>
      <H6 align="left" className={classes.title}>@{data.name}</H6>
      <Typography variant="body1" className={classes.title}>{data.vertical.name}</Typography>
    </div>
    <div className={classes.chipContainer}>
      <Chip
        label={`Post Count ${formatNumber(
          data.totalPostCount,
          '0a',
          NULL_VALUE_DISPLAY,
        )}`}
      />
    </div>
  </div>
);

BrandTitle.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    username: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(BrandTitle);
