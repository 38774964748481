import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { slice } from 'lodash';
import { brandProfileUrl } from 'constants/routing';
import { Link } from 'react-router-dom';
import Brand from './Brand';

const styles = () => ({
  root: {},
  brandLink: {
    textDecoration: 'none',
  },
});

const Brands = ({ classes, brands }) => (
  <Grid container spacing={2} className={classes.root}>
    {brands.map(b => (
      <Grid item xs={12} md={4}>
        <Link
          className={classes.brandLink}
          to={brandProfileUrl(b.id)}
        >
          <Brand data={b} />
        </Link>
      </Grid>
    ))}
  </Grid>
);

Brands.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ vertical }) => ({
  brands: slice(vertical.data.brands, 0, 6),
});

export default connect(mapStateToProps, null)(withStyles(styles)(Brands));
