import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ChipFilter from 'components/base/ChipFilter';
import withAll from 'components/base/withAll';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  label: {
    marginRight: '0.3rem',
  },
});

class BrandFilter extends React.PureComponent {
  optionSelected = brandsSelected => this.props.handleSelect(brandsSelected);

  render() {
    const {
      classes,
      params: { brands: brandsSelected, combinedMentions },
      brands,
      showCombinedMentions,
      combinedMentionsSelected,
    } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.label}>Brand:</Typography>
        {brands.map(b => (
          <ChipFilter
            buttonSelected={brandsSelected.includes(b.id)}
            onDelete={() => this.optionSelected(b.id)}
            onClick={() => this.optionSelected(b.id)}
            disabled={brandsSelected.includes(b.id) && brandsSelected.length === 1}
            label={b.name}
          />
        ))}
        {showCombinedMentions && (
          <ChipFilter
            buttonSelected={combinedMentions}
            onDelete={combinedMentionsSelected}
            onClick={combinedMentionsSelected}
            label="Combined mentions"
          />
        )}
      </div>
    );
  }
}

BrandFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSelect: PropTypes.func.isRequired,
  combinedMentionsSelected: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
  showCombinedMentions: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})),
};

BrandFilter.defaultProps = {
  brands: [],
  showCombinedMentions: false,
};

const mapStateToProps = ({ competitiveReportStat }) => ({
  brands: get(competitiveReportStat, 'data.brands', []),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
  withRouter,
)(BrandFilter);
