import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { reset, SubmissionError } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { CardContent } from '@material-ui/core';
import Card from 'components/base/Card';
import withModal from 'components/base/withModal';
import withAll from 'components/base/withAll';
import PageTitle from 'components/base/titles/PageTitle';
import Button from 'components/base/Button';
import UpgradeModal from 'components/common/UpgradeModal';
import { createCompetitiveReports as createCompetitiveReportsAction } from 'redux/reports/actions';
import { forms } from 'constants/config';
import { maxReportLimit } from 'constants/messages';
import { competitiveReportsUrl } from 'constants/routing';
import CompetitiveReportForm from './CompetitiveReportForm';

const styles = () => ({
  root: {
    overflow: 'visible',
  },
  card: {
    overflow: 'visible',
  },
});

const SuccessMessage = () => (
  <React.Fragment>
    Report has been created.
  </React.Fragment>
);

const CreateReport = ({
  classes,
  resetForm,
  createCompetitiveReports,
  history,
  loading,
  closeModal,
  openModal,
  open,
}) => {
  const onSubmit = async (formData) => {
    try {
      await createCompetitiveReports({
        ...formData,
        brandIds: formData.brandIds.map(b => b.id),
      });
      resetForm();
      toast.success(<SuccessMessage />);
      history.push(competitiveReportsUrl());
    } catch (error) {
      if (error.errors) {
        if (error.errors.status === 403) {
          openModal();
        }
        throw new SubmissionError(error.errors);
      }
    }
  };

  return (
    <div className={classes.root}>
      <PageTitle title="Create Report" />
      <Card className={classes.card}>
        <CardContent>
          <CompetitiveReportForm
            onSubmit={data => onSubmit(data)}
            initial={{ brandIds: [], active: true }}
            actions={(
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={loading}>Save</Button>
            )}
          />
        </CardContent>
      </Card>
      <UpgradeModal open={open} closeModal={closeModal} messageBody={maxReportLimit} />
    </div>
  );
};

CreateReport.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  resetForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  createCompetitiveReports: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ competitiveReport }) => ({
  loading: competitiveReport.loading,
});

const mapDispatchToProps = {
  createCompetitiveReports: createCompetitiveReportsAction,
  resetForm: () => dispatch => dispatch(reset(forms.CREATE_REPORT_FORM)),
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withModal,
  withRouter,
)(CreateReport);
