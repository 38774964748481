export default theme => ({
  root: {
    position: 'relative',
  },
  profile__h5: {
    fontSize: '1rem',
    color: theme.palette.black.dark,
    marginBottom: '1rem',
  },
  audience__col: {
    padding: '0.8rem',
  },
});
