import React from 'react';
import { identity } from 'lodash';
import withRequest from './withRequest';
import API from '../../constants/api';

function withPermission(action, mapPropsToExtra, mapMetaToProps, stillDisplay = false) {
  return (WrappedComponent) => {
    const WithPermission = (props) => {
      const { permitted, meta } = props;
      if (permitted || stillDisplay) {
        const metaProps = mapMetaToProps && meta ? mapMetaToProps(meta) : null;
        return (
          <WrappedComponent
            permitted={permitted}
            {...props}
            {...metaProps}
          />
        );
      }
      return null;
    };

    return withRequest('post', API.SECURITY_PERMITTED, props => ({
      action,
      extra: mapPropsToExtra ? mapPropsToExtra(props) : null,
    }), identity)(WithPermission);
  };
}

export default withPermission;
