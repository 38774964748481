import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { getCompetitiveReportStats } from 'redux/reports/actions';
import CompetitiveReportCommon from 'components/common/CompetitiveReport';
import Loader from 'components/base/Loader';
import { nonExisting, login } from 'constants/routing';
import styles from './styles';

class CompetitiveReport extends PureComponent {
  async componentDidMount() {
    const { reportId } = this.props.match.params;
    try {
      await this.props.getCompetitiveReportStats(reportId);
    } catch (e) {
      if (e.errors.status === 401 || e.errors.status === 403) {
        window.location.href = login();
        return;
      }
      window.location.replace(nonExisting());
    }
  }

  render() {
    const {
      isLoading,
      loaded,
      userProfile,
    } = this.props;
    return (
      <Loader loading={isLoading} loaded={loaded}>
        <CompetitiveReportCommon isInternal={userProfile.data.isInternal} />
      </Loader>
    );
  }
}

CompetitiveReport.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getCompetitiveReportStats: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.string,
    }),
  }).isRequired,
  userProfile: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportStat, userProfile }) => ({
  isLoading: competitiveReportStat.loading,
  loaded: competitiveReportStat.loaded,
  userProfile,
});

export default connect(
  mapStateToProps,
  { getCompetitiveReportStats },
)(withStyles(styles)(CompetitiveReport));
