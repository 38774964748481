import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Grid,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get, slice } from 'lodash';
import CreatorCard from './CreatorCard';


const MAX_CREATORS = 10;

const styles = () => ({
  root: {},
});

const Creators = ({
  topCreators,
  classes,
}) => (
  <Grid container spacing={3} className={classes.root}>
    {topCreators.map(data => (
      <Grid item md={12}>
        <CreatorCard data={data} />
      </Grid>
    ))}
  </Grid>
);

Creators.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  topCreators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ brand }) => ({
  topCreators: slice(get(brand, 'data.topCreators', []).sort((a, b) => a + b), 0, MAX_CREATORS),
});

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(Creators));
