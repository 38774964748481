export default theme => ({
  category__select: {
    width: '49%',
    marginTop: '1rem',
  },
  category__option: {
    padding: '5px 15px',
    color: theme.palette.text.bold,
    fontSize: '18px',
  },
});
