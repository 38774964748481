import React from 'react';
import PropTypes from 'prop-types';
import { GridList } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import NoResultCard from 'components/base/NoResultCard';
import Loader from 'components/base/Loader';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { openInNewTab } from 'utils/helpers';
import numeral from 'numeral';
import GridListTile from '../GridListTile';
import GridListTileBar from './Post/GridListTileBar';
import PostStat from './Post/PostStat';

const CELL_HEIGHT = 180;
const COLS = 4;
const CELL_SPACING = 20;


const generateTagList = tags => (
  !isEmpty(tags) ? tags.map(tag => `#${tag}`).join(', ') : null
);

const Posts = ({ posts, loading, classes }) => (
  <Loader loading={loading}>
    <React.Fragment>
      {isEmpty(posts) && <NoResultCard noResultMessage="No post data. Select a bubble above." />}
      <GridList cellHeight={CELL_HEIGHT} className={classes.root} spacing={CELL_SPACING} cols={COLS}>
        {posts.map(post => (
          <GridListTile
            key={post.id}
            onClick={() => openInNewTab(post.link)}
          >
            <img src={post.imageUrl} alt="" />
            <GridListTileBar
              title={generateTagList(post.tags)}
              subtitle={
                <div className={classes.post__subtitle}>
                  <PostStat Icon={ThumbUpIcon} statValue={numeral(post.likeCount).format('0,0')} />
                  <PostStat Icon={ChatBubbleIcon} statValue={numeral(post.commentCount).format('0,0')} />
                </div>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </React.Fragment>
  </Loader>
);

Posts.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    likeCount: PropTypes.number,
    commentCount: PropTypes.number,
    link: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool,
};

Posts.defaultProps = {
  loading: false,
};

export default withStyles(() => ({}))(Posts);
