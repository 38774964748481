import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SubmissionError } from 'redux-form';
import { Typography, Card } from '@material-ui/core';
import { forgotPassword } from 'redux/user/actions';
import LoggedOutLayout from 'components/base/LoggedOutLayout';
import Logo from 'components/base/Logo/LoginLogo';
import styles from './styles';
import ForgotPasswordForm from '../ForgotPassword/ForgotPasswordForm';

class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      forgotPasswordSuccess: false,
    };
  }

  async onSubmit(formData) {
    try {
      await this.props.forgotPassword(formData.email);
      this.handleSuccess();
    } catch (error) {
      if (error.errors) {
        throw new SubmissionError({ _error: 'Email is incorrect.' });
      }
    }
  }

  handleSuccess = () => {
    this.setState({
      forgotPasswordSuccess: true,
    });
  }

  render() {
    const { classes } = this.props;
    const { forgotPasswordSuccess } = this.state;

    return (
      <LoggedOutLayout>
        <div className={classes.card}>
          <Logo />
          <Card>
            { !forgotPasswordSuccess &&
            <ForgotPasswordForm onSubmit={data => this.onSubmit(data)} classes={classes} /> }
            { forgotPasswordSuccess &&
            <div className={classes.reset__password__message__wrapper}>
              <Typography>
                Success! Check your email for the reset link.
              </Typography>
            </div>
            }
          </Card>
        </div>
      </LoggedOutLayout>
    );
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    auth: state.auth,
  }),
  { forgotPassword },
)(withStyles(styles)(ForgotPassword));

