import React from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { updateList } from 'redux/lists/actions';
import ListFormModal from '../ListFormModal';
import styles from './styles';

class UpdateListModal extends React.Component {
  onSubmit = async (formData) => {
    const listId = this.props.list.uid;
    const listUpdate = this.getUpdate(formData);
    const other = this.selectedDefaultImage(formData.image) ? { image: formData.image.defaultImage } : {};

    try {
      await this.props.updateList(listId, listUpdate, other);
      toast.success('List Updated Successfully');
      this.props.closeModal();
    } catch (error) {
      if (error.errors) {
        if (error.errors.status === 409) {
          throw new SubmissionError({
            name: 'A list with this name already exists',
            _error: 'Duplicate list name!',
          });
        }
        throw new SubmissionError(error.errors);
      }
    }
  };

  getUpdate = (formData) => {
    const listUpdate = {};
    const { list } = this.props;
    if (list.name !== formData.name) {
      listUpdate.name = formData.name;
    }
    if (formData.image && formData.image && list.image !== formData.image.image) {
      listUpdate.image = formData.image.image || null;
    }
    if (list.category !== formData.category) {
      listUpdate.category = formData.category;
    }
    return listUpdate;
  };

  selectedDefaultImage = image => !image.image && image.defaultImage;

  render() {
    const {
      closeModal,
      list,
      open,
    } = this.props;
    return (
      <ListFormModal
        title="Update List"
        onSubmit={this.onSubmit}
        onClose={closeModal}
        open={open}
        list={list}
      />
    );
  }
}

UpdateListModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  list: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  updateList,
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(UpdateListModal));
