import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import withMultipleModals from 'components/base/withMultipleModals';
import ListFormModal from 'components/common/modals/ListFormModal';
import UpgradeModal from 'components/common/UpgradeModal';
import { fetchLists } from 'redux/lists/actions';
import { request } from 'lib/http';
import API from 'constants/api';
import styles from './styles';

const ModalName = {
  UpgradeModal: 'UpgradeModal',
};

class CreateListModal extends React.Component {
  onSubmit = ({ name, category = undefined, image = {} }) => {
    const { openModal } = this.props;
    return request('put', API.CREATE_LIST, {
      data: {
        name,
        category,
        image: image.image,
      },
    }).then(() => {
      toast.success('List Created Successfully');
      this.props.fetchLists();
      this.props.onClose();
    }).catch((response) => {
      if (response.response.status === 403) {
        openModal(ModalName.UpgradeModal);
      } else if (response.response.status === 409) {
        throw new SubmissionError({
          name: 'A list with this name already exists',
          _error: 'Duplicate list name!',
        });
      } else {
        throw new Error('Something went wrong');
      }
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      open,
      closeModal,
    } = this.props;
    return (
      <React.Fragment>
        <ListFormModal
          title="Create List"
          onSubmit={this.onSubmit}
          onClose={onClose}
          open={isOpen}
        />
        <UpgradeModal
          open={open[ModalName.UpgradeModal]}
          closeModal={() => closeModal(ModalName.UpgradeModal)}
        />
      </React.Fragment>
    );
  }
}

CreateListModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.shape({}).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchLists: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchLists,
};

export default withAll(
  withStyles(styles),
  withMultipleModals([ModalName.UpgradeModal]),
  connect(null, mapDispatchToProps),
)(CreateListModal);
