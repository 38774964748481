import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import { withStyles } from '@material-ui/styles';
import { CardContent, Grid, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { formatNumber, isPositiveNumber } from 'utils/helpers';

const NULL_VALUE_DISPLAY = 'N/A';

const styles = theme => ({
  cardContent: {
    padding: '10px',
    '&:last-child': {
      padding: '10px',
    },
  },
  statValue: {
    fontSize: '1rem',
    display: 'block',
    color: ({ value }) => (isPositiveNumber(value) ? theme.palette.green.main : theme.palette.red.main),
  },
  label: {
    textTransform: 'uppercase',
    display: 'block',
    fontWeight: 700,
    fontSize: '0.75rem',
    color: theme.palette.black.light,
  },
  iconStyle: {
    fontSize: '1rem',
    marginLeft: '0.3rem',
  },
});

const StatCard = ({ value, label, classes }) => (
  <Card>
    <CardContent className={classes.cardContent}>
      <Grid container alignItems="center">
        <Grid item xs="12">
          <Grid container alignItems="center">
            <Grid item xs="10">
              <Typography className={classes.statValue}>
                <span>{formatNumber(value, '0.00%', NULL_VALUE_DISPLAY)}</span>
                <span>
                  {isPositiveNumber(value) ?
                    <ArrowUpwardIcon className={classes.iconStyle} /> :
                    <ArrowDownwardIcon className={classes.iconStyle} />}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.label}>{label}</Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

StatCard.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(StatCard);
