import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity } from 'lodash';
import { withStyles } from '@material-ui/styles';
import Loader from 'components/base/Loader';
import PageLayout from 'components/base/Layout/PageLayout';
import withAll from 'components/base/withAll';
import InfluencerGrid from 'components/common/InfluencerGrid';
import InfluencerSelectionManager from 'components/common/InfluencerSelectionManager';
import withPermission from 'components/common/withPermission';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import SearchBar from './SearchBar';
import SearchHeader from './SearchHeader';
import SearchPagination from './SearchPagination';
import styles from './styles';

const DEFAULT_COLUMNS = 3;
const PANEL_COLLAPSED_COLUMNS = 4;

class Search extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.updateOnBoarding({ run: true });
    }, 300);
  }

  render() {
    const {
      classes,
      searchDisplay,
      theme,
      influencers,
      permitted,
    } = this.props;
    const searchColumns = searchDisplay.isVerticalPanelOpen ? DEFAULT_COLUMNS : PANEL_COLLAPSED_COLUMNS;
    const searchContentStyles = {
      marginLeft: searchDisplay.isVerticalPanelOpen ? theme.sizes.sideBar.desktop : 0,
    };

    return (
      <InfluencerSelectionManager>
        <div className={classes.root}>
          <SearchBar />
          <Loader loading={influencers.loading}>
            <PageLayout className={classes.searchContent} style={searchContentStyles}>
              <SearchHeader handleSearch={() => {}} />
              <InfluencerGrid
                columns={searchColumns}
                influencers={influencers.influencers}
                canViewBrandSafety={permitted}
              />
              <SearchPagination />
            </PageLayout>
          </Loader>
        </div>
      </InfluencerSelectionManager>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  searchDisplay: PropTypes.shape({
    isVerticalPanelOpen: PropTypes.bool.isRequired,
  }).isRequired,
  updateOnBoarding: PropTypes.func.isRequired,
  influencers: PropTypes.shape({
    total: PropTypes.number,
    influencers: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }).isRequired,
  permitted: PropTypes.bool,
};

Search.defaultProps = {
  permitted: false,
};

export default withAll(
  withPermission('create_brand_safety_reports', null, identity, true),
  withStyles(styles, { withTheme: true }),
  connect(
    state => ({
      searchDisplay: state.searchDisplay,
      influencers: state.influencers,
    }),
    { updateOnBoarding },
  ),
)(Search);
