import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import withAll from 'components/base/withAll';
import {
  competitiveReportCreateUrl,
} from 'constants/routing';
import withModal from 'components/base/withModal';
import { connect } from 'react-redux';
import BrandAutoComplete from './BrandAutoComplete';
import CreateReportsModal from '../../../common/modals/CreateReportModal';

const ModalName = {
  CreateModal: 'CreateModal',
};

const styles = theme => ({
  searchContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      width: '33rem',
    },
  },
  navButton: {
    margin: '0.4rem',
    padding: '4px 16px',
  },
});

const SearchContainer = ({
  classes,
  isAuthenticated,
  history,
  openModal,
  open,
  closeModal,
  brandSearch,
  allowedReports,
}) => {
  const onClose = (modalName) => {
    closeModal(modalName);
  };
  return (
    <div className={classes.searchContainer}>
      {isAuthenticated && allowedReports !== 0 && (
        <React.Fragment>
          <Button
            className={classes.navButton}
            color="primary"
            variant="contained"
            onClick={() => {
              openModal(ModalName.CreateModal);
            }}
            id="navBarcreateReportBtn"
          >
            Create Report
          </Button>
          <CreateReportsModal
            isOpen={open}
            onClose={() => onClose()}
          />
        </React.Fragment>
      )}
      {!isAuthenticated && allowedReports !== 0 && (
        <Button
          className={classes.navButton}
          color="primary"
          variant="contained"
          onClick={() => history.push(competitiveReportCreateUrl())}
          id="navBarcreateReportBtn"
        >
          Create Report
        </Button>
      )}
      {brandSearch && <BrandAutoComplete />}
    </div>
  );
};

SearchContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  brandSearch: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.arrayOf(PropTypes.bool).isRequired,
  allowedReports: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
};

SearchContainer.defaultProps = {
  brandSearch: false,
  allowedReports: 0,
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default withAll(
  connect(mapStateToProps, null),
  withModal,
  withStyles(styles),
  withRouter,
)(SearchContainer);
