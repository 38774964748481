import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AsyncMultiSelect from 'components/base/AsyncMultiSelect';
import { getCountriesAutoComplete } from 'redux/autocompletes/actions';

const MINIMUM_INPUT_CHARS = 3;

class AudienceTopCountriesAutoComplete extends Component {
  loadOptions = async (inputValue, callback) => {
    if (inputValue.length < MINIMUM_INPUT_CHARS) {
      callback([]);
      return;
    }
    await this.props.getCountriesAutoComplete(inputValue);
    callback(this.props.countriesAutocomplete.results);
  }

  render() {
    const { onChange, handleRemoveCountry, countries } = this.props;
    return (
      <div>
        <AsyncMultiSelect
          name="countries"
          placeholder="Find Countries"
          note="Enter countries that must show up in Top 5"
          value={countries}
          onChange={onChange}
          loadOptions={this.loadOptions}
          handleRemoveSelection={handleRemoveCountry}
          minChars={MINIMUM_INPUT_CHARS}
        />
      </div>
    );
  }
}

AudienceTopCountriesAutoComplete.propTypes = {
  getCountriesAutoComplete: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  })),
  countriesAutocomplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  handleRemoveCountry: PropTypes.func.isRequired,
};

AudienceTopCountriesAutoComplete.defaultProps = {
  onChange: null,
  countries: null,
};

export default connect(
  state => ({
    countriesAutocomplete: state.countriesAutocomplete,
  }),
  { getCountriesAutoComplete },
)(AudienceTopCountriesAutoComplete);
