export default theme => ({
  toggleButton: {
    position: 'fixed',
    left: '0',
    top: '8.2rem',
    borderTopRightRadius: '1rem',
    borderBottomRightRadius: '1rem',
    border: `1px solid ${theme.palette.border.main}`,
    boxShadow: theme.customShadows[1],
    borderLeft: 0,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: '40px',
    zIndex: 9,
    backgroundColor: theme.palette.white.main,
  },
  toggleIcon: {
    transition: 'all 0.2s ease-in',
  },
  searchBtnWrapper: {
    display: 'flex',
    flex: 1,
  },
});
