import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { PLATFORMS, PLAIN_PLATFORM_ICONS } from 'constants/options';
import ToggleButton from 'components/base/ToggleButton';
import ToggleButtonGroup from 'components/base/ToggleButtonGroup';
import styles from './styles';

class PlatformSelectField extends React.Component {
  getOptions = () => {
    const { exclude, include } = this.props;
    return (
      Object.entries(PLATFORMS)
        .filter(name => (include.length > 0 ? include.includes(name[1]) : true))
        .filter(name => !exclude.includes(name[1]))
        .map(name => (
          <ToggleButton value={name[1]} className="btn__platform">
            {PLAIN_PLATFORM_ICONS[name[1]]}
          </ToggleButton>
        )));
  };

  render() {
    const {
      classes, input, meta: { touched, error }, required, ...custom
    } = this.props;
    const { value, onChange } = input;
    const hasError = !!(touched && error);
    const errorElem = <span className="MuiFormHelperText-root Mui-error">{hasError ? error : ''}</span>;
    return (
      <FormControl className={classes.platform__select}>
        <ToggleButtonGroup
          value={value}
          onChange={(_, newValue) => {
            onChange(newValue);
          }}
          {...custom}
        >
          {this.getOptions()}
        </ToggleButtonGroup>
        {errorElem}
      </FormControl>
    );
  }
}

PlatformSelectField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  required: PropTypes.bool,
  include: PropTypes.arrayOf(PropTypes.string),
  exclude: PropTypes.arrayOf(PropTypes.string),
};

PlatformSelectField.defaultProps = {
  meta: null,
  required: false,
  include: [],
  exclude: [],
};

export default withStyles(styles)(PlatformSelectField);
