export default theme => ({
  textSection: {
    [theme.breakpoints.up('md')]: {
      marginTop: '13vh',
    },
  },
  title: {
    textAlign: 'left',
    display: 'block',
    fontSize: '2rem',
    margin: '0',
    '&:before': {
      width: '40px',
      marginBottom: '0.8rem',
      display: 'block',
      position: 'relative',
      content: '""',
      height: '2px',
      backgroundColor: theme.palette.pink.main,
    },
    [theme.breakpoints.up('md')]: {
      width: '25rem',
    },
  },
  subTitle: {
    marginBottom: '1.2rem',
    color: theme.palette.black.light,
    display: 'block',
  },
  topDataContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '250px',
      padding: '0 3rem 0 3rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem 0 1rem',
    },
  },
  verticalDataContainer: {
    '& > *': {
      display: 'block',
      marginTop: '0.3rem',
    },
    '& > :first-child': {
      marginTop: '0px',
    },
    marginTop: '2rem',
    spacing: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      scrollMarginTop: theme.sizes.layout.appHeader.height.desktop,
    },
  },
  verticalLink: {
    fontSize: '1rem',
    margin: '0 0 1rem 0',
    textDecoration: 'none',
    textTransform: 'none',
    color: theme.palette.blue.main,
  },
});
