import React from 'react';
import { request } from 'lib/http';
import Loader from '../base/Loader';


function withRequest(method, endpoint, mapPropsToData, mapResponseToProps) {
  return (WrappedComponent) => {
    class WithRequest extends React.Component {
      state = {
        loading: false,
        response: null,
      };

      componentDidMount() {
        this.requestAndSet();
      }

      requestAndSet = async () => {
        const data = mapPropsToData ? mapPropsToData(this.props) : null;
        this.setState({ loading: true });
        const response = await this.request(data);
        this.setState({ response, loading: false });
      };

      request = async data => request(method, endpoint, {
        data,
      });

      render() {
        const { response, loading } = this.state;
        const responseProps = mapResponseToProps ? mapResponseToProps(response) : {};
        return (
          <Loader loading={loading}>
            <WrappedComponent
              {...this.props}
              {...responseProps}
            />
          </Loader>
        );
      }
    }
    return WithRequest;
  };
}

export default withRequest;
