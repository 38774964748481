import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    transition: 'all 0.5s ease-out',
    padding: '1rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '48rem',
      marginLeft: theme.sizes.sideBar.desktop,
      padding: `${theme.sizes.layout.appHeader.height.desktop} 0 1rem 2rem`,
    },
  },
});

const TabContainer = ({ classes, children }) => (
  <div className={classes.root}>{children}</div>
);

TabContainer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default withStyles(styles)(TabContainer);
