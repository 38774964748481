import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import withAll from 'components/base/withAll';
import withSearchParam from '../../withSearchParam';
import styles from './styles';

const radii = [10, 25, 50, 100];

class LocationFilterRadius extends React.PureComponent {
  render() {
    const { classes, theme, input: { onChange, value } } = this.props;
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel
            disableAnimation
            htmlFor="locationRadius"
            shrink={false}
            style={{
              position: 'relative',
              transform: 'none',
              marginRight: '0.4rem',
              color: theme.palette.black.dark,
            }}
          >
            Within
          </InputLabel>
          <Select
            className={classes.select}
            name="locationRadius"
            value={value}
            inputProps={{
              id: 'locationRadius',
              name: 'locationRadius',
            }}
            onChange={e => onChange(e.target.value)}
          >
            {radii.map(r => (
              <option key={r} value={r} className={classes.selectOption}>{`${r} miles`}</option>
            ))}
          </Select>
          <span className={classes.label}> of</span>
        </FormControl>
      </div>
    );
  }
}

LocationFilterRadius.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default withAll(
  withStyles(styles, { withTheme: true }),
  withSearchParam('locationRadius'),
)(LocationFilterRadius);
