import React from 'react';
import PropTypes from 'prop-types';
import SearchSection from './SearchSection';
import LabelAutoComplete from './LabelAutoComplete';
import withSearchParam from '../withSearchParam';

class LabelFilter extends React.PureComponent {
  handleRemoveLabel = (label) => {
    const { input: { onChange, value } } = this.props;
    onChange(value.filter(v => v.value !== label));
  };

  render() {
    const { input: { onChange, value } } = this.props;
    return (
      <SearchSection
        id="label_search_filter"
        title="Label Search"
        className="label_search"
        tooltip="Find things that aren't mentioned via a hashtag or text by using our AI Powered image recognition to
         search images."
      >
        <LabelAutoComplete
          labels={value}
          onChange={onChange}
          handleRemoveLabel={this.handleRemoveLabel}
        />
      </SearchSection>
    );
  }
}

LabelFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])).isRequired,
  }).isRequired,
};

export default withSearchParam('labels')(LabelFilter);
