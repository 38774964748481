import { mpTrack } from 'utils/copenhagen';
import { request } from '../../../lib/http';
import API from '../../../constants/api';

export const FETCH_FEATURED_INFLUENCERS = 'FETCH_FEATURED_INFLUENCERS';

export const fetchFeaturedInfluencers = () => async (dispatch) => {
  mpTrack('load__featured_influencers', {});
  dispatch({
    type: FETCH_FEATURED_INFLUENCERS,
    payload: request('get', API.FETCH_FEATURED_INFLUENCERS, {}),
  });
};
