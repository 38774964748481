import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getKeyPhraseMonitorPosts, resetKeyPhraseMonitorPosts } from 'redux/monitors/actions';
import KeyPhraseMonitorCommon from 'components/common/KeyPhraseMonitor';
import { nonExisting } from 'constants/routing';

class KeyPhraseMonitor extends PureComponent {
  componentDidMount() {
    const { params, match: { params: { monitorId } } } = this.props;
    try {
      this.props.resetKeyPhraseMonitorPosts();
      this.props.getKeyPhraseMonitorPosts(monitorId, params);
    } catch (e) {
      window.location.replace(nonExisting());
    }
  }

  render() {
    const {
      match: { params: { monitorId } },
    } = this.props;
    return (
      <KeyPhraseMonitorCommon monitorId={monitorId} />
    );
  }
}

KeyPhraseMonitor.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getKeyPhraseMonitorPosts: PropTypes.func.isRequired,
  resetKeyPhraseMonitorPosts: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      monitorId: PropTypes.number,
    }),
  }).isRequired,
  params: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ keyPhraseMonitorPosts }) => ({
  params: keyPhraseMonitorPosts.params,
});

const mapDispatchToProps = { getKeyPhraseMonitorPosts, resetKeyPhraseMonitorPosts };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KeyPhraseMonitor);
