import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { withStyles } from '@material-ui/styles';
import ToggleButton from 'components/base/ToggleButton';
import ToggleButtonGroup from 'components/base/ToggleButtonGroup';

const styles = {
  root: {
    marginRight: '1rem',
  },
};

class PlatformToggleButton extends React.PureComponent {
  handlePlatformChange = (event, platforms) => {
    const { input: { onChange } } = this.props;
    onChange(platforms);
  };

  render() {
    const { input: { value }, classes } = this.props;
    return (
      <ToggleButtonGroup exclusive className={classes.root} value={value} onChange={this.handlePlatformChange}>
        <ToggleButton value="instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

PlatformToggleButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(PlatformToggleButton);
