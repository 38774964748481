import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    margin: '0 0.2rem',
    display: 'inline-block',
    '&:before': {
      margin: '0 0.2rem',
      display: 'inline-block',
      width: '0.6rem',
      height: '0.6rem',
      borderRadius: '50%',
      content: '""',
      overflow: 'hidden',
      backgroundColor: b => b.brand.legendColor,
    },
  },
});

const BrandItem = ({ brand, classes }) => (
  <Typography className={classes.root}>{brand.name}</Typography>
);

BrandItem.propTypes = {
  brand: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    legendColor: PropTypes.string,
  })).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(BrandItem);
