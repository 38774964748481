import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import LineBasic from 'components/base/charts/Line/LineBasic';
import { get, orderBy } from 'lodash';
import { findBrandObjFromName } from 'components/common/CompetitiveReport/utils';
import H6 from 'components/base/titles/CardHeading/H6';
import Legend from 'components/base/charts/Legend';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { formatNumber } from 'utils/helpers';
import { transformLegendLabels, transformPostsByMonth } from 'redux/reports/reducers';

const styles = () => ({
  root: {},
  titleText: {
    marginRight: '0.8rem',
    display: 'inline-block',
  },
});

const dataRateTooltipLabel = (tooltip, dataset) => {
  const { index: i, datasetIndex } = tooltip;
  const thisDataset = dataset.datasets[datasetIndex];
  if (!i) {
    return '';
  }
  return `${thisDataset.label}: ${formatNumber(tooltip.yLabel, '0a', '-')}`;
};

const getOptions = () => ({
  legend: {
    display: false,
  },
  tooltips: {
    displayColors: false,
    mode: 'index',
    enabled: true,
    intersect: false,
    callbacks: {
      label: (tooltip, dataset) => dataRateTooltipLabel(tooltip, dataset),
    },
  },
});

class PostByBrand extends React.PureComponent {
  transformedPostByBrand() {
    const { data, brands } = this.props;
    return {
      labels: data.labels,
      datasets: orderBy(data.dataSets.map((d) => {
        const brandColor = findBrandObjFromName(d.label, brands).legendColor;
        return {
          data: d.values,
          backgroundColor: 'transparent',
          borderWidth: 3,
          borderColor: brandColor,
          pointBackgroundColor: brandColor,
          pointBorderColor: 'transparent',
          label: d.label,
        };
      }), 'label'),
    };
  }

  render() {
    const { classes } = this.props;
    const transformedData = this.transformedPostByBrand();
    return (
      <div className={classes.root}>
        <H6>
          <LabelWithInfoIcon
            labelText="Mentions of Brand"
            tooltip="The frequency and timing of the mentions of a brand by creators."
          />
          <Legend
            items={transformedData.datasets.map(d => ({
              title: d.label,
              color: d.borderColor,
            }))}
          />
        </H6>
        <LineBasic
          height={100}
          options={getOptions()}
          data={transformedData}
        />
      </div>
    );
  }
}

PostByBrand.propTypes = {
  data: PropTypes.shape({}).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportPostActivity }) => ({
  data: transformPostsByMonth(get(competitiveReportPostActivity, 'data.postsByMonth', {})),
  brands: transformLegendLabels(get(competitiveReportPostActivity, 'data.postsByMonth.dataSets', [])),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(PostByBrand);
