export default theme => ({
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: '6rem',
    maxHeight: '8rem',
    border: `2px dotted ${theme.palette.border.dark}`,
    [theme.breakpoints.up('sm')]: {
      width: '49%',
    },
  },
  dropzone__content: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },
  dropzone__image: {
    color: theme.palette.text.meta,
    margin: 'auto',
  },
  dropzone__text: {
    margin: 'auto',
    fontSize: '1rem',
    color: theme.palette.text.meta,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  image__selection: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
  },
  image__container: {
    width: '100%',
    textAlign: 'center',
  },
  image: {
    maxWidth: '49%',
    maxHeight: '15rem',
  },
  no__image: {
    background: theme.palette.white.dark,
    width: '100%',
    height: '100%',
    position: 'relative',
    marginBottom: '1rem',
  },
  no__image__text: {
    width: '100%',
    textAlign: 'center',
  },
  image__buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.5rem',
    justifyContent: 'end',
  },
});
