import React from 'react';
import PropTypes from 'prop-types';
import { clone } from 'lodash';
import { MenuItem } from '@material-ui/core';
import withModal from 'components/base/withModal';
import * as moment from 'moment';
import { DATE_FILTER_OPTIONS, DATE_FILTER_MAX_DATE } from 'constants/options';
import ButtonMenu from 'components/base/ButtonMenu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomDateModal from 'components/common/modals/CustomDateModal';
import { strartOfDayISO } from 'utils/helpers';
import DateRangeIcon from '@material-ui/icons/DateRange';

class DateFilter extends React.Component {
  getCustomDateOption = () => {
    const { customDate, openModal } = this.props;
    if (customDate) {
      return ([
        <MenuItem
          key="customDate"
          onClick={openModal}
          closeOnClick
        >
        Custom date
        </MenuItem>]);
    }
    return [];
  };

  createFilterOptions = () => {
    const filterOptions = clone(DATE_FILTER_OPTIONS);
    return filterOptions;
  };

  renderFilterOptions = (filterOptions) => {
    const { handleSelect } = this.props;
    return filterOptions.map(filterType => (
      <MenuItem
        key={`filter-${filterType.value}`}
        onClick={() => handleSelect(
          strartOfDayISO(moment().subtract(filterType.value, 'month')),
          strartOfDayISO(DATE_FILTER_MAX_DATE),
        )}
        closeOnClick
      >
        {filterType.label}
      </MenuItem>
    )).concat(this.getCustomDateOption());
  };

  render() {
    const {
      open, closeModal, handleSelect, selectedFromDate, selectedToDate,
    } = this.props;

    const filterOptions = this.createFilterOptions();
    const findFunc = option => (
      strartOfDayISO(moment().subtract(option.value, 'month')) === selectedFromDate &&
      strartOfDayISO(DATE_FILTER_MAX_DATE) === selectedToDate
    );
    const selectedFilter = filterOptions.find(findFunc);

    return (
      <div>
        <ButtonMenu
          buttonProps={{
          variant: 'contained',
          color: 'secondary',
        }}
          buttonChildren={(
            <React.Fragment>
              <DateRangeIcon />
              {selectedFilter ? selectedFilter.label : 'Custom date'}
              <KeyboardArrowDownIcon />
            </React.Fragment>
        )}
          menuChildren={this.renderFilterOptions(filterOptions)}
        />
        <CustomDateModal
          open={open}
          closeModal={closeModal}
          handleSelect={(fromDate, toDate) => handleSelect(strartOfDayISO(fromDate), strartOfDayISO(toDate))}
          initialFromValue={moment(selectedFromDate)}
          initialToValue={moment(selectedToDate)}
        />
      </div>
    );
  }
}

DateFilter.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selectedFromDate: PropTypes.string.isRequired,
  selectedToDate: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  customDate: PropTypes.bool,
};

DateFilter.defaultProps = {
  customDate: false,
};

export default withModal(DateFilter);
