import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { SubmissionError } from 'redux-form';
import { Card } from '@material-ui/core';
import { resetPasswordWithToken } from 'redux/user/actions';
import withQueryParams from 'components/base/withQueryParams';
import LoggedOutLayout from 'components/base/LoggedOutLayout';
import Logo from 'components/base/Logo/LoginLogo';
import styles from './styles';
import ChangePasswordForm from './ChangePasswordForm';
import { login } from '../../../constants/routing';

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      changePasswordSuccess: false,
    };
  }

  async onSubmit(formData) {
    try {
      await this.props.resetPasswordWithToken(formData.password, formData.confirmPassword, this.props.resetCode);
      this.handleSuccess();
      this.props.history.push(login());
    } catch (error) {
      if (error.errors) {
        throw new SubmissionError(error.errors);
      }
    }
  }

  handleSuccess = () => {
    this.setState({
      changePasswordSuccess: true,
    });
  }

  render() {
    const { classes } = this.props;
    const { changePasswordSuccess } = this.state;

    return (
      <LoggedOutLayout>
        <div className={classes.card}>
          <Logo />
          <Card>
            { (!isEmpty(changePasswordSuccess) || !changePasswordSuccess) &&
            <ChangePasswordForm onSubmit={data => this.onSubmit(data)} classes={classes} /> }
          </Card>
        </div>
      </LoggedOutLayout>
    );
  }
}

ChangePassword.propTypes = {
  resetPasswordWithToken: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
  }).isRequired,
  resetCode: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ChangePassword.defaultProps = {
  resetCode: null,
};

const paramsToProps = ({ code }) => ({
  resetCode: code,
});

export default connect(
  state => ({
    auth: state.auth,
  }),
  { resetPasswordWithToken },
)(withQueryParams(paramsToProps)(withStyles(styles)(ChangePassword)));
