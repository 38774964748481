import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import NoResultCard from 'components/base/NoResultCard';
import SafetyLegend from 'components/app/BrandSafetyReports/SafetyLegend';
import ReportRow from './ReportRow';
import TableCellWithTooltip from './TableCellWithTooltip';

const styles = () => ({
  root: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  table: {
    '& th': {
      fontWeight: 'bold',
    },
  },
});

const ReportList = ({ classes, canViewUnusualPostIndex, reports }) => {
  if (isEmpty(reports)) {
    return <NoResultCard noResultMessage="No reports created yet" />;
  }
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Creator</TableCell>
              <TableCellWithTooltip align="left" tooltip="Social Media Platforms the creator is on that were analyzed.">
                Platform
              </TableCellWithTooltip>
              <TableCellWithTooltip
                align="center"
                tooltip="The total number of posts across all available platforms that were analyzed."
              >
                Posts Analyzed
              </TableCellWithTooltip>
              {/* <TableCell align="center">Variety Index</TableCell> TODO next step */}
              {/* <TableCell align="center">Sponsored Ratio</TableCell> TODO next step */}
              <TableCellWithTooltip
                align="center"
                tooltip="After reviewing the creators historical social media content we recommend how they line with
                 your brand."
              >
                Potentially Objectionable Content
              </TableCellWithTooltip>
              { canViewUnusualPostIndex &&
                <TableCellWithTooltip
                  align="center"
                  tooltip="After reviewing the creators content we search for any anomalies that may warrant a second
                   look."
                >
                  Unusual Post Engagement
                </TableCellWithTooltip>}
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Last Modified</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map(r => <ReportRow report={r} canViewUnusualPostIndex={canViewUnusualPostIndex} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <SafetyLegend />
    </React.Fragment>
  );
};

ReportList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  canViewUnusualPostIndex: PropTypes.bool.isRequired,
  reports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ brandSafetyReports }) => ({
  reports: get(brandSafetyReports, 'data.results', []),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(ReportList);
