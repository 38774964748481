export default theme => ({
  root: {
    height: '21px',
    cursor: 'pointer',
  },
  name: {
    color: theme.palette.blue.main,
    '&:hover': {
      color: theme.palette.blue.dark,
    },
  },
  link: {
    textDecoration: 'none',
  },
});
