import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'utils/helpers';
import { sum } from 'lodash';
import {
  Avatar,
  TableCell,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import BusinessIcon from '@material-ui/icons/Business';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import StatTicker from 'components/common/BrandDetail/RelatedBrands/StatTicker';
import { brandProfileUrl } from 'constants/routing';
import { withRouter } from 'react-router';

const styles = () => ({
  root: {},
  brandTitle: {
    marginLeft: '0.8rem',
    fontWeight: 600,
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});


const RelatedBrandTable = ({ brand, classes, history }) => (
  <TableRow key={`brand-${brand.id}`}>
    <TableCell component="th" scope="row">
      <Button variant="link" onClick={() => history.push(brandProfileUrl(brand.id))}>
        <div className={classes.brandContainer}>
          <Avatar src={brand.logo} imgProps={{ style: { objectFit: 'contain' } }} className={classes.avatar}>
            <BusinessIcon />
          </Avatar>
          <Typography className={classes.brandTitle}>{brand.name}</Typography>
        </div>
      </Button>
    </TableCell>
    <TableCell align="left">
      {brand.platforms.map(p => <PlatformItem platform={p} />)}
    </TableCell>
    <TableCell align="right">
      <Typography>{formatNumber(sum(brand.dailyPostCounts), '0a', '-')}</Typography>
    </TableCell>
    <TableCell align="right">
      <Typography>{formatNumber(brand.totalCreators, '0a', '-')}</Typography>
    </TableCell>
    <TableCell align="right">
      <StatTicker
        value={brand.trend6WRa}
      />
    </TableCell>
  </TableRow>
);

RelatedBrandTable.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brand: PropTypes.shape({}).isRequired,
  history: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(RelatedBrandTable));
