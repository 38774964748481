import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import { CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import H6 from 'components/base/titles/CardHeading/H6';
import cx from 'classnames';

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  statValue: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  statLabel: {
    color: theme.palette.black.light,
    fontWeight: 400,
  },
});

const StatCard = ({
  title,
  value,
  classes,
  className,
  cardId,
}) => (
  <Card id={cardId} className={cx(classes.root, className)}>
    <CardContent>
      <Typography id={cardId ? `${cardId}Value` : null} className={classes.statValue}>{value}</Typography>
      <H6 id={cardId ? `${cardId}Title` : null} className={classes.statLabel} align="center">{title}</H6>
    </CardContent>
  </Card>
);

StatCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  cardId: PropTypes.string,
};

StatCard.defaultProps = {
  className: null,
  cardId: null,
};

export default withStyles(styles)(StatCard);
