import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import MUIModal from '@material-ui/core/Modal/index';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

class BaseModal extends React.PureComponent {
  render() {
    const {
      children,
      classes,
      onClose,
      open,
      title,
      width,
      height,
    } = this.props;

    return (
      <MUIModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
        className={classes.root}
      >
        <div className={classes.container} style={{ width, height }}>
          <div className={classes.header}>
            <Typography className={classes.header__text}>
              {title}
            </Typography>
            <IconButton onClick={onClose} className={classes.close__button}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </MUIModal>
    );
  }
}

BaseModal.propTypes = {
  children: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

BaseModal.defaultProps = {
  width: null,
  height: '60%',
};

export default withStyles(styles)(BaseModal);
