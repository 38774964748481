export default theme => ({
  stat: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '14%',
    borderLeft: `2px solid ${theme.palette.white.dark}`,
  },
  title: {
    fontSize: 18,
    color: theme.palette.text.meta,
  },
  value: {
    marginTop: '60px',
    fontWeight: 'bold',
    fontSize: 24,
    color: theme.palette.text.bold,
  },
});
