export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '4rem',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  container: {
    background: 'white',
    boxShadow: theme.customShadows[1],
    outline: 'none',
    position: 'relative',
    width: '90%',
    backgroundColor: theme.palette.white.dark,
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  header: {
    flexDirection: 'row',
    padding: '1rem',
    backgroundColor: theme.palette.yellow.main,
    textAlign: 'center',
  },
  logo: {
    height: '50px',
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: theme.palette.text.bold,
    display: 'block',
  },
  content: {
    background: theme.palette.white.dark,
    width: '100%',
    padding: '1.2rem',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  closeButton: {
    marginLeft: 'auto',
    position: 'absolute',
    top: '0.4rem',
    right: '0.4rem',
  },
});
