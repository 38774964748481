import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, replace, sortBy, slice } from 'lodash';
import Dimensions from 'react-dimensions';
import { withStyles } from '@material-ui/styles';
import NoResultCard from 'components/base/NoResultCard';
import { connect } from 'react-redux';
import { getInfluencerTopPost } from 'redux/influencers/actions';
import { Typography } from '@material-ui/core';
import Force from 'components/base/charts/Force';
import FooterSpace from 'components/common/FooterSpace';
import Posts from '../Posts';
import styles from './styles';

const BUBBLE_MAX_LIMIT = 30;

class Mentions extends Component {
  handleClick = (tag) => {
    const { influencerProfile } = this.props;
    this.props.getInfluencerTopPost(influencerProfile.data.id, { tag: replace(tag, /#|@/g, '') });
  }

  render() {
    const {
      mentions,
      containerWidth,
      classes,
      theme,
      influencerTopPosts: { posts, loading },
    } = this.props;
    return (
      <div>
        {isEmpty(mentions) && <NoResultCard noResultMessage="No mention data." />}
        {!isEmpty(mentions) &&
          <React.Fragment>
            <div className={classes.bubble__container}>
              <Typography variant="h5">Mentions</Typography>
              <Force
                data={{
                  nodes: slice(
                    sortBy(mentions.map(d => ({ label: d.text, value: d.count })), v => -v.value),
                    0,
                    BUBBLE_MAX_LIMIT,
                  ),
                  links: [],
                }}
                width={containerWidth}
                height={300}
                onSelectNode={node => this.handleClick(node.label)}
                nodeColor={theme.palette.blue.main}
              />
            </div>
            <div>
              <Typography variant="h5">Posts</Typography>
              <Posts posts={posts} loading={loading} />
            </div>
            <FooterSpace />
          </React.Fragment>
        }
      </div>
    );
  }
}

Mentions.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  mentions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    count: PropTypes.number,
  })).isRequired,
  influencerProfile: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  influencerTopPosts: PropTypes.shape({}),
  containerWidth: PropTypes.number.isRequired,
  getInfluencerTopPost: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

Mentions.defaultProps = {
  influencerTopPosts: {},
};

export default connect(
  state => ({
    influencerProfile: state.influencerProfile,
    influencerTopPosts: state.influencerTopPosts,
  }),
  { getInfluencerTopPost },
)(Dimensions()(withStyles(styles, { withTheme: true })(Mentions)));
