export default theme => ({
  root: {
    marginBottom: '0.6rem',
    '& .input-range__label-container': {
      display: 'none',
    },
    '& .input-range__track': {
      backgroundColor: theme.palette.border.main,
      '&--active': {
        backgroundColor: theme.palette.blue.main,
      },
    },
    '& .input-range__slider': {
      backgroundColor: theme.palette.blue.main,
      border: `4px solid ${theme.palette.white.main}`,
      boxShadow: `0 0 4px 0 ${theme.palette.border.main}`,
      boxSizing: 'border-box',
      height: '1.2rem',
      width: '1.2rem',
      marginTop: '-0.72rem',
    },
  },
});
