import { transform } from 'lodash';

export const API_CONFIG = {
  API_BASE: `${process.env.API_BASE}api/`,
  TOKEN_NAME: 'csrftoken',
  API_VERSIONS: {
    V1: 'v1/',
  },
};

export const SHOW_STATES_FOR_COUNTRIES = ['US'];
export const VERTICAL_PATHS = {
  'apparel-accessories': { name: 'Apparel & Accessories' },
  athletics: { name: 'Athletics' },
  automotive: { name: 'Automotive' },
  'baby-children': { name: 'Baby/Children' },
  'beer-wine-spirits': { name: 'Beer, Wine & Spirits' },
  'beverage-non-alcoholic': { name: 'Beverage (Non-Alcoholic)' },
  'cigarettes-tobacco': { name: 'Cigarettes & Tobacco' },
  'conference-event': { name: 'Conference/Event' },
  'direct-response-companies': { name: 'Direct Response Companies' },
  'education-and-training': { name: 'Education and Training' },
  'financial-real-estate': { name: 'Financial & Real Estate' },
  food: { name: 'Food' },
  'general-conglomerate': { name: 'General Conglomerate' },
  hobbies: { name: 'Hobbies' },
  'home-furnishings': { name: 'Home Furnishings' },
  'media-entertainment': { name: 'Media & Entertainment' },
  'medical-pharmaceutical': { name: 'Medical/Pharmaceutical' },
  'medical-pharmaceutical-animals': { name: 'Medical/Pharmaceutical (Animals)' },
  'musical-instruments': { name: 'Musical Instruments' },
  pets: { name: 'Pets' },
  'professional-service': { name: 'Professional Service' },
  'public-service': { name: 'Public Service' },
  'restaurant-and-bar': { name: 'Restaurant and Bar' },
  'retailer-wholesale': { name: 'Retailer/Wholesale' },
  technology: { name: 'Technology' },
  'toiletries-cosmetics': { name: 'Toiletries & Cosmetics' },
  'travel-leisure': { name: 'Travel & Leisure' },
};

export const OPC_BRAND_VERTICALS = [
  'Apparel & Accessories',
  'Athletics',
  'Automotive',
  'Baby/Children',
  'Beer, Wine & Spirits',
  'Beverage (Non-Alcoholic)',
  'Cigarettes & Tobacco',
  'Education and Training',
  'Financial & Real Estate',
  'Food',
  'Home Furnishings',
  'Media & Entertainment',
  'Medical/Pharmaceutical',
  'Pets',
  'Professional Service',
  'Restaurant and Bar',
  'Retailer/Wholesale',
  'Technology',
  'Toiletries & Cosmetics',
  'Travel & Leisure',
];

export const VERTICALS_NAMES = transform(OPC_BRAND_VERTICALS, (result, v) => {
  // eslint-disable-next-line
  result[v.toLowerCase()] = v;
}, {});

export const VERTICAL_PATHS_NAME_MAP = transform(VERTICAL_PATHS, (result, v, k) => {
  // eslint-disable-next-line
  result[v.name] = k;
}, {});

export const forms = {
  BRAND_SAFETY_REPORT: 'brandSafetyReportsForm',
  REGISTER_FORM: 'registerForm',
  BETA_SIGNUP_FORM: 'betaSignupForm',
  QUICK_SIGNUP_FORM: 'quickSignupForm',
  CREATE_REPORT_FORM: 'createReportForm',
  NEW_LIST_FORM: 'newListForm',
  BRAND_REQUEST_FORM: 'brandRequestForm',
  KEY_PHRASE_MONITOR_FORM: 'keyPhraseMonitorForm',
  SHARE_LIST_FORM: 'shareListForm',
};

export const BETA_SIGNUP_TIMEOUT = 10 * 1000;

export const LOCAL_STORAGE_VALUE = {
  BETA_SIGN_UP_COMPLETE: 'BETA_SIGN_UP_COMPLETE',
  MIXPANEL: 'mixpanel',
};
