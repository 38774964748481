export default theme => ({
  root: {
    paddingTop: '7rem',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.customShadows[1],
    outline: 'none',
    display: 'flex',
    boxSizing: 'border-box',
  },
  btn__close: {
    position: 'absolute',
    top: '7.5rem',
    right: '1rem',
    zIndex: '1',
    backgroundColor: theme.palette.background.paper,
  },
});
