import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { pricing } from 'constants/routing';

const styles = theme => ({
  root: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '6rem',
    fontSize: '1rem',
    fontWeight: 800,
  },
  date: {
    fontWeight: 500,
    display: 'flex',
    margin: '0 0.2rem',
  },
  btnSubscribe: {
    padding: '0',
    margin: '0 0.3rem',
    display: 'flex',
    fontSize: '0.9rem',
    textDecoration: 'none',
    textTransform: 'none',
    alignItems: 'center',
    color: theme.palette.blue.main,
    '&:hover': {
      color: theme.palette.blue.dark,
      backgroundColor: 'transparent',
    },
  },
});

const DateGenerated = ({
  classes,
  history,
  isSubscriptionActive,
  isTrial,
  createdAt,
}) => (
  <div className={classes.root}>
    <Typography className={classes.title}>Generated:</Typography>
    <div className={classes.date}>{moment(createdAt).format('MM/DD/YY')}</div>
    {
      isSubscriptionActive && isTrial ? (
        <Button
          variant="link"
          className={classes.btnSubscribe}
          onClick={() => history.push(pricing())}
        >
          Want to refresh the data? Subscribe now
        </Button>
      ) : null
    }
  </div>
);

DateGenerated.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isSubscriptionActive: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
};

const mapStateToProps = ({ userProfile: { data: { isSubscriptionActive, isTrial } } }) => ({
  isSubscriptionActive,
  isTrial,
});

export default withAll(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps),
)(DateGenerated);
