import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import Tooltip from 'components/base/Tooltip';

const TableCellWithTooltip = ({ children, align, tooltip }) => (
  <Tooltip title={tooltip}>
    <TableCell align={align}> {children}</TableCell>
  </Tooltip>
);

TableCellWithTooltip.propTypes = {
  children: PropTypes.shape({}).isRequired,
  align: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default TableCellWithTooltip;
