export default theme => ({
  root: {
    position: 'relative',
  },
  chip: {
    margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  multiSelectResultContainer: {
    margin: '0.4rem -0.2rem 0 -0.2rem',
  },
  placeholder: {
    position: 'absolute',
  },
  indicatorSeparator: {
    display: 'none',
  },
  valueContainer: {
    order: 1,
    flex: 1,
  },
  indicatorsContainer: {
    flex: 0,
  },
});
