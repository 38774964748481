import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import PlatformFilter from 'components/common/PlatformFilter';
import UpgradeOverlay from 'components/common/UpgradeOverlay';
import { PLATFORMS } from 'constants/options';
import AgeChart from './AgeChart';
import EthnicChart from './EthnicChart';
import GenderChart from './GenderChart';
import TopCountriesChart from './TopCountriesChart';
import styles from './styles';

const Audience = ({
  classes,
  audienceTeaser,
  locationTeaser,
  audience,
}) => {
  const { isJuliusDemographics, platforms } = audience;
  const platformKeys = Object.keys(platforms);
  const defaultPlatform = platformKeys.length ? platformKeys[0] : PLATFORMS.INSTAGRAM;

  const [platform, setPlatform] = useState(defaultPlatform);

  const {
    ageStats,
    ethnicStats,
    genderStats,
    geoStats,
  } = platforms[platform];

  return (
    <div className={classes.root}>
      <UpgradeOverlay requireUpgrade={audienceTeaser}>
        <Grid container>
          <Grid item xs={12}>
            <PlatformFilter
              singleSelection
              selected={[platform]}
              platforms={platformKeys}
              onSelect={p => setPlatform(p)}
            />
          </Grid>

          <Grid item xs={4} className={classes.audience__col}>
            <Typography variant="h6" className={classes.profile__h5}>Gender</Typography>
            <GenderChart genderStats={genderStats} />
          </Grid>

          <Grid item xs={4} className={classes.audience__col}>
            <Typography variant="h6" className={classes.profile__h5}>Age Range</Typography>
            <AgeChart ageData={ageStats} isJuliusData={isJuliusDemographics} />
          </Grid>

          {
            !isEmpty(ethnicStats) ?
              <Grid item xs={4} className={classes.audience__col}>
                <Typography variant="h6" className={classes.profile__h5}>Ethnic Origin</Typography>
                <EthnicChart ethnicData={ethnicStats} />
              </Grid>
              : null
          }

          <Grid item xs={12} className={classes.audience__col}>
            <Typography variant="h6" className={classes.profile__h5}>Top Countries</Typography>
            <TopCountriesChart geoData={geoStats} locationTeaser={locationTeaser} />
          </Grid>
        </Grid>
      </UpgradeOverlay>
    </div>
  );
};

const AudienceShape = PropTypes.shape({
  ageStats: PropTypes.shape({}),
  ethnicStats: PropTypes.arrayOf(PropTypes.shape({
    ethnicType: PropTypes.string,
    value: PropTypes.number,
  })),
  genderStats: PropTypes.shape({
    male: PropTypes.number.isRequired,
    female: PropTypes.number.isRequired,
  }).isRequired,
  geoStats: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    topLocations: PropTypes.arrayOf(PropTypes.string),
  })),
});

Audience.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  audienceTeaser: PropTypes.bool.isRequired,
  locationTeaser: PropTypes.bool.isRequired,
  audience: PropTypes.shape({
    isJuliusDemographics: PropTypes.bool.isRequired,
    platforms: PropTypes.shape({
      instagram: AudienceShape,
      tiktok: AudienceShape,
      twitter: AudienceShape,
      youtube: AudienceShape,
    }).isRequired,
  }),
};

Audience.defaultProps = {
  audience: {
    platforms: {
      [PLATFORMS.INSTAGRAM]: {
        ageStats: {},
        ethnicStats: [],
        geoStats: [],
      },
    },
  },
};

export default withStyles(styles, { withTheme: true })(Audience);
