import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { competitiveReportCreateUrl, brandSafetyReportsUrl } from 'constants/routing';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import FormModal from 'components/base/modals/FormModal';
import { forms } from 'constants/config';
import { withRouter } from 'react-router';
import { change, getFormValues, reduxForm } from 'redux-form';
import UpgradeModal from 'components/common/UpgradeModal';
import AddReportsModal from 'components/app/BrandSafetyReports/AddReportsModal';
import withMultipleModals from 'components/base/withMultipleModals';
import { identity } from 'lodash';
import withPermission from 'components/common/withPermission';
import styles from './styles';

const ModalName = {
  CreateModal: 'CreateModal',
  UpdatePlanModal: 'UpgradeModal',
};

const ReportType = {
  COMPETITIVE_ANALYSIS_REPORT: 'Competitive Analysis Report',
  BRAND_SAFETY_REPORT: 'Brand Safety Report',
};

class CreateReportsModal extends React.Component {
  onModalClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  onSubmit = async () => {
    const { formValues: { reportType }, openModal, permitted } = this.props;

    switch (reportType) {
      case ReportType.COMPETITIVE_ANALYSIS_REPORT:
        this.props.history.push(competitiveReportCreateUrl());
        break;
      case ReportType.BRAND_SAFETY_REPORT:
        this.props.history.push(brandSafetyReportsUrl());
        if (permitted) {
          openModal(ModalName.CreateModal);
        } else {
          openModal(ModalName.UpdatePlanModal);
        }
        break;
      default:
        break;
    }

    this.onModalClose();
  };

  handleReportSelected = (e) => {
    const { formValues = {} } = this.props;
    formValues.reportType = e.target.value;
    this.props.change(forms.CREATE_REPORT_FORM, 'reportType', e.target.value);
  };

  render() {
    const {
      isOpen, classes, open, closeModal,
    } = this.props;

    const onClose = (modalName) => {
      closeModal(modalName);
    };

    return (
      <React.Fragment>
        <AddReportsModal
          isOpen={open[ModalName.CreateModal]}
          onClose={() => onClose(ModalName.CreateModal)}
        />
        <UpgradeModal
          open={open[ModalName.UpdatePlanModal]}
          closeModal={() => onClose(ModalName.UpdatePlanModal)}
          messageBody="You have reached the max limit of brand safety reports allowed for your plan type."
        />
        <FormModal
          open={isOpen}
          onClose={this.onModalClose}
          onSubmit={this.onSubmit}
          title="Create Report"
          submitText="Create Report"
          hasCloseButton
          form="createReportForm"
          width="38rem"
          height="auto"
          initial={{}}
        >
          <RadioGroup
            row
            name="create_report"
            onChange={this.handleReportSelected}
          >
            <FormControlLabel
              control={<Radio />}
              label="
                Competitive Analysis Report - Gain a competitive advantage
                and see what your competitors or name brands in your space
                are doing with creator marketing.
                "
              value={ReportType.COMPETITIVE_ANALYSIS_REPORT}
              className={classes.radio}
            />
            <FormControlLabel
              control={<Radio />}
              label="
                Brand Safety Report - Properly vet creators to be certain
                they align with your brand and campaign.
                "
              value={ReportType.BRAND_SAFETY_REPORT}
              className={classes.radio}
            />
          </RadioGroup>
        </FormModal>
      </React.Fragment>
    );
  }
}

CreateReportsModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  open: PropTypes.arrayOf(PropTypes.bool).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  permitted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(forms.CREATE_REPORT_FORM)(state),
});

const mapDispatchToProps = {
  change,
};

export default withAll(
  withPermission('create_brand_safety_reports', null, identity, true),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withMultipleModals([ModalName.CreateModal, ModalName.UpdatePlanModal]),
  withRouter,
  reduxForm({ form: forms.CREATE_REPORT_FORM }),
)(CreateReportsModal);
