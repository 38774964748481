export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  rankBtn: {
    borderRadius: 0,
    borderRight: `1px solid ${theme.palette.black.dark}`,
    color: theme.palette.black.dark,
    cursor: 'pointer',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  rankBtnActive: {
    fontWeight: 'bold',
    color: theme.palette.blue.main,
  },
});
