export default theme => ({
  header: {
    backgroundColor: theme.palette.white.main,
    flexGrow: 1,
    boxShadow: theme.customShadows[1],
    zIndex: 2,
    position: 'realative',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      height: theme.sizes.layout.appHeader.height.desktop,
    },
  },
  headerContainer: {
    backgroundColor: theme.palette.white.main,
    padding: '0 3rem',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logoContainer: {
    flexGrow: 1,
  },
  topNav: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
});
