import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import BaseLayout from './BaseLayout';

const styles = theme => ({
  bodyWrapper: {
    minHeight: '100%',
    marginBottom: `-${theme.sizes.layout.footer.height.desktop}`,
  },
  push: {
    height: theme.sizes.layout.footer.height.desktop,
    marginTop: '1rem',
  },
});

const LoggedInLayout = (props) => {
  const { classes, children } = props;
  return (
    <React.Fragment>
      <BaseLayout>
        {children}
        <div className={classes.push} />
      </BaseLayout>
    </React.Fragment>
  );
};

LoggedInLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
};

export default (withStyles(styles)(LoggedInLayout));
