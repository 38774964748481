export default theme => ({
  info: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    width: '150px',
    height: '150px',
    marginTop: '25px',
    marginLeft: '25px',
  },
  info__text: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    marginLeft: '25px',
    width: 'calc(100% - 200px)',
  },
  list__name: {
    color: theme.palette.text.bold,
    fontSize: '24px',
    fontWeight: 'bold',
    opacity: 0.8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 25px)',
  },
  list__meta: {
    color: theme.palette.text.meta,
  },
});
