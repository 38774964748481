import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import withAll from 'components/base/withAll';
import CheckboxCircle from 'components/common/CheckboxCircle';
import { setSearchParam } from 'redux/searchParams/actions';
import SearchSection from './SearchSection';
import UsernameAutoComplete from './UsernameAutoComplete';
import withSearchParam from '../withSearchParam';

const styles = () => ({
  checkbox: {
    width: '1.5rem',
    marginRight: '0.5rem',
    padding: 0,
  },
  checkboxRow: {
    display: 'block',
    margin: '0.5rem 0 0 0',
  },
});

class UsernameFilter extends React.PureComponent {
  onUsernameExactChange = (event, checked) => {
    this.props.setSearchParam('usernameExact', checked);
  };

  render() {
    const {
      classes,
      input: { onChange, value },
      searchParams: { usernameExact },
    } = this.props;
    return (
      <SearchSection
        title="Username Search"
        className="username_search"
        tooltip="Entering a creator's username will override any other search filters. Find a Creator by name, username,
         or social media handle. "
      >
        <UsernameAutoComplete
          username={value}
          onChange={onChange}
        />
        <FormControlLabel
          className={classes.checkboxRow}
          label="Exact match"
          control={
            <CheckboxCircle
              size="1.6rem"
              className={classes.checkbox}
              onChange={this.onUsernameExactChange}
              checked={usernameExact}
            />
          }
        />
      </SearchSection>
    );
  }
}

UsernameFilter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({}),
  }).isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  setSearchParam: PropTypes.func.isRequired,
};

export default withAll(
  withStyles(styles),
  connect(
    state => ({ searchParams: state.searchParams }),
    { setSearchParam },
  ),
  withSearchParam('username'),
)(UsernameFilter);
