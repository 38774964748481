import { newReducer } from '../utils';

const initialState = {
  brandSafetyReport: {
    loading: false,
    loaded: false,
    data: {},
  },
  brandSafetyReportPosts: {
    loading: false,
    loaded: false,
    data: {
      lists: [],
      posts: [],
    },
  },
  brandSafetyReports: {
    loading: false,
    loaded: false,
    data: {
      results: [],
    },
  },
  brandSafetyReportUnusualEngagementPosts: {
    loading: false,
    loaded: false,
    data: {
      posts: [],
    },
  },
  objectionableContentLists: {
    loading: false,
    loaded: false,
    data: {
      lists: [],
    },
  },
};

export const brandSafetyReportReducer = newReducer(initialState.brandSafetyReport);
export const brandSafetyReportPostsReducer = newReducer(initialState.brandSafetyReportPosts);
export const brandSafetyReportUnusualEngagementPostsReducer =
  newReducer(initialState.brandSafetyReportUnusualEngagementPosts);
export const brandSafetyReportsReducer = newReducer(initialState.brandSafetyReports);
export const objectionableContentListsReducer = newReducer(initialState.objectionableContentLists);

// Create brand safety report
brandSafetyReportReducer.method.CREATE_COMPETITIVE_REPORTS_PENDING = state => ({
  ...state,
  loading: true,
});

brandSafetyReportReducer.method.CREATE_COMPETITIVE_REPORTS_FULFILLED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

brandSafetyReportReducer.method.CREATE_COMPETITIVE_REPORTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

// Get individual brand safety report
brandSafetyReportReducer.method.DELETE_BRAND_SAFETY_REPORT_PENDING = state => ({
  ...state,
  loading: true,
});

brandSafetyReportReducer.method.DELETE_BRAND_SAFETY_REPORT_FULFILLED = state => ({
  ...state,
  loading: false,
  loaded: true,
});

brandSafetyReportReducer.method.DELETE_BRAND_SAFETY_REPORT_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

brandSafetyReportReducer.method.GET_BRAND_SAFETY_REPORT_PENDING = state => ({
  ...state,
  loading: true,
});

brandSafetyReportReducer.method.GET_BRAND_SAFETY_REPORT_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

brandSafetyReportReducer.method.GET_BRAND_SAFETY_REPORT_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

brandSafetyReportReducer.method.REFRESH_BRAND_SAFETY_REPORT_PENDING = state => ({
  ...state,
  loading: true,
});

brandSafetyReportReducer.method.REFRESH_BRAND_SAFETY_REPORT_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

brandSafetyReportReducer.method.REFRESH_BRAND_SAFETY_REPORT_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

// Get individual brand safety report posts
brandSafetyReportPostsReducer.method.GET_BRAND_SAFETY_REPORT_POSTS_PENDING = state => ({
  ...state,
  loading: true,
});

brandSafetyReportPostsReducer.method.GET_BRAND_SAFETY_REPORT_POSTS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

brandSafetyReportPostsReducer.method.GET_BRAND_SAFETY_REPORT_POSTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

// Get individual brand safety report unusual engagement posts
brandSafetyReportUnusualEngagementPostsReducer.method.GET_BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS_PENDING =
    state => ({
      ...state,
      loading: true,
    });

brandSafetyReportUnusualEngagementPostsReducer.method.GET_BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS_FULFILLED =
  (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
    loaded: true,
  });

brandSafetyReportUnusualEngagementPostsReducer.method.GGET_BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS_REJECTED =
    state => ({
      ...state,
      loading: false,
      loaded: false,
    });

// Get all user's brand safety reports
brandSafetyReportsReducer.method.GET_BRAND_SAFETY_REPORTS_PENDING = state => ({
  ...state,
  loading: true,
});

brandSafetyReportsReducer.method.GET_BRAND_SAFETY_REPORTS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

brandSafetyReportsReducer.method.GET_BRAND_SAFETY_REPORTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

// Get objectionable lists content
objectionableContentListsReducer.method.FETCH_OBJECTIONABLE_CONTENT_LISTS_PENDING = state => ({
  ...state,
  loading: true,
});

objectionableContentListsReducer.method.FETCH_OBJECTIONABLE_CONTENT_LISTS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

objectionableContentListsReducer.method.FETCH_OBJECTIONABLE_CONTENT_LISTS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});
