import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  isEmpty,
  get,
  slice,
  uniq,
} from 'lodash';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { CardContent, Typography } from '@material-ui/core';
import Card from 'components/base/Card';
import withAll from 'components/base/withAll';
import Button from 'components/base/Button';
import QuickSignupModal from 'components/common/modals/QuickSignupModal';
import { createCompetitiveReportAnonymous as createCompetitiveReportAnonymousAction } from 'redux/reports/actions';
import { forms } from 'constants/config';
import withQueryParams from 'components/base/withQueryParams';
import { getBrands as getBrandsAction } from 'redux/brand/actions';
import Loader from 'components/base/Loader';
import withModal from 'components/base/withModal';
import CompetitiveReportForm from './CompetitiveReportForm';

const styles = () => ({
  root: {
    overflow: 'visible',
  },
  card: {
    overflow: 'visible',
  },
});

const SuccessMessage = () => (
  <React.Fragment>
    <Typography variant="body">
      <b>Report and account created!</b><br /> Please, login below.
    </Typography>
  </React.Fragment>
);

class CreateReportPublic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFormData: {},
    };
  }
  componentDidMount() {
    const { initialFormParams } = this.props;
    if (!isEmpty(get(initialFormParams, 'brandIds'))) {
      this.props.getBrands({ idIn: uniq(slice(initialFormParams.brandIds.split(','), 0, 4)) });
    }
  }

  onSubmit = async (reportFormData) => {
    const {
      openModal,
    } = this.props;
    this.setState({ reportFormData }, openModal);
  };

  formSubmit = async (formData) => {
    const {
      resetForm,
      createCompetitiveReportAnonymous,
      closeModal,
    } = this.props;
    const { reportFormData } = this.state;
    await createCompetitiveReportAnonymous({
      ...formData,
      ...reportFormData,
      brandIds: reportFormData.brandIds.map(b => b.id),
    });
    closeModal();
    resetForm();
    toast.success(<SuccessMessage />);
  }

  render() {
    const {
      classes,
      loading,
      closeModal,
      open,
      brands,
      initialFormParams: { title },
    } = this.props;
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Loader loading={loading}>
              <CompetitiveReportForm
                onSubmit={data => this.onSubmit(data)}
                initial={{ brandIds: brands.map(b => ({ ...b, label: b.name })), active: true, title }}
                hideActive
                actions={(
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    CREATE REPORT
                  </Button>
                )}
              />
            </Loader>
          </CardContent>
        </Card>
        <QuickSignupModal open={open} closeModal={closeModal} onSignupComplete={this.formSubmit} />
      </div>
    );
  }
}

CreateReportPublic.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  resetForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  createCompetitiveReportAnonymous: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  initialFormParams: PropTypes.shape({}),
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CreateReportPublic.defaultProps = {
  initialFormParams: {},
};

const mapStateToProps = ({ competitiveReport, brands }) => ({
  loading: competitiveReport.loading || brands.loading,
  loaded: brands.loading,
  brands: brands.data,
});

const mapDispatchToProps = {
  createCompetitiveReportAnonymous: createCompetitiveReportAnonymousAction,
  getBrands: getBrandsAction,
  resetForm: () => dispatch => dispatch(reset(forms.CREATE_REPORT_FORM)),
};

const queryParamsToProps = ({ title, brandIds }) => ({
  initialFormParams: { title, brandIds },
});

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withModal,
  withQueryParams(queryParamsToProps),
)(CreateReportPublic);
