import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Card, Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CATEGORIES } from 'constants/options';
import styles from './styles';
import { searchUrl } from '../../../constants/routing';
import { setSearchParam } from '../../../redux/searchParams/actions';

class CategoryCard extends PureComponent {
  setCategory = () => {
    const { category: { category } } = this.props;
    this.props.setSearchParam('categories', [{
      value: category,
      label: CATEGORIES.find(element => element.value === category).label,
    }]);
    this.props.history.push(searchUrl());
  };

  render() {
    const { classes, category } = this.props;

    return (
      <Card className={classes.root}>
        <Link to={searchUrl()} onClick={this.setCategory}>
          <img src={category.image} alt="" className={classes.category_image} />
        </Link>
        <Typography className={classes.title}>
          {category.displayName}
        </Typography>
      </Card>
    );
  }
}

CategoryCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  category: PropTypes.shape({
    category_id: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired,
    is_featured: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setSearchParam: PropTypes.func.isRequired,
};

CategoryCard.defaultProps = {};

export default connect(null, { setSearchParam })(withRouter(withStyles(styles)(CategoryCard)));
