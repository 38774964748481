import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import ViewListIcon from '@material-ui/icons/ViewList';
import { Button, Checkbox, Typography } from '@material-ui/core';
import { identity, filter, lowerCase, map, size, sortBy, values } from 'lodash';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import ButtonMenu from 'components/base/ButtonMenu';
import Loader from 'components/base/Loader';
import withModal from 'components/base/withModal';
import withAll from 'components/base/withAll';
import withOnBoarding from 'components/common/OnBoarding/withOnBoarding';
import UpgradeModal from 'components/common/UpgradeModal';
import withPermission from 'components/common/withPermission';
import { addToLists, fetchLists } from 'redux/lists/actions';
import { deselectAllInfluencers } from 'redux/searchDisplay/actions';
import API from 'constants/api';
import { forms } from 'constants/config';
import { request } from 'lib/http';
import { maxInfluencerLimit, maxListLimit } from 'constants/messages';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import NewListForm from './NewListForm';
import styles from './styles';


class AddToList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLists: new Set([]),
      errorModalMessage: null,
    };
  }

  onSelect = (listId) => {
    const { selectedLists } = this.state;
    selectedLists.add(listId);
    this.setState({
      selectedLists,
    });
  };

  onDeselect = (listId) => {
    const { selectedLists } = this.state;
    selectedLists.delete(listId);
    this.setState({
      selectedLists,
    });
  };

  onChange = listId => ({ target: { checked } }) => {
    if (checked) {
      this.onSelect(listId);
    } else {
      this.onDeselect(listId);
    }
  };

  onClick = () => {
    const { isAuthenticated, openModal } = this.props;
    if (!isAuthenticated) {
      openModal();
      return;
    }
    this.handleAddToList();
  };

  getListOption = (list) => {
    const {
      classes,
    } = this.props;
    const listId = list.uid;
    const totalMembers = list.members;
    const selected = this.isSelected(listId);
    return (
      <div className={cx(classes.list__option, 'saved-list__option')} key={list.uid}>
        <Checkbox
          color="primary"
          onChange={this.onChange(listId)}
        />
        <Typography className={cx(classes.list__name, selected && classes.selected__list)}>
          {list.name} ({totalMembers})
        </Typography>
      </div>
    );
  };

  getListSelections = () => {
    const { lists } = this.props;
    const sortedLists = sortBy(lists, item => lowerCase(item.name));
    const mappedLists = sortedLists.map(list => this.getListOption(list));
    return filter(mappedLists, identity);
  };

  handleCreateNewList = ({ name }) => {
    const { openModal } = this.props;
    request('put', API.CREATE_LIST, {
      data: {
        name,
      },
    }).then(() => {
      toast.success('List Created Successfully');
      this.props.fetchLists();
      this.props.resetForm();
      this.props.handleCreateNewList();
    }).catch((response) => {
      if (response.response.status === 403) {
        this.setState({
          errorModalMessage: maxListLimit,
        }, openModal);
      } else if (response.response.status === 409) {
        throw new SubmissionError({
          name: 'A list with this name already exists',
          _error: 'Duplicate list name!',
        });
      } else {
        throw new Error('Something went wrong');
      }
    });
  }

  clearSelectedLists = () => {
    this.setState({ selectedLists: new Set([]) });
  };

  handleAddToList = async () => {
    const { selectedInfluencers, openModal } = this.props;
    const selectedInfluencerIds = map(selectedInfluencers, 'id');
    const { selectedLists } = this.state;

    await this.props.addToLists(Array.from(selectedLists), selectedInfluencerIds)
      .then(() => {
        setTimeout(() => {
          this.props.fetchLists();
        }, 1000);
      })
      .catch((response) => {
        if (response.response.status === 403) {
          this.setState({
            errorModalMessage: maxInfluencerLimit,
          }, openModal);
        }
      });
  };

  isSelected = listId => this.state.selectedLists.has(listId);

  renderMenuChildren = () => {
    const {
      classes,
      hideSelectedListPopoverTitle,
      listsLoading,
      permitted,
      selectedInfluencers,
    } = this.props;
    const { selectedLists } = this.state;

    const selectedInfluencersCount = size(selectedInfluencers) || 0;
    const selectedListsCount = size(selectedLists) || 0;

    return (
      <div className={classes.menu__content} key="addToLists" id="saved_list_menu" tabIndex="">
        {!hideSelectedListPopoverTitle &&
          <Typography className={classes.influencer__text}>
            {`Add selected ${selectedInfluencersCount} creators`}
          </Typography>
        }
        <div className={classes.list__options}>
          <Loader loading={listsLoading}>{this.getListSelections()}</Loader>
          {permitted &&
            <div className={classes.list__option} key="add-list">
              <NewListForm onSubmit={this.handleCreateNewList} />
            </div> }
        </div>
        <Button
          className={classes.add__to__list__button}
          variant="contained"
          color="primary"
          id="btn_add_influencers_to_list"
          disabled={selectedListsCount === 0}
          onClick={this.onClick}
        >
          Add to lists
        </Button>
      </div>
    );
  }

  render() {
    const {
      classes,
      selectedInfluencers,
      open,
      closeModal,
      isAuthenticated,
      openModal,
      fullWidth,
    } = this.props;
    const { errorModalMessage } = this.state;
    const selectedInfluencersCount = size(selectedInfluencers) || 0;
    return (
      <React.Fragment>
        <ButtonMenu
          openable={!open && isAuthenticated}
          onOpen={isAuthenticated ? this.props.fetchLists : openModal}
          onClose={isAuthenticated ? this.props.deselectAllInfluencers : closeModal}
          buttonProps={{
            variant: 'contained',
            color: 'primary',
            id: 'btn_add_to_list_menu',
            disabled: selectedInfluencersCount === 0,
            fullWidth,
          }}
          buttonChildren={(
            <React.Fragment>
              <ViewListIcon className={classes.saved__list__icon} /> Add to list
            </React.Fragment>
          )}
          menuProps={{
            className: classes.menu__container,
            disableAutoFocusItem: true,
            onExit: this.clearSelectedLists,
          }}
          menuChildren={isAuthenticated ? [
            this.renderMenuChildren(),
          ] : []}
        />
        {isAuthenticated && <UpgradeModal
          open={open}
          closeModal={closeModal}
          messageBody={errorModalMessage}
        />}
        {!isAuthenticated && <BetaSignupModal
          open={open}
          closeModal={closeModal}
          onSignupComplete={closeModal}
        />}
      </React.Fragment>
    );
  }
}

AddToList.propTypes = {
  resetForm: PropTypes.func.isRequired,
  addToLists: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  deselectAllInfluencers: PropTypes.func.isRequired,
  fetchLists: PropTypes.func.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideSelectedListPopoverTitle: PropTypes.bool,
  selectedInfluencers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  listsLoading: PropTypes.bool.isRequired,
  permitted: PropTypes.bool.isRequired,
  handleCreateNewList: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
};

AddToList.defaultProps = {
  hideSelectedListPopoverTitle: false,
  fullWidth: false,
};

const mapStateToProps = ({ lists, auth }) => ({
  lists: values(lists.data) || [],
  listsLoading: lists.loading,
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = {
  addToLists,
  deselectAllInfluencers,
  fetchLists,
  resetForm: () => dispatch => dispatch(reset(forms.NEW_LIST_FORM)),
};

export default withAll(
  withOnBoarding,
  withPermission('lists', null, identity, true),
  withStyles(styles),
  withModal,
  connect(mapStateToProps, mapDispatchToProps),
)(AddToList);
