import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import { withStyles } from '@material-ui/styles';
import {
  Tabs,
  CardContent,
} from '@material-ui/core';
import Tab from 'components/base/Tab';
import TabPanel from 'components/base/tabs/TabPanel';
import { a11yProps } from 'components/base/tabs/utils';
import Platforms from './Platforms';
import Posts from './Posts';
import Creators from './Creators';

const styles = () => ({
  root: {},
  tabContainer: {
    padding: '0 1rem',
  },
});

const TABS = {
  PLATFORMS: 0,
  POSTS: 1,
  CREATORS: 2,
};

class StatTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: TABS.PLATFORMS,
    };
  }
  getTabs() {
    const { classes } = this.props;
    const { tabValue } = this.state;
    return (
      <Tabs value={tabValue} onChange={this.handleChange} indicatorColor="primary">
        <Tab className={classes.tab} label="Platforms" {...a11yProps(TABS.PLATFORMS)} />
        <Tab className={classes.tab} label="Top Posts" {...a11yProps(TABS.POSTS)} />
        <Tab className={classes.tab} label="Top Creators" {...a11yProps(TABS.CREATORS)} />
      </Tabs>
    );
  }

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };
  render() {
    const { classes } = this.props;
    const { tabValue } = this.state;
    return (
      <Card className={classes.root}>
        <div className={classes.tabContainer}>
          {this.getTabs()}
        </div>
        <TabPanel value={tabValue} index={TABS.PLATFORMS}>
          <CardContent>
            <Platforms />
          </CardContent>
        </TabPanel>
        <TabPanel value={tabValue} index={TABS.POSTS}>
          <CardContent>
            <Posts />
          </CardContent>
        </TabPanel>
        <TabPanel value={tabValue} index={TABS.CREATORS}>
          <CardContent>
            <Creators />
          </CardContent>
        </TabPanel>
      </Card>
    );
  }
}

StatTable.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(StatTable);
