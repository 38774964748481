import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Doughnut from 'components/base/charts/Doughnut/Doughnut';
import H6 from 'components/base/titles/CardHeading/H6';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import { GENDER_LABEL_REVERSE_MAP } from 'constants/options';
import { formatNumber } from 'utils/helpers';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {},
  iconContainer: {
    fontSize: '3rem',
    display: 'inline-block',
  },
  male: {
    color: theme.palette.gender.m,
  },
  female: {
    color: theme.palette.gender.f,
  },
  gender__stat: {
    display: 'inline-block',
    padding: '0',
    fontSize: '1rem',
    color: theme.palette.black.light,
  },
  gender__stat__value: {
    color: theme.palette.black.main,
  },
  gender__icon_female: {
    color: theme.palette.blue.main,
    fontSize: '1.4rem',
    marginRight: '0.4rem',
  },
  gender__icon_male: {
    color: theme.palette.green.main,
    fontSize: '1.4rem',
    marginRight: '0.4rem',
  },
  legend: {
    marginTop: '0.4rem',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '0.4rem',
    flexWrap: 'wrap',
  },
});


class Gender extends React.PureComponent {
  getGenderIndices = (labels) => {
    const ret = {};
    for (let index = 0; index < labels.length; index += 1) {
      if (labels[index].toLowerCase() === 'male') {
        ret.male = index;
      } else {
        ret.female = index;
      }
    }
    return ret;
  }

  transformData = () => {
    const { data: { values, labels }, theme } = this.props;
    return {
      datasets: [{
        data: values,
        backgroundColor: labels.map(l => theme.palette.gender[GENDER_LABEL_REVERSE_MAP[l]]),
      }],
      labels,
    };
  }

  render() {
    const { classes } = this.props;
    const transformedData = this.transformData();
    const genderIndices = this.getGenderIndices(transformedData.labels);

    return (
      <div className={classes.root}>
        <H6 align="center">
          <LabelWithInfoIcon
            labelText="Gender"
            tooltip="The most probable gender of the audience."
          />
        </H6>
        <Doughnut
          data={transformedData}
          height={250}
          options={{
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: (tooltip, data) => (
                  ` ${data.labels[tooltip.index]}: ` +
                  `${formatNumber(data.datasets[tooltip.datasetIndex].data[tooltip.index] / 100, '0%', '-')}`
                ),
              },
            },
          }}
          backdropElement={(
            <div className={classes.iconContainer}>
              <FontAwesomeIcon icon={faFemale} className={classes.female} />
              <FontAwesomeIcon icon={faMale} className={classes.male} />
            </div>
          )}
        />
        <div className={classes.legend}>
          <Typography variant="body1" className={classes.gender__stat}>
            <b className={classes.gender__stat__value}>
              <FontAwesomeIcon className={classes.gender__icon_female} icon={faVenus} />
              {transformedData.datasets[0].data[genderIndices.female]}%
            </b> {transformedData.labels[genderIndices.female]}
          </Typography>
          <Typography variant="body1" className={classes.gender__stat}>
            <b className={classes.gender__stat__value}>
              <FontAwesomeIcon className={classes.gender__icon_male} icon={faMars} />
              {transformedData.datasets[0].data[genderIndices.male]}%
            </b> {transformedData.labels[genderIndices.male]}
          </Typography>
        </div>
      </div>
    );
  }
}

Gender.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(Gender);
