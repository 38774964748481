import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import cx from 'classnames';
import styles from './styles';

const Navigation = ({ classes, children, className }) => (
  <div className={cx(classes.navigation__container, className)}>
    {children}
  </div>
);

Navigation.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  // eslint-disable-next-line
  open: PropTypes.bool,
};

Navigation.defaultProps = {
  open: true,
  className: null,
};
export default withStyles(styles)(Navigation);
