export default theme => ({
  orTitle: {
    display: 'block',
    fontSize: '1rem',
    margin: '1.2rem',
  },
  noCreditCard: {
    display: 'block',
    fontStyle: 'italic',
    color: theme.palette.black.dark,
    fontSize: '0.8rem',
    fontWeight: 600,
  },
  brandLink: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
});
