export default theme => ({
  root: {
    padding: '0.4rem 0 1rem 0',
    position: 'relative',
  },
  container: {
    position: 'relative',
  },
  autoCompleteWrapper: {
    marginBottom: '3rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  input: {
    fontSize: '0.8rem',
    height: '2rem',
  },
  searchIcon: {
    color: theme.palette.black.light,
    paddingLeft: '0.4rem',
  },
  selectedResultContainer: {},
});
