import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import Post from 'components/common/CompetitiveReport/TopContent/Post';
import NoResultCard from 'components/base/NoResultCard';

const styles = () => ({
  root: {},
});

const TopPosts = ({ classes, topPosts }) => (
  <Grid container spacing={4} className={classes.root}>
    {isEmpty(topPosts) && (
      <Grid key="no-post-result" item xs={12}>
        <NoResultCard noResultMessage="No posts found" />
      </Grid>
    )}
    {topPosts.map(p => (
      <Grid item md={3}>
        <Post data={p} />
      </Grid>
    ))}
  </Grid>
);

TopPosts.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  topPosts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ competitiveReportVerticalBreakdown }) => ({
  topPosts: get(competitiveReportVerticalBreakdown, 'data.topPosts', []),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(TopPosts);
