import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { isEmpty, sum } from 'lodash';
import cx from 'classnames';
import Bar from '../Bar';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: ({ height }) => height,
  },
});

const tranformValues = ({
  values,
  labels,
  colors,
  barLabels,
}) => {
  const maxVal = sum(values);
  return values.map((v, i) => ({
    label: labels[i],
    fill: `${(v / maxVal) * 100}%`,
    value: v,
    barColor: colors[i],
    barLabel: !isEmpty(barLabels) ? barLabels[i] : null,
  }));
};

const VerticalBar = ({
  classes,
  data,
  className,
  displayTooltip,
}) => (
  <div className={cx(classes.root, className)}>
    {tranformValues(data).map(d => (
      <Bar data={d} rotation="vertical" displayTooltip={displayTooltip} />
    ))}
  </div>
);

VerticalBar.propTypes = {
  data: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    colors: PropTypes.arrayOf(PropTypes.string),
    barLabels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line
  height: PropTypes.string,
  displayTooltip: PropTypes.bool,
};

VerticalBar.defaultProps = {
  className: null,
  height: '200px',
  displayTooltip: false,
};

export default withStyles(styles)(VerticalBar);
