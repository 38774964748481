import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { STEPS_INDXES } from 'constants/onboarding';
import { getInfluencers } from 'redux/influencers/actions';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import Tooltip from './Tooltip';

class Tags2Tooltip extends React.Component {
  handleSearch = async () => {
    const { searchParams } = this.props;
    searchParams.page = 0;
    await this.props.getInfluencers(searchParams);
    setTimeout(() => {
      this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST });
    }, 1000);
  }

  render() {
    return <Tooltip submitText="Search" handleSubmit={this.handleSearch} {...this.props} />;
  }
}


Tags2Tooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  getInfluencers: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    searchParams: state.searchParams,
  }),
  { getInfluencers, updateOnBoarding },
)(Tags2Tooltip);
