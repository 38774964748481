import React from 'react';
import PropTypes from 'prop-types';
import { Scatter } from 'react-chartjs-2';


export const getOptions = options => ({
  responsive: true,
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  title: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: false,
      },
    }],
    xAxes: [{
      display: true,
      type: 'category',
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
    }],
  },
  ...options,
  legend: {
    display: false,
    labels: {
      usePointStyle: true,
    },
    ...options.legend ? options.legend : {},
  },
});

const ScatterBasic = ({
  options, data, rawData, customOptions, ...other
}) => (
  <Scatter
    data={data}
    options={getOptions(options, rawData, customOptions)}
    {...other}
  />
);

ScatterBasic.propTypes = {
  options: PropTypes.shape({}),
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      backgroundColor: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })),
    })),
  }).isRequired,
};

ScatterBasic.defaultProps = {
  options: {},
};

export default ScatterBasic;
