import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import HomeSignup from 'components/common/HomeSignup';
import { mpPageView } from 'utils/copenhagen';
import styles from './styles';
import IntroBanner from './IntroBanner';
import ContentCounterSection from './ContentCounterSection';
import InfluencerDiscovery from './InfluencerDiscovery';
import CompetitiveReports from './CompetitiveReports';
import Verticals from './Verticals';
import Brands from './Brands';

class Home extends React.Component {
  componentDidMount() {
    mpPageView({ name: 'home' });
  }
  render() {
    const { location } = this.props;
    return (
      <React.Fragment>
        <IntroBanner />
        <ContentCounterSection />
        <InfluencerDiscovery />
        <CompetitiveReports />
        <Verticals />
        <Brands />
        <HomeSignup location={location} />
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  location: PropTypes.shape({}).isRequired,
};


export default withStyles(styles)(Home);
