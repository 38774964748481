import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AsyncMultiSelect from 'components/base/AsyncMultiSelect';
import { getLocationAutoComplete } from 'redux/autocompletes/actions';

const MININUM_INPUT_CHARS = 3;

class LocationAutoComplete extends Component {
  // TODO: add debounce
  loadOptions = async (inputValue, callback) => {
    if (inputValue.length < MININUM_INPUT_CHARS) {
      callback([]);
      return;
    }
    await this.props.getLocationAutoComplete(inputValue);
    callback(this.props.locationAutoComplete.results);
  }

  render() {
    const { onChange, handleRemoveLocation, locations } = this.props;
    return (
      <div>
        <AsyncMultiSelect
          id="locations_autocomplete"
          name="locations"
          value={locations}
          placeholder="Find locations"
          onChange={onChange}
          loadOptions={this.loadOptions}
          handleRemoveSelection={handleRemoveLocation}
          creatable={false}
          isMulti
        />
      </div>
    );
  }
}

LocationAutoComplete.propTypes = {
  getLocationAutoComplete: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({}),
  })),
  locationAutoComplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({}),
    })).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  handleRemoveLocation: PropTypes.func.isRequired,
};

LocationAutoComplete.defaultProps = {
  onChange: null,
  locations: null,
};

export default connect(
  state => ({
    locationAutoComplete: state.locationAutoComplete,
  }),
  { getLocationAutoComplete },
)(LocationAutoComplete);
