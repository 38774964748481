export default theme => ({
  root: {
    display: 'flex',
    marginBottom: '1rem',
    marginTop: '1rem',
    '& button': {
      margin: '0.2rem',
      boxShadow: theme.customShadows[1],
    },
  },
  leftButtons: {
    flexGrow: 1,
  },
});
