import React from 'react';
import PropTypes from 'prop-types';
import SearchSection from './SearchSection';
import LocationAutoComplete from './LocationAutoComplete';
import LocationFilterRadius from './LocationFilterRadius';
import withSearchParam from '../withSearchParam';

class LocationFilter extends React.PureComponent {
  handleRemoveLocation = (location) => {
    const { input: { onChange, value } } = this.props;
    onChange(value.filter(v => v.value !== location));
  };

  render() {
    const { input: { onChange, value } } = this.props;
    return (
      <SearchSection
        id="location_search"
        title="Location Search"
        tooltip="The Country, State, or City where the Creator is mostly based."
      >
        <LocationFilterRadius />
        <LocationAutoComplete
          locations={value}
          onChange={onChange}
          handleRemoveLocation={this.handleRemoveLocation}
        />
      </SearchSection>
    );
  }
}

LocationFilter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default withSearchParam('locations')(LocationFilter);
