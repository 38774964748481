import { first, isEmpty, keys, size } from 'lodash';
import { format, numericality, validateForm } from 'redux-form-validators';

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const companyEmailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!icloud.com)(?!gmx.com)(?!protonmail.com)(?!zoho.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const requiredValidator = value => (value || typeof value === 'number' ? undefined : 'Required');

export const lengthValidator = (greaterThan = null, lessThan = null) => (value) => {
  const valueLength = size(value);
  if (greaterThan && valueLength <= greaterThan) {
    return `Must be greater than ${greaterThan} characters`;
  }
  if (lessThan && valueLength >= lessThan) {
    return `Must be less than ${lessThan} characters`;
  }
  return null;
};

export const itemsCountValidator = (greaterThan = null, lessThan = null) => (value) => {
  const values = value.split(',');
  const valueLength = size(values);
  if (greaterThan && valueLength <= greaterThan) {
    return `Must be greater than ${greaterThan} items`;
  }
  if (lessThan && valueLength >= lessThan) {
    return `Must be less than ${lessThan} items`;
  }
  return null;
};

export const emailValidator = value => (
  value && !emailRegex.test(value)
    ? 'Invalid email address'
    : undefined
);

export const emailsValidator = value =>
  value.split(',').find(emailValidator) || null;

export const companyEmailValidator = (value) => {
  if (!value) {
    return undefined;
  }
  // eslint-disable-next-line no-param-reassign
  value = value.toLowerCase();
  return (!emailRegex.test(value) || !companyEmailRegex.test(value))
    ? 'Invalid corporate email address'
    : undefined;
};

export const phoneValidator = value => (
  value && !phoneRegex.test(value)
    ? 'Invalid phone number'
    : undefined
);

export const numericallyValidator = msg => numericality({
  int: true,
  msg: msg || 'Must only contain numbers.',
});

export const passwordValidator = format({
  with: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#$^+=!*()@%&]).{8,}$/,
  msg: 'Passwords must be at least 8 characters, contain one capital letter, one number and one special character',
});

export const handleBlur = (event) => {
  // in order to fix https://github.com/erikras/redux-form/issues/860
  const { relatedTarget } = event;
  if (relatedTarget && (relatedTarget.getAttribute('type') === 'button')) {
    event.preventDefault();
  }
};

export const passwordsMatch = msg => (value, allValues) => {
  const allFields = allValues[0];
  return value !== allFields.password ? msg : undefined;
};

export const brandIds = msg => (value) => {
  if (!value) {
    return undefined;
  }
  const allFields = first(value);
  if (!allFields) {
    return undefined;
  }
  return isEmpty(allFields.brandIds) ? msg : undefined;
};

/**
 * ]Wraps validateForm and transforms errors to be placed in _error property so we can recieve field errors.
 * @param validationConfig config for https://github.com/gtournie/redux-form-validators#validateform
 * @returns {Function}
 */
export const validate = validationConfig => (values, props) => {
  const errors = validateForm(validationConfig)(values, props);
  const foundErrors = keys(errors).filter(k => !!errors[k]);
  if (isEmpty(props.registeredFields) || isEmpty(errors) || isEmpty(foundErrors)) {
    return errors;
  }
  return {
    ...errors,
    _error: foundErrors.map(k => errors[k]),
  };
};

export const validInstagram = username => (
  username.match(/^[a-z0-9._]{1,30}$/) ? undefined : 'Username is not valid.'
);
