import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import PropTypes from 'prop-types';
import 'chartjs-plugin-datalabels';
import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { config as i18nextConfig } from 'translations/index';
import { ToastContainer } from 'react-toastify';
import { withStyles } from '@material-ui/styles';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from 'components/base/ScrollToTop';
import Loader from 'components/base/Loader';
import OnBoarding from 'components/common/OnBoarding';
import Router from 'routes/Router';
import { getUser } from 'redux/authentication/actions';
import { fetchUserProfile } from 'redux/user/actions';
import { fetchConfig } from 'redux/config/actions';

i18next.init(i18nextConfig);

const styles = theme => ({
  toast: {
    fontFamily: theme.typography.fontFamily,
    paddingLeft: 30,
    '& div[class*="toast--success"]': {
      background: theme.palette.blue.main,
    },
    '& div[class*="toast--error"]': {
      background: theme.palette.red.main,
    },
  },
  toast__body: {
    marginLeft: 10,
  },
});

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  async componentDidMount() {
    await this.props.getUser();
    this.loadUserProfileData();
    this.loadConfigData();
  }

  loadConfigData = () => {
    this.props.fetchConfig();
  };

  loadUserProfileData = () => {
    this.props.fetchUserProfile();
  };

  render() {
    const {
      classes,
      configs,
      userProfile,
      auth: { loading, isAuthenticated, loaded },
    } = this.props;
    const isLoading = loading || configs.loading || userProfile.loading;
    const isLoaded = loaded && configs.loaded && userProfile.loaded;
    const childProps = {
      loading: isLoading,
      isAuthenticated,
      configs,
    };
    // eslint-disable-next-line no-undef
    pendo.initialize({});

    if (userProfile.data.email) {
      // eslint-disable-next-line no-undef
      pendo.identify({
        visitor: {
          id: userProfile.data.email,
          name: `${userProfile.data.firstName} ${userProfile.data.lastName}`,
          email: userProfile.data.email,
          creationDate: userProfile.data.dateJoined,
        },
        account: {
          id: `account-${userProfile.data.id}`,
        },
      });
    }

    // show loading if user auth is loading or if user is authenticated are loading.
    return (
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <ScrollToTop>
            <Loader loading={isLoading} loaded={isLoaded}>
              <Router childProps={childProps} />
              <ToastContainer
                position="top-center"
                autoClose={4000}
                draggable={false}
                className={classes.toast}
                bodyClassName={classes.toast__body}
              />
              <OnBoarding />
            </Loader>
          </ScrollToTop>
        </I18nextProvider>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getUser: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
  }),
  userProfile: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  fetchConfig: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  configs: PropTypes.shape({
    featuredCategoriesEnabled: PropTypes.bool,
    featuredInfluencersEnabled: PropTypes.bool,
    loaded: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

App.defaultProps = {
  auth: {
    loading: true,
    loaded: false,
    isAuthenticated: false,
  },
};

export default hot(connect(
  ({ auth, configs, userProfile }) => ({
    auth,
    configs,
    userProfile,
  }),
  {
    getUser,
    fetchConfig,
    fetchUserProfile,
  },
)(withStyles(styles)(App)));
