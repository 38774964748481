import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Chip from 'components/base/Chip';
import { CATEGORY_DICT } from 'constants/options';
import Dimensions from 'react-dimensions';
import {
  isEmpty,
  pick,
  keys,
  slice,
  round,
} from 'lodash';
import styles from './styles';

const CHAR_LIMIT_PER_PIXEL = 0.068;

class Categories extends Component {
  createCategoryList = (categories) => {
    const { containerWidth } = this.props;
    if (isEmpty(categories)) {
      return [];
    }
    const truncateCharLimit = round(containerWidth * CHAR_LIMIT_PER_PIXEL);

    // Incrementer for categories that can fit
    let maxCategoriesToDisplay = 0;

    // Only display primary categories, do not show secondary categories
    const mainCategories = keys(pick(categories, keys(CATEGORY_DICT)));

    // decide where to truncate, get total amount of characters that can fit in card
    let currentCharCount = 0;
    mainCategories.forEach((category) => {
      currentCharCount += CATEGORY_DICT[category].length;
      if (currentCharCount > truncateCharLimit) {
        return false;
      }
      maxCategoriesToDisplay += 1;
      return true;
    });

    // Count of remaining categories
    const remainingCategories = mainCategories.length - maxCategoriesToDisplay;

    // Truncate list to max categories that can be displayed
    const categoriesToDisplay = slice(mainCategories, 0, maxCategoriesToDisplay);

    // Get labels to go with property values
    const transformedCateogires = categoriesToDisplay.map(category => ({
      value: category,
      label: CATEGORY_DICT[category],
    }));

    // Add item for truncated omission
    if (remainingCategories) {
      transformedCateogires.push({
        value: 'cat-more',
        label: `${remainingCategories}+`,
      });
    }
    return transformedCateogires;
  }

  renderCategories = (categories) => {
    const transformedCategories = this.createCategoryList(categories);
    return transformedCategories.map(category => (
      <Chip key={category.value} label={category.label} />
    ));
  }

  render() {
    const { classes, categories } = this.props;
    return (
      <div className={classes.categories}>
        {this.renderCategories(categories)}
      </div>
    );
  }
}

Categories.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  categories: PropTypes.shape({}),
  containerWidth: PropTypes.number.isRequired,
};

Categories.defaultProps = {
  categories: {},
};

export default Dimensions()(withStyles(styles)(Categories));
