export default theme => ({
  root: {
    marginBottom: '2rem',
  },
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '0.3rem',
    '& > *': {
      marginLeft: 'auto',
    },
    '& > :last-child': {
      marginLeft: 'initial',
    },
    '& > :first-child': {
      marginLeft: 'auto',
    },
  },
  filterItem: {
    marginRight: '0.15rem',
  },
  description: {
    padding: '1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  descriptionTitle: {
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    paddingRight: '0.5rem',
  },
});
