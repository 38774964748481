import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from 'components/base/Card';
import { Grid } from '@material-ui/core';
import styles from './styles';

const TabHeader = ({
  classes, children, description, title,
}) => {
  const arrayChildren = Children.toArray(children);
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={arrayChildren.length > 0 ? 8 : 12}>
          <Card className={classes.description}>
            <div className={classes.descriptionTitle}>{title}:</div>
            {description}
          </Card>
        </Grid>
        {arrayChildren.length > 0 &&
        <Grid item xs={12} md={4}>
          <div className={classes.filterContainer}>
            {arrayChildren.map(c => (
              <div className={classes.filterItem}>
                {c}
              </div>
            ))}
          </div>
        </Grid>}
      </Grid>
    </div>
  );
};

TabHeader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

TabHeader.defaultProps = {
  children: null,
};

export default withStyles(styles)(TabHeader);
