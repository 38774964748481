export default () => ({
  list__form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  image__section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '80%',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  list__name: {
    width: '49%',
    marginTop: '1rem',
  },
});
