import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { mpTrack } from 'utils/copenhagen';
import styles from './styles';

const NavigationItem = ({
  classes,
  label,
  to,
  id,
  trackEvent,
}) => {
  let navProps = {
    id,
    to,
    className: classes.navigationItem,
  };
  if (trackEvent) {
    navProps = {
      ...navProps,
      onClick: () => mpTrack(trackEvent.name, trackEvent.eventProps),
    };
  }
  return (
    <NavLink {...navProps}>
      {label}
    </NavLink>
  );
};

NavigationItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  id: PropTypes.string,
  trackEvent: PropTypes.shape({
    name: PropTypes.string,
    eventProps: PropTypes.shape({}),
  }),
};

NavigationItem.defaultProps = {
  id: null,
  trackEvent: null,
};

export default withStyles(styles)(NavigationItem);
