import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  Avatar,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core';
import Card from 'components/base/Card';
import { PLAIN_PLATFORM_ICONS, PLATFORM_COLOR } from 'constants/options';
import { openInNewTab, formatNumber } from 'utils/helpers';
import withAll from 'components/base/withAll';
import ImageBoxWithDefault from 'components/common/ImageBoxWithDefault';
import Stat from './Stat';
import CardHeader from './CardHeader';

const NULL_VALUE_DISPLAY = '-';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.customShadows[2],
    },
  },
  media: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  caption: {
    flex: 1,
  },
  content: {
    paddingTop: '1rem',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    top: 1,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    color: ({ data: { image } }) => (isEmpty(image) ? theme.palette.black.dark : theme.palette.white.main),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      opacity: ({ data: { image } }) => (isEmpty(image) ? 1 : 0),
      transition: 'opacity 0.2s ease-in',
      backgroundColor: 'transparent',
      '&:hover': {
        opacity: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
  },
  contentContainer: {
    position: 'relative',
    height: '20rem',
  },
  contentCopy: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    color: ({ data: { image } }) => (isEmpty(image) ? theme.palette.black.dark : theme.palette.white.main),
  },
  icon: {
    color: theme.palette.black.light,
    padding: '0 0.4rem',
  },
  statContainer: {
    paddingBottom: '1rem',
  },
  brandUsername: {
    maxWidth: '9.2rem',
  },
  stat: {
    color: ({ data: { image } }) => (isEmpty(image) ? theme.palette.black.dark : theme.palette.white.main),
  },
  imgNotAvailTextClass: {
    top: '1rem',
  },
});

const Post = ({
  classes,
  data,
  brands,
  brandName,
}) => {
  const postBrands = data.brandMentions ? data.brandMentions
    .filter(b => brands.includes(b.name))
    .map(b => b.name) : [];
  return (
    // eslint-disable-next-line
    <div className={classes.root} onClick={() => openInNewTab(data.postLink)} sel="brand_post" brand_name={brandName}>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" style={{ backgroundColor: PLATFORM_COLOR[data.platform] }}>
              {PLAIN_PLATFORM_ICONS[data.platform]}
            </Avatar>
          }
          title={postBrands.join(', ')}
          subheader={`@${data.username}`}
        />
        <div className={classes.contentContainer}>
          <ImageBoxWithDefault
            src={data.image}
            imgNotAvailTextClass={classes.imgNotAvailTextClass}
          />
          <CardContent className={classes.content}>
            <Grid container className={classes.statContainer}>
              <Grid item xs={4}>
                <Stat
                  className={classes.stat}
                  value={formatNumber(data.followerCount, '0a', NULL_VALUE_DISPLAY)}
                  label="Followers"
                />
              </Grid>
              <Grid item xs={4}>
                <Stat
                  className={classes.stat}
                  value={formatNumber(data.likes, '0a', NULL_VALUE_DISPLAY)}
                  label="Likes"
                />
              </Grid>
              <Grid item xs={4}>
                <Stat
                  className={classes.stat}
                  value={formatNumber(data.comments, '0a', NULL_VALUE_DISPLAY)}
                  label="Comments"
                />
              </Grid>
              <Grid item xs={4}>
                <Stat
                  className={classes.stat}
                  value={formatNumber(data.views, '0a', NULL_VALUE_DISPLAY)}
                  label="Views"
                />
              </Grid>
              <Grid item xs={4}>
                <Stat
                  className={classes.stat}
                  value={formatNumber(data.engagement / 100, '0.00%', NULL_VALUE_DISPLAY)}
                  label="Engagements"
                />
              </Grid>
            </Grid>
            <Typography className={classes.contentCopy} variant="body2" color="textSecondary" component="p">
              {data.caption}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

Post.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    username: PropTypes.string.isRequired,
    postDate: PropTypes.string.isRequired,
    caption: PropTypes.string,
    postLink: PropTypes.string,
    likes: PropTypes.number,
    comments: PropTypes.number,
    followerCount: PropTypes.number,
  }).isRequired,
  brandName: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ competitiveReportStat }) => ({
  brands: get(competitiveReportStat, 'data.brands', []).map(b => b.name),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles, { withTheme: true }),
)(Post);

