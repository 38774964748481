import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import { withStyles } from '@material-ui/styles';
import {
  Tabs,
  CardContent,
} from '@material-ui/core';
import Tab from 'components/base/Tab';
import LineBasic from 'components/base/charts/Line/LineBasic';
import { PLATFORM_LABELS, PLATFORM_COLOR } from 'constants/options';
import { slice, keys } from 'lodash';
import * as moment from 'moment';
import { ma } from 'moving-averages';
import { connect } from 'react-redux';
import TabPanel from 'components/base/tabs/TabPanel';
import { a11yProps } from 'components/base/tabs/utils';
import AvgStatCtrl from 'components/common/AvgStatCtrl';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import { formatNumber } from 'utils/helpers';

const styles = () => ({
  root: {
    position: 'relative',
  },
  tabContainer: {
    padding: '0 1rem',
    position: 'relative',
    zIndex: 1,
  },
  legendContainer: {
    textAlign: 'center',
    marginTop: '0.8rem',
  },
});

const TABS = {
  POST_ACTIVATIONS: 0,
  POST_ENGAGEMENT: 1,
};

const MA_DAYS = 7;

const getEngagementOptions = () => ({
  tooltips: {
    enabled: true,
    intersect: false,
    displayColors: true,
    callbacks: {
      label: (tooltip, dataset) => {
        const thisDataset = dataset.datasets[tooltip.datasetIndex];
        return [`${thisDataset.label}: ${formatNumber(thisDataset.data[tooltip.index], '0a', '-')}`];
      },
      labelColor: (tooltipItem, chart) => ({
        borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
        backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
      }),
    },
  },
  legend: {
    display: false,
    position: 'bottom',
  },
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: true,
      },
    }],
    xAxes: [{
      display: true,
      ticks: {
        autoSkipPadding: 7,
      },
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
    }],
  },
});

const getPostOptions = () => ({
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: false,
      },
    }],
    xAxes: [{
      display: true,
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
      ticks: {
        autoSkipPadding: 7,
      },
    }],
  },
});

class PostGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: TABS.POST_ACTIVATIONS,
    };
  }
  getTabs() {
    const { classes } = this.props;
    const { tabValue } = this.state;
    return (
      <Tabs value={tabValue} onChange={this.handleChange} indicatorColor="primary">
        <Tab className={classes.tab} label="Post Activations" {...a11yProps(TABS.POST_ACTIVATIONS)} />
        <Tab className={classes.tab} label="Post Engagements" {...a11yProps(TABS.POST_ENGAGEMENT)} />
      </Tabs>
    );
  }
  transformPostActivationData = (data) => {
    const { theme } = this.props;
    return {
      labels: slice(data.dates.map(value => moment(value).format('MMM DD')), MA_DAYS),
      datasets: [{
        data: slice(ma(data.dailyPostCounts, MA_DAYS), MA_DAYS),
        backgroundColor: 'transparent',
        borderWidth: 3,
        borderColor: theme.palette.green.main,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      }],
    };
  };

  transformPostEngagementData = data => ({
    labels: slice(data.dates.map(value => moment(value).format('MMM DD')), MA_DAYS),
    datasets: keys(data.platforms).map(p => ({
      label: PLATFORM_LABELS[p],
      data: slice(ma(data.platforms[p].dailyEngagements, MA_DAYS), MA_DAYS).map(val => Math.round(val * 100) / 100),
      fill: false,
      backgroundColor: 'transparent',
      borderWidth: 3,
      borderColor: PLATFORM_COLOR[p],
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
    })),
  })

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };
  render() {
    const { classes, data } = this.props;
    const { tabValue } = this.state;
    return (
      <Card className={classes.root}>
        <div className={classes.tabContainer}>
          {this.getTabs()}
        </div>
        <TabPanel value={tabValue} index={TABS.POST_ACTIVATIONS}>
          <CardContent>
            <AvgStatCtrl statList={data.dailyPostCounts} isWideScreen="true" />
            <LineBasic
              data={this.transformPostActivationData(data)}
              options={getPostOptions()}
              rawData={data.dailyPostCounts}
              height="100px"
            />
          </CardContent>
        </TabPanel>
        <TabPanel value={tabValue} index={TABS.POST_ENGAGEMENT}>
          <CardContent>
            <LineBasic
              data={this.transformPostEngagementData(data)}
              options={getEngagementOptions()}
              height="100px"
            />
            <div className={classes.legendContainer}>
              {keys(data.platforms).map(p => (
                <PlatformItem platform={p} />
              ))}
            </div>
          </CardContent>
        </TabPanel>
      </Card>
    );
  }
}

PostGraph.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ vertical }) => ({
  data: vertical.data,
});

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(PostGraph));
