import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';
import { connect } from 'react-redux';
import { Tooltip, withStyles } from '@material-ui/core';
import Button from 'components/base/Button';
import withModal from 'components/base/withModal';
import withAll from 'components/base/withAll';
import UpgradeModal from 'components/common/UpgradeModal';
import withPermission from 'components/common/withPermission';
import { PLATFORMS } from 'constants/options';
import { isSearchParamsEqual } from 'utils/helpers';
import { defaultInitialState } from 'redux/searchParams/reducers';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import { maxSearchLimit } from 'constants/messages';
import styles from './styles';

const requiredPlatforms = [
  PLATFORMS.INSTAGRAM,
  PLATFORMS.YOUTUBE,
  PLATFORMS.TIKTOK,
  PLATFORMS.PINTEREST,
];

const SearchButton = ({
  classes,
  handleSearch,
  remaining,
  permitted,
  canPlanBeUpgraded,
  searchParams,
  closeModal,
  openModal,
  open,
  isAuthenticated,
}) => {
  const [remainingSearches, setRemainingSearches] = useState(remaining);

  const onClick = () => {
    if (remaining !== -1 && remaining > 0 && remainingSearches > 0 &&
      !isSearchParamsEqual(searchParams, defaultInitialState)) {
      setRemainingSearches(remainingSearches - 1);
    }

    if ((!permitted || remainingSearches === 0) && canPlanBeUpgraded) {
      openModal();
      return;
    }

    handleSearch();
  };

  let tooltipTitle = '';

  const hasAnyRequiredPlatform = requiredPlatforms.some(p => searchParams.platforms.includes(p));
  if (!hasAnyRequiredPlatform) {
    tooltipTitle = 'One of these platforms must be selected: Instagram, YouTube, TikTok, Pinterest.';
  }

  const buttonText = remainingSearches !== -1 ? `Search (${remainingSearches >= 0 ? remainingSearches : 0})` : 'Search';
  const disabled = !hasAnyRequiredPlatform || ((!permitted || remainingSearches === 0) && !canPlanBeUpgraded);

  useEffect(() => {
    setRemainingSearches(remaining);
  }, [remaining]);

  return (
    <Tooltip title={tooltipTitle} aria-label="search">
      <span className={classes.searchBtnWrapper}>
        <Button
          id="btn-search"
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText}
        </Button>
        {isAuthenticated && <UpgradeModal open={open} closeModal={closeModal} messageBody={maxSearchLimit} />}
        {!isAuthenticated && <BetaSignupModal
          open={open}
          closeModal={closeModal}
          onSignupComplete={closeModal}
        />}
      </span>
    </Tooltip>
  );
};

SearchButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSearch: PropTypes.func.isRequired,
  remaining: PropTypes.number.isRequired,
  permitted: PropTypes.bool.isRequired,
  canPlanBeUpgraded: PropTypes.bool.isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default withAll(
  withPermission('search', null, identity, true),
  withStyles(styles),
  withModal,
  connect(
    state => ({
      searchParams: state.searchParams,
      isAuthenticated: state.auth.isAuthenticated,
    }),
    null,
  ),
)(SearchButton);
