import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { recursiveFunc } from 'utils/mixins';
import { mapKeys, snakeCase } from 'lodash';
import { mpTrack, MP_EVENTS } from 'utils/copenhagen';

export const COMPETITIVE_REPORT_CLEAR_CACHE = 'COMPETITIVE_REPORT_CLEAR_CACHE';
export const GET_COMPETITIVE_REPORT = 'GET_COMPETITIVE_REPORT';
export const GET_COMPETITIVE_REPORT_EXPORT = 'GET_COMPETITIVE_REPORT_EXPORT';
export const GET_COMPETITIVE_REPORT_STATS = 'GET_COMPETITIVE_REPORT_STATS';
export const DELETE_COMPETITIVE_REPORT = 'DELETE_COMPETITIVE_REPORT';
export const GET_COMPETITIVE_REPORTS = 'GET_COMPETITIVE_REPORTS';
export const GET_CUSTOMIZED_COMPETITIVE_REPORTS = 'GET_CUSTOMIZED_COMPETITIVE_REPORTS';
export const CREATE_COMPETITIVE_REPORTS = 'CREATE_COMPETITIVE_REPORTS';
export const UPDATE_COMPETITIVE_REPORTS = 'UPDATE_COMPETITIVE_REPORTS';
export const GET_COMPETITIVE_REPORT_POSTS = 'GET_COMPETITIVE_REPORT_POSTS';
export const GET_COMPETITIVE_REPORT_VERTICAL_BREAKDOWN = 'GET_COMPETITIVE_REPORT_VERTICAL_BREAKDOWN';
export const GET_COMPETITIVE_REPORT_CREATORS = 'GET_COMPETITIVE_REPORT_CREATORS';
export const GET_COMPETITIVE_REPORT_DISTRIBUTION = 'GET_COMPETITIVE_REPORT_DISTRIBUTION';
export const GET_COMPETITIVE_REPORT_ACTIVITY = 'GET_COMPETITIVE_REPORT_ACTIVITY';
export const GET_COMPETITIVE_REPORT_ESTIMATED_SPEND = 'GET_COMPETITIVE_REPORT_ESTIMATED_SPEND';
export const REFRESH_COMPETITIVE_REPORT = 'REFRESH_COMPETITIVE_REPORT';

export const getCompetitiveReportDetail = reportId => async (dispatch) => {
  await dispatch({
    type: GET_COMPETITIVE_REPORT,
    payload: request('get', API.COMPETITIVE_REPORT_DETAIL(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getCompetitiveReportExport = reportId => async (dispatch) => {
  await dispatch({
    type: GET_COMPETITIVE_REPORT_EXPORT,
    payload: request('get', API.COMPETITIVE_REPORT_EXCEL(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.EXPORT_COMPETITIVE_REPORT, { reportId });
};

export const getCompetitiveReportStats = reportId => async (dispatch) => {
  await dispatch({
    type: GET_COMPETITIVE_REPORT_STATS,
    payload: request('get', API.COMPETITIVE_REPORT_STATS(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const deleteCompetitiveReport = (reportId, report) => async (dispatch) => {
  await dispatch({
    type: DELETE_COMPETITIVE_REPORT,
    payload: request('delete', API.COMPETITIVE_REPORT_DETAIL(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.DELETE_COMPETITIVE_REPORT, report);
};

export const getCompetitiveReports = () => async (dispatch) => {
  await dispatch({
    type: GET_COMPETITIVE_REPORTS,
    payload: request('get', API.COMPETITIVE_REPORTS(), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getCustomizedCompetitiveReports = () => async (dispatch) => {
  await dispatch({
    type: GET_CUSTOMIZED_COMPETITIVE_REPORTS,
    payload: request('get', API.CUSTOMIZED_COMPETITIVE_REPORTS(), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const createCompetitiveReports = data => async (dispatch) => {
  await dispatch({
    type: CREATE_COMPETITIVE_REPORTS,
    payload: request('post', API.COMPETITIVE_REPORTS(), {
      data: recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
    }),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.CREATE_COMPETITIVE_REPORT, data);
};

export const createCompetitiveReportAnonymous = data => async (dispatch) => {
  await dispatch({
    type: CREATE_COMPETITIVE_REPORTS,
    payload: request('post', API.COMPETITIVE_REPORTS_ANONYMOUS(), {
      data: recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
    }),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.CREATE_COMPETITIVE_REPORT, data);
};

export const updateCompetitiveReports = (reportId, data) => async (dispatch) => {
  await dispatch({
    type: UPDATE_COMPETITIVE_REPORTS,
    payload: request('put', API.COMPETITIVE_REPORT_DETAIL(reportId), {
      data: recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
    }),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.UPDATE_COMPETITIVE_REPORT, { reportId, ...data });
};

export const getCompetitiveReportPosts = (reportId, params = {}) => (dispatch) => {
  dispatch({
    type: GET_COMPETITIVE_REPORT_POSTS,
    payload: request('get', API.COMPETITIVE_REPORT_POSTS(reportId), {
      params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)),
    }),
    meta: { params },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const refreshCompetitiveReport = reportId => async (dispatch) => {
  await dispatch({
    type: REFRESH_COMPETITIVE_REPORT,
    payload: request('post', API.COMPETITIVE_REPORT_DETAIL(reportId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
  mpTrack(MP_EVENTS.REFRESH_COMPETITIVE_REPORT, {});
};

export const getCompetitiveReportVerticalBreakdown = (reportId, params = {}) => (dispatch) => {
  dispatch({
    type: GET_COMPETITIVE_REPORT_VERTICAL_BREAKDOWN,
    payload: request('get', API.COMPETITIVE_REPORT_VERTICAL_STATS(reportId), {
      params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)),
    }),
    meta: { params },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getCompetitiveReportCreators = (reportId, params = {}) => (dispatch) => {
  dispatch({
    type: GET_COMPETITIVE_REPORT_CREATORS,
    payload: request('get', API.COMPETITIVE_REPORT_CREATORS(reportId), {
      params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)),
    }),
    meta: { params },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getCompetitiveReportPlatformDistribution = (reportId, params = {}) => (dispatch) => {
  dispatch({
    type: GET_COMPETITIVE_REPORT_DISTRIBUTION,
    payload: request('get', API.COMPETITIVE_PLATFORM_DISTRIBUTION(reportId), {
      params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)),
    }),
    meta: { params },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getCompetitiveReportPostActivity = (reportId, params = {}) => (dispatch) => {
  dispatch({
    type: GET_COMPETITIVE_REPORT_ACTIVITY,
    payload: request('get', API.COMPETITIVE_POST_ACTIVITY(reportId), {
      params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)),
    }),
    meta: { params },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const getCompetitiveReportEstimatedInfluencerSpend = (reportId, params = {}) => (dispatch) => {
  dispatch({
    type: GET_COMPETITIVE_REPORT_ESTIMATED_SPEND,
    payload: request('get', API.COMPETITIVE_REPORT_ESTIMATED_SPEND(reportId), {
      params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)),
    }),
    meta: { params },
  }).catch((response) => {
    handleErrors(response);
  });
};
