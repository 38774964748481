import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';
import { recursiveFunc } from 'utils/mixins';
import { mapKeys, snakeCase, camelCase } from 'lodash';

export const GET_KEY_PHRASE_MONITORS = 'GET_KEY_PHRASE_MONITORS';
export const GET_KEY_PHRASE_MONITOR = 'GET_KEY_PHRASE_MONITOR';
export const CREATE_KEY_PHRASE_MONITOR = 'CREATE_KEY_PHRASE_MONITOR';
export const UPDATE_KEY_PHRASE_MONITOR = 'UPDATE_KEY_PHRASE_MONITOR';
export const DELETE_KEY_PHRASE_MONITOR = 'DELETE_KEY_PHRASE_MONITOR';
export const RESET_KEY_PHRASE_MONITOR = 'RESET_KEY_PHRASE_MONITOR';
export const GET_KEY_PHRASE_MONITOR_POSTS = 'GET_KEY_PHRASE_MONITOR_POSTS';
export const RESET_KEY_PHRASE_MONITOR_POSTS = 'RESET_KEY_PHRASE_MONITOR_POSTS';

export const getKeyPhraseMonitors = () => async (dispatch) => {
  await dispatch({
    type: GET_KEY_PHRASE_MONITORS,
    payload: request('get', API.KEY_PHRASE_MONITORS, {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const createKeyPhraseMonitor = data => async (dispatch) => {
  await dispatch({
    type: CREATE_KEY_PHRASE_MONITOR,
    payload: request('post', API.KEY_PHRASE_MONITORS, {
      data: {
        ...recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
        status: data.status ? 'active' : 'inactive',
      },
    }),
  }).catch((response) => {
    handleErrors({
      response: {
        status: response.response.status,
        data: recursiveFunc(mapKeys)(response.response.data, (v, k) => camelCase(k)),
      },
    });
  });
};

export const getKeyPhraseMonitor = monitorId => async (dispatch) => {
  await dispatch({
    type: GET_KEY_PHRASE_MONITOR,
    payload: request('get', API.KEY_PHRASE_MONITOR(monitorId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const updateKeyPhraseMonitor = (monitorId, data) => async (dispatch) => {
  await dispatch({
    type: UPDATE_KEY_PHRASE_MONITOR,
    payload: request('put', API.KEY_PHRASE_MONITOR(monitorId), {
      data: {
        ...recursiveFunc(mapKeys)(data, (v, k) => snakeCase(k)),
        status: data.status ? 'active' : 'inactive',
      },
    }),
  }).catch((response) => {
    handleErrors({
      response: {
        status: response.response.status,
        data: recursiveFunc(mapKeys)(response.response.data, (v, k) => camelCase(k)),
      },
    });
  });
};

export const deleteKeyPhraseMonitor = monitorId => async (dispatch) => {
  await dispatch({
    type: DELETE_KEY_PHRASE_MONITOR,
    payload: request('delete', API.KEY_PHRASE_MONITOR(monitorId), {}),
  }).catch((response) => {
    handleErrors(response);
  });
};

export const resetKeyPhraseMonitor = () => (dispatch) => {
  dispatch({
    type: RESET_KEY_PHRASE_MONITOR,
  });
};

export const getKeyPhraseMonitorPosts = (monitorId, params) => async (dispatch) => {
  await dispatch({
    type: GET_KEY_PHRASE_MONITOR_POSTS,
    payload: request(
      'get', API.KEY_PHRASE_MONITOR_POSTS(monitorId),
      { params: recursiveFunc(mapKeys)(params, (v, k) => snakeCase(k)) },
    ),
    meta: { params },
  }).catch((response) => {
    handleErrors(response);
  });
};

export const resetKeyPhraseMonitorPosts = () => (dispatch) => {
  dispatch({
    type: RESET_KEY_PHRASE_MONITOR_POSTS,
  });
};
