import { request } from 'lib/http';
import API from 'constants/api';
import { handleErrors } from 'utils/api';

export const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM';
export const MERGE_SEARCH_PARAMS = 'MERGE_SEARCH_PARAMS';
export const RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS';
export const DEFAULT_SEARCH_PARAMS = 'DEFAULT_SEARCH_PARAMS';


export const setSearchParam = (key, value) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_PARAM,
    key,
    value,
  });
};

export const mergeSearchParams = searchParams => (dispatch) => {
  dispatch({
    type: MERGE_SEARCH_PARAMS,
    searchParams,
  });
};

export const resetSearchParams = () => (dispatch) => {
  dispatch({
    type: RESET_SEARCH_PARAMS,
  });
};

export const setDefaultSearchParams = () => (dispatch) => {
  dispatch({
    type: DEFAULT_SEARCH_PARAMS,
    payload: request('post', API.DEFAULT_INFLUENCERS_FILTERS, {}),
  }).catch((response) => {
    handleErrors(response);
  });
};
