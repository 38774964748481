export default theme => ({
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '42px',
    border: `2px dotted ${theme.palette.border.dark}`,
  },
  dropzone__content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: 'auto',
    width: '100%',
    maxHeight: '50%',
  },
  dropzone__image: {
    color: theme.palette.text.meta,
    margin: 'auto',
  },
  dropzone__text: {
    margin: 'auto',
    fontSize: '1rem',
    color: theme.palette.text.meta,
    display: 'none',
  },
  image__selection: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    height: '372px',
    marginTop: '10px',
  },
  image__container: {
    width: '100%',
    height: '100%',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    margin: 'auto',
    display: 'block',
  },
  no__image: {
    background: theme.palette.white.dark,
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  no__image__text: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: 'auto',
    width: '100%',
  },
  image__buttons: {
    position: 'absolute',
    bottom: '5px',
    left: '5px',
  },
  delete__image__button: {
    marginLeft: 'auto',
    height: '35px',
  },
});
