import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Dimensions from 'react-dimensions';
import { CardContent } from '@material-ui/core';
import Card from 'components/base/Card';
import BarBasic from 'components/base/charts/Bar/BarBasic';
import { config } from 'theme';
import { Chart } from 'react-chartjs-2';
import styles from './styles';


const transformData = (verticals) => {
  const baseColor = Chart.helpers.color(config.palette.pink.main);
  const mixinColor = Chart.helpers.color(config.palette.blue.main);
  return {
    labels: verticals.map(v => v.vertical),
    datasets: [{
      data: verticals.map(v => v.totalPostCount),
      backgroundColor: verticals.map((_, i) => (
        baseColor.clone().mix(mixinColor, 1 - (i / verticals.length)).rgbString()
      )),
    }],
  };
};

class VerticalChart extends Component {
  getPlugins = () => {
    const {
      theme, containerWidth,
    } = this.props;

    if (containerWidth > theme.breakpoints.values.sm) {
      return {
        datalabels: {
          display: false,
        },
      };
    }

    return {
      datalabels: {
        anchor: 'start',
        align: '-90',
        clamp: true,
        rotation: -90,
        formatter: (value, context) => context.chart.data.labels[context.dataIndex],
      },
    };
  };

  getScales = () => {
    const {
      theme, containerWidth,
    } = this.props;

    const scales = {
      yAxes: [{
        display: false,
      }],
    };

    if (containerWidth > theme.breakpoints.values.sm) {
      scales.xAxes = [{
        gridLines: {
          display: false,
        },
      }];
    } else {
      scales.xAxes = [{
        display: false,
      }];
    }

    return scales;
  }

  render() {
    const {
      classes, verticals, onBarClick,
    } = this.props;

    return (
      <Card className={classes.card} >
        <CardContent className={classes.content} >
          <BarBasic
            data={transformData(verticals)}
            options={{
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: 'Creator activity by industry in the past year',
              },
              scales: this.getScales(),
              tooltips: {
                titleMarginBottom: 0,
                callbacks: {
                    label() {
                        return '';
                    },
                },
            },
              plugins: this.getPlugins(),
              onClick: onBarClick,
              maintainAspectRatio: false,
            }}
          />
        </CardContent>
      </Card>
    );
  }
}

VerticalChart.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  containerWidth: PropTypes.number.isRequired,
  verticals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onBarClick: PropTypes.func.isRequired,
};

export default Dimensions()(withStyles(styles, { withTheme: true })(VerticalChart));
