import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Fab, Fade, Box, useScrollTrigger } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import styles from './styles';


const ScrollTopButton = ({ classes }) => {
  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Fade in={trigger}>
      <Box
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        role="presentation"
        className={classes.buttonBox}
      >
        <Fab
          aria-label="scroll back to top"
          className={classes.buttonFab}
        >
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Fade>
  );
};

ScrollTopButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ScrollTopButton);
