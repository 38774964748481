import React from 'react';
import { Typography } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const NotFound404 = ({ classes }) => (
  <div className={classes.not__found}>
    <div>
      <div className={classes.four__oh__four}>
        <div className={classes.four__oh__four_text}>
          <Typography className={classes.four}>4</Typography>
          <Search className={classes.icon} />
          <Typography className={cx(classes.four, classes.second__four)}>4</Typography>
        </div>
      </div>
      <h3 className={classes.not__found__info}>
        Ooops! The page you are looking for could not be found
      </h3>
    </div>
  </div>
);

NotFound404.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const styles = theme => ({
  not__found: {
    paddingTop: '18em',
  },
  four__oh__four: {
    width: '100%',
    textAlign: 'center',
  },
  four__oh__four_text: {
    display: 'flex',
    flexDirection: 'row',
    width: '21.5em',
    margin: 'auto',
  },
  four: {
    fontSize: '10.5em',
    color: theme.palette.blue.main,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: '15em',
    marginTop: '0.12em',
    color: theme.palette.blue.main,
    marginLeft: '-0.1em',
  },
  second__four: {
    marginLeft: '-0.45em',
  },
  not__found__info: {
    textAlign: 'center',
    fontSize: '30px',
  },
});

export default withStyles(styles)(NotFound404);
