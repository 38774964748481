import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import withMultipleModals from 'components/base/withMultipleModals';
import UpgradeModal from 'components/common/UpgradeModal';
import AddReportModal from './AddReportModal';
import styles from './styles';

const ModalName = {
  CreateModal: 'CreateModal',
  UpdatePlanModal: 'UpdatePlanModal',
};

const AddReportButton = ({
  classes,
  permitted,
  open,
  openModal,
  closeModal,
  preselectedInfluencer,
  modalTitle,
  children,
  onModalChangeState,
}) => {
  const onClick = () => {
    onModalChangeState(true);
    if (permitted) {
      openModal(ModalName.CreateModal);
    } else {
      openModal(ModalName.UpdatePlanModal);
    }
  };

  const onClose = (modalName) => {
    closeModal(modalName);
    onModalChangeState(false);
  };

  const [submitting, setSubmitting] = React.useState(false);

  return (
    <React.Fragment>
      <IconButton
        className={classes.brandSafetyIconBackground}
        onClick={onClick}
        disabled={submitting}
      >
        { children }
      </IconButton>
      <AddReportModal
        isOpen={open[ModalName.CreateModal]}
        onClose={() => onClose(ModalName.CreateModal)}
        handleSubmit={setSubmitting}
        preselectedInfluencer={preselectedInfluencer}
        title={modalTitle}
      />
      <UpgradeModal
        open={open[ModalName.UpdatePlanModal]}
        closeModal={() => onClose(ModalName.UpdatePlanModal)}
        messageBody="You have reached the max limit of brand safety reports allowed for your plan type."
      />
    </React.Fragment>
  );
};

AddReportButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  permitted: PropTypes.bool.isRequired,
  open: PropTypes.arrayOf(PropTypes.bool).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  preselectedInfluencer: PropTypes.shape({}),
  modalTitle: PropTypes.string,
  children: PropTypes.shape({}).isRequired,
  onModalChangeState: PropTypes.func,
};

AddReportButton.defaultProps = {
  preselectedInfluencer: null,
  modalTitle: 'Add Brand Safety Reports',
  onModalChangeState: noop,
};

export default withAll(
  withMultipleModals([ModalName.CreateModal, ModalName.UpdatePlanModal]),
  withStyles(styles),
)(AddReportButton);
