import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import UpgradeButton from 'components/common/UpgradeButton';
import Card from 'components/base/Card';
import H6 from 'components/base/titles/CardHeading/H6';
import styles from './styles';

const UpgradeOverlay = ({
  classes,
  children,
  requireUpgrade,
}) => {
  if (!requireUpgrade) {
    return children;
  }
  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <Card className={classes.buttonCard}>
          <CardContent>
            <H6 align="center">Get full access by subscribing below.</H6>
            <UpgradeButton fullWidth />
          </CardContent>
        </Card>
      </div>
      <div className={classes.blurContainer}>
        {children}
      </div>
    </div>
  );
};

UpgradeOverlay.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
  requireUpgrade: PropTypes.bool,
};

UpgradeOverlay.defaultProps = {
  requireUpgrade: false,
};

export default withStyles(styles)(UpgradeOverlay);
