export default theme => ({
  root: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.shape.borderRadius,
    marginLeft: '0.4rem',
    color: theme.palette.white.main,
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: theme.customShadows[2],
      backgroundColor: theme.palette.green.dark,
    },
  },
});
