import React from 'react';

function withModal(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        open: false,
      };
    }

    openModal = () => {
      this.setState({
        open: true,
      });
    };

    closeModal = () => {
      this.setState({
        open: false,
      });
    };

    render() {
      return (
        <WrappedComponent
          open={this.state.open}
          openModal={this.openModal}
          closeModal={this.closeModal}
          {...this.props}
        />
      );
    }
  };
}

export default withModal;
