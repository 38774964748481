import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Icon, CardActions, CardContent, Typography } from '@material-ui/core';
import Input from 'components/base/forms/Input';
import PropTypes from 'prop-types';
import Error from 'components/base/forms/Error/index';
import { passwordValidator, passwordsMatch, validate } from 'utils/validators';
import { combine, required } from 'redux-form-validators';

const FORM_FULL_WIDTH = true;

const customValidate = validate({
  password: combine(
    required({ msg: 'Password is required.' }),
    passwordValidator,
  ),
  confirmPassword: combine(
    passwordsMatch('Password does not match.'),
    required({ msg: 'Password confirmation is required.' }),
  ),
});

class ChangePasswordForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    classes: PropTypes.shape({
    }).isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { handleSubmit, error, classes } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <CardContent>
          <div className={classes.reset__password__title}>
            <Typography variant="h5" color="inherit">
              Change Password
            </Typography>
          </div>
          <div>
            <Field
              name="password"
              component={Input}
              type="password"
              placeholder="new password"
              label="new password"
              fullWidth={FORM_FULL_WIDTH}
            />
          </div>
          <div>
            <Field
              name="confirmPassword"
              component={Input}
              type="password"
              placeholder="confirm new password"
              label="confirm new password"
              fullWidth={FORM_FULL_WIDTH}
            />
          </div>
          <div>
            <Error error={error} />
          </div>
          <div className={classes.reset__password__tip__wrapper}>
            <Typography className={classes.reset__password__tip}>
              Your password is case sensitive. All Passwords must be at least 8 characters, contain one capital letter,
              one number and one special character.
            </Typography>
          </div>
        </CardContent>
        <CardActions>
          <Button color="primary" size="small" type="submit"><Icon>send</Icon> Submit</Button>
        </CardActions>
      </form>
    );
  }
}

export default reduxForm({
  form: 'changePasswordForm', // a unique identifier for this form
  validate: customValidate,
})(ChangePasswordForm);
