import { newReducer } from '../utils';


const initialState = {
  message: null,
  status: null,
};

// eslint-disable-next-line import/prefer-default-export
export const errorsReducer = newReducer(initialState);

// eslint-disable-next-line no-unused-vars
errorsReducer.method.ON_FAILED_REQUEST = (state = initialState, action) => ({
  message: action.payload.data,
  status: action.payload.status,
});
