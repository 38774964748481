import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Page from './Page';
import FirstPageLink from './FirstPageLink';
import LastPageLink from './LastPageLink';
import styles from './styles';

const calculateRange = ({
  total,
  pageSize,
  display,
  current,
}) => {
  /**
   * Calculates page range that should show in pagination depending on current page (ex: 1,2,3,4 or 5,6,7,8)
   * @type {number}
   */
  // Total pages available in result set
  const totalPages = Math.ceil(total / pageSize);
  // Last page
  let end = totalPages;
  // First page
  let start = 0;
  // If pages to display is less than end then calculate what pages to display
  if (display < end) {
    // Find the beginning and end number to be displayed in the pagination page range. Always display one page after
    // current page. Ex: If current page is 10 and there is 15 pages, show [7, 8, 9, 10, 11].
    // rounded to the nearest integer smaller
    let beforeNumber = Math.round((display / 2) - 0.5);
    const afterNumber = beforeNumber;
    if (display % 2 === 0) {
      beforeNumber -= 1;
    }

    if (current <= beforeNumber + 1) {
      end = display;
    } else if (current >= (totalPages - afterNumber)) {
      start = (totalPages - display) + 1;
    } else {
      start = current - beforeNumber;
      end = current + afterNumber;
    }
  }
  return { end, start };
};

const getStateFromProps = (props) => {
  let { total, current, display } = props;
  const { pageSize } = props;
  total = total > 0 ? total : 1;
  display = display > 0 ? display : 1;
  current = current < total ? current : total;
  display = display < total ? display : total;
  return {
    current,
    display,
    total,
    pageSize,
  };
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const tem = getStateFromProps(props);
    this.setCurrent = this.setCurrent.bind(this);

    this.state = {
      ...tem,
      ...calculateRange(tem),
    };
  }

  componentWillReceiveProps(nextProps) {
    const tem = getStateFromProps(nextProps);
    this.setState({
      ...tem,
      ...calculateRange(tem),
    });
  }

  setCurrent(current) {
    if (this.state.current !== current) {
      const tem = { ...this.state, current };
      this.props.onChange(current);
      this.setState({
        ...tem,
        ...calculateRange(tem),
      });
    }
  }

  showPagination() {
    const {
      pageSize,
      total,
    } = this.state;
    return (total / pageSize) > 1;
  }

  render() {
    const array = [];
    const {
      current,
      start,
      end,
      pageSize,
      total,
    } = this.state;
    const { classes } = this.props;
    for (let i = start; i < end; i += 1) {
      array.push(i);
    }

    return (
      <div className={classes.root}>
        {this.showPagination() &&
          <React.Fragment>
            <FirstPageLink
              hide={current === 0}
              onClick={() => this.setCurrent(0)}
              styleFirstPageLink={this.props.styleFirstPageLink}
            />
            {
              array.map(page => (
                <Page
                  key={`page-${page}`}
                  value={page}
                  isActive={this.state.current === page}
                  onClick={() => this.setCurrent(page)}
                />
              ))
            }
            <LastPageLink
              onClick={() => this.setCurrent(Math.ceil(total / pageSize) - 1)}
              hide={current === Math.ceil(total / pageSize) - 1}
              styleLastPageLink={this.props.styleLastPageLink}
            />
          </React.Fragment>
        }
      </div>
    );
  }
}

Pagination.propTypes = {
  classes: PropTypes.shape({}).isRequired,

  // eslint-disable-next-line react/no-unused-prop-types
  total: PropTypes.number,

  // eslint-disable-next-line react/no-unused-prop-types
  current: PropTypes.number,

  // eslint-disable-next-line react/no-unused-prop-types
  display: PropTypes.number,

  // eslint-disable-next-line react/no-unused-prop-types
  pageSize: PropTypes.number,
  onChange: PropTypes.func.isRequired,

  styleRoot: PropTypes.shape({}),
  styleFirstPageLink: PropTypes.shape({}),
  styleLastPageLink: PropTypes.shape({}),
  styleButton: PropTypes.shape({}),
  stylePrimary: PropTypes.shape({}),
};

Pagination.defaultProps = {
  styleRoot: null,
  styleFirstPageLink: null,
  styleLastPageLink: null,
  styleButton: null,
  stylePrimary: null,
  display: 0,
  current: 0,
  total: 0,
  pageSize: 20,
};

export default withStyles(styles)(Pagination);
