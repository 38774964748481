import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { CardContent, Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import {
  getCompetitiveReportPostActivity as getCompetitiveReportPostActivityAction,
} from 'redux/reports/actions';
import { getDaysFromDate, getDateFromDays } from 'utils/helpers';
import Loader from 'components/base/Loader';
import Card from 'components/base/Card';
import withAll from 'components/base/withAll';
import UpgradeOverlay from 'components/common/UpgradeOverlay';
import DateFilter from 'components/common/DateFilter';
import PostByBrand from './PostByBrand';
import TotalPosts from './TotalPosts';
import PostsByPlatform from './PostsByPlatform';
import CreatorsUtilized from './CreatorsUtilized';
import PostsByCreatorReach from './PostsByCreatorReach';
import BrandByDay from './BrandByDay';
import PlatformsByDayOfWeek from './PlatformByDayOfWeek';
import SponsoredSelect from './SponsoredSelect';
import TabHeader from '../TabHeader';

const styles = () => ({
  root: {},
  filterSelect: {
    marginBottom: '2rem',
  },
  card: {
    marginBottom: '1.8rem',
    position: 'relative',
  },
});

const PostActivity = ({
  classes,
  params,
  viewFullReport,
  isLoading,
  loaded,
  getCompetitiveReportPostActivity,
  match: { params: { reportId } },
}) => {
  React.useEffect(() => {
    getCompetitiveReportPostActivity(
      reportId,
      { ...params },
    );
  }, []);

  const handleDateSelect = (fromDate, toDate) => {
    getCompetitiveReportPostActivity(
      reportId,
      {
        ...params,
        postedWithin: getDaysFromDate(fromDate),
        postedBefore: getDaysFromDate(toDate),
      },
    );
  };

  return (
    <div className={classes.root}>
      <TabHeader
        title="Post Activity"
        description="Provides historical insights into a brand&apos;s share of voice and details
              around the timing and frequency of their posting. This can help determine your brand&apos;s
              timing and when may be the best chance to achieve your objectives."
      >
        <DateFilter
          handleSelect={handleDateSelect}
          selectedFromDate={getDateFromDays(params.postedWithin)}
          selectedToDate={getDateFromDays(params.postedBefore)}
          customDate
        />
      </TabHeader>

      <div className={classes.filterSelect}>
        <SponsoredSelect />
      </div>

      <Loader loading={isLoading} loaded={loaded}>
        <Card className={classes.card}>
          <CardContent sel="post_by_brand">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <PostByBrand />
              </Grid>
              <Grid item md={4}>
                <TotalPosts />
              </Grid>
              <Grid item md={4}>
                <PostsByPlatform />
              </Grid>
              <Grid item md={4}>
                <CreatorsUtilized />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <UpgradeOverlay requireUpgrade={!viewFullReport}>
          <Card className={classes.card}>
            <CardContent sel="post_by_creator_reach">
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <PostsByCreatorReach />
                </Grid>
                <Grid item md={6}>
                  <BrandByDay />
                </Grid>
                <Grid item md={12}>
                  <PlatformsByDayOfWeek />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </UpgradeOverlay>
      </Loader>
    </div>
  );
};

PostActivity.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  viewFullReport: PropTypes.bool,
  getCompetitiveReportPostActivity: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

PostActivity.defaultProps = {
  viewFullReport: false,
};

const mapStateToProps = ({
  competitiveReportPostActivity: { data: { viewFullReport } },
  competitiveReportPostActivity,
}) => ({
  viewFullReport,
  isLoading: competitiveReportPostActivity.loading,
  loaded: competitiveReportPostActivity.loaded,
  params: competitiveReportPostActivity.params,
});

const mapDispatchToProps = {
  getCompetitiveReportPostActivity: getCompetitiveReportPostActivityAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(PostActivity);
