import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import Loader from 'components/base/Loader';
import PageTitle from 'components/base/titles/PageTitle';
import ACCOUNT_TAB from 'components/app/AccountSettings/constants';
import { request } from 'lib/http';
import API from 'constants/api';
import { buildAccountSettingsUrl, pricing } from 'constants/routing';
import styles from './styles';

const formatDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  return moment(new Date(dateString)).format('MMM DD, YYYY');
};

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSubscription: {},
      plans: [],
      loading: false,
    };
  }

  async componentDidMount() {
    this.loadAndSetPlans();
  }

  loadAndSetPlans = async () => {
    this.setState({ loading: true });
    const { plans } = await this.loadPlans();
    const userSubscription = await this.loadUserSubscription();
    this.setState({
      userSubscription,
      plans,
      loading: false,
    });
  };

  loadPlans = async () => request('get', API.SECURITY_PLANS, {});

  loadUserSubscription = async () => request('get', API.SECURITY_SUBSCRIPTION, {});

  goToBillingPortal = async () => {
    const response = await request('post', API.SECURITY_BILLING_SESSION, {
      data: { redirect_url: `${window.location.origin}${buildAccountSettingsUrl(ACCOUNT_TAB.SUBSCRIPTION)}` },
    });

    if (response.url) {
      window.location.href = response.url;
    }
  }

  goToPricingPage = async () => {
    window.location.href = pricing();
  }

  render() {
    const { classes } = this.props;
    const { loading, plans, userSubscription } = this.state;
    const subscriptions = [];

    plans.forEach((p) => {
      p.subscriptions.forEach((s) => {
        // eslint-disable-next-line no-param-reassign
        s.plan = p;
        subscriptions.push(s);
      });
    });

    let activeSubscription = subscriptions.filter(s => s.subscriptionId === userSubscription.subscriptionId);
    activeSubscription = activeSubscription.length ? activeSubscription[0] : null;

    const price = activeSubscription &&
      `${numeral(activeSubscription.price).format('$0.00')}/${activeSubscription.interval}`;

    const subscriptionCancelAt = formatDate(userSubscription.subscriptionCancelAt);
    const alreadyCanceled = new Date(userSubscription.subscriptionCancelAt) < new Date();

    return (
      <Loader loading={loading}>
        <PageTitle title="Subscription" />
        <div>
          {activeSubscription ? (
            <div>
              {subscriptionCancelAt && alreadyCanceled ?
                <Alert severity="warning" className={classes.subscribeAlert}>
                  Your subscription has expired. Subscribe now to gain access.
                </Alert> :
                (
                  <React.Fragment>
                    <Typography variant="h4">{activeSubscription.plan.name}</Typography>
                    <Typography variant="h5">{price}</Typography>
                  </React.Fragment>
                )
              }
              {subscriptionCancelAt && !alreadyCanceled && <Typography>Expires at {subscriptionCancelAt}</Typography>}
            </div>
          ) :
          (
            <React.Fragment>
              <Typography variant="h4">You&apos;re on the Free Trial Plan!</Typography>
            </React.Fragment>
          )}
          <div className={classes.btnContainer}>
            <Button variant="contained" className={classes.btn} onClick={this.goToPricingPage}>
              See More Plans
            </Button>
          </div>
        </div>
      </Loader>
    );
  }
}

Subscription.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(Subscription);
