import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.orange.label,
    padding: '0.1rem 0.4rem',
    borderRadius: '0.4rem',
    marginLeft: '0.2rem',
  },
});

const ObjectionableLabel = ({ classes, key, label }) => (
  <div key={key} className={classes.root}>{label}</div>
);

ObjectionableLabel.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(ObjectionableLabel);
