import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { isPositiveNumber } from 'utils/helpers';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const getValueColor = (coloredValues, value, theme) => {
  if (!coloredValues) {
    return theme.palette.black.main;
  }
  return isPositiveNumber(value) ? theme.palette.green.main : theme.palette.red.main;
};

const styles = theme => ({
  statLabel: {
    '& p': {
      color: theme.palette.black.light,
    },
  },
  statValue: {
    color: ({ value, coloredValues }) => getValueColor(coloredValues, value, theme),
  },
  statContainer: {
    padding: '0.2rem',
  },
  iconStyle: {
    fontSize: '1rem',
    marginLeft: '0.3rem',
  },
});

const StatCard = ({
  value,
  label,
  classes,
  formatter,
  showDirectionalArrow,
  ...other
}) => (
  <Grid className={classes.statContainer} item {...other}>
    <div>
      <Typography className={classes.statValue} variant="h6">
        {formatter(value)}
        <span>
          {isPositiveNumber(value) && showDirectionalArrow ?
            <ArrowUpwardIcon className={classes.iconStyle} /> :
            <ArrowDownwardIcon className={classes.iconStyle} />}
        </span>
      </Typography>
    </div>
    <div className={classes.statLabel}>
      <Typography variant="body1">{label}</Typography>
    </div>
  </Grid>
);

StatCard.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  coloredValues: PropTypes.bool,
  showDirectionalArrow: PropTypes.bool,
  formatter: PropTypes.func,
};

StatCard.defaultProps = {
  coloredValues: false,
  formatter: v => v,
  showDirectionalArrow: false,
};

export default withStyles(styles)(StatCard);
