import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { ALL_LABEL, LEGEND_MAX_CHAR } from 'components/common/CompetitiveReport/constants';
import BarBasic from 'components/base/charts/Bar/BarBasic';
import { get, orderBy, truncate } from 'lodash';
import { findBrandObjFromName } from 'components/common/CompetitiveReport/utils';
import H6 from 'components/base/titles/CardHeading/H6';
import Legend from 'components/base/charts/Legend';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { transformLegendLabels } from 'redux/reports/reducers';

const styles = () => ({
  root: {},
  titleText: {
    paddingRight: '0.8rem',
  },
});

const transformData = (competitiveReportData, brands) => {
  const { postsByDay } = competitiveReportData;
  const filteredDataSet = postsByDay.dataSets.filter(d => d.label !== ALL_LABEL);
  return {
    datasets: orderBy(filteredDataSet.map(d => ({
      data: d.values,
      label: d.label,
      backgroundColor: findBrandObjFromName(d.label, brands).legendColor,
    })), 'label'),
    labels: postsByDay.labels,
  };
};


const TotalPostsByDay = ({ data, classes }) => (
  <div className={classes.root}>
    <H6>
      <LabelWithInfoIcon
        labelText="Brand Posts by Day of Week"
        tooltip="The number of posts on each day of the week by the brand."
      />
      <Legend
        items={data.datasets.map(d => ({
          title: truncate(d.label, { length: LEGEND_MAX_CHAR }),
          color: d.backgroundColor,
        }))}
      />
    </H6>
    <BarBasic
      data={data}
      options={{
        legend: {
          display: false,
        },
      }}
    />
  </div>
);

TotalPostsByDay.propTypes = {
  data: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportPostActivity }) => ({
  data: transformData(
    competitiveReportPostActivity.data,
    transformLegendLabels(get(competitiveReportPostActivity, 'data.postsByMonth.dataSets', [])),
  ),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(TotalPostsByDay);
