import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Grid,
} from '@material-ui/core';
import { get, slice } from 'lodash';
import { connect } from 'react-redux';
import Post from 'components/common/FeaturedPosts/Post';


const styles = () => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const MAX_POSTS = 10;

const generateKey = i => `post-${i}`;

const FeaturedPosts = ({ classes, posts }) => (
  <Grid container spacing={3}>
    {posts.map((p, i) => (
      <Grid key={generateKey(i)} className={classes.gridItem} item md={4}>
        <Post data={p} />
      </Grid>
    ))}
  </Grid>
);

FeaturedPosts.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ brand }) => ({
  posts: slice(get(brand, 'data.posts'), 0, MAX_POSTS),
});

export default connect(mapStateToProps, null)(withStyles(styles)(FeaturedPosts));
