import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import VerticalBarHtml from 'components/base/charts/Bar/BarHtml/VerticalBarHtml';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import { PLATFORM_COLOR } from 'constants/options';
import H6 from 'components/base/titles/CardHeading/H6';

const styles = () => ({
  root: {
    textAlign: 'center',
  },
  title: {},
});

const AudienceDistribution = ({
  classes,
  title,
  data: { values, labels },
}) => (

  <div className={classes.root}>
    <H6 className={classes.title} align="center">{title}</H6>
    <VerticalBarHtml
      data={{
        values,
        labels: labels.map(l => <PlatformItem platform={l.toLowerCase()} />),
        colors: labels.map(l => PLATFORM_COLOR[l.toLowerCase()]),
      }}
    />
  </div>
);

AudienceDistribution.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};
export default withStyles(styles)(AudienceDistribution);
