export default theme => ({
  root: {
    padding: '0 1rem',
    width: '100%',
    display: 'flex',
    marginBottom: '0.4rem',
  },
  input: {
    flexGrow: '1',
  },
  icon: {
    color: theme.palette.green.main,
  },
  button: {
    '&:hover': {
      opacity: '0.8',
      backgroundColor: 'transparent',
    },
  },
});
