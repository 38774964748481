export default theme => ({
  noResults: {
    marginTop: '1rem',
  },
  buttonContainer: {
    textAlign: 'center',
    backgroundColor: theme.palette.border.main,
    boxShadow: 'none',
    paddingTop: '0.4rem',
    borderRadius: '0.4rem',
  },
  button: {},
  brandLogo: {
    marginRight: '1rem',
  },
  headerItem: {
    marginTop: '1rem',
  },
  title: {
    textAlign: 'left',
    display: 'block',
    fontSize: '1.5rem',
    margin: '1rem 0',
    '&:before': {
      width: '40px',
      marginBottom: '0.3rem',
      display: 'block',
      position: 'relative',
      content: '""',
      height: '2px',
      backgroundColor: theme.palette.pink.main,
    },
  },
});
