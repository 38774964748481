import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Carousel from 'components/base/Carousel';
import PageTitle from 'components/base/titles/PageTitle';
import CategoryCard from 'components/app/CategoryCard';
import { fetchFeaturedCategories } from '../../../../redux/featured/categories/actions';
import styles from './styles';

class Categories extends React.Component {
  componentDidMount() {
    this.props.fetchFeaturedCategories();
  }

  getChildren = () => {
    const { featuredCategories = [] } = this.props;
    return featuredCategories.map(category => (
      <CategoryCard
        key={`${category.category_id}_featured_category`}
        category={category}
      />
    ));
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <PageTitle title="Suggested Categories" />
        <div className={classes.carousel_container}>
          <Carousel numberOfCards={4} >
            {this.getChildren()}
          </Carousel>
        </div>
      </div>
    );
  }
}

Categories.defaultProps = {
  featuredCategories: [],
};

Categories.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  fetchFeaturedCategories: PropTypes.func.isRequired,
  featuredCategories: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = ({ featured: { categories: { categories: { data, loading } } } }) => ({
  featuredCategories: data || [],
  loading,
});

const mapDispatchToProps = {
  fetchFeaturedCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Categories));
