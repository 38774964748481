import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import { setSearchParam } from 'redux/searchParams/actions';
import { scrollToTop } from 'utils/helpers';
import { STEPS_INDXES } from 'constants/onboarding';
import { getInfluencers } from 'redux/influencers/actions';


const mapStateToProps = ({ onBoarding, searchParams }) => ({
  onBoarding,
  searchParams,
});


const withOnBoarding = WrappedComponent => connect(
  mapStateToProps,
  { updateOnBoarding, setSearchParam, getInfluencers },
)(class extends React.Component {
  static propTypes = {
    getInfluencers: PropTypes.func.isRequired,
    setSearchParam: PropTypes.func.isRequired,
  }

  handleOnBoardingCategorySearch = async (value) => {
    await this.props.setSearchParam('categories', value);
    scrollToTop();
    const { searchParams } = this.props;
    searchParams.page = 0;
    this.props.getInfluencers(searchParams);
    this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SEARCH_LOCATIONS });
  }

  handleCreateNewList = () => {
    const { onBoarding } = this.props;
    if (onBoarding.run && onBoarding.stepIndex === STEPS_INDXES.SAVED_LIST_3) {
      this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST_4 });
    }
  }

  handleSavedListsOnBoarding = () => {
    const { onBoarding } = this.props;
    if (onBoarding.run && onBoarding.stepIndex === STEPS_INDXES.SAVED_LIST_4) {
      setTimeout(() => {
        this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST_VIEW });
      }, 1000);
    }
  }

  handleSavedListDetailOnBoarding = () => {
    const { onBoarding } = this.props;
    if (onBoarding.run && onBoarding.stepIndex === STEPS_INDXES.SAVED_LIST_VIEW) {
      this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST_DETAIL });
    }
  }

  render() {
    return (
      <WrappedComponent
        handleOnBoardingCategorySearch={this.handleOnBoardingCategorySearch}
        handleCreateNewList={this.handleCreateNewList}
        handleSavedListsOnBoarding={this.handleSavedListsOnBoarding}
        handleSavedListDetailOnBoarding={this.handleSavedListDetailOnBoarding}
        {...this.props}
      />
    );
  }
});

export default WrappedComponent => withOnBoarding(WrappedComponent);
