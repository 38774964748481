import React, { Component } from 'react';
import Chip from 'components/base/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faYoutube,
  faSnapchatGhost,
  faPinterest,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';
import { createProfileLinkFromUsername, createUsernameDisplay } from 'utils/influencers';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const PLATFORM_STYLE_CONFIG = {
  instagram: {
    icon: <FontAwesomeIcon icon={faInstagram} />,
    label: 'Instagram',
    backgroundColor: 'linear-gradient(90deg, rgba(250,189,109,1) 0%, rgba(212,54,107,1) 50%, rgba(157,64,184,1) 100%)',
    color: '#fff',
    chipBackgroundColor: '#eee0f5',
  },
  facebook: {
    icon: <FontAwesomeIcon style={{ verticalAlign: 'middle' }} icon={faFacebookF} />,
    label: 'Facebook',
    backgroundColor: '#4267B2',
    color: '#fff',
    chipBackgroundColor: '#e0e7f3',
  },
  twitter: {
    icon: <FontAwesomeIcon style={{ verticalAlign: 'middle' }} icon={faTwitter} />,
    label: 'Twitter',
    backgroundColor: '#00acee',
    color: '#fff',
    chipBackgroundColor: '#dcf2fc',
  },
  youtube: {
    icon: <FontAwesomeIcon icon={faYoutube} />,
    label: 'Youtube',
    backgroundColor: '#ff0000',
    color: '#fff',
    chipBackgroundColor: '#fce0e0',
  },
  snapchat: {
    icon: <FontAwesomeIcon icon={faSnapchatGhost} />,
    label: 'Youtube',
    backgroundColor: '#fffc00',
    color: '#fff',
    chipBackgroundColor: '#f9fcdb',
  },
  tiktok: {
    icon: <FontAwesomeIcon icon={faTiktok} />,
    label: 'TikTok',
    backgroundColor: '#69c9d0',
    color: '#fff',
    chipBackgroundColor: '#ffe9ee',
  },
  pinterest: {
    icon: <FontAwesomeIcon icon={faPinterest} />,
    label: 'Pinterest',
    backgroundColor: '#e60023',
    color: '#fff',
    chipBackgroundColor: '#eee0f5',
  },
};

class SocialLink extends Component {
  platformTooltip = () => {
    const { platform } = this.props;
    return (
      <React.Fragment>
        <div>{createUsernameDisplay(platform.username, platform.platform)}</div>
      </React.Fragment>
    );
  }

  generateSocialIcon = () => {
    const { classes, platform } = this.props;
    const platformConfig = PLATFORM_STYLE_CONFIG[platform.platform];
    return (
      <div
        className={classes.social__icon__container}
        style={{
          background: platformConfig.backgroundColor,
          color: platformConfig.color,
        }}
      >
        {platformConfig.icon}
      </div>
    );
  }
  render() {
    const { platform, classes } = this.props;
    const platformConfig = PLATFORM_STYLE_CONFIG[platform.platform];
    if (platformConfig === undefined) {
      return <React.Fragment />;
    }

    return (
      <Tooltip title={this.platformTooltip()} placement="top">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={createProfileLinkFromUsername(platform.username, platform.platform)}
          className={classes.platform_link}
        >
          <Chip
            style={{
              backgroundColor: platformConfig.chipBackgroundColor,
            }}
            label={numeral(platform.followerCount).format('0a')}
            avatar={this.generateSocialIcon()}
            className={classes.platform_chip}
          />
        </a>
      </Tooltip>
    );
  }
}

SocialLink.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  platform: PropTypes.shape({
    platform: PropTypes.string.isRequired,
    followerCount: PropTypes.number,
    username: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(SocialLink);
