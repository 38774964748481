import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import FormModal from 'components/base/modals/FormModal';
import {
  createBrandSafetyReports as createBrandSafetyReportsAction,
  getBrandSafetyReports as getBrandSafetyReportsAction,
} from 'redux/brandSafetyReports/actions';
import { forms } from 'constants/config';
import BrandSafetyReportsForm from './BrandSafetyReportsForm';
import styles from './styles';

const SuccessMessage = () => <Typography variant="body2">Reports has been queued.</Typography>;

class AddReportsModal extends React.Component {
  onModalClose = () => {
    const { onClose } = this.props;
    reset(forms.BRAND_SAFETY_REPORT);
    onClose();
  };

  onSubmit = async ({ influencers, objectionableListPriorities }) => {
    const { createBrandSafetyReports, getBrandSafetyReports } = this.props;
    const influencersData = influencers.map(i => i.id);

    if (!influencersData.length) {
      return;
    }

    try {
      await createBrandSafetyReports({
        influencer_ids: influencersData,
        objectionableListPriorities,
      });

      toast.success(<SuccessMessage />);
      getBrandSafetyReports();
      this.onModalClose();
    } catch (error) {
      if (error.errors) {
        if (error.errors.status === 403) {
          toast.error(<Typography variant="body2">{error.errors.detail}</Typography>);
          return;
        }
        throw new SubmissionError(error.errors);
      }
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <React.Fragment>
        <FormModal
          open={isOpen}
          onClose={this.onModalClose}
          onSubmit={this.onSubmit}
          title="Add Brand Safety Reports"
          form="brandSafetyReportsForm"
          width="800px"
          height="auto"
          initial={{}}
        >
          <BrandSafetyReportsForm />
        </FormModal>
      </React.Fragment>
    );
  }
}

AddReportsModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createBrandSafetyReports: PropTypes.func.isRequired,
  getBrandSafetyReports: PropTypes.func.isRequired,
};

const mapStateToProps = ({ brandSafetyReport }) => ({
  loading: brandSafetyReport.loading,
});

const mapDispatchToProps = {
  createBrandSafetyReports: createBrandSafetyReportsAction,
  getBrandSafetyReports: getBrandSafetyReportsAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AddReportsModal);
