export default theme => ({
  card: {
    width: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  logo: {
    textAlign: 'center',
    opacity: 0.3,
    marginBottom: '10px',
  },
  reset__password__title: {
    textAlign: 'center',
  },
  reset__password__tip__wrapper: {
    padding: '0.5rem',
    textAlign: 'center',
    border: '1px solid',
    borderColor: theme.palette.blue.main,
  },
  reset__password__tip: {
    color: theme.palette.blue.main,
  },
});
