import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import InfluencerCard from 'components/common/InfluencerCard';
import { CardContent, CardHeader, Grid } from '@material-ui/core';
import Card from 'components/base/Card';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';
import styles from './styles';

const VerticalCreators = ({
  classes,
  creators,
  permittedProfile,
  permittedProfileViews,
}) => (
  <Card>
    <CardHeader title="Sample of Top Brands and Creators" />
    <CardContent>
      {isEmpty(creators) && (
        <Alert severity="warning">
              We don&apos;t have sufficient data for this vertical. Please try again later.
        </Alert>
      )}
      <Grid container spacing={2}>
        {creators.map(p => (
          <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
            <InfluencerCard
              influencer={p}
              canViewProfile={permittedProfile}
              permittedProfileViews={permittedProfileViews}
              canViewTopBrands
              canSaveToList={false}
            />
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

VerticalCreators.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  creators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  permittedProfile: PropTypes.bool,
  permittedProfileViews: PropTypes.shape({}),
};

VerticalCreators.defaultProps = {
  permittedProfile: false,
  permittedProfileViews: {},
};

export default withStyles(styles)(VerticalCreators);
