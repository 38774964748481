import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'inline-block',
    textAlign: 'center',
  },
  countNumber: {
    fontSize: '1rem',
    color: theme.palette.border.dark,
    fontWeight: 800,
  },
});


const PieCountBackground = ({ value, classes }) => (
  <div className={classes.root}>
    <Typography className={classes.countNumber}>
      {value}
    </Typography>
  </div>
);

PieCountBackground.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(PieCountBackground);
