import React from 'react';
import { Typography } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const ErrorPage = ({ classes, message }) => (
  <div className={classes.error__page}>
    <div>
      <div className={classes.uh__oh}>
        <div className={classes.uh__oh__text}>
          <Typography className={classes.text}>Uh  </Typography>
          <ErrorOutline className={classes.icon} />
          <Typography className={cx(classes.text, classes.last__h)}>h!</Typography>
        </div>
      </div>
      <h3 className={classes.error__info}>
        Ooops! Looks like something has gone wrong, please refresh!
      </h3>
      { message &&
      <h3 className={classes.error__info}>
        {message}
      </h3> }
    </div>
  </div>
);

ErrorPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  message: PropTypes.string,
};

ErrorPage.defaultProps = {
  message: null,
};

const styles = theme => ({
  error__page: {
    paddingTop: '18em',
  },
  uh__oh: {
    width: '100%',
    textAlign: 'center',
  },
  uh__oh__text: {
    display: 'flex',
    flexDirection: 'row',
    width: '34em',
    margin: 'auto',
  },
  text: {
    fontSize: '10.5em',
    color: theme.palette.blue.main,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: '10em',
    marginTop: '0.25em',
    color: theme.palette.blue.main,
    marginLeft: '0.1em',
  },
  last__h: {
    marginLeft: '-0.05em',
  },
  error__info: {
    textAlign: 'center',
    fontSize: '30px',
  },
});

export default withStyles(styles)(ErrorPage);
