import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required, validateForm } from 'redux-form-validators';
import { withStyles } from '@material-ui/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from 'components/base/Button';
import Input from 'components/base/forms/Input';
import withAll from 'components/base/withAll';
import { forms } from 'constants/config';
import styles from './styles';

const customValidate = {
  name: required({ msg: 'Required.' }),
};

const NewListForm = ({ classes, handleSubmit }) => (
  <form className={classes.root} onSubmit={handleSubmit}>
    <Field
      className={classes.input}
      name="name"
      component={Input}
      type="text"
      placeholder="Or create a new list"
    />
    <Button className={classes.button} type="submit" id="btn_add_to_list">
      <AddBoxIcon className={classes.icon} />
    </Button>
  </form>
);

NewListForm.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withAll(
  reduxForm({
    form: forms.NEW_LIST_FORM,
    validate: validateForm(customValidate),
  }),
  withStyles(styles),
)(NewListForm);
