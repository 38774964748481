import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PlatformIcon from 'components/common/PlatformIcon';
import ImageBoxWithDefault from 'components/common/ImageBoxWithDefault';
import ObjectionableLabel from './ObjectionableLabel';
import styles from './styles';

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

const Post = ({
  classes, post, lists, showAnomalyEngagement,
}) => {
  const renderStat = (title, value) => value && (
    <div className={classes.stat}>
      <span className={classes.statValue}>{value}</span>
      <span className={classes.statTitle}>{title}</span>
    </div>
  );

  const getListLabel = (listId) => {
    const found = lists.filter(l => l.id === listId);
    return found.length && found[0].label;
  };

  return (
    <div className={classes.root} key={post.postId}>
      <div className={classes.header}>
        <PlatformIcon platform={post.platform} className={classes.platformIcon} />
        <div className={classes.usernameWrapper}>
          <div className={classes.username}>@{post.username}</div>
          <div className={classes.date}>Posted on: {moment(post.postTime).format('DD MMM, YYYY')}</div>
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.image}>
          <Link href={post.link} target="_blank">
            <ImageBoxWithDefault
              src={post.imageUrl}
              caption="Click to View externally"
              imgNotAvailTextClass={classes.imgNotAvailTextClass}
            />
          </Link>
        </div>
        <div className={classes.stats}>
          {renderStat('Followers', post.followerCount ? formatter.format(post.followerCount) : null)}
          {renderStat('Post Likes', post.likes ? formatter.format(post.likes) : null)}
          {renderStat('Comments', post.comments ? formatter.format(post.comments) : null)}
          {renderStat('Post Views', post.videoViews ? formatter.format(post.videoViews) : null)}
          {renderStat('Engagement', post.engagement ? `${formatter.format(post.engagement)}%` : null)}
        </div>
      </div>
      <div className={classes.caption}>{post.caption}</div>
      {post.objectionableLists ? post.objectionableLists.map(ol => (
        <div key={`${post.postId}__${ol.id}`} className={classes.objectionableList}>
          <div className={classes.objectionableListTitle}>{getListLabel(ol.id)}:</div>
          <div className={classes.objectionableListValue}>
            {ol.matchedPhrases.map(p => <ObjectionableLabel key={`${post.postId}_${p}`} label={p} />)}
          </div>
        </div>
      )) : null}
      {showAnomalyEngagement &&
        <div className={classes.anomalyEngagementContent}>
          <b>Reason:</b> This post has <b>{formatter.format(post.engagement)}%</b> engagement,
          which is <b>{formatter.format(post.anomalyEngagementPercentage)}%</b> more
          than usual for last year.
        </div>
      }
    </div>
  );
};

Post.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  post: PropTypes.shape({
    postId: PropTypes.string.isRequired,
    postTime: PropTypes.number.isRequired,
    platform: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    followerCount: PropTypes.number,
    likes: PropTypes.number,
    comments: PropTypes.number,
    videoViews: PropTypes.number,
    engagement: PropTypes.number,
    objectionableLists: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      matchedPhrases: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    })),
  }).isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    postCount: PropTypes.number.isRequired,
  }).isRequired),
  showAnomalyEngagement: PropTypes.bool,
};

Post.defaultProps = {
  lists: [],
  showAnomalyEngagement: false,
};

export default withStyles(styles)(Post);
