import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Card from 'components/base/Card';
import { CardContent } from '@material-ui/core';
import withAll from 'components/base/withAll';
import PageTitle from 'components/base/titles/PageTitle';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import {
  updateCompetitiveReports as updateCompetitiveReportsAction,
  getCompetitiveReportDetail as getCompetitiveReportDetailAction,
} from 'redux/reports/actions';
import Button from 'components/base/Button';
import withModal from 'components/base/withModal';
import { isEqual } from 'lodash';
import CompetitiveReportForm from './CompetitiveReportForm';
import DeleteDialog from './DeleteDialog';

const styles = () => ({
  root: {
    overflow: 'visible',
  },
  card: {
    overflow: 'visible',
  },
});

const SuccessMessage = () => (
  <React.Fragment>
    Report has been updated.
  </React.Fragment>
);

class UpdateReport extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(
      nextProps,
      this.props,
    );
  }

  onSubmit = async (formData) => {
    const {
      updateCompetitiveReports,
      competitiveReport,
      getCompetitiveReportDetail,
    } = this.props;
    try {
      await updateCompetitiveReports(competitiveReport.id, {
        ...formData,
        brandIds: formData.brandIds.map(b => b.id),
      });
      await getCompetitiveReportDetail(competitiveReport.id);
      toast.success(<SuccessMessage />);
    } catch (error) {
      if (error.errors) {
        throw new SubmissionError(error.errors);
      }
    }
  };

  render() {
    const {
      classes,
      competitiveReport,
      open,
      closeModal,
      openModal,
      loading,
    } = this.props;
    return (
      <div className={classes.root} key="update-container">
        <DeleteDialog report={competitiveReport} open={open} closeModal={closeModal} />
        <PageTitle title={`Update ${competitiveReport.title}`} />
        <Card className={classes.card}>
          <CardContent>
            <CompetitiveReportForm
              onSubmit={data => this.onSubmit(data)}
              initial={{
                brandIds: competitiveReport.brands,
                active: competitiveReport.active,
                title: competitiveReport.title,
              }}
              actions={(
                <React.Fragment>
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    type="link"
                    onClick={openModal}
                    component="a"
                  >
                    Delete
                  </Button>
                  <Button
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </React.Fragment>
              )}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

UpdateReport.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  updateCompetitiveReports: PropTypes.func.isRequired,
  getCompetitiveReportDetail: PropTypes.func.isRequired,
  competitiveReport: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ competitiveReport }) => ({
  competitiveReport: {
    id: competitiveReport.data.id,
    title: competitiveReport.data.title,
    active: competitiveReport.data.active,
    brands: competitiveReport.data.brands.map(b => ({
      ...b,
      label: b.name,
      value: b.name,
    })),
  },
  loading: competitiveReport.loading,
});

const mapDispatchToProps = {
  updateCompetitiveReports: updateCompetitiveReportsAction,
  getCompetitiveReportDetail: getCompetitiveReportDetailAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withModal,
)(UpdateReport);
