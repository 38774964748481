import React from 'react';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import FooterNav from 'components/common/FooterNav';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    minHeight: '100%',
    marginBottom: `-${theme.sizes.layout.footer.height.desktop}`,
  },
  push: {
    height: theme.sizes.layout.footer.height.desktop,
  },
});

const LoggedOutLayout = ({ classes, children }) => (
  <React.Fragment>
    <Container maxWidth={false} className={classes.root}>
      {children}
      <div className={classes.push} />
    </Container>
    <FooterNav />
  </React.Fragment>
);

LoggedOutLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
};

export default withStyles(styles)(LoggedOutLayout);
