import { get, includes, keyBy, omit, remove, update } from 'lodash';
import { newReducer } from '../utils';
import { updateInfluencerBrandSafetyReport } from '../brandSafetyReports/utils';

const initialState = {
  data: {},
  deleting: null,
  loading: false,
  previous: null,
  members: {
    data: [],
    loading: false,
  },
  categories: {
    data: [],
    loading: false,
  },
};

const exportInitalState = {
  loading: false,
  listData: {
    url: null,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const listsReducer = newReducer(initialState);

listsReducer.method.FETCH_LISTS_FULFILLED = (state = initialState, action) => ({
  ...state,
  data: keyBy(get(action.payload, 'lists'), 'uid'),
  loading: false,
});

listsReducer.method.FETCH_LISTS_PENDING = (state = initialState) => ({
  ...state,
  loading: true,
});

// eslint-disable-next-line no-unused-vars
listsReducer.method.FETCH_LISTS_REJECTED = (state = initialState) => ({
  ...state,
  members: {
    data: {},
    loading: false,
  },
});

listsReducer.method.FETCH_LIST_FULFILLED = (state = initialState, action) => ({
  ...state,
  data: keyBy(get(action.payload, 'lists'), 'uid'),
  loading: false,
});

listsReducer.method.FETCH_LIST_PENDING = (state = initialState) => ({
  ...state,
  loading: true,
});

listsReducer.method.FETCH_LIST_REJECTED = (state = initialState) => ({
  ...state,
  loading: false,
  members: {
    data: {},
    loading: false,
  },
});

listsReducer.method.FETCH_MEMBERS_FULFILLED = (state = initialState, action) => ({
  ...state,
  members: {
    data: get(action.payload, 'members'),
    loading: false,
  },
});

listsReducer.method.FETCH_MEMBERS_PENDING = (state = initialState) => ({
  ...state,
  members: {
    data: [],
    loading: true,
  },
});

listsReducer.method.FETCH_MEMBERS_REJECTED = (state = initialState) => ({
  ...state,
  members: {
    data: [],
    loading: false,
  },
});

listsReducer.method.EXPORT_LIST_FULFILLED = (state = exportInitalState, action) => ({
  ...state,
  listData: {
    url: get(action.payload, 'url'),
  },
});

listsReducer.method.EXPORT_LIST_PENDING = (state = exportInitalState) => ({
  loading: true,
  ...state,
});

listsReducer.method.EXPORT_LIST_REJECTED = (state = exportInitalState) => ({
  ...state,
});

listsReducer.method.UPDATE_LIST_FULFILLED = (state = initialState, { meta: { listId, listUpdate, other } }) => ({
  ...state,
  data: update(state.data, listId, existing => ({
    ...existing,
    ...listUpdate,
    ...other,
  })),
  loading: false,
});

listsReducer.method.UPDATE_LIST_PENDING = (state = initialState) => ({
  ...state,
  loading: true,
});

listsReducer.method.UPDATE_LIST_REJECTED = (state = initialState) => ({
  ...state,
  loading: false,
});

listsReducer.method.DELETE_LIST_PENDING = (state = initialState, { meta: { listId } }) => ({
  ...state,
  data: omit(state.data, listId),
  deleting: get(state.data, listId),
});

listsReducer.method.DELETE_LIST_REJECTED = (state = initialState, action) => {
  const { data, deleting } = state;
  const { meta: { listId } } = action;
  data[listId] = deleting;
  return {
    ...state,
    data,
    deleting: null,
  };
};

listsReducer.method.DELETE_LIST_FULFILLED = (state = initialState) => ({
  ...state,
  deleting: null,
});

listsReducer.method.REMOVE_FROM_LISTS_PENDING = (state = initialState, { meta: { influencerIds } }) => {
  const { members: { data } } = state;
  const newMembersData = remove(data, member => !includes(influencerIds, member.id));
  return {
    ...state,
    members: {
      ...state.members,
      data: newMembersData,
    },
  };
};

listsReducer.method.FETCH_LIST_CATEGORIES_FULFILLED = (state = initialState, action) => ({
  ...state,
  categories: {
    data: action.payload,
    loading: false,
  },
});

listsReducer.method.FETCH_LIST_CATEGORIES_PENDING = (state = initialState) => ({
  ...state,
  categories: {
    data: [],
    loading: true,
  },
});

listsReducer.method.FETCH_LIST_CATEGORIES_REJECTED = (state = initialState) => ({
  ...state,
  categories: {
    data: [],
    loading: false,
  },
});

listsReducer.method.CREATE_BRAND_SAFETY_REPORTS_FULFILLED = (state = initialState, action) => ({
  ...state,
  members: {
    data: updateInfluencerBrandSafetyReport(state.members.data, get(action, 'payload.results', [])),
  },
});
