import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/base/Button';
import {
  TableCell,
  TableRow,
  Icon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { openInNewTab } from 'utils/helpers';
import RefreshAction from 'components/common/CompetitiveReports/RefreshAction';
import { connect } from 'react-redux';

const styles = () => ({
  name: {
    width: '100%',
  },
});


const ReportRow = ({
  report,
  userId,
  classes,
}) => (
  <React.Fragment>
    <TableRow key={`report-${report.id}`} className={classes.root}>
      <TableCell component="th" scope="row" className={classes.name}>
        {report.name}
      </TableCell>
      <TableCell align="left">
        <Button
          onClick={() => openInNewTab(report.report)}
          className={classes.download}
          variant="contained"
          color="primary"
        >
          <Icon>download</Icon>
        </Button>
      </TableCell>
      <TableCell align="left">
        {userId === report.createdById && <RefreshAction report={report} />}
      </TableCell>
    </TableRow>
  </React.Fragment>
);


ReportRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    active: PropTypes.bool,
    createdById: PropTypes.number,
  }).isRequired,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ userProfile: { data: { id } } }) => ({
  userId: id,
});

export default withAll(
  connect(mapStateToProps, {}),
  withStyles(styles),
)(ReportRow);
