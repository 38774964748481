import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { getBrandAutoComplete } from 'redux/autocompletes/actions';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import AutoComplete from 'components/base/AutoComplete';
import { scrollToTop } from 'utils/helpers';
import { debounce, isEmpty } from 'lodash';
import { brandProfileUrl } from 'constants/routing';
import styles from './styles';
import NoResults from './NoResults';

const MINIMUM_INPUT_CHARS = 3;

const renderInputComponent = (inputProps) => {
  const {
    classes,
    inputRef = () => {},
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        className: classes.input,
        fullWidth: true,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" className={classes.searchIcon}>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      type="text"
      {...other}
    />
  );
};

class BrandAutoComplete extends Component {
  state = {
    value: '',
    openNoResults: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionSelected = (event, suggestion) => {
    scrollToTop();
    this.props.history.push(brandProfileUrl(suggestion.suggestion.value.id));
  };

  onChange = (value) => {
    this.setState({
      value,
    });
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({
      openNoResults: false,
    });
  };

  loadSuggestions = debounce(async (value) => {
    if (value.length < MINIMUM_INPUT_CHARS) {
      this.setState({
        openNoResults: false,
      });
      return;
    }

    await this.props.getBrandAutoComplete(value);
    if (isEmpty(this.props.brandAutocomplete.results)) {
      this.setState({
        openNoResults: true,
      });
    }
  }, 300);


  render() {
    const {
      classes,
      brandAutocomplete,
    } = this.props;
    const { value, openNoResults } = this.state;
    const suggestions = brandAutocomplete.results;

    return (
      <div
        className={classes.root}
        ref={(node) => {
          this.node = node;
        }}
      >
        <AutoComplete
          showAfter={MINIMUM_INPUT_CHARS}
          renderInputComponent={renderInputComponent}
          inputProps={{
            classes,
            placeholder: 'Search brand name.',
          }}
          onChange={this.onChange}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionSelected={this.onSuggestionSelected}
        />
        {openNoResults &&
          <NoResults username={value} />
        }
      </div>
    );
  }
}

BrandAutoComplete.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getBrandAutoComplete: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  brandAutocomplete: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({}),
    })).isRequired,
  }).isRequired,
};

BrandAutoComplete.defaultProps = {};

const mapStateToProps = ({ brandAutocomplete }) => ({
  brandAutocomplete,
});

const mapDispatchToProps = {
  getBrandAutoComplete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(BrandAutoComplete)));
