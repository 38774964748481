export default theme => ({
  root: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  postedOn: {
    display: 'flex',
    alignItems: 'center',
  },
  postedOnLabel: {
    marginRight: '1rem',
  },
  flaggedFor: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flaggedForLabel: {
    marginRight: '1rem',
  },
  flaggedForFormGroup: {
    flexDirection: 'row',
  },
  flaggedForCheckboxLabel: {
    marginRight: '1.2rem',
  },
  noResults: {
    width: '100%',
    padding: '3rem',
    marginBottom: '3rem',
    marginTop: '8px',
    textAlign: 'center',
    background: theme.palette.white.main,
    color: theme.palette.black.dark,
  },
});
