const sort = (valueA, valueB, desc = false) => {
  if (valueA < valueB) {
    return desc ? 1 : -1;
  }
  if (valueA > valueB) {
    return desc ? -1 : 1;
  }
  return 0;
};

export const RankByField = {
  Interactions: 1,
  EngagementRate: 2,
  FollowerCount: 3,
  SponsoredEngagementRate: 4,
  Date: 5,
};

export const RankByComparators = {
  [RankByField.Interactions]: (a, b) => {
    const interactionA = a.likes + a.comments + a.videoViews;
    const interactionB = b.likes + b.comments + b.videoViews;
    return sort(interactionA, interactionB, true);
  },
  [RankByField.EngagementRate]: (a, b) => sort(a.engagement, b.engagement, true),
  [RankByField.FollowerCount]: (a, b) => sort(a.followerCount, b.followerCount, true),
  [RankByField.SponsoredEngagementRate]: (a, b) => sort(
    a.isSponsored ? a.engagement : 0,
    b.isSponsored ? b.engagement : 0,
    true,
  ),
  [RankByField.Date]: (a, b) => sort(a.postTime, b.postTime, true),
};

export const filterIfRankBySponsored = (rankByField, posts, handleError) => {
  if (rankByField !== RankByField.SponsoredEngagementRate) return posts;
  if (posts.filter(p => p.isSponsored === true).length === 0) {
    handleError();
    return posts;
  }
  return posts.filter(p => p.isSponsored === true);
};
