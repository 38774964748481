export default theme => ({
  buttons: {
    display: 'flex',
    padding: '1rem',
  },
  btn: {
    marginRight: '0.5rem',
  },
  tab__container: {
    boxShadow: theme.customShadows[1],
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  tab__item: {
    margin: '0',
    width: '50%',
    textTransform: 'none',
    fontSize: '1rem',
  },
});
