import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from 'components/base/AuthenticatedRoute';
import Brand from 'components/app/Brand';
import Brands from 'components/app/Brands';
import LoggedInLayout from 'components/base/LoggedInLayout';
import BaseLayout from 'components/base/BaseLayout';
import PermissionedRoute from 'components/base/PermissionedRoute';
import UnauthenticatedRoute from 'components/base/UnauthenticatedRoute';
import AccountSettings from 'components/app/AccountSettings';
import BrandSafetyReport from 'components/app/BrandSafetyReport';
import BrandSafetyReports from 'components/app/BrandSafetyReports';
import ChangePassword from 'components/app/ChangePassword';
import CompetitiveReport from 'components/app/CompetitiveReport';
import CompetitiveReports from 'components/app/CompetitiveReports';
import CompetitiveReportCreate from 'components/app/CompetitiveReportCreate';
import CompetitiveReportCreatePublic from 'components/app/CompetitiveReportCreatePublic';
import CompetitiveReportUpdate from 'components/app/CompetitiveReportUpdate';
import Featured from 'components/app/Featured';
import ForgotPassword from 'components/app/ForgotPassword';
import Home from 'components/app/Home';
import InfluencerProfile from 'components/app/InfluencerProfile';
import Lists from 'components/app/Lists';
import ListDetails from 'components/app/Lists/ListDetails';
import LogIn from 'components/app/Login';
import NotFound404 from 'components/app/InterstitialPages/NotFound404';
import Pricing from 'components/app/Pricing';
import PrivacyPolicy from 'components/app/PrivacyPolicy';
import Register from 'components/app/Register';
import Search from 'components/app/Search';
import SetupPassword from 'components/app/SetupPassword';
import TermsOfUse from 'components/app/TermsOfUse';
import VerifyEmail from 'components/app/VerifyEmail';
import Vertical from 'components/app/Vertical';
import Verticals from 'components/app/Verticals';
import BrandRequest from 'components/app/BrandRequest';
import PaidRoute from 'components/common/PaidRoute';
import {
  accountSettingsUrl,
  brandUrl,
  brandsUrl,
  brandSafetyReportUrl,
  brandSafetyReportsUrl,
  changePassword,
  competitiveReportCreateUrl,
  competitiveReportUpdateUrl,
  competitiveReportUrl,
  competitiveReportsUrl,
  featuredUrl,
  forgotPassword,
  home,
  listsUrl,
  login,
  nonExisting,
  pricing,
  privacyPolicy,
  register,
  searchUrl,
  setupPassword,
  termsOfUse,
  verifyEmailUrl,
  verticalDetailUrl,
  verticalsUrl,
  buildBrandRequestUrl,
  creatorListeningUrl,
  keyPhraseMonitorUrl,
} from 'constants/routing';
import SemLandingPage from 'components/app/SEMLandingPage';
import urlList from 'constants/landingPageUrlList';
import CreatorListeningMonitors from 'components/app/CreatorListeningMonitors';
import KeyPhraseMonitor from 'components/app/KeyPhraseMonitor';
import FooterSpace from 'components/common/FooterSpace';

const homePageParamString = new URLSearchParams({ target: 'signUpSection' });

const wrappedRoutes = childProps => () => (
  <LoggedInLayout>
    <div className="container__wrap">
      <Switch>
        <Route
          exact
          props={childProps}
          path={verticalsUrl()}
          component={Verticals}
        />
        <Route
          exact
          props={childProps}
          path={verticalDetailUrl()}
          component={Vertical}
        />
        <Route
          exact
          props={childProps}
          path={brandsUrl()}
          component={Brands}
        />
        <Route
          exact
          props={childProps}
          path={brandUrl()}
          component={Brand}
        />
        <Route
          exact
          props={childProps}
          path={competitiveReportCreateUrl()}
          component={childProps.isAuthenticated ? CompetitiveReportCreate : CompetitiveReportCreatePublic}
        />
        <PaidRoute
          exact
          props={childProps}
          path={competitiveReportUpdateUrl()}
          component={CompetitiveReportUpdate}
        />
        <Route
          exact
          props={childProps}
          path={competitiveReportUrl()}
          component={CompetitiveReport}
          redirect={`${home()}?${homePageParamString.toString()}`}
        />
        <PaidRoute
          exact
          props={childProps}
          path={competitiveReportsUrl()}
          component={CompetitiveReports}
        />
        <PaidRoute
          exact
          props={childProps}
          path={brandSafetyReportUrl()}
          component={BrandSafetyReport}
        />
        <PaidRoute
          exact
          props={childProps}
          path={brandSafetyReportsUrl()}
          component={BrandSafetyReports}
        />
        <PaidRoute
          exact
          props={childProps}
          path={creatorListeningUrl()}
          component={CreatorListeningMonitors}
        />
        <PaidRoute
          exact
          props={childProps}
          path={keyPhraseMonitorUrl()}
          component={KeyPhraseMonitor}
        />
        <PaidRoute
          exact
          path={buildBrandRequestUrl()}
          component={BrandRequest}
        />
        <Route exact path={searchUrl()} component={Search} />
        <PaidRoute exact path={listsUrl()} component={Lists} />,
        <PermissionedRoute
          exact
          path={featuredUrl()}
          component={Featured}
          shouldHaveRule={{ featuredCategoriesEnabled: true, featuredInfluencersEnabled: true }}
        />
        <AuthenticatedRoute
          props={childProps}
          exact
          path={accountSettingsUrl()}
          component={AccountSettings}
        />
        <Route exact path={home()} component={Home} />
        <Route exact path="*" component={NotFound404} />
      </Switch>
    </div>
  </LoggedInLayout>
);

const Router = ({ childProps }) => (
  <main>
    <Switch>
      {urlList.map(d => (
        <Route
          exact
          props={childProps}
          path={d.url}
          component={() => <SemLandingPage titleHtml={d.titleHtml} titleText={d.title} />}
        />
      ))}
      <Route props={childProps} path={privacyPolicy()} component={PrivacyPolicy} />
      <Route props={childProps} path={termsOfUse()} component={TermsOfUse} />
      <UnauthenticatedRoute props={childProps} path={login()} component={LogIn} />
      <Route props={childProps} path={register()} component={Register} />
      <UnauthenticatedRoute props={childProps} path={changePassword()} component={ChangePassword} />
      <UnauthenticatedRoute props={childProps} path={forgotPassword()} component={ForgotPassword} />
      <UnauthenticatedRoute props={childProps} path={setupPassword()} component={SetupPassword} />
      <Route props={childProps} path={pricing()} component={Pricing} />
      <Route props={childProps} path={verifyEmailUrl()} component={VerifyEmail} />
      <PaidRoute
        path="/influencer/:influencerId"
        render={
          routeProps => (childProps.isAuthenticated ?
            <BaseLayout>
              <InfluencerProfile influencerId={routeProps.match.params.influencerId} />
            </BaseLayout>
            :
            <Redirect to={login()} />)}
      />
      <Route
        path="/list/:listId"
        render={routeProps => (
          <BaseLayout>
            <ListDetails listId={routeProps.match.params.listId} />
            <FooterSpace />
          </BaseLayout>
        )}
      />
      <Route props={childProps} path={home()} component={wrappedRoutes(childProps)} />
      <Route exact path={nonExisting()} component={NotFound404} />
    </Switch>
  </main>
);

Router.propTypes = {
  childProps: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Router;
