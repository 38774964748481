import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import styles from './styles';

const PageTitle = ({
  title,
  classes,
  subtitle,
  className,
  icon,
  titleId,
}) => (
  <div className={classes.root}>
    <Typography
      id={titleId}
      variant="h2"
      className={cx(className, { [classes.hasSubtitle]: !isEmpty(subtitle) }, classes.title)}
    >
      {!isEmpty(icon) && icon} {title}
    </Typography>
    {!isEmpty(subtitle) && <Typography variant="body1" className={classes.subtitle}>{subtitle}</Typography>}
  </div>
);

PageTitle.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  titleId: PropTypes.string,
  icon: PropTypes.element,
};

PageTitle.defaultProps = {
  subtitle: null,
  className: '',
  icon: null,
  titleId: null,
};

export default withStyles(styles)(PageTitle);
