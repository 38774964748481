import React from 'react';
import PropTypes from 'prop-types';
import withPermission from 'components/common/withPermission';
import AudienceGenderFilter from './AudienceGenderFilter';
import AudienceTopCountriesFilter from './AudienceTopCountriesFilter';
import GenderFilter from './GenderFilter';
import LocationFilter from './LocationFilter';
import PlatformFilter from './PlatformFilter';
import CategoryFilter from './CategoryFilter';
import KeywordFilter from './KeywordFilter';
import LabelFilter from './LabelFilter';
import FollowerFilters from './FollowerFilters';
import UsernameFilter from './UsernameFilter';
import CaptionFilter from './CaptionFilter';

const SearchFilters = ({
  gender,
  location,
  platforms,
  followerCount,
  category,
  keyword,
  label,
  caption,
  exactUsername,
  audienceGender,
  audienceTopCountries,
}) => (
  <React.Fragment>
    {category && <CategoryFilter />}
    {keyword && <KeywordFilter />}
    {label && <LabelFilter />}
    {caption && <CaptionFilter />}
    {exactUsername && <UsernameFilter />}
    {gender && <GenderFilter />}
    {location && <LocationFilter />}
    {audienceGender && <AudienceGenderFilter />}
    {audienceTopCountries && <AudienceTopCountriesFilter />}
    {followerCount && <FollowerFilters />}
    {platforms && <PlatformFilter />}
  </React.Fragment>
);

SearchFilters.propTypes = {
  gender: PropTypes.bool,
  location: PropTypes.bool,
  platforms: PropTypes.bool,
  followerCount: PropTypes.bool,
  category: PropTypes.bool,
  keyword: PropTypes.bool,
  label: PropTypes.bool,
  caption: PropTypes.bool,
  exactUsername: PropTypes.bool,
  audienceGender: PropTypes.bool,
  audienceTopCountries: PropTypes.bool,
};

SearchFilters.defaultProps = {
  gender: false,
  location: false,
  platforms: false,
  followerCount: false,
  category: false,
  keyword: false,
  label: false,
  caption: false,
  exactUsername: false,
  audienceGender: true,
  audienceTopCountries: true,
};

export default withPermission('search_filters', null, ({ filters }) => filters)(SearchFilters);
