import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { withStyles } from '@material-ui/styles';

export const getOptions = options => ({
  responsive: true,
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  elements: {
    point: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
  tooltips: {
    displayColors: false,
    mode: 'index',
    enabled: true,
    intersect: false,
    callbacks: {
      label: (tooltip) => {
        const { datasetIndex } = tooltip;
        // Only show for the main dataset. The other dataset is just for visual effect.
        if (datasetIndex !== 0) {
          return '';
        }
        return [`Post count: ${tooltip.yLabel}`];
      },
      labelColor: (tooltipItem, chart) => ({
        borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
        backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
      }),
    },
  },
  title: {
    display: true,
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    yAxes: [{
      stacked: true,
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
      display: true,
      ticks: {
        beginAtZero: false,
      },
    }],
    xAxes: [{
      stacked: true,
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
      display: true,
    }],
  },
  ...options,
});

class BarBasic extends React.Component {
  render() {
    const {
      options, data, other, theme,
    } = this.props;
    const renderingData = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 600);
      gradient.addColorStop(0, theme.palette.barChart.gradient.blue);
      gradient.addColorStop(1, theme.palette.barChart.gradient.green);
      data.datasets[0].backgroundColor = gradient;
      return data;
    };
    return (
      <Bar
        redraw
        data={renderingData}
        options={getOptions(options)}
        {...other}
      />
    );
  }
}


BarBasic.propTypes = {
  options: PropTypes.shape({}),
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
};

BarBasic.defaultProps = {
  options: {},
};

export default withStyles({}, { withTheme: true })(BarBasic);
