export default () => ({
  actionButtons: {
    marginLeft: 'auto',
    marginTop: '1rem',
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  createMonitorButton: {
    textTransform: 'none',
  },
  createMonitorIcon: {
    marginRight: 5,
  },
});
