import numeral from 'numeral';
import moment from 'moment';
import { isEqual, round, startCase, toLower } from 'lodash';

export const totalPages = (total, pageSize) => Math.ceil(total / pageSize);

export const scrollToTop = () => window.scrollTo(0, 0);

export const isPositiveNumber = change => Math.sign(change) === 1;

export const formatNumber = (value, formatter, defaultValue = null) => {
  if (!value) {
    return defaultValue;
  }
  if (value < 1000000 && formatter === '0a') {
    return numeral(value).format('0,0');
  }
  return numeral(value).format(formatter);
};

export const formatIndicator = (change, defaultDisplay = 'N/A') => (
  `${isPositiveNumber(change) ? '▲' : '▼'} ${formatNumber(change / 100, '0.00%', defaultDisplay)}`
);

export const isEnterKeyPress = event => event.key === 'Enter';

export const displayNumber = (number) => {
  if (!number) {
    return 0;
  }
  const precision = 2 - Math.floor(Math.min(Math.log10(number), 2));
  return round(number, precision).toLocaleString();
};

export const normalizeNumber = (val, max, min) => {
  if (max === min) {
    return 1;
  }
  return (val - min) / (max - min);
};

export const openInNewTab = url => window.open(url, '_blank');

export const getRandomArbitrary = (min, max) => {
  const CeilMin = Math.ceil(min);
  const CeilMax = Math.floor(max);
  return Math.floor(Math.random() * ((CeilMax - CeilMin) + 1)) + CeilMin;
};

export const scrollIntoView = (querySelector, block = 'start') => {
  const anchor = document.querySelector(querySelector);
  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block });
  }
};

export const urlParamStringToObject = (paramStr) => {
  const params = new URLSearchParams(paramStr);
  return Object.fromEntries(params);
};

export const titleCase = str => startCase(toLower(str));

export const getObjectsDiff = (obj1, obj2) => (
  Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      result.splice(result.indexOf(key), 1);
    }
    return result;
  }, Object.keys(obj2))
);

export const getObjectsDiffByKeys = (obj1, obj2, keys = []) => (
  getObjectsDiff(obj1, obj2).reduce((result, key) => {
    if (keys.includes(key)) {
      result.push(key);
    }
    return result;
  }, [])
);

export const isEqualByKeys = (obj1, obj2, keys = []) => getObjectsDiffByKeys(obj1, obj2, keys).length === 0;

export const isSearchParamsEqual = (params1, params2) =>
  isEqualByKeys(
    params1, params2,
    ['igFollowerRange', 'piFollowerRange', 'ttFollowerRange', 'ytFollowerRange',
      'gender', 'genders', 'categories', 'keywords', 'labels', 'captions', 'platforms', 'locations',
      'sort', 'limit', 'page', 'username', 'usernameExact', 'audienceGender', 'countries'],
  );

export const strartOfDayISO = date => date.startOf('day').toISOString();

export const getDaysFromDate = fromDate => moment().diff(moment.parseZone(fromDate), 'days');

export const getDateFromDays = days => strartOfDayISO(moment().subtract(days, 'days'));
