import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { RankByField } from 'components/app/BrandSafetyReport/utils';
import Button from 'components/base/Button';
import styles from './styles';

const RankButton = withStyles(styles)(({
  classes,
  label,
  active,
  onClick,
}) => (
  <Button
    variant="text"
    className={cx(classes.rankBtn, active && classes.rankBtnActive)}
    onClick={onClick}
  >
    {label}
  </Button>
));

RankButton.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const RankBy = ({ classes, activeRank, onRankChange }) => (
  <div className={classes.root}>
    <div>Rank by:</div>
    <RankButton
      label="Date"
      active={activeRank === RankByField.Date}
      onClick={() => onRankChange(RankByField.Date)}
    />
    <RankButton
      label="Interactions"
      active={activeRank === RankByField.Interactions}
      onClick={() => onRankChange(RankByField.Interactions)}
    />
    <RankButton
      label="Engagement Rate"
      active={activeRank === RankByField.EngagementRate}
      onClick={() => onRankChange(RankByField.EngagementRate)}
    />
    <RankButton
      label="Sponsored Engagement Rate"
      active={activeRank === RankByField.SponsoredEngagementRate}
      onClick={() => onRankChange(RankByField.SponsoredEngagementRate)}
    />
    <RankButton
      label="Follower Count"
      active={activeRank === RankByField.FollowerCount}
      onClick={() => onRankChange(RankByField.FollowerCount)}
    />
  </div>
);

RankBy.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onRankChange: PropTypes.func.isRequired,
  activeRank: PropTypes.oneOfType(RankByField).isRequired,
};

export default withStyles(styles)(RankBy);
