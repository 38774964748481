import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { VERTICALS_NAMES } from 'constants/config';
import styles from './styles';


class VerticalSelectField extends React.Component {
  getOptions = () => {
    const { classes } = this.props;
    return Object.entries(VERTICALS_NAMES).map(name => (
      <option
        key={name[0]}
        value={name[0]}
        className={classes.vertical__option}
      >
        {name[1]}
      </option>
    ));
  };

  render() {
    const {
      classes, input, meta: { touched, error }, required, ...custom
    } = this.props;
    const hasError = !!(touched && error);
    const errorElem = <span>{hasError ? error : ''}</span>;
    return (
      <FormControl className={classes.vertical__select}>
        <InputLabel htmlFor="verticals" required={required}>Vertical</InputLabel>
        <Select
          name="Vertical"
          inputProps={{
            name: 'verticals',
            id: 'verticals',
          }}
          error={hasError}
          helperText={errorElem}
          required={required}
          {...input}
          {...custom}
        >
          {this.getOptions()}
        </Select>
      </FormControl>
    );
  }
}

VerticalSelectField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  required: PropTypes.bool,
};

VerticalSelectField.defaultProps = {
  meta: null,
  required: false,
};

export default withStyles(styles)(VerticalSelectField);
