import React from 'react';
import ItemsCarousel from 'react-items-carousel';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import styles from './styles';

class Carousel extends React.Component {
  state = {
    activeIndex: 0,
  };

  changeActiveItem = (activeIndex) => {
    this.setState({
      activeIndex,
    });
  };

  render() {
    const {
      children,
      numberOfCards,
    } = this.props;
    const { activeIndex } = this.state;
    return (
      <ItemsCarousel
        numberOfCards={numberOfCards}
        gutter={12}
        showSlither
        firstAndLastGutter
        freeScrolling={false}
        requestToChangeActive={this.changeActiveItem}
        activeItemIndex={activeIndex}
        activePosition="center"
        chevronWidth={24}
        rightChevron={'>'}
        leftChevron={'<'}
        outsideChevron={false}
      >
        {children}
      </ItemsCarousel>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  classes: PropTypes.shape({}).isRequired,
  numberOfCards: PropTypes.number.isRequired,
};

export default withStyles(styles)(Carousel);
