export default theme => ({
  container: {
    position: 'absolute',
    background: 'white',
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '25rem',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.2rem 0 0.2rem 0.4rem',
    alignItems: 'center',
  },
  headerKeywordsContainer: {
    padding: '0px 2.5rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  content: {
    padding: '0 0.4rem',
    alignItems: 'center',
    background: theme.palette.white.dark,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    overflowY: 'auto',
    maxHeight: '50vh',
  },
  closeButton: {
    marginLeft: 'auto',
    position: 'absolute',
    top: '0.4rem',
    right: '0.4rem',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.white.dark,
  },
  footerButton: {
    margin: '0.4rem',
    textDecoration: 'none',
    textTransform: 'none',
  },
});
