import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import NoResultCard from 'components/base/NoResultCard';
import InfluencerCard from 'components/common/InfluencerCard';
import styles from './styles';
import withPermission from '../withPermission';

const DEFAULT_GRID_SPACING = 2;
const MAX_GRID_SIZE = 12;
const DEFAULT_COLUMNS = 3;

const InfluencerGrid = ({
  classes,
  columns,
  influencers,
  permitted,
  permittedViews,
  canViewBrandSafety,
}) => {
  const lgGridSize = MAX_GRID_SIZE / columns;
  const mdGridSize = MAX_GRID_SIZE / (columns - 1);
  return (
    <Grid id="search_results" container className={classes.root} spacing={DEFAULT_GRID_SPACING}>
      {(!influencers || !influencers.length) &&
      <Grid key="no-results" item xs={MAX_GRID_SIZE}>
        <NoResultCard />
      </Grid>
      }
      {influencers.map(influencer => (
        <Grid key={influencer.id} item lg={lgGridSize} md={mdGridSize} className={classes.influencerCard}>
          <InfluencerCard
            influencer={influencer}
            canViewProfile={permitted}
            permittedProfileViews={permittedViews}
            canViewBrandSafety={canViewBrandSafety}
          />
        </Grid>
      ))}
    </Grid>
  );
};


InfluencerGrid.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  columns: PropTypes.number,
  influencers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    profilePhoto: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    categories: PropTypes.shape({}),
    stats: PropTypes.shape({
      totalReach: PropTypes.number,
      avgComments: PropTypes.number,
      avgEngagement: PropTypes.number,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      postId: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      link: PropTypes.string,
    })),
  })),
  permitted: PropTypes.bool,
  permittedViews: PropTypes.shape({}),
  canViewBrandSafety: PropTypes.bool,
};

InfluencerGrid.defaultProps = {
  columns: DEFAULT_COLUMNS,
  influencers: [],
  permitted: false,
  permittedViews: {},
  canViewBrandSafety: false,
};

const mapMetaToProps = ({ views }) => ({
  permittedViews: views,
});

export default withPermission(
  'influencer_profile',
  null,
  mapMetaToProps,
  true,
)(withStyles(styles)(InfluencerGrid));
