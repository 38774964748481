import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Dimensions from 'react-dimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faSnapchatGhost,
  faPinterest,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import numeral from 'numeral';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import ForceIcon from 'components/base/charts/ForceIcon';

const PLATFORM_STYLE_CONFIG = {
  instagram: {
    icon: <FontAwesomeIcon style={{ verticalAlign: 'middle' }} icon={faInstagram} />,
    label: 'Instagram',
    backgroundColor: 'url(#igGradient)',
    color: '#fff',
  },
  facebook: {
    icon: <FontAwesomeIcon style={{ verticalAlign: 'middle' }} icon={faFacebookF} />,
    label: 'Facebook',
    backgroundColor: '#4267B2',
    color: '#fff',
  },
  twitter: {
    icon: <FontAwesomeIcon style={{ verticalAlign: 'middle' }} icon={faTwitter} />,
    label: 'Twitter',
    backgroundColor: '#00acee',
    color: '#fff',
  },
  youtube: {
    icon: <FontAwesomeIcon icon={faYoutube} />,
    label: 'Youtube',
    backgroundColor: '#ff0000',
    color: '#fff',
  },
  snapchat: {
    icon: <FontAwesomeIcon icon={faSnapchatGhost} />,
    label: 'Youtube',
    backgroundColor: '#fffc00',
    color: '#fff',
  },
  blog: {
    icon: <FontAwesomeIcon icon={faGlobe} />,
    label: 'Blog',
    backgroundColor: '#eca026',
    color: '#fff',
  },
  tiktok: {
    icon: <FontAwesomeIcon icon={faTiktok} />,
    label: 'TikTok',
    backgroundColor: '#69c9d0',
    color: '#fff',
  },
  pinterest: {
    icon: <FontAwesomeIcon icon={faPinterest} />,
    label: 'Pinterest',
    backgroundColor: '#e60023',
    color: '#fff',
  },
};

class Platforms extends Component {
  transformBubbleData = platforms => platforms.map((item) => {
    const followerCount = numeral(item.value).format('0a');
    return {
      ...item,
      icon: PLATFORM_STYLE_CONFIG[item.label].icon,
      color: PLATFORM_STYLE_CONFIG[item.label].backgroundColor,
      textColor: PLATFORM_STYLE_CONFIG[item.label].color,
      label: followerCount,
      popoverLabel: `${followerCount} Followers
          ${item.username ? `<span class="popover-username">@${item.username}</span>` : ''}
        `,
    };
  });

  render() {
    const {
      platforms,
      theme,
      containerWidth,
    } = this.props;
    const filteredPlatforms = platforms.filter(item => PLATFORM_STYLE_CONFIG[item.label]);
    return (
      <ForceIcon
        data={{
          nodes: this.transformBubbleData(filteredPlatforms),
          links: [],
        }}
        width={containerWidth}
        height={96}
        nodeColor={theme.palette.blue.main}
        gravityCenter={{ x: 1, y: 50 }}
      />
    );
  }
}

Platforms.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  containerWidth: PropTypes.number.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
};

export default Dimensions()(withStyles(() => ({}), { withTheme: true })(Platforms));
