import React from 'react';
import Dropzone from 'react-dropzone';
import cx from 'classnames';
import { omit } from 'lodash';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Error from 'components/base/forms/Error/index';
import styles from './styles';

const IMAGE_MAX_ALLOWED_SIZE_MB = 10;

export class ImageUpload extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  onDrop = (acceptedFiles) => {
    const { input: { value, onChange } } = this.props;

    const reader = new FileReader();
    reader.onload = () => onChange({
      ...value,
      image: reader.result,
    });

    acceptedFiles.forEach((file) => {
      const fileSizeMb = file && file.size && file.size / 1000 / 1000;
      if (fileSizeMb > IMAGE_MAX_ALLOWED_SIZE_MB) {
        this.setState({
          error: `Image exceeds maximum allowed size of ${IMAGE_MAX_ALLOWED_SIZE_MB}Mb and will be ignored.`,
        });
      } else {
        this.setState({
          error: null,
        }, () => reader.readAsDataURL(file));
      }
    });
  };

  removeImage = () => {
    const { input: { value, onChange } } = this.props;
    onChange(omit(value, 'image'));
  };

  render() {
    const { classes, input: { value = {} }, meta: { error } } = this.props;
    const { image, defaultImage } = value;
    return (
      <React.Fragment>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.dropzone}>
              <input {...getInputProps()} />
              <div className={classes.dropzone__content}>
                <i className={cx(classes.dropzone__image, 'material-icons')}>
                  cloud_upload
                </i>
                <Typography className={classes.dropzone__text}>
                  Drag image here to upload
                </Typography>
                <Typography className={classes.dropzone__text}>
                  or click to upload from computer
                </Typography>
              </div>
            </div>
          )}
        </Dropzone>
        <div className={classes.image__selection} >
          <div className={classes.image__container}>
            {image || defaultImage ?
              <img
                src={image || defaultImage}
                className={classes.image}
                alt="Uploaded List"
              /> :
              <div className={classes.no__image}>
                <Typography className={classes.no__image__text}>
                  Preview
                </Typography>
              </div>
            }
          </div>
          {image && (
            <div className={classes.image__buttons} >
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={this.removeImage}
                disabled={!image}
              >
                Delete Image
              </Button>
            </div>
          )}
          <Error error={this.state.error || error} />
        </div>
      </React.Fragment>
    );
  }
}

export const ImageUploadStyled = withStyles(styles)(ImageUpload);
