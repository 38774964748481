import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const PostStat = ({ Icon, statValue, classes }) => (
  <span className={classes.post__stat}><Icon className={classes.post__stat__icon} /> {statValue}</span>
);

PostStat.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  Icon: PropTypes.element.isRequired,
  statValue: PropTypes.string.isRequired,
};

export default withStyles(styles)(PostStat);
