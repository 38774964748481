import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity } from 'lodash';
import { withStyles } from '@material-ui/styles';
import { Container, IconButton } from '@material-ui/core';
import Button from 'components/base/Button';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import withPermission from 'components/common/withPermission';
import cx from 'classnames';
import {
  login,
} from 'constants/routing';
import MenuIcon from '@material-ui/icons/Menu';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import Navigation from './Navigation';
import styles from './styles';
import RightOptions from '../RightOptions';
import NavItems from './NavItems';
import SearchContainer from './SearchContainer';

const SearchNav = ({
  classes,
  isAuthenticated,
  brandSafety,
  search,
  lists,
  allowedReports,
  open,
  closeModal,
  openModal,
}) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  return (
    <div className={classes.root}>
      <BetaSignupModal
        open={open}
        closeModal={closeModal}
        onSignupComplete={closeModal}
      />
      <Container maxWidth={false} className={cx(classes.searchButtonContainer, classes.mobileMainNav)}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => setDisplayMenu(!displayMenu)}
        >
          <MenuIcon />
        </IconButton>
        <div className={cx(classes.mobileNav, { [classes.mobileNavOpen]: displayMenu })} >
          {isAuthenticated && <RightOptions />}
          {!isAuthenticated && <Button color="primary" href={login()}>Login</Button>}
          {!isAuthenticated && <Button color="primary" variant="contained" onClick={openModal}>Signup</Button>}
        </div>
        <Navigation open={displayMenu}>
          <NavItems
            brandSafety={brandSafety}
            search={search}
            lists={lists}
            allowedReports={allowedReports}
          />
          <SearchContainer allowedReports brandSearch />
        </Navigation>
      </Container>
      <Container maxWidth={false} className={cx(classes.searchButtonContainer, classes.desktopMainNav)}>
        <Navigation open={displayMenu}>
          <NavItems
            brandSafety={brandSafety}
            search={search}
            lists={lists}
            allowedReports={allowedReports}
          />
        </Navigation>
        <SearchContainer allowedReports brandSearch />
      </Container>
    </div>
  );
};

SearchNav.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  allowedReports: PropTypes.number,
  isAuthenticated: PropTypes.bool.isRequired,
  brandSafety: PropTypes.bool,
  search: PropTypes.bool,
  lists: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

SearchNav.defaultProps = {
  brandSafety: false,
  search: false,
  lists: false,
  allowedReports: 0,
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default withAll(
  connect(mapStateToProps, null),
  withPermission('navigation', null, identity, true),
  withStyles(styles),
  withModal,
)(SearchNav);
