import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'components/base/modals/BaseModal';
import Button from 'components/base/Button';
import { DATE_FILTER_MIN_DATE, DATE_FILTER_MAX_DATE } from 'constants/options';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './styles';


const CustomDateModal = ({
  classes,
  closeModal,
  open,
  handleSelect,
  initialFromValue,
  initialToValue,
  theme,
}) => {
  const [fromDate, setFromDate] = React.useState(initialFromValue);
  const [toDate, setToDate] = React.useState(initialToValue);

  const selectionRange = {
    startDate: fromDate.toDate(),
    endDate: toDate.toDate(),
    key: 'selection',
    color: theme.palette.blue.main,
  };

  React.useEffect(() => {
    setFromDate(initialFromValue);
    setToDate(initialToValue);
  }, [initialFromValue, initialToValue]);

  return (
    <BaseModal
      onClose={closeModal}
      open={open}
      title="Custom date"
      height="auto"
      width="auto"
    >
      <div className={classes.container}>
        <DateRange
          onChange={(value) => {
            setFromDate(moment(value.selection.startDate));
            setToDate(moment(value.selection.endDate));
          }}
          ranges={[selectionRange]}
          minDate={DATE_FILTER_MIN_DATE.toDate()}
          maxDate={DATE_FILTER_MAX_DATE.toDate()}
        />
        <div className={classes.buttons}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={() => {
                closeModal();
                handleSelect(fromDate, toDate);
                }
            }
          >
            Select
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </BaseModal>

  );
};

CustomDateModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  initialFromValue: PropTypes.instanceOf(moment),
  initialToValue: PropTypes.instanceOf(moment),
};

CustomDateModal.defaultProps = {
  initialFromValue: DATE_FILTER_MIN_DATE,
  initialToValue: DATE_FILTER_MAX_DATE,
};

export default withStyles(styles, { withTheme: true })(CustomDateModal);
