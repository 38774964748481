import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@material-ui/styles';
import GaugeChart from 'react-gauge-chart';
import styles from './styles';

const getColors = theme => [theme.palette.red.main, theme.palette.yellow.main, theme.palette.green.main];

const GaugeSimple = ({
  classes,
  id,
  percent,
  arcPadding,
  arcWidth,
  chartContainerStyle,
  cornerRadius,
  hideText,
  label,
  marginInPercent,
}) => {
  const theme = useTheme();
  return (
    <div className={classes.root}>
      {label && <div className={classes.label}>{label}</div>}
      <GaugeChart
        className={classes.chartContainer}
        id={id}
        arcPadding={arcPadding}
        arcWidth={arcWidth}
        colors={getColors(theme)}
        cornerRadius={cornerRadius}
        hideText={hideText}
        percent={percent}
        needleColor={theme.palette.black.dark}
        needleBaseColor={theme.palette.black.dark}
        marginInPercent={marginInPercent}
        style={chartContainerStyle}
      />
    </div>
  );
};

GaugeSimple.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  arcPadding: PropTypes.number,
  arcWidth: PropTypes.number,
  chartContainerStyle: PropTypes.shape({}),
  cornerRadius: PropTypes.number,
  hideText: PropTypes.bool,
  label: PropTypes.string,
  marginInPercent: PropTypes.number,
};

GaugeSimple.defaultProps = {
  arcPadding: 0.01,
  arcWidth: 0.3,
  chartContainerStyle: {
    maxWidth: Math.min(Math.round(window.innerWidth / 3.5), 600),
  },
  cornerRadius: 0,
  hideText: true,
  label: null,
  marginInPercent: 0.08,
};

export default withStyles(styles)(GaugeSimple);
