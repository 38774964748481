import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { MenuItem } from '@material-ui/core';
import { first } from 'lodash';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { SEARCH_PAGE_SIZE_OPTIONS } from 'constants/options';
import ButtonMenu from 'components/base/ButtonMenu';
import withAll from 'components/base/withAll';
import withPermission from 'components/common/withPermission';
import withInfluencerSearch from 'components/common/withInfluencerSearch';

const styles = () => ({});

const PageLimit = ({
  searchParams,
  limit,
  getInfluencers,
}) => {
  // If only 1 option do not display
  if (limit <= first(SEARCH_PAGE_SIZE_OPTIONS)) {
    return null;
  }
  return (
    <ButtonMenu
      buttonProps={{
        variant: 'contained',
        color: 'secondary',
      }}
      buttonChildren={(
        <React.Fragment>{searchParams.limit} per page <KeyboardArrowDownIcon /></React.Fragment>
      )}
      menuChildren={SEARCH_PAGE_SIZE_OPTIONS
        .filter(l => l <= limit || limit === -1)
        .map(l => (
          <MenuItem
            key={`limit-${l}`}
            onClick={() => getInfluencers({
              ...searchParams,
              limit: l,
              page: 0,
            })}
          >
            {l} per page
          </MenuItem>
        ))}
    />
  );
};

PageLimit.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getInfluencers: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  limit: PropTypes.number.isRequired,
};

const mapStateToProps = ({ searchDisplay, searchParams }) => ({
  searchDisplay,
  searchParams,
});

export default withAll(
  connect(
    mapStateToProps,
    null,
  ),
  withPermission(
    'search_results',
    null,
    ({ limit }) => ({ limit }),
  ),
  withInfluencerSearch,
  withStyles(styles),
)(PageLimit);
