import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Loader from 'components/base/Loader';
import TabPanel from 'components/base/tabs/TabPanel';
import withAll from 'components/base/withAll';
import withPermission from 'components/common/withPermission';
import { getBrandSafetyReport as getBrandSafetyReportAction } from 'redux/brandSafetyReports/actions';
import { mpPageView } from 'utils/copenhagen';
import { TABS } from './constants';
import styles from './styles';
import BrandSafety from './BrandSafety';
import Header from './Header';
import Scorecard from './Scorecard';
import UnusualPostEngagement from './UnusualPostEngagement';

class BrandSafetyReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: TABS.SCORECARD,
    };
  }

  async componentDidMount() {
    const {
      getBrandSafetyReport,
      match: { params: { reportId } },
    } = this.props;
    await getBrandSafetyReport(reportId);
    mpPageView({ name: 'brandSafetyReport', reportId });
  }

  updateTab = (e, tab) => {
    this.setState({ tab });
  }

  render() {
    if (!this.props.permitted) {
      return null;
    }

    const { tab } = this.state;
    const {
      classes,
      canViewUnusualPostIndex,
      report,
      isLoading,
      loaded,
    } = this.props;

    return (
      <Loader loading={isLoading} loaded={loaded}>
        <div className={classes.root}>
          <Header
            report={report}
            tab={tab}
            updateTab={this.updateTab}
            canViewUnusualPostIndex={canViewUnusualPostIndex}
          />
          <Container maxWidth={false}>
            <TabPanel value={tab} index={TABS.SCORECARD}>
              <Scorecard
                report={report}
                setTab={newTab => this.setState({ tab: newTab })}
                canViewUnusualPostIndex={canViewUnusualPostIndex}
              />
            </TabPanel>
            <TabPanel value={tab} index={TABS.BRAND_SAFETY}>
              <BrandSafety report={report} />
            </TabPanel>
            {
              canViewUnusualPostIndex && (
                <TabPanel value={tab} index={TABS.UNUSUAL_POST_ENGAGEMENT}>
                  <UnusualPostEngagement report={report} />
                </TabPanel>
              )
            }
          </Container>
        </div>
      </Loader>
    );
  }
}

BrandSafetyReport.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  permitted: PropTypes.bool.isRequired,
  canViewUnusualPostIndex: PropTypes.bool.isRequired,
  report: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  getBrandSafetyReport: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = ({ brandSafetyReport }) => ({
  report: brandSafetyReport.data,
  isLoading: brandSafetyReport.loading,
  loaded: brandSafetyReport.loaded,
});

export default withAll(
  withPermission('view_brand_safety_reports', null, identity, true),
  connect(mapStateToProps, { getBrandSafetyReport: getBrandSafetyReportAction }),
  withStyles(styles),
)(BrandSafetyReport);
