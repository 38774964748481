import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { ALL_LABEL } from 'components/common/CompetitiveReport/constants';
import { get, orderBy, sum } from 'lodash';
import { findBrandObjFromName } from 'components/common/CompetitiveReport/utils';
import Doughnut from 'components/base/charts/Doughnut/Doughnut';
import H6 from 'components/base/titles/CardHeading/H6';
import PieCountBackground from 'components/common/CompetitiveReport/PostActivity/PieCountBackground';
import { formatNumber } from 'utils/helpers';
import Legend from 'components/base/charts/Legend';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { transformLegendLabels } from 'redux/reports/reducers';

const styles = () => ({
  root: {},
  legend: {
    marginTop: '0.4rem',
  },
});

const transformData = (competitiveReportData, brands) => {
  const { creators } = competitiveReportData;
  const filteredDataSet = orderBy(creators.dataSets.filter(d => d.label !== ALL_LABEL), 'label');
  return {
    datasets: [{
      data: filteredDataSet.map(d => sum(d.values)),
      backgroundColor: filteredDataSet.map(d => findBrandObjFromName(d.label, brands).legendColor),
    }],
    labels: filteredDataSet.map(d => d.label),
  };
};

const getSumValue = creators => sum(creators.dataSets.find(d => d.label === ALL_LABEL).values);


const TotalPosts = ({ data, classes, totalPosts }) => (
  <div className={classes.root}>
    <H6 align="center">
      <LabelWithInfoIcon
        labelText="Share of Unique Creators (Creators Utilized)"
        tooltip="The total number of unique creators mentioning each brand."
      />
    </H6>
    <Doughnut
      data={data}
      height={130}
      options={{
        legend: {
          display: false,
        },
      }}
      backdropElement={(
        <PieCountBackground value={formatNumber(totalPosts, '0a', '-')} />
      )}
    />
    <Legend
      className={classes.legend}
      items={data.labels.map((d, k) => ({
        title: d,
        color: data.datasets[0].backgroundColor[k],
        tooltip: {
          title: `Creators ${formatNumber(data.datasets[0].data[k], '0a', '-')}`,
        },
      }))}
    />
  </div>
);

TotalPosts.propTypes = {
  data: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  totalPosts: PropTypes.number.isRequired,
};

const mapStateToProps = ({ competitiveReportPostActivity }) => ({
  data: transformData(
    competitiveReportPostActivity.data,
    transformLegendLabels(get(competitiveReportPostActivity, 'data.creators.dataSets', [])),
  ),
  totalPosts: getSumValue(get(competitiveReportPostActivity, 'data.creators', {})),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(TotalPosts);
