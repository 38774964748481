import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/base/Button';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { withRouter } from 'react-router';
import { competitiveReportsUrl } from 'constants/routing';
import {
  deleteCompetitiveReport as deleteCompetitiveReportAction,
} from 'redux/reports/actions';
import { toast } from 'react-toastify';

const styles = theme => ({
  root: {},
  delete: {
    color: theme.palette.red.main,
  },
});

const SuccessMessage = () => (
  <React.Fragment>
    Report has been deleted.
  </React.Fragment>
);

const DeleteDialog = ({
  report,
  classes,
  deleteCompetitiveReport,
  closeModal,
  open,
  history,
}) => {
  const deleteReport = async () => {
    await deleteCompetitiveReport(report.id, report);
    closeModal();
    toast.success(<SuccessMessage />);
    history.push(competitiveReportsUrl());
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">Delete?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you wish to delete report <b>{report.title}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancel
        </Button>
        <Button onClick={deleteReport} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  report: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  deleteCompetitiveReport: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withAll(
  connect(null, {
    deleteCompetitiveReport: deleteCompetitiveReportAction,
  }),
  withStyles(styles),
  withRouter,
)(DeleteDialog);
