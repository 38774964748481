import { isString, startsWith } from 'lodash';
import multimethod from '../lib/polymorphism';

// eslint-disable-next-line import/prefer-default-export
export const newReducer = (initialState) => {
  // eslint-disable-next-line no-unused-vars
  const reducer = multimethod((state = initialState, action) => action.type);
  // eslint-disable-next-line no-unused-vars
  reducer.method.default = (state = initialState, action) => state;
  return reducer;
};

const getKeywordType = keyword => (startsWith(keyword, '@') ? 'mention' : 'hashtag');

export const convertKeyword = (keyword) => {
  if (isString(keyword.value)) {
    return { type: getKeywordType(keyword.value), text: keyword.value };
  }
  return { type: keyword.value.type, text: keyword.value.text };
};
