import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const StatsItem = ({
  classes,
  Icon,
  label,
  value,
}) => (
  <div className={classes.root}>
    <div className={classes.stat__icon_column}><Icon className={classes.stat__icon} /></div>
    <div className={classes.stat__stat_column}>
      <Typography variant="h6" className={classes.stat__value}>{value}</Typography>
      <Typography className={classes.stat__label}>{label}</Typography>
    </div>
  </div>
);

StatsItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  Icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(StatsItem);
