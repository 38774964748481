import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  lowRisk: {
    color: theme.palette.green.main,
    fontWeight: 'bold',
  },
  mediumRisk: {
    color: theme.palette.yellow.main,
    fontWeight: 'bold',
  },
  highRisk: {
    color: theme.palette.red.main,
    fontWeight: 'bold',
  },
  unknownRisk: {
    color: theme.palette.black.dark,
    fontWeight: 'bold',
  },
});

const classNames = {
  LOW_RISK: 'lowRisk',
  MEDIUM_RISK: 'mediumRisk',
  HIGH_RISK: 'highRisk',
};

const labels = {
  LOW_RISK: 'LOW',
  MEDIUM_RISK: 'MODERATE',
  HIGH_RISK: 'REVIEW RECOMMENDED',
  UNDEFINED: 'NO DATA',
};

const BrandSafetyIndex = ({ classes, report, className }) => {
  const safetyIndex = report.brandSafetyIndex;
  if (!safetyIndex) {
    return <div className={classes.unknownRisk}>{labels.UNDEFINED}</div>;
  }
  const indexClassName = classes[classNames[safetyIndex]];
  return <div className={cx(className, indexClassName)}>{labels[safetyIndex]}</div>;
};

BrandSafetyIndex.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({
    brandSafetyIndex: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

BrandSafetyIndex.defaultProps = {
  className: null,
};

export default withStyles(styles)(BrandSafetyIndex);
