import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import withAll from 'components/base/withAll';
import TabPanel from 'components/base/tabs/TabPanel';
import { mpPageView } from 'utils/copenhagen';
import { TABS } from './constants';
import Header from './Header';
import PostActivity from './PostActivity';
import PlatformDistribution from './PlatformDistribution';
import AudienceDemographics from './AudienceDemographics';
import EstimatedInfluencerSpend from './EstimatedInfluencerSpend';
import TopContent from './TopContent';
import TopCreators from './TopCreators';
import SignupBanner from './SignupBanner';
import VerticalBreakdown from './VerticalBreakdown';

const styles = theme => ({
  buttonContainer: {
    textAlign: 'center',
    backgroundColor: theme.palette.border.main,
    boxShadow: 'none',
    paddingTop: '0.4rem',
    borderRadius: '0.4rem',
  },
  button: {},
  brandLogo: {
    marginRight: '1rem',
  },
});

const containerRef = React.createRef();

class BrandDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: TABS.POST_ACTIVITY,
    };
  }

  componentDidMount() {
    const { competitiveReportStat: { id } } = this.props;
    mpPageView({ name: 'report', reportId: id });
  }

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  render() {
    const {
      classes, competitiveReportStat, isAuthenticated, isInternal,
    } = this.props;
    const { tabValue } = this.state;
    return (
      <div className={classes.root}>
        <Header
          data={competitiveReportStat}
          handleChange={this.handleChange}
          tabValue={tabValue}
          containerRef={containerRef}
          isInternal={isInternal}
        />
        <Container maxWidth={false} ref={containerRef}>
          <TabPanel value={tabValue} index={TABS.POST_ACTIVITY}>
            <PostActivity />
          </TabPanel>
          <TabPanel value={tabValue} index={TABS.PLATFORM_DISTRIBUTION}>
            <PlatformDistribution />
          </TabPanel>
          <TabPanel value={tabValue} index={TABS.TOP_CONTENT}>
            <TopContent />
          </TabPanel>
          <TabPanel value={tabValue} index={TABS.VERTICAL_DISTRIBUTION}>
            <VerticalBreakdown />
          </TabPanel>
          <TabPanel value={tabValue} index={TABS.TOP_CREATORS}>
            <TopCreators />
          </TabPanel>
          <TabPanel value={tabValue} index={TABS.AUDIENCE_DEMOGRAPHICS}>
            <AudienceDemographics />
          </TabPanel>
          {isInternal && (
            <TabPanel value={tabValue} index={TABS.ESTIMATED_INFLUENCER_SPEND}>
              <EstimatedInfluencerSpend />
            </TabPanel>
          )}
        </Container>
        {!isAuthenticated && <SignupBanner />}
      </div>
    );
  }
}

BrandDetail.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  competitiveReportStat: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isInternal: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ competitiveReportStat, auth: { isAuthenticated } }) => ({
  competitiveReportStat: competitiveReportStat.data,
  isAuthenticated,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(BrandDetail);
