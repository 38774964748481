import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import PageTitle from 'components/base/titles/PageTitle';
import Loader from 'components/base/Loader';
import { Link } from 'react-router-dom';
import PromoBannerWrapper from 'components/base/sections/PromoBannerWrapper';
import VerticalChart from 'components/common/Verticals/VerticalChart';
import VerticalPosts from 'components/common/Verticals/VerticalPosts';
import VerticalCreators from 'components/common/Verticals/VerticalCreators';
import withPermission from 'components/common/withPermission';
import withAll from 'components/base/withAll';
import ScrollTopButton from 'components/common/ScrollTopButton';
import { createVerticalDetailUrl } from 'constants/routing';
import { VERTICAL_PATHS_NAME_MAP } from 'constants/config';
import { getVerticalSummary } from 'redux/stats/actions';
import { mpPageView } from 'utils/copenhagen';
import { scrollIntoView } from 'utils/helpers';
import styles from './styles';

class Verticals extends PureComponent {
  componentDidMount() {
    this.props.getVerticalSummary();
    mpPageView({ name: 'verticals' });
  }

  handleBarClick = (event, value) => {
    // eslint-disable-next-line
    if (value[0]) scrollIntoView(`#verticalPostsAndCreators${value[0]._index}`, 'start');
  }

  render() {
    const {
      classes, isLoading, verticals, permittedProfileViews, permitted,
    } = this.props;
    return (
      <Loader loading={isLoading}>
        <PromoBannerWrapper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} className={classes.textSection} >
              <PageTitle title="Creator Industry Trends" className={classes.title} />
              <Typography variant="body" className={classes.subTitle} id="verticalsChartDescription">
                Visualize Creator activity of all influencers in our database across industries.
                Scroll down to view top performing influencer content and top influencers across
                a variety of sectors.
              </Typography>
            </Grid>
            <Grid item style={{ flexGrow: '1' }} />
            <Grid item xs={12} sm={12} md={7}>
              <VerticalChart verticals={verticals} onBarClick={this.handleBarClick} />
            </Grid>
          </Grid>
        </PromoBannerWrapper>
        <div className={classes.topDataContainer}>
          {verticals.map((verticalData, index) => (
            <div id={`verticalPostsAndCreators${index}`} className={classes.verticalDataContainer} >
              <PageTitle title={verticalData.vertical} className={classes.title} />
              <Link
                className={classes.verticalLink}
                to={() => createVerticalDetailUrl(VERTICAL_PATHS_NAME_MAP[verticalData.vertical])}
              >
                Explore brands in this industry
              </Link>
              <VerticalPosts posts={verticalData.posts} />
              <VerticalCreators
                creators={verticalData.influencers}
                permittedProfileViews={permittedProfileViews}
                permittedProfile={permitted}
              />
            </div>
          ))}
        </div>
        <ScrollTopButton />
      </Loader>
    );
  }
}

Verticals.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getVerticalSummary: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  verticals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  permitted: PropTypes.bool,
  permittedProfileViews: PropTypes.shape({}),
};

Verticals.defaultProps = {
  permitted: false,
  permittedProfileViews: {},
};

const mapStateToProps = ({ verticals }) => ({
  isLoading: verticals.loading,
  verticals: verticals.data.verticals,
});

const mapMetaToProps = ({ views }) => ({
  permittedProfileViews: views,
});

export default withAll(
  connect(mapStateToProps, { getVerticalSummary }),
  withPermission('influencer_profile', null, mapMetaToProps, true),
  withStyles(styles),
)(Verticals);
