import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import LockIcon from '@material-ui/icons/Lock';
import Tabs from 'components/base/SideBar/Tabs';
import Tab from 'components/base/SideBar/Tab';
import withAll from 'components/base/withAll';
import SideBar from 'components/base/SideBar';
import withPermission from 'components/common/withPermission';
import UserProfile from './UserProfile';
import ChangePassword from './ChangePassword';
import Subscription from './Subscription';
import TabContainer from './TabContainer';
import ACCOUNT_TAB from './constants';
import styles from './styles';

const tabProps = tabIndex => ({
  id: `menu-tab-${tabIndex}`,
  'aria-controls': `vertical-tabpanel-${tabIndex}`,
});

const creatTabLabel = (Icon, text) => <span><Icon /> {text}</span>;

class AccountSettings extends Component {
  constructor(props) {
    super(props);
    const { tab } = this.props.match.params;
    this.state = {
      selectedTab: parseInt(tab, 10),
    };
  }

  handleTabChange = (e, value) => this.setState({ selectedTab: value });

  render() {
    const {
      classes,
      subscriptions,
    } = this.props;
    const { selectedTab } = this.state;
    return (
      <div className={classes.root}>
        <SideBar>
          <Typography className={classes.menu__title} variant="h6">Account Settings</Typography>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={this.handleTabChange}
          >
            <Tab label={creatTabLabel(AccountCircleIcon, 'User profile')} {...tabProps(ACCOUNT_TAB.USER_PROFILE)} />
            <Tab label={creatTabLabel(LockIcon, 'Change password')} {...tabProps(ACCOUNT_TAB.CHANGE_PASSWORD)} />
            { subscriptions &&
              <Tab
                label={creatTabLabel(OfflineBoltIcon, 'Subscription')}
                {...tabProps(ACCOUNT_TAB.SUBSCRIPTION)}
              /> }
          </Tabs>
        </SideBar>
        <TabContainer className={classes.tabs__content__container}>
          <React.Fragment>
            {selectedTab === ACCOUNT_TAB.USER_PROFILE && <UserProfile />}
            {selectedTab === ACCOUNT_TAB.CHANGE_PASSWORD && <ChangePassword />}
            {selectedTab === ACCOUNT_TAB.SUBSCRIPTION && subscriptions && <Subscription />}
          </React.Fragment>
        </TabContainer>
      </div>
    );
  }
}

AccountSettings.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withAll(
  withPermission('account_settings', null, identity),
  withStyles(styles),
)(AccountSettings);
