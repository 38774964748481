import React from 'react';
import { withStyles } from '@material-ui/styles';
import { MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { every, identity, isEmpty, filter, map, size } from 'lodash';
import styles from './styles';
import withSelectedInfluencers from '../withSelectedInfluencers';
import ButtonMenu from '../../base/ButtonMenu';
import { addSelectedInfluencers, deselectAllInfluencers } from '../../../redux/searchDisplay/actions';

class SelectedInfluencers extends React.Component {
  handleSelectAll = () => {
    const { influencers } = this.props;

    if (isEmpty(influencers)) {
      return;
    }
    this.props.addSelectedInfluencers(influencers);
  };

  handleDeselectAll = () => {
    this.props.deselectAllInfluencers();
  };

  selectAllText = () => {
    const { influencers } = this.props;
    return `Select All (${size(influencers)})`;
  };

  selectAllOption = () => (
    <MenuItem key="select-all" onClick={this.handleSelectAll} closeOnClick>
      {this.selectAllText()}
    </MenuItem>
  );

  allSelected = () => {
    const { influencers, selectedInfluencers } = this.props;
    const selectedInfluencerIds = map(selectedInfluencers, 'id') || [];
    return every(influencers, influencer => selectedInfluencerIds.includes(influencer.id));
  };

  deselectAllOption = () => (
    <MenuItem key="deselect-all" onClick={this.handleDeselectAll} closeOnClick>
      Deselect All ({size(this.props.selectedInfluencers)})
    </MenuItem>
  );

  noneSeleccted = () => size(this.props.selectedInfluencers) === 0;

  renderSelected = () => {
    const { selectedInfluencers } = this.props;
    return selectedInfluencers.map(influencer => (
      <MenuItem key={influencer.id}>
        {influencer.name}
      </MenuItem>
    ));
  };

  renderMenuChildren = () => filter([
    !this.allSelected() && this.selectAllOption(),
    !this.noneSeleccted() && this.deselectAllOption(),
    ...this.renderSelected(),
  ], identity);

  render() {
    const {
      buttonProps,
      influencers,
      selectedInfluencers,
    } = this.props;
    if (size(influencers) > 0) {
      return (
        <ButtonMenu
          buttonProps={buttonProps}
          buttonChildren={(
            <React.Fragment>
              {size(selectedInfluencers)} Selected
              <KeyboardArrowDownIcon />
            </React.Fragment>
          )}
          menuChildren={this.renderMenuChildren()}
        />
      );
    }
    return null;
  }
}

SelectedInfluencers.propTypes = {
  addSelectedInfluencers: PropTypes.func.isRequired,
  deselectAllInfluencers: PropTypes.func.isRequired,
  buttonProps: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
  influencers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  selectedInfluencers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

SelectedInfluencers.defaultProps = {
  buttonProps: {},
  influencers: [],
};

export default connect(null, {
  addSelectedInfluencers,
  deselectAllInfluencers,
})(withSelectedInfluencers(withStyles(styles)(SelectedInfluencers)));
