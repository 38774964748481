import { newReducer } from '../utils';

const initialState = {
  data: {},
  loading: false,
  loaded: false,
};

// eslint-disable-next-line import/prefer-default-export
export const userProfileReducer = newReducer(initialState);

userProfileReducer.method.FETCH_USER_PROFILE_FULFILLED = (state = initialState, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

userProfileReducer.method.FETCH_USER_PROFILE_PENDING = (state = initialState) => ({
  ...state,
  data: {},
  loading: true,
});

userProfileReducer.method.FETCH_USER_PROFILE_REJECTED = (state = initialState) => ({
  ...state,
  data: {},
  loading: false,
  loaded: true,
});
