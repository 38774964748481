import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Tab, Tabs, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { GENDER_LABEL_MAP } from 'constants/options';
import { isEmpty } from 'lodash';
import { destroyInfluencerPosts } from 'redux/influencers/actions';
import NoResultCard from 'components/base/NoResultCard';
import Button from 'components/base/Button';
import SocialLink from './SocialLink';
import Categories from './Categories';
import Audience from './Audience';
import Mentions from './Mentions';
import TopLabels from './TopLabels';
import TopContent from './TopContent';
import SponsoredPosts from './SponsoredPosts';
import styles from './styles';

const TABS = {
  TOP_CONTENT: 0,
  AUDIENCE: 1,
  TOP_LABELS: 2,
  HASHTAGS_MENTIONS: 3,
  SPONSORED_CONTENT: 4,
};

class ProfileRightColumn extends Component {
  state = {
    tabSelected: TABS.TOP_CONTENT,
  };

  getEmails = () => {
    const { influencer } = this.props;
    return influencer.emails.map(e => e.email).join(' ');
  }

  handleTabChange = (event, tabSelected) => {
    this.props.destroyInfluencerPosts();
    this.setState({
      tabSelected,
    });
  };

  renderProfileItem = (label, value) => {
    const {
      classes,
    } = this.props;
    return (
      <li className={classes.profile__item}>
        <Typography className={classes.profile__item__label}>{label}</Typography>
        <Typography className={classes.profile__item__value}>{value}</Typography>
      </li>
    );
  };

  render() {
    const {
      classes,
      influencer,
      theme,
      permittedViews,
    } = this.props;
    const {
      tabSelected,
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.profile__container}>
          <Typography className={classes.profile__title} variant="h5">Profile</Typography>
          <Typography variant="body1" className={classes.profile__bio}>{influencer.bio}</Typography>
          <ul className={classes.profile__items}>
            {!isEmpty(influencer.gender) &&
              this.renderProfileItem('Gender:', GENDER_LABEL_MAP[influencer.gender])
            }
            {!isEmpty(influencer.age) &&
              this.renderProfileItem('Age:', influencer.age)
            }
            {!isEmpty(influencer.relationShipStatus) &&
              this.renderProfileItem('Relationship status:', influencer.relationShipStatus)
            }
            {!isEmpty(influencer.language) &&
              this.renderProfileItem('Language:', influencer.language)
            }
          </ul>
          <div className={classes.profile__additional_data}>
            <div className={classes.profile__social_links}>
              <Typography variant="h6" className={classes.profile__h5}>Social Links</Typography>
              <div className={classes.social_links__container}>
                {influencer.platforms.map(platform => <SocialLink key={platform.platform} platform={platform} />)}
              </div>
              <div className={classes.profile__email_button}>
                {
                  !isEmpty(influencer.emails) &&
                  <Tooltip title={this.getEmails()}>
                    <Button
                      onClick={
                        () => {
                          window.location = `mailto:${influencer.emails.map(e => e.email).join(',')}`;
                        }
                      }
                      color="primary"
                      variant="contained"
                    > Contact Influencer
                    </Button>
                  </Tooltip>
                }
              </div>
            </div>

            <div className={classes.profile__category}>
              {!isEmpty(influencer.categories) &&
                <React.Fragment>
                  <Typography variant="h6" className={classes.profile__h5}>Category</Typography>
                  <Categories categories={influencer.categories} />
                </React.Fragment>
              }
            </div>
          </div>
        </div>
        <Tabs
          value={tabSelected}
          onChange={this.handleTabChange}
          className={classes.tab__container}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.blue.main,
            },
          }}
        >
          <Tab className={classes.tab__item} value={TABS.TOP_CONTENT} label="Top Content" />
          <Tab className={classes.tab__item} value={TABS.AUDIENCE} label="Audience" />
          {permittedViews.topStats &&
            <Tab className={classes.tab__item} value={TABS.TOP_LABELS} label="Top Labels" />}
          {permittedViews.topStats &&
            <Tab className={classes.tab__item} value={TABS.HASHTAGS_MENTIONS} label="Top Hashtags & Mentions" />}
          {permittedViews.sponsoredPosts &&
            <Tab className={classes.tab__item} value={TABS.SPONSORED_CONTENT} label="Sponsored Content" />}
        </Tabs>
        <div className={classes.tabs__content__container}>
          {tabSelected === TABS.TOP_CONTENT && <TopContent />}
          {tabSelected === TABS.AUDIENCE &&
            <React.Fragment>
              {(isEmpty(influencer.audience) || isEmpty(influencer.audience.platforms)) &&
                <div className={classes.tabs__content__container_empty}>
                  <NoResultCard noResultMessage="No audience data found" />
                </div>}
              {(influencer.audience && !isEmpty(influencer.audience.platforms)) &&
                <Audience
                  audienceTeaser={!permittedViews.audience}
                  locationTeaser={!permittedViews.locations}
                  audience={influencer.audience}
                />
              }
            </React.Fragment>
          }
          {tabSelected === TABS.TOP_LABELS && <TopLabels labels={influencer.topLabels} />}
          {tabSelected === TABS.HASHTAGS_MENTIONS && <Mentions mentions={influencer.topHashtagsMentions} />}
          {tabSelected === TABS.SPONSORED_CONTENT &&
            <SponsoredPosts
              brands={influencer.sponsoredPosts.brands}
              sponsoredTags={influencer.sponsoredPosts.sponsoredTags}
            />
          }
        </div>
      </div>
    );
  }
}

ProfileRightColumn.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  influencer: PropTypes.shape({
    bio: PropTypes.string,
    gender: PropTypes.string,
    age: PropTypes.string,
    relationShipStatus: PropTypes.string,
    language: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.shape({})),
    categories: PropTypes.shape({}),
    topHashtagsMentions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      count: PropTypes.number,
    })),
    sponsoredPosts: PropTypes.shape({
      sponsoredTags: PropTypes.arrayOf(PropTypes.shape({})),
      brands: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    topLabels: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      count: PropTypes.number,
    })),
  }).isRequired,
  destroyInfluencerPosts: PropTypes.func.isRequired,
  permittedViews: PropTypes.shape({
    audience: PropTypes.bool,
    topStats: PropTypes.bool,
    sponsoredPosts: PropTypes.bool,
  }).isRequired,
};

export default connect(
  null,
  { destroyInfluencerPosts },
)(withStyles(styles, { withTheme: true })(ProfileRightColumn));
