export default () => ({
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionButtons: {
    marginLeft: 'auto',
    marginTop: '1rem',
  },
});
