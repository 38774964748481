export default theme => ({
  root: {
    padding: '0 0.8rem',
    '&:after': {
      content: '""',
      position: 'relative',
      right: '-0.8rem',
      [theme.breakpoints.up('sm')]: {
        content: '"|"',
      },
    },
    '&:last-child:after': {
      content: '""',
    },
  },
});
