import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import BaseModal from 'components/base/modals/BaseModal';
import withAll from 'components/base/withAll';
import {
  deleteKeyPhraseMonitor as deleteKeyPhraseMonitorAction,
  getKeyPhraseMonitors as getKeyPhraseMonitorsAction,
} from 'redux/monitors/actions';
import styles from './styles';

const SuccessMessage = () => <React.Fragment>Monitor has been deleted.</React.Fragment>;

const DeleteMonitorModal = ({
  classes,
  open,
  closeModal,
  monitor,
  deleteKeyPhraseMonitor,
  getKeyPhraseMonitors,
}) => {
  const onConfirm = async () => {
    await deleteKeyPhraseMonitor(monitor.id);
    closeModal();
    toast.success(<SuccessMessage />);
    getKeyPhraseMonitors();
  };

  return (
    <BaseModal
      open={open}
      onClose={closeModal}
      title="Delete monitor"
      width="25rem"
      height="auto"
    >
      <div className={classes.body}>
        <Typography variant="body1">
          Are you sure you want to delete monitor <b>{monitor.monitorName}</b>?
        </Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            Yes
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            onClick={closeModal}
          >
            No
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

DeleteMonitorModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  monitor: PropTypes.shape({
    id: PropTypes.string,
    monitorName: PropTypes.string,
  }).isRequired,
  deleteKeyPhraseMonitor: PropTypes.func.isRequired,
  getKeyPhraseMonitors: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  deleteKeyPhraseMonitor: deleteKeyPhraseMonitorAction,
  getKeyPhraseMonitors: getKeyPhraseMonitorsAction,
};

export default withAll(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(DeleteMonitorModal);
