export default {
  card: {
    width: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  forgot__password__title: {
    textAlign: 'center',
  },
  reset__password__message__wrapper: {
    height: '6rem',
    paddingTop: '4em',
    textAlign: 'center',
  },
};
