
const API = {
  REFRESH_TIMEOUT: 1500,

  PING: 'ping',

  // Authentication endpoints
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  GET_USER: 'auth/user',
  FORGOT_PASSWORD: 'auth/forgot-password',
  CHANGE_PASSWORD: 'auth/change-password',
  SET_PASSWORD: 'auth/set-password',
  VERIFY_EMAIL: 'auth/verify-email',

  HOME_STATS: 'stats/featured',
  VERTICAL_SUMMARY: 'stats/verticals',
  STAT_BRANDS: 'stats/brands',
  VERTICAL_DETAIL: v => `stats/verticals/${v}`,
  BRAND_DETAIL: v => `stats/brands/${v}`,

  VERTICALS_CATEGORIES: 'stats/verticals-categories',

  // Brands
  BRANDS: 'brands',
  BRAND_REQUEST: 'brand-request',

  // Reports
  COMPETITIVE_REPORT_DETAIL: v => `report/${v}`,
  COMPETITIVE_REPORTS: () => 'report',
  CUSTOMIZED_COMPETITIVE_REPORTS: () => 'customized-report',
  COMPETITIVE_REPORTS_ANONYMOUS: () => 'report/anonymous',
  COMPETITIVE_REPORT_POSTS: v => `report/${v}/posts`,
  COMPETITIVE_REPORT_VERTICAL_STATS: v => `report/${v}/vertical_stats`,
  COMPETITIVE_REPORT_STATS: v => `report/${v}/stats`,
  COMPETITIVE_PLATFORM_DISTRIBUTION: v => `report/${v}/platform_distribution`,
  COMPETITIVE_POST_ACTIVITY: v => `report/${v}/post_activity`,
  COMPETITIVE_REPORT_CREATORS: v => `report/${v}/creators`,
  COMPETITIVE_REPORT_EXCEL: v => `report/${v}/excel`,
  COMPETITIVE_REPORT_ESTIMATED_SPEND: v => `report/${v}/estimated-influencer-spend`,

  // Brand safety reports
  BRAND_SAFETY_REPORT: reportId => `brand-safety/reports/${reportId}`,
  BRAND_SAFETY_REPORT_POSTS: reportId => `brand-safety/reports/${reportId}/posts`,
  BRAND_SAFETY_REPORTS: () => 'brand-safety/reports',
  BRAND_SAFETY_REPORT_UNUSUAL_ENGAGEMENT_POSTS: reportId => `brand-safety/reports/${reportId}/unusual-engagement-posts`,
  OBJECTIONABLE_CONTENT_LISTS: () => 'brand-safety/objectionable-content-lists',

  FETCH_LISTS: 'lists/all',
  EXPORT_LIST: 'lists/export',
  FETCH_MEMBERS: 'lists/members',
  FETCH_LIST: listId => `lists/${listId}`,
  CREATE_LIST: 'lists/create',
  UPDATE_LIST: 'lists/update',
  DELETE_LIST: 'lists/delete',
  ADD_TO_LISTS: 'lists/add',
  REMOVE_FROM_LISTS: 'lists/remove',
  FETCH_LIST_CATEGORIES: 'lists/categories',

  FETCH_CONFIG: 'config/all',

  INFLUENCERS_RECOMMEND: 'influencers/recommend',
  INFLUENCERS_SEARCH: 'influencers/search',
  RELATED_KEYWORDS: 'influencers/search/related_keywords',
  INFLUENCERS_DEFAULT_SEARCH: 'influencers/default_search',
  DEFAULT_INFLUENCERS_FILTERS: 'influencers/default_filters',
  INFLUENCERS_PROFILE: 'influencers/profile',
  INFLUENCERS_PROFILE_TOP_CONTENT: 'influencers/profile/top_content',
  INFLUENCERS_PROFILE_TOP_POST: 'influencers/profile/top_posts',
  INFLUENCERS_PROFILE_SPONSORED_POST: 'influencers/profile/sponsored_posts',
  REQUEST_NEW_INFLUENCERS: 'influencers/request_new',
  COUNTRIES_AUTO_COMPLETE: 'autocomplete/country',
  LOCATION_AUTO_COMPLETE: 'autocomplete/locations',
  KEYWORD_AUTO_COMPLETE: 'autocomplete/keywords',
  LABELS_AUTO_COMPLETE: 'autocomplete/labels',
  USERNAMES_AUTO_COMPLETE: 'autocomplete/usernames',
  BRANDS_AUTO_COMPLETE: 'autocomplete/brands',

  FETCH_FEATURED_INFLUENCERS: 'influencers/featured',
  FETCH_FEATURED_CATEGORIES: 'categories/featured',

  CREATE_USER: 'user/create_prism_user',
  CREATE_INFLUENCER_USER: 'user/create_influencer',
  FETCH_USER_PROFILE: 'user/profile',
  UPDATE_USER_PROFILE: 'user/profile/update',
  PATCH_USER_PROFILE: 'user/profile/patch',
  UPDATE_USER_PASSWORD: 'user/password/update',
  USER_HELP_REQUEST: 'user/help',
  USER_UPDATE_BILLING_INFO: 'user/add_card',
  USER_DELETE_BILLING_INFO: 'user/delete_card',

  CEVICHE: 'ceviche/track',

  SECURITY_PERMITTED: 'security/permitted',
  SECURITY_PLANS: 'security/prism/plans',
  SECURITY_BILLING_SESSION: 'security/prism/billing-session',
  SECURITY_CHECKOUT_SESSION: 'security/prism/checkout-session',
  SECURITY_SUBSCRIPTION: 'security/subscription',
  SECURITY_SUBSCRIBE: 'security/subscribe',
  SECURITY_UNSUBSCRIBE: 'security/unsubscribe',

  KEY_PHRASE_MONITORS: 'monitor/all',
  KEY_PHRASE_MONITOR: v => `monitor/${v}`,
  KEY_PHRASE_MONITOR_POSTS: v => `monitor/${v}/posts`,
};

export default API;
