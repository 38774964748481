export default theme => ({
  monitorDataContainer: {
    '& > *': {
      display: 'block',
      marginTop: '0.3rem',
    },
    '& > :first-child': {
      marginTop: '0px',
    },
  },
  monitorTitle: {
    display: 'flex',
  },
  monitorPlatforms: {
    fontSize: '1rem',
    marginLeft: '1rem',
    display: 'flex',
  },
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '0.3rem',
  },
  filterItem: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '1.5rem',
    },
    marginBottom: '1.5rem',
    marginRight: '0.15rem',
    marginLeft: 'auto',
  },
});
