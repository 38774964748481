export default theme => ({
  root: {
    width: '7rem',
    margin: '0 auto',
    '& img': {
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: '11rem',
      margin: 0,
    },
  },
});
