import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import cx from 'classnames';
import { addSelectedInfluencer, removeSelectedInfluencer } from 'redux/searchDisplay/actions';
import styles from './styles';
import CheckboxCircle from '../../CheckboxCircle';

class SavedListCheckbox extends Component {
  selectInfluencer = (event, checked) => {
    const { influencerDisplayName, influencerId } = this.props;
    if (checked) {
      this.props.addSelectedInfluencer(influencerId, influencerDisplayName);
    } else {
      this.props.removeSelectedInfluencer(influencerId);
    }
  };

  render() {
    const { classes, influencerId, searchDisplay } = this.props;
    return (
      <CheckboxCircle
        className={cx(classes.root, 'influnecer_select')}
        onChange={this.selectInfluencer}
        checked={!!searchDisplay.selectedInfluencers[influencerId]}
      />
    );
  }
}
SavedListCheckbox.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  influencerDisplayName: PropTypes.string.isRequired,
  influencerId: PropTypes.string.isRequired,
  addSelectedInfluencer: PropTypes.func.isRequired,
  removeSelectedInfluencer: PropTypes.func.isRequired,
  searchDisplay: PropTypes.shape({
    selectedInfluencers: PropTypes.shape({}),
  }).isRequired,
};
export default connect(
  state => ({
    searchDisplay: state.searchDisplay,
  }),
  {
    addSelectedInfluencer,
    removeSelectedInfluencer,
  },
)(withStyles(styles)(SavedListCheckbox));
