import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withAll from 'components/base/withAll';
import { pricing } from 'constants/routing';
import BetaSignupModal from 'components/common/modals/BetaSignupModal';
import withModal from 'components/base/withModal';
import styles from './styles';

const UpgradeButton = ({
  classes,
  history,
  isAuthenticated,
  isSubscriptionActive,
  isTrial,
  fullWidth,
  open,
  closeModal,
  openModal,
}) => {
  const label = (isSubscriptionActive && isTrial) || !isAuthenticated ? 'Pricing' : 'Upgrade';
  const handleSubscribeClick = () => {
    if (isAuthenticated) {
      history.push(pricing());
    }
    openModal();
  };
  return (
    <React.Fragment>
      <BetaSignupModal
        open={open}
        closeModal={closeModal}
        onSignupComplete={closeModal}
      />
      <Button
        fullWidth={fullWidth}
        variant="contained"
        className={classes.root}
        onClick={handleSubscribeClick}
      >
        {label}
      </Button>
    </React.Fragment>
  );
};

UpgradeButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSubscriptionActive: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

UpgradeButton.defaultProps = {
  fullWidth: false,
};

const mapStateToProps = ({ auth: { isAuthenticated }, userProfile: { data: { isSubscriptionActive, isTrial } } }) => ({
  isAuthenticated,
  isSubscriptionActive,
  isTrial,
});

export default withAll(
  connect(mapStateToProps),
  withStyles(styles),
  withRouter,
  withModal,
)(UpgradeButton);
