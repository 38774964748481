import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deselectAllInfluencers } from '../../redux/searchDisplay/actions';

class InfluencerSelectionManager extends React.Component {
  componentDidMount() {
    this.props.deselectAllInfluencers();
  }

  componentWillUnmount() {
    this.props.deselectAllInfluencers();
  }

  render() {
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

InfluencerSelectionManager.propTypes = {
  deselectAllInfluencers: PropTypes.func.isRequired,
};

export default connect(null, {
  deselectAllInfluencers,
})(InfluencerSelectionManager);
