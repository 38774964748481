import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { socialLinkFromUsernameAndPlatform } from 'lib/utils';
import { PLATFORMS } from 'constants/options';
import Chip from 'components/base/Chip';
import styles from './styles';


const Brands = ({ brands, classes }) => (
  <React.Fragment>
    {brands.map(brand => (
      <Link
        href={socialLinkFromUsernameAndPlatform(brand.instagramUsername, PLATFORMS.INSTAGRAM)}
        target="_blank"
        className={classes.brand__link}
      >
        <Chip className={classes.chip} label={`@${brand.instagramUsername}`} />
      </Link>
    ))}
  </React.Fragment>
);

Brands.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    instagramUsername: PropTypes.string,
  })).isRequired,
};


export default withStyles(styles)(Brands);
