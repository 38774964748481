import { newReducer } from '../utils';

const initialState = {
  relatedKeywords: {
    loading: false,
    loaded: false,
    data: [],
  },
};

// eslint-disable-next-line import/prefer-default-export
export const relatedKeywordsReducer = newReducer(initialState.relatedKeywords);

relatedKeywordsReducer.method.GET_RELATED_KEYWORDS_PENDING = state => ({
  ...state,
  loading: true,
});

relatedKeywordsReducer.method.GET_RELATED_KEYWORDS_FULFILLED = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  loaded: true,
});

relatedKeywordsReducer.method.GET_RELATED_KEYWORDS_REJECTED = state => ({
  ...state,
  loading: false,
  loaded: false,
});

relatedKeywordsReducer.method.RESET_RELATED_KEYWORDS = () => ({
  ...JSON.parse(JSON.stringify(initialState)),
});
