import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';


const styles = theme => ({
  statSection: {
    margin: '1rem -0.5rem 0.5rem -0.5rem',
  },
  statContainer: {
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.border.main}`,
    '& > div': {
      borderRight: `1px solid ${theme.palette.border.main}`,
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
});

const StatCard = ({
  children,
  classes,
}) => (
  <div className={classes.statSection}>
    <Grid container className={classes.statContainer} spacing={16}>
      {children}
    </Grid>
  </div>
);

StatCard.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(StatCard);
