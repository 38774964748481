export default theme => ({
  root: {
    padding: '1.4rem',
  },
  stats__title: {
    color: theme.palette.white.main,
    fontSize: '1rem',
    marginBottom: '0.8rem',
  },
});
