import React, { Component } from 'react';
import BaseModal from 'components/base/modals/BaseModal';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import RequestInfluencerForm from 'components/common/forms/RequestInfluencerForm';
import styles from './styles';

class RequestInfluencers extends Component {
  handleSubmit = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  render() {
    const {
      open,
      closeModal,
      classes,
    } = this.props;
    return (
      <BaseModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={closeModal}
        title="Request Influencer"
        width="700"
        height="auto"
      >
        <div className={classes.root}>
          <Typography variant="body1">
            Don&apos;t see a specific influencer in our system? No problem! Use this form to enter the user names
             and associated platforms of influencers you&apos;d like to add to CreatorBase. We&apos;ll notify you when
             it&apos;s there. It typically takes just a few minutes to get into our system.
          </Typography>
          <RequestInfluencerForm onSubmit={this.handleSubmit} />
        </div>
      </BaseModal>
    );
  }
}

RequestInfluencers.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(RequestInfluencers);
