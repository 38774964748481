import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import NoResultCard from 'components/base/NoResultCard';
import Loader from 'components/base/Loader';
import Post from 'components/common/CompetitiveReport/TopContent/Post';
import styles from './styles';


const TopContent = ({
  classes, posts, loaded, isLoading,
}) => (
  <div>
    <Typography variant="h5">
      This section displays the Top Content of this creator posted within the past 365 days
    </Typography>
    <Loader loading={isLoading} loaded={loaded}>
      <div>
        {isEmpty(posts) && <NoResultCard noResultMessage="No content found for this creator" />}
        <Grid container spacing={2} justify="center">
          {posts.map(p => (
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <Post data={p} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Loader>
  </div>
);

TopContent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  posts: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ influencerTopContent }) => ({
  isLoading: influencerTopContent.loading,
  loaded: influencerTopContent.loaded,
  posts: influencerTopContent.posts,
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(TopContent));
