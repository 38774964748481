import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { round } from 'lodash';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import MenuButton from 'components/base/MenuButton';
import MenuOption from 'components/base/MenuOption';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import UpdateListModal from 'components/common/modals/UpdateListModal';
import { listUrl } from 'constants/routing';
import { deleteList } from 'redux/lists/actions';
import { formatDate } from '../../../../lib/utils';
import Stats from './Stats';
import styles from './styles';

class List extends React.PureComponent {
  formatStats = () => {
    const { list: { stats, members } } = this.props;
    return [
      {
        title: 'Creators',
        value: members || 0,
        tooltip: 'Total Number of Creators in the list',
      },
      {
        title: 'Reach',
        value: stats.totalReach || 0,
        tooltip: 'Total number of followers of the creators in the list.',
      },
      {
        title: 'Avg. Engagement',
        value: round(stats.avgEngagement, 3) || 0,
        tooltip: 'Average of all the engagement of the creators in the list',
      },
      {
        title: 'Avg. Spon. Eng.',
        value: round(stats.avgSponsoredPostEngagement, 3) || 0,
        tooltip: 'Average of the sponsored engagement of the creators in the list.',
      },
    ];
  };

  deleteList = () => {
    this.props.deleteList(this.props.list.uid);
  };

  render() {
    const {
      classes,
      list,
      open,
      closeModal,
      openModal,
    } = this.props;

    return (
      <Card className={classes.list}>
        <Link to={listUrl(list.uid)}>
          <CardMedia
            className={classes.image}
            image={list.image || list.defaultImage}
          />
        </Link>
        <div className={classes.info}>
          <div className={classes.list__text}>
            <Typography className={classes.list__name}>
              {list.name}
            </Typography>
            <Typography className={classes.list__date}>
              {`Created on ${formatDate(list.createdTime)}`}
            </Typography>
          </div>
          <MenuButton
            className={cx(classes.more__button, 'btn__list-menu-btn')}
            icon={<MoreVertIcon />}
            btn
          >
            <MenuOption
              label="Edit List"
              icon={<CreateIcon />}
              onClick={openModal}
            />
            <MenuOption
              className="btn__delete-list"
              label="Delete List"
              icon={<DeleteIcon />}
              onClick={this.deleteList}
            />
          </MenuButton>
        </div>
        <Stats stats={this.formatStats()} />
        {
          open && <UpdateListModal
            open={open}
            closeModal={closeModal}
            list={list}
          />
        }
      </Card>
    );
  }
}

List.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  deleteList: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  list: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    createdTime: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    members: PropTypes.number,
    stats: PropTypes.shape({
      totalReach: PropTypes.number,
      avgEngagement: PropTypes.number,
      avgSponsoredPostEngagement: PropTypes.number,
    }),
  }).isRequired,
};

const mapDispatchToProps = {
  deleteList,
};

export default withAll(
  connect(null, mapDispatchToProps),
  withModal,
  withRouter,
  withStyles(styles),
)(List);
