import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import PageTitle from 'components/base/titles/PageTitle';
import ReportList from './ReportList';
import CustomizedReportList from './CustomizedReportList';

const styles = () => ({
  root: {},
});

const CompetitiveReportsCommon = ({ classes, customizedCompetitiveReports }) => (
  <div className={classes.root}>
    {!isEmpty(customizedCompetitiveReports) && (
      <React.Fragment>
        <PageTitle title="Customized Reports" />
        <CustomizedReportList />
      </React.Fragment>
    )}
    <PageTitle title="Reports" />
    <ReportList />
  </div>
);

CompetitiveReportsCommon.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  customizedCompetitiveReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ customizedCompetitiveReports }) => ({
  customizedCompetitiveReports: get(customizedCompetitiveReports, 'data.results', []),
});

export default withAll(
  connect(
    mapStateToProps,
    null,
  ),
  withStyles(styles),
)(CompetitiveReportsCommon);
