import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { withStyles } from '@material-ui/styles';
import { formatNumber } from 'utils/helpers';

export const getOptions = options => ({
  responsive: true,
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  elements: {
    point: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
  tooltips: {
    displayColors: false,
    mode: 'index',
    enabled: true,
    intersect: false,
    callbacks: {
      label: (tooltip, dataset) => {
        const { datasetIndex } = tooltip;
        // Only show for the main dataset. The other dataset is just for visual effect.
        if (datasetIndex !== 0) {
          return '';
        }
        return dataset.datasets.map(d => (
          `${d.label}: ${formatNumber(d.data[tooltip.index], '0a', '-')} Posts`
        ));
      },
      labelColor: (tooltipItem, chart) => ({
        borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
        backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
      }),
    },
  },
  title: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    yAxes: [{
      stacked: true,
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
      display: true,
      ticks: {
        beginAtZero: false,
      },
    }],
    xAxes: [{
      stacked: true,
      gridLines: {
        display: true,
        drawOnChartArea: false,
      },
      display: true,
    }],
  },
  ...options,
  legend: {
    display: false,
    labels: {
      usePointStyle: true,
    },
    ...options.legend ? options.legend : {},
  },
});

class BarBasic extends React.PureComponent {
  render() {
    const {
      options, data, ...other
    } = this.props;
    return (
      <Bar
        data={data}
        options={getOptions(options)}
        {...other}
      />
    );
  }
}

BarBasic.propTypes = {
  options: PropTypes.shape({}),
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
};

BarBasic.defaultProps = {
  options: {},
};

export default withStyles({}, { withTheme: true })(BarBasic);
