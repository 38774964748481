import { withStyles } from '@material-ui/core/styles';
import { ToggleButton } from '@material-ui/lab';

export default withStyles(theme => ({
  root: {
    textTransform: 'none',
    flexGrow: 1,
    marginLeft: '0.06rem',
    marginRight: '0.06rem',
    backgroundColor: theme.palette.blue.light,
    color: theme.palette.blue.dark,
    '&:first-child': {
      borderRadius: '0px',
      borderRight: '0px',
    },
    '&:not(:first-child)': {
      borderRadius: '0px',
      borderLeft: `1px solid ${theme.palette.white.main}`,
    },
    '&$selected': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.white.main,
      borderRadius: '0px',
      border: `1px solid ${theme.palette.white.main}`,
    },
    height: '2.2rem',
    borderRadius: '0px',
    border: `1px solid ${theme.palette.white.main}`,
  },
  selected: {},
}))(ToggleButton);
