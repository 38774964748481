export default theme => ({
  platform__select: {
    width: '100%',
  },
  platform__option: {
    padding: '5px 15px',
    color: theme.palette.text.bold,
    fontSize: '18px',
  },
});
