import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduce } from 'lodash';
import { withStyles } from '@material-ui/styles';
import Doughnut from 'components/base/charts/Doughnut/Doughnut';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import Legend from 'components/base/charts/Legend';
import H6 from 'components/base/titles/CardHeading/H6';
import withAll from 'components/base/withAll';
import { FOLLOWER_RANGE_COLORS, FOLLOWER_RANGE_LABELS } from 'components/common/CompetitiveReport/constants';
import { formatNumber } from 'utils/helpers';
import PieCountBackground from './PieCountBackground';

const styles = () => ({
  root: {},
  legend: {
    marginTop: '0.4rem',
  },
});

const NULL_VALUE = '-';
const MONEY_FORMAT = '$0.00a';

const ByFollowerRangeDonut = ({ classes, data }) => {
  const options = {
    tooltips: {
      callbacks: {
        label: (tooltip, chartData) => (
          ` ${chartData.labels[tooltip.index]}: ` +
          `${formatNumber(chartData.datasets[tooltip.datasetIndex].data[tooltip.index], MONEY_FORMAT, NULL_VALUE)}`
        ),
      },
    },
  };

  const followerRangesSpend = Object.keys(FOLLOWER_RANGE_LABELS).map(followerRange => (
    reduce(data.map(b => b.currentPeriod[followerRange]), (memo, spend) => memo + spend, 0)
  ));
  const totalSpend = reduce(followerRangesSpend, (memo, spend) => memo + spend, 0);
  const chartData = {
    labels: Object.values(FOLLOWER_RANGE_LABELS),
    datasets: [{
      data: followerRangesSpend,
      backgroundColor: Object.keys(FOLLOWER_RANGE_LABELS).map(followerRange => FOLLOWER_RANGE_COLORS[followerRange]),
    }],
  };

  const chartHeight = Math.max(84, 42 * data.length);

  return (
    <div className={classes.root}>
      <H6>
        <LabelWithInfoIcon
          labelText="Estimated Share of Spend"
          tooltip="Segmented by Creator size."
        />
      </H6>
      <Doughnut
        height={chartHeight}
        data={chartData}
        options={options}
        redraw
        backdropElement={(
          <PieCountBackground value={formatNumber(totalSpend, MONEY_FORMAT, NULL_VALUE)} />
        )}
      />
      <Legend
        className={classes.legend}
        items={Object.keys(FOLLOWER_RANGE_LABELS).map(followerRange => ({
          title: FOLLOWER_RANGE_LABELS[followerRange],
          color: FOLLOWER_RANGE_COLORS[followerRange],
        }))}
      />
    </div>
  );
};

ByFollowerRangeDonut.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    currentPeriod: PropTypes.shape({}),
  })),
};

ByFollowerRangeDonut.defaultProps = {
  data: [],
};

const mapStateToProps = ({ competitiveReportEstimatedSpend: { data: { data } } }) => ({
  data,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(ByFollowerRangeDonut);
