import React from 'react';

import ViewListIcon from '@material-ui/icons/ViewList';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import styles from './styles';
import withModal from '../../../base/withModal';
import CreateListModal from '../../../common/modals/CreateListModal';
import withPermission from '../../../common/withPermission';
import withAll from '../../../base/withAll';

class CreateListButton extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      openModal,
      closeModal,
      permitted,
      canPlanBeUpgraded,
    } = this.props;
    return (
      <React.Fragment>
        <Button
          className={classes.createListButton}
          variant="contained"
          color="primary"
          onClick={openModal}
          // Disable button if user has reached lists limit and plan cannot be upgraded.
          disabled={!permitted && !canPlanBeUpgraded}
        >
          <ViewListIcon className={classes.createListIcon} />
          Create New List
        </Button>
        <CreateListModal
          isOpen={open}
          onClose={closeModal}
        />
      </React.Fragment>
    );
  }
}

CreateListButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  permitted: PropTypes.bool.isRequired,
  canPlanBeUpgraded: PropTypes.bool.isRequired,
};

export default withAll(
  withPermission('lists', null, identity, true),
  withModal,
  withStyles(styles),
)(CreateListButton);
