import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import Loader from '../../../../../base/Loader';

const Stat = (props) => {
  const {
    classes,
    loading,
    title,
    value,
  } = props;
  return (
    <div className={classes.stat}>
      <Loader loading={loading}>
        <Typography className={classes.value}>
          {value}
        </Typography>
        <Typography className={classes.title}>
          {title}
        </Typography>
      </Loader>
    </div>
  );
};

Stat.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withStyles(styles)(Stat);
