import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import styles from './styles';

const Page = ({
  classes,
  value,
  isActive,
  onClick,
}) => (
  <Button
    variant="contained"
    color={isActive ? 'primary' : 'secondary'}
    onClick={onClick}
    className={classes.root}
  >
    {value + 1}
  </Button>
);
Page.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  value: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Page.defaultProps = {
  isActive: false,
};

export default withStyles(styles)(Page);
