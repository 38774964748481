import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import logo from 'images/logo.png';
import styles from './styles';
import { home } from '../../../constants/routing';

const Logo = props => (
  <Link to={home()}>
    <div className={props.classes.root}><img src={logo} alt="Prism" /></div>
  </Link>
);

Logo.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(Logo);
