import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import {
  getCompetitiveReportPlatformDistribution as getCompetitiveReportPlatformDistributionAction,
} from 'redux/reports/actions';
import SponsoredFilter from '../SponsoredFilter';

const SponsoredSelect = ({
  params,
  getCompetitiveReportPlatformDistribution,
  match: { params: { reportId } },
}) => {
  const handleSponsoredSelect = (options) => {
    const { isSponsored, ...otherParams } = params;
    getCompetitiveReportPlatformDistribution(
      reportId,
      {
        ...otherParams,
        ...options.sponsored ? { isSponsored: true } : {},
        ...options.organic ? { isSponsored: false } : {},
      },
    );
  };

  return (<SponsoredFilter handleSelect={handleSponsoredSelect} params={params} />);
};

SponsoredSelect.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  getCompetitiveReportPlatformDistribution: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = ({ competitiveReportDistribution }) => ({
  params: competitiveReportDistribution.params,
});

export default withAll(
  connect(mapStateToProps, {
    getCompetitiveReportPlatformDistribution: getCompetitiveReportPlatformDistributionAction,
  }),
  withRouter,
)(SponsoredSelect);
