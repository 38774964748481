import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '../../components/app/InterstitialPages/Error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { children, renderComponent: ErrorComponent } = this.props;
    const { error, hasError, info } = this.state;
    const DisplayComponent = ErrorComponent || ErrorPage;
    if (hasError) {
      return (
        <DisplayComponent
          error={error}
          message={info}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  renderComponent: PropTypes.element,
};

ErrorBoundary.defaultProps = {
  renderComponent: null,
};

export default ErrorBoundary;
