import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import withAll from 'components/base/withAll';
import withModal from 'components/base/withModal';
import UpgradeModal from 'components/common/UpgradeModal';
import { getSelectedInfluencerIds } from 'components/common/withSelectedInfluencers';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import { addToLists } from 'redux/lists/actions';
import { STEPS_INDXES } from 'constants/onboarding';
import API from 'constants/api';
import { request } from 'lib/http';
import Tooltip from './Tooltip';

class SavedList3Tooltip extends React.Component {
  handleSearch = async () => {
    const { openModal } = this.props;
    request('put', API.CREATE_LIST, {
      data: {
        name: 'My dessert list',
      },
    }).then((res) => {
      const { selectedInfluencers } = this.props;
      const selectedInfluencerIds = map(selectedInfluencers, 'id');
      this.props.addToLists([res.listId], selectedInfluencerIds)
        .catch((response) => {
          if (response.response.status === 403) {
            openModal();
          }
        });
      this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST_4 });
    }).catch((response) => {
      if (response.response.status === 403) {
        openModal();
      } else if (response.response.status === 409) {
        this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST_4 });
        toast.success('List already exists');
      } else {
        throw new Error('Something went wrong');
      }
    });
  }

  render() {
    const { open, closeModal } = this.props;
    return (
      <React.Fragment>
        <Tooltip submitText="Next" handleSubmit={this.handleSearch} {...this.props} />
        <UpgradeModal open={open} closeModal={closeModal} />
      </React.Fragment>
    );
  }
}

SavedList3Tooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  updateOnBoarding: PropTypes.func.isRequired,
  addToLists: PropTypes.func.isRequired,
  selectedInfluencers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ searchDisplay: { selectedInfluencers } }) => ({
  selectedInfluencers: getSelectedInfluencerIds(selectedInfluencers),
});

export default withAll(
  connect(mapStateToProps, { updateOnBoarding, addToLists }),
  withModal,
)(SavedList3Tooltip);
