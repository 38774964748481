import React from 'react';
import {
  IconButton,
  Typography,
  Modal,
  Fade,
  Backdrop,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import { withStyles } from '@material-ui/styles';
import logo from 'images/logo.png';
import styles from './styles';


class BaseModal extends React.PureComponent {
  render() {
    const {
      children,
      classes,
      onClose,
      open,
      title,
      width,
      height,
      hideClose,
    } = this.props;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.root}
      >
        <Fade in={open}>
          <div className={classes.container} style={{ width, height }}>
            <div className={classes.header}>
              <img src={logo} alt="Prism" className={classes.logo} />
              <Typography className={classes.headerText}>
                {title}
              </Typography>
              {!hideClose && (
                <IconButton onClick={onClose} className={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              )}
            </div>
            <div className={classes.content}>
              {children}
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

BaseModal.propTypes = {
  children: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  hideClose: PropTypes.bool,
};

BaseModal.defaultProps = {
  width: null,
  height: '60%',
  hideClose: false,
};

export default withStyles(styles)(BaseModal);
