import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from 'components/base/Tooltip';
import styles from './styles';
import { displayNumber } from '../../../../../utils/helpers';

const Stat = (props) => {
  const {
    classes,
    className,
    title,
    value,
    tooltip,
  } = props;
  return (
    <Tooltip title={tooltip}>
      <div className={cx(className, classes.stat)}>
        <Typography className={classes.value}>
          {displayNumber(value)}
        </Typography>
        <Typography className={classes.title}>
          {title}
        </Typography>
      </div>
    </Tooltip>
  );
};

Stat.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  tooltip: PropTypes.string.isRequired,
};

Stat.defaultProps = {
  className: '',
};

export default withStyles(styles)(Stat);
