import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import html from './html';

/* eslint-disable */
const PrivacyPolicy = ({ classes }) => (
  <Container>
    <div className={classes.container} dangerouslySetInnerHTML={{__html: html}} />
  </Container>
);

PrivacyPolicy.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(PrivacyPolicy);
