import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { getCompetitiveReports, getCustomizedCompetitiveReports } from 'redux/reports/actions';
import CompetitiveReportsCommon from 'components/common/CompetitiveReports';
import PageLayout from 'components/base/Layout/PageLayout';
import Loader from 'components/base/Loader';
import { mpPageView } from 'utils/copenhagen';
import styles from './styles';

class CompetitiveReports extends React.Component {
  async componentDidMount() {
    await this.props.getCompetitiveReports();
    await this.props.getCustomizedCompetitiveReports();
    mpPageView({ name: 'reports' });
  }

  render() {
    const {
      isLoading,
      loaded,
      classes,
    } = this.props;
    return (
      <Loader loading={isLoading} loaded={loaded}>
        <div className={classes.root}>
          <PageLayout>
            <CompetitiveReportsCommon />
          </PageLayout>
        </div>
      </Loader>
    );
  }
}

CompetitiveReports.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getCompetitiveReports: PropTypes.func.isRequired,
  getCustomizedCompetitiveReports: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ competitiveReports }) => ({
  isLoading: competitiveReports.loading,
  loaded: competitiveReports.loaded,
});

export default connect(
  mapStateToProps,
  { getCompetitiveReports, getCustomizedCompetitiveReports },
)(withStyles(styles)(CompetitiveReports));
