import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ACCOUNT_TAB from 'components/app/AccountSettings/constants';
import { buildAccountSettingsUrl, home } from 'constants/routing';

const homePageParamString = new URLSearchParams({ target: 'signUpSection' });

const PaidRoute = ({
  component: C, render, isAuthenticated, isSubscriptionActive, props: cProps, ...rest
}) => {
  const redirect = isAuthenticated ?
    <Redirect to={buildAccountSettingsUrl(ACCOUNT_TAB.SUBSCRIPTION)} /> :
    <Redirect to={`${home()}?${homePageParamString.toString()}`} />;

  const componentRender = props => (isAuthenticated && (isSubscriptionActive || isSubscriptionActive == null) ?
    <C {...props} {...cProps} /> : redirect
  );
  return (
    <Route
      {...rest}
      render={render || componentRender}
    />
  );
};

PaidRoute.propTypes = {
  component: PropTypes.shape({}).isRequired,
  isSubscriptionActive: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ userProfile: { data: { isSubscriptionActive } }, auth: { isAuthenticated } }) => ({
  isSubscriptionActive,
  isAuthenticated,
});

export default connect(mapStateToProps)(PaidRoute);
