import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import LineBasic from 'components/base/charts/Line/LineBasic';
import { get } from 'lodash';
import { PLATFORM_COLOR } from 'constants/options';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import H6 from 'components/base/titles/CardHeading/H6';
import PlatformItem from 'components/common/CompetitiveReport/Header/Platforms/PlatformItem';
import { formatNumber } from 'utils/helpers';

const styles = () => ({
  root: {},
});

class PostByPlatform extends React.PureComponent {
  getOptions = () => ({
    legend: {
      display: false,
    },
    tooltips: {
      displayColors: false,
      mode: 'index',
      enabled: true,
      intersect: false,
      callbacks: {
        label: (tooltip, dataset) => {
          const { index: i, datasetIndex } = tooltip;
          const thisDataset = dataset.datasets[datasetIndex];
          if (!i) {
            return '';
          }
          return `${thisDataset.label}: ${formatNumber(tooltip.yLabel, '0a', '-')}`;
        },
      },
    },
  })

  transformedPostByBrand() {
    const { data } = this.props;
    return {
      labels: data.labels,
      datasets: data.dataSets.map(d => ({
        data: d.values,
        backgroundColor: 'transparent',
        borderWidth: 3,
        borderColor: PLATFORM_COLOR[d.label.toLowerCase()],
        label: d.label,
      })),
    };
  }

  render() {
    const { classes, data } = this.props;
    return (
      <div className={classes.root}>
        <H6>
          <LabelWithInfoIcon
            labelText="Mentions of Brand"
            tooltip="The frequency and timing of brand mentions by creators broken down by platform."
          />
          <span className={classes.legend}>
            {data.dataSets.map(d => (
              <PlatformItem platform={d.label.toLowerCase()} />
            ))}
          </span>
        </H6>
        <LineBasic
          height={100}
          options={this.getOptions()}
          data={this.transformedPostByBrand()}
        />
      </div>
    );
  }
}

PostByPlatform.propTypes = {
  data: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportDistribution }) => ({
  data: get(competitiveReportDistribution, 'data.platformByMonth', {}),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(PostByPlatform);
