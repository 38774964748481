import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import { nonExisting } from 'constants/routing';

// eslint-disable-next-line import/prefer-default-export
export const handleErrors = (error) => {
  if (error.response && error.response.status === 404) {
    window.location.replace(nonExisting());
    throw new SubmissionError({
      _error: 'Instance does not exist.',
    });
  } else if (error.response && error.response.status >= 400 && error.response.status < 500) {
    const { data, status } = error.response;
    throw new SubmissionError({ ...data || {}, status });
  } else if (error.response && error.response.status === 500) {
    toast.error('Server error, something went wrong.');
    throw new SubmissionError({
      _error: 'Server error, something went wrong.',
    });
  } else if (error.name.includes('Error')) {
    toast.error(error.message, { hideProgressBar: true, autoClose: false });
  }
  // TODO parse errors here and return error text and status
  return error;
};
