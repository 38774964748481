import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { CardContent, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Card from 'components/base/Card';
import Loader from 'components/base/Loader';
import withAll from 'components/base/withAll';
import TabHeader from 'components/common/CompetitiveReport/TabHeader';
import DateFilter from 'components/common/DateFilter';
import { getDateFromDays, getDaysFromDate } from 'utils/helpers';
import {
  getCompetitiveReportEstimatedInfluencerSpend as getCompetitiveReportEstimatedInfluencerSpendAction,
} from 'redux/reports/actions';
import ByFollowerRangeDonut from './ByFollowerRangeDonut';
import ByFollowerRangeHorizontal from './ByFollowerRangeHorizontal';
import ByMonth from './ByMonth';
import OverallChart from './OverallChart';
import PreviousPeriodCompared from './PreviousPeriodCompared';

const styles = () => ({
  root: {},
  card: {
    marginBottom: '1.8rem',
  },
});

const EstimatedInfluencerSpend = ({
  classes,
  params,
  isLoading,
  loaded,
  getCompetitiveReportEstimatedInfluencerSpend,
  match: { params: { reportId } },
}) => {
  React.useEffect(() => {
    getCompetitiveReportEstimatedInfluencerSpend(
      reportId,
      { ...params },
    );
  }, []);

  const handleDateSelect = (fromDate, toDate) => {
    getCompetitiveReportEstimatedInfluencerSpend(
      reportId,
      {
        ...params,
        postedWithin: getDaysFromDate(fromDate),
        postedBefore: getDaysFromDate(toDate),
      },
    );
  };

  return (
    <div className={classes.root}>
      <TabHeader
        title="Estimated Creator Spend"
        description="Prism examines sponsored creator content from each of the brands in this competitive report
                     to estimate a brand's creator marketing spend."
      >
        <DateFilter
          handleSelect={handleDateSelect}
          selectedFromDate={getDateFromDays(params.postedWithin)}
          selectedToDate={getDateFromDays(params.postedBefore)}
          customDate
        />
      </TabHeader>
      <Loader loading={isLoading} loaded={loaded}>
        <Card className={classes.card}>
          <CardContent sel="overall">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <OverallChart />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent sel="periods">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <PreviousPeriodCompared />
              </Grid>
              <Grid item md={6}>
                <ByMonth />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent sel="follower_range">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <ByFollowerRangeDonut />
              </Grid>
              <Grid item md={6}>
                <ByFollowerRangeHorizontal />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Loader>
    </div>
  );
};

EstimatedInfluencerSpend.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  getCompetitiveReportEstimatedInfluencerSpend: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ competitiveReportEstimatedSpend }) => ({
  isLoading: competitiveReportEstimatedSpend.loading,
  loaded: competitiveReportEstimatedSpend.loaded,
  params: competitiveReportEstimatedSpend.params,
});

const mapDispatchToProps = {
  getCompetitiveReportEstimatedInfluencerSpend: getCompetitiveReportEstimatedInfluencerSpendAction,
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(EstimatedInfluencerSpend);
