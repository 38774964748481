import React from 'react';
import TextField from 'components/base/forms/bootstrap/TextField';

export default ({
  input,
  label,
  meta: { touched, error } = {},
  ...custom
}) => {
  const hasError = !!(touched && error);
  const errorElem = <span>{hasError ? error : ''}</span>;
  return (
    <TextField
      error={hasError}
      helperText={errorElem}
      label={label}
      {...input}
      {...custom}
    />
  );
};

