import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import MUIModal from '@material-ui/core/Modal/index';
import CloseIcon from 'mdi-react/CloseIcon';
import { connect } from 'react-redux';
import withAll from 'components/base/withAll';
import CircularLoader from 'components/base/CircularLoader';
import { isString, cloneDeep, uniqBy } from 'lodash';
import { setSearchParam as setSearchParamAction } from 'redux/searchParams/actions';
import styles from './styles';

const convertKeyword = (keyword) => {
  if (isString(keyword.value)) {
    return keyword.value;
  }
  return keyword.value.text;
};

const convertRelatedKeyword = keyword => ({
  label: keyword.label,
  value: {
    label: keyword.label,
    text: keyword.text,
    type: 'hashtag',
  },
});

const RelatedKeywordsModal = ({
  classes,
  onClose,
  open,
  relatedKeywords,
  keywords,
  isLoading,
  setSearchKeywords,
}) => {
  const [secondPart, setSecondPart] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState([]);

  const handleClose = () => {
    setSecondPart(false);
    setSelectedKeyword([]);
    onClose();
  };

  const isChecked = value => selectedKeyword.filter(v => v.text === value).length > 0;

  const handleAdd = async () => {
    const keywordsClone = cloneDeep(keywords);
    keywordsClone.push(...selectedKeyword.map(k => convertRelatedKeyword(k)));
    setSearchKeywords(keywordsClone);
    handleClose();
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const selectedKeywordsClone = cloneDeep(selectedKeyword);

    if (checked) {
      selectedKeywordsClone.push(...relatedKeywords.filter(v => v.text === value));
      setSelectedKeyword(selectedKeywordsClone);
    } else {
      setSelectedKeyword(selectedKeywordsClone.filter(v => v.text !== value));
    }
  };

  const getKeywordCheckboxes = () => {
    const filteredRelatedKeywords = [];
    if (secondPart) {
      filteredRelatedKeywords.push(...selectedKeyword);
      filteredRelatedKeywords.push(...relatedKeywords.slice(8, 16));
    } else {
      filteredRelatedKeywords.push(...relatedKeywords);
    }

    return uniqBy(filteredRelatedKeywords, 'text').slice(0, 8).sort((a, b) => a.text>b.text).map(rk =>
      (<FormControlLabel
        label={rk.label}
        control={<Checkbox
          checked={isChecked(rk.text)}
          color="primary"
          value={rk.text}
          onChange={e => handleCheckboxChange(e)}
        />}
      />));
  };

  return (
    <MUIModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h6">
        Recent popular keywords related to:
          </Typography>
          <div className={classes.headerKeywordsContainer}>
            {keywords.map((k, index) => (<span>{!!index && (<b>or</b>)} {convertKeyword(k)}&nbsp;</span>)) }
          </div>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <CircularLoader isLoading={isLoading} />
        <div className={classes.content}>
          { relatedKeywords && getKeywordCheckboxes() }
          { !isLoading && relatedKeywords && relatedKeywords.length === 0 &&
          <Typography>Related keywords not found</Typography>
        }
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            variant="contained"
            color="primary"
            onClick={handleAdd}
          >
          Add Selected
          </Button>
          {!secondPart && relatedKeywords && relatedKeywords.length>8 &&
          <React.Fragment>
            or
            <Button
              className={classes.footerButton}
              variant="outlined"
              color="primary"
              onClick={() => setSecondPart(true)
              }
            >
            Refresh Unselected
            </Button>
          </React.Fragment>
        }
        </div>
      </div>
    </MUIModal>);
};

RelatedKeywordsModal.propTypes = {
  relatedKeywords: PropTypes.shape({}).isRequired,
  keywords: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setSearchKeywords: PropTypes.func.isRequired,
};

const mapStateToProps = ({ relatedKeywords, searchParams }) => ({
  isLoading: relatedKeywords.loading,
  relatedKeywords: relatedKeywords.data,
  keywords: searchParams.keywords,
});

const mapDispatchToProps = {
  setSearchKeywords: value => setSearchParamAction('keywords', value),
};

export default withAll(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(RelatedKeywordsModal);
