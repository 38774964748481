import React from 'react';
import PageTitle from 'components/base/titles/PageTitle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import BetaSignupForm from 'components/common/forms/BetaSignupForm';
import Card from 'components/base/Card';
import { SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { register, login } from 'redux/authentication/actions';
import { forms } from 'constants/config';
import { scrollIntoView, urlParamStringToObject } from 'utils/helpers';
import { get } from 'lodash';
import withAll from 'components/base/withAll';
import { withRouter } from 'react-router';
import { searchUrl } from 'constants/routing';

const SIGNUP_SECTION = 'signUpSection';

const styles = theme => ({
  title: {
    justifyContent: 'center',
    color: theme.palette.white.main,
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width: 375,
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  root: {
    backgroundColor: theme.palette.blue.dark,
    padding: '2rem',
  },
});

const SuccessMessage = () => (
  <React.Fragment>
    Account created!
  </React.Fragment>
);

class HomeSignup extends React.Component {
  componentDidMount() {
    if (this.formFocused()) {
      this.hasShownMessage = true;
      setTimeout(() => {
        scrollIntoView(`#${SIGNUP_SECTION}`);
      }, 1000);
    }
  }

  onSubmit = async (formData) => {
    const { history } = this.props;
    try {
      await this.props.register(formData);
      this.props.resetForm();
      toast.success(<SuccessMessage />);
      setTimeout(async () => {
        await this.props.login(formData.email, formData.password);
        history.push(searchUrl());
      }, 1000);
    } catch (error) {
      if (error.errors) {
        throw new SubmissionError(error.errors);
      }
    }
  }

  formFocused() {
    const { location } = this.props;
    const params = urlParamStringToObject(location.search);
    return get(params, 'target') === SIGNUP_SECTION;
  }

  render() {
    const { classes, isAuthenticated } = this.props;
    return (
      <React.Fragment>
        {!isAuthenticated &&
          <div className={classes.root} id="signUpSection">
            <PageTitle titleId="homeSignupH1" title="Get Started for FREE" className={classes.title} />
            <Card className={classes.card}>
              <BetaSignupForm formFocused={this.formFocused()} onSubmit={data => this.onSubmit(data)} />
            </Card>
          </div>
        }
      </React.Fragment>
    );
  }
}

HomeSignup.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  resetForm: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = {
  register,
  login,
  resetForm: () => dispatch => dispatch(reset(forms.BETA_SIGNUP_FORM)),
};

export default withAll(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(HomeSignup);
