import React, { Component } from 'react';
import cx from 'classnames';
import Button from 'components/base/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { reduxForm } from 'redux-form';
import ErrorCard from 'components/base/forms/ErrorCard';
import { isEmpty } from 'lodash';
import styles from './styles';


class AccountForm extends Component {
  componentDidMount() {
    const { initial, initialize } = this.props;
    if (initial) {
      initialize(initial);
    }
  }

  render() {
    const {
      classes,
      handleSubmit,
      children,
      submitText,
      pristine,
      error,
      hideHeaderError,
    } = this.props;
    return (
      <form className={cx(classes.form, 'form')} onSubmit={handleSubmit}>
        {!hideHeaderError && <ErrorCard errors={error} />}
        <div className={classes.form__content}>
          {children}
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.submit__button}
            variant="contained"
            color="primary"
            type="submit"
            disabled={pristine || !isEmpty(error)}
          >
            {submitText}
          </Button>
        </div>
      </form>
    );
  }
}

AccountForm.propTypes = {
  children: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initial: PropTypes.shape({}),
  initialize: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.arrayOf(PropTypes.string),
  hideHeaderError: PropTypes.bool,
};

AccountForm.defaultProps = {
  initial: null,
  error: null,
  hideHeaderError: false,
};

AccountForm.defaultProps = {
  submitText: 'Submit',
};

export default withStyles(styles)(reduxForm({})(AccountForm));
