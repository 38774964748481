export default theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    margin: '1.5em',
  },
  title: {
    fontSize: '1.4rem',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '0.4rem',
    marginTop: '0.4rem',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  closeButton: {
    marginLeft: 'auto',
    minWidth: '0.5em',
  },
  tooltipIconDiv: {
    display: 'inline-flex',
  },
  tooltipIcon: {
    marginLeft: '0.3em',
    color: theme.palette.black.dark,
    fontSize: '1.3em',
    '&:hover': {
      color: theme.palette.black.main,
    },
  },
});
