export default theme => ({
  root: {
    textAlign: 'center',
  },
  gender__stat: {
    display: 'inline-block',
    padding: '0 0.4rem',
    fontSize: '1rem',
    marginTop: '0.4rem',
    color: theme.palette.black.light,
  },
  gender__stat__value: {
    color: theme.palette.black.main,
  },
  gender__icon_female: {
    color: theme.palette.blue.main,
    fontSize: '1.4rem',
    marginRight: '0.4rem',
  },
  gender__icon_male: {
    color: theme.palette.green.main,
    fontSize: '1.4rem',
    marginRight: '0.4rem',
  },
});
