import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { STEPS_INDXES } from 'constants/onboarding';
import { resetSearchParams } from 'redux/searchParams/actions';
import { updateOnBoarding } from 'redux/onBoarding/actions';
import withInfluencerSearch from 'components/common/withInfluencerSearch';
import Tooltip from './Tooltip';

class SavedListTooltip extends React.Component {
  handleSearch = async () => {
    await this.props.resetSearchParams();
    const { searchParams } = this.props;
    searchParams.page = 0;
    await this.props.getInfluencers(searchParams);
    setTimeout(() => {
      this.props.updateOnBoarding({ stepIndex: STEPS_INDXES.SAVED_LIST_2 });
    }, 1000);
  }

  render() {
    return <Tooltip submitText="Next" handleSubmit={this.handleSearch} {...this.props} />;
  }
}


SavedListTooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  influencers: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
  getInfluencers: PropTypes.func.isRequired,
  resetSearchParams: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    influencers: state.influencers,
    searchParams: state.searchParams,
  }),
  { updateOnBoarding, resetSearchParams },
)(withInfluencerSearch(SavedListTooltip));
