export default theme => ({
  root: {
    fontSize: '0.9em',
    height: theme.sizes.layout.footer.height.mobile,
    [theme.breakpoints.up('sm')]: {
      height: theme.sizes.layout.footer.height.desktop,
    },
  },
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  link: {
    color: theme.palette.blue.main,
    textDecoration: 'none',
  },
  privacyItem: {
    padding: '0.5em',
  },
});
