import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/styles';
import H6 from 'components/base/titles/CardHeading/H6';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import BarBasic from 'components/base/charts/Bar/BarBasic';
import withAll from 'components/base/withAll';
import { formatNumber } from 'utils/helpers';
import { config } from 'theme';

const styles = () => ({
  root: {},
});

const COLORS = [
  config.palette.green.main,
  config.palette.red.main,
  config.palette.barChart.gradient.blue,
  config.palette.blue.dark,
];

const ByMonth = ({ classes, data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      intersect: false,
      mode: 'index',
      callbacks: {
        label: (tooltip, dataset) => {
          const { datasetIndex } = tooltip;
          // Only show for the main dataset. The other dataset is just for visual effect.
          if (datasetIndex !== 0) {
            return '';
          }
          return dataset.datasets.map(d => (
            `${d.label}: ${formatNumber(d.data[tooltip.index], '$0.00a', '-')}`
          ));
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 12,
        usePointStyle: true,
      },
    },
    scales: {
      yAxes: [{
        display: true,
        stacked: true,
        gridLines: {
          display: true,
          drawOnChartArea: true,
        },
        ticks: {
          callback: value => formatNumber(value, '$0a', '$0'),
        },
      }],
      xAxes: [{
        stacked: true,
        display: true,
        gridLines: {
          display: true,
          drawOnChartArea: false,
        },
      }],
    },
  };

  let periods = [];
  data.forEach((d) => {
    periods = periods.concat(Object.keys(d.currentPeriod.monthly));
  });
  periods.sort();
  periods = [...new Set(periods)];

  const defaultData = {};
  periods.forEach((p) => {
    defaultData[p] = { total: 0 };
  });

  const labels = periods.map(p => moment(p).format('MMM, YYYY'));
  const barThickness = periods.length > 18 ? 20 : 30;
  const chartData = {
    labels,
    datasets: data.map((b, i) => {
      const brandMonthlyPeriods = [];
      const monthlyData = isEmpty(b.currentPeriod.monthly) ? defaultData : b.currentPeriod.monthly;
      periods.forEach((p) => {
        brandMonthlyPeriods.push(monthlyData[p] || defaultData[p]);
      });

      return {
        label: b.name,
        data: brandMonthlyPeriods.map(v => v.total),
        backgroundColor: COLORS[i],
        barThickness,
      };
    }),
  };
  window.console.log(chartData);

  const chartHeight = Math.max(200, 100 * data.length);

  return (
    <div className={classes.root}>
      <H6>
        <LabelWithInfoIcon
          labelText="Estimated Creator Spend by Month"
          tooltip="Aggregated by a monthly period."
        />
      </H6>
      <BarBasic
        data={chartData}
        options={options}
        height={chartHeight}
        redraw
      />
    </div>
  );
};

ByMonth.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    currentPeriod: PropTypes.shape({
      monthly: PropTypes.shape({}),
    }),
  })),
};

ByMonth.defaultProps = {
  data: [],
};

const mapStateToProps = ({ competitiveReportEstimatedSpend: { data: { data } } }) => ({
  data,
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(ByMonth);
