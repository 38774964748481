export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    padding: '0',
    color: theme.palette.black.main,
  },
  title: {
    fontSize: '1.4rem',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.4rem',
    marginTop: '1.4rem',
    alignItems: 'center',
  },
  subtitle: {
    marginBottom: '2rem',
  },
  hasSubtitle: {
    marginBottom: '0.8rem',
  },
});
