export default theme => ({
  error__container: {
    backgroundColor: theme.palette.red.main,
    color: theme.palette.white.main,
    width: '100%',
    marginBottom: '2rem',
  },
  error__title: {
    textAlign: 'center',
  },
  error__list: {
    margin: 0,
  },
});
