import { withStyles } from '@material-ui/core/styles';
import GridListTileBar from '@material-ui/core/GridListTileBar';

export default withStyles(() => ({
  root: {
    height: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
}))(GridListTileBar);
