export default theme => ({
  formControl: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  select: {
    marginTop: '0 !important',
  },
  selectOption: {
    padding: '0.5rem 1rem',
    cursor: 'pointer',
  },
  label: {
    fontSize: '0.8rem',
    color: theme.palette.black.dark,
  },
});
