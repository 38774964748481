import { get, isEmpty } from 'lodash';

class Multimethod {
  constructor(dispatch, dfault) {
    if (!dispatch) {
      throw new Error('Multimethod requires dispatch.');
    }
    if (dispatch instanceof Function) {
      this.dispatch = dispatch;
    } else {
      this.dispatch = x => get(x, dispatch);
    }
    this.default = dfault;
    this.method = {};
  }

  resolveMethod(args) {
    const v = this.dispatch(...args);
    const f = this.method[v] || this.method.default;
    if (!f) {
      throw new Error(`No method found for dispatch value ${v}.`);
    }
    if (!(f instanceof Function)) {
      throw new Error(`Method for dispatch value ${v} is not a function.`);
    }
    return f;
  }

  call(...args) {
    if (isEmpty(args)) {
      throw new Error('Multimethod does not support 0-arity.');
    }
    const method = this.resolveMethod(args);
    return method(...args);
  }
}

export default function multimethod(dispatch, dfault) {
  const mm = new Multimethod(dispatch, dfault);
  const f = (...args) => mm.call(...args);
  f.method = mm.method;
  return f;
}
