export default () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  action__buttons: {
    marginLeft: 'auto',
    marginTop: '1.4rem',
  },
  add__to__list: {},
});
