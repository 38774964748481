import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import Button from 'components/base/Button';
import styles from './styles';


const LastPageLink = ({
  onClick,
  classes,
  hide,
}) => (
  hide ? null : (
    <Button variant="contained" className={classes.root} onClick={onClick} color="secondary" >
      <NavigateNextIcon />
    </Button>
  )
);

LastPageLink.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  styleFirstPageLink: PropTypes.shape({}),
};

LastPageLink.defaultProps = {
  styleFirstPageLink: {},
  hide: false,
};

export default withStyles(styles)(LastPageLink);
