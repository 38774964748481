import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default withStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  contained: {
    backgroundColor: theme.palette.black.light,
    color: theme.palette.white.main,
  },
  containedPrimary: {
    backgroundColor: theme.palette.blue.main,
  },
  containedSecondary: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.black.dark,
    '&:hover': {
      backgroundColor: theme.palette.white.light,
    },
  },
  label: {
    textTransform: 'none',
  },
}))(Button);
