import { mpTrack } from 'utils/copenhagen';
import { request } from 'lib/http';
import API from 'constants/api';

export const FETCH_FEATURED_CATEGORIES = 'FETCH_FEATURED_CATEGORIES';

export const fetchFeaturedCategories = () => async (dispatch) => {
  mpTrack('load__featured_categories', {});
  dispatch({
    type: FETCH_FEATURED_CATEGORIES,
    payload: request('get', API.FETCH_FEATURED_CATEGORIES, {}),
  });
};
