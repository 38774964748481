import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { byCountry } from 'country-code-lookup';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import PlatformFilter from 'components/common/PlatformFilter';
import { titleCase } from 'utils/helpers';
import BrandAudience from './BrandAudience';
import TabHeader from '../TabHeader';

const styles = theme => ({
  root: {},
  card: {
    marginBottom: '1.8rem',
  },
  disclaimer: {
    marginBottom: '4rem',
    textAlign: 'center',
    display: 'block',
  },
  noData: {
    margin: '4rem',
    textAlign: 'center',
    color: theme.palette.black.light,
  },
});

const transformData = ({
  audience,
  brandLabelLegend,
}) => {
  const { platforms } = audience;
  const audiencePlatforms = Object.keys(platforms);

  const platformData = {};

  audiencePlatforms.forEach((p) => {
    platformData[p] = brandLabelLegend.map((brand, index) => ({
      brand,
      audienceAge: {
        labels: platforms[p].age.labels,
        values: platforms[p].age.dataSets[index].values,
      },
      audienceGender: {
        labels: platforms[p].gender.labels,
        values: platforms[p].gender.dataSets[index].values,
      },
      audienceEthnicity: {
        labels: platforms[p].ethnicity.labels,
        values: platforms[p].ethnicity.dataSets[index].values,
      },
      audienceCountries: {
        labels: platforms[p].countries[index].labels.map(l => get(byCountry(titleCase(l)), 'iso2')),
        values: platforms[p].countries[index].values,
      },
      audienceInterest: {
        values: platforms[p].interest.dataSets.map(interest => interest.values[index]),
      },
    }));
  });

  return {
    audiencePlatforms,
    platformData,
  };
};

const AudienceDemographics = ({ classes, data, isExportedAsPDF }) => {
  const { audiencePlatforms, platformData } = data;

  const [currentPlatform, setCurrentPlatform] = useState(audiencePlatforms.length ? audiencePlatforms[0] : null);

  return (
    <div className={classes.root}>
      <TabHeader
        title="Audience Demographics"
        description="Focuses on the audience of each brand breaking down critical demographics
                  like gender, age, ethnicity, country, and general categories of interest."
      />
      {audiencePlatforms.length ? (
        <>
          <PlatformFilter
            singleSelection
            selected={[currentPlatform]}
            platforms={audiencePlatforms}
            onSelect={p => setCurrentPlatform(p)}
          />
          {platformData[currentPlatform] && platformData[currentPlatform].map(d => (
            <BrandAudience key={`${currentPlatform}-${d.brand.name}`} data={d} isExportedAsPDF={isExportedAsPDF} />
          ))}
          <Typography variant="caption" className={classes.disclaimer}>
            Creator audience demographics are not representative of specific posts or social platforms and are intended
            to be used for directional purposes only.  The metrics above are reflective of the cross-platform audience
            for all brand-sponsored creators within this analysis and are derived or inferred utilizing multiple data
            sources and cross-platform signals.
          </Typography>
        </>
      ) : (
        <Typography variant="h2" className={classes.noData}>No Data</Typography>
      )}
    </div>
  );
};

AudienceDemographics.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    audiencePlatforms: PropTypes.arrayOf(PropTypes.string),
    platformData: PropTypes.shape({}),
  }).isRequired,
  isExportedAsPDF: PropTypes.bool,
};

AudienceDemographics.defaultProps = {
  isExportedAsPDF: false,
};

const mapStateToProps = ({ competitiveReportStat }) => ({
  data: transformData(competitiveReportStat.data),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(AudienceDemographics);
