import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base/Card';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
  },
  brandName: {
    textAlign: 'center',
    paddingLeft: '1rem',
  },
  logo: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    position: 'absolute',
    height: '100%',
  },
  logoContainer: {
    width: '4rem',
    height: '4rem',
    position: 'relative',
    backgroundColor: theme.palette.white.dark,
    '&:after': {
      content: '',
      display: 'block',
      paddingBottom: '100%',
    },
  },
});

const Brand = ({ classes, data }) => (
  <Card
    className={classes.root}
  >
    <div className={classes.logoContainer}>
      <div className={classes.logo} style={{ backgroundImage: `url(${data.logo})` }} />
    </div>
    <div className={classes.brandName}>{data.username}</div>
  </Card>
);

Brand.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    username: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(Brand);
