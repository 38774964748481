import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import {
  getCompetitiveReportPostActivity as getCompetitiveReportPostActivityAction,
} from 'redux/reports/actions';
import SponsoredFilter from '../SponsoredFilter';

const SponsoredSelect = ({
  params,
  getCompetitiveReportPostActivity,
  match: { params: { reportId } },
}) => {
  const handleSponsoredSelect = (options) => {
    const { isSponsored, ...otherParams } = params;
    getCompetitiveReportPostActivity(
      reportId,
      {
        ...otherParams,
        ...options.sponsored ? { isSponsored: true } : {},
        ...options.organic ? { isSponsored: false } : {},
      },
    );
  };

  return (<SponsoredFilter handleSelect={handleSponsoredSelect} params={params} />);
};

SponsoredSelect.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  getCompetitiveReportPostActivity: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = ({ competitiveReportPostActivity }) => ({
  params: competitiveReportPostActivity.params,
});

export default withAll(
  connect(mapStateToProps, { getCompetitiveReportPostActivity: getCompetitiveReportPostActivityAction }),
  withRouter,
)(SponsoredSelect);
