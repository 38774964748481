import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withAll from 'components/base/withAll';
import { searchUrl } from 'constants/routing';
import { deselectAllInfluencers as deselectAllInfluencersAction } from 'redux/searchDisplay/actions';
import { resetSearchParams as resetSearchParamsAction,
  setSearchParam as setSearchParamAction } from 'redux/searchParams/actions';
import { getRecommendedInfluencers as getRecommendedInfluencersAction } from 'redux/influencers/actions';
import { scrollToTop } from 'utils/helpers';
import styles from './styles';


const RecommendInfluencer = ({
  classes,
  selectedInfluencerId,
  setSearchParam,
  resetSearchParams,
  deselectAllInfluencers,
  getRecommendedInfluencers,
  history,
}) => {
  const onClick = async () => {
    await resetSearchParams();
    await setSearchParam('isRecommendation', true);
    deselectAllInfluencers();
    scrollToTop();
    getRecommendedInfluencers(selectedInfluencerId);
    history.push(searchUrl());
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        <PersonAddIcon className={classes.recommend_influencer_icon} /> Similar Influencers
      </Button>
    </div>
  );
};

RecommendInfluencer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  selectedInfluencerId: PropTypes.string.isRequired,
  setSearchParam: PropTypes.func.isRequired,
  resetSearchParams: PropTypes.func.isRequired,
  deselectAllInfluencers: PropTypes.func.isRequired,
  getRecommendedInfluencers: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withAll(
  connect(
    null,
    {
      setSearchParam: setSearchParamAction,
      resetSearchParams: resetSearchParamsAction,
      deselectAllInfluencers: deselectAllInfluencersAction,
      getRecommendedInfluencers: getRecommendedInfluencersAction,
    },
  ),
  withRouter,
  withStyles(styles),
)(RecommendInfluencer);
