import React from 'react';
import PropTypes from 'prop-types';
import { clone, filter } from 'lodash';
import { MenuItem } from '@material-ui/core';
import { SEARCH_SORT_OPTIONS } from 'constants/options';
import ButtonMenu from 'components/base/ButtonMenu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import withPermission from '../../../common/withPermission';

class Sort extends React.Component {
  static propTypes = {
    handleSort: PropTypes.func.isRequired,
    showLabels: PropTypes.bool.isRequired,
    selectedSort: PropTypes.string.isRequired,
    followerCount: PropTypes.bool,
    averageLikes: PropTypes.bool,
    averageComments: PropTypes.bool,
    engagement: PropTypes.bool,
    sponsoredEngagement: PropTypes.bool,
    relevantPostEngagement: PropTypes.bool,
  };

  static defaultProps = {
    followerCount: false,
    averageLikes: false,
    averageComments: false,
    engagement: false,
    sponsoredEngagement: false,
    relevantPostEngagement: false,
  };

  canSort = ({ value }) => {
    switch (value) {
      case 'follower_count':
        return this.props.followerCount;
      case 'average_likes':
        return this.props.averageLikes;
      case 'average_comments':
        return this.props.averageComments;
      case 'engagement':
        return this.props.engagement;
      case 'sponsored_engagement':
        return this.props.sponsoredEngagement;
      case 'relevant_post_engagement':
        return this.props.relevantPostEngagement;
      default:
        return false;
    }
  };

  createSortOptions = () => {
    const { showLabels } = this.props;
    const sortOptions = clone(SEARCH_SORT_OPTIONS);
    if (showLabels) {
      sortOptions.push({ label: 'Label usage', value: 'labels' });
    }
    return sortOptions;
  };

  renderSortOptions = (sortOptions) => {
    const { handleSort } = this.props;
    return sortOptions.map(sortType => (
      <MenuItem
        key={`sort-${sortType.value}`}
        onClick={() => handleSort(sortType.value)}
      >
        {sortType.label}
      </MenuItem>
    ));
  };

  render() {
    const sortOptions = filter(this.createSortOptions(), this.canSort);
    const selectedSort = sortOptions.find(options => options.value === this.props.selectedSort);
    return (
      <ButtonMenu
        buttonProps={{
          variant: 'contained',
          color: 'secondary',
        }}
        buttonChildren={(
          <React.Fragment>
            {selectedSort ? selectedSort.label : ''}
            <KeyboardArrowDownIcon />
          </React.Fragment>
        )}
        menuChildren={this.renderSortOptions(sortOptions)}
      />
    );
  }
}

export default withPermission('search_sorts', null, ({ sorts }) => sorts)(Sort);
