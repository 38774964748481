import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PageTitle from 'components/base/titles/PageTitle';
import cx from 'classnames';

const styles = theme => ({
  title: {
    fontSize: '2rem',
    marginBottom: '1rem',
    display: 'block',
    '&:before': {
      width: '40px',
      marginBottom: '0.8rem',
      display: 'block',
      position: 'relative',
      content: '""',
      height: '2px',
      backgroundColor: theme.palette.pink.main,
    },
  },
});

const HomeTitle = ({
  classes,
  className,
  title,
  subtitle,
  icon,
  titleId,
}) => (
  <PageTitle
    title={title}
    subtitle={subtitle}
    icon={icon}
    className={cx(classes.title, className)}
    titleId={titleId}
  />
);

HomeTitle.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  titleId: PropTypes.string,
  icon: PropTypes.element,
};

HomeTitle.defaultProps = {
  subtitle: null,
  className: '',
  icon: null,
  titleId: null,
};

export default withStyles(styles)(HomeTitle);
