import 'theme/bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import App from 'components/app/App';
import { MuiThemeProvider } from '@material-ui/core/styles';
import jss from 'jss';
import preset from 'jss-preset-default';
import { SheetsRegistry } from 'react-jss';
import theme from 'theme/index';
import baseStyle from 'theme/base';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

jss.setup(preset());
const setupJss = () => {
  jss.setup(preset());
  const sheetsRegistry = new SheetsRegistry();
  const globalStyleSheet = jss.createStyleSheet(baseStyle(theme)).attach();
  sheetsRegistry.add(globalStyleSheet);
  return sheetsRegistry;
};

setupJss();
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_BUILD_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const Root = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
