export default theme => ({
  form: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputs: {
    display: 'flex',
    flexBasis: '48%',
    flexDirection: 'column',
  },
  title: {
    fontSize: '14px',
    marginBottom: '0.6rem',
    color: theme.palette.black.light,
  },
  influencerList: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
    padding: '0.6rem',
    border: `1px solid ${theme.palette.border.dark}`,
    color: theme.palette.black.light,
    minHeight: '8rem',
  },
  priorities: {
    display: 'flex',
    flexBasis: '48%',
    flexDirection: 'column',
  },
  prioritiesTable: {
    width: '320px',
  },
  priorityRadios: {
    '& label': {
      marginLeft: 0,
      marginRight: 0,
      width: '25%',
      justifyContent: 'center',
    },
  },
  priorityLabel: {
    width: '40%',
    alignSelf: 'center',
  },
  tableCell: {
    border: 0,
    padding: 0,
    color: theme.palette.black.light,
  },
  usernameAutocomplete: {
    '& > div > div > div > div': {
      backgroundColor: theme.palette.white.main,
      border: `1px solid ${theme.palette.border.dark}`,
    },
  },
  influencerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  removeButton: {
    minWidth: '1rem',
    padding: 0,
    color: theme.palette.black.light,
    '&:hover': {
      color: theme.palette.black.dark,
    },
  },
});
