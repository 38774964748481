import {
  combineReducers,
  createStore,
  compose,
  applyMiddleware,
} from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { errorsReducer } from './errors/reducers';
import { authReducer } from './authentication/reducers';
import { appReducer, tooltip } from './app/reducers';
import { configReducer } from './config/reducers';
import { homeStatReducer, verticalsReducer, verticalReducer } from './stats/reducers';
import { brandReducer, brandsReducer, statBrandsReducer } from './brand/reducers';
import {
  brandSafetyReportReducer,
  brandSafetyReportPostsReducer,
  brandSafetyReportsReducer,
  brandSafetyReportUnusualEngagementPostsReducer,
  objectionableContentListsReducer,
} from './brandSafetyReports/reducers';
import {
  competitiveReportReducer,
  competitiveReportsReducer,
  competitiveReportPostsReducer,
  competitiveReportVerticalBreakdownReducer,
  competitiveReportCreatorsReducer,
  competitiveReportStatReducer,
  competitiveReportDistributionReducer,
  competitiveReportPostActivityReducer,
  competitiveReportExportReducer,
  customizedCompetitiveReportsReducer,
  competitiveReportEstimatedSpendReducer,
} from './reports/reducers';
import { onBoardingReducer } from './onBoarding/reducers';
import {
  brandAutocompleteReducer,
  countriesAutoCompleteReducer,
  keywordAutoCompleteReducer,
  labelAutoCompleteReducer,
  locationAutoCompleteReducer,
  usernameAutoCompleteReducer,
} from './autocompletes/reducers';
import { userProfileReducer } from './user/reducers';
import { searchReducer } from './searchDisplay/reducers';
import {
  influencerProfileNavigationReducer,
  influencerProfileReducer,
  influencerReducer,
  influencerSponsoredPostsReducer,
  influencerTopPostsReducer,
  requestNewInfluencerReducer,
  influencerTopContentReducer,
} from './influencers/reducers';
import { searchParamsReducer } from './searchParams/reducers';
import { listsReducer } from './lists/reducers';
import featuredReducer from './featured/reducers';
import verticalsCategoriesReducer from './categories/reducers';
import { relatedKeywordsReducer } from './relatedKeywords/reducers';
import { keyPhraseMonitorsReducer, keyPhraseMonitorReducer, keyPhraseMonitorPostsReducer } from './monitors/reducers';

const middlewares = [thunk, promise];

// Maintain alphabetical order
const reducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  brand: brandReducer,
  brandAutocomplete: brandAutocompleteReducer,
  brandSafetyReport: brandSafetyReportReducer,
  brandSafetyReportPosts: brandSafetyReportPostsReducer,
  brandSafetyReports: brandSafetyReportsReducer,
  brandSafetyReportUnusualEngagementPosts: brandSafetyReportUnusualEngagementPostsReducer,
  brands: brandsReducer,
  competitiveReport: competitiveReportReducer,
  competitiveReportExport: competitiveReportExportReducer,
  competitiveReports: competitiveReportsReducer,
  customizedCompetitiveReports: customizedCompetitiveReportsReducer,
  competitiveReportStat: competitiveReportStatReducer,
  competitiveReportPosts: competitiveReportPostsReducer,
  competitiveReportVerticalBreakdown: competitiveReportVerticalBreakdownReducer,
  competitiveReportCreators: competitiveReportCreatorsReducer,
  competitiveReportDistribution: competitiveReportDistributionReducer,
  competitiveReportPostActivity: competitiveReportPostActivityReducer,
  competitiveReportEstimatedSpend: competitiveReportEstimatedSpendReducer,
  setTooltip: tooltip,
  configs: configReducer,
  countriesAutocomplete: countriesAutoCompleteReducer,
  errors: errorsReducer,
  featured: featuredReducer,
  form: reduxFormReducer, // mounted under "form",
  homeStats: homeStatReducer,
  influencerProfile: influencerProfileReducer,
  influencerProfileNavigation: influencerProfileNavigationReducer,
  influencerSponsoredPosts: influencerSponsoredPostsReducer,
  influencerTopPosts: influencerTopPostsReducer,
  influencerTopContent: influencerTopContentReducer,
  influencers: influencerReducer,
  keywordAutoComplete: keywordAutoCompleteReducer,
  labelAutoComplete: labelAutoCompleteReducer,
  lists: listsReducer,
  locationAutoComplete: locationAutoCompleteReducer,
  objectionableContentLists: objectionableContentListsReducer,
  onBoarding: onBoardingReducer,
  requestNewInfluencer: requestNewInfluencerReducer,
  searchDisplay: searchReducer,
  searchParams: searchParamsReducer,
  statBrands: statBrandsReducer,
  usernameAutoComplete: usernameAutoCompleteReducer,
  userProfile: userProfileReducer,
  vertical: verticalReducer,
  verticals: verticalsReducer,
  verticalsCategories: verticalsCategoriesReducer,
  relatedKeywords: relatedKeywordsReducer,
  keyPhraseMonitors: keyPhraseMonitorsReducer,
  keyPhraseMonitor: keyPhraseMonitorReducer,
  keyPhraseMonitorPosts: keyPhraseMonitorPostsReducer,
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export default store;
