import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = theme => ({
  pageMiddle_detail: {
    display: 'inline-block',
    width: '33%',
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  pageMiddle_detailIcon: {
    fontSize: '3.5rem',
    color: '#f02a82',
    margin: '0.5rem',
  },
  pageMiddle_detail_title: {
    margin: '1rem',
    fontWeight: '800',
  },
  pageMiddle_detail_desc: {
    margin: '1rem',
    color: '#9c9c9c',
    marginBottom: '1rem',
  },
});

const DetailContainer = ({
  classes, icon, title, description,
}) => (
  <div className={classes.pageMiddle_detail}>
    <FontAwesomeIcon icon={icon} className={classes.pageMiddle_detailIcon} />
    <div className={classes.pageMiddle_detail_title}>{title}</div>
    <div className={classes.pageMiddle_detail_desc}>
      {description}
    </div>
  </div>
);

DetailContainer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default withStyles(styles)(DetailContainer);
