export default theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.main,
      fontFamily: '"Nunito", sans-serif',
    },
    [`
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus
    `]: {
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.palette.white.main} inset`,
      transition: 'background-color 5000s ease-in-out 0s',
    },
    '.tooltip': {
      position: 'absolute',
      textAlign: 'left',
      width: 'auto',
      height: 'auto',
      padding: '0.4rem',
      fontSize: '12px',
      fontWeight: '800',
      background: theme.palette.black.main,
      border: '0',
      color: theme.palette.white.main,
      pointerEvents: 'none',
      boxShadow: theme.customShadows[1],
      zIndex: 1200,
      '&:before': {
        width: 0,
        height: 0,
        borderTop: `10px solid ${theme.palette.black.main}`,
        borderRight: '15px solid transparent',
        content: '" "',
        position: 'absolute',
        bottom: '-10px',
        left: '10px',
      },
      '& .popover-username': {
        display: 'block',
        color: theme.palette.black.light,
      },
    },
  },
});
