import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { listsUrl } from 'constants/routing';
import Tooltip from './Tooltip';

class SavedList4Tooltip extends React.Component {
  handleSearch = async () => {
    this.props.history.push(listsUrl());
  }

  render() {
    return <Tooltip submitText="Next" handleSubmit={this.handleSearch} {...this.props} />;
  }
}


SavedList4Tooltip.propTypes = {
  backProps: PropTypes.shape({}).isRequired,
  closeProps: PropTypes.shape({}).isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  primaryProps: PropTypes.shape({}).isRequired,
  size: PropTypes.number.isRequired,
  skipProps: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  tooltipProps: PropTypes.shape({}).isRequired,
};

export default withRouter(SavedList4Tooltip);
