import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles';

const Icon = ({ classes, icon, className }) => (
  <div className={cx(classes.icon, className)}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

Icon.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  icon: PropTypes.shape({}).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
};

Icon.defaultProps = {
  className: {},
};

export default withStyles(styles)(Icon);
