import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import FooterItem from 'components/common/FooterNav/FooterItem';
import styles from './styles';
import { privacyPolicy, termsOfUse } from '../../../constants/routing';

const FooterNav = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <FooterItem>© Copyright 2021 Go Prism</FooterItem>
      <FooterItem>All Rights Reserved</FooterItem>
      <FooterItem>
        <Link
          className={classes.link}
          to={termsOfUse()}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms Of Use
        </Link>
      </FooterItem>
      <FooterItem>
        <Link
          className={classes.link}
          to={privacyPolicy()}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </FooterItem>
    </div>
  </div>
);

FooterNav.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(FooterNav);
