import React from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import Button from 'components/base/Button';
import withAll from 'components/base/withAll';
import { withStyles } from '@material-ui/styles';
import { keys } from 'lodash';
import PropTypes from 'prop-types';
import { PLATFORM_LABELS } from 'constants/options';
import { filterBrandDetail as filterBrandDetailAction } from 'redux/brand/actions';
import LanguageIcon from '@material-ui/icons/Language';

const styles = () => ({

});

const FILTER_LABELS = {
  ...PLATFORM_LABELS,
  [null]: 'All Platforms',
};

const PALTFORM_PARAM_NAME = 'platform';

function PlatformFilter({
  availablePlatforms,
  filterBrandDetail,
  params,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (p) => {
    setAnchorEl(null);
    setSelectedOption(p);
  };

  const onSelect = (p) => {
    handleClose(p);
    filterBrandDetail({
      ...params,
      [PALTFORM_PARAM_NAME]: p,
    });
  };
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<LanguageIcon />}
      >
        {FILTER_LABELS[selectedOption]}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(selectedOption)}
      >
        {/* eslint-disable-next-line */}
        <MenuItem onClick={() => onSelect(null)}>{FILTER_LABELS[null]}</MenuItem>
        {availablePlatforms.map(p => <MenuItem onClick={() => onSelect(p)}>{FILTER_LABELS[p]}</MenuItem>)}
      </Menu>
    </div>
  );
}

PlatformFilter.propTypes = {
  filterBrandDetail: PropTypes.func.isRequired,
  availablePlatforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  params: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ brand }) => ({
  availablePlatforms: keys(brand.data.platforms),
  params: brand.params,
});

export default withAll(
  connect(mapStateToProps, { filterBrandDetail: filterBrandDetailAction }),
  withStyles(styles),
)(PlatformFilter);
