import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import LoggedOutLayout from 'components/base/LoggedOutLayout';
import withQueryParams from 'components/base/withQueryParams';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { verifyEmail } from 'redux/user/actions';
import { logout } from 'redux/authentication/actions';
import { toast } from 'react-toastify';
import { login } from 'constants/routing';
import styles from './styles';

const TIMEOUT = 4000;

class VerifyEmail extends PureComponent {
  async componentDidMount() {
    const {
      code,
      email,
      isAuthenticated,
      redirect,
    } = this.props;
    if (isAuthenticated) {
      this.props.logout();
    }
    if (isEmpty(code) || isEmpty(email)) {
      toast.error('Your link is missing the email or verification code.');
      return;
    }
    try {
      await this.props.verifyEmail(email, code);
      toast.success('Email verified! You will be redirected to login.');
      setTimeout(() => {
        const url = !isEmpty(redirect) ? `${login()}?redirect=${redirect}` : login();
        this.props.history.push(url);
      }, TIMEOUT);
    } catch (error) {
      if (error.errors) {
        toast.error('Verification failed. This may be due to link already being verified.');
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <LoggedOutLayout>
        <div className={classes.card} />
      </LoggedOutLayout>
    );
  }
}

VerifyEmail.propTypes = {
  verifyEmail: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
  }).isRequired,
  code: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const paramsToProps = ({ code, email, redirect }) => ({
  code,
  email,
  redirect,
});

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default connect(
  mapStateToProps,
  { verifyEmail, logout },
)(withQueryParams(paramsToProps)(withStyles(styles)(VerifyEmail)));
