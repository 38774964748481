import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import BarBasic from 'components/base/charts/Bar/BarBasic';
import { findBrandObjFromName } from 'components/common/CompetitiveReport/utils';
import H6 from 'components/base/titles/CardHeading/H6';
import Legend from 'components/base/charts/Legend';
import LabelWithInfoIcon from 'components/base/LabelWithInfoIcon/LabelWithInfoIcon';
import { orderBy, truncate, get } from 'lodash';
import { transformLegendLabels } from 'redux/reports/reducers';
import { LEGEND_MAX_CHAR } from 'components/common/CompetitiveReport/constants';

const styles = () => ({
  root: {},
  titleText: {
    paddingRight: '0.8rem',
  },
});

const transformData = ({ postsByAvgAudienceSize }, brands) => ({
  datasets: orderBy(postsByAvgAudienceSize.dataSets.map(d => ({
    data: d.values,
    label: d.label,
    backgroundColor: findBrandObjFromName(d.label, brands).legendColor,
  })), 'label'),
  labels: postsByAvgAudienceSize.labels,
});


const PostsByCreatorReach = ({ data, classes }) => (
  <div className={classes.root}>
    <H6>
      <LabelWithInfoIcon
        labelText="Post by Creator Reach"
        tooltip="The number of posts by each type of creators categorized by follower count."
      />
      <Legend
        items={data.datasets.map(d => ({
          title: truncate(d.label, { length: LEGEND_MAX_CHAR }),
          color: d.backgroundColor,
        }))}
      />
    </H6>
    <BarBasic
      data={data}
      options={{
        legend: {
          display: false,
        },
      }}
    />
  </div>
);

PostsByCreatorReach.propTypes = {
  data: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportPostActivity }) => ({
  data: transformData(
    competitiveReportPostActivity.data,
    transformLegendLabels(get(competitiveReportPostActivity, 'data.postsByAvgAudienceSize.dataSets', [])),
  ),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(PostsByCreatorReach);
