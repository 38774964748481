import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import withAll from 'components/base/withAll';
import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import AudienceDistribution from './AudienceDistribution';

const styles = () => ({
  root: {},
});

const AverageAudienceSizeByPlatform = ({ data: { labels, dataSets }, classes }) => (
  <div className={classes.root}>
    <Grid container spacing={2} justify="center">
      {orderBy(dataSets, 'label').map(d => (
        <Grid item md={3}>
          <AudienceDistribution
            title={d.label}
            data={{
              values: d.values,
              labels,
            }}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

AverageAudienceSizeByPlatform.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    dataSets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ competitiveReportDistribution }) => ({
  data: get(competitiveReportDistribution, 'data.avgAudienceSizePlatform', []),
});

export default withAll(
  connect(mapStateToProps, null),
  withStyles(styles),
)(AverageAudienceSizeByPlatform);
